import React from 'react';

//더보기 페이징
const MoreBtn = (props: any) => {
  const onClickHandler = () => {
    /*let offset = (Math.floor(props.offset / props.limit) + 1) * props.limit + props.data.length - 1
    let data = await props.fetchData(offset, props.limit)
    props.onClick(data.results) */

    props.setLimit(props.limit + 10);
  }

  return (
    <div className="more_area" style={{display: props.limit<props.totalCount ? '' : 'none'}}>
      <button className="btn btn_lg btn_outline" onClick={() => onClickHandler()}>
        더보기 <i className="ico_more"></i>
      </button>
    </div>
  );
};

export default MoreBtn;
