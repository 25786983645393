import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import queryString from 'query-string';

import CollapseView from '../../components/molecules/collapse';
import WorkSite from '../../components/molecules/tab/workSite';

import DefaultTemplate from '../../components/templetes/listDefault';

import { ROOM_TYPE, WORK_SITE_CODE, ROOM_TYPE_CODE, WORK_SITE } from '../../constant';

import './index.scss';
import OnOff from '../../apis/onOff';
import { SHOW_MODAL } from '../../store/modules/modal';

const UseGuidePage = (props: any) => {
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user,
  }));

  const [workSite, setWorkSite] = useState<number>(userInfo.worksite);
  const [roomType, setRoomType] = useState<number>(1);

  const [contents, setContents] = useState<string>("");
  const [workSites, setWorksites] = useState<any>([]);

  useEffect(() => {
    const qs: any = queryString.parse(window.location.search);
    setWorkSite(parseInt(qs.workSite));
    setRoomType(parseInt(qs.roomType));
  }, [window.location.search]);

  useEffect(() => {
    (async () => {
      if(userInfo.worksite !== 0){
        try {
          // 사업장 그리기
          let { data } = await OnOff.getWorksite({
            // work_site: props.value,
            user_work_site: userInfo.worksite,
            room_type: roomType
          });

          setWorksites(data);

          // 초기 사업장 선택
          if(data.filter((d: any) => d.user_work_site === userInfo.worksite).length > 0){
            setWorkSite(data[0].work_site);
          }
          // else{//if(data[0].work_site)
          //   setWorkSite(data[0].work_site);
          // }
        }catch ({ status, data }) {
          if (status === 401) {
            window.location.href = '/force/signout';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }

          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '문제발생',
              msg: '문제가 발생했습니다',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }
      }
    })();
  }, [userInfo.worksite, roomType]);
  
  return (
    <DefaultTemplate
      {...props}
      id="wrap"
      className="wrap_join"
      leftInfo={
        <>
          <h2>{ROOM_TYPE[roomType]}</h2>
          <p className="desc">
            {ROOM_TYPE[roomType]} 이용을 위한 안내사항을 확인하실 수 있습니다
              </p>
          <div className="bg_img room"></div>
        </>
      }
    >
      <div className="tabs_area">
        {/* <WorkSite
          value={workSite}
          onChange={(worksite: number) => setWorkSite(worksite)}
        /> */}
        <ul className="tabs">
          {workSites.map((option: any, optionIdx: number) => (
            <li
              onClick={() => setWorkSite(option.work_site)}
            >
              <a
                href="javascript:void(0)"
                className={`${workSite === option.work_site ? 'on' : ''}`}
              >
                {WORK_SITE[option.work_site]}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <CollapseView
        workSite={workSite}
        roomType={roomType}
        onChangeContent={setContents}
      />

    </DefaultTemplate>
  )

};

export default UseGuidePage;
