import React from 'react';
import { BAD_DISABLE_COLOR } from '../../../color';

const DisableBad = (props: any) => {
  const backgroundColor = BAD_DISABLE_COLOR;
  return (
    <a className="room disabled" style={{ backgroundColor }}>
      {props.children}
    </a>
  );
};

export default DisableBad;
