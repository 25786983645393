import React from 'react';
import { useDispatch } from 'react-redux';

import { SHOW_CONFIRM_MODAL, CLOSE_CONFIRRM_MODAL } from '../../../store/modules/modal';

interface Props {
  title?: string
  msg: string
  action: Function
  label: string
  className?: string
  style?: any
  validate?: Boolean
  validateMsg?: string
}


//확인 버튼
const ConfirmBtn = (props: Props) => {
  const dispatch = useDispatch();
  
  const onClickHndler = () => {
    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        isModalShow: true,
        title: props.title,
        msg: props.validate? props.validateMsg : props.msg,
        action: props.validate ? () => { } : props.action
      }
    })
  }

  return (
    <button
      {...props}
      onClick={onClickHndler}
    >
      {props.label}
    </button>
  );
};

export default ConfirmBtn;
