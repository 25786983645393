import React from 'react';

const Select = (props: any) => {
  return (
    <select {...props}>
      {props.options.map((option: any, optionIdx: number) => (
        <option value={option.value} label={option.label} key={optionIdx}>{option.label}</option>
      ))}
    </select>
  );
};

export default Select;
