import React from 'react';

import { GENDER } from '../../../constant';

import Bad from '../bad';

const FacilityRoom = (props: any) => {
  if (props.room.name === '계단') return <a className="stair"></a>;
  else if (props.room.name === '샤워실') return <a className="wash"></a>;
  else return <a className=""> </a>;
};

const Rooms = (props: any) => {

  const getBad = () => {
    const temp: any = [];
    for (let i = 0; i < props.room.quota; ++i) {
      temp.push(
        <Bad
          key={i}
          // onClick={() => props.onClick(props.room, i)}
          room={props.room}
          guestIdx={i}
          onRoomClickHandlerToSelect={() => props.onRoomClickHandlerToSelect(props.room, i)}
          onClickonRoomClickHandlerToUnSelect={() => props.onClickonRoomClickHandlerToUnSelect(props.room, i)}
          selected={props.isSelect(props.room, i)}
          isDisable={props.isDisable(props.room, i)}
          detail={props.detail ? props.detail(props.room, i) : () => { }}
        />
      );
    }

    return temp;
  };
  const g: number = props.room?.gender_type || 1;

  return (
    <li key={props.key}>
      <p className="num"> {props.room.name} 
        {props.room?.gender_type === 1? '(남)' : ''}
        {props.room?.gender_type === 2? '(여)' : ''}</p>
      { getBad()}
    </li>
  )
};

export default Rooms;
