import React from 'react';
import { Redirect } from 'react-router';

import './index.scss';

const SignupComplete = (props: any) => {
  if (!props.location.state) {
    return <Redirect to="/" />;
  }

  return (
    <div id="wrap" className="wrap_join">
      <header>
        <h1>
          <a
            href="javascript:void(0)"
            className="logo ir"
            onClick={() => props.history.push('/')}
          >
            AMS Platform
          </a>
        </h1>
        <div className="bar"></div>
      </header>
      {/*header*/}
      <div id="container">
        <div className="row clearfix">
          {/*<div className="col col_left group_title">
            <h2>회원가입</h2>
            <p className="desc">
              빠른 숙소 신청 서비스로 간편하게 숙소를 이용해보세요.
            </p>
            <div className="bg_img"></div>
          </div>*/}
          <div id="contents" className="">
            <dl className="lst_complete">
              <dt>가입이 완료되었습니다</dt>
              <dd>
                하기와 같은 정보로 가입이 완료되었습니다
                <br />
                기본정보는 인사 정보를 연동하여 가져오며, 수정이 불가합니다
                <br />
                상이한 정보가 존재하면 인사 정보를 변경하시면 저희 플랫폼 내
                정보가 자동 변경 됩니다
              </dd>
            </dl>

            <dl className="lst_basicinfo">
              <dt>가입 요약 정보</dt>
              <dd>
                <ul>
                  <li>
                    <strong>성명</strong>
                    <span>{props.location.state.name}</span>
                  </li>
                  <li>
                    <strong>아이디</strong>
                    <span>{props.location.state.employeeNumber}</span>
                  </li>
                  <li>
                    <strong>근무지명</strong>
                    <span>{props.location.state.workplace}</span>
                  </li>
                </ul>
              </dd>
            </dl>

            <div className="submit btn_length_1">
              <button
                className="btn btn_lg btn_darkblue"
                onClick={() => props.history.push('/')}
              >
                완료
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupComplete;
