import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import StatusTag, {CHECKOUT} from '../../components/atoms/tag/statusTag';
import Pagination from '../../components/organismas/pagination';
import MoreBtn from '../../components/organismas/pagination/more';

import DefaultTemplete from '../../components/templetes/listDefault';

import { ROOM_TYPE, CHECKOUT_STATUS_TYPE_CODE, LODGE_STATUS_CODE, CHECKOUT_STATUS_CODE } from '../../constant';
import { lodge } from '../../apis';
import { formatByString_YYYYMMDD } from '../../utils/date';
import { SHOW_MODAL } from '../../store/modules/modal';

// 마이 페이지 - 퇴숙 관리 리스트
const LodgeCheckoutPage = (props: any) => {
  const [reload, setReload] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [checkouts, setCheckouts] = useState([]);
  const [rejectDescription, setRejectDescription] = useState<string>('');
  const [isRejectPop, setIsRejectPop] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      window.history.pushState(null, document.title, props.history.location.href); 
      window.addEventListener('popstate', function(event) { 
          window.history.pushState(null, document.title, props.history.location.href); 
      });
      const data = await fetchData(offset, limit)
      const count: number = data.count;
      setCheckouts(data.results);
      setTotalPage(Math.ceil(count / limit));
      setTotalCount(count);
    })();
  }, [ offset, reload, limit]);

  const fetchData = async (offset: number, limit: number) => {
    try {
      const payload: any = {
        offset,
        limit,
        //status: [LODGE_STATUS_CODE.checkin, LODGE_STATUS_CODE.checkout].join(',')
      };
      
      const { data }: any = await lodge.getCheckout(payload);

      return data
    }catch ({ status, data }) {
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  }

  // 진행상태 
  const getCheckoutStatus = (status: number, des: string) => {
    if (status === CHECKOUT_STATUS_CODE.apply) {
        return <span className="state_room case2">승인대기</span>
    } else if (status === CHECKOUT_STATUS_CODE.approve) {
        return <span className="state_room case4">승인완료</span>
    } else if (status === CHECKOUT_STATUS_CODE.reject) {
        return (
            <>
                <span className="state_room case1">승인거절</span>
                <div className="right_area">
                    <button className="btn btn_sm btn_outline" onClick={() => { setRejectDescription(des); setIsRejectPop(true) }}>거절사유</button>
                </div>
            </>
        )
    }else if (status === CHECKOUT_STATUS_CODE.terminate) {
      return (
          <>
              <span className="state_room case1">반려(재요청)</span>
              <div className="right_area">
                  <button className="btn btn_sm btn_outline" onClick={() => { setRejectDescription(des); setIsRejectPop(true) }}>반려사유</button>
              </div>
          </>
      )
    } else if (status === CHECKOUT_STATUS_CODE.cancel) {
        return <span className="state_room case1">신청취소</span>
    }
  }

  // 진행상태 
  const getCheckoutStatusMobile = (status: number, des: string) => {
    if (status === CHECKOUT_STATUS_CODE.apply) {
        return <span className="state_room case2">승인대기</span>
    } else if (status === CHECKOUT_STATUS_CODE.approve) {
        return <span className="state_room case4">승인완료</span>
    } else if (status === CHECKOUT_STATUS_CODE.reject) {
        return (
            <>
                <div className="right_area">
                <span className="state_room case1">승인거절</span>
                    <button className="btn btn_sm btn_outline ml10" onClick={() => { setRejectDescription(des); setIsRejectPop(true) }}>거절사유</button>
                </div>
            </>
        )
    }else if (status === CHECKOUT_STATUS_CODE.terminate) {
      return (
          <>
              <div className="right_area">
                  <span className="state_room case1">반려(재요청)</span>
                  <button className="btn btn_sm btn_outline ml10" onClick={() => { setRejectDescription(des); setIsRejectPop(true) }}>반려사유</button>
              </div>
          </>
      )
    } else if (status === CHECKOUT_STATUS_CODE.cancel) {
        return <span className="state_room case1">신청취소</span>
    }
  }

  // 팝업 초기화
  const popInit = () => {
      setRejectDescription('');
  }

  return (
    <DefaultTemplete
      leftInfo={
        <>
          <h2>퇴숙관리</h2>
          <p className="desc">기숙사, 사택  퇴숙신청 및 현황을 확인하실 수 있습니다</p>
          <div className="bg_img checkout"></div>
        </>
      }
      id="wrap"
      className="wrap_mypage_manage"
      {...props}
    >

      <div className="title_contents">
        <h3 className="title">퇴숙 신청 리스트</h3>
      </div>

      <div className="pc_view">
        <div className="table_normal_list mt20">
          <table>
            <caption className="sr_only">퇴숙신청 목록</caption>
            <colgroup>
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="15%" />
              <col width="*" />
            </colgroup>
            <thead>
              <tr>
                <th>숙소유형</th>
                <th>퇴숙사유</th>
                <th>퇴숙요청일</th>
                <th>퇴숙확정일</th>
                <th>신청일</th>
                <th>진행상태</th>
              </tr>
            </thead>
            <tbody>
              {checkouts.length === 0 ? (
                <tr>
                  <td colSpan={6} className="blank_area type_white">
                    <p className="txt_blank">데이터가 없습니다.</p>
                  </td>
                </tr>
              ) : (
                checkouts.map((checkout: any, idx: number) => (
                  <tr className="" key={idx}>
                    <td style={{'cursor': 'pointer'}} onClick={()=> props.history.push(`/mypage/checkout/detail`, checkout)}>{ROOM_TYPE[checkout.room_type]}</td>
                    <td style={{'cursor': 'pointer'}} onClick={()=> props.history.push(`/mypage/checkout/detail`, checkout)}>{checkout.reason_select}</td>
                    <td style={{'cursor': 'pointer'}} onClick={()=> props.history.push(`/mypage/checkout/detail`, checkout)}>{checkout.stay_before}</td>
                    <td style={{'cursor': 'pointer'}} onClick={()=> props.history.push(`/mypage/checkout/detail`, checkout)}>{checkout.checkout_at}</td>
                    <td style={{'cursor': 'pointer'}} onClick={()=> props.history.push(`/mypage/checkout/detail`, checkout)}>{checkout.created_at? formatByString_YYYYMMDD(checkout.created_at) : ''}</td>
                    <td>{getCheckoutStatus(checkout.status, checkout.status_description)}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div> 
        <div className="board_paging">
          <ul>
            <Pagination
              offset={offset}
              limit={limit}
              setOffset={setOffset}
              totalPage={totalPage}
            />
          </ul>
        </div>  {/*board_paging*/}
      </div> {/* pc_view */}

      <div className="mb_view">
        {checkouts.length === 0 ? (
            <div className="blank_area">
                <p className="txt_blank">데이터가 없습니다.</p>
            </div>
        ) : (
            <div className="lst_apply">
              {checkouts.map((checkout: any, idx: number) => (
                <>
                  <div className="sub_title">
                    <span className="label_text">{checkout.reason_select}</span>
                    <a href="javascript:void(0);" className="btn_detail" onClick={()=> props.history.push('/mypage/checkout/detail', checkout)}>상세보기</a>
                  </div>

                  <ul className="lst_item">
                  <li>
                    <p>퇴숙희망일</p>
                    <p>{checkout.stay_before}</p>
                  </li>
                  <li>
                    <p>신청일</p>
                    <p>{checkout.created_at? formatByString_YYYYMMDD(checkout.created_at) : ''}</p>
                  </li>
                  <li>
                    <p>진행상태</p>
                    <p>{getCheckoutStatusMobile(checkout.status, checkout.status_description)}</p>
                  </li>
                  </ul>
                </>
              ))}
            </div>
        )}
        <div className="more_area">
          <MoreBtn
            offset={offset}
            limit={limit}
            setLimit={setLimit}
            totalCount={totalCount}
            /*fetchData={fetchData}
            data={checkouts}
            onClick={(reqCheckout: any) => {
              console.log(reqCheckout)
              setCheckouts(checkouts.concat(reqCheckout))
            }}*/
          />
        </div>
      </div>

      {/* 반려사유 팝업 */}
      <div id="pop_request_refuse" className="pop_dim_wrap pop_modal pop_sm" style={{ display: isRejectPop ? 'block' : 'none' }}>
          <div className="pop_wrap">
              <div className="title_wrap">
                  <h3>반려(재요청)사유</h3>
                  <button className="pop_btn_close pop_close" onClick={() => {setIsRejectPop(false); popInit(); }}>닫기</button>
              </div>
              <div className="contents_wrap">
                  <div className="process_list">
                      <dl>
                          <dt>반려(재요청)사유</dt>
                          <dd>
                              <textarea className="form_control" readOnly value={rejectDescription}></textarea>
                          </dd>
                      </dl>
                  </div>
              </div>
              <div className="button_wrap btn_length_1">
                  <button type="button" className="btn btn_darkblue pop_close" onClick={() => { setIsRejectPop(false); popInit(); }}>확인</button>
              </div>
          </div>
      </div>
    </DefaultTemplete>
  );
};

export default LodgeCheckoutPage;
