import { all } from 'redux-saga/effects';

import authSaga from './auth';
import userSaga from './user';
import baseSaga from './base';

function* rootSaga() {
  try {
    yield all([authSaga(), userSaga(), baseSaga()]);
  } catch (err) {
    // console.log('rootSaga');
  }
}

export default rootSaga;
