import React, { useState, useEffect, useContext } from 'react';
import intl from 'react-intl-universal';
import DefaultTemplete from '../../components/templetes/listDefault';
import { LODGE_STATUS_CODE, SEARCH_TYPE, VOC_CATEGORY } from '../../constant';
import { lodge, voc as api } from '../../apis';
import { formatByString, isAfterAndSame, getNextDay, getPrevDay, getTodayDate, getTwoLatersMonthDate, getPrevMonth, getTwoLatersOldMonthDate } from '../../utils/date';
import DatePicker from '../../components/organismas/datePicker';
import Select from '../../components/atoms/select';
import { MessageContext, MessageImpl } from '../../routes';
import xss from 'xss';
import CustomCalendar from '../../components/organismas/customCalendar';
import { SHOW_MODAL } from '../../store/modules/modal';
import { parserEscape } from '../../utils/commons';

const statusUseable = [
  LODGE_STATUS_CODE.checkin
]

const VocPage = (props: any) => {
  const [limit, setLimit] = useState<number>(12);
  const [offset, setOffset] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<number>(-1);
  const [created_after, setCreated_after] = useState<string>(getTwoLatersOldMonthDate(2)[1]);
  const [created_before, setCreated_before] = useState<string>(getTwoLatersMonthDate(2)[1]);
  const [search, setSearch] = useState<string>('');
  const [items, setItems] = useState<any[]>([]);
  const [isNext, setIsNext] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [category1, setCategory1] = useState<string>('');
  const [category2, setCategory2] = useState<string>('');
  const [category3, setCategory3] = useState<string>('');
  const [categories1, setCategories1] = useState<any[]>([]);
  const [categories2, setCategories2] = useState<any[]>([]);
  const [categories3, setCategories3] = useState<any[]>([]);
  const [checkInlodgeds, setCheckInlodgeds] = useState<any[]>([]);
  const [reload, setReload] = useState<boolean>(false);
  const searchTypes = [{ value: '-1', label: '전체', optionIdx: 0 }];
  Object.keys(SEARCH_TYPE).forEach((key: string, index: number) => {
    searchTypes.push({
      value: key,
      label: SEARCH_TYPE[Number(key)],
      optionIdx: index + 1,
    });
  });

  // 숙소 이용중에만 voc 등록 가능하게 해야함 그래서 숙소 이용중인 것을 API로 불러오기
  const getGuest = async() => {
    try {
      const { data } = await lodge.getGuest({status: LODGE_STATUS_CODE.checkin}); // 이용중
      //setCheckInlodgeds(data.filter((lodge: any) => statusUseable.includes(lodge.status) && lodge.room_type === 2 || statusUseable.includes(lodge.status) && lodge.room_type === 5 || statusUseable.includes(lodge.status) && lodge.room_type === 6));   // 이용중
      setCheckInlodgeds(data);
      setReload(true);
    }catch ({ status, data }) {
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }
    }
  }

  useEffect(() => {
    getGuest();
    // console.log('checkInlodgeds',checkInlodgeds);
  }, [reload])

  useEffect(() => {
    (async () => {
      const data = await fetchItems({
        category1,
        category2,
        category3,
        limit : 12,
        offset: 0,
        created_after,
        created_before,
      });
      // console.log(data);
      setIsSearch(false);
      setLimit(12);
      setOffset(0);
      setItems(data.results || []);
      setIsNext(!!data.next);
      setTotalPage(Math.ceil(data.count / limit));
    })();
  }, []);
  // category1, category2, category3

  const fetchItems = async (payload: any) => {
    if (!payload.category1) {
      delete payload.category1;
    }
    if (!payload.category2) {
      delete payload.category2;
    }
    if (!payload.category3) {
      delete payload.category3;
    }

    switch (payload.searchType) {
      case 1: payload.title = payload.search; break;
      case 2: payload.contents = payload.search; break;
      case 3: payload.author = payload.search; break;
    }
    delete payload.searchType;

    if (!created_after) {
      delete payload.created_after;
    }

    if (!created_before) {
      delete payload.created_before;
    }
    try {
      const { data } = await api.getVocs(payload);
      return data;
    }catch ({ status, data }) {
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }
    }
  };

  const onSearchTypeFilter = (_searchType: number) => {
    // console.log(_searchType);
    setSearchType(_searchType);
  };

  const onSearchHandler = async () => {
    const data = await fetchItems({
      category1,
      category2,
      category3,
      searchType,
      search,
      created_after,
      created_before,
      limit,
      offset: 0,
    });
    setIsSearch(true);
    setItems(data.results);
    setIsNext(!!data.next);
    setTotalPage(Math.ceil(data.count / limit));
  };

  const onNextHandler = async () => {
    if (isNext) {

      const payload: any = {
        category1,
        category2,
        category3,
        searchType,
        search,
        created_after,
        created_before,
        limit,
        offset: limit * (currentPage + 1),
      }
      if (search) {
        payload.title = search;
      }
      const data = await fetchItems(payload);
      const tmpItems =
        items.length > 0 ? [...items, ...data.results] : data.results;
      setItems(tmpItems);
      setIsNext(!!data.next);
      setTotalPage(Math.ceil(data.count / limit));
      setCurrentPage(currentPage + 1);
    }
  };

  const removeTags = (text: string) =>
    text.replace(/<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/gi, '');

  const { info }: MessageImpl = useContext(MessageContext);
  //등록일자 관련 handler
  const onClickDateAfterByCalendar = (day: string) => {
    // dateAfter <= dateBefore
    const _isAfter = isAfterAndSame(day, created_before);
    //props.setDateAfter(day);
    setCreated_after(day);

    if (!_isAfter || day === created_before) {
      setCreated_after(getPrevDay(created_before)[0]);
      info(`${created_before}보다 이후가 될 수 없습니다.`);
    }
  };

  const onClickDateBeforeByCalendar = (day: string) => {
    // dateAfter <= dateBefore
    const _isAfter = isAfterAndSame(created_after, day);
    setCreated_before(day);
    if (!_isAfter) {
      setCreated_before(getNextDay(created_after)[0]);
      info(`${created_after}보다 이전이 될 수 없습니다.`);
    }
  };

  return (
    <DefaultTemplete
      leftInfo={
        <>
          <h2>{intl.get('My VoC')}</h2>
          <p className="desc">
            {intl.get('숙소이용 불편사항 등을 게시하고 답변 받을 수 있습니다')}
          </p>
          <div className="bg_img voc"></div>
        </>
      }
      id="wrap"
      className="wrap_customer"
      {...props}
    >
      <div className="table_design_list col_width2">
        <div id="table" className="table">
          <div className="thead mobile">
            <div className="tr">
              <div className="th"></div>
              <div className="th">
                {intl.get('검색')}
              </div>
            </div>
          </div>
          <div className="tbody">

            <div className="tr">
              <div className="th">VoC 유형</div>
              <div className="td">
                <div className="form_type">
                  <select name="" id="" className="form_control fl"
                    value={category1}
                    onChange={(e) => {
                      const category: any = VOC_CATEGORY.find(c => c.name === e.target.value);
                      const _category1 = category ? category.name : '';
                      setCategory1(_category1);
                      setCategory2('');
                      setCategory3('');
                      if (_category1 && category.child.length > 0) {
                        setCategories2([
                          { value: '', name: '2차 분류항목을 선택하세요', child: [] },
                          ...category.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child }))
                        ]);
                      } else {
                        setCategories2([]);
                      }
                      setCategories3([]);
                    }}
                  >
                    <option value="">1차 분류항목을 선택하세요</option>
                    {VOC_CATEGORY.map((category: any, index: number) => (
                      <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
                    ))}
                  </select>
                  {categories2.length > 0 ? (
                    <select name="" id="" className="form_control fl"
                      value={category2}
                      onChange={(e) => {
                        const category: any = categories2.find(c => c.name === e.target.value);
                        const _category2 = category ? category.name : '';
                        setCategory2(_category2);
                        setCategory3('');
                        if (_category2 && category.child.length > 0) {
                          setCategories3([
                            { value: '', name: '3차 분류항목을 선택하세요', child: [] },
                            ...category.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child  }))
                          ]);
                        } else {
                          setCategories3([]);
                        }
                      }}
                    >
                      {categories2.map((category: any, index: number) => (
                        <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
                      ))}
                    </select>
                  ) : <></>}
                  {categories3.length > 0 ? (
                    <select name="" id="" className="form_control fl"
                      value={category3}
                      onChange={(e) => setCategory3(e.target.value)}
                    >
                    {categories3.map((category: any, index: number) => (
                      <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
                    ))}
                    </select>
                  ) : <></>}
                </div>
              </div>
            </div>
            <div className="tr">
              <div className="th">{intl.get('등록일자')}</div>
              <div className="td" style={{overflow: 'visible'}}>
                <div className="group_datepicker">
                  {/* <DatePicker
                    value={created_after}
                    width={250}
                    onChange={(date: string) => onClickDateAfterByCalendar(date)}
                    isPrevMonthView={true}
                    isNextMonthNotView={false}
                    max={getTwoLatersMonthDate(2)[1]}
                  /> */}
                  <CustomCalendar
                   max={getTwoLatersMonthDate(2)[1]}
                    value={created_after}
                    onChange={(date:string) => {onClickDateAfterByCalendar(date)}}
                  ></CustomCalendar>
                  <span>~</span>
                  {/* <DatePicker
                    value={created_before}
                    width={250}
                    onChange={(date: string) => onClickDateBeforeByCalendar(date)}
                    isPrevMonthView={true}
                    isNextMonthNotView={false}
                    max={getTwoLatersMonthDate(2)[1]}
                  /> */}
                  <CustomCalendar
                    max={getTwoLatersMonthDate(2)[1]}
                    value={created_before}
                    onChange={(date:string) => {onClickDateBeforeByCalendar(date)}}
                  ></CustomCalendar>
                </div>
              </div>
            </div>
            <div className="tr">
              <div className="th">검색어</div>
              <div className="td">
                <div className="search_form">
                  <Select
                    className="form_control"
                    options={searchTypes}
                    onChange={(e: any) => onSearchTypeFilter(Number(e.target.value))}
                  />
                  <input
                    type="text"
                    className="form_control"
                    onKeyUp={e => (e.key === 'Enter' ? onSearchHandler() : null)}
                    onChange={(e: any) => setSearch(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn_darkblue btn_search"
                    onClick={onSearchHandler}>
                    {intl.get('검색')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> {/* table_design_list */}
      
      <div className="regist_area">
          <button
            className="btn btn_default btn_outline"
            onClick={() => {
              if(checkInlodgeds.length > 0){
                props.history.push(`/customer/voc/create`);
              }else{
                info('숙소이용 중에만 VoC를 등록 하실 수 있습니다.'); 
              }
             }}>
            {intl.get('등록')}
          </button>
      </div>

      
      {!items.length ? (
        <ul className="lst_notice blank_area">
          <li className="txt_blank">데이터가 없습니다</li>
        </ul>
      ) : (
        <ul className="lst_notice">
            {items.map((item: any, index: number) => {
              const category3 = item.category3 ? `> ${item.category3}` : '';
              const category2 = item.category2 ? `> ${item.category2}` : '';
              const category1 = `${item.category1} ${category2}  ${category3}`;
              let process = '';
              let processCase = 'case2';
              if (item.is_complete) {
                process = '해결완료';
                processCase = 'case3';
              } else {
                if ((item.comments.length % 2) === 1) {
                  process = '처리중';
                  processCase = 'case1';
                } else {
                  process = '신청완료';
                }
              }
              return (
                <li
                  onClick={() =>
                    props.history.push(`/customer/voc/detail`, item)
                  }
                  key={index}
                >
                  <a href="javascript:void(0);" className="box">
                    <span className="state_room case3 nav">{category1}</span>
                    <p className="title">{item.title}</p>
                    <p className="desc"> {parserEscape(item.contents)}</p>
                    {/*<p className="desc" dangerouslySetInnerHTML={{ __html: parserEscape(item.contents)}}></p>*/}
                    <p className="etc">
                      <span className={['state', processCase].join(' ')}>{process}</span>
                      <span className="datetime">{formatByString(item.created_at, 'yyyy.MM.dd HH:mm')}</span>
                    </p>
                  </a>
                </li>
              )
            })}
        </ul>
      )}

      <div className="more_area ac">
        {isNext ? (
          <button className="btn btn_lg btn_outline" onClick={onNextHandler}>
            {intl.get('더보기')} <i className="ico_more"></i>
          </button>
        ) : (
          <></>
        )}
      </div>
    </DefaultTemplete>
  );
};

export default VocPage;
