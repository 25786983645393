import React, { useState, useEffect, useContext } from 'react';
import { MessageContext, MessageImpl } from '../../../routes';
import { isMobile } from 'react-device-detect';
import intl from 'react-intl-universal';

import Button from '../../atoms/button';
import DownloadBtn from '../download/button';

import { core } from '../../../apis';
import xss from 'xss';
import { stubString } from 'lodash';
import { parserEscape } from '../../../utils/commons';
import { SHOW_MODAL } from '../../../store/modules/modal';


interface Detail {
  title: string
  contents: string
  is_visible: boolean
  guide_file: string
  order: number
}

interface Props {
  workSite: number
  roomType: number

  onChangeGuideFile?: Function
  onChangeContent?: Function
}

//숙소이용안내 acordian
const CollapseView = (props: Props) => {
  let [openTabIdx, setOpenTabIdx] = useState<number>(-1);
  let [data, setData] = useState<any>([]);

  const [guideType, setGuideType] = useState<number>();
  const [prevType, setPrevType] = useState<number>();
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  let onClickChangeGuideTypeHandler = (idx: number) => {
      if (idx === prevType){
          setGuideType(-1);
          setPrevType(-1);
      } else{
          setGuideType(idx);
          setPrevType(idx);
      }
  }

  useEffect(() => {
    if (!(props.workSite && props.roomType)) return

    (async () => {
      try {
        let { data: details } = await core.getGuides({
          work_site: props.workSite,
          room_type: props.roomType,
          is_visible: true
        })
        
        let data = details.filter((item: Detail) => item.is_visible)
        data.sort((item1: Detail, item2: Detail) => item1.order - item2.order)
        setData(data)
      }catch ({ status, data }) {
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }
    })()
  }, [props.workSite, props.roomType])

  // 파일의 확장자를 가져오는 함수
  const getFileExtension = (filename: string) => {
    const parts = filename.split('.');
    if (parts.length > 1) {
      return parts.pop()!.toLowerCase(); // 파일 이름이 마침표를 포함하는 경우에만 확장자 추출
    } else {
      return ''; // 마침표가 없는 경우 빈 문자열 반환
    }
  };

  const downloadFile = async (url: string, filename: string) => {
    const token = window.localStorage.getItem('accessToken');
    console.log('url : ' + url);
    console.log('filename : ' + filename);

    if (!url) {
      console.error('url 경로가 없습니다.');
      return;
    }

    if(!filename || filename.length == 0) {
      const name = url.split("/");
      filename = name[name.length - 1];
    }
   
    const unifiedUrl = url.replace(/\\/g, '/');
    const parts = unifiedUrl.split('/');
    const desiredParts = parts.slice(2).join('\\');
    
    // 파일의 확장자가 이미지나 PDF인지 확인하는 함수
    const isImageOrPDF = (extension: string) => {
      const supportedExtensions = ['gif', 'jpg', 'jpeg', 'png', 'pdf'];
      return supportedExtensions.includes(extension);
    };
  
    const fileExtension = getFileExtension(filename);
    const isImageOrPDFFile = isImageOrPDF(fileExtension);
  
    //fileDownload
    try {
      let payload: any = {
        filename: filename,
        url : desiredParts,
      };
      
      let { data } = await core.fileDownload(payload);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      //info("협조전 등록이 완료되었습니다.");
    } catch ({ data, status }) {
      console.log(data, status);
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }
      info("파일 다운로드 중 문제가 발생했습니다");
      return;
    }
  };

  return (
    <dl className="lst_terms">
      {data.map((item: Detail, itemIdx: number) => (
        <>
          <dt
            onClick={() => {openTabIdx === itemIdx ? setOpenTabIdx(-1) : setOpenTabIdx(itemIdx); onClickChangeGuideTypeHandler(0)}}
            className={`${openTabIdx === itemIdx ? 'on' : ''}`}
          >
            <p className="title">{item.title}</p>
            <a href=" javascript:void(0)" className="btn_toggle ir">
              {intl.get('펼치기/접기')}
            </a>
          </dt>
          <dd className={`${openTabIdx === itemIdx ? 'on editor' : 'editor'}`} dangerouslySetInnerHTML={{ __html: parserEscape(item.contents) }} />
          
          <dd className={`${openTabIdx === itemIdx ? 'on' : ''}`} >
            
            <div className="fr">
              {item.guide_file && (
                // <DownloadBtn
                //   value={intl.get("파일 다운로드")}sd
                //   src={`${process.env.REACT_APP_MEDIA}/${item.guide_file}`}
                // />
                <button 
                    className="btn_sm btn_darkblue btn_download"
                    onClick={() => downloadFile(`${process.env.REACT_APP_MEDIA}/${item.guide_file}`, (item.guide_file).split('/')[2])}
                  >
                    파일다운로드
                </button>
                /* 모의해킹 조치
                  <a 
                  href={`${process.env.REACT_APP_MEDIA}/${item.guide_file}`}
                  target={`${item.guide_file}`.split('.').pop() 
                      && ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(`${item.guide_file}`.split('.').pop() || '' )
                      && !isMobile ? '_blank' : ''}
                >
                <button className='btn btn_outline btn_download'>
                  파일다운로드
                </button>
                <span className="ml10">{(item.guide_file).split('/')[2]}</span>
              </a>*/
              )}
            </div>
          </dd>
        </>
      ))}
    </dl>
  );
};

export default CollapseView;
