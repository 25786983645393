import React, { useState, useEffect, useContext } from 'react';
import { MessageContext } from '../../routes';
import DefaultTemplete from '../../components/templetes/default';
import ConfirmBtn from '../../components/atoms/button/confirm';
import { CHECKOUT_STATUS_CODE, ROOM_TYPE } from '../../constant';
import { lodge } from '../../apis/';
import FileForm from '../../components/organismas/form/file';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import { SHOW_MODAL } from '../../store/modules/modal';

const LodgeCheckoutDetailPage = (props: any) => {
  const { info } = useContext(MessageContext);
  // const [checkoutDate, setCheckoutDate] = useState<string>(props.history.location.state.stay_before || getTodayDate()[0]);
  // const [reason, setReason] = useState<string>(props.history.location.state.checkout_reason || '만기');
  // const [reasonMemo, setReasonMemo] = useState<string>('');
  const [claim, setClaim] = useState<string>(props.history.location.state.checkout_reason_detail || '');
  const [files, setFiles] = useState(_.cloneDeep(props.history.location.state.files || []));
  // const [isEquimentOk, setIsEquimentOk] = useState<boolean>(false);
  // const [etcMsg, setEtcMsg] = useState<string>(props.history.location.state.checkout_memo || '');
  // const [files, setFiles] = useState<any>([]);
  const [checkOutData, setCheckOutData] = useState<any>([]);
  const [showEqDesc, setShowEqDesc] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [isImgPop, setIsImgPop] = useState<boolean>(false);
  const [imgPath, setImgPath] = useState<string>('');

  useEffect(() => {
      (async ()=> {
        const data = await fetchData();
        //console.log('CheckOutList : Data ===', data);
        
        if(data) {
            ReactDOM.render( // id="booking" 주석 확인(*필수*)
                <>
                <li><strong>숙소유형</strong><span>{ROOM_TYPE[data.guest.room_type]}</span></li>
                <li><strong>이용사업장</strong><span>{data.guest.booking.applicant_work_site}</span></li>
                <li><strong>입숙일</strong><span>{data.guest.checkin_at}</span></li>
                <li><strong>입숙만료일</strong><span>{data.guest.expired_at}</span></li>
                <li><strong>배방</strong><span>{data.guest.room_building_dong} {data.guest.room_building_name} {data.guest.room_name}</span></li>
                </>
                , document.getElementById("booking"));
    
            ReactDOM.render( // id="booking" 주석 확인(*필수*)
                <>
                <textarea name="" id="" className="form_control" disabled>{data.claim}</textarea>{/* value={claim} onChange={(e: any) => setClaim(e.target.value)} */}
                <span className="size">{data.claim? data.claim.length : 0} byte / 400 byte</span>
                </>
                , document.getElementById("claim"));
    
            ReactDOM.render( // id="imgArea" 주석:  확인(*필수*)
                <>
                {data.guest.checkin_files.map((image: any, index: number) => (
                    <div key={index} className="img" style={{cursor:'pointer'}} onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                    <a download>
                        <img
                        src={`${process.env.REACT_APP_MEDIA}/${image.path}`}
                        alt={image.name}
                        />
                    </a>
                    </div>
                    ))}
                </>
            , document.getElementById("imgArea"));
    
            if(data.files){
                ReactDOM.render( // id="files" 주석:  확인(*필수*)
                    <>
                    <FileForm
                        files={data.files}
                        originFiles={_.cloneDeep(data?.files)}
                        isUpdateMode={false}
                        onChange={(changedFiles: any) => setFiles(changedFiles)}
                    />
                    </>
                , document.getElementById("files"));
            }
    
            ReactDOM.render( // id="isEquipment" 주석:  확인(*필수*)
                <>
                <label className="input_radio" htmlFor="radio_warning1">
                    <input type="radio" id="sample_case2" name="sample" checked={!data.is_equipment_ok}/>
                    <span className="label_text">숙소 상태 및 비품 등에 이상이 없습니다.</span>
                </label>
                <label className="input_radio" htmlFor="radio_warning2">
                    <input type="radio" id="sample_case1" name="sample" checked={data.is_equipment_ok}/>
                    <span className="label_text">숙소 상태 및 비품 등에 이상이 있습니다.</span>
                </label>
                </>
            , document.getElementById("isEquipment"));
    
            // 이상품목 영역 노출여부
            setShowEqDesc(data.is_equipment_ok);
    
            if(data.is_equipment_ok){
                ReactDOM.render( // id="booking" 주석 확인(*필수*)
                <>
                <textarea name="" id="" className="form_control" disabled>{data.equipment_description}</textarea>
                <span className="size">{data.equipment_description? data.equipment_description.length : 0} byte / 400 byte</span>
                </>
                , document.getElementById("equipmentDesc"));
            }
    
            data.checkoutId = data.id;
        }
        // if(!props.history.location.state.checkout_at){
        //     ReactDOM.render( // id="goModify" 주석:  확인(*필수*)
        //         <>
        //         <button type="button" className="btn btn_lg btn_darkblue" onClick={()=> props.history.push(`/mypage/checkout/checkOutDetail`, data)}>수정</button>
        //         </>
        //     , document.getElementById("goModify"));
        // }
      })();
      
    //console.log(props.history.location.state)
  }, [props.history.location.state, reload])

  const backstay = async ()=> {
    try {
      let { data } = await lodge.checkoutDeleteApply(
        props.history.location.state.id
      )
      //console.log(data)
      info('퇴숙신청이 완료되었습니다');
      props.history.push('/mypage/checkout');
    }catch({status, data}){
        
        if (status === 401) {
            window.location.href = '/force/signout';
            return ;
        } else if (status === 403) {
            (window as any).store.dispatch({
                type: SHOW_MODAL,
                payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
                },
            });
            return ;
        }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
        }
      

        info('퇴숙신청 중 문제가 발생했습니다.')
        return;
    }
  };

  const fetchData = async ()=> {
    try {
        let checkOutId = props.history.location.state.id; // id 가져오기
        //console.log(checkOutId);
        const {data} : any = await lodge.getCheckOutDetail(checkOutId);
        setCheckOutData(data);
        setReload(true);
        return data
    }catch ({ status, data }) {
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '문제발생',
              msg: '문제가 발생했습니다',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
    }
  }
  

  return (
    <DefaultTemplete
      id="wrap"
      className="wrap_mypage_manage"
      leftInfo={
        <>
          <h2>퇴숙신청</h2>
          <p className="desc">기숙사, 사택  퇴숙신청 및 현황을 확인하실 수 있습니다</p>
          <div className="bg_img checkout"></div>
        </>
      }
      {...props}
    >
      <div id="contents" className="col col_right">
        <div className="title_contents">
            <h3 className="title">기본 정보</h3>
        </div>
        <ul className="lst_status" id="booking">
            {/** 데이터가 먼저 렌더링 되기 전에 화면이 먼저 뿌려져서 무한루프 및 브라우저가 뻗는 심각한
             * 버그가 발생함 그래서 useEffect 사용될때 그리게 만듬
             */}
        </ul>

        <div className="title_contents">
            <h3 className="title">퇴숙신청 정보입력</h3>
        </div>

        <div className="table_design_write detail col_width2">
            <div id="table" className="table">
                <div className="tbody">
                    <div className="tr">
                        <div className="th"><span className="">퇴숙요청일</span></div>
                        <div className="td">
                          {props.history.location.state.stay_before}
                        </div>
                    </div>
                    <div className="tr">
                        <div className="th"><span className="">퇴숙사유</span></div>
                        <div className="td fwb">
                            <div className="form_cause">
                                <select name="" id="" className="form_control" value={props.history.location.state.reason_select} disabled>
                                    <option value="만기">만기</option>
                                    <option value="결혼">결혼</option>
                                    <option value="퇴사">퇴사</option>
                                    <option value="발령">발령</option>
                                    <option value="본가이사">본가이사</option>
                                    <option value="자가구입">자가구입</option>
                                    <option value="기타">기타</option>
                                </select>
                                {props.history.location.state.reason_select === "기타"? (
                                    <input type="text" 
                                        className="form_control"
                                        value={checkOutData.reason}
                                        disabled
                                    />
                                ) : ''}
                            </div>
                        </div>
                    </div>
                    <div className="tr">
                        <div className="th textarea"><span className="">요청사항 입력</span></div>
                        <div className="td textarea" id="claim">
                          
                        </div>
                    </div>
                    <div className="tr">
                        <div className="th">
                            <span className="">비품 이상유무 체크</span>
                        </div>
                        <div className="td row">
                        <div className="contents_area images">
                            <div className="images_area" id="imgArea">
                                

                            </div>
                            <ul className="lst_warning gray">
                                <li>입숙절차 시 관리자와 같이 확인했던 내용입니다. 이미지를 보시고 현재 이용중인 숙소의 이상 유무를 체크해 주세요.
                                </li>
                            </ul>
                            </div>
                            <div className="radio_area" id="isEquipment">
                                
                            </div>
                        </div>
                    </div>
                    {showEqDesc? (
                        <div className="tr">
                            <div className="th textarea"><span className="">이상 품목 입력</span></div>
                            <div className="td textarea" id="equipmentDesc">
                            
                            </div>
                        </div>
                    ):''}
                    <div id="files">

                    </div>
                </div>
            </div>
        </div> {/* table_design_list */}

        <ul className="lst_help mt15">
            <li>퇴숙요청일에 숙소 상태 및 비품 등 이상유무를 점검합니다. 관리자가 입력한 퇴숙확정일이 퇴숙완료일로 기산됩니다
            </li>
            <li>퇴숙 시, 숙소 상태 및 비품 등에 이상이 있거나 짐이 남아 있으면 원상복구 요청 등을 할 수 있으며 퇴숙 완료 처리가 되지 않습니다</li>
        </ul>

        <div className="mb_view">
            <button className="btn btn_lg btn_gray" onClick={()=>props.history.push('/mypage/checkout')}>목록</button>
        </div>

        <div className="submit pt20">
            <div className="fl">
                <button type="button" className="btn btn_lg btn_gray" onClick={()=>props.history.push('/mypage/checkout')}>목록</button>

            </div>
            {props.history.location.state.status === CHECKOUT_STATUS_CODE.apply ? (
                <>
                {/* <div className="fr" id="goModify">
                    
                </div> */}

                <div className="fr">
                    <button type="button" className="btn btn_lg btn_darkblue" onClick={()=> props.history.push(`/mypage/checkout/checkOutDetail`, checkOutData)}>수정</button>
                    <ConfirmBtn
                    className="btn btn_lg btn_outline"
                    action={() => backstay()}
                    label={"신청취소"}
                    title=""
                    msg={"퇴숙 신청을 취소하시겠습니까?"}
                    />
                    {/* <button type="button" className="btn btn_lg btn_darkblue">수정</button> */}
                </div>
                </>
            ):''}

            {props.history.location.state.status === CHECKOUT_STATUS_CODE.terminate ? (
                <>
                <div className="fr">
                    <button type="button" className="btn btn_lg btn_darkblue" onClick={()=> props.history.push(`/mypage/checkout/checkOutDetail`, checkOutData)}>재요청</button>
                </div>
                </>
            ):''}

        </div>
    </div> 

    {/* 이미지 팝업 */}
    <div id="pop_image" className="pop_dim_wrap pop_modal" style={{display: isImgPop? 'block' : 'none'}}>
        <div className="pop_wrap">
            <div className="title_wrap">
                <a href="javascript:void(0);" className="pop_btn_close" onClick={()=> setIsImgPop(false)}>닫기</a>
            </div>
            <div className="contents_wrap">
                <div className="slide">
                    <p className="img_area">
                        <img src={imgPath} alt=""/>
                    </p>
                </div>
            </div>
        </div>
    </div>
    </DefaultTemplete>
  );
};

export default LodgeCheckoutDetailPage;
