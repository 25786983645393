import React, { useState } from 'react';
import Select from '../../atoms/select';
import intl from 'react-intl-universal';

interface props {
  options: { value: string; label: string }[];
  onSearch: Function;
}

const SearchForm = ({ options, onSearch }: props) => {
  const [option, setOption] = useState<string>(options[0].value);
  const [text, setText] = useState<string>('');

  const onClickHandler = (e: any) => onSearch(option, text);
  const onEnterHandler = (e: any) => {
    if (e.keyCode === 13) {
      onSearch(option, text);
    }
  };
  return (
    <>
      <Select
        className="form_control"
        options={options}
        onChange={(e: any) => setOption(e.target.value)}
      />
      <input
        type="text"
        className="form_control"
        onChange={(e: any) => setText(e.target.value)}
        onKeyDown={onEnterHandler}
      />
      <button
        type="button"
        className="btn btn_darkblue btn_search"
        onClick={onClickHandler}
      >
        {' '}
        {intl.get('검색')}
      </button>
    </>
  );
};

export default SearchForm;
