import React from 'react';
import intl from 'react-intl-universal';

//근무조 select
const SelectParty = (props: any) => {
  return (
    <select {...props}>
      <option value="">{intl.get("근무조를 선택하세요")}</option>
      <option value="A">A조</option>
      <option value="B">B조</option>
      <option value="상시주간">상시주간</option>
    </select>
  );
};

export default SelectParty;
