import React, { useContext, useRef, useState } from 'react';
import { core } from '../../../apis';
import intl from 'react-intl-universal';
import _ from 'lodash';
import { MessageContext, MessageImpl } from '../../../routes';
import { SHOW_MODAL } from '../../../store/modules/modal';

//이미지 업로드
const ImageUpload = (props: any) => {
  const { info, warning, error }: MessageImpl = useContext(MessageContext)
  const [fileList, setFileList] = useState<any[]>(props.items);
  const [maxCount, setMaxCount] = useState<number>(props.maxCount || 3);
  const fileRef = useRef(null);

  const pathString = (fileInfo: any) => {
    const path = fileInfo.path ? fileInfo.path : fileInfo.image;
    return `${process.env.REACT_APP_MEDIA}/${path}`;
  }

  const onFileChange = async (e: any) => {

    // if ((fileList.length + 1) > maxCount) {
    //   error(`최대 ${maxCount}개까지 첨부할 수 있습니다.`);
    //   return;
    // }

    const {
      target: { files },
    } = e;
    const tmpFileList = [...fileList];
    props.items.splice(0, props.items.length);

    const actions: any[] = [];
    files.forEach((file: Blob) => actions.push(addFile(file)));
    try {
      const founds = await Promise.all(actions);

      founds.forEach((found: any) => {
        if (found) {
          tmpFileList.push(found.data);
        }
      });

      setFileList(tmpFileList);
      props.onSetData(tmpFileList);
    }catch ({ status, data }) {
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  };

  const addFile = async (file: Blob) => {
    const form = new FormData();
    form.append('path', file);

    let extList: any = [];
    if (props.type === "image") {
      extList = ['gif', 'jpg', 'jpeg', 'png'];
    } else {
      extList = ['gif', 'jpg', 'jpeg', 'png', 'hwp', 'pdf', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'docx'];
    }

    let fileData: any = file;
    let fileExt = (fileData.name).split('.').pop().toLowerCase();

    if (!extList.includes(fileExt)) {
      let extText = extList.join(', ');
      error(`파일은 ` + extText + ` 만 가능합니다.`);
      return false;
    } else if (file.size >= 10485760) {
      error(`파일 용량은 10MB 이상 첨부할 수 없습니다.`);
      return false;
    } else {
      try {
        (fileRef.current as any).value = null;
        return await core.fileUpload<{
          id?: number;
          path?: string;
          name?: string;
          category?: string | number;
        }>(form);
      } catch (error) {
        //console.log(error);
        const { status, data } = error;

        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
  
      }
    }

  };

  const clicking = () => {
    let totalSize: number = 0;
    const tmpFileList = [...fileList];
    // console.log(process.env.REACT_APP_MEDIA);
    // if (fileList.length > 2) {
    //   error(`최대 ${maxCount}개까지 첨부할 수 있습니다.`);
    //   return;
    // }

    tmpFileList.map((item: any, idx: number) => {
      totalSize += parseInt(item.size);
      if (totalSize > 1024 * 1024 * 10) {
        error(`파일 용량은 10MB 이상 첨부할 수 없습니다.`);
        return;
      }
    });

    (fileRef.current as any).click();
  }

  const deleteFile = (fileInfo: any) => {
    const { id } = fileInfo;
    const founds = fileList.filter(file => file.id !== Number(id));
    setFileList(founds);
    props.onSetData(founds);
    (fileRef.current as any).value = null;
  };

  return (
    <>
      <div className="wrap_file only_btn">
        <input id="input_copy" className="upload_name form_control sr_only" placeholder={intl.get('파일찾기를 클릭하여 파일을 업로드 하세요')} value="" />
        <input ref={fileRef} type="file" multiple={true} accept={"image/*;capture=camera"} id="input_upload_copy" className="upload_hidden" onChange={onFileChange} />
        <label htmlFor="input_upload_copy" className="btn btn_sm btn_darkblue btn_srch_file" >{intl.get('파일찾기')}</label>
      </div>
      {fileList.length > 0 ? (
        <div className="contents_area images">
          <div className="images_area">
            {fileList.map((fileInfo: any, index: number) => (
              <div className="img" key={index}>
                <img src={pathString(fileInfo)} alt="미리보기" />
                <a href="javascript:void (0);" className="btn_del" onClick={() => deleteFile(fileInfo)}>{intl.get('삭제')}</a>
              </div>
            ))}
          </div>
        </div>
      ) : (<></>)}
    </>
  );
};

export default ImageUpload;
