import React from 'react';
import './index.scss';
import Header from '../../components/organismas/header';
import Footer from '../../components/organismas/footer';

const ServerErrorPage = (props: any) => {
  return (
    <div id="wrap" className="wrap_error">
      <Header {...props} isApiDisabled={true} />
      <div id="container">
        <div className="row clearfix">
          <div className="col col_left group_title">
            <h2>오류안내</h2>
            <p className="desc">
              빠른 숙소 신청 서비스로 간편하게 숙소를 이용해보세요.
            </p>
            <div className="bg_img"></div>
          </div>
          <div id="contents" className="col col_right">
            <dl className="lst_complete">
              <dt>
                죄송합니다
                <br />
                기술적인 문제로 서비스 접속이 되지 않습니다
              </dt>
              <dd></dd>
            </dl>

            <div className="lst_msg">
              일시적인 현상으로 잠시 후 다시 이용해 보시면 정상 접속할 수
              있습니다.
              <br />
              {/* 해당 내용을 담당자가 확인 중에 있습니다. 문제가 지속되는 경우에는 My Page &gt; VoC로<br /> */}
              알려주시면 조속히 문제해결이 될 수 있도록 하겠습니다
              <br />
              이용에 불편을 드려 다시 한번 사과드립니다.
            </div>

            <div className="submit">
              <button
                className="btn btn_lg btn_darkblue btn_outline"
                onClick={() => props.history.push('/home')}
              >
                Home
              </button>
              {/* <button className="btn btn_lg btn_darkblue">VoC 바로가기</button> */}
            </div>
          </div>
          {/*#contents*/}
        </div>
      </div>
      {/*container*/}
      <Footer />
    </div>
  );
};

export default ServerErrorPage;
