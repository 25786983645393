const c = {
  local_mode: {},
  prod: {
    IP: process.env.REACT_APP_IP,
  },
  dev: {
    IP: process.env.REACT_APP_IP,
  },
};

export default c;
