import React, { useState, useEffect, useContext } from 'react';
import { isMobile } from 'react-device-detect';

import { onOff, core, pledge } from '../../../apis';
import _ from 'lodash';

import intl from 'react-intl-universal';
import { SHOW_MODAL } from '../../../store/modules/modal';
import { MessageContext, MessageImpl } from '../../../routes';

export interface Document {
  id: number
  name: string
  is_required: true
  visible_type: number
}

export interface File {
  id: number
  path: string  // 실제 서버경로
  size: string
  name: string  // 업로드 했을 때 파일명
}

export interface FlatFile {
  document: number
  id: number
  is_required: true
  name: string
  title: string
  visible_type: number
}

export interface Props {
  category: number
  
  workSiteName?: string
  workSite?: number
  guestType: number
  roomType: number
  visibleType: number

  onChange: Function
  value: FlatFile[]
  alreadyFiles: {
    file: File,
    document?: Document
  }[]
  isSupported: boolean    // 주거지원금 수혜 여부
  isDocumentPop?: boolean
}


const CompanyUploadFileList = (props: Props) => {
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const [files, setFiles] = useState<FlatFile[]>([]);
  const [pledgeFile, setPledgeFile] = useState<string>("");

  useEffect(() => {
    if(!props.isDocumentPop) return;
    if ((props.workSite && props.workSite < 0) || props.guestType < 0 || props.roomType < 0) return

    let payload: any = {
      guest_type: props.guestType,
      room_type: props.roomType,
      visible_type: props.visibleType
    };
      
    if (props.workSite) payload.work_site = props.workSite;
    if (props.workSiteName) payload.work_site_name = props.workSiteName;

    (async () => {
      try {
        let { data } = await onOff.getDocument(payload)
        
        let files = []
        files = data.map((document: Document) => {
          return props.alreadyFiles.reduce((acc: any, curVal: any, idx: number) => { 
            // 업로드 했던 파일 추가하기
            if (
              curVal.document?.id === document.id 
            ) {
              acc.document = document.id
              acc.title = document.name
              acc.name = curVal.file.name
              acc.id = curVal.file.id
              acc.is_required = document.is_required
              acc.visible_type = document.visible_type
            }
            
            return acc
          }, {
            document: document.id,
            title: document.name,
            id: -1,
            is_required: document.is_required,
            name: '',
            visible_type: document.visible_type
          })
        }).filter(
          (file: { visible_type: number }) => file.visible_type === props.category
        )

        if(!props.isSupported){
          let tempFileList:FlatFile[] = [];
          tempFileList = files.filter((file: any) => file.title !== "주거지원금 반환 증빙서류" );
          setFiles(tempFileList);
        }else {
          files.filter((file: any, index: number) => {
            if(file.title === "주거지원금 반환 증빙서류" ) {file.is_required = true;}
          });
          setFiles(files);
        }

        let { data: pledgeData } = await pledge.getPledges(payload);
        
        if (pledgeData.length) {
          setPledgeFile(pledgeData[0].pledge_file);
        }
      }catch ({ status, data }) {
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }
    })()
  }, [props.workSiteName, props.workSite, props.guestType, props.roomType, props.isSupported]);


  useEffect(() => {
    // console.log(files.filter((file: FlatFile) => file?.document))
    props.onChange(
      files.filter((file: FlatFile) => file?.document)
    );
  }, [files])

  const onUploadHandler = async (e: any, fileIdx: number) => {
    let tempFiles = _.cloneDeep(files);
    let frm = new FormData();

    frm.append("path", e.target.files[0]);

    let extList = ['gif', 'jpg', 'jpeg', 'png', 'hwp', 'pdf', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'docx'];

    let fileData: any = e.target.files[0];
    let fileExt = (fileData.name).split('.').pop().toLowerCase();

    if(!extList.includes(fileExt)){
      let extText = extList.join(', ');
      error(`파일은 `+extText+` 만 가능합니다.`);
      return false;
    }else if (e.target.files[0].size >= 10485760) {      
      error(`파일 용량은 10MB 이상 첨부할 수 없습니다.`);
      return false;
    }

    try {
      let { data } = await core.fileUpload<FlatFile>(frm);
      tempFiles[fileIdx].id = data.id
      tempFiles[fileIdx].name = data.name
      setFiles(tempFiles);
    } catch (err) {
      //console.log(err)

      const { status, data } = err;

      // console.log(status, data)

      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;

    }
  }

  if(!props.isDocumentPop) return <></>;

  // 파일의 확장자를 가져오는 함수
  const getFileExtension = (filename: string) => {
    const parts = filename.split('.');
    if (parts.length > 1) {
      return parts.pop()!.toLowerCase(); // 파일 이름이 마침표를 포함하는 경우에만 확장자 추출
    } else {
      return ''; // 마침표가 없는 경우 빈 문자열 반환
    }
  };

  const downloadFile = async (url: string, filename: string) => {
    const token = window.localStorage.getItem('accessToken');
    console.log('url : ' + url);
    console.log('filename : ' + filename);
  
    if (!url) {
      console.error('url 경로가 없습니다.');
      return;
    }
  
    if(!filename || filename.length == 0) {
      const name = url.split("/");
      filename = name[name.length - 1];
    }
   
    const unifiedUrl = url.replace(/\\/g, '/');
    const parts = unifiedUrl.split('/');
    const desiredParts = parts.slice(2).join('\\');
    
    // 파일의 확장자가 이미지나 PDF인지 확인하는 함수
    const isImageOrPDF = (extension: string) => {
      const supportedExtensions = ['gif', 'jpg', 'jpeg', 'png', 'pdf'];
      return supportedExtensions.includes(extension);
    };
  
    const fileExtension = getFileExtension(filename);
    const isImageOrPDFFile = isImageOrPDF(fileExtension);
  
    //fileDownload
    try {
      let payload: any = {
        filename: filename,
        url : desiredParts,
      };
      
      let { data } = await core.fileDownload(payload);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      //info("협조전 등록이 완료되었습니다.");
    } catch ({ data, status }) {
      console.log(data, status);
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }
      info("파일 다운로드 중 문제가 발생했습니다");
      return;
    }
  };
  return (
    <>
      {files.map((file: any, fileIdx: number) => 
        // !props.isSupported && file.title === "주거지원금 반환 증빙서류"? '' : (
          <div className="tr" key={fileIdx}>
            <div className="th"><span className="">{file.title}{file.is_required ? ' (필수)' : ''}</span></div>
            <div className="td">
              <div className="wrap_file">
                <input id="input_copy" className="upload_name form_control" placeholder={intl.get("파일찾기를 클릭하여 파일을 업로드 하세요")} value={file.name} disabled />
                <input
                  type="file"
                  id={`input_upload_copy_${fileIdx}`}
                  className="upload_hidden"
                  onClick={(e: any) => e.target.value = null}
                  onChange={(e: any) => onUploadHandler(e, fileIdx)}
                />
                <label htmlFor={`input_upload_copy_${fileIdx}`} className="btn btn_gray btn_srch_file">{intl.get("파일찾기")}</label>
              </div>
            </div>
          </div>
        // )
      )}
      {pledgeFile? (
        <div className="tr">
          <div className="th"><span>서약서 양식</span></div>
          <div className="td">
              <div className="download_area">
                  <p className="txt_guide">입숙서약서 양식을 클릭하여 다운받아 작성 및 서명날인 하신 후,<br/>파일을 업로드 하세요</p>
                  <button className="btn_sm btn_darkblue btn_download"
                  onClick={() => downloadFile(`${process.env.REACT_APP_MEDIA}/${pledgeFile}`, '')}
                  >
                    입숙 서약서 양식
                    </button>
                  {/*<a 
                    href={`${process.env.REACT_APP_MEDIA}/${pledgeFile}`} download
                    target={`${pledgeFile}`.split('.').pop() 
                        && ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(`${pledgeFile}`.split('.').pop() || '' )
                        && !isMobile ? '_blank' : ''}
                  >
                    <button type="button" className="btn btn_sm btn_outline">입숙 서약서 양식</button>
                  </a>*/}
              </div>
          </div>
        </div>
      ) : ''}
    </>
  );
};

export default CompanyUploadFileList;
