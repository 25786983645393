import React from 'react';

import { formatByString_YYYYMMDD } from '../../../utils/date';
import { BOOKING_STATUS, BOOKING_STATUS_CODE, LODGE_STATUS, LODGE_STATUS_CODE, ROOM_TYPE, ROOM_TYPE_CODE, STATUS, WORK_SITE } from '../../../constant';

const ApplicantForm = (props: any) => {

  return (
    <>
      <ul className="lst_thumb_room">
        <li>
          <strong>신청일자</strong>
          <span>{props.applicant.created_at ? formatByString_YYYYMMDD(props.applicant.created_at): ''}</span>
        </li>
        <li>
          <strong>숙소유형</strong>
          <span>{ROOM_TYPE[props.applicant.room_type]}</span>
        </li>
        <li>
          <strong>신청지역</strong>
          <span>{WORK_SITE[props.applicant.room_work_site]}</span>
        </li>
        <li>
          <strong>진행상태정보</strong>
          <span>
              {props.applicant.guests && props.applicant.guests.length > 0 && props.applicant.status === BOOKING_STATUS_CODE.approved ? 
                props.applicant.guests[0].status === LODGE_STATUS_CODE.waiting ?
                (
                <>
                {BOOKING_STATUS[props.applicant.status]}
                </> 
                )
                : props.applicant.guests[0].status === LODGE_STATUS_CODE.opened ?
                  props.applicant.room_type !== ROOM_TYPE_CODE.business_room_group?
                      ("최종승인대기") 
                      : ("서류승인완료") 
                  : (
                      LODGE_STATUS[props.applicant.guests[0].status]
                    )
              :(
                props.applicant.guests && props.applicant.guests.length > 0 &&
                props.applicant.room_type === ROOM_TYPE_CODE.company_room && props.applicant.status === BOOKING_STATUS_CODE.documentApproved?
                  ("승인완료")
                : props.applicant.guests && props.applicant.guests.length > 0 ? (BOOKING_STATUS[props.applicant.status])
                : ''
              )}
          </span>
        </li>
      </ul>

      <div className="title_contents">
        <h3 className="title">신청자</h3>
      </div>

      <div className="table_design_list col_width6">
        <div id="table" className="table">
          <div className="tbody">
            <div className="tr">
              <div className="th">이름</div>
              <div className="td">{props.applicant.applicant_name}</div>
              <div className="th">성별</div>
              <div className="td">{props.applicant.applicant_gender === 1 ? '남' : '여'}</div>
              <div className="th">생년월일</div>
              <div className="td">{props.applicant.applicant_birthdate}</div>
            </div>
            <div className="tr">
              <div className="th">사번 (아이디)</div>
              <div className="td">{props.applicant.applicant_employee_number}</div>
              <div className="th">근무지명</div>
              <div className="td">{props.applicant.applicant_work_site}</div>
              <div className="th">부서</div>
              <div className="td">{props.applicant.applicant_department}</div>
            </div>
            <div className="tr">
              <div className="th">직책</div>
              <div className="td">{props.applicant.applicant_position}</div>
              <div className="th">직종 / 근무조</div>
              <div className="td">
                <ul className="desc_area">
                  <li>{props.applicant.applicant_occupation}</li>
                  <li>{props.applicant.applicant_party}조</li>
                </ul>
              </div>
              <div className="th">결혼유무</div>
              <div className="td">{props.applicant.applicant_is_married ? '유' : '무'}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicantForm;
