import React from 'react';

import './layout.scss';

// 시설배치도 팝업(모바일)

const Layout = (props: any) => {
  return (
    <div
      id="pop_view_installation"
      className="pop_dim_wrap pop_modal pop_md"
      style={{ display: props.isShow ? 'block' : 'none' }}
    >
      <div className="pop_wrap">
        <div className="title_wrap">
          <h3>시설 배치도</h3>
          <button className="pop_btn_close pop_close" onClick={props.onClose}>
            닫기
          </button>
        </div>
        <div className="contents_wrap">
          <div className="plan_area">
            {/*출장자 숙소*/}
            {props.roomType === 1 && (
              <img src="/static/img/pc/location_type3.jpg" alt="" />
            )}
            {/*영빈관*/}
            {props.roomType === 2 && (
              <img src="/static/img/pc/location_type4.jpg" alt="" />
            )}
          </div>
        </div>
        <div className="button_wrap btn_length_1">
          <button
            type="button"
            className="btn btn_xlg btn_darkblue pop_close"
            onClick={props.onClose}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default Layout;
