import { observer } from 'mobx-react';
import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { auth, lodge } from '../../apis';
import ConfirmBtn from '../../components/atoms/button/confirm';
import DefaultTemplete from '../../components/templetes/default';
import { LODGE_MOVE_CODE, MOVE_STATUS_CODE, ROOM_TYPE, ROOM_TYPE_CODE } from '../../constant';
import { MessageContext } from '../../routes';
import { useStores } from '../../store/mobx';
import ReactDOM from 'react-dom';
import { SHOW_MODAL } from '../../store/modules/modal';

const LodgeMoveReqDetail = observer((props: any) => {
  const {lodgedStore: store} = useStores();
  const { info } = useContext(MessageContext);
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user,
  }));
  const [deTailData, setDeTailData] = useState<any>([]);
  const [employee, setEmployee] = useState<any>([]);
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
        store._category = LODGE_MOVE_CODE.one;

        await fetchData();

        // 내가 신청자라면 응답자 정보 가져오기
        if(deTailData.employee_number1 === userInfo.employeenumber){
            //await getEmployeeInfo();
        }else if(deTailData.employee_number2 === userInfo.employeenumber){
            
        }
    })();
  }, [reload]);

  // 상세 데이터
  const fetchData = async () => {
    try {
      let guestId = props.history.location.state.id //guestId 가져오기
      const { data }: any = await lodge.getOneToOneMoveDetail(guestId);
      setDeTailData(data[0]);

      // 수정 데이터 전달
      let emp: any = [];
      emp.guest_name = data[0].guest_name;
      emp.guest_gender = data[0].guest_gender;
      emp.guest_department = data[0].guest_department;
      emp.guest_cellphone = data[0].guest_cellphone;
      emp.room_work_site = data[0].work_site;
      setEmployee(emp);

      setReload(true);

      ReactDOM.render( 
      <>
        <textarea name="" id="" className="form_control" disabled>{data[0].claim? data[0].claim :''}</textarea>
        <span className="size">{data[0].claim? data[0].claim.length : 0} byte / 400 byte</span>
      </>
      , document.getElementById("claim"));
    } catch (error) {
      const {status, data} = error;

      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;

    }
  }

  // 신청 취소
  const moveCancel = async() => {
    try {
        const payload: any = {
            status : MOVE_STATUS_CODE.cancel
        }
        await lodge.lodgeMoveChangeStatus(deTailData.id, payload);
        info("신청이 취소되었습니다.");
        props.history.push('/mypage/move');
        setReload(!reload);
    } catch (error) {
        //console.log(error);

        const { status, data } = error;
        
        if (status === 401) {
            window.location.href = '/force/signout';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }
    

        info('신청 취소 중 문제가 발생하였습니다.');
        return;
    }
  }

  return (
    <DefaultTemplete
      leftInfo={
        <>
          <h2>이숙관리</h2>
          <p className="desc">기숙사 이숙신청 및 현황을 확인하실 수 있습니다</p>
          <div className="bg_img change"></div>
        </>
      }
      id="wrap"
      className="wrap_mypage_manage"
      {...props}
    >
        <div className="title_contents">
            <h3 className="title">기본 정보</h3>
        </div>
        <ul className="lst_status">
          <li><strong>숙소유형</strong><span>{ROOM_TYPE[ROOM_TYPE_CODE.dormitory_room]}</span></li>
          <li><strong>이용사업장</strong><span>{deTailData.work_site}</span></li>
          {deTailData.employee_number1 === userInfo.employeenumber?(    // 시점 : 신청자
                <>
                <li><strong>입숙일</strong><span>{deTailData.managed_at1}</span></li>
                <li><strong>입숙만료일</strong><span>{deTailData.expired_at1}</span></li>
                <li><strong>배방</strong><span>{deTailData.building_name} {deTailData.dong} {deTailData.ho}호</span></li>
                </>
          ):(   // 시점 : 응답자
                <>
                <li><strong>입숙일</strong><span>{deTailData.managed_at2}</span></li>
                <li><strong>입숙만료일</strong><span>{deTailData.expired_at2}</span></li>
                <li><strong>배방</strong><span>{deTailData.guest_building_name} {deTailData.guest_building_dong} {deTailData.guest_room_name}호</span></li>
                </>
          )}
        </ul>

        <div className="title_contents">
            <h3 className="title">이숙신청 상대 정보</h3>
        </div>

        {deTailData.employee_number1 === userInfo.employeenumber?(    // 시점 : 신청자
            <>
                <div className="table_design_list row">
                    <dl className="col">
                        <dt>사번 (아이디)</dt>
                        <dd>{deTailData.employee_number2}</dd>
                    </dl>
                    <dl className="col">
                        <dt>성명</dt>
                        <dd>{deTailData.guest_name}</dd>
                    </dl>
                    <dl className="col">
                        <dt>성별</dt>
                        <dd>{deTailData.guest_gender === "1"? '남' : '여'}</dd>
                    </dl>
                    <dl className="col">
                        <dt>소속 부서</dt>
                        <dd>{deTailData.guest_department}</dd>
                    </dl>
                    <dl className="col">
                        <dt>휴대폰 번호</dt>
                        <dd>{deTailData.guest_cellphone}</dd>
                    </dl>
                </div>

                <div className="table_design_list row mt10">
                    <dl className="col">
                        <dt>숙소유형</dt>
                        <dd>{ROOM_TYPE[ROOM_TYPE_CODE.dormitory_room]}</dd>
                    </dl>
                    <dl className="col">
                        <dt>이용사업장</dt>
                        <dd>{deTailData.work_site}</dd>
                    </dl>
                    <dl className="col">
                        <dt>입숙일</dt>
                        <dd className="linethrough">-</dd>
                    </dl>
                    <dl className="col">
                        <dt>입숙만료일</dt>
                        <dd className="linethrough">-</dd>
                    </dl>
                    <dl className="col">
                        <dt>배방</dt>
                        <dd className="">
                            {deTailData.status === MOVE_STATUS_CODE.complete? (
                                deTailData.guest_building_name + deTailData.guest_building_dong + deTailData.guest_room_name + '호'
                            ) :'-' }
                        </dd>
                    </dl>
                </div>
            </>
        ) :(    // 시점: 응답자
            <>
                <div className="table_design_list row">
                    <dl className="col">
                        <dt>사번 (아이디)</dt>
                        <dd>{deTailData.employee_number1}</dd>
                    </dl>
                    <dl className="col">
                        <dt>성명</dt>
                        <dd>{deTailData.name}</dd>
                    </dl>
                    <dl className="col">
                        <dt>성별</dt>
                        <dd>{parseInt(deTailData.gender) === 1? '남' : '여'}</dd>
                    </dl>
                    <dl className="col">
                        <dt>소속 부서</dt>
                        <dd>{deTailData.department}</dd>
                    </dl>
                    <dl className="col">
                        <dt>휴대폰 번호</dt>
                        <dd>{deTailData.cellphone}</dd>
                    </dl>
                </div>

                <div className="table_design_list row mt10">
                    <dl className="col">
                        <dt>숙소유형</dt>
                        <dd>{ROOM_TYPE[ROOM_TYPE_CODE.dormitory_room]}</dd>
                    </dl>
                    <dl className="col">
                        <dt>이용사업장</dt>
                        <dd>{deTailData.work_site}</dd>
                    </dl>
                    <dl className="col">
                        <dt>입숙일</dt>
                        <dd className="linethrough">-</dd>
                    </dl>
                    <dl className="col">
                        <dt>입숙만료일</dt>
                        <dd className="linethrough">-</dd>
                    </dl>
                    <dl className="col">
                        <dt>배방</dt>
                        <dd className="">
                            {deTailData.building_name} {deTailData.dong} {deTailData.ho} 호
                        </dd>
                    </dl>
                </div>
            </>
        )}

        <div className="title_contents">
            <h3 className="title">이숙신청 정보입력</h3>
            {/*<span className="info_state"><span className="state_room case2">대기5번</span> 입니다</span>*/}
        </div>

        <div className="table_design_write col_width2 detail">
            <div id="table" className="table">
                <div className="tbody">
                    <div className="tr">
                        <div className="th"><span>기존 근무조</span></div>
                        <div className="td">{deTailData.original_party}</div>
                    </div>
                    <div className="tr">
                        <div className="th"><span className="">실제근무조</span></div>
                        <div className="td">
                            <div className="check_area">
                                <label className="input_radio">
                                    <input type="radio" id="sample_case1" name="sample" checked={deTailData.original_party === deTailData.party} disabled /><span className="label_text">동일</span>
                                </label>
                                <label className="input_radio">
                                    <input type="radio" id="sample_case2" name="sample" checked={deTailData.original_party !== deTailData.party} disabled /><span className="label_text">다름</span>
                                </label>

                                <p id="" className="txt_etc">
                                    {deTailData.party}
                                </p>
                            </div>

                        </div>
                    </div>
                    <div className="tr">
                        <div className="th"><span>이숙요청일</span></div>
                        <div className="td">{deTailData.moved_at}</div>
                    </div>
                    <div className="tr mix_width2">
                        <div className="th textarea"><span>요청사항 입력</span></div>
                        <div className="td textarea" id="claim">
                            
                            <textarea name="" id="" className="form_control" disabled>{deTailData.claim? deTailData.claim :''}</textarea>
                            <span className="size">{deTailData.claim? deTailData.claim.length : 0} byte / 400 byte</span>
                        </div>
                    </div>
                </div>
            </div>
        </div> {/* table_design_list */}

        <div className="mb_view">
            <button className="btn btn_lg btn_gray" onClick={() => {props.history.location.state.category = LODGE_MOVE_CODE.one; props.history.push('/mypage/move')}}>목록</button>
        </div>

        <div className="submit pt20">
            <div className="fl">
                <button className="btn btn_lg btn_gray" onClick={() => {props.history.location.state.category = LODGE_MOVE_CODE.one; props.history.push('/mypage/move')}}>목록</button>
            </div>
            {deTailData.employee_number1 === userInfo.employeenumber && deTailData.status === MOVE_STATUS_CODE.apply? (
                <div className="fr">
                    <ConfirmBtn
                        className="btn btn_lg btn_outline"
                        action={() => moveCancel()}
                        label={"신청취소"}
                        title=""
                        msg={"이숙신청을 취소하시겠습니까?"}
                    />
                    <button className="btn btn_lg btn_darkblue"
                        onClick={() => {deTailData.moveId = deTailData.id; deTailData.employee = employee; props.history.push('/mypage/move/reqapply', deTailData)}}    
                    >수정</button>
                </div>
            ) : ''}
        </div>
    </DefaultTemplete>
  );
});

export default React.memo(LodgeMoveReqDetail);
