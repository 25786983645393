import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import intl from 'react-intl-universal';
import FileUpload from '../../../components/molecules/fileUpload';
import DefaultTemplete from '../../../components/templetes/default';
import { formatByString } from '../../../utils/date';
import { audit as api, core } from '../../../apis';
import { MessageImpl, MessageContext } from '../../../routes';
import { SHOW_CONFIRM_MODAL, SHOW_MODAL } from '../../../store/modules/modal';
import { EXHIBIT_STATUS } from '../../../constant';
import xss from 'xss';
import ConfirmBtn from '../../../components/atoms/button/confirm';
import { parserEscape } from '../../../utils/commons';
import FileSaver from 'file-saver';

const AuditDetailPage = (props: any) => {
  const dispatch = useDispatch();
  const item: any = props.history.location.state;
  const [orgFiles, setOrgFiles] = useState<string>('');
  const [files, setFiles] = useState<any[]>(item.files || []);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const [isImgPop, setIsImgPop] = useState<boolean>(false);
  const [imgPath, setImgPath] = useState<string>('');

  useEffect(() => {
    setOrgFiles((item.files || []).map((file: any) => file.name).join('') || '')
  }, []);

  const currentData = () => (files || []).map((file: any) => file.name).join('') || '';

  const saveHandler = () => {
    // if (orgFiles === currentData()) {
    //   info('변경된 항목이 없습니다.');
    //   return;
    // }
    // console.log('testing...')
    
    if(!files || files.length <=0) {
      info('파일을 등록해주세요');
      return;
    }

    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        title: '',
        msg: intl.get('작성하신 내용을 등록하시겠습니까?'),
        redirectPath: false,
        statusCode: false,
        errorCode: '',
        type: 'info',
        isModalShow: true,
        action: async () => {
          try {
            await api.attachAudit(item.id, { files: files.map(file => file.id) });
            info('등록 완료했습니다.');
            props.history.push(`/customer/audit`);
          } catch ({ status, data }) {
            if (status === 401) {
              window.location.href = '/force/signout';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }

            (window as any).store.dispatch({
              type: SHOW_MODAL,
              payload: {
                title: '문제발생',
                msg: '문제가 발생했습니다',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }
        }
      },
    });
  }

  return (
    <DefaultTemplete
      leftInfo={
        <>
          <h2>{intl.get('정기서류검사')}</h2>
          <p className="desc">
            {intl.get('정기서류검사를 위한 안내 및 서류 등록을 하실 수 있습니다')}
          </p>
          <div className="bg_img doc"></div>
        </>
      }
      id="wrap"
      className="wrap_customer"
      {...props}
    >
      <div className="table_design_write detail col_width4 mt0">
        <div id="table" className="table">
          <div className="tbody">
            <div className="tr">
              <div className="th">
                <span className="">{intl.get('진행상황')}</span>
              </div>
              <div className="td">{EXHIBIT_STATUS[item.status]}</div>

              <div className="th">
                <span className="">{intl.get('작성일시')}</span>
              </div>
              <div className="td">
                {formatByString(item.audit.created_at, 'yyyy.MM.dd HH:mm')}
              </div>
            </div>

            <div className="tr">
              <div className="th">
                <span className="">{intl.get('제목')}</span>
              </div>
              <div className="td">{item.audit.title}</div>

              <div className="th">
                <span className="">{intl.get('작성자')}</span>
              </div>
              <div className="td">{item.audit.author_name}</div>
            </div>

            <div className="tr mix_width2 mb_w100">
              <div className="th">
                <span className="">{intl.get('내용')}</span>
              </div>
              <div className="td">
                <p
                  id="editor"
                  className="contents_area"
                  dangerouslySetInnerHTML={{ __html: parserEscape(item.audit.contents) }}
                ></p>
              </div>
            </div>
            {item.status === 4? (
              <div className="tr mix_width2 mb_w100">
                <div className="th"><span className="">반려사유</span></div>
                <div className="td">
                  {item.description}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="tr mix_width2 mb_w100">
              <div className="th">
                <span className="">{intl.get('사진첨부')}</span>
              </div>
              <div className="td">
                <p className="contents_area images">
                  <div className="images_area">
                    {item.audit.images.map((image: any, index: number) => (
                      <div key={index} className="img" style={{cursor:'pointer'}} onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                        <a className="">
                          <img
                            src={`${process.env.REACT_APP_MEDIA}/${image.path}`}
                            alt={image.name}
                          />
                        </a>
                      </div>
                    ))}
                  </div>
                </p>
              </div>
            </div>

            <div className="tr mix_width2 mb_w100">
              <div className="th">
                <span className="">{intl.get('파일첨부')}</span>
              </div>
              <div className="td">
                {item.audit.files.map((file: any, index: number) => (
                  <a
                    key={index}
                    href={`${process.env.REACT_APP_MEDIA}/${file.path}`}
                    target={`${file.path}`.split('.').pop() 
                        && ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(`${file.path}`.split('.').pop() || '' )
                        && !isMobile ? '_blank' : ''}
                    className="item file"
                  >
                    {file.name}
                  </a>
                ))}
              </div>
            </div>
            
            <div className="tr mix_width2 mb_w100">
              <div className="th"><span className="">첨부서류</span></div>
              <div className="td">
                {item.status !== 3?(
                  <FileUpload fileId="attacheFile" items={files} onSetData={(data: any[]) => setFiles([...data])} />
                ):(
                  <>
                  {files? files.map((file: any, index: number) => (
                   <a
                    key={index}
                    href={`${process.env.REACT_APP_MEDIA}/${file.path}`}
                    target={`${file.path}`.split('.').pop() 
                        && ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(`${file.path}`.split('.').pop() || '' )
                        && !isMobile ? '_blank' : ''}
                    className="item file"
                  >
                    {file.name}
                  </a>
                  )) :''}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>{' '}
      {/* table_design_list */}
      <div className="mb_view">
        <button type="button" className="btn btn_lg btn_gray" onClick={() => props.history.push('/customer/audit')}>
          {intl.get('목록')}
        </button>
      </div>
      <div className="submit pt20">
        <div className="fl">
          <button
            type="button"
            className="btn btn_lg btn_gray"
            onClick={() => props.history.push('/customer/audit')}
          >
            {intl.get('목록')}
          </button>
        </div>

        <div className="fr">
          {item.status !== 3? (
            <>
              <ConfirmBtn
                action={() => props.history.push('/customer/audit')}
                label={`취소`}
                title=""
                msg={`취소하시겠습니까?`}
                className="btn btn_lg btn_outline"
              />
              {/* <button type="button" className="btn btn_lg btn_outline">{intl.get('취소')}</button> */}
              {/* <button type="button" className="btn btn_lg btn_outline">{intl.get('초기화')}</button>
              <button type="button" className="btn btn_lg btn_outline">{intl.get('반려사유')}</button> */}
              <button type="button" className="btn btn_lg btn_darkblue" onClick={saveHandler}>{intl.get('저장')}</button>
            </>
          ) :''}
        </div>
      </div>

      {/* 이미지 팝업 */}
      <div id="pop_image" className="pop_dim_wrap pop_modal" style={{display: isImgPop? 'block' : 'none'}}>
          <div className="pop_wrap">
              <div className="title_wrap">
                <a href="javascript:void(0);" className="pop_btn_close" onClick={()=> setIsImgPop(false)}>닫기</a>
              </div>
              <div className="contents_wrap">
                  <div className="slide">
                      <p className="img_area">
                          <img src={imgPath} alt=""/>
                      </p>
                  </div>
              </div>
          </div>
      </div>
    </DefaultTemplete>
  );
};

export default AuditDetailPage;
