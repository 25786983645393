import { VERIFY_CODE } from '../../constant';

enum _VERIFY_CODE_TYPE {
  phone = VERIFY_CODE.phone,
  email = VERIFY_CODE.email,
}

export const VERIFY_STATUS_CODE: { [key: string]: number } = {
  default: 0,
  request: 1,
  complete: 2,
};

export interface SignupForm {
  employeeNumber: string;
  password: string;
  password2: string;
  phone: string;
  emailFront: string;
  emailBack: string;
  privacyPolicyAgreed: boolean;
  verifyType: number;
}

export interface MustAgreed {
  termAgreed: boolean;
  privacyAgreed: boolean;
  // privacyUseAgreed: boolean;
}

export interface Valid {
  isValid: boolean;
  msg: string;
}
