import React, { useState, useEffect, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { MessageContext, MessageImpl } from '../../routes';
import ConfirmBtn from '../../components/atoms/button/confirm';
import LimitSelect from '../../components/atoms/select/limit';
import RoomType from '../../components/molecules/tab/roomType';
import Pagination from '../../components/organismas/pagination';
import MoreBtn from '../../components/organismas/pagination/more';
import SearchForm from '../../components/organismas/form/search';
import DefaultTemplete from '../../components/templetes/listDefault';

import { core, lodge } from '../../apis';
import { ROOM_TYPE, ROOM_TYPE_CODE, STATUS_CODE, WORK_SITE, BOOKING_STATUS, BOOKING_STATUS_CODE, LODGE_STATUS_CODE, LODGE_STATUS, FILE_CODE, FILE_UPLOAD_CODE, DOCUMENT_FILE_VISIBLE_TYPE_CODE, DELAY_COUNT_CODE } from '../../constant';
import { formatByString_YYYYMMDD, getPrevDay, getTodayDate } from '../../utils/date';
import './index.scss';
import Calendar from '../../components/molecules/calendar';
import LodgeBackroomPopup from '../../components/organismas/popup/lodgeBackroomPopup';
import BusinessGroupbackroom from '../../components/organismas/popup/businessGroupbackroom';
import BusinessGroupbackroomConfirm from '../../components/organismas/popup/businessGroupbackroomConfirm';
import CompanyUploadFileList from '../../components/organismas/companyUploadFileList';
import { getWorkSitePhoneNum } from '../../utils/commons';
import { SHOW_MODAL } from '../../store/modules/modal';
import FileSaver from 'file-saver';

const LodgeAccplicantPage = (props: any) => {
  const { info } = useContext(MessageContext);

  const { workplaceMap = {} } = useSelector((state: any) => ({
    workplaceMap: state.base.workplaceMap
  }));
  const [reload, setReload] = useState<boolean>(false);
  const [roomType, setRoomType] = useState<number>(0);

  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const [group, setGroup] = useState<any>([]);

  const [selectForBackRoom, setSelectForBackRoom] = useState<any>({}); // 출장자 단체 배방하기 위해 선택된 방
  const [isShowBackroomPop, setIsShowBackroomPop] = useState<boolean>(false);
  const [isShowGroupBackroomPop, setIsShowGroupBackroomPop] = useState<boolean>(false);
  const [isShowGroupBackroomConfirmPop, setIsShowGroupBackroomConfirmPop] = useState<boolean>(false);
  const [checkedGuests, setCheckedGuests] = useState<any>({});

  const [isAllChecked, setIsAllChecked] = useState<boolean>(false);
  const [searchOption, setSearchOption] = useState<{ option: string, text: string }>({ option: "", text: "선택" });
  const [bookingId, setBookingId] = useState<number>(-1);
  const [files, setFiles] = useState<any>([]);

  // 입숙예정일 관련
  const todayDate = getTodayDate();
  const [isCalendar, setIsCalendar] = useState<boolean>(false);   // 입숙예정일 입력, 수정 팝업
  const [stayAfter, setStayAfter] = useState<string>('');
  const [stayData, setStayData] = useState<any>([]);
  const [delayCheck, setDelayCheck] = useState<boolean>(false);
  const [statusDescription , setStatusDescription] = useState<any>('');

  // 반려 관련
  const [isRejectPop, setIsRejectPop] = useState<boolean>(false);
  const [isRejectCode, setIsRejectCode] = useState<number>();
  const [rejectDescription, setRejectDescription] = useState<string>('');
  const [terminatedAt, setTerminatedAt] = useState<string>('');

  const [isFilePop, setIsFilePop] = useState<boolean>(false);
  const [popFileList, setPopFileList] = useState<any>([]);
  const [isGroup, setIsGroup] = useState<boolean>(false);

  // 증빙서류 업로드 팝업
  const [isDocumentPop, setIsDocumentPop] = useState<boolean>(false);
  const [stayGuestData, setStayGuestData] = useState<any>([]);
  const [documentFiles, setDocumentFiles] = useState<any>([]);

  useEffect(() => {
    (async () => {
      const data = await fetchData(offset, limit)
      // console.log(data);
      const count: number = data.count;
      setTotalCount(count);
      setGroup(data.results);
      setTotalPage(Math.ceil(count / limit));
      setIsAllChecked(false);
    })();
  }, [roomType, offset, reload, searchOption, limit]);

  const fetchData = async (offset: number, limit: number) => {
    const payload: any = {
      offset,
      limit,
      //guest_status: [LODGE_STATUS_CODE.opened, LODGE_STATUS_CODE.waiting].join(','),
     /* status: [
        STATUS_CODE.booking_requested,
        STATUS_CODE.booking_completed,
        STATUS_CODE.booking_canceled,
        STATUS_CODE.checkin_pending,
        STATUS_CODE.booking_rejected,
        STATUS_CODE.booking_terminated
      ].join(',') */
    };

    if (roomType) {
      payload.room_type = roomType;
    }

    if (searchOption.option) {
      payload[searchOption.option] = searchOption.text
    }

    try {
      const { data }: any = await lodge.getBookingsByLodge(payload);
      return data;
    }catch ({ status, data }) {
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  }

  const onGuestCheckHandler = (e: any, room: any) => {
    const temp = { ...checkedGuests };
    room.guests.forEach((guest: any, idx: number) => {
      temp[guest.id] = e.target.checked;
      if (e.target.checked) temp[guest.id] = guest;
      else delete temp[guest.id];
    });
    setCheckedGuests(temp);
  };

  const onAllGuestCheckHandler = (e: any) => {
    const temp = { ...checkedGuests };
    setIsAllChecked(e.target.checked);

    group.forEach((room: any, idx: number) => {
      room.guests.forEach((guest: any, guestIdx: number) => {
        if (e.target.checked) temp[guest.id] = guest;
        else delete temp[guest.id];
      });
    });

    setCheckedGuests(temp);
  };

  const onCancelHandler = async (e: any) => {
    const status = getCheckedStatus();
    const filtered = status.filter(
      (s: any, idx: number) =>
        s !== STATUS_CODE.booking_requested &&
        s !== STATUS_CODE.booking_completed &&
        s !== STATUS_CODE.checkin_pending,
    );

    if (filtered.length) {
      info('입숙취소 할 수 없는 상태를 가진 항목이 선택되었습니다.');
      return;
    }

    if (isEmptyChecked()) {
      info('선택된 항목이 없습니다.');
      return;
    }

    const ids = Object.keys(checkedGuests).join(',');
    // const { data } = await lodge.cancel(ids);

    setReload(!reload);
    setCheckedGuests({});
    setIsAllChecked(false);
    info('취소처리를 정상적으로 완료했습니다.');
  };

  const getCheckedStatus = () =>
    Object.keys(checkedGuests).map(
      (checkedKey: any, checkedsIdx: number) =>
        checkedGuests[checkedKey].status,
    );
  const isEmptyChecked = () => !Object.keys(checkedGuests).length;

  const onSearchHandler = (option: string, text: string) => {
    setOffset(0);
    setLimit(10);
    setSearchOption({ option, text })
  }

  // 팝업 초기화
  const popInit = () => {
    setStayAfter('');
    setStayData([]);
    setIsRejectCode(0);
    setRejectDescription('');
    setTerminatedAt('');
    setDelayCheck(false);
  }

  // 입숙예정일 입력
  const setStayAfterDay = async() => {
    // if(delayCheck && stayAfter !== '') {
    //   info('입숙예정일 입력, 입숙 보류 체크는 동시에 불가합니다');
    //   return false;
    // }
    if(!delayCheck && (stayAfter === '' || stayAfter === null)) {
      info('입숙예정일을 선택해주세요.');
      return false;
    }

    try {
      if(delayCheck) {
        // 이전에 입숙보류를 했었는지 체크
        const payload = {
          classify : stayData.guests[0].guest_employee_number,
          status : DELAY_COUNT_CODE.delay,
          booking : stayData.id
        }
        let { data: delay } = await lodge.lodgeDelayCount(stayData.guests[0].guest_employee_number, DELAY_COUNT_CODE.delay, payload);

        if(delay === "데이터가 존재"){
          info('입숙보류 신청은 1회만 가능합니다.');
        }else{
          let { data } = await lodge.lodgeScheduleDelay(stayData.id);
  
          info('입숙예정일입력이 보류되었습니다.');
          setIsCalendar(false);
          popInit();
          setReload(!reload);
        }
      }else {
        if(stayData.room_type === ROOM_TYPE_CODE.dormitory_room) {
          let { data } = await lodge.lodgeScheduleDormitory(stayData.id, {    //기숙사
            stay_after: stayAfter
          });
        }else if(stayData.room_type === ROOM_TYPE_CODE.company_room) {    // 사택 
          let { data } = await lodge.lodgeSchedule(stayData.id, {
            stay_after: stayAfter
          });
        }

        if(stayData.status !== BOOKING_STATUS_CODE.stayAfterTerminated){    // 변경 요청이 아닌 경우
          if(stayData.room_type === ROOM_TYPE_CODE.company_room && Number(stayData.room_work_site) === 2){
            // 문자 발송 - 남양 사택은 호실 X
            let smsPayload: any = {
              cellphone : stayData.applicant_cellphone,
              message: '입숙예정일을 확정하였습니다. 예정일에 관리사무소 방문 부탁드립니다. 입숙예정일 : '+stayAfter
                      +'\n신청진행 확인은 "마이페이지>>신청관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 '+getWorkSitePhoneNum(stayData.room_work_site, stayData.room_type)
            }
            const { data: sms } = await lodge.sendSMS(smsPayload);
          }else {
            // 문자 발송
            let smsPayload: any = {
              cellphone : stayData.applicant_cellphone,
              message: '입숙예정일을 확정하였습니다. 예정일에 관리사무소 방문 부탁드립니다. 입숙예정일 : '+stayAfter+' 호  실 : '+stayData.guests[0].room_building_name +' '+ stayData.guests[0].room_building_dong +' '+ stayData.guests[0].room_name
                      +'\n신청진행 확인은 "마이페이지>>신청관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 '+getWorkSitePhoneNum(stayData.room_work_site, stayData.room_type)
            }
            const { data: sms } = await lodge.sendSMS(smsPayload);
          }
        }else {
          // 입숙예정일 변경인 경우
          if(stayData.room_type === ROOM_TYPE_CODE.company_room && Number(stayData.room_work_site) === 2){
            // 문자 발송 - 남양 사택은 호실 X
            let smsPayload: any = {
              cellphone : stayData.applicant_cellphone,
              message: '입숙예정일이 변경요청되었습니다. 입숙예정일 : '+stayAfter
            }
            const { data: sms } = await lodge.sendSMS(smsPayload);
          }else {
            // 문자 발송
            let smsPayload: any = {
              cellphone : stayData.applicant_cellphone,
              message: '입숙예정일이 변경요청되었습니다. 입숙예정일 : '+stayAfter+' 호  실 : '+stayData.guests[0].room_building_name +' '+ stayData.guests[0].room_building_dong +' '+ stayData.guests[0].room_name
            }
            const { data: sms } = await lodge.sendSMS(smsPayload);
          }
        }

        info('입숙예정일입력이 완료되었습니다.');
        setIsCalendar(false);
        popInit();
        setReload(!reload);
      }
    } catch ({ data, status }) {
      // console.log(data)
      // console.log(status)
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }


      info("입숙예정일 입력 중 문제가 발생했습니다.");
      return;
    }
  }

  const onClickDateAfterByCalendar = (day: string) => {
    if(stayAfter === day){
      setStayAfter('');
    }else{
      setStayAfter(day);
    }
  };

  // 증빙서류 업로드
  const documentUpload = async() => {
    let isFileUploaded = documentFiles.filter((file: any) => file.id < 1 && file.is_required)

    if (isFileUploaded.length) {
      info("필수항목을 업로드하지 않았습니다.");
      return
    }

    try {
      let payload = {
        files: documentFiles.map((file: { id: number, document: number }) => ({
          file: file.id,
          document: file.document
        })).filter((file:{ file: number, document?: number}) => file?.document && file.file !== -1)
      }

      let { data } = await lodge.lodgeDocumentUpload(stayData.id, payload);      

      info('증빙서류 등록이 완료되었습니다');
      setIsDocumentPop(false);
      popInit();
      setReload(!reload);
    } catch ({ data, status }) {
      // console.log(data)
      // console.log(status)

      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("증빙서류 업로드 중 문제가 발생했습니다.");
      return;
    }
  }

  //휴대폰 번호 하이픈 처리
  const cellPhoneHyphen = (data : any) => {
    
    if(data !== undefined &&  data > 0) {
        let phone = data.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3");
        
        return phone;
    }
    return '';
  };

  // // 입숙예정일 보류 선택시
  // const setStayDelay = async() => {
  //   try {
  //     let { data } = await lodge.lodgeScheduleDelay(stayData.id);

  //     info('입숙예정이 보류되었습니다.');
  //     setIsCalendar(false);
  //     popInit();
  //     setReload(!reload);
  //   } catch ({ data, status }) {
  //     console.log(data)
  //     console.log(status)
  //     info("입숙예정일 입력 중 문제가 발생했습니다.");
  //     return;
  //   }
  // }
  // 파일의 확장자를 가져오는 함수
  const getFileExtension = (filename: string) => {
    const parts = filename.split('.');
    if (parts.length > 1) {
      return parts.pop()!.toLowerCase(); // 파일 이름이 마침표를 포함하는 경우에만 확장자 추출
    } else {
      return ''; // 마침표가 없는 경우 빈 문자열 반환
    }
  };

  const downloadFile = async (url: string, filename: string) => {
    const token = window.localStorage.getItem('accessToken');
    console.log('url : ' + url);
    console.log('filename : ' + filename);

    if (!url) {
      console.error('url 경로가 없습니다.');
      return;
    }

    if(!filename || filename.length == 0) {
      const name = url.split("/");
      filename = name[name.length - 1];
    }
  
    const unifiedUrl = url.replace(/\\/g, '/');
    const parts = unifiedUrl.split('/');
    const desiredParts = parts.slice(2).join('\\');

    // 파일의 확장자가 이미지나 PDF인지 확인하는 함수
    const isImageOrPDF = (extension: string) => {
      const supportedExtensions = ['gif', 'jpg', 'jpeg', 'png', 'pdf'];
      return supportedExtensions.includes(extension);
    };

    const fileExtension = getFileExtension(filename);
    const isImageOrPDFFile = isImageOrPDF(fileExtension);

    //fileDownload
    try {
      let payload: any = {
        filename: filename,
        url : desiredParts,
      };

      let { data } = await core.fileDownload(payload);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      //info("협조전 등록이 완료되었습니다.");
    } catch ({ data, status }) {
      console.log(data, status);
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }
      info("파일 다운로드 중 문제가 발생했습니다");
      return;
    }
  };

  return (
    <>

      <DefaultTemplete
        id="wrap"
        className="wrap_mypage_manage"
        addClassName="w100"
        leftInfo={
          <>
            <h2>신청관리</h2>
            <p className="desc">
              숙소이용신청 내역확인 및 수정을 진행하실 수 있습니다
          </p>
          </>
        }
        {...props}
      >
        <div className="group_search">
          <div className="search_form">
            <SearchForm
              options={[
                { value: "", label: "선택" },
                { value: "room_work_site", label: "신청지역" },
                { value: "guest_name", label: "이용자 성명" },
                { value: "guest_cellphone", label: "이용자 휴대폰번호" },
                { value: "guest_employee_number", label: "이용자 사번" },
                { value: "guest_company", label: "이용자 회사명" },
              ]}
              onSearch={onSearchHandler}
            />
          </div>
        </div>

        <div className="group_tab mt40">
          <div className="tabs_area">
            <RoomType
              value={roomType}
              onChange={(roomType: number) => {setRoomType(roomType); setLimit(limit); setOffset(0);}}
            />
          </div>
          <div className="right_area">
            <LimitSelect limit={limit} onChange={(limit: number) => { setLimit(limit); setOffset(0); }} />

          </div>

        </div>
        <div className="pc_view">
          <div className="table_normal_list mt30" style={{ overflowX: "auto" }}>
            <table>
              <caption className="sr_only">신청관리 목록</caption>
              {group.length ? (
                <colgroup>
                  {/* <col width="80px"/> */}

                  <col width="80px"/>
                  <col width="80px"/>
                  <col width="80px"/>
                  <col width="150px"/>

                  {/*<col width="80px"/>
                  <col width="80px"/>
              <col width="120px"/>*/}
                  <col width="150px"/>
                  <col width="80px"/>
                  <col width="80px"/>
                  <col width="80px"/>
                  <col width="80px"/>
                  <col width="80px"/>
                  {/*<col width="120px"/>*/}
                  <col width="100px"/>
                  <col width="100px"/>
                  <col width="100px"/>
                  <col width="100px"/>
                  <col width="80px"/>
                  {/*<col width="80px"/>
                  <col width="80px"/>*/}

                  {/*<col width="80px"/>
                  <col width="80px"/>
            <col width="80px"/>*/}

                  <col width="120px"/>
                </colgroup>
              ) : ''}
              <thead>
                <tr>
                  {/*<th rowSpan={2}>
                    <label className="input_check pop_notext">
                      <input
                        type="checkbox"
                        id=""
                        name=""
                        checked={isAllChecked}
                        onChange={onAllGuestCheckHandler}
                      />
                      <span className="label_text"></span>
                    </label>
                </th> */}
                  <th colSpan={4}>신청 기본 정보</th>
                  {/*<th colSpan={3}>신청자</th>*/}
                  <th colSpan={11}>이용자</th>
                  {/*<th colSpan={3}>신청자</th>*/}
                  <th rowSpan={2}>파일</th>
                </tr>
                <tr>
                  {/* 신청 기본 정보 */}
                  <th className="">신청일자</th>
                  <th className="">숙소유형</th>
                  <th className="">신청지역</th>
                  <th className="">진행상태</th>

                  {/* 신청자 */}
                  {/*<th className="">성명</th>
                  <th className="">부서명</th>
                  <th>휴대폰</th>*/}

                  {/* 이용자 */}
                  <th className="">숙소정보(동호수)</th>
                  <th className="">입숙예정일</th>
                  <th>퇴숙예정일</th>
                  <th>사번</th>
                  <th className="">성명</th>
                  <th className="">성별</th>
                  <th className="">휴대폰</th>
                  {/*<th>소속회사 유형</th>*/}
                  <th>회사명/단체명</th>
                  <th>소속 부서</th>
                  <th>직급</th>
                  <th className="brl">직종</th>
                  {/*<th>근무조</th>
                  <th>차량번호</th>*/}

                  {/* 신청자 */}
                  {/*<th>회사명</th>
                  <th>직급</th>
                  <th>사번</th>*/}
                </tr>
              </thead>
              <tbody>
                {/*데이타 없을 시*/}
                {!group.length ? (
                  <tr>
                    <td colSpan={16} className="blank_area type_white">
                      <p className="txt_blank">데이터가 없습니다.</p>
                    </td>
                  </tr>
                ) : (
                    group.map((booking: any, bookingIdx: number) =>
                      //booking.guests.map((guest: any, guestIdx: number) => (
                        <tr className="" key={`${bookingIdx}`}>
                          {/*<td
                            style={{ display: guestIdx ? 'none' : '' }}
                            rowSpan={!guestIdx ? booking.guests.length : 0}
                          >
                            <label className="input_check pop_notext">
                              <input
                                type="checkbox"
                                id=""
                                name="guest_user_checkbox"
                                onChange={(e: any) =>
                                  onGuestCheckHandler(e, booking)
                                }
                                checked={checkedGuests[guest.id]}
                              />
                              <span className="label_text"></span>
                            </label>
                            </td> */}
                          {/*신청일자 */}
                          <td
                            //style={{ display: guestIdx ? 'none' : '', cursor: "pointer" }}
                            style={{ cursor: "pointer" }}
                            //rowSpan={!guestIdx ? booking.guests.length : 0}
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                          >
                            {formatByString_YYYYMMDD(booking.created_at)}
                          </td>
                          {/*숙소유형 */}
                          <td
                            style={{ cursor: "pointer" }}
                            //rowSpan={!guestIdx ? booking.guests.length : 0}
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                          >
                            {ROOM_TYPE[booking.room_type]}
                          </td>
                          {/*신청지역 */}
                          <td
                            style={{ cursor: "pointer" }}
                            //rowSpan={!guestIdx ? booking.guests.length : 0}
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                          >
                            {WORK_SITE[booking.room_work_site]}
                          </td>
                          {/* <td style={{display: guestIdx? "none": ""}} rowSpan={!guestIdx? booking.guests.length : 0}></td> */}
                          {/*진행상태 */}
                          <td style={{ cursor: "pointer" }}>
                            {booking.status === BOOKING_STATUS_CODE.approved ? 
                              booking.guests && booking.guests.length > 0 && booking.guests[0].status === LODGE_STATUS_CODE.waiting ?
                              (
                              <>
                              <p>{BOOKING_STATUS[booking.status]}</p>
                              <p>(입숙예정일 : {booking.guests && booking.guests.length > 0 ? booking.guests[0].stay_after : ''})</p>
                              </> 
                              )
                              : booking.guests && booking.guests.length > 0 && booking.guests[0].status === LODGE_STATUS_CODE.opened ?
                                booking.room_type !== ROOM_TYPE_CODE.business_room_group?
                                    (<p>최종승인대기</p>) 
                                    : (<p>서류승인완료</p>) 
                                : (
                                    <p>{booking.guests && booking.guests.length > 0 ? LODGE_STATUS[booking.guests[0].status] : ''}</p>
                                  )
                            :(
                              booking.room_type === ROOM_TYPE_CODE.company_room && booking.status === BOOKING_STATUS_CODE.documentApproved?
                                (<p>승인완료</p>)
                              : (
                                  <p>
                                    {BOOKING_STATUS[booking.status]}
                                    {/* 신청 완료, 입숙보류 상태일 경우 대기 순번 표시 */}
                                    {(booking.status === BOOKING_STATUS_CODE.applied || booking.status === BOOKING_STATUS_CODE.stayHold) 
                                      && (booking.room_type === ROOM_TYPE_CODE.company_room || booking.room_type === ROOM_TYPE_CODE.dormitory_room) 
                                      && booking.guests && booking.guests.length > 0 && booking.guests[0].rank ? (
                                      <><br /> (대기 {booking.guests && booking.guests.length > 0 ? booking.guests[0].rank : ''}번)</>
                                    ) : ''} 
                                  </p>
                                )
                            )}

                            {/* 출장자 단체 배방해야 되는 경우 */}
                            {booking.room_type === ROOM_TYPE_CODE.business_room_group && booking.status === BOOKING_STATUS_CODE.documentApproved
                              ? (
                                <button type="button" className="btn_sm btn_outline"
                                  onClick={() => {
                                    setSelectForBackRoom(booking);
                                    setIsShowGroupBackroomPop(true);
                                  }}
                                >
                                  배방 진행
                                </button>
                                ) : ''}

                            {/* 반려(재요청)인 경우 */}
                            {booking.status === BOOKING_STATUS_CODE.rejected? (
                              <>
                              <button type="button" className="btn_sm btn_outline" 
                                onClick={()=>{
                                  setIsRejectCode(booking.status);
                                  setRejectDescription(booking.status_description);
                                  setTerminatedAt(booking.status_terminated_at);
                                  setIsRejectPop(true);
                                }}>
                                반려 사유
                              </button>
                              <button type="button" className="btn_sm btn_gray" onClick={() => props.history.push('/mypage/applicant/detail?isUpdateMode=1&isReapply=1', booking)}>
                                재등록
                              </button>
                              </>
                            ) : ''}

                            {/* 승인거절인 경우 */}
                            {booking.status === BOOKING_STATUS_CODE.terminated? (
                              <button type="button" className="btn_sm btn_outline"  
                                onClick={()=>{
                                  setIsRejectCode(booking.status);
                                  setRejectDescription(booking.status_description);
                                  setTerminatedAt(booking.status_terminated_at);
                                  setIsRejectPop(true);
                                }}>
                                거절 사유
                              </button>
                            ) : ''}
                            
                            {/* 입숙일 입력 */}
                            {(booking.room_type === ROOM_TYPE_CODE.dormitory_room || booking.room_type === ROOM_TYPE_CODE.company_room)  
                              && (booking.status === BOOKING_STATUS_CODE.roomOpen)
                              && (
                                <button type="button" className="btn_sm btn_outline" onClick={() => {setStayData(booking); setIsCalendar(true);}}>입숙예정일 입력</button>
                              )
                            }

                            {/* 입숙일 변경 요청 */}
                            {(booking.room_type === ROOM_TYPE_CODE.dormitory_room || booking.room_type === ROOM_TYPE_CODE.company_room)  
                              && (booking.status === BOOKING_STATUS_CODE.stayAfterTerminated)
                              && booking.guests && booking.guests.length > 0 && (
                                <button type="button" className="btn_sm btn_outline" onClick={() => {setStayData(booking); setStayAfter(booking.guests[0].stay_after); setIsCalendar(true); setStatusDescription(booking.status_description)}}>입숙예정일 변경</button>
                              )
                            }
                            
                            {/* 증빙서류 업로드인 경우 */}
                            {booking.room_type === ROOM_TYPE_CODE.company_room && (booking.status === BOOKING_STATUS_CODE.documentApproved || booking.status === BOOKING_STATUS_CODE.documentReject)
                              && booking.guests && booking.guests.length > 0 && (
                                <button type="button" className="btn_sm btn_outline" 
                                  onClick={() => {
                                      setStayData(booking); 
                                      setStayGuestData(booking.guests[0]); 
                                      if(booking.files){
                                        setFiles(booking.files.filter((file: any) => file.document.visible_type === DOCUMENT_FILE_VISIBLE_TYPE_CODE.assign))
                                      }
                                      setIsDocumentPop(true);
                                  }}>
                                  증빙서류 업로드
                                </button>
                              )
                            }

                          </td> 

                          {/*<td
                            style={{ cursor: "pointer" }}
                            //rowSpan={!guestIdx ? booking.guests.length : 0}
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                          >
                            <a
                              href="javascript:void(0)"
                              className="user_name gray"
                            >
                              {booking.applicant_name}
                            </a>
                          </td>
                          <td
                            style={{ cursor: "pointer" }}
                            //rowSpan={!guestIdx ? booking.guests.length : 0}
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                          >
                            {booking.applicant_department}
                          </td>
                          <td
                            style={{ cursor: "pointer" }}
                            //rowSpan={!guestIdx ? booking.guests.length : 0}
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                          >
                            {booking.applicant_cellphone}
                          </td>
                          */}
                          {/* 숙소정보 */}
                          <td
                            style={{ textAlign: 'center' }}
                            // onClick={() =>
                            //   props.history.push(`/lodge/applicant/detail`, booking)
                            // }
                          >
                          
                          {/* 기숙사, 사택(남양X) -> 배방 확인 */}
                          {(booking.room_type === ROOM_TYPE_CODE.dormitory_room || (booking.room_type === ROOM_TYPE_CODE.company_room && Number(booking.room_work_site) !== 2)) && 
                              (booking.status !== BOOKING_STATUS_CODE.applied && booking.status !== BOOKING_STATUS_CODE.canceled && booking.status !== BOOKING_STATUS_CODE.stayHold 
                                && booking.status !== BOOKING_STATUS_CODE.rejected && booking.status !== BOOKING_STATUS_CODE.terminated ) && booking.guests && booking.guests.length > 0 && (
                                <>
                                    건물/동: {booking.guests[0].room_building_name}
                                      { '/'+ booking.guests[0].room_building_dong}
                                    <br />
                                      방호수: {booking.guests[0].room_floor_name}층/
                                      {booking.guests[0].room_name}호

                                  <button
                                    type="button"
                                    className="btn_sm btn_outline"
                                    onClick={() => {
                                      setSelectForBackRoom(booking);
                                      setIsShowBackroomPop(true);
                                    }}
                                  >
                                    배방확인
                                  </button>
                                </>
                          )}

                          {/* 사택(남양) -> 배방 확인 */}
                          {((booking.room_type === ROOM_TYPE_CODE.company_room && Number(booking.room_work_site) === 2)) && 
                              (booking.status === BOOKING_STATUS_CODE.documentApproved || booking.status === BOOKING_STATUS_CODE.approved
                                || booking.status === BOOKING_STATUS_CODE.documentSubmit || booking.status === BOOKING_STATUS_CODE.documentReject) && booking.guests && booking.guests.length > 0 && (
                                <>
                                    건물/동: {booking.guests[0].room_building_name}
                                      { '/'+ booking.guests[0].room_building_dong}
                                    <br />
                                      방호수: {booking.guests[0].room_floor_name}층/
                                      {booking.guests[0].room_name}호

                                  <button
                                    type="button"
                                    className="btn_sm btn_outline"
                                    onClick={() => {
                                      setSelectForBackRoom(booking);
                                      setIsShowBackroomPop(true);
                                    }}
                                  >
                                    배방확인
                                  </button>
                              </>
                          )}

                          {/* 출장자 단체 배방 확인 */}
                          {booking.room_type === ROOM_TYPE_CODE.business_room_group && booking.status === BOOKING_STATUS_CODE.approved 
                            && booking.guests && booking.guests.length > 0 && (booking.guests[0].status !== LODGE_STATUS_CODE.terminated)
                            && (
                              <>
                                <button type="button" className="btn_sm btn_outline"
                                  onClick={() => {
                                    setSelectForBackRoom(booking);
                                    setIsShowGroupBackroomConfirmPop(true);
                                  }}
                                >
                                  배방 확인
                                </button>
                              </>
                            )
                          }

                          {(booking.room_type !== ROOM_TYPE_CODE.dormitory_room && booking.room_type !== ROOM_TYPE_CODE.company_room && booking.room_type !== ROOM_TYPE_CODE.business_room_group)
                            && booking.status === BOOKING_STATUS_CODE.approved && booking.guests && booking.guests.length > 0 ? (
                              <>
                              건물/동: {booking.guests[0].room_building_name}
                                { '/'+ booking.guests[0].room_building_dong}
                              <br />
                                방호수: {booking.guests[0].room_floor_name}층/
                                {booking.guests[0].room_name}호

                                <button
                                  type="button"
                                  className="btn_sm btn_outline"
                                  onClick={() => {
                                    setSelectForBackRoom(booking);
                                    setIsShowBackroomPop(true);
                                  }}
                                >
                                  배방확인
                                </button>
                              </>
                          ) : ''}
                          </td>
                          {/*이용자 입숙예정일 */}
                          <td
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {booking.guests && booking.guests.length > 0 ? booking.guests[0].stay_after : ''}
                          </td>
                          {/*이용자 퇴숙예정일 */}
                          <td
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {booking.guests && booking.guests.length > 0 ? booking.guests[0].stay_before : ''}

                          </td>
                          {/*사번*/}
                          <td
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {Number(booking.room_type) === 1 ? booking.applicant_employee_number : booking.guests && booking.guests.length > 0 ? booking.guests[0].guest_employee_number : ''}
                          </td>
                          {/*이용자 성명 */}
                          <td
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {booking.guests && booking.guests.length > 1 ? (
                              <a href="javascript:void(0)" className="user_name">
                                <p>{booking.guests[0].guest_name} 외 {parseInt(booking.guests.length) - 1} 명</p>
                              </a>
                            ) : booking.guests && booking.guests.length > 0? (
                              <a href="javascript:void(0)" className="user_name">
                                <p>{booking.guests[0].guest_name}</p>
                              </a>
                            ) : ''}
                          </td>
                          {/*이용자 성별 */}
                          <td
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {booking.guests && booking.guests.length > 0 && booking.guests[0].guest_gender === 1 ? '남자' : 
                              booking.guests && booking.guests.length > 0 && booking.guests[0].guest_gender === 2 ?'여자' : ''}
                          </td>
                          {/*이용자 휴대폰 */}
                          <td
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {booking.guests && booking.guests.length > 0 ? cellPhoneHyphen(booking.guests[0].guest_cellphone) :''}
                          </td>
                          {/* TODO 소속회사유형 */}
                          {/*<td
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {booking.guests[0].guest_type === 1
                              ? '사내 임직원'
                              : booking.guests[0].guest_type === 2
                                ? '계열사 임직원'
                                : '외부인'}
                          </td>
                          */}
                          {/* TODO 회사명/단체명 */}
                          <td
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {booking.guests && booking.guests.length > 0 ? booking.guests[0].guest_company :''}
                          </td>
                          {/*소속부서 */}    
                          <td
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {booking.guests && booking.guests.length > 0 ? booking.guests[0].guest_department:''}
                          </td>
                          {/*직급 */}
                          <td
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {booking.guests && booking.guests.length > 0 ? booking.guests[0].guest_position :''}
                          </td>
                          {/*직종 */}
                          <td
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {booking.guests && booking.guests.length > 0 ? booking.guests[0].guest_occupation :''}
                          </td>
                          {/*근무조 */}
                          {/*<td
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {booking.guests[0].guest_party}
                          </td>*/}
                          {/*차량번호 */}
                          {/*<td
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div style={{ whiteSpace: 'pre-wrap' }}>
                              {booking.guests[0].guest_car_number1} {booking.guests[0].guest_car_number2 && `\n${booking.guests[0].guest_car_number2}`} {booking.guests[0].guest_car_number3 && `\n${booking.guests[0].guest_car_number3}`}
                            </div>
                          </td>*/}

                          {/* 신청자 회사명 */}
                          {/*<td
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                            //rowSpan={!guestIdx ? booking.guests.length : 0}
                            style={{ cursor: "pointer" }}
                          >
                            현대자동차 {/* 신청자 회사명 하드코딩 이유: 신청자의 회사는 현대자동차만있음 
                          </td>
                          신청자 직급
                          <td
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                            //rowSpan={!guestIdx ? booking.guests.length : 0}
                            style={{ cursor: "pointer" }}
                          >
                            {booking.applicant_position}
                          </td>
                          {/*신청자 사번    
                          <td
                            onClick={() =>
                              props.history.push(`/mypage/applicant/detail`, booking)
                            }
                            //rowSpan={!guestIdx ? booking.guests.length : 0}
                            style={{ cursor: "pointer" }}
                          >
                            {booking.applicant_employee_number}
                          </td>
                          */}
                          {/* TODO 처리 */}
                          <td 
                            //style={{ display: guestIdx ? "none" : "" }} 
                            //rowSpan={!guestIdx ? booking.guests.length : 0}
                          >
                            {booking.files.length > 0 ? (
                              <button type="button" className="btn_sm btn_outline" onClick={() => {
                                  setPopFileList(booking.files); 
                                  if(booking.room_type === ROOM_TYPE_CODE.business_room_group) {
                                    setIsGroup(true);
                                  }else {
                                    setIsGroup(false);
                                  }
                                  setIsFilePop(true);
                              }}>
                                첨부서류 확인
                              </button>
                            ) : ''}
                            {/* {booking.booking_type === 2 && ( <button type="button" className="btn_sm btn_outline btn_darkblue" style={{ display: "" }} onClick={() => { setFiles(booking.files); setBookingId(booking.id) }}>협조전</button> )} */}
                          </td>
                        </tr>
                      //)),
                    )
                  )}
              </tbody>
            </table>
          </div>{' '}
          {/*table*/}
          <div className="board_paging">
            <ul>
              <Pagination
                offset={offset}
                limit={limit}
                setOffset={setOffset}
                totalPage={totalPage}
              />
            </ul>

            <div className="submit ar pt30">
              {/* <button type="button" className="btn btn_lg btn_outline"></button> */}

              {/* <ConfirmBtn
                className="btn btn_lg btn_darkblue"
                action={onCancelHandler}
                label="신청 취소"
                title=""
                msg="선택된 항목을 취소하시겠습니까?"
              /> */}
            </div>
          </div>

        </div>{' '}
        {/* pc_view */}
        
        <div className="mb_view">
          <div className="submit ar pt30 btn_length_1">
            {/* <ConfirmBtn
              title=""
              msg="선택된 항목을 취소하시겠습니까?"
              className="btn btn_lg btn_darkblue"
              action={onCancelHandler}
              label="신청 취소"
            /> */}
          </div>

          {group.length === 0 ? (
            <div className="blank_area">
                <p className="txt_blank">데이터가 없습니다.</p>
            </div>
          ) :(
            <div className="lst_apply">
              {/* <div className="all_check">
                  <p>
                    <label className="input_check pop_notext">
                      <input
                        type="checkbox"
                        id=""
                        name="check_notice"
                        checked={isAllChecked}
                        onChange={onAllGuestCheckHandler}
                      />
                      <span className="label_text"></span>
                    </label>
                  </p>
                  <p>전체선택</p>
              </div> */}
              {group.map((room: any, roomIdx: number) => (
                <>
                  <dl>
                    <dt>
                      <p>
                        {/* <label className="input_check pop_notext">
                          <input
                            type="checkbox"
                            id=""
                            name="check_notice"
                            onChange={(e: any) => onGuestCheckHandler(e, room)}
                            checked={checkedGuests[room.guests[0].id]}
                          />
                          <span className="label_text"></span>
                          </label> */}
                      </p>
                      <p>신청 기본 정보</p>
                      <a href="javascript:void(0)" className="btn_detail" onClick={() => props.history.push(`/mypage/applicant/detail`, room)}>
                        상세보기
                      </a>
                    </dt>

                    <dd>
                      <div className="title">
                        <p>{room.applicant_name}</p>
                        <p>
                          {/* <StatusTag status={room.guests[0].status} /> */}
                          {room.status === BOOKING_STATUS_CODE.approved ?   // 신청 status -> 승인인 경우 
                            room.guests && room.guests.length > 0 && room.guests[0].status === LODGE_STATUS_CODE.waiting ?   // 신청 상태 -> 승인 , 이용자 상태 -> 대기
                            (
                            <>
                              
                                <span className="user_name">
                                    {BOOKING_STATUS[room.status]}
                                </span>
                              
                            </> 
                            )
                            : room.guests && room.guests.length > 0 && room.guests[0].status === LODGE_STATUS_CODE.opened ?     // 신청 상태 -> 승인 , 이용자 상태 -> 신청중
                              room.room_type !== ROOM_TYPE_CODE.business_room_group?    // 출장자 단체 X
                                  (<span className="user_name">최종승인대기</span>) 
                                  : (<span className="user_name">서류승인완료</span>) 
                              : room.guests && room.guests.length > 0 ? ( // 출장자 단체 O
                                  <span className="user_name">{LODGE_STATUS[room.guests[0].status]}</span>
                                )
                              : ''
                          :(
                            room.room_type === ROOM_TYPE_CODE.company_room && room.status === BOOKING_STATUS_CODE.documentApproved? // 사택 증빙서류 요청
                              (<span className="user_name">승인완료</span>)
                            : (
                                <span className="user_name">
                                  {BOOKING_STATUS[room.status]}
                                  {/* 신청 완료, 입숙보류 상태일 경우 대기 순번 표시 */}
                                  {(room.status === BOOKING_STATUS_CODE.applied || room.status === BOOKING_STATUS_CODE.stayHold) 
                                    && (room.room_type === ROOM_TYPE_CODE.company_room || room.room_type === ROOM_TYPE_CODE.dormitory_room) 
                                    && room.guests && room.guests.length > 0 && room.guests[0].rank ? (
                                    <> (대기 {room.guests[0].rank}번)</>
                                  ) : ''}
                                </span>
                              )
                          )} 
                        </p>
                      </div>
                      <ul className="lst_item">
                        <li>
                          <p>사번</p>
                          <p>{room.applicant_employee_number}</p>
                        </li>
                        <li>
                          <p>
                            <em>부서명</em>
                            <em>직책</em>
                          </p>
                          <p>
                            <em>{room.applicant_department}</em>
                            <em>{room.applicant_position}</em>
                          </p>
                        </li>
                        <li>
                          <p>
                            <em>신청숙소</em>
                            <em>지역</em>
                          </p>
                          <p>
                            <em>{ROOM_TYPE[room.room_type]}</em>
                            <em>{workplaceMap[room.room_work_site]?.name}</em>
                          </p>
                        </li>
                        <li>
                          <p>신청일자</p>
                          <p>{formatByString_YYYYMMDD(room.created_at)}</p>
                        </li>
                      </ul>

                      <div className="sub_title">
                        <p>이용자</p>
                      </div>
                      {room.guests && room.guests.length > 0 && room.guests.map((guest: any, guestIdx: number) => (
                        <React.Fragment key={guestIdx}>
                          <ul className="lst_item">
                            <li>
                              <p>성명</p>
                              <p>{guest.guest_name}</p>
                            </li>
                            <li>
                              <p>성별</p>
                              <p>
                                {parseInt(guest.guest_gender) === 1
                                  ? '남자'
                                  : '여자'}
                              </p>
                            </li>
                            <li>
                              <p>휴대폰번호</p>
                              <p>{guest.guest_cellphone}</p>
                            </li>
                            <li>
                              <p>입숙예정일</p>
                              <p>{guest.stay_after}</p>
                            </li>
                            <li>
                              <p>퇴숙예정일</p>
                              <p>{guest.stay_before}</p>
                            </li>
                          </ul>

                          {/* <div className="btn_apply">
                            {guestIdx >= room.guests.length - 1 &&
                              guest.status !== STATUS_CODE.booking_requested ? (
                                room.booking_type === 2 &&
                                  guest.status === STATUS_CODE.booking_completed ? (
                                    <button
                                      type="button"
                                      className="btn btn_lg btn_darkblue pc"
                                      onClick={() => {
                                        setSelectForBackRoom(room);
                                      }}
                                    >
                                      배방
                                    </button>
                                  ) : (
                                    <>
                                      {guest.room_building_dong}/
                                      {guest.room_building_name}/
                                      {guest.room_building_floor}층
                                    </>
                                  )
                              ) : (
                                <></>
                              )}
                          </div> */}
                        </React.Fragment>
                      ))}
                    </dd>
                  </dl>
                  
                  <div className="btn_wrap ar mt10">
                    {/* 반려(재요청)인 경우 */}
                    {room.status === BOOKING_STATUS_CODE.rejected? (
                        <>
                        <button type="button" className="btn_sm btn_outline" 
                          onClick={()=>{
                            setIsRejectCode(room.status);
                            setRejectDescription(room.status_description);
                            setTerminatedAt(room.status_terminated_at);
                            setIsRejectPop(true);
                          }}>
                          반려 사유
                        </button>
                        <button type="button" className="btn_sm btn_gray ml10" onClick={() => props.history.push('/mypage/applicant/detail?isUpdateMode=1&isReapply=1', room)}>
                          재등록
                        </button>
                        </>
                      ) : ''}

                      {/* 승인거절인 경우 */}
                      {room.status === BOOKING_STATUS_CODE.terminated? (
                        <button type="button" className="btn_sm btn_outline"  
                          onClick={()=>{
                            setIsRejectCode(room.status);
                            setRejectDescription(room.status_description);
                            setTerminatedAt(room.status_terminated_at);
                            setIsRejectPop(true);
                          }}>
                          거절 사유
                        </button>
                      ) : ''}
                      
                      {/* 입숙일 입력 */}
                      {(room.room_type === ROOM_TYPE_CODE.dormitory_room || room.room_type === ROOM_TYPE_CODE.company_room)  
                        && (room.status === BOOKING_STATUS_CODE.roomOpen)
                        && (
                          <button type="button" className="btn_sm btn_outline" onClick={() => {setStayData(room); setIsCalendar(true);}}>입숙예정일 입력</button>
                        )
                      }

                      {/* 입숙일 변경 요청 */}
                      {(room.room_type === ROOM_TYPE_CODE.dormitory_room || room.room_type === ROOM_TYPE_CODE.company_room)  
                        && (room.status === BOOKING_STATUS_CODE.stayAfterTerminated)
                        && room.guests && room.guests.length > 0 && (
                          <button type="button" className="btn_sm btn_outline" onClick={() => {setStayData(room); setStayAfter(room.guests[0].stay_after); setIsCalendar(true); setStatusDescription(room.status_description)}}>입숙예정일 변경</button>
                        )
                      }
                      
                      {/* 증빙서류 업로드인 경우 */}
                      {room.room_type === ROOM_TYPE_CODE.company_room && (room.status === BOOKING_STATUS_CODE.documentApproved || room.status === BOOKING_STATUS_CODE.documentReject)
                        && room.guests && room.guests.length > 0 && (
                          <button type="button" className="btn_sm btn_outline" 
                            onClick={() => {
                                setStayData(room); 
                                setStayGuestData(room.guests[0]); 
                                if(room.files){
                                  setFiles(room.files.filter((file: any) => file.document.visible_type === DOCUMENT_FILE_VISIBLE_TYPE_CODE.assign))
                                }
                                setIsDocumentPop(true);
                            }}>
                            증빙서류 업로드
                          </button>
                        )
                      }
                  </div>
                  <hr></hr>
                </>
              ))}
            </div>
          )}


          <MoreBtn
            offset={offset}
            limit={limit}
            setLimit={setLimit}
            totalCount={totalCount}
            //fetchData={fetchData}
            //data={group}
            //onClick={(reqGuests: any) => setGroup(group.concat(reqGuests))}
          />
        </div>
      </DefaultTemplete>

      {/* 출장자 단체 배방 */}
      {/* <BackroomPopup
        selectGroup={selectForBackRoom}
        isShow={!!Object.keys(selectForBackRoom).length}
        onClose={() => {
          setReload(!reload);
          setSelectForBackRoom({});
        }}
        history={props.history}
        reload={reload}
      /> */}
      
      <BusinessGroupbackroom 
        isShowGroupBackroomPop={isShowGroupBackroomPop}
        selectGroup={selectForBackRoom}
        onClose={() => {
          setReload(!reload);
          setSelectForBackRoom({});
          setIsShowGroupBackroomPop(false);
        }}
        onRefresh={() => setReload(!reload)}
      />

      {/* 단체 배방 확인 팝업 */}
      <BusinessGroupbackroomConfirm
        isShowGroupBackroomConfirmPop={isShowGroupBackroomConfirmPop}
        selectGroup={selectForBackRoom}
        onClose={() => {
          setReload(!reload);
          setSelectForBackRoom({});
          setIsShowGroupBackroomConfirmPop(false);
        }}
        onRefresh={() => setReload(!reload)}
      />

      {/* 배방 확인 팝업 */}
      <LodgeBackroomPopup
        selectBackroom={selectForBackRoom}
        setSelectBackroom={setSelectForBackRoom}
        onClose={() => {setSelectForBackRoom({}); setIsShowBackroomPop(false);}}
        onRefresh={() => setReload(!reload)}
        isShowPop={isShowBackroomPop}
      />

      {/* <RfcFile
        bookingId={bookingId}
        files={files}
        onClose={() => { setFiles([]); setBookingId(-1) }}
        setFiles={setFiles}
      /> */}

      {/* 입숙예정일 팝업 */}
      <div
        id="pop_write_date"
        className="pop_dim_wrap pop_modal pop_sm"
        style={{ display: isCalendar ? 'block' : 'none' }}
      >
        <div className="pop_wrap">
            <div className="title_wrap">
              <h3>입숙예정일입력</h3>
              <button
                className="pop_btn_close pop_close"
                onClick={() => {setIsCalendar(false); popInit();}}
              > 닫기
              </button>
            </div>
            <div className="contents_wrap">
              <div className="process_list">{/*result_area*/}
                {/* <ul className="lst_date"></ul> */}
                  {/* <li></li> */}
                  <dl>
                    <dt>입숙예정일</dt>
                    <dd>
                      <input type="text" className="form_control" value={stayAfter} disabled/>
                      <ul className="lst_warning gray">
                          <li>신청 승인이 완료 되었습니다. 입숙예정일을 입력해주세요.</li>
                      </ul>
                    </dd>
                  
                    {stayData.status === BOOKING_STATUS_CODE.stayAfterTerminated? (
                      <dl>
                        <dt>사유</dt>
                        <dd>
                          <textarea className="form_control" value={statusDescription} disabled/>
                        </dd>
                      </dl>
                    ):''}

                    <dd className="datepicker_area">
                      <Calendar
                        onClickSelectDay={onClickDateAfterByCalendar}
                        onRangeSelectDay={(e: string) => { }}
                        tasks={false}
                        isNextMonthButtonView={true}
                        selectDate={stayAfter? stayAfter : todayDate[0]}
                        beforeDisablePoint={getPrevDay(todayDate[0])[0]}
                      />
                    </dd>

                    {stayData.room_type === ROOM_TYPE_CODE.company_room && stayData.status !== BOOKING_STATUS_CODE.stayAfterTerminated ? (
                      <dd className="agree_area">
                        <label className="input_check">
                            <input type="checkbox" id="" name="" onChange={()=>setDelayCheck(!delayCheck)} checked={delayCheck}/>
                            <span className="label_text">입숙 보류를 원하시면 체크하세요</span>
                        </label>
                        <ul className="lst_warning gray pl30">
                            <li>사정에 의해 선택 기한 내에 입숙예정일을 정하지 못할 시 1회 입숙보류 가능합니다</li>
                        </ul>
                      </dd>
                    ): ''}
                  </dl>
                {/* <button className="btn btn_sm btn_darkblue btn_outline">날짜 재선택</button> */}
              </div>
            </div>
            <div className="button_wrap btn_length_1">
               {delayCheck? (
                <ConfirmBtn
                  className="btn btn_lg btn_darkblue"
                  action={() => setStayAfterDay()}
                  label="적용"
                  title=""
                  msg="입숙보류를 하시겠습니까?"
                />
              ):(
                <button
                type="button"
                className="btn btn_xlg btn_darkblue pop_close"
                onClick={() => {
                  setStayAfterDay();
                }}
                >
                  적용
                </button>
              )} 
            </div>
        </div>
      </div>

      {/* 반려사유 팝업 */}
      <div id="pop_request_refuse" className="pop_dim_wrap pop_modal pop_sm" style={{ display: isRejectPop? 'block':'none'}}>
        <div className="pop_wrap">
            <div className="title_wrap">
                {isRejectCode === BOOKING_STATUS_CODE.rejected ? (
                  <h3>반려(재등록) 요청</h3>
                ) : (
                  <h3>승인거절사유</h3>
                )}
                <button className="pop_btn_close pop_close" onClick={() => {setIsRejectPop(false); popInit();}}>닫기</button>
            </div>
            <div className="contents_wrap">
            {isRejectCode === BOOKING_STATUS_CODE.rejected ? (
                <>
                  <div className="process_list">
                      <dl>
                          <dt>재등록 기한</dt>
                          <dd>
                              {/* <input type="text" className="form_control datepicker" placeholder="날짜를 선택해주세요"/> */}
                              <p className="form_control">{terminatedAt}</p>
                          </dd>
                          <dt>반려사유</dt>
                          <dd>
                              <textarea className="form_control" readOnly value={rejectDescription}></textarea>
                          </dd>
                      </dl>
                  </div>
                  </>
                ) : (
                  <>
                  <div className="process_list">
                      <dl>
                          <dt>거절사유</dt>
                          <dd>
                              <textarea className="form_control" readOnly value={rejectDescription}></textarea>
                          </dd>
                      </dl>
                  </div>
                  </>
                )}
            </div>
            <div className="button_wrap btn_length_1">
                <button type="button" className="btn btn_darkblue pop_close" onClick={() => {setIsRejectPop(false); popInit();}}>확인</button>
            </div>
        </div>
      </div>

      {/* 증빙서류 업로드 팝업 */}
      <div id="pop_upload_doc" className="pop_dim_wrap pop_modal pop_sm" style={{ display: isDocumentPop? 'block' : 'none' }}>
            <div className="pop_wrap">
                <div className="title_wrap">
                    <h3>증빙서류 업로드</h3>
                    <button className="pop_btn_close pop_close" onClick={() => setIsDocumentPop(false)}>닫기</button>
                </div>
                <div className="contents_wrap">
                    <ul className="request_date bd0">
                        <li>
                            <strong>배방</strong>
                            <span>{stayGuestData.room_building_name} {stayGuestData.room_building_dong} {stayGuestData.room_name}호</span>
                        </li>
                        <li>
                            <strong>입숙예정일</strong>
                            <span>{stayGuestData.stay_after}</span>
                        </li>
                        {stayData.room_work_site === 2 ? 
                        <li>
                                <button className="btn_sm btn_darkblue btn_download"
                                onClick={() => downloadFile(`${process.env.REACT_APP_MEDIA}/static/사택_보증서입금관련 메뉴얼.pdf`, '')}
                                >
                                  보증서입금 매뉴얼
                                  </button>
                          {/*<a className="btn btn_sm btn_outline" 
                            href={`${process.env.REACT_APP_MEDIA}static/사택_보증서입금관련 메뉴얼.pdf`} download
                            target='_blank'
                          >보증서입금 매뉴얼</a>*/}
                        </li>
                        : ''}
                    </ul>
                    
                    {stayData.status === BOOKING_STATUS_CODE.documentReject? (
                      <div className="process_list">
                        <dl>
                            <dt>증빙서류 재요청</dt>
                            <dd>
                              <textarea className="form_control" value={stayData.status_description} disabled style={{height: '80px'}}/>
                            </dd>
                        </dl>
                      </div>
                    ) :''}

                    <div className="table_design_write col_width2 mt10">
                        <div id="table" className="table">
                            <div className="tbody">
                            <CompanyUploadFileList
                                workSite={stayData.room_work_site}
                                guestType={stayGuestData.guest_type}
                                roomType={stayData.room_type}
                                category={FILE_UPLOAD_CODE.assign}
                                onChange={(files: any) => setDocumentFiles(files)}
                                value={files}
                                alreadyFiles={files}
                                visibleType={DOCUMENT_FILE_VISIBLE_TYPE_CODE.assign}
                                //setisFile={(chkFile: boolean) => setisFile(chkFile)}
                                isSupported={stayGuestData.is_supported}
                                isDocumentPop={isDocumentPop}
                              />
                            </div>
                        </div>
                    </div> {/* table_design_list */}

                </div>
                <div className="button_wrap btn_length_1">
                    <button type="button" className="btn btn_darkblue pop_close" onClick={documentUpload}>적용</button>
                </div>
            </div>
        </div>

      {/* 첨부서류 팝업 */}
      <div id="pop_files" className="pop_dim_wrap pop_modal pop_lg" style={{display: isFilePop? 'block' : 'none'}}>
          <div className="pop_wrap">
              <div className="title_wrap">
                <h3>파일첨부 확인</h3>
                <button className="pop_btn_close pop_close" onClick={() => setIsFilePop(false)}>닫기</button>
              </div>

              <div className="contents_wrap">
                  {/* <FileForm value={props.value} /> */}
                  <div className="table_normal_list">
                  <table >
                      <colgroup>
                          <col width="280" />
                          <col width="*" />
                      </colgroup>
                      <tbody>
                      {!isGroup? 
                        popFileList.map((file: any, fileIdx: number) => (
                            file?.document && (
                            <tr key={fileIdx}>
                                <th>{file?.document?.name}</th>
                                <td>
                                  <button className="btn_sm btn_darkblue btn_download"
                                  onClick={() => downloadFile(`${process.env.REACT_APP_MEDIA}/${file.file.path}`, '')}
                                  >
                                    {file.file.name}
                                    </button>
                                  {/*<a 
                                    href={`${process.env.REACT_APP_MEDIA}/${file.file.path}`} download
                                    target={`${file.file.path}`.split('.').pop() 
                                        && ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(`${file.file.path}`.split('.').pop() || '' )
                                        && !isMobile ? '_blank' : ''}
                                    >{file.file.name}</a>*/}
                                </td>
                            </tr>
                            )
                        ))
                        :
                        popFileList.map((file: any, fileIdx: number) => (
                          file.document.visible_type === FILE_CODE.RFC && (
                          <tr key={fileIdx}>
                              <th>협조전</th>
                              <td>
                                <button className="btn_sm btn_darkblue btn_download"
                                onClick={() => downloadFile(`${process.env.REACT_APP_MEDIA}/${file.file.path}`, '')}
                                >
                                  {file.file.name}
                                  </button>                                
                                {/*<a 
                                  href={`${process.env.REACT_APP_MEDIA}/${file.file.path}`} download
                                  target={`${file.file.path}`.split('.').pop() 
                                      && ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(`${file.file.path}`.split('.').pop() || '' )
                                      && !isMobile ? '_blank' : ''}
                                  >{file.file.name}</a>*/}
                              </td>
                          </tr>
                          )
                        ))
                      }
                      </tbody>
                  </table>
                  </div>
              </div>
          </div>
      </div>
    </>
  );
};

export default LodgeAccplicantPage;