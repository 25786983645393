import React from 'react';

interface Props {
  totalPage : number
  offset: number
  limit: number
  setOffset: Function
}

//번호 페이징
const Pagination = (props: Props) => {
  let range = 10;
  let pages = []

  let currentPage: number = Math.floor(props.offset / props.limit)
  let lastPage = props.totalPage
  
  let viewUnit = Math.floor(currentPage / range);

  for (let i: number = 0; i < range ; ++i) {
      let viewPage = viewUnit * range + i
      if (viewUnit * range + i < lastPage) {
          pages.push(
              <li key={i}>
                  <a href="javascript:void(0);"
                      className={viewPage === currentPage ? "active" : ""}
                      onClick={() => props.setOffset(viewPage * props.limit)}
                  > {viewPage + 1} </a>
              </li>
          )
      }
  }


  const getPrevOffset = (offset: number, limit: number) => {
      let prevOffset = (Math.floor(offset / limit) - 1) * limit
      if (!(prevOffset < 0)) props.setOffset(prevOffset)
  }

  const getNextOffset = (offset: number, limit: number, totalPage: number) => {
      let nextOffset = (Math.floor(offset / limit) + 1) * limit
      if (nextOffset < (totalPage * limit)) props.setOffset(nextOffset)
  }

  return (
      <>
          <li><a href="javascript:void(0);" className="paging_prev" onClick={() => getPrevOffset(props.offset, props.limit)}>paging_prev</a></li>
          {pages.length ? pages : <li><a href="javascript:void(0)"> 1 </a></li>}
          <li><a href="javascript:void(0);" className="paging_next" onClick={() => getNextOffset(props.offset, props.limit, props.totalPage)}>paging_next</a></li>
      </>
  )
}

export default Pagination;
