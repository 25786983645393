import React, { useContext } from 'react';

import { SignupForm, Valid, MustAgreed, VERIFY_STATUS_CODE } from './type.d';
import Signup from '../../components/templetes/signup';

import { MessageContext, MessageImpl } from '../../routes';
import { passwordValid } from '../../utils/validation';
import { VERIFY_CODE } from '../../constant';

const SignupPage = (props: any) => {
  const { info } = useContext<MessageImpl>(MessageContext);

  // 인증번호 보내기 클릭 시 검사
  const prevVerifySendCheckValid = (
    {
      employeeNumber,
      password,
      password2,
      phone,
      emailFront,
      emailBack,
      privacyPolicyAgreed,
      verifyType,
    }: SignupForm,
    isGetPersonnel: boolean,
  ): Valid => {
    let isValid = false;
    let msg = '';

    if (!isGetPersonnel) {
      isValid = true;
      info('사원번호를 가져온 후 인증을 진행할 수 있습니다.');
    } else if (!(phone.length === 11) && verifyType === VERIFY_CODE.phone) {
      isValid = true;
      info(
        '휴대폰 번호 항목이 올바르지 않습니다. 휴대폰 번호는 11자리여야 합니다.',
      );
    } else if (
      !employeeNumber ||
      !password ||
      !password2 ||
      !phone ||
      !emailFront ||
      !emailBack
    ) {
      isValid = true;
      info('모든 항목을 입력해야 인증을 진행할 수 있습니다.');
    }

    return { isValid, msg };
  };

  // 인증확인 버튼 클릭
  const prevVerifyCheckValid = (
    verityStatus: number,
    verifyNumber: string,
  ): Valid => {
    let isValid = false;
    let msg = '';

    if (verityStatus !== VERIFY_STATUS_CODE.request) {
      isValid = true;
      info('인증번호를 발송하지 않았습니다.');
    } else if (!(verifyNumber.length === 6)) {
      isValid = true;
      info('인증번호 항목이 비었거나 올바르지 않습니다.');
    }

    return { isValid, msg };
  };

  // 회원가입
  const prevSignupCheckValid = (
    {
      employeeNumber,
      password,
      password2,
      phone,
      emailFront,
      emailBack,
      privacyPolicyAgreed,
    }: SignupForm,
    { termAgreed, privacyAgreed }: MustAgreed,
    verityStatus: number,
  ): Valid => {
    let isValid = false;
    let msg = '';
    if (verityStatus !== VERIFY_STATUS_CODE.complete) {
      isValid = true;
      info('인증을 완료하지 않았습니다.');
    }

    if (!password || !password2) {
      isValid = true;
      info('비밀번호가 비었습니다.');
    } else if (!(password === password2)) {
      isValid = true;
      info('비밀번호가 일치하지 않습니다.');
    } else if (passwordValid(password, password2)) {
      isValid = true;
      info(
        '비밀번호는 영문, 특수문자(!, @, #, $, %, ^, &, *), 숫자를 조합하여 입력바랍니다. \n비밀번호는 8~20자리까지 입력할 수 있습니다.',
      );
    } else if (!termAgreed || !privacyAgreed) {
      isValid = true;
      info('동의하지 않은 이용약관이 있습니다.');
    }

    return { msg, isValid };
  };

  return (
    <>
      <Signup
        {...props}
        prevVerifySendCheckValid={prevVerifySendCheckValid}
        prevVerifyCheckValid={prevVerifyCheckValid}
        prevSignupCheckValid={prevSignupCheckValid}
      />
    </>
  );
};

export default SignupPage;
