import React, {useState, useEffect} from 'react';

import Pagination from '../../organismas/pagination';
import MoreBtn from '../../organismas/pagination/more';

import StatusTag from '../../atoms/tag/statusTag';

import { LODGE_STATUS, LODGE_STATUS_CODE, ROOM_TYPE, ROOM_TYPE_CODE, STATUS_CODE, WORK_SITE_CODE } from '../../../constant';

import { formatByString_YYYYMMDD } from '../../../utils/date';

import { lodge } from '../../../apis';

import { getTodayDate } from '../../../utils/date';
import { getViewPageIdx } from '../../../utils/commons';
import { SHOW_MODAL } from '../../../store/modules/modal';

import DeductDetail from '../../../components/organismas/popup/deduct';

interface Props {
  setLodgingGuest?: Function 
  isPaginationView?: Boolean
  onItemClick?: Function
  offset: number
  limit: number
  roomType?: number
  searchOption?: {
    option: string
    text: string
  }
  history : any
}

//이용자 정보 table
const GuestTable = (props: Props) => {
  const today = getTodayDate();
  const [guests, setGuests] = useState([]);
  const [reload, setReload] = useState<boolean>(false);

  const [offset, setOffset] = useState<number>(props.offset || 0);
  const [limit, setLimit] = useState<number>(props.limit || 1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isShowDeductPop, setIsShowDeductPop] = useState<boolean>(false); //공제 팝업

  useEffect(() => {
    (async () => {
      try {
        let data: any = await fetchData(offset, limit);
        setGuests(data.results);
        setTotalPage(Math.ceil(data.count / limit));
        setTotalCount(data.count);
      }catch ({ status, data }) {
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }
    })()
  }, [offset, props.roomType, limit, props.searchOption]);

  const fetchData = async (offset: number=0, limit: number=10) => {
    const payload: any = {
      offset,
      limit,
      status: [LODGE_STATUS_CODE.checkin, LODGE_STATUS_CODE.checkout].join(',')
    };

    if (props.roomType) {
      payload.room_type = props.roomType
    }

    if (props?.searchOption?.option) {
      payload[props?.searchOption?.option] = props?.searchOption?.text
    }

    try {
      const { data }: any = await lodge.getGuest(payload);
      return data
    }catch ({ status, data }) {
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  }

  const onItemClickHandler = (guest: any) => {
    if (props.onItemClick) {
      props.onItemClick(guest)
    }
  }
  const onDeductHandler = (guest: any) => {  
    props.history.location.state = guest;
    setIsShowDeductPop(true);
  }

  
  //휴대폰 번호 하이픈 처리
  const cellPhoneHyphen = (data : any) => {
    if(data !== undefined &&  data > 0) {
        let phone = data.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3");
        
        return phone;
    }
    return '';
  };

  return (
    <>
      <div className="pc_view">
        <div className="table_normal_list mt30" style={{overflowX: "auto"}}>
          <table>
            <caption className="sr_only">이용관리 목록</caption>
            {guests.length ? (
            <colgroup>
              <col width="60px" />
              <col width="120px" />

              <col width="120px" />
              <col width="120px" />
              <col width="120px" />
              {/* <col width="120px" /> */}

              {/* 신청자
              <col width="120px" />
              <col width="120px" />
              <col width="120px" />
              */}
              <col width="120px" />
              {/*<col width="120px" />*/}
              <col width="120px" />
              <col width="120px" />
              <col width="120px" />
              <col width="120px" />
              <col width="120px" />
              <col width="120px" />
              <col width="120px" />
              <col width="120px" />
              <col width="120px" />
              <col width="120px" />
              <col width="120px" />
              <col width="120px" />
              <col width="120px" />
              <col width="120px" />
              <col width="120px" />

              {/*<col width="120px" />
              <col width="120px" />
              <col width="120px" />
              */}

              {/* <col width="120px" /> */}
            </colgroup>
            ) : ''}
            <thead>
              <tr>
                <th rowSpan={2}>번호</th>
                <th rowSpan={2}>서비스 구분</th>
                <th colSpan={3}>신청 기본정보</th>
                {/*<th colSpan={3}>신청자</th>*/}
                <th colSpan={16}>이용자</th>
                {/*<th colSpan={3}>신청자</th>*/}
                {/* <th rowSpan={2}>비고</th> */}
              </tr>
              <tr>
                {/* 신청기본정보 */}
                <th>신청일자</th>
                <th>숙소유형</th>
                <th>신청지역</th>
                {/* <th>진행상태</th> */}

                {/* 신청자 
                <th>성명</th>
                <th>부서명</th>
                <th>휴대폰</th>
                */}

                {/* 이용자 */}
                <th>숙소정보(동호수)</th>
                <th>입숙일</th>
                <th>퇴숙예정일</th>
                <th>만료일</th>
                <th>퇴숙일</th>
                {/*<th>동</th>
                <th>호</th>*/}
                <th>성명</th>
                <th>성별</th>
                <th>휴대폰</th>
                <th>공제내역</th>
                <th>소속회사 유형</th>
                <th>회사명/단체명</th>
                <th>소속부서</th>
                <th>사번</th>
                <th>직책</th>
                <th>직종</th>
                <th>근무조</th>

                {/* 신청자
                <th>회사명</th>
                <th>직책</th>
                <th>사번</th>
                */}
              </tr>
            </thead>
            <tbody>
              {/*데이타 없을 시*/}

              {guests.length
                ? (guests.map((guest: any, guestIdx: number) => (
                  <tr className="" key={guestIdx}>
                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{getViewPageIdx(offset, limit, guestIdx)}</td>
                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >
                      {/* <StatusTag status={guest.status} /> */}
                      {guest.status === LODGE_STATUS_CODE.checkin? (<span className="state_room case1">이용중</span>)  
                        : guest.status === LODGE_STATUS_CODE.checkout? (<span className="state_room case6">퇴숙</span>) : '' }
                    </td>

                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{formatByString_YYYYMMDD(guest.booking.created_at)}</td>
                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)}> {ROOM_TYPE[guest.room_type]}</td>
                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{guest.room_work_site}</td>
                    {/* <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} ><StatusTag status={guest.status} /></td> */}

                    {/*<td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{guest.booking.applicant_name}</td>
                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{guest.booking.applicant_department}</td>
                <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{guest.booking.applicant_cellphone}</td>*/}

                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{guest.room_building_name}{guest.room_building_dong}{guest.room_name}</td>
                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{guest.checkin_at}</td>
                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{guest.stay_before}</td>
                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >
                        {guest.room_type === ROOM_TYPE_CODE.dormitory_room || guest.room_type === ROOM_TYPE_CODE.company_room? guest.expired_at: ''}
                    </td>
                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{guest.checkout_at}</td>
                    {/*<td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} ></td>
                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} ></td>*/}
                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{guest.guest_name}</td>
                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{parseInt(guest.guest_gender) === 1 ? '남' : '여'}</td>
                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{cellPhoneHyphen(guest.guest_cellphone)}</td>
                    <td style={{cursor: props.onItemClick? "pointer": ""}} >
                        {guest.room_type === ROOM_TYPE_CODE.company_room || guest.room_type === ROOM_TYPE_CODE.dormitory_room 
                         || (guest.room_type === ROOM_TYPE_CODE.business_room && WORK_SITE_CODE[guest.room_work_site] === WORK_SITE_CODE.울산 && guest.status === LODGE_STATUS_CODE.checkout)
                          ? <button className="btn btn_sm btn_gray left" onClick={()=>{onDeductHandler(guest);}}>상세내역</button>
                          :<></> 
                        }
                    </td>
                    
                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} ></td>
                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{guest.guest_company}</td>
                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{guest.guest_department}</td>
                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{guest.room_type === 1 ? guest.booking.applicant_employee_number : guest.guest_employee_number}</td>
                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{guest.guest_position}</td>
                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{guest.guest_occupation}</td>
                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{guest.guest_party}</td>

                    {/*<td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} ></td>
                    <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{guest.booking.applicant_position}</td>
                  <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{guest.booking.applicant_employee_number}</td>*/}

                    {/* <td style={{cursor: props.onItemClick? "pointer": ""}}onClick={() => onItemClickHandler(guest)} >{guest.memo}</td> */}

                  </tr>
                )))
                : (
                  <tr>
                    <td colSpan={21} className="blank_area type_white">
                      <p className="txt_blank">이용내역이 존재하지 않습니다.</p>
                    </td>
                  </tr>
                )
              }

            </tbody>
          </table>
        </div>

        {props.isPaginationView && 
          <div className="board_paging">
            <ul>
              <Pagination
                offset={offset}
                limit={limit}
                setOffset={setOffset}
                totalPage={totalPage}
              />
            </ul>
          </div>
        }

      </div>

      <div className="mb_view">
        {guests.length === 0 ? (
          <div className="blank_area">
              <p className="txt_blank">데이터가 없습니다.</p>
          </div>
        ) : (
          <div className="lst_apply">
            <dl>
              {guests.map((guest: any, guestIdx: number) => (
                <React.Fragment key={guestIdx}>
                  <dt onClick={() => onItemClickHandler(guest)}>
                    <a href="javascript:void(0);">
                      {/* <StatusTag status={guest.status} /> */}
                      {guest.status === LODGE_STATUS_CODE.checkin? (<span className="state_room case3">이용중</span>) 
                          : guest.status === LODGE_STATUS_CODE.checkout? (<span className="state_room case3">퇴숙</span>) : '' }
                      <span className="title ml10">{guest.room_work_site}</span>
                    </a>
                  </dt>
                  <dd>
                    <ul className="lst_item">
                      <li>
                        <p>숙소유형</p>
                        <p>{ROOM_TYPE[guest.room_type]}</p>
                      </li>
                      <li>
                        <p>
                          <em>건물명</em>
                          <em>동호수</em>
                        </p>
                        <p>
                          <em>{guest.room_building_name}</em>
                          <em>{guest.room_building_dong} {guest.room_name}</em>
                        </p>
                      </li>
                      <li>
                        <p>입숙일자</p>
                        <p>{guest.checkin_at}</p>
                      </li>
                      <li>
                        <p>만료일자</p>
                        <p>{guest.room_type === ROOM_TYPE_CODE.dormitory_room || guest.room_type === ROOM_TYPE_CODE.company_room? guest.expired_at: ''}</p>
                      </li>
                      <li>
                        <p>퇴숙일자</p>
                        <p>{guest.checkout_at}</p>
                      </li>
                      {guest.room_type === ROOM_TYPE_CODE.company_room || guest.room_type === ROOM_TYPE_CODE.dormitory_room 
                        || (guest.room_type === ROOM_TYPE_CODE.business_room && WORK_SITE_CODE[guest.room_work_site] === WORK_SITE_CODE.울산 && guest.status === LODGE_STATUS_CODE.checkout)
                        ? 
                        <li>
                          <p>공제내역</p>
                          <p onClick={()=>{onDeductHandler(guest);}}><span className="txt_link">상세내역</span></p>
                          {/*<button className="btn btn_sm btn_gray left" onClick={()=>{onDeductHandler(guest);}}>상세내역</button>*/}
                        </li>
                        :<></> 
                      }
                      <li>
                        <p>비고</p>
                        <p></p>
                      </li>
                    </ul>
                  </dd>
                </React.Fragment>
              ))}

            </dl>
          </div>
        )}

        {props.isPaginationView && 
          <MoreBtn
            offset={offset}
            limit={limit}
            setLimit={setLimit}
            totalCount={totalCount}
            /*fetchData={fetchData}
            data={guests}
            onClick={(reqGuests: any) => setGuests(guests.concat(reqGuests))} */
          />
        }

      </div>

      {/* 공제내역 */}
      <DeductDetail
        isShowDeductPop={isShowDeductPop}
        deductData={props}
        onClose={() => setIsShowDeductPop(false)}
      />
    </>

  );
};

export default GuestTable;
