import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { SHOW_MODAL } from '../../../store/modules/modal';

import '../signin/index.scss';

const ForceSignout = (props: any) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if(isMobile){
      props.history.push('/');
    }else {
      dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '토큰만료',
          msg: '보안을 위하여 다시 로그인해 주시기 바랍니다.',
          redirectPath: '/',
          statusCode: 401,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
    }
  }, []);

  return (
    <div id="wrap" className="wrap_login">
      <div id="container">
        <h1 className="ir logo">WESN AMS Platform - West East South North</h1>
        <div className="row">
          <div className="col"></div>
          <div className="col">
            <div className="group_login">

              <h2 className="title pc">통합숙소관리시스템</h2>
              <h2 className="title mobile">통합숙소관리시스템</h2>
              <ul className="form_login clearfix">
                <li>
                  <label htmlFor="input_id">ID</label>
                  <p><input type="text" id="input_id" className="form_control form_lg" placeholder="아이디를 입력해주세요" /></p>
                </li>
                <li>
                  <label htmlFor="input_pw">PASSWORD</label>
                  <p><input type="password" id="input_pw" className="form_control form_lg" placeholder="비밀번호를 입력해주세요" /></p>
                </li>
              </ul>

              <div className="submit">
                <button className="btn btn_xlg btn_darkblue">로그인</button>
                <button className="btn btn_xlg btn_outline">회원가입</button>

                <ul className="lst_srch">
                  <li><a href="javascript:void(0);" className="btn btn_sm btn_link">아이디찾기</a></li>
                  <li><a href="javascript:void(0);" className="btn btn_sm btn_link">비밀번호찾기</a></li>
                </ul>
              </div>

              <ul className="lst_membership">
                <li><button className="btn btn_outline">가입관련문의</button></li>
                <li><button className="btn btn_outline">이용약관</button></li>
                <li><button className="btn btn_outline">개인정보처리방침</button></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForceSignout;
