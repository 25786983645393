import React, { useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import useWorkSite from '../../hooks/useWorkSite';

import { MessageContext, ApiErrorHandleContext, ApiErrorHandleImpl, MessageImpl } from '../../routes';

import ConfirmBtn from '../../components/atoms/button/confirm';
import SelectCompany from '../../components/atoms/select/company';
import WorkSiteSelect from '../../components/atoms/select/workSite';

import Calendar from '../../components/molecules/calendar';
import GuestType from '../../components/molecules/tab/guestType';
import {DownloadButton } from '../../components/molecules/download';

import TermsDetail from '../../components/organismas/termsDetail';

import intl from 'react-intl-universal';

import {
  isAfterAndSame,
  getTodayDate,
  getNextDay,
  getPrevDay,
  formatByString_YYYYMMDD,
  leftBig
} from '../../utils/date';

import { GENDER, FILE_CODE, ROOM_TYPE_CODE, GUEST_TYPE } from '../../constant';
import { auth, core } from '../../apis';

import './step1.scss';

import { AgGridColumn, AgGridReact } from '@ag-grid-community/react';
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import 'ag-grid-enterprise';
import "@ag-grid-community/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-community/all-modules/dist/styles/ag-theme-alpine.css";
import NumericEditor from './numericEditor.jsx';
import EmployeeNumberInput from '../../components/atoms/input/employeeNumber';
import OnOff from '../../apis/onOff';
import { SHOW_MODAL } from '../../store/modules/modal';

interface Rfc {
  id?: number
  path?: string
  name?: string
}

interface Excel {
  id: number,
  path: string,
  data: [any]
}

const EXCEL_IDX = 'excelIdx';

const Step1 = (props: any) => {
  const todayDate = getTodayDate();
  const { info }: MessageImpl = useContext(MessageContext);
  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);

  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user
  }));

  const [guestType, setGuestType] = useState<number>(1);
  const [employeeNumber, setEmployeeNumber] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [department, setDepartment] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [gender, setGender] = useState<number>(1);
  const [mealTicket, setMealTicket] = useState<number>(0);
  const [addmealTicket, setAddMealTicket] = useState<number>(0);
  const [dateAfter, setDateAfter] = useState<string>(todayDate[0]);
  const [dateBefore, setDateBefore] = useState<string>(
    getNextDay(todayDate[0])[0],
  );
  const [isLeader, setIsLeader] = useState<boolean>(false);
  const [carNumber, setCarNumber] = useState('');
  const [privacyPolicyAgreed, setPrivacyPolicyAgreed] = useState<boolean>(
    false,
  );
  const [
    personalInformationCollectionAgreed,
    setPersonalInformationCollectionAgreed,
  ] = useState<boolean>(false);

  const [company, setCompany] = useState<string>('');

  const [checkUsersIdx, setCheckUsersIdx] = useState<any>({}); // idx를 키값으로 가진다.
  const [allCheck, setAllCheck] = useState<boolean>(false);

  // 협조전 input state
  const [rfcFileText, setRfcFileText] = useState<string>(props.rfc?.name);

  // 팝업 상태
  const [isDeletePop, setIsDeletePop] = useState<boolean>(false);
  const [isCalendar, setIsCalendar] = useState<boolean>(false);

  //const [excelFiles, setExcelFiles] = useState<any>([]);
  // const [ worksites, setUserWorkSiteByWorksite, setRoomTypeByWorksite ] = useWorkSite({
  //   roomType: ROOM_TYPE_CODE.business_room_group,
  //   userWorkSite: userInfo.worksite
  // })
  const [ worksites, setWorksites ] = useState<any>([]);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [isClickRow, setIsClickRow] = useState<any>();
  const [isEditorChk, setIsEditorChk] = useState<boolean>(true);

  const onGridReady = (params : any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  }

  useEffect(() => {
    (async () => {
      try{
        if(userInfo.worksite !== 0){
          // 사업장 그리기
          let { data } = await OnOff.getWorksite({
            // work_site: props.value,
            user_work_site: userInfo.worksite,
            room_type: ROOM_TYPE_CODE.business_room_group
          });
  
          setWorksites(data);
          
          // 초기 사업장 선택
          if(data.filter((d: any) => d.work_site === userInfo.worksite).length > 0){
            props.setWorkSite(userInfo.worksite);
          }else if(data[0].work_site) {
            props.setWorkSite(data[0].work_site);
          }
        }
      }catch ({ status, data }) {
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }
    })();
  }, [userInfo.worksite]);

  useEffect(() => {
    //props.setRoomType(ROOM_TYPE_CODE.business_room_group);
    if (worksites.length) {
      //props.setWorkSite(0)
      let tempChk = false;
      worksites.forEach((workplace: any, workplaceIdx: number) =>{
        if(props.workSite === workplace.work_site) {
          //props.setWorkSite(props.workSite);  
          tempChk = true;
        }
      });

      if(!tempChk) {
        //props.setWorkSite(worksites[0].work_site);
      }
    }
  }, [worksites])

  // useEffect(() => {
  //   setUserWorkSiteByWorksite(userInfo.worksite);
  // }, [userInfo.worksite]);

  const init = () => {
    setEmployeeNumber('');
    setName('');
    setDepartment('');
    setCompany('');
    setPhone('');
    setGender(1);
    //setMealTicket(0);
    setAddMealTicket(0);
    setDateAfter(todayDate[0]);
    setDateBefore(getNextDay(todayDate[0])[0]);
    setIsLeader(false);
    setCarNumber('');
    setPrivacyPolicyAgreed(false);
    setPersonalInformationCollectionAgreed(false);
    
    if(props.workSite === 2) {
      setIsEditorChk(false);
    }
  };

  //const onCheckedUser = (e: any, guestIdx: number) => {
  const onCheckedUser = (e: any) => {
    
    //const temp = { ...checkUsersIdx };
    const temp : any = [];
    if(e.api.getSelectedNodes().length > 0) {
      e.api.getSelectedNodes().forEach((node:any) => {
        temp[node.rowIndex] = true;
      }) 
    }

    //if (e.node.isSelected()) temp[guestIdx] = true;
    //else delete temp[guestIdx];
    
    setCheckUsersIdx(temp);
  };

  const onAddUserHandler = (e: any) => {
    {/*if (guestType === 1) {
      if (!employeeNumber) {
        info(intl.get('사번을 검색하여 이용자 정보를 등록해주세요.'));
        return;
      }
    }*/}
    
    const { isValid, msg } = props.isAddedUserCheck({
      privacyPolicyAgreed, personalInformationCollectionAgreed, name, phone, dateAfter, dateBefore, gender
    })

    if (isValid) return;
    
    const user: any = {
      work_site: props.workSite,
      guest_employee_number: employeeNumber,
      guest_name: name,
      guest_gender: gender,
      guest_cellphone: phone,
      guest_department: department,
      stay_after: dateAfter,
      stay_before: dateBefore,
      //meal_ticket_request_count: 0,
      is_leader: isLeader,
      guest_type: guestType,
      guest_company: company,
      guest_car_number1: carNumber,
    };

    if(privacyPolicyAgreed) {
      user.privacyAgree = 'Y'
    } else {
      user.privacyAgree = 'N'
    }

    if(personalInformationCollectionAgreed) {
      user.personalAgree = 'Y'
    } else {
      user.personalAgree = 'N'
    }

    //if (!carNumber) delete user['guest_car_number1']
    if (guestType === 1) {
      // if (!employeeNumber) {
      //   //info(intl.get('사번을 검색하여 이용자 정보를 등록해주세요.'));
      //   info(intl.get('사번 항목이 비었습니다.'));
      //   return;
      // } else 
      if (!department) {
        info(intl.get('부서 항목이 비었습니다.'));
        return;
      }
      //사내 임직원일때에 회사명 change 가 안됬을경우
      if(!company) {
        user['guest_company'] = "현대자동차";
      }
      //delete user['guest_company'];
    } else if (guestType === 2) {
      if(!company) {
        info(intl.get('회사 항목을 선택해주세요.'));
        return;
      }else if (!employeeNumber) {
        info(intl.get('사번 항목이 비었습니다.'));
        return;
      } else if (!department) {
        info(intl.get('부서 항목이 비었습니다.'));
        return;
      }
      // delete user['is_leader'];
    } else if (guestType === 3) {
      // delete user['is_leader'];
      if(!company) {
        info(intl.get('회사 항목이 비었습니다.'));
        return;
      }
      // delete user['guest_department'];
      // delete user['guest_employee_number'];
      // delete user['guest_company'];
    }
    
    let tmpCount = addmealTicket + mealTicket;
    setMealTicket(tmpCount); 
    props.addGuest(user, 1);

    init();
  };

  const onAllCheckHandler = (e: any) => {
    let temp = {};
    if (e.target.checked) {
      temp = props.guests.reduce((acc: any, curVal: any, idx: number) => {
        acc[idx] = true;
        return acc;
      }, {});
    }
    if(allCheck) setAllCheck(false)
    else setAllCheck(true);

    //console.log(temp);

    setCheckUsersIdx(temp);
  };

  const onDeleteUserHandler = (e?: any) => {
    props.deleteGuest(checkUsersIdx);
    setCheckUsersIdx({});
    setIsDeletePop(false);
    setAllCheck(false);

    // 식권 재 카운팅
    let mealCount:number = 0;
    props.guests.map((guest: any, idx: number) => {
      if(!checkUsersIdx[idx]) {
        mealCount = mealCount + Number(guest.meal_ticket_request_count);
      }
    });

    setMealTicket(mealCount);

    if(checkUsersIdx.length === props.guests.length) {
      props.setExcelFiles([]);
    }
  };

  const guestFormalize = (guests: any) => props.guests
  .map((guest: any, idx: number) => {
    guest.guest_type = guest.guest_type ? guest.guest_type : '';
    guest.guest_gender =
      guest.guest_gender === '남' || guest.guest_gender === '남자' ? 1 : 
      guest.guest_gender === '여' || guest.guest_gender === '여자' ? 2 : guest.guest_gender; 
    guest.is_leader =
      guest.is_leader === '여' ? true : 
      guest.is_leader === '부' ? false : guest.is_leader;
    guest.meal_ticket_request_count = parseInt(guest.meal_ticket_request_count);
    guest.stay_after = guest.stay_after && formatByString_YYYYMMDD(guest.stay_after.replace(/\./g, '-')) || '';
    guest.stay_before = guest.stay_before && formatByString_YYYYMMDD(guest.stay_before.replace(/\./g, '-')) || '';
    guest.guest_car_number1 = guest.guest_car_number1 ? guest.guest_car_number1 : '';
    guest.guest_company = guest.guest_company ? guest.guest_company : '';
    
    return guest;
  });

  const nextStep = () => {
   
    // console.log(user);

    //console.log(props.guests);
    /*if (!privacyPolicyAgreed || !personalInformationCollectionAgreed) {
      info(intl.get('서비스이용약관 동의 또는 개인정보이용동의를 동의하지 않았습니다.'));
      return;
    }*/
    
    if (!Object.keys(props.rfc).length) {
      info(intl.get('협조전이 등록되어있지 않습니다.\n 협조전 파일을 등록해주세요.'));
      return
    }
    let { isValid } = props.isOverlapping(props.guests);
    //console.log(isValid)
    if (isValid) return

    if (!props.guests.length) {
      info(intl.get('추가된 이용자가 없습니다.'));
      return;
    }

    let guests : any = guestFormalize(props.guests);
    
    props.setGuests(guests);

    // 전체 식권수 등록
    props.setMealTicket(mealTicket);

    let possibleNext : boolean = true;
    let isLeaderCnt: number = 0;
    
    for(let idx=0; idx<guests.length; idx++) {
      let excelRow = idx;

      if(guests[idx].is_leader) {
        isLeaderCnt++;
      }

      if(props.workSite === 1) { //울산일 경우
        // if(guests[idx].guest_type === 3) {
        //   info(intl.get('이용 사업장이 울산일 경우 현대차직원, 계열사 직원으로만 신청 가능합니다'));
        //   possibleNext = false;
        //   return;
        // }
      }else if(props.workSite === 2) { //남양일 경우
        if(guests[idx].guest_type !== 1) {
          info(intl.get('이용 사업장이 남양연구센터일 경우 현대차직원으로만 신청 가능합니다.'));
          possibleNext = false;
          return;
        }
      }else if(props.workSite === 3) { //전주일 경우
        if(guests[idx].guest_type === 3) {
          info(intl.get('이용 사업장이 전주공장일 경우 현대차직원, 계열사직원으로만 신청 가능합니다'));
          possibleNext = false;
          return;
        }
      }else if (!([guests[idx].guest_name, guests[idx].guest_cellphone, guests[idx].guest_gender, guests[idx].stay_before, guests[idx].stay_after].every((item) => item))) {
        info(excelRow+1 + intl.get('행에서 빈항목이 있습니다.\n(성명, 휴대폰번호, 입숙일, 퇴숙일, 성별, 인솔자 여부)'));
        possibleNext = false;
        return;
      }else if(guests[idx].guest_gender !== 1 && guests[idx].guest_gender !== 2){
        info(excelRow+1 + intl.get('행에서 성별 항목이 잘못되었습니다.'));
        possibleNext = false;
        return;
      }else if (!/[^0-9]*$/gi.test(guests[idx].guest_cellphone) || !(guests[idx].guest_cellphone.length === 11)) {
        info(excelRow+1 + intl.get('행에서 휴대폰번호 항목이 잘못되었습니다.'));
        possibleNext = false;
        return;
      }else if(!/[^0-9]*$/gi.test(guests[idx].guest_employee_number)) {
        info(excelRow+1 + intl.get('행에서 사번이 잘못되었습니다.'));
        possibleNext = false;
        return;
      }else if(isAfterAndSame(guests[idx].stay_before, guests[idx].stay_after)) {
        info(excelRow+1 +intl.get( '행에서 날짜가 잘못되었습니다.'));
        possibleNext = false;
        return;
      }else if(leftBig(guests[idx].stay_after, guests[idx].stay_before)) {
        info(excelRow+1 +intl.get( '행에서 날짜가 잘못되었습니다.'));
        possibleNext = false;
        return;
      }
      // else if(guests[idx].work_site != 1) {
      //   if(!guests[idx].meal_ticket_request_count) guests[idx].meal_ticket_request_count = 0;
      //   if(!/[^0-9]*$/gi.test(guests[idx].meal_ticket_request_count)) {
      //     info(excelRow+1 + intl.get('행에서 식권 수가 잘못되었습니다.'));
      //     possibleNext = false;
      //     return;
      //   }
      // }
    }

    if(isLeaderCnt <=0 ) {
      info(intl.get('이용자 중 인솔자는 반드시\n 1명 이상 선택되어야 합니다.\n 인솔자 여부를 확인해주세요.'));
      possibleNext = false;
      return;
    }
    
    if(possibleNext) {
      props.nextStep();
    }
  };

  const onUploadHandler = async (e: any) => {
    //console.log('onUploadHandler')
    let file = e.target.files[0]
    let splited = file.name.split('.')
    if (splited[splited.length - 1] !== 'xlsx' && splited[splited.length - 1] !== 'xls') {
      let msg = intl.get("업로드하신 엑셀파일의 형태가 맞지 않거나 입력정보가 올바르지 않아서 인원추가에 실패하였습니다. \n양식다운로드 하신 파일을 이용해 주시기 바랍니다.");
      info(msg);
      return
    }
    let guests: any = []

    try {
      var excel = new FormData();
      excel.append("path", file);
      // excel.append("menu_type", FILE_CODE["EXCEL"].toString())
      let { data } = await core.fileConvertGuests(excel);
      
      // console.log("first try");
      // guests.forEach((guest:any, idx:number) => {
      //   //console.log(Object.keys(guest).length);
      //   if(Object.keys(guest).length > 0) {
      //     info(idx+1+'행에서 오류가 발생했습니다.');
      //     //guests = [];
      //     return;
      //   }
      // });

      guests = data.data || [];
      

    } catch ({status, data}) {
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      let msg = intl.get("업로드하신 엑셀파일의 형태가 맞지 않거나 입력정보가 올바르지 않아서 인원추가에 실패하였습니다. \n양식다운로드 하신 파일을 이용해 주시기 바랍니다.");
      info(msg);
    }

    try {
      
      if(guests.length > 0) {
        //console.log('=============================================d', guests)
        let mealCount: number = 0;
        let errorRows : String = '';
        for(let idx=0; idx<guests.length; idx++) {
          let excelRow = idx;
          // if(props.workSite === 1 && guests[idx].guest_type === '외부인') { //울산일 경우
          //     errorRows = (intl.get('이용 사업장이 울산일 경우 현대차직원, 계열사 직원으로만 신청 가능합니다'));
          //     info(intl.get('업로드한 파일에 오류가 있습니다.\n')+"("+errorRows+")");
          //     return;
          // }else 
          if(props.workSite === 2 && guests[idx].guest_type !== '사내임직원') { //남양일 경우
              errorRows = (intl.get('이용 사업장이 남양연구센터일 경우 현대차직원으로만 신청 가능합니다.다'));
              info(intl.get('업로드한 파일에 오류가 있습니다.\n')+"("+errorRows+")");
              return;
          }else if(props.workSite === 3 && guests[idx].guest_type === '외부인') { //전주일 경우
            errorRows = (intl.get('이용 사업장이 전주공장일 경우 현대차직원, 계열사직원으로만 신청 가능합니다'));
            info(intl.get('업로드한 파일에 오류가 있습니다.\n')+"("+errorRows+")");
            return;
          }else if (!([guests[idx].guest_name, guests[idx].guest_cellphone, guests[idx].guest_gender, guests[idx].stay_before, guests[idx].stay_after, guests[idx].is_leader].every((item) => item))) {
            errorRows = (excelRow+1 + intl.get('행에서 빈항목이 있습니다.\n(성명, 휴대폰번호, 입숙일, 퇴숙일, 성별, 인솔자 여부)'));
            info(intl.get('업로드한 파일에 오류가 있습니다.\n')+"("+errorRows+")");
            return;
          }else if(guests[idx].guest_gender !== '남' && guests[idx].guest_gender !== '남자'
           && guests[idx].guest_gender !== '여' && guests[idx].guest_gender !== '여자'
          ){
            errorRows = (excelRow+1 + intl.get('행에서 성별 항목이 잘못되었습니다.'));
            info(intl.get('업로드한 파일에 오류가 있습니다.\n')+"("+errorRows+")");
            return;
          }else if (!/[^0-9]*$/gi.test(guests[idx].guest_cellphone) || !(guests[idx].guest_cellphone.length === 11)) {
            errorRows = (excelRow+1 + intl.get('행에서 휴대폰번호 항목이 잘못되었습니다.'));
            info(intl.get('업로드한 파일에 오류가 있습니다.\n')+"("+errorRows+")");
            return;
          }else if(!/[^0-9]*$/gi.test(guests[idx].guest_employee_number)) {
            errorRows = (excelRow+1 + intl.get('행에서 사번이 잘못되었습니다.'));
            info(intl.get('업로드한 파일에 오류가 있습니다.\n')+"("+errorRows+")");
            return;
          }else if(isAfterAndSame(guests[idx].stay_before, guests[idx].stay_after)) {
            errorRows = (excelRow+1 +intl.get( '행에서 날짜가 잘못되었습니다.'));
            info(intl.get('업로드한 파일에 오류가 있습니다.\n')+"("+errorRows+")");
            return;
          }else if(leftBig(guests[idx].stay_after, guests[idx].stay_before)) {
            errorRows = (excelRow+1 +intl.get( '행에서 날짜가 잘못되었습니다.'));
            info(intl.get('업로드한 파일에 오류가 있습니다.\n')+"("+errorRows+")");
            return;
          }else if(guests[idx].work_site != 1) {
            if(!guests[idx].meal_ticket_request_count) guests[idx].meal_ticket_request_count = 0;
            if(!/[^0-9]*$/gi.test(guests[idx].meal_ticket_request_count)) {
              errorRows = (excelRow+1 + intl.get('행에서 식권 수가 잘못되었습니다.'));
              info(intl.get('업로드한 파일에 오류가 있습니다.\n')+"("+errorRows+")");
              return;
            }
          }else if(guests[idx].is_leader.toLowerCase() !== 'y' && guests[idx].is_leader.toLowerCase() !== 'yes'
            && guests[idx].is_leader.toLowerCase() !== 'n' && guests[idx].is_leader.toLowerCase() !== 'no'
          ){
            errorRows = (excelRow+1 + intl.get('행에서 인솔자 여부 항목이 잘못되었습니다.'));
            info(intl.get('업로드한 파일에 오류가 있습니다.\n')+"("+errorRows+")");
            return;
          }
          
          mealCount += guests[idx].meal_ticket_request_count;
          //console.log('mealCount>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', mealCount);
        }
        setMealTicket(mealCount+Number(mealTicket));
        //console.log('mealTicket>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', mealTicket);

        if(errorRows === '') {
          guests = guests
          .map((guest: any, idx: number) => {
              guest.guest_gender =
                guest.guest_gender === '남' || guest.guest_gender === '남자' ? 1 : 2;
              guest.is_leader =
                guest.is_leader.toLowerCase() === 'y' ||
                  guest.is_leader.toLowerCase() === 'yes'
                  ? true
                  : false;
              guest.meal_ticket_request_count = parseInt(guest.meal_ticket_request_count);
              guest.stay_after = guest.stay_after && formatByString_YYYYMMDD(guest.stay_after.replace(/\./g, '-')) || '';
              guest.stay_before = guest.stay_before && formatByString_YYYYMMDD(guest.stay_before.replace(/\./g, '-')) || '';
              guest.guest_type =
                guest.guest_type === '사내임직원'
                  ? 1
                  : guest.guest_type === '계열사임직원'
                    ? 2
                    : 3;
              guest.privacyAgree = "Y";     // 서비스, 개인정보 이용 동의
              guest.personalAgree = "Y";
              guest[EXCEL_IDX] = props.excelFiles.length;
              return guest;
            
          });
          // .filter((guest: any, idx: number) =>
          //   guest.guest_name && guest.stay_after && guest.stay_before
          // ).map((guest: any, idx: number) => { return deleteByEmptyValue(guest) });

          props.setExcelFiles(props.excelFiles.concat(file.name));
          props.addGuest(guests, 2);
        }
      }
    } catch ({ status, data }) {
      // console.log(err);
      // console.log(err.name);

      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      let msg = "";
      if (data.name === 'RangeError') {
        msg = intl.get("입숙일/퇴숙일 날짜는 올바른 날짜여야 합니다.");
      } else {
        msg = intl.get("업로드한 파일에 오류가 있습니다. \n양식을 다운로드 받아 사용해 주세요.");
      }

      info(msg);
      return;
    }
    
  };

  const deleteByEmptyValue = (obj: any) => {
    const temp = { ...obj };

    Object.keys(temp).forEach((key: any, idx: number) => {
      if (key !== EXCEL_IDX && !obj[key]) delete obj[key];
    });

    return obj;
  };

  const onClickGetPersonnelHandler = async () => {
    if (!employeeNumber) {
      info(intl.get('사번을 입력해주세요.'));
      return;
    }
    try {
      const { data } = await auth.isSignedUser(employeeNumber);

      setName(data.name || '');
      setDepartment(data.personnel?.department || '');
      setPhone(data.cellphone || '');
      setGender(data.personnel.gender);
      // setMealTicket(0);
    } catch ({ status, data }) {
      // console.log(err);
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else if (status === 404) {
        info(`${employeeNumber}` + intl.get('는 가입된 사번이 아닙니다.'));
        return;
      }
      
      info(intl.get('조회중 문제가 발생했습니다.'));
      init();
      return;
    }
  };

  const onEmployeeNumberEnterHandler = async (e: any) => {
    if (e.key === "Enter") {
      onClickGetPersonnelHandler()
    }
  }

  const onClickDateAfterByCalendar = (day: string) => {

    // dateAfter <= dateBefore
    const _isAfter = isAfterAndSame(day, props.dateBefore);
    setDateAfter(day);

    if (_isAfter || day === dateBefore) {
      setDateBefore(getNextDay(day)[0]);
      // alert(`${day}보다 ${props.dateBefore}가 이전이 될 수 없습니다.`);
    }

    //ag-grid에서 편집할때 사용하기위함
    if(isClickRow) {
      //console.log(isClickRow);
      props.guests[isClickRow.rowIndex]['stay_after'] = day;
      props.guests[isClickRow.rowIndex]['stay_before'] = getNextDay(day)[0];
      isClickRow.api.redrawRows();
      
    }
  };

  const onClickDateBeforeByCalendar = (day: string) => {
    
    // dateAfter <= dateBefore
    const _isAfter = isAfterAndSame(dateAfter, day);
    setDateBefore(day);
    if (!_isAfter) {
      setDateAfter(day);
      // alert(`${props.dateAfter}보다 ${day}가 이전이 될 수 없습니다.`);
    }

    //ag-grid에서 편집할때 사용하기위함
    if(isClickRow) {
      //console.log(isClickRow);
      props.guests[isClickRow.rowIndex]['stay_before'] = day;
      isClickRow.api.redrawRows();
      
    }
  };

  // 협조전(RFC) 업로드
  const onRFCUploadHandler = async (e: any) => {
    let frm = new FormData();
    let name = e.target.files[0].name;

    let extList = ['gif', 'jpg', 'jpeg', 'png', 'hwp', 'pdf', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'docx'];

    let fileData: any = e.target.files[0];
    let fileExt = (fileData.name).split('.').pop().toLowerCase();

    if(!extList.includes(fileExt)){
      let extText = extList.join(', ');
      info(`파일은 `+extText+` 만 가능합니다.`);
      return false;
    }else if (e.target.files[0].size >= 10485760) {      
      info(`파일 용량은 10MB 이상 첨부할 수 없습니다.`);
      return false;
    }

    // 10MB 미만일때 업로드
    if (e.target.files[0].size < 10485760) {
      frm.append("path", e.target.files[0]);
      // frm.append('document', FILE_CODE["RFC"].toString());

      try {
        let { data } = await core.fileUpload<Rfc>(frm);

        props.setRfc({
          file: data.id,
          path: data.path,
          name: name,
          document: FILE_CODE["RFC"].toString()
        })

        setRfcFileText(name);
        info(intl.get("협조전 등록이 완료되었습니다."));
      } catch ({ data, status }) {
        // console.log(data, status);
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
  
        info(intl.get("협조전 업로드 중 문제가 발생했습니다"));
        return;
      }
    } else {
      info(intl.get("파일의 용량이 큽니다.\n10M 미만의 파일을 등록해 주세요"));
      return;
    }
  }

  // 협조전(RFC) 클리어
  const onRFCClearHandler = () => {
    props.setRfc({});
    setRfcFileText('');
  }

  const removeByExcelunit = (excelIdx: number) => {
    let tempExcelFiles = [...props.excelFiles];
    tempExcelFiles[excelIdx] = null;
    props.setExcelFiles(tempExcelFiles);

    props.overwriteGuest(
      props.guests.filter((guest: any) => guest[EXCEL_IDX] !== excelIdx)
    )
  }

  const numberRegex = new RegExp(/[^0-9]/, 'g');


  // 이용약관, 개인정보 자세히보기 추가
  const [tabNumber, setTabNumber] = useState<number>(-1);
  const onShowtermsDetailPopupHandler = (idx: number) => {
    setTabNumber(idx);
  }; // 열기
  const onCloseTermsDetailPopupHandler = () => {
    setTabNumber(-1);
  }; // 닫기

  return (
    <>
      <div className="title_contents">
        <h3 className="title">{intl.get("신청자 정보")}</h3>
      </div>

      <div className="table_design_list col_width6">
        <div id="table" className="table">
          <div className="tbody">
            <div className="tr">
              <div className="th">{intl.get("사번 (아이디)")}</div>
              <div className="td">{userInfo.employeenumber}</div>
              <div className="th">{intl.get("성명")}</div>
              <div className="td">{userInfo.username}</div>
              <div className="th">{intl.get("성별")}</div>
              <div className="td">{GENDER[userInfo.gender]}</div>
            </div>
            <div className="tr">
              <div className="th">{intl.get("소속 부서")}</div>
              <div className="td">{userInfo.department}</div>
              <div className="th">{intl.get("휴대폰 번호")}</div>
              <div className="td">{userInfo.phone}</div>
              <div className="th"></div>
              <div className="td"></div>
            </div>
          </div>
        </div>
      </div>

      <WorkSiteSelect value={props.workSite} 
        options={worksites} 
        onChange={(workSite: number) => {
          //console.log(workSite);
          props.setWorkSite(workSite);
          if(workSite === 1) { setIsEditorChk(false) }
          props.setGuests([]);
          init();
          props.setExcelFiles([]);
        }} 
      />

      <div className="title_contents">
        <h3 className="title">{intl.get("이용자 정보")}</h3>
      </div>

      <div className="group_tab">
        <div className="tabs_area">
          <GuestType
            value={guestType}
            selectWorkSite={props.workSite}
            roomType={props.roomType}
            onChange={(guestType: number) => {
              init()
              setGuestType(guestType)
            }}
          />
        </div>
      </div>

      {/*사내 임직원*/}
      <div className={`tab_contents ${guestType === 1 ? 'on' : ''}`}>
        {' '}
        {/*on 추가 시 show*/}
        <div className="upload_area bg_white">
          <label>{intl.get("파일 업로드")}</label>
          <div className="wrap_file">
            <input
              id="input_copy"
              className="upload_name form_control"
              placeholder={intl.get("파일찾기를 클릭하여 파일을 업로드 하세요")}
              value=""
            />
            <input
              type="file"
              id="input_upload_copy"
              className="upload_hidden"
              onChange={onUploadHandler}
              onClick={(e: any) => (e.target.value = null)}
              accept="xlsx,xls"
            />
            <label htmlFor="input_upload_copy" className="btn btn_gray">
                {intl.get("파일찾기")}
            </label>
          </div>
          <DownloadButton
              src={`${process.env.REACT_APP_MEDIA}/static/reservation_group_form.xlsx`}
              value={intl.get("양식다운로드")}
              onlySrc
          />

          <div className="file_area al clearfix">
            {props.excelFiles && props.excelFiles.map((file: any, fileIdx: number) => file && (
              <ConfirmBtn
                action={() => removeByExcelunit(fileIdx)}
                label={`${file}`}
                title=""
                msg={intl.get("업로드 된 파일 ") + `${file}` + intl.get("을 정말 삭제하시겠습니까?")}
                className="item file del mt10"
              />
            ))}
          </div>
        </div>

        <div className="table_design_list col_width4">
          <div id="table" className="table">
            <div className="tbody">
              <div className="tr">
                <div className="th">{intl.get("사번 (아이디)")}</div>
                <div className="td">
                  {/*<div className="form_wrap">*/}
                  <EmployeeNumberInput
                    type="text"
                    className="form_control"
                    placeholder={intl.get("사번을 입력하세요")}
                    value={employeeNumber}
                    onChange={(e: any) =>
                      e.target.value.length <= 7 &&
                      setEmployeeNumber(e.target.value)
                    }
                    //onKeyDown={onEmployeeNumberEnterHandler}
                  />
                  {/** !e.target.value.match(numberRegex) && */}
                  
                    {/* <input
                      type="text"
                      className="form_control"
                      placeholder={intl.get("사번을 입력하세요")}
                      value={employeeNumber}
                      onChange={e =>
                        !e.target.value.match(numberRegex) &&
                        e.target.value.length <= 7 &&
                        setEmployeeNumber(e.target.value)
                      }
                      onKeyDown={onEmployeeNumberEnterHandler}
                    /> */}
                    {/*<button
                      className="btn_sm btn_gray"
                      onClick={onClickGetPersonnelHandler}
                    >
                      {intl.get("적용")}
                    </button>*/}
                  {/*</div>*/}
                </div>  
                <div className="th">{intl.get("회사명")}</div>
                <div className="td">
                  <SelectCompany
                    value={"현대자동차"}
                    onChange={(e: any) => setCompany("현대자동차")}
                    disabled = {true}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("성명")}</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    placeholder={intl.get("성명을 입력하세요")}
                    value={name}
                    onChange={e =>
                      e.target.value.length >= 0 &&
                      e.target.value.length <= 10 &&
                      setName(e.target.value)
                    }
                  />
                </div>
                <div className="th">{intl.get("소속 부서")}</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    placeholder={intl.get("소속부서를 입력하세요")}
                    value={department}
                    onChange={e =>
                      e.target.value.length <= 20 &&
                      setDepartment(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("성별")}</div>
                <div className="td">
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="sex_choice2"
                      name="in-sex"
                      checked={gender === 1}
                      onChange={e =>
                        e.target.checked ? setGender(1) : setGender(2)
                      }
                    />
                    <span className="label_text">{intl.get("남자")}</span>
                  </label>
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="sex_choice2"
                      name="in-sex"
                      checked={gender === 2}
                      onChange={e =>
                        e.target.checked ? setGender(2) : setGender(1)
                      }
                    />
                    <span className="label_text">{intl.get("여자")}</span>
                  </label>
                </div>
                <div className="th">{intl.get("휴대폰 번호")}</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    placeholder={intl.get("휴대폰 번호를 입력하세요")}
                    value={phone}
                    onChange={e =>
                      !e.target.value.match(numberRegex) &&
                      e.target.value.length < 12 &&
                      setPhone(e.target.value)
                    }
                  />
                </div>      
              </div>
              <div className="tr">
                <div className="th">{intl.get("입숙일")}</div>
                <div className="td" onClick={e => setIsCalendar(true)}>
                  <input
                    type="text"
                    className="form_control datepicker"
                    placeholder={intl.get("날짜를 선택해주세요")}
                    value={dateAfter}
                    onChange={e => setDateAfter(e.target.value)}
                  />
                </div>
                <div className="th">{intl.get("퇴숙일")}</div>
                <div className="td" onClick={e => setIsCalendar(true)}>
                  <input
                    type="text"
                    className="form_control datepicker"
                    placeholder={intl.get("날짜를 선택해주세요")}
                    value={dateBefore}
                    onChange={e => setDateBefore(e.target.value)}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("차량번호")}</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    value={carNumber}
                    onChange={e => e.target.value.length < 12 && setCarNumber(e.target.value)}
                    placeholder={intl.get("차량번호를 입력하세요")}
                    maxLength={10}
                  />
                </div>
                <div className="th">{intl.get("식권 수 (장)")}</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    placeholder={intl.get("신청하실 식권 수를 입력해주세요")}
                    value={addmealTicket}
                    onChange={e => {
                      setAddMealTicket(parseInt(e.target.value) || 0);
                    }}
                    maxLength={4}
                    disabled={props.workSite === 2 ? true : false}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("인솔자 여부")}</div>
                <div className="td">
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="guide_choice1"
                      name="guide"
                      checked={isLeader}
                      onChange={e => setIsLeader(e.target.checked)}
                    />
                    <span className="label_text">{intl.get("여")}</span>
                  </label>
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="guide_choice2"
                      name="guide"
                      checked={!isLeader}
                      onChange={e => {
                        setIsLeader(!e.target.checked);
                      }}
                    />
                    <span className="label_text">{intl.get("부")}</span>
                  </label>
                </div>
                <div className="th"></div>
                <div className="td"></div>
              </div>
            </div>
          </div>

          <div className="tr group_agree">
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term1"
                  checked={privacyPolicyAgreed}
                  onChange={e =>
                    setPrivacyPolicyAgreed(e.target.checked)
                  }
                />
                <span className="label_text">{intl.get("서비스 이용약관 동의(필수)")}</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(1)}>{intl.get("자세히보기")}</button>
            </div>
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term2"
                  checked={personalInformationCollectionAgreed}
                  onChange={e =>
                    setPersonalInformationCollectionAgreed(
                      e.target.checked,
                    )
                  }
                />
                <span className="label_text">{intl.get("개인정보 이용 동의(필수)")}</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(2)}>{intl.get("자세히보기")}</button>
            </div>
          </div>
        </div>{' '}
        {/* table_design_list */}
        <ul className="lst_help bg_gray">
          <li>
            {intl.get("출장자 숙소 단체 신청의 경우 양식 다운로드 받아서 파일업로드로 업무 처리하시기를 권장합니다")}
          </li>
          <li>
            {intl.get("만약 이용자 정보의 개별 등록을 원하실 경우 직접입력이 가능합니다")}
          </li>
          <li>
            {intl.get("정보 입력 후 추가버튼을 클릭하시면 하단 단체이용자 정보에 추가됩니다")}
          </li>
          <li>
            {intl.get("파일 업로드와 직접입력 혼용도 가능합니다")}
          </li>
        </ul>
      </div>

      {/*계열사 임직원*/}
      <div className={`tab_contents ${guestType === 2 ? 'on' : ''}`}>
        <div className="upload_area bg_white">
          <label>{intl.get("파일 업로드")}</label>
          <div className="wrap_file">
            <input
              id="input_copy"
              className="upload_name form_control"
              placeholder={intl.get("파일찾기를 클릭하여 파일을 업로드 하세요")}
              value=""
            />
            <input
              type="file"
              id="input_upload_copy"
              placeholder={intl.get("파일찾기를 클릭하여 파일을 업로드 하세요")}
              className="upload_hidden"
            />
            <label htmlFor="input_upload_copy" className="btn btn_gray">
              {intl.get("파일찾기")}
            </label>
          </div>
          <DownloadButton
              src="/static/reservation_group_form.xlsx"
              value={intl.get("양식다운로드")}
              onlySrc
          />
        </div>
        <div className="table_design_list col_width4">
          <div id="table" className="table">
            <div className="tbody">
              <div className="tr">
                <div className="th">{intl.get("회사명")}</div>
                <div className="td">
                  <SelectCompany
                    value={company}
                    guestType={guestType}
                    onChange={(e: any) => setCompany(e.target.value)}
                  />
                </div>
                <div className="th">{intl.get("소속 부서")}</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    placeholder={intl.get("소속부서를 입력하세요")}
                    value={department}
                    onChange={e =>
                      e.target.value.length >= 0 &&
                      e.target.value.length <= 20 &&
                      setDepartment(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("사번")}</div>
                <div className="td">
                  <EmployeeNumberInput
                    type="text"
                    className="form_control"
                    placeholder={intl.get("사번을 입력하세요")}
                    value={employeeNumber}
                    onChange={(e: any) =>
                      e.target.value.length <= 7 &&
                      setEmployeeNumber(e.target.value)
                    }
                    onKeyDown={onEmployeeNumberEnterHandler}
                  />
                </div>
                <div className="th">{intl.get("성명")}</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    placeholder={intl.get("성명을 입력하세요")}
                    value={name}
                    onChange={e =>
                      e.target.value.length >= 0 &&
                      e.target.value.length <= 10 &&
                      setName(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("성별")}</div>
                <div className="td">
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="outer_sex_choice"
                      name="same-sex"
                      checked={gender === 1}
                      onChange={e =>
                        e.target.checked ? setGender(1) : setGender(2)
                      }
                    />
                    <span className="label_text">{intl.get("남자")}</span>
                  </label>
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="sex_choice2"
                      name="same-sex"
                      checked={gender === 2}
                      onChange={e =>
                        e.target.checked ? setGender(2) : setGender(1)
                      }
                    />
                    <span className="label_text">{intl.get("여자")}</span>
                  </label>
                </div>
                <div className="th">{intl.get("휴대폰 번호")}</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    placeholder={intl.get("휴대폰 번호를 입력하세요")}
                    value={phone}
                    onChange={e =>
                      !e.target.value.match(numberRegex) &&
                      e.target.value.length < 12 &&
                      setPhone(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("입숙일")}</div>
                <div className="td" onClick={e => setIsCalendar(true)}>
                  <input
                    type="text"
                    className="form_control datepicker"
                    placeholder={intl.get("날짜를 선택해주세요")}
                    value={dateAfter}
                    onChange={e => setDateAfter(e.target.value)}
                  />
                </div>
                <div className="th">{intl.get("퇴숙일")}</div>
                <div className="td" onClick={e => setIsCalendar(true)}>
                  <input
                    type="text"
                    className="form_control datepicker"
                    placeholder={intl.get("날짜를 선택해주세요")}
                    value={dateBefore}
                    onChange={e => setDateBefore(e.target.value)}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("차량번호")}</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    value={carNumber}
                    onChange={e => e.target.value.length < 12 && setCarNumber(e.target.value)}
                    placeholder={intl.get("차량번호를 입력하세요")}
                    maxLength={7}
                  />
                </div>
                <div className="th">{intl.get("식권 수 (장)")}</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    placeholder={intl.get("신청하실 식권 수를 입력해주세요")}
                    value={addmealTicket}
                    onChange={e =>
                      setAddMealTicket(parseInt(e.target.value) || 0)
                    }
                    maxLength={4}
                    disabled={props.workSite === 2 ? true : false}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("인솔자 여부")}</div>
                <div className="td">
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="guide_choice1"
                      name="guide2"
                      checked={isLeader}
                      onChange={e => setIsLeader(e.target.checked)}
                    />
                    <span className="label_text">{intl.get("여")}</span>
                  </label>
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="guide_choice2"
                      name="guide2"
                      checked={!isLeader}
                      onChange={e => {
                        setIsLeader(!e.target.checked);
                      }}
                    />
                    <span className="label_text">{intl.get("부")}</span>
                  </label>
                </div>
                <div className="th"></div>
                <div className="td"></div>
                {/* <div className="th blank"></div>
                <div className="td blank"></div> */}
              </div>
            </div>
          </div>

          <div className="tr group_agree">
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term1"
                  checked={privacyPolicyAgreed}
                  onChange={e =>
                    setPrivacyPolicyAgreed(e.target.checked)
                  }
                />
                <span className="label_text">{intl.get("서비스 이용약관 동의(필수)")}</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(1)}>{intl.get("자세히보기")}</button>
            </div>
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term2"
                  checked={personalInformationCollectionAgreed}
                  onChange={e =>
                    setPersonalInformationCollectionAgreed(
                      e.target.checked,
                    )
                  }
                />
                <span className="label_text">{intl.get("개인정보 이용 동의(필수)")}</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(2)}>{intl.get("자세히보기")}</button>
            </div>
          </div>
        </div>{' '}
        {/* table_design_list */}
        <ul className="lst_help bg_gray">
          <li>
            {intl.get("출장자 숙소 단체 신청의 경우 양식 다운로드 받아서 파일업로드로 업무 처리하시기를 권장합니다")}
          </li>
          <li>
            {intl.get("만약 이용자 정보의 개별 등록을 원하실 경우 직접입력이 가능합니다")}
          </li>
          <li>
            {intl.get("정보 입력 후 추가버튼을 클릭하시면 하단 단체이용자 정보에 추가됩니다")}
          </li>
          <li>
            {intl.get("파일 업로드와 직접입력 혼용도 가능합니다")}
          </li>
        </ul>
      </div>

      {/*외부인*/}
      <div className={`tab_contents ${guestType === 3 ? 'on' : ''}`}>
        <div className="upload_area bg_white">
          <label>{intl.get("파일 업로드")}</label>
          <div className="wrap_file">
            <input
              id="input_copy"
              className="upload_name form_control"
              placeholder={intl.get("파일찾기를 클릭하여 파일을 업로드 하세요")}
              value=""
            />
            <input
              type="file"
              id="input_upload_copy"
              className="upload_hidden"
            />
            <label htmlFor="input_upload_copy" className="btn btn_gray">
              {intl.get("파일찾기")}
            </label>
          </div>
          <DownloadButton
              src={`${process.env.REACT_APP_MEDIA}/static/reservation_group_form.xlsx`}
              value={intl.get("양식다운로드")}
              onlySrc
          />
        </div>
        <div className="table_design_list col_width4">
          <div id="table" className="table">
            <div className="tbody">
              <div className="tr">
                <div className="th">{intl.get("회사명(단체명)")}</div>
                <div className="td">
                  <input type="text"
                    placeholder={intl.get("회사명 또는 단체명을 입력하세요")}
                    className="form_control"
                    value={company}
                    maxLength={30}
                    onChange={(e: any) => setCompany(e.target.value)}
                  />
                </div>
                <div className="th">{intl.get("성명")}</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    placeholder={intl.get("성명을 입력하세요")}
                    value={name}
                    onChange={e =>
                      e.target.value.length >= 0 &&
                      e.target.value.length <= 10 &&
                      setName(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("성별")}</div>
                <div className="td">
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="outer_sex_choice"
                      name="out-sex"
                      checked={gender === 1}
                      onChange={e =>
                        e.target.checked ? setGender(1) : setGender(2)
                      }
                    />
                    <span className="label_text">{intl.get("남자")}</span>
                  </label>
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="outer_sex_choice"
                      name="out-sex"
                      checked={gender === 2}
                      onChange={e =>
                        e.target.checked ? setGender(2) : setGender(1)
                      }
                    />
                    <span className="label_text">{intl.get("여자")}</span>
                  </label>
                </div>
                <div className="th">{intl.get("휴대폰 번호")}</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    placeholder={intl.get("휴대폰 번호를 입력하세요")}
                    value={phone}
                    onChange={e =>
                      !e.target.value.match(numberRegex) &&
                      e.target.value.length < 12 &&
                      setPhone(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("입숙일")}</div>
                <div className="td" onClick={e => setIsCalendar(true)}>
                  <input
                    type="text"
                    className="form_control datepicker"
                    placeholder={intl.get("날짜를 선택해주세요")}
                    value={dateAfter}
                    onChange={e => setDateAfter(e.target.value)}
                  />
                </div>
                <div className="th">{intl.get("퇴숙일")}</div>
                <div className="td" onClick={e => setIsCalendar(true)}>
                  <input
                    type="text"
                    className="form_control datepicker"
                    placeholder={intl.get("날짜를 선택해주세요")}
                    value={dateBefore}
                    onChange={e => setDateBefore(e.target.value)}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("차량번호")}</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    value={carNumber}
                    onChange={e => e.target.value.length < 12 && setCarNumber(e.target.value)}
                    placeholder={intl.get("차량번호를 입력하세요")}
                    maxLength={10}
                  />
                </div>
                <div className="th">{intl.get("식권 수 (장)")}</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    placeholder={intl.get("신청하실 식권 수를 입력해주세요")}
                    value={addmealTicket}
                    onChange={e =>
                      setAddMealTicket(parseInt(e.target.value) || 0)
                    }
                    maxLength={4}
                    disabled={props.workSite === 2 ? true : false}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("인솔자 여부")}</div>
                <div className="td">
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="guide_choice1"
                      name="guide3"
                      checked={isLeader}
                      onChange={e => setIsLeader(e.target.checked)}
                    />
                    <span className="label_text">{intl.get("여")}</span>
                  </label>
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="guide_choice2"
                      name="guide3"
                      checked={!isLeader}
                      onChange={e => {
                        setIsLeader(!e.target.checked);
                      }}
                    />
                    <span className="label_text">{intl.get("부")}</span>
                  </label>
                </div>
                <div className="th"></div>
                <div className="td"></div>
                {/* <div className="th blank"></div>
                <div className="td blank"></div> */}
              </div>
            </div>
          </div>
          <div className="tr group_agree">
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term1"
                  checked={privacyPolicyAgreed}
                  onChange={e =>
                    setPrivacyPolicyAgreed(e.target.checked)
                  }
                />
                <span className="label_text">{intl.get("서비스 이용약관 동의(필수)")}</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(1)}>{intl.get("자세히보기")}</button>
            </div>
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term2"
                  checked={personalInformationCollectionAgreed}
                  onChange={e =>
                    setPersonalInformationCollectionAgreed(
                      e.target.checked,
                    )
                  }
                />
                <span className="label_text">{intl.get("개인정보 이용 동의(필수)")}</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(2)}>{intl.get("자세히보기")}</button>
            </div>
          </div>
        </div>{' '}
        {/* table_design_list */}
        <ul className="lst_help bg_gray">
        <li>
            {intl.get("출장자 숙소 단체 신청의 경우 양식 다운로드 받아서 파일업로드로 업무 처리하시기를 권장합니다")}
          </li>
          <li>
            {intl.get("만약 이용자 정보의 개별 등록을 원하실 경우 직접입력이 가능합니다")}
          </li>
          <li>
            {intl.get("정보 입력 후 추가버튼을 클릭하시면 하단 단체이용자 정보에 추가됩니다")}
          </li>
          <li>
            {intl.get("파일 업로드와 직접입력 혼용도 가능합니다")}
          </li>
        </ul>
      </div>
      
      
        <div className="function">
          <button className="btn btn_outline" onClick={() => init()}>
            {intl.get("초기화")}
          </button>
          <button className="btn btn_darkblue" onClick={onAddUserHandler}>
            {intl.get("추가")}
          </button>
      </div>
      
      <hr />

      <div className="table_list mt20">
        <div id="myGrid"
            style={{
              height: 600,
              width: "100%"
            }} 
            className="ag-theme-alpine">
            
            <AgGridReact
                modules={AllCommunityModules}
                defaultColDef={{
                    width: 120,
                    resizable: true
                  }}
                  suppressRowTransform={true}
                  suppressRowClickSelection={true}
                  frameworkComponents={{
                    numericCellEditor: NumericEditor,
                  }}
                  rowSelection={'multiple'}
                  onSelectionChanged={(e: any) => onCheckedUser(e)}
                  onGridReady={onGridReady}
                  rowData={props.guests}

            >
                
              <AgGridColumn 
                  field=""
                  editable={false}
                  headerCheckboxSelection={true}
                  checkboxSelection={true}
                  width= {Number(50)}
              ></AgGridColumn>
              {/* <AgGridColumn 
                  headerName="No"
                  field="no"
                  editable={false}
                  valueGetter={rowindxGetter}
                  width= {Number(90)}
              ></AgGridColumn> */}
              <AgGridColumn
                  headerName="이용자 유형"
                  field="guest_type"
                  //editable={true}
                  cellEditor="agSelectCellEditor"
                  cellRenderer={({data}:{data:any}) => 
                    GUEST_TYPE[data.guest_type]
                  }
                  width= {Number(130)}
              ></AgGridColumn>
              <AgGridColumn
                  headerName="회사명(단체명)"
                  field="guest_company"
                  editable={true}
                  width= {Number(150)}
              ></AgGridColumn>
              <AgGridColumn
                  headerName="소속부서"
                  field="guest_department"
                  editable={true}
                  width= {Number(140)}
              ></AgGridColumn>
              <AgGridColumn
                  headerName="사번(아이디)"
                  field="guest_employee_number"
                  editable={true}
                  cellEditor="numericCellEditor"
                  width= {Number(130)}
              ></AgGridColumn>
              <AgGridColumn
                  headerName="성명"
                  field="guest_name"
                  editable={true}
                  width= {Number(140)}
              ></AgGridColumn>
              <AgGridColumn
                  headerName="성별"
                  field="guest_gender"
                  editable={true}
                  cellEditor="agSelectCellEditor"
                  cellRenderer={({data}:{data:any}) => 
                    data.guest_gender === 1 ? '남자' : '여자'
                  }
                  cellEditorParams={{
                      values: ['남자', '여자'],
                  }}
                  width= {Number(90)}
              ></AgGridColumn>
              <AgGridColumn
                  headerName="휴대폰번호"
                  field="guest_cellphone"
                  editable={true}
                  cellEditor="numericCellEditor"
                  width= {Number(130)}
              ></AgGridColumn>
              <AgGridColumn
                  headerName="입숙예정일"
                  field="stay_after"
                  editable={true}
                  onCellClicked={(e) => {
                    setIsCalendar(true); 
                    setIsClickRow(e);
                  }}
                  width= {Number(130)}
              ></AgGridColumn>
              <AgGridColumn
                  headerName="퇴숙예정일"
                  field="stay_before"
                  editable={true}
                  onCellClicked={(e) => {
                    setIsCalendar(true); 
                    setIsClickRow(e);
                  }}
                  width= {Number(130)}
              ></AgGridColumn>
              <AgGridColumn
                  headerName="차량번호"
                  field="guest_car_number1"
                  editable={true}
                  width= {Number(120)}
              ></AgGridColumn>
              {/* <AgGridColumn
                  headerName="식권 수(장)"
                  field="meal_ticket_request_count"
                  editable={true}
                  cellEditor="numericCellEditor"
                  onCellValueChanged={({data}:{data:any}) => {
                      console.log(data.meal_ticket_request_count, mealTicket);
                      let count = data.meal_ticket_request_count + mealTicket;
                      setMealTicket(count)
                    }}
                  width= {Number(96)}
                  ></AgGridColumn> */}
              <AgGridColumn
                  headerName="인솔자여부"
                  field="is_leader"
                  editable={true}
                  cellEditor="agSelectCellEditor"
                  cellRenderer={({data}:{data:any}) => 
                    data.is_leader ? '여' : '부'
                  }
                  cellEditorParams={{
                      values: ['여', '부'],
                  }}
                  width= {Number(110)}
              ></AgGridColumn>
              
            </AgGridReact>
        </div>    
      </div>
      <div className="function">
        {/* <button className="btn btn_outline">수정</button> */}
        <button
            className="btn btn_darkblue"
            onClick={() => {Object.keys(checkUsersIdx).length <= 0 ? (info(intl.get('삭제 할 내용 선택해 주세요.'))) : (setIsDeletePop(true))}}
        >
          {intl.get("삭제")}
        </button>
      </div>

      <div className="upload_area">
        <label htmlFor="input_copy">{intl.get("협조전 파일")}</label>
        <div className="wrap_file">
          <input id="input_copy" className="upload_name form_control" value={rfcFileText} placeholder={intl.get("파일찾기를 클릭하여 파일을 업로드 하세요")} disabled={true} />
          <input
              type="file" id="input_upload_rfc" className="upload_hidden"
              onChange={onRFCUploadHandler}
              onClick={(e: any) => (e.target.value = null)}
          />
          {rfcFileText && <button type="button" className="btn_fileuploade_close" onClick={onRFCClearHandler}></button>}
          <label htmlFor="input_upload_rfc" className="btn btn_gray">{intl.get("파일찾기")}</label>
        </div>
        <p className="txt_warning">{intl.get("협조전은 한 개만 등록할 수 있습니다. 어떤 파일유형이든 업로드 가능하며 10M이상의 파일은 올릴 수 없습니다.")}</p>
      </div>
      <div className="upload_area mt0">
          <label htmlFor="input_copy">{intl.get("식권")}</label>
          <div className="wrap_file p0">
              <input id="input_copy" 
                className="upload_name form_control" 
                placeholder={intl.get("필요한 전체 식권 수량을 입력 하세요")}
                value={mealTicket}
                onChange={e =>
                  !e.target.value.match(numberRegex) &&
                  e.target.value.length < 5 &&
                  setMealTicket(parseInt(e.target.value) || 0)
                }
                //disabled={props.workSite === 2 ? true : false}
              />
          </div>
      </div>

      <div className="submit">
        <ConfirmBtn
          className="btn btn_lg btn_outline"
          action={() => props.history.replace('/home')}
          label={intl.get("취소")}
          title=""
          msg={intl.get("정말 취소하시겠습니까?")}
        />
        <button className="btn btn_lg btn_darkblue" onClick={nextStep}>
          {intl.get("다음")}
        </button>
      </div>

      <div
        id="pop_period"
        className="pop_dim_wrap pop_modal pop_md"
        style={{ display: isCalendar ? 'block' : 'none' }}
      >
        <div className="pop_wrap">
          <div className="title_wrap">
            <h3>{intl.get("기간선택")}</h3>
            <button
              className="pop_btn_close pop_close"
              onClick={() => setIsCalendar(false)}
            >
               {intl.get("닫기")}
            </button>
          </div>
          <div className="contents_wrap">
            <div className="result_area">
              <ul className="lst_date">
                <li>
                  <strong>{intl.get("입숙예정일")}</strong>
                  <span>{dateAfter}</span>
                </li>
                <li className="dash"></li>
                <li>
                  <strong>{intl.get("퇴숙예정일")}</strong>
                  <span>{dateBefore}</span>
                </li>
              </ul>
              {/* <button className="btn btn_sm btn_darkblue btn_outline">날짜 재선택</button> */}
            </div>
            <div className="select_date">
              <div className="date_start">
                <div className="datepicker_area">
                  <Calendar
                    onClickSelectDay={onClickDateAfterByCalendar}
                    onRangeSelectDay={(e: string) => { }}
                    tasks={false}
                    selectDate={dateAfter}
                    isNextMonthButtonView={true}
                    beforeDisablePoint={getPrevDay(todayDate[0])[0]}
                  />
                </div>
              </div>
              <div className="date_end">
                <div className="datepicker_area">
                  <Calendar
                    onClickSelectDay={onClickDateBeforeByCalendar}
                    onRangeSelectDay={(e: string) => { }}
                    tasks={false}
                    selectDate={dateBefore}
                    isNextMonthButtonView={true}
                    beforeDisablePoint={dateAfter}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="button_wrap btn_length_1">
            <button
              type="button"
              className="btn btn_xlg btn_darkblue pop_close"
              onClick={() => {
                setIsCalendar(false);
                if(isClickRow) {
                  props.guests[isClickRow.rowIndex]['stay_after'] = dateAfter;
                  props.guests[isClickRow.rowIndex]['stay_before'] = dateBefore;
                  isClickRow.api.redrawRows();
                }
                setIsClickRow(null);
              }}
            >
              {intl.get("확인")}
            </button>
          </div>
        </div>


      </div>
      <div
        id="pop_alert_delete"
        className="pop_dim_wrap pop_alert"
        style={{ display: isDeletePop ? 'block' : 'none' }}
      >
        <div className="pop_wrap">
          <button
            className="pop_btn_close pop_close"
            onClick={() => setIsDeletePop(false)}
          >
            {intl.get("닫기")}
          </button>
          <div className="message_table">
            <div className="messageWrap">{intl.get("정말로 삭제하시겠습니까?")}</div>
          </div>
          <div className="button_wrap">
            <button
              type="button"
              className="btn btn_lg btn_gray w50"
              onClick={() => setIsDeletePop(false) }
            >
              {intl.get("아니오")}
            </button>
            <button
              type="button"
              className="btn btn_lg btn_darkblue w50"
              onClick={() => onDeleteUserHandler()}
            >
              {intl.get("예")}
            </button>
          </div>
        </div>
      </div>

      {/*이용약관 자세히보기 팝업*/}
      <TermsDetail
        classNameBySelect="form_control"
        tabNumber={tabNumber}
        setTabNumber={setTabNumber}
        onCloseTermsDetailPopupHandler={onCloseTermsDetailPopupHandler}
      />
    </>
  );
};

function rowindxGetter(params : any) {
  return params.node.rowIndex+1;
}

export default Step1;
