import { takeEvery, put, call } from 'redux-saga/effects';

import intl from 'react-intl-universal';

import {
  LOGIN_REQUEST,
  LOGIN_COMPLETE,
  SNS_LOGIN_REQUEST,
  SNS_INTEGRATE_REQUEST,
  SIGNUP_REQUEST,
  LOGOUT_REQUEST,
  AUTH_CLEAR,
} from '../modules/auth';

import {
  USERINFO_REQUEST,
  USERINFO_COMPLETE,
  USERINFO_CLEAR,
} from '../modules/user';

import { SHOW_MODAL } from '../modules/modal';

import { auth } from '../../apis';

function* login({ payload, history }: any) {
  // console.log('[SAGA] API CALL: login');
  try {
    const res = yield call(auth.login, payload);
    // console.log('[SAGA] API response');
    //console.log(res);
    yield put({
      type: LOGIN_COMPLETE,
      payload: {
        accessToken: res.data.token,
        refreshToken: res.data.refresh,
      },
    });

    yield put({
      type: USERINFO_REQUEST,
      payload: {
        accessToken: res.data.access,
        refreshToken: res.data.refresh,
      },
      // history: history
    });

    history.replace('/home');
  } catch (err) {
    //console.log('[SAGA] API error');

    if (err.response.status === 403) {
      yield put({
        type: SHOW_MODAL,
        payload: {
          title: '로그인 오류',
          msg: '임직원만 로그인할 수 있습니다.',
          redirectPath: false,
          statusCode: err.response.status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return;
    }

    yield put({
      type: SHOW_MODAL,
      payload: {
        title: '로그인 오류',
        msg: err.response.data.detail,
        redirectPath: false,
        statusCode: err.response.status,
        errorCode: '',
        type: 'error',
        isModalShow: true,
      },
    });
  }
}

function* signup({ payload }: any) {
  // console.log('[SAGA] API CALL: signup');
  try {
    const res = yield call(auth.signup, payload);
    // console.log('[SAGA] API response');
    // console.log(res);
    yield put({
      type: SHOW_MODAL,
      payload: {
        title: '회원가입 성공',
        msg: '회원가입을 성공했습니다. 로그인해주세요',
        redirectPath: '/',
        statusCode: res.status,
        errorCode: '',
        type: 'success',
        isModalShow: true,
      },
    });
  } catch (err) {
    // console.log('[SAGA] API error');
    // console.log(err);

    const errorMap: any = {};
    const s = Object.keys(err.data).map(key => {
      errorMap[`${key}_${err.statusCode}`] = true;
      return err.data[key].join(' ');
    });
    const a = `${err.status}_http`;
    const errorTitle = intl.get(a);

    yield put({
      type: SHOW_MODAL,
      payload: {
        title: errorTitle,
        msg: s.join(''),
        redirectPath: false,
        statusCode: err.status,
        errorCode: '',
        type: 'error',
        isModalShow: true,
      },
    });
  }
}

function* snsLogin({ payload, history }: any) {
  // console.log('[SAGA] API CALL: snsLogin');
  try {
    const res = yield call(auth.snsLogin, payload);
    // console.log('[SAGA] API response');
    // console.log(res);
    yield put({
      type: LOGIN_COMPLETE,
      payload: {
        accessToken: res.data.access,
        refreshToken: res.data.refresh,
      },
    });
    history.push('/');
  } catch (err) {
    // console.log('[SAGA] API error');
    // console.log(err);
  }
}

function* snsIntegrate({ payload, history }: any) {
  // console.log('[SAGA] API CALL: snsLogin');
  try {
    const { data } = yield call(auth.snsIntegrate, payload);
    // console.log('[SAGA] API response');
    // console.log(data);
    yield put({ type: USERINFO_COMPLETE, payload: data });
    yield put({
      type: SHOW_MODAL,
      payload: {
        title: 'success',
        msg: '연동성공',
        redirectPath: false,
        statusCode: false,
        errorCode: 0,
        type: 'success',
        isModalShow: true,
      },
    });
  } catch (err) {
    // console.log('[SAGA] API error');
    // console.log(err);
  }
}

function* logout({ payload, history }: any) {
  // console.log('[SAGA] API CALL: logout');
  try {
    const res = yield call(auth.logout, {});
    yield put({ type: AUTH_CLEAR });
    yield put({ type: USERINFO_CLEAR });
    history.push('/');
  } catch (err) {
    // console.log('[SAGA] API error');
    // console.log(err);
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_REQUEST, login);
  yield takeEvery(SIGNUP_REQUEST, signup);
  yield takeEvery(SNS_LOGIN_REQUEST, snsLogin);
  yield takeEvery(SNS_INTEGRATE_REQUEST, snsIntegrate);
  yield takeEvery(LOGOUT_REQUEST, logout);
}

export default authSaga;
