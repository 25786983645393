import React, {useState, useEffect, useContext} from 'react';
import StatusTag from '../../components/atoms/tag/statusTag';
import DefaultTemplete from '../../components/templetes/default';
import { getTodayDate, getStayHourConfirm, getYear5, getYear, getMonth} from '../../utils/date';
import { GUEST_TYPE, LODGE_MOVE_CODE, LODGE_STATUS_CODE, ROOM_TYPE, ROOM_TYPE_CODE, WORK_SITE, WORK_SITE_CODE, MOVE_STATUS_CODE, DELAY_COUNT_CODE, CHECKOUT_STATUS_CODE } from '../../constant';
import './index.scss';
import { lodge } from '../../apis';
import { useSelector } from 'react-redux';
import { MessageContext } from '../../routes';
import { isNull, range } from 'lodash';
import { SHOW_MODAL } from '../../store/modules/modal';
import { addMonths, format } from 'date-fns';
import DeductDetail from '../../components/organismas/popup/deduct';


const LodgeGuestDetailPage = (props: any) => {
  const { info } = useContext(MessageContext);
  const { userInfo = {} } = useSelector((state: any) => ({
      userInfo: state.user
  }));
  const [guestData, setGuestData] = useState<any>();
  const [reload, setReload] = useState<boolean>(false);
  const [fixedBtn, setFixedBtn] = useState<boolean>(false);
  const [moveBtn, setMoveBtn] = useState<boolean>(false);
  const [moveYearBtn, setMoveYearBtn] = useState<boolean>(false);
  const [reqMoveBtn, setReqMoveBtn] = useState<boolean>(false);
  const [checkoutBtn, setCheckoutBtn] = useState<boolean>(false);
  const [companyCheckoutBtn, setCompanyCheckoutBtn] = useState<boolean>(false);
  const [isShowDeductPop, setIsShowDeductPop] = useState<boolean>(false); // 공제팝업

  useEffect(() => {
    (async () => {
      const data = await fetchData();      
      setGuestData(data);

      // 1:1 이숙은 남양, 울산, 마북만 가능
      if(guestData && guestData.booking){
        if(WORK_SITE_CODE[guestData.room_work_site] === WORK_SITE_CODE.남양 || WORK_SITE_CODE[guestData.room_work_site] === WORK_SITE_CODE.울산 || WORK_SITE_CODE[guestData.room_work_site] === WORK_SITE_CODE.마북) {
          setReqMoveBtn(true)
        }
      }

      await getFixedList();   // 정기 이숙 가능인지 판단
      await getMoveList();    // 이숙 신청 내역있는지 판단
      await checkoutCheck();  // 퇴숙 신청 내역있는지 판단
    })();
  }, [reload]);

  // 정기 이숙 가능한 아이인지 확인
  const getFixedList = async() => {
    try {
      if(guestData && guestData.booking){
        const payload: any = {
          work_site: WORK_SITE_CODE[guestData.room_work_site],
          is_use:1
        }
        const { data }: any = await lodge.getFixedList(payload);
  
        // 정기이숙에서 오늘 날짜가 포함된 리스트만 받기
        let tmpList = data.filter((item: any, idx: number) => {
            if(getStayHourConfirm(item.start_date, item.end_date, getTodayDate()[0])) return item
        });
  
        if(tmpList.length > 0){
          setFixedBtn(true);
        }
  
        // 정기는 남양, 마북만
        if(WORK_SITE_CODE[guestData.room_work_site] !== WORK_SITE_CODE.남양 && WORK_SITE_CODE[guestData.room_work_site] !== WORK_SITE_CODE.마북) {
          setFixedBtn(false);
        }
      }
      setReload(true);
    } catch ({ status, data }) {
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }
    }
  }

  // 이미 신청한 이숙내역이 있는지 확인필요
  const getMoveList = async() =>{
    try {
      if(guestData && guestData.booking){
        // 정기/상시
        const { data }: any = await lodge.lodgeMoveList({});
  
        // 1:1
        const payload: any = {
          employee_number: userInfo.employeenumber,
          room_work_site: WORK_SITE[WORK_SITE_CODE[guestData.room_work_site]]
        };
        const { data : reqData }: any = await lodge.lodgeOneToOneMoveList(payload);
  
        let moveList: any = data.filter((d: any) => (
          d.status === MOVE_STATUS_CODE.apply || d.status === MOVE_STATUS_CODE.approve
        ));
  
        let reqMoveList: any = reqData.filter((d: any) => (
          d.status === MOVE_STATUS_CODE.apply || d.status === MOVE_STATUS_CODE.approve || d.status === MOVE_STATUS_CODE.resApprove
        ));
  
        // 이미 신청 내역이 있으면 버튼 노출 X
        if(moveList.length > 0 || reqMoveList.length > 0){
          await setMoveBtn(false);
        }else {
          await setMoveBtn(true);
        }

        // 정기, 상시, 1:1 이숙 1년내에 신청 건이 있는지 체크
        let { data: alwayMv } = await lodge.lodgeMoveCount(props.history.location.state.guest_employee_number, 2);
        let { data: fixedMv } = await lodge.lodgeMoveCount(props.history.location.state.guest_employee_number, 3);
        let { data: oneMv } = await lodge.lodgeMoveCount(props.history.location.state.guest_employee_number, 4);

        if(alwayMv === "데이터가 존재" || fixedMv === "데이터가 존재" || oneMv === "데이터가 존재"){
          setMoveYearBtn(false);
        }else{
          setMoveYearBtn(true);
        }
      }
    } catch (error) {
      //console.log(error);

      const { status, data } = error;

      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

    }
  }

  // 정기, 상시, 1:1 이숙 1년내에 신청내역이 있는지 체크
  const checkMoveHistory = async(status: number) => {
    try {
      // const payload = {
      //   classify : props.history.location.state.guest_employee_number,
      //   status : status,
      //   booking : props.history.location.state.booking.id
      // }
      // let { data: alwayMv } = await lodge.lodgeMoveCount(props.history.location.state.guest_employee_number, 2);
      // let { data: fixedMv } = await lodge.lodgeMoveCount(props.history.location.state.guest_employee_number, 3);
      // let { data: oneMv } = await lodge.lodgeMoveCount(props.history.location.state.guest_employee_number, 4);

      // if(alwayMv === "데이터가 존재" || fixedMv === "데이터가 존재" || oneMv === "데이터가 존재"){
      //   info('이숙신청이 불가합니다 \n 1년에 1회 이숙이 가능합니다');
      // }else{
        if(status === 2){   // 상시
          props.history.location.state.category = LODGE_MOVE_CODE.alway; 
          props.history.push('/mypage/move/apply', props.history.location.state)
        }else if(status === 3){   // 정기
          props.history.location.state.category = LODGE_MOVE_CODE.fixed; 
          props.history.push('/mypage/move/apply', props.history.location.state)
        }else if(status === 4){   // 1:1
          props.history.push('/mypage/move/reqapply', props.history.location.state)
        }
      // }
    } catch (error) {
      //console.log(error);

      const {status, data} = error;

      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

    }
  }

  // 퇴숙 신청건 조회
  const checkoutCheck = async() => {
    try {
      // 1:1
      const { data }: any = await lodge.getCheckout({});
      let checkoutList = data.filter((item: any) => item.status !== CHECKOUT_STATUS_CODE.cancel && item.status !== CHECKOUT_STATUS_CODE.reject && item.room_type === ROOM_TYPE_CODE.dormitory_room);
      let companyCheckoutList = data.filter((item: any) => item.status !== CHECKOUT_STATUS_CODE.cancel && item.status !== CHECKOUT_STATUS_CODE.reject && item.room_type === ROOM_TYPE_CODE.company_room);

      // 기숙사 퇴숙 신청 버튼 노출
      if(checkoutList.length > 0) {
        setCheckoutBtn(false);
      }else {
        setCheckoutBtn(true);
      }

      // 사택 퇴숙 신청 버튼 노출
      if(companyCheckoutList.length > 0) {
        setCompanyCheckoutBtn(false);
      }else {
        setCompanyCheckoutBtn(true);
      }

    } catch (error) {
      //console.log(error);
      const {status, data} = error;

      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

    }
  }

  // 상세 데이터
  const fetchData = async () => {
    try {
      let guestId = props.history.location.state.id;
      const { data }: any = await lodge.getGuestDetail(guestId);
      return data
    }catch ({ status, data }) {
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }
    }
  }

  if (!props.history.location.state) {
    if (props.history.action !== 'PUSH') {
      props.history.push('/mypage/guest');
    } else {
      props.history.goBack();
    }
    return <></>
  }

  return (
    <DefaultTemplete
      id="wrap"
      className="wrap_mypage_info"
      addClassName="w100"
      leftInfo={
        <>
          <h2 className="">숙소 이용정보</h2>
          <p className="desc">숙소이용 이력 및 상세내용을 확인하실 수 있습니다</p>
        </>
      }
      {...props}
    >

    <ul className="lst_thumb_room">
        <li>
            <strong>사업장</strong>
            <span>{props.history.location.state.room_work_site}</span>
        </li>
        <li>
            <strong>숙소유형</strong>
            <span>{ROOM_TYPE[props.history.location.state.room_type]}</span>
        </li>
        <li>
            <strong>동호수</strong>
            <span>{props.history.location.state.room_building_name} {props.history.location.state.room_building_dong} {props.history.location.state.room_name}</span>
        </li>
        <li>
            <strong>입숙일</strong>
            <span>{props.history.location.state.checkin_at}</span>
        </li>
        {props.history.location.state.room_type === ROOM_TYPE_CODE.dormitory_room || props.history.location.state.room_type === ROOM_TYPE_CODE.company_room ? 
            (<li>
                <strong>만료일</strong>
                <span>{props.history.location.state.expired_at}</span>
            </li>) : ''
        }
        <li>
            <strong>퇴숙일</strong>
            <span>{props.history.location.state.checkout_at ? props.history.location.state.checkout_at : '-'}</span>
        </li>
        <li>
            <strong>신청자ID</strong>
            <span>{props.history.location.state.booking.applicant_employee_number}</span>
        </li>
        <li>
            <strong>신청자휴대폰</strong>
            <span>{props.history.location.state.booking.applicant_cellphone}</span>
        </li>
    </ul>
      
    {/* <div className="title_contents">
      <h3 className="title">기본정보</h3>
    </div>

    <div className="table_design_list col_width6">
      <div id="table" className="table">
        <div className="tbody">
          <div className="tr">
            <div className="th">사번(아이디)</div>
            <div className="td">{props.history.location.state.guest_employee_number}</div>
            <div className="th">이름</div>
            <div className="td">{props.history.location.state.guest_name}</div>
            <div className="th">성별</div>
            <div className="td">{props.history.location.state.guest_gender === 1 ? "남" : "여"}</div>
          </div>
          <div className="tr">
            <div className="th">소속부서</div>
            <div className="td">{props.history.location.state.guest_department}</div>
            <div className="th">근무조</div>
            <div className="td">{props.history.location.state.guest_party}</div>
            <div className="th blank"></div>
            <div className="td blank"></div>
          </div>
        </div>
      </div>
    </div>  */}

      <div className="title_contents">
        <h3 className="title">이용자 정보</h3>
      </div>

      <div className="table_design_list col_width4 mix_width2">
        <div id="table" className="table">
          <div className="tbody">
            <div className="tr">
              <div className="th">소속회사유형</div>
              <div className="td">{GUEST_TYPE[props.history.location.state.guest_type]}</div>
              {/* <div className="th">신청일자</div>
              <div className="td">{formatByString_YYYYMMDD(props.history.location.state.booking.created_at)}</div> */}
              <div className="th">구분</div>
              <div className="td">
                {/* <StatusTag status={props.history.location.state.status} /> */}
                {props.history.location.state.status === LODGE_STATUS_CODE.checkin? (<span className="state_room case3">이용중</span>) 
                        : props.history.location.state.status === LODGE_STATUS_CODE.checkout? (<span className="state_room case">퇴숙</span>) : '' }
              </div>
            </div>
            <div className="tr">
              <div className="th">사번(아이디)</div>
              <div className="td">{props.history.location.state.guest_employee_number}</div>
              <div className="th">성명</div>
              <div className="td">{props.history.location.state.guest_name}</div>
            </div>
            <div className="tr">
              <div className="th">휴대폰번호</div>
              <div className="td">{props.history.location.state.guest_cellphone}</div>
              <div className="th">성별</div>
              <div className="td">{props.history.location.state.guest_gender === 1 ? "남" : "여"}</div>
            </div>
            {props.history.location.state.room_type === ROOM_TYPE_CODE.dormitory_room ? (
              <>
              <div className="tr">
                <div className="th">소속부서</div>
                <div className="td">{props.history.location.state.guest_department}</div>
                <div className="th">근무조</div>
                <div className="td">{props.history.location.state.guest_party}</div>
              </div>
              <div className="tr">
                {/* <div className="th">퇴숙일</div>
                <div className="td">{props.history.location.state.checkout_at}</div> */}
                <div className="th">차량번호</div>
                <div className="td">{props.history.location.state.guest_car_number1}</div>
                <div className="th">
                  공제내역
                </div>
                <div className="td">
                  {/* 58,000원 */}
                  <button className="btn btn_sm btn_gray left" onClick={()=>{setIsShowDeductPop(true)}}>상세내역</button>
                </div>
              </div>
              </>
            ): ''}
            {props.history.location.state.room_type === ROOM_TYPE_CODE.company_room ? (
              <>
              <div className="tr">
                <div className="th">차량번호1</div>
                <div className="td">{props.history.location.state.guest_car_number1}</div>
                <div className="th">차량번호2</div>
                <div className="td">{props.history.location.state.guest_car_number2}</div>
              </div>
              <div className="tr">
                <div className="th">차량번호3</div>
                <div className="td">{props.history.location.state.guest_car_number3}</div>
                <div className="th">
                  공제내역
                </div>
                <div className="td">
                  <button className="btn btn_sm btn_gray left" onClick={()=>{setIsShowDeductPop(true)}}>상세내역</button>
                </div>
              </div>
              </>            ): ''}
            {props.history.location.state.room_type === ROOM_TYPE_CODE.vip_room ? (
              <div className="tr">
                <div className="th">소속부서</div>
                <div className="td">{props.history.location.state.guest_department}</div>
                <div className="th">차량번호</div>
                <div className="td">{props.history.location.state.guest_car_number1}</div>
              </div>
            ): ''}
            {props.history.location.state.room_type === ROOM_TYPE_CODE.business_room ? (
              <div className="tr">
                <div className="th">소속부서</div>
                <div className="td">{props.history.location.state.guest_department}</div>
                <div className="th">식권 수 (장)</div>
                <div className="td">{Number(props.history.location.state.booking.meal_ticket ? props.history.location.state.booking.meal_ticket : 0) - Number(props.history.location.state.return_meal_ticket ? props.history.location.state.return_meal_ticket : 0)}</div>
              </div>
            ): ''}  
            {props.history.location.state.room_type == ROOM_TYPE_CODE.business_room && WORK_SITE_CODE[props.history.location.state.room_work_site] === WORK_SITE_CODE.울산 ?
            //checkou_id 도 추가되어야함 **** 퇴숙시에만 버튼이 노출되어야함
              <div className="tr">
                <div className="th">
                  공제내역
                </div>
                <div className="td">
                  <button className="btn btn_sm btn_gray left" onClick={()=>{setIsShowDeductPop(true)}}>상세내역</button>
                </div>
              </div>
            : <></>}
            {/* <div className="tr">
              <div className="th">이용사업장</div>
              <div className="td">{props.history.location.state.room_work_site}</div>
              <div className="th">숙소유형</div>
              <div className="td">{ROOM_TYPE[props.history.location.state.room_type]}</div>
            </div>
            <div className="tr">
              <div className="th">입숙일</div>
              <div className="td">{props.history.location.state.checkin_at}</div>
              <div className="th">만료일</div>
              <div className="td">{props.history.location.state.expired_at}</div>
            </div> */}
            {/* <div className="tr">
              <div className="th textarea">비고</div>
              <div className="td textarea">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores at autem, consectetur consequuntur deleniti deserunt est, magni maxime nemo nostrum numquam obcaecati quam quia quibusdam quod recusandae reiciendis rem saepe.
              </div>
            </div> */}
          </div>
        </div>
      </div> {/* table_design_list */}

      

      <div className="submit inline">
        <div className="fl">
          <button className="btn btn_lg btn_gray" onClick={() => props.history.push('/mypage/guest')}>목록</button>
        </div>
        <div className="fr">
        {props.history.location.state.room_type === ROOM_TYPE_CODE.dormitory_room && props.history.location.state.status === LODGE_STATUS_CODE.checkin 
          && checkoutBtn && moveBtn && moveYearBtn ? (
          <>
            <button className="btn btn_lg btn_white"
              onClick={() => {checkMoveHistory(DELAY_COUNT_CODE.normal)}}>
                상시이숙신청
            </button>
            <button className="btn btn_lg btn_white" style={{display: reqMoveBtn? '' : 'none'}}
              onClick={() => {checkMoveHistory(DELAY_COUNT_CODE.oneOne)}}>
                1:1이숙신청
            </button>
            <button className="btn btn_lg btn_white" style={{display: fixedBtn? '' : 'none'}}
              onClick={() => {checkMoveHistory(DELAY_COUNT_CODE.regular)}}>
                정기이숙신청
            </button>
          </>
        ) : ''}
        {/* 기숙사 퇴숙 신청 버튼 */}
        {props.history.location.state.room_type === ROOM_TYPE_CODE.dormitory_room && props.history.location.state.status === LODGE_STATUS_CODE.checkin 
          && checkoutBtn && moveBtn? (
            <button className="btn btn_lg btn_white" onClick={() => props.history.push('/mypage/checkout/apply', props.history.location.state)}>퇴숙신청</button>
        ) : ''}

        {/* 사택 퇴숙 신청 버튼 */}
        {props.history.location.state.room_type === ROOM_TYPE_CODE.company_room && props.history.location.state.status === LODGE_STATUS_CODE.checkin 
          && companyCheckoutBtn ? (
            <button className="btn btn_lg btn_white" onClick={() => props.history.push('/mypage/checkout/apply', props.history.location.state)}>퇴숙신청</button>
        ) : ''}
        </div>
      </div>

      <div className="mb_view">
          <button className="btn btn_lg btn_gray" onClick={() => props.history.push('/mypage/guest')}>목록</button>
      </div>


      {/* 공제내역 */}
      <DeductDetail
        isShowDeductPop={isShowDeductPop}
        deductData={props}
        onClose={() => setIsShowDeductPop(false)}
      />
    </DefaultTemplete>
  );
};

export default LodgeGuestDetailPage;
function changeYearMonth() {
  throw new Error('Function not implemented.');
}