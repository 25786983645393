import base from './base';
import error from './error';
import validation from './validation';

const d = {
  en: {
    ...base.en,
    ...error.en,
    ...validation.en
  },
  kr: {
    ...base.kr,
    ...error.kr,
    ...validation.kr
  }
}

export default d;
