import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;

class Core {
  static URL = `${IP}`;

  @apiHandler()
  static async fileConvertGuests(payload: any) {
    const PATH = '/core/file/convert/guests/';
    return await axios.post(`${Core.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async getWorkSites(payload: {}) {
    const PATH = '/member/worksite/';
    return await axios.get(`${Core.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async fileUpload<T>(payload: any): Promise<{data: T, status: number}> {
    const PATH = '/core/file/upload/';
    return await axios.post(`${Core.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 이용안내 조회
  @apiHandler()
  static async getGuides(payload: {work_site: number, room_type: number, is_visible?: boolean}) {
    const PATH = '/member/worksite/lodge/guide/';
    return await axios.get(`${Core.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // [공통] 첨부된 파일 다운로드
  @apiHandler()
  static async fileDownloadFileSaver(payload : any) {
    const PATH = '/admin/board/filedownload/';
    return await axios.get(`${Core.URL}${PATH}`, {
      params : payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // [공통] 첨부된 파일 다운로드
  @apiHandler()
  static async fileDownload(payload : any) {
    const PATH = '/core/file/filedownload/';
    return await axios.get(`${Core.URL}${PATH}`, {
      params : payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
      responseType: 'blob'
    });
  }
}


export default Core
