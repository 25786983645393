import React, { useState, useEffect, useContext } from 'react';
import intl from 'react-intl-universal';
import DefaultTemplete from '../../components/templetes/listDefault';
import { EXHIBIT_STATUS, SEARCH_TYPE } from '../../constant';
import { audit as api } from '../../apis';
import { audit } from '../../apis';
import { formatByString, isAfterAndSame, getNextDay, getPrevDay, getTodayDate, getPrevMonth, getTwoLatersMonthDate, getTwoLatersOldMonthDate} from '../../utils/date';
// import './index.scss';
import DatePicker from '../../components/organismas/datePicker';
import Select from '../../components/atoms/select';
import { MessageContext, MessageImpl } from '../../routes';
import { parserEscape } from '../../utils/commons';
import CustomCalendar from '../../components/organismas/customCalendar';
import { SHOW_MODAL } from '../../store/modules/modal';

const AuditPage = (props: any) => {
  const [limit, setLimit] = useState<number>(12);
  const [offset, setOffset] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchType, setSearchType] = useState<number>(-1);
  const [created_after, setCreated_after] = useState<string>(getTwoLatersOldMonthDate(2)[1]);
  const [created_before, setCreated_before] = useState<string>(getTwoLatersMonthDate(2)[1]);
  const [search, setSearch] = useState<string>('');
  const [items, setItems] = useState<any[]>([]);
  const [isNext, setIsNext] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const searchTypes = [{ value: '-1', label: '전체', optionIdx: 0 }];
  Object.keys(SEARCH_TYPE).forEach((key: string, index: number) => {
    searchTypes.push({
      value: key,
      label: SEARCH_TYPE[Number(key)],
      optionIdx: index + 1,
    });
  });

  useEffect(() => {
    (async () => {
      const data = await fetchItems({ 
        limit : 12,
        offset: 0,
        created_after,
        created_before,
      });
      // console.log(data);
      setItems(data.results || []);
      setIsNext(!!data.next);
      setTotalPage(Math.ceil(data.count / limit));
    })();
  }, []);

  const fetchItems = async (payload: any) => {
    switch (payload.searchType) {
      case 1: payload.title = payload.search; break;
      case 2: payload.contents = payload.search; break;
      case 3: payload.author = payload.search; break;
    }
    delete payload.searchType;

    if (!created_after) {
      delete payload.created_after;
    }

    if (!created_before) {
      delete payload.created_before;
    }

    try {
      const { data } = await api.getAuditList(payload);
      return data;
    }catch ({ status, data }) {
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  };

  const onSearchTypeFilter = (_searchType: number) => {
    // console.log(_searchType);
    setSearchType(_searchType);
  };

  const onSearchHandler = async () => {
    const data = await fetchItems({
      limit,
      offset: 0,
      searchType,
      search,
      created_after,
      created_before,
    });
    setItems(data.results);
    setIsNext(!!data.next);
    setTotalPage(Math.ceil(data.count / limit));
  };

  const onNextHandler = async () => {
    if (isNext) {
      const payload: any = {
        limit,
        offset: limit * (currentPage + 1),
      }
      if (search) {
        payload.title = search;
      }
      const data = await fetchItems(payload);
      const tmpItems =
        items.length > 0 ? [...items, ...data.results] : data.results;
      setItems(tmpItems);
      setIsNext(!!data.next);
      setTotalPage(Math.ceil(data.count / limit));
      setCurrentPage(currentPage + 1);
    }
  };

  const removeTags = (text: string) =>
    text && text.replace(/<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/gi, '');
    
    
  const { info }: MessageImpl = useContext(MessageContext);
  //등록일자 관련 handler
  const onClickDateAfterByCalendar = (day: string) => {
    // dateAfter <= dateBefore
    const _isAfter = isAfterAndSame(day, created_before);
    //props.setDateAfter(day);
    setCreated_after(day);

    if (!_isAfter || day === created_before) {
      setCreated_after(getPrevDay(created_before)[0]);
      info(`${created_before}보다 이후가 될 수 없습니다.`);
    }
  };

  const onClickDateBeforeByCalendar = (day: string) => {
    // dateAfter <= dateBefore
    const _isAfter = isAfterAndSame(created_after, day);
    setCreated_before(day);
    if (!_isAfter) {
      setCreated_before(getNextDay(created_after)[0]);
      info(`${created_after}보다 이전이 될 수 없습니다.`);
    }
  };
  return (
    <DefaultTemplete
      leftInfo={
        <>
          <h2>{intl.get('정기서류검사등록')}</h2>
          <p className="desc">
            {intl.get('정기서류검사를 위한 안내 및 서류 등록을 하실 수 있습니다')}
          </p>
          <div className="bg_img doc"></div>
        </>
      }
      id="wrap"
      className="wrap_customer"
      {...props}
    >
      {/* <div className="group_search">
        <div className="search_form">
          <input
            type="text"
            className="form_control"
            onKeyUp={e => (e.key === 'Enter' ? onSearchHandler() : null)}
            onChange={(e: any) => setSearch(e.target.value)}
          />
          <button
            type="button"
            className="btn btn_darkblue btn_search"
            onClick={onSearchHandler}
          >
            {intl.get('검색')}
          </button>
        </div>
      </div> */}

      <div className="table_design_list col_width2 mt0">
        <div id="table" className="table">
          <div className="thead mobile">
            <div className="tr">
              <div className="th"></div>
              <div className="th">
                {intl.get('검색')}
              </div>
            </div>
          </div>
          <div className="tbody">
            <div className="tr">
              <div className="th">{intl.get('작성일자')}</div>
              <div className="td">
                <div className="group_datepicker">
                  {/* <DatePicker
                    value={created_after}
                    width={250}
                    onChange={(date: string) => onClickDateAfterByCalendar(date)}
                    isPrevMonthView={true}
                    isNextMonthNotView={false}
                    max={getTwoLatersMonthDate(2)[1]}
                  /> */}
                  <CustomCalendar
                    max={getTwoLatersMonthDate(2)[1]}
                    value={created_after}
                    onChange={(date:string) => {onClickDateAfterByCalendar(date)}}
                  ></CustomCalendar> 
                  <span>~</span>
                  {/* <DatePicker
                    value={created_before}
                    width={250}
                    onChange={(date: string) => onClickDateBeforeByCalendar(date)}
                    isPrevMonthView={true}
                    isNextMonthNotView={false}
                    max={getTwoLatersMonthDate(2)[1]}
                  /> */}
                  <CustomCalendar
                    max={getTwoLatersMonthDate(2)[1]}
                    value={created_before}
                    onChange={(date:string) => {onClickDateBeforeByCalendar(date)}}
                  ></CustomCalendar> 
                </div>
              </div>
            </div>
            <div className="tr">
              <div className="th">검색어</div>
              <div className="td">
                <div className="search_form">
                  <Select
                    className="form_control"
                    options={searchTypes}
                    onChange={(e: any) => onSearchTypeFilter(Number(e.target.value))}
                  />
                  <input
                    type="text"
                    className="form_control"
                    onKeyUp={e => (e.key === 'Enter' ? onSearchHandler() : null)}
                    onChange={(e: any) => setSearch(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn_darkblue btn_search"
                    onClick={onSearchHandler}>
                    {intl.get('검색')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
      {!items.length ? (
          <ul className="lst_notice blank_area">
            <li className="txt_blank">데이터가 없습니다</li>
          </ul>
        ) : (
          <>
            <ul className="lst_notice">
                {items.map((item, index) => (
                  <li
                    key={index}
                    onClick={() => props.history.push(`/customer/audit/detail`, item)}
                  >
                    <a href="javascript:void(0);" className="box">
                      <span className={['state_room', 'case6'].join(' ')}>
                          {EXHIBIT_STATUS[item.status]}
                      </span>
                      <p className="title">{item.audit.title}</p>
                      <p className="desc" dangerouslySetInnerHTML= {{ __html: parserEscape(item.audit.contents)}}></p> {/*removeTags(item.audit.contents)*/}
                      <p className="etc">
                        {formatByString(item.audit.created_at, 'yyyy.MM.dd HH:mm')}
                      </p>
                    </a>
                  </li>
                ))}
            </ul>
          </>
      )}
      

      <div className="more_area ac">
        {isNext ? (
          <button className="btn btn_lg btn_outline" onClick={onNextHandler}>
            {intl.get('더보기')} <i className="ico_more"></i>
          </button>
        ) : (
          <></>
        )}
      </div>
    </DefaultTemplete>
  );
};

export default AuditPage;
