import React, { useState, useEffect, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';

import { MessageContext } from '../../routes';

import EmployeeNumberInput from '../../components/atoms/input/employeeNumber';
import DepartmentInput from '../../components/atoms/input/department';
import WorkSiteSelect from '../../components/atoms/select/workSite';
import SelectCompany from '../../components/atoms/select/company';
import ConfirmBtn from '../../components/atoms/button/confirm';
import PhoneInput from '../../components/atoms/input/phone';
import NameInput from '../../components/atoms/input/name';

import GuestType from '../../components/molecules/tab/guestType';

import UploadFileList from '../../components/organismas/uploadFileList';

import TermsDetail from '../../components/organismas/termsDetail';

import intl from 'react-intl-universal';

import { blankRemoveJson } from '../../utils/commons';
import { GENDER, FILE_UPLOAD_CODE, WORK_SITE_CODE, DOCUMENT_FILE_VISIBLE_TYPE_CODE, ROOM_TYPE_CODE } from '../../constant';
import { auth, pledge, core } from '../../apis';

import './step1.scss';
import { observer } from 'mobx-react';
import { useStores } from '../../store/mobx';
import DatePicker from '../../components/organismas/datePicker';
import { getTodayDate, getTwoLatersMonthDate, getNextDay } from '../../utils/date';
import CustomCalendar from '../../components/organismas/customCalendar';
import OnOff from '../../apis/onOff';
import { SHOW_MODAL } from '../../store/modules/modal';

const Step1 = observer((props: any) => {
  const {reservationStore: store} = useStores();
  const { info } = useContext(MessageContext);

  const [guestType, setGuestType] = useState<number>(store._companyRoom.type? store._companyRoom.type : 1);
  const [isSame, setIsSame] = useState<boolean>(store._companyRoom.isSame? store._companyRoom.isSame : false);
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user
  }));
  const { workPlaceInfo = {} } = useSelector((state: any) => ({
    workPlaceInfo: state.base,
  }));

  const [employeeNumber, setEmployeeNumber] = useState(store._companyRoom.employee_number? store._companyRoom.employee_number : '');
  const [newEmployeeNumber, setNewEmployeeNumber] = useState(store._companyRoom.employee_number? store._companyRoom.employee_number : '');
  const [name, setName] = useState(store._companyRoom.name? store._companyRoom.name : '');
  const [department, setDepartment] = useState(store._companyRoom.department? store._companyRoom.department : '');
  const [phone, setPhone] = useState(store._companyRoom.cellphone? store._companyRoom.cellphone : '');
  const [gender, setGender] = useState(store._companyRoom.gender? store._companyRoom.gender : 1); // 남자(1), 여자(2)
  const [office, setOffice] = useState(store._companyRoom.company? store._companyRoom.company : '');
  const [carNumber, setCarNumber] = useState(store._companyRoom.car_number1? store._companyRoom.car_number1 : '');
  const [carNumber2, setCarNumber2] = useState(store._companyRoom.car_number2? store._companyRoom.car_number2 : '');
  const [carNumber3, setCarNumber3] = useState(store._companyRoom.car_number3? store._companyRoom.car_number3 : '');
  const [isCC, setIsCC] = useState<boolean>(store._companyRoom.is_cc? store._companyRoom.is_cc : false);
  const [isSupported, setIsSupported] = useState<boolean>(store._companyRoom.is_supported? store._companyRoom.is_supported : false);
  const [married, setMarried] = useState<boolean>(store._companyRoom.married? store._companyRoom.married : false);
  const [checkin_hope, setCheckin_hope] = useState(store._companyRoom.checkin_hope? store._companyRoom.checkin_hope : '');

  // 이용약관, 개인정보 자세히보기 추가
  const [tabNumber, setTabNumber] = useState<number>(-1);

  const [privacyPolicyAgreed, setPrivacyPolicyAgreed] = useState<boolean>(store._companyRoom.privacyPolicyAgreed? store._companyRoom.privacyPolicyAgreed : false);

  const [personalInformationCollectionAgreed, setPersonalInformationCollectionAgreed] = useState<boolean>(store._companyRoom.personalInformationCollectionAgreed? store._companyRoom.personalInformationCollectionAgreed : false);
  const [pledgeFile, setPledgeFile] = useState<string>("");

  useEffect(() => {
    // setEmployeeNumber(userInfo.employeenumber);
    // setName(userInfo.username);
    // setDepartment(userInfo.department);
    // setPhone(userInfo.phone);
    // setGender(userInfo.gender);
    // setPrivacyPolicyAgreed(true);
    // setPersonalInformationCollectionAgreed(true)
    (async () => {
      try{
        if(userInfo.worksite === 2 || userInfo.worksite === 6){
          
          let payload: any = {
            work_site: userInfo.worksite,
            guest_type: guestType,
            room_type: props.roomType,
            visible_type: DOCUMENT_FILE_VISIBLE_TYPE_CODE.apply
          };
  
          let { data: pledgeData } = await pledge.getPledges(payload);
            
          if (pledgeData.length) {
            setPledgeFile(pledgeData[0].pledge_file);
          }
        }
      }catch ({ status, data }) {
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }
    })();
  }, [userInfo]);

  useEffect(() => {
    info(intl.get('사택 신청전 이용안내 탭에서 사택 입숙조건 및 기타 내용 확인 후 신청해주세요.'));
  }, []);

  

  const init = () => {
    setEmployeeNumber('');
    setName('');
    setDepartment('');
    setPhone('');
    setGender(1);
    setOffice('');
    setPrivacyPolicyAgreed(false);
    setPersonalInformationCollectionAgreed(false);
    setCarNumber('');
    setCarNumber2('');
    setCarNumber3('');
    setIsCC(false);
    setIsSupported(false);
    setIsSame(false);
    setGuestType(1);
    setCheckin_hope('');
  };

  const onClickUserSame = (e: any) => {

    if (e.target.checked) {
      setGuestType(1);
      setIsSame(true);
      setEmployeeNumber(userInfo.employeenumber);
      setNewEmployeeNumber(userInfo.employeenumber);
      setName(userInfo.username);
      setDepartment(userInfo.department);
      setPhone(userInfo.phone);
      setGender(userInfo.gender);
      setMarried(userInfo.isMarried);
      //setPrivacyPolicyAgreed(true);
      //setPersonalInformationCollectionAgreed(true)
      setCarNumber('');
      setCarNumber2('');
      setCarNumber3('');
    } else {
      setIsSame(false);
      setEmployeeNumber('');
      setNewEmployeeNumber('');
      setName('');
      setDepartment('');
      setPhone('');
      setGender(1);
      setCarNumber('');
      setCarNumber2('');
      setCarNumber3('');
      setIsCC(false);
      setIsSupported(false);
      //setPrivacyPolicyAgreed(false);
      //setPersonalInformationCollectionAgreed(false)
    }
  };

  const next = (e: any) => {

    if (!employeeNumber) {
      info(intl.get('사번을 검색하여 이용자 정보를 등록해주세요.'));
      return;
    }

    if(guestType === 1 && (newEmployeeNumber !== employeeNumber)) {
      info(intl.get("사번을 적용해주세요."));
      return;
    }

    if (!privacyPolicyAgreed || !personalInformationCollectionAgreed) {
      info(intl.get('서비스이용약관 동의 또는 개인정보이용동의를 동의하지 않았습니다.'));
      return;
    }

    if (!name) {
      info(intl.get('성명을 입력해주세요.'));
      return;
    }

    if (!phone || !(phone.length === 11)) {
      info(intl.get('휴대폰번호 항목이 비었거나 잘못되었습니다.\n(휴대폰번호는 11자리입니다.)'));
      return;
    }

    if (!gender) {
      info(intl.get('성별 항목이 비었습니다.'));
      return;
    }

    if (guestType === 1 && (!employeeNumber || !department)) {
      if (!employeeNumber) {
        info(intl.get('사번을 검색하여 이용자 정보를 등록해주세요.'));
        return;
      }
      if (!department) {
        info(intl.get('소속부서를 입력해주세요.'));
        return;
      }
      return;
    } else if (guestType === 2 && (!employeeNumber || !department || !office)) {
      if (!employeeNumber) {
        info(intl.get('사번을 입력해주세요.'));
        return;
      }
      if (!department) {
        info(intl.get('소속부서를 입력해주세요.'));
        return;
      }
      if (!office) {
        info(intl.get('회사명을 선택해주세요.'));
        return;
      }
      return;
    } else if (guestType === 3 && !office) {
      if (!office) {
        info(intl.get('회사명 또는 단체명을 입력해주세요.'));
        return;
      }
    }

    let isFileUploaded = props.files.filter((file: any) => file.id < 1 && file.is_required)

    if (isFileUploaded.length) {
      info(intl.get("필수항목을 업로드하지 않았습니다."))
      return
    }
    
    // 사업장별 결혼 여부 체크 (남양: 기혼만)
    // if(guestType === 1 && props.workSite === WORK_SITE_CODE.남양) {
    //   if(!married) {
    //     info(intl.get("해당 사업장은 기혼만 신청 가능합니다."));
    //     return;
    //   }
    // }
    if(guestType === 1) {
      let chk: boolean = true;
      workPlaceInfo.workplaces.map((work: any) => {
        if((props.workSite === work.id) && work.booking_company_house_marriage_check && !married){
          chk = false;
        }
      });

      if(!chk){
        info(intl.get("해당 사업장은 기혼만 신청 가능합니다."));
        return;
      }
    }

    const userInfo: any = blankRemoveJson({
      type: guestType,
      employee_number: employeeNumber,
      name: name,
      department: department,
      cellphone: phone,
      gender: gender,
      company: office,
      car_number1: carNumber,
      car_number2: carNumber2,
      car_number3: carNumber3,
      is_cc: isCC,
      is_supported: isSupported,
      checkin_hope: checkin_hope
    });

    if(privacyPolicyAgreed) {
      userInfo.privacyAgree = 'Y'
    } else {
      userInfo.privacyAgree = 'N'
    }

    if(personalInformationCollectionAgreed) {
      userInfo.personalAgree = 'Y'
    } else {
      userInfo.personalAgree = 'N'
    }

    props.setUsers([userInfo]);

    // store 에 모든 데이터 담기!!!!!
    store._companyRoom = [];
    const reservationData: any = ({
      type: guestType,
      employee_number: employeeNumber,
      name: name,
      department: department,
      cellphone: phone,
      gender: gender,
      company: office,
      car_number1: carNumber,
      car_number2: carNumber2,
      car_number3: carNumber3,
      is_cc: isCC,
      is_supported: isSupported,
      work_site: props.workSite,
      isSame: isSame,
      privacyPolicyAgreed: privacyPolicyAgreed,
      personalInformationCollectionAgreed: personalInformationCollectionAgreed,
      checkin_hope : checkin_hope,
      married : married
    });

    store._companyRoom = reservationData;

    props.nextStep();
  };
  // 파일의 확장자를 가져오는 함수
  const getFileExtension = (filename: string) => {
    const parts = filename.split('.');
    if (parts.length > 1) {
      return parts.pop()!.toLowerCase(); // 파일 이름이 마침표를 포함하는 경우에만 확장자 추출
    } else {
      return ''; // 마침표가 없는 경우 빈 문자열 반환
    }
  };

  const downloadFile = async (url: string, filename: string) => {
    const token = window.localStorage.getItem('accessToken');
    console.log('url : ' + url);
    console.log('filename : ' + filename);

    if (!url) {
      console.error('url 경로가 없습니다.');
      return;
    }

    if(!filename || filename.length == 0) {
      const name = url.split("/");
      filename = name[name.length - 1];
    }
  
    const unifiedUrl = url.replace(/\\/g, '/');
    const parts = unifiedUrl.split('/');
    const desiredParts = parts.slice(2).join('\\');

    // 파일의 확장자가 이미지나 PDF인지 확인하는 함수
    const isImageOrPDF = (extension: string) => {
      const supportedExtensions = ['gif', 'jpg', 'jpeg', 'png', 'pdf'];
      return supportedExtensions.includes(extension);
    };

    const fileExtension = getFileExtension(filename);
    const isImageOrPDFFile = isImageOrPDF(fileExtension);

    //fileDownload
    try {
      let payload: any = {
        filename: filename,
        url : desiredParts,
      };

      let { data } = await core.fileDownload(payload);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      //info("협조전 등록이 완료되었습니다.");
    } catch ({ data, status }) {
      console.log(data, status);
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }
      info("파일 다운로드 중 문제가 발생했습니다");
      return;
    }
  };

  const onClickPersonnelHandler = async (e: any) => {
    if (!employeeNumber) {
      info(intl.get('사번을 입력해주세요.'));
      return;
    }
    try {
      const { data } = await auth.isSignedUser(employeeNumber);

      setName(data.name || '');
      setDepartment(data.personnel.department || '');
      setPhone(data.cellphone || '');
      setGender(data.personnel.gender);
      setMarried(data.personnel.is_married);
      
      // 입력한 사번데이터 담기
      setNewEmployeeNumber(employeeNumber);
    } catch ({ status, data }) {
      init();
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else if (status === 404) {
        info(`${employeeNumber}`+ intl.get('는 가입된 사번이 아닙니다.'));
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  };

  const onEmployeeNumberEnterHandler = async (e: any) => {
    if (e.key === "Enter") {
      onClickPersonnelHandler(e)
    }
  }

  const onShowtermsDetailPopupHandler = (idx: number) => {
    setTabNumber(idx);
  }; // 열기
  const onCloseTermsDetailPopupHandler = () => {
    setTabNumber(-1);
  }; // 닫기

  return (
    <>
      <div className="title_contents">
        <h3 className="title">{intl.get("신청자 정보")}</h3>
      </div>

      <div className="table_design_list col_width6">
        <div id="table" className="table">
          <div className="tbody">
            <div className="tr">
              <div className="th">{intl.get("사번 (아이디)")}</div>
              <div className="td">{userInfo.employeenumber}</div>
              <div className="th">{intl.get("성명")}</div>
              <div className="td">{userInfo.username}</div>
              <div className="th">{intl.get("성별")}</div>
              <div className="td">{GENDER[userInfo.gender]}</div>
            </div>
            <div className="tr">
              <div className="th">{intl.get("소속 부서")}</div>
              <div className="td">{userInfo.department}</div>
              <div className="th">{intl.get("휴대폰 번호")}</div>
              <div className="td">{userInfo.phone}</div>
              <div className="th"></div>
              <div className="td"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt20">
        <label className="input_check">
          <input
              type="checkbox"
              checked={isSame}
              onChange={onClickUserSame}
          />
          <span className="label_text">
            {intl.get("신청자와 이용자가 동일합니다 (신청자 본인이 이용할 경우 체크하시면 이용자 정보가 자동 입력 됩니다)")}
          </span>
        </label>
      </div>

      {/* 사택은 본인의 사업장으로 자동선택이라 사업장 선택 X */}
      {/* <WorkSiteSelect value={props.workSite}  options={worksites} onChange={(workSite: number) => props.setWorkSite(workSite)} /> */}

      <div className="title_contents">
        <h3 className="title">{intl.get("이용자 정보")}</h3>
      </div>

      <div className="tabs_area">
        <GuestType
          value={guestType}
          selectWorkSite={props.workSite}
          roomType={props.roomType}
          onChange={(guestType: number) => {
            init()
            setIsSame(false)
            setGuestType(guestType)
          }}
        />
      </div>

      {/* 사내 임직원 */}
      <div className={`tab_contents ${guestType === 1 ? 'on' : ''}`}>
        <div className="table_design_list col_width4">
          <div id="table" className="table">
            <div className="tbody">
              <div className="tr">
                <div className="th">{intl.get("사번 (아이디)")}</div>
                <div className="td">
                  <div className="form_wrap">
                    <EmployeeNumberInput
                      type="text"
                      className="form_control"
                      placeholder={intl.get("사번을 입력하세요")}
                      value={employeeNumber}
                      onChange={(e: any) => {
                        userInfo.employeenumber === e.target.value ? setIsSame(true) : setIsSame(false);
                        setEmployeeNumber(e.target.value)
                      }}
                      onKeyDown={onEmployeeNumberEnterHandler}
                    />
                    <button className="btn_sm btn_gray" onClick={onClickPersonnelHandler}>{intl.get("적용")}</button>
                  </div>
                </div>
                <div className="th">{intl.get("성명")}</div>
                <div className="td">
                  <NameInput
                    type="text"
                    className="form_control"
                    placeholder={intl.get("성명을 입력하세요")}
                    value={name}
                    onChange={(e: any) => setName(e.target.value)}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("소속 부서")}</div>
                <div className="td">
                  <DepartmentInput
                    type="text" className="form_control" placeholder={intl.get("소속부서를 입력하세요")}
                    value={department}
                    onChange={(e: any) => setDepartment(e.target.value)}
                    disabled={true}
                  />
                </div>
                <div className="th">{intl.get("성별")}</div>
                <div className="td">
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="sex_choice1"
                      name="similar-sex1"
                      checked={gender === 1}
                      onChange={e => setGender(e.target.checked ? 1 : 2)}
                      disabled={true}
                    />
                    <span className="label_text">{intl.get("남자")}</span>
                  </label>
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="sex_choice2"
                      name="similar-sex1"
                      checked={gender === 2}
                      onChange={e => setGender(e.target.checked ? 2 : 1)}
                      disabled={true}
                    />
                    <span className="label_text">{intl.get("여자")}</span>
                  </label>
                </div>
              </div>
              <div className="tr">                
                <div className="th">{intl.get("휴대폰 번호")}</div>
                <div className="td">
                  <PhoneInput
                    type="text"
                    id="input_cphone"
                    className="form_control"
                    placeholder={intl.get("휴대폰 번호를 입력하세요")}
                    value={phone}
                    onChange={(e: any) => setPhone(e.target.value)}
                    disabled={true}
                  />
                </div>
                <div className="th">{intl.get("차량번호")}1</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    value={carNumber}
                    onChange={e => e.target.value.length < 12 && setCarNumber(e.target.value)}
                    placeholder={intl.get("차량번호를 입력하세요")}
                  />
                </div>
              </div>

              <div className="tr">
                <div className="th">{intl.get("차량번호")}2</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    value={carNumber2}
                    onChange={e => e.target.value.length < 12 && setCarNumber2(e.target.value)}
                    placeholder={intl.get("차량번호를 입력하세요")}
                  />
                </div>
                <div className="th">{intl.get("차량번호")}3</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    value={carNumber3}
                    onChange={e => e.target.value.length < 12 && setCarNumber3(e.target.value)}
                    placeholder={intl.get("차량번호를 입력하세요")}
                  />
                </div>
              </div>
              <div className="tr">
              <div className="th">{intl.get("사내부부 여부")}</div>
                <div className="td">
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="guide_cc1"
                      name="guide_cc"
                      checked={isCC}
                      onChange={e => setIsCC(e.target.checked)}
                    />
                    <span className="label_text">{intl.get("여")}</span>
                  </label>
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="guide_cc2"
                      name="guide_cc"
                      checked={!isCC}
                      onChange={e => {
                        setIsCC(!e.target.checked);
                      }}
                    />
                    <span className="label_text">{intl.get("부")}</span>
                  </label>
                </div>
                <div className="th">{intl.get("주거지원금 수혜여부")}</div>
                <div className="td">
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="guide_support1"
                      name="guide_support"
                      checked={isSupported}
                      onChange={e => setIsSupported(e.target.checked)}
                    />
                    <span className="label_text">{intl.get("여")}</span>
                  </label>
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="guide_support2"
                      name="guide_support"
                      checked={!isSupported}
                      onChange={e => {
                        setIsSupported(!e.target.checked);
                      }}
                    />
                    <span className="label_text">{intl.get("부")}</span>
                  </label>
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("입숙희망일")}</div>
                <div className="td">
                  <CustomCalendar
                    min={getTodayDate()[0]}
                    value={checkin_hope}
                    onChange={(date:string) => {setCheckin_hope(date)}}
                  ></CustomCalendar>
                </div>
                <div className="th"></div>
                <div className="td"></div>
              </div>
            </div>
          </div>

          <div className="tr group_agree">
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term1"
                  checked={privacyPolicyAgreed}
                  onChange={e =>
                    setPrivacyPolicyAgreed(e.target.checked)
                  }
                />
                <span className="label_text">{intl.get("서비스 이용약관 동의(필수)")}</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(1)}>{intl.get("자세히보기")}</button>
            </div>
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term2"
                  checked={personalInformationCollectionAgreed}
                  onChange={e =>
                    setPersonalInformationCollectionAgreed(
                      e.target.checked,
                    )
                  }
                />
                <span className="label_text">{intl.get("개인정보 이용 동의(필수)")}</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(2)}>{intl.get("자세히보기")}</button>
            </div>
          </div>

        </div>

        <ul className="lst_help bg_gray">
          <li>{intl.get("대리 신청의 경우 사번을 입력하시면 인사 정보 연동하여 기본 정보가 자동 입력 됩니다. 차량번호를 제외한 모든 입력 사항은 필수로 입력하셔야 합니다.")}</li>
          <li style={{color:"blue"}}>{intl.get("증빙서류인 지방세세목별과세증명서는 아래의 [과세증명서 매뉴얼]을 다운받아 확인 후 발급하여 업로드하여 하시기 바랍니다.")}<br/>{intl.get("# 발급처는 구)동사무소만 가능합니다. (인터넷,발급기 발급 불가)")}</li>
        </ul>
      </div>

      {/* 계열사 임직원 */}
      <div className={`tab_contents ${guestType === 2 ? 'on' : ''}`}>
        <div className="table_design_list col_width4">
          <div id="table" className="table">
            <div className="tbody">
              <div className="tr">
                <div className="th">{intl.get("회사명(단체명)")}</div>
                <div className="td">
                  <SelectCompany
                    name=""
                    value={office}
                    guestType={guestType}
                    onChange={(e: any) => setOffice(e.target.value)}
                  />
                </div>
                <div className="th">{intl.get("소속 부서")}</div>
                <div className="td">
                  <DepartmentInput
                    type="text"
                    id="input_sdm"
                    className="form_control"
                    placeholder={intl.get("소속부서를 입력하세요")}
                    value={department}
                    onChange={(e: any) => setDepartment(e.target.value)}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("사번")}</div>
                <div className="td">
                  <EmployeeNumberInput
                    type="text"
                    className="form_control"
                    placeholder={intl.get("사번을 입력하세요")}
                    value={employeeNumber}
                    onChange={(e: any) => setEmployeeNumber(e.target.value)}
                  />
                </div>
                <div className="th">{intl.get("성명")}</div>
                <div className="td">
                  <NameInput
                    type="text"
                    className="form_control"
                    placeholder={intl.get("성명을 입력하세요")}
                    value={name}
                    onChange={(e: any) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("성별")}</div>
                <div className="td">
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="sex_choice1"
                      name="similar-sex2"
                      checked={gender === 1}
                      // onChange={e => setGender(e.target.checked ? 1 : 2)}
                      onClick={e => setGender(1)}
                      disabled={false}
                    />
                    <span className="label_text">{intl.get("남자")}</span>
                  </label>
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="sex_choice2"
                      name="similar-sex2"
                      checked={gender === 2}
                      onClick={e => setGender(2)}
                      disabled={false}
                    />
                    <span className="label_text">{intl.get("여자")}</span>
                  </label>
                </div>

                <div className="th">{intl.get("휴대폰 번호")}</div>
                <div className="td">
                  <PhoneInput
                    type="text"
                    id="input_sphone"
                    className="form_control"
                    placeholder={intl.get("휴대폰 번호를 입력하세요")}
                    value={phone}
                    onChange={(e: any) =>
                      setPhone(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("차량번호")}1</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    value={carNumber}
                    onChange={e => e.target.value.length < 12 && setCarNumber(e.target.value)}
                    placeholder={intl.get("차량번호를 입력하세요")}
                  />
                </div>
                <div className="th">{intl.get("차량번호")}2</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    value={carNumber2}
                    onChange={e => e.target.value.length < 12 && setCarNumber2(e.target.value)}
                    placeholder={intl.get("차량번호를 입력하세요")}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("차량번호")}3</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    value={carNumber3}
                    onChange={e => e.target.value.length < 12 && setCarNumber3(e.target.value)}
                    placeholder={intl.get("차량번호를 입력하세요")}
                  />
                </div>
                <div className="th">{intl.get("입숙희망일")}</div>
                <div className="td">
                  <CustomCalendar
                    min={getTodayDate()[0]}
                    value={checkin_hope}
                    onChange={(date:string) => {setCheckin_hope(date)}}
                  ></CustomCalendar>
                </div>
              </div>
            </div>
          </div>
          <div className="tr group_agree">
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term1"
                  checked={privacyPolicyAgreed}
                  onChange={e =>
                    setPrivacyPolicyAgreed(e.target.checked)
                  }
                />
                <span className="label_text">{intl.get("서비스 이용약관 동의(필수)")}</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(1)}>{intl.get("자세히보기")}</button>
            </div>
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term2"
                  checked={personalInformationCollectionAgreed}
                  onChange={e =>
                    setPersonalInformationCollectionAgreed(
                      e.target.checked,
                    )
                  }
                />
                <span className="label_text">{intl.get("개인정보 이용 동의(필수)")}</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(2)}>{intl.get("자세히보기")}</button>
            </div>
          </div>

          <ul className="lst_help bg_gray">
            <li>{intl.get("차량번호를 제외한 모든 입력 사항은 필수로 입력하셔야 합니다.")}</li>
            <li>{intl.get("입숙 서약서 다운로드 -> 확인후 사인 -> 증빙서류 파일업로드 시 서약서 파일업로드 하여 서류 제출 요청드립니다.")}</li>
          </ul>

        </div>
      </div>

      {/* 외부인 */}
      <div className={`tab_contents ${guestType === 3 ? 'on' : ''}`}>
        <div className="table_design_list col_width4">
          <div id="table" className="table">
            <div className="tbody">
              <div className="tr">
                <div className="th">{intl.get("회사명(단체명)")}</div>
                <div className="td">
                  <input type="text" className="form_control" placeholder={intl.get("회사명 또는 단체명을 입력하세요")} value={office} onChange={(e: any) => setOffice(e.target.value)} />
                </div>
                <div className="th">{intl.get("성명")}</div>
                <div className="td">
                  <NameInput
                    type="text"
                    className="form_control"
                    placeholder={intl.get("성명을 입력하세요")}
                    value={name}
                    onChange={(e: any) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("성별")}</div>
                <div className="td">
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="sex_choice1"
                      name="s3"
                      checked={gender === 1}
                      onChange={e => setGender(e.target.checked ? 1 : 2)}
                    />
                    <span className="label_text">{intl.get("남자")}</span>
                  </label>
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="sex_choice2"
                      name="s3"
                      checked={gender === 2}
                      onChange={e => setGender(e.target.checked ? 2 : 1)}
                    />
                    <span className="label_text">{intl.get("여자")}</span>
                  </label>
                </div>
                <div className="th">{intl.get("휴대폰 번호")}</div>
                <div className="td">
                  <PhoneInput
                    type="text"
                    className="form_control"
                    placeholder={intl.get("휴대폰 번호를 입력하세요")}
                    value={phone}
                    onChange={(e: any) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("차량번호")}1</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    value={carNumber}
                    onChange={e => e.target.value.length < 12 && setCarNumber(e.target.value)}
                    placeholder={intl.get("차량번호를 입력하세요")}
                  />
                </div>
                <div className="th">{intl.get("차량번호")}2</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    value={carNumber2}
                    onChange={e => e.target.value.length < 12 && setCarNumber2(e.target.value)}
                    placeholder={intl.get("차량번호를 입력하세요")}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("차량번호")}3</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    value={carNumber3}
                    onChange={e => e.target.value.length < 12 && setCarNumber3(e.target.value)}
                    placeholder={intl.get("차량번호를 입력하세요")}
                  />
                </div>
                <div className="th">{intl.get("입숙희망일")}</div>
                <div className="td">
                  <CustomCalendar
                    min={getTodayDate()[0]}
                    value={checkin_hope}
                    onChange={(date:string) => {setCheckin_hope(date)}}
                  ></CustomCalendar>
                </div>
              </div>
            </div>
          </div>

          <div className="tr group_agree">
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term1"
                  checked={privacyPolicyAgreed}
                  onChange={e =>
                    setPrivacyPolicyAgreed(e.target.checked)
                  }
                />
                <span className="label_text">{intl.get("서비스 이용약관 동의(필수)")}</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(1)}>{intl.get("자세히보기")}</button>
            </div>
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term2"
                  checked={personalInformationCollectionAgreed}
                  onChange={e =>
                    setPersonalInformationCollectionAgreed(
                      e.target.checked,
                    )
                  }
                />
                <span className="label_text">{intl.get("개인정보 이용 동의(필수)")}</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(2)}>{intl.get("자세히보기")}</button>
            </div>
          </div>

          <ul className="lst_help bg_gray">
            <li>{intl.get("차량번호를 제외한 모든 입력 사항은 필수로 입력하셔야 합니다.")}</li>
            <li>{intl.get("입숙 서약서 다운로드 -> 확인후 사인 -> 증빙서류 파일업로드 시 서약서 파일업로드 하여 서류 제출 요청드립니다.")}</li>
          </ul>
        </div>

      </div>
      {/* 입숙 서약서 */}
      {/* 증빙서류 */}
      {props.files.length > 0 ?(
        <div className="title_contents">
          <h3 className="title">
            {intl.get("증빙서류")}
            <ul className="lst_warning red">
                <li>주민등록번호 뒤 7자리는 반드시 * 처리해주세요.</li>
                {/* {isMobile ? 
                <li className="align_case">입숙 서약서 다운로드 -&gt; 확인 후 서명 -&gt; 서약서 파일업로드 하여 서류 제출 요청드립니다.</li>
                : ''} */}
            </ul>
          </h3>
          {pledgeFile && (userInfo.worksite === 2 || userInfo.worksite === 6)?(
          <div className="right_area mt0 ar">
            <button className="btn_sm btn_darkblue btn_download"
            onClick={() => downloadFile(`${process.env.REACT_APP_MEDIA}/static/사택_이용신청서 메뉴얼.pdf`, '')}
            >
              이용신청서 매뉴얼
              </button>
            <button className="btn_sm btn_darkblue btn_download"
            onClick={() => downloadFile(`${process.env.REACT_APP_MEDIA}/static/사택_신청 전 입주조건 Check List.pdf`, '')}
            >
              입주조건 체크리스트
              </button>
            <button className="btn_sm btn_darkblue btn_download"
            onClick={() => downloadFile(`${process.env.REACT_APP_MEDIA}/static/사택_과세증명서 매뉴얼.pdf`, '')}
            >
              과세증명서 매뉴얼
              </button>                            
            {/*<a className="btn btn_sm btn_outline" 
              href={`${process.env.REACT_APP_MEDIA}static/사택_이용신청서 메뉴얼.pdf`} download
              target='_blank'
            >이용신청서 매뉴얼</a>
            <a className="btn btn_sm btn_outline" 
              href={`${process.env.REACT_APP_MEDIA}static/사택_신청 전 입주조건 Check List.pdf`} download
              target='_blank'
            >입주조건 체크리스트</a>
            <a className="btn btn_sm btn_outline" 
              href={`${process.env.REACT_APP_MEDIA}static/사택_과세증명서 매뉴얼.pdf`} download
              target='_blank'
            >과세증명서 매뉴얼</a>*/}
            <>
            
              {/* <a className={isMobile ? "btn btn_sm btn_outline mt10":"btn btn_sm btn_outline"} 
                href={`${process.env.REACT_APP_MEDIA}/${pledgeFile}`} download
                target={`${pledgeFile}`.split('.').pop() 
                    && ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(`${pledgeFile}`.split('.').pop() || '' )
                    && !isMobile ? '_blank' : ''}
              >입숙 서약서 다운로드</a> */}
              {/* {!isMobile ? 
              <p className="mt10">입숙 서약서 다운로드 -&gt; 확인 후 서명 -&gt; 서약서 파일업로드 하여 서류 제출 요청드립니다.</p> : ''} */}
            </>
          </div>
            ):(
              <></>
            )}
        </div>
      ): ''}

      <UploadFileList
        value={props.file}
        onChange={(files: any) => props.setFiles(files)}
        workSite={props.workSite}
        guestType={guestType}
        roomType={props.roomType}
        category={FILE_UPLOAD_CODE.apply}
        alreadyFiles={[]}
        visibleType={DOCUMENT_FILE_VISIBLE_TYPE_CODE.apply}
      />

      <ul className="lst_help bg_gray">
        <li>사택 신청전 이용안내 탭에서 사택 입숙조건 및 기타 내용 확인 후 신청해주세요.</li>
      </ul>

      <hr />

      <div className="submit">
        <ConfirmBtn
          className="btn btn_lg btn_outline"
          action={() => {store.roomInit(); props.history.replace('/home')}}
          label={intl.get("취소")}
          title=""
          msg={intl.get("이용신청을 취소하시겠습니까?")}
        />

        <button className="btn btn_lg btn_darkblue" onClick={next}>
          {intl.get("다음")}
        </button>
      </div>


      {/*이용약관 자세히보기 팝업*/}
      <TermsDetail
        classNameBySelect="form_control"
        tabNumber={tabNumber}
        setTabNumber={setTabNumber}
        onCloseTermsDetailPopupHandler={onCloseTermsDetailPopupHandler}
      />
    </>
  );
});

export default Step1;
