import React from 'react';
import { useSelector } from 'react-redux';

import Calendar from '../../components/molecules/calendar';
import {
  isAfterAndSame,
  getTodayDate,
  getNextDay,
  getPrevDay,
} from '../../utils/date';

import { GENDER } from '../../constant';

import intl from 'react-intl-universal';
import './step2.scss';

const Step2 = (props: any) => {
  const { userInfo = {}, workplaceMap = {} } = useSelector((state: any) => ({
    userInfo: state.user,
    workplaceMap: state.base.workplaceMap
  }));
  
  const todayDate = getTodayDate();

  const onClickDateAfterByCalendar = (day: string) => {
    // dateAfter <= dateBefore
    const _isAfter = isAfterAndSame(day, props.dateBefore);
    props.setDateAfter(day);

    if (!_isAfter || day === props.dateBefore) {
      //console.log(getNextDay(day)[0])
      props.setDateBefore(getNextDay(day)[0]);
      // alert(`${day}보다 ${props.dateBefore}가 이전이 될 수 없습니다.`);
    }
  };

  const onClickDateBeforeByCalendar = (day: string) => {
    // dateAfter <= dateBefore
    const _isAfter = isAfterAndSame(props.dateAfter, day);
    props.setDateBefore(day);
    if (!_isAfter) {
      props.setDateAfter(day);
      // alert(`${props.dateAfter}보다 ${day}가 이전이 될 수 없습니다.`);
    }
  };

  const onClickTodayReservationDate = (e: any) => {
    props.setDateAfter(todayDate[0]);
    props.setDateBefore(getNextDay(todayDate[0])[0]);
  };

  return (
    <>
      <ul className="lst_thumb_request">
        <li>
          <strong>{intl.get("숙소유형")}</strong>
          <span>{intl.get("출장자숙소(개인)")}</span>
        </li>
        <li>
          <strong>{intl.get("이용사업장")}</strong>
          <span>{workplaceMap[props.workSite]?.name}</span>
        </li>
        <li>
          <strong>{intl.get("입숙예정일")}</strong>
          <span>{props.dateAfter}</span>
        </li>
        <li>
          <strong>{intl.get("퇴숙예정일")}</strong>
          <span>{props.dateBefore}</span>
        </li>
        <li>
          <strong>{intl.get("배방정보")}</strong>
          <span>
            {intl.get("건물/동")}:
            <br />
            {intl.get("방호수")}: 
          </span>
        </li>
      </ul>

      <div className="title_contents">
        <h3 className="title">{intl.get("신청자 정보")}</h3>
      </div>

      <div className="table_design_list col_width6">
        <div id="table" className="table">
          <div className="tbody">
            <div className="tr">
              <div className="th">{intl.get("사번 (아이디)")}</div>
              <div className="td">{userInfo.employeenumber}</div>
              <div className="th">{intl.get("성명")}</div>
              <div className="td">{userInfo.username}</div>
              <div className="th">{intl.get("성별")}</div>
              <div className="td">{GENDER[userInfo.gender]}</div>
            </div>
            <div className="tr">
              <div className="th">{intl.get("소속 부서")}</div>
              <div className="td">{userInfo.department}</div>
              <div className="th">{intl.get("휴대폰 번호")}</div>
              <div className="td">{userInfo.phone}</div>
              <div className="th"></div>
              <div className="td"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="title_contents">
        <h3 className="title">{intl.get("이용자 정보")}</h3>
      </div>
      <div className="table_design_list col_width6">
        <div id="table" className="table">
          <div className="tbody">
            <div className="tr">
              <div className="th">{intl.get("사번 (아이디)")}</div>
              <div className="td">{props.user.employee_number}</div>
              <div className="th">{intl.get("성명")}</div>
              <div className="td">{props.user.name}</div>
              <div className="th">{intl.get("성별")}</div>
              <div className="td">{GENDER[props.user.gender]}</div>
            </div>
            <div className="tr">
              <div className="th">{intl.get("소속 부서")}</div>
              <div className="td">{props.user.department}</div>
              <div className="th">{intl.get("휴대폰 번호")}</div>
              <div className="td">{props.user.cellphone}</div>
              <div className="th"></div>
              <div className="td"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="title_contents title_selectdate">
        <h3 className="title">{intl.get("이용기간 선택")}</h3>
        <div className="right_area">
          {/* <button className="btn btn_md btn_darkblue"><i className="ico_calendar"></i>예약현황</button> */}
          <ul className="lst_help">
            <li>{intl.get("이용 기간을 선택하여 주세요.")}</li>
          </ul>
        </div>
      </div>
      <div className="select_date">
        <div className="date_start">
          <div className="datepicker_area">
            {/* <img src="/static/img/pc/temp_datepicker.png" className="img_datepicker" alt="" /> */}
            <Calendar
              onClickSelectDay={onClickDateAfterByCalendar}
              onRangeSelectDay={(e: string) => { }}
              tasks={false}
              selectDate={props.dateAfter}
              name={"left"}
              isNextMonthButtonView={true}
              beforeDisablePoint={getPrevDay(props.today)[0]}
            />
            <div className="btn_area">
              {/* <button className="btn btn_sm btn_outline">날짜 재선택</button> */}
            </div>
          </div>
        </div>
        <div className="date_end">
          <div className="datepicker_area">
            <Calendar
              onClickSelectDay={onClickDateBeforeByCalendar}
              onRangeSelectDay={(e: string) => { }}
              tasks={false}
              name={"right"}
              isNextMonthButtonView={true}
              selectDate={props.dateBefore}
              beforeDisablePoint={props.dateAfter}
            />
            <div className="btn_area">
              <button
                className="btn btn_sm btn_darkblue btn_outline"
                onClick={onClickTodayReservationDate}
              >
                {intl.get("당일 예약선택")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="submit">
        <button
          className="btn btn_lg btn_outline"
          onClick={props.prevStep}
        >
          {intl.get("이전")}
            </button>
        <button className="btn btn_lg btn_darkblue" onClick={props.nextStep}>
          {intl.get("다음")}
        </button>
      </div>
    </>
  );
};

export default Step2;

