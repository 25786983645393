// 이용안내
import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';

import { MessageContext } from '../../routes';

import ConfirmBtn from '../../components/atoms/button/confirm';
import PasswordInput from '../../components/atoms/input/password';
import DefaultTemplate from '../../components/templetes/default';

import { passwordValid } from '../../utils/validation';

import { ERROR_CODE } from '../../constant/error';
import { VERIFY_CODE } from '../../constant';

import { auth } from '../../apis';

import "./index.scss";
import { SHOW_MODAL } from '../../store/modules/modal';

const UserInfoUpdatePage = (props: any) => {
  const { info } = useContext(MessageContext);

  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user,
  }));

  const [number, setNumber] = useState<string>("");

  const [password, setPassword] = useState<string>("");
  const [oldpassword, setOldPassword] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");

  const [verifyType, setVerifyType] = useState<number>(VERIFY_CODE.phone); // 1: 휴대폰 인증, 2: 이메일 인증

  const [verifyToken, setVerifyToken] = useState<string>("");
  const [verified, setVerified] = useState<boolean>(false);


  const init = () => {
    setNumber("");
    setPassword("");
    setPassword2("");
    setVerifyType(VERIFY_CODE.phone);
    setVerifyToken("");
    setVerified(false);
  }

  // 인증번호 보내기 클릭
  const onVerifySendHandler = async () => {
    try {
      let { data } = await auth.profileAuth({
        type: verifyType
      })
      info("인증번호를 발송했습니다.");
      setVerifyToken(data.token)
    } catch ({ data, status }) {
      //console.log(data, status)
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("인증번호 전송중 문제가 발생했습니다.");
      return;
    }
  }

  const onVerifyHandler = async () => {
    if (!verifyToken) {
      info("인증번호를 발송하지 않았습니다.");
      return
    }
    if (number.length !== 6) {
      info("인증번호가 잘못되었습니다.");
      return
    }
    if (verified) {
      info("이미 인증을 진행했습니다");
      return
    }
    try {
      let { data } = await auth.profileVerify({
        number,
        token: verifyToken
      })
      setVerified(true);
      info("인증 완료했습니다. \n바꿀 비밀번호를 입력하여 확인을 누르면 비밀번호 변경을 완료합니다.");
    } catch ({ data, status }) {
      if (status === 400) {
        if (data.code) { info(ERROR_CODE[data.code]); return }
        else { info("인증 중 문제가 발생했습니다"); return }
      }else if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  }

  const onProfileUpdateHandler = async () => {
    if (!verified) {
      info("인증을 완료하지 않았습니다.");
      return
    }
    if (passwordValid(password, password2)) {
      info("비밀번호는 8~20자로 영문, 특수문자, 숫자를 조합하여 입력해주세요. \n영문 대문자와 소문자를 구별해주세요");
      return
    }
    try {
      let { data } = await auth.profileUpdate({
        old_password: oldpassword,
        new_password: password,
        new_password2 : password2
      })

      init();
      info("회원정보 변경을 완료했습니다.", '/mypage/user/info');
    } catch ({ data, status }) {
      // console.log(data)
      // console.log(status)
      if (status === 400) {
        if (data.code) { info(ERROR_CODE[data.code]); return; }
        else if (data?.code?.password) {
          info("강력한 비밀번호로 바꿔주세요.");
          return
        }
      }else  if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("비밀번호가 너무 일상적인 단어입니다.");
      return;
    }
  }

  return (
    <DefaultTemplate
      {...props}
      id="wrap"
      className="wrap_mypage"
      leftInfo={
        <>
          <h2>정보관리</h2>
          <p className="desc">회원가입 정보를 확인하고 수정하실 수 있습니다</p>
          <div className="bg_img info"></div>
        </>
      }
    >
      <div className="title_contents">
        <h3 className="title">정보 변경</h3>
      </div>
      <div className="form_write form_lg clearfix">
        <div className="row">
          <div className="col">
            <label htmlFor="input_phonenumber2" className="title_form">사번</label>
            <input type="text" id="input_phonenumber2" className="form_control" placeholder="인증번호를 입력해주세요" value={userInfo.employeenumber} disabled />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <label htmlFor="input_pw" className="title_form">비밀번호<span className="emp_red"></span></label>
            <PasswordInput
              id="input_pw"
              className="form_control"
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
              placeholder="비밀번호를 입력해주세요"
            />
          </div>
          <div className="col">
            <label htmlFor="input_pw2" className="title_form">비밀번호 확인<span className="emp_red"></span></label>
            <PasswordInput
              id="input_pw2"
              className="form_control"
              value={password2}
              onChange={(e: any) => setPassword2(e.target.value)}
              placeholder="비밀번호를 다시 한번 입력해주세요"
            />
          </div>
        </div>

        <ul className="lst_warning">
          <li>비밀번호는 8~20자로 영문 , 특수문자, 숫자를 조합하여 입력해주세요. 영문 대문자와 소문자를 구별해주세요</li>
        </ul>

        <div className="row">
          <div className="col">
            <label htmlFor="input_phonenumber" className="title_form">휴대폰 번호</label>
            <div className="">
              <input type="text" id="input_phonenumber" className="form_control" placeholder="숫자만 입력해주세요" value={userInfo.phone} disabled />
              {/*<button className="btn btn_lg btn_gray">인증하기</button>*/}
            </div>
          </div>

          <div className="col">
            <label htmlFor="input_email" className="title_form">이메일 주소</label>
            <p className="form_email">
              <input type="text" id="input_email" className="form_control" placeholder="이메일 아이디를 입력해주세요" value={userInfo.email && userInfo.email.split('@').length && userInfo.email.split('@')[0]} disabled />
              {/*<span className="dash"></span>*/}
              <p className="dash">@</p>
              <input type="text" className="form_control" value={userInfo.email && userInfo.email.split('@').length && userInfo.email.split('@')[1]} disabled />
            </p>
          </div>
        </div>

        <ul className="lst_warning">
          <li>본인의 휴대폰 번호가 아닌 타인의 휴대폰 번호를 입력할 경우 인사상 불이익을 당할 수 있습니다</li>
        </ul>
      </div>

      <div className="title_contents">
        <h3 className="title">인증하기</h3>
      </div>

      <div className="radio_terms">
        <dl>
          <dt>
            <label className="input_radio" onClick={() => setVerifyType(VERIFY_CODE.phone)}>
              <input type="radio" id="" name="" checked={verifyType === VERIFY_CODE.phone} />
              <span className="label_text">휴대폰 번호 인증</span>
            </label>
            <label className="input_radio" onClick={() => setVerifyType(VERIFY_CODE.email)}>
              <input type="radio" id="" name="" checked={verifyType === VERIFY_CODE.email} />
              <span className="label_text">이메일 주소 인증</span>
            </label>
          </dt>
          <dd>
            <div className="col">
              <button className="btn btn_darkblue btn_outline" onClick={onVerifySendHandler}>인증번호 보내기</button>
              <input type="text" className="form_control" placeholder="인증번호를 입력해주세요" value={number} onChange={(e: any) => setNumber(e.target.value)} />
              <button className="btn btn_gray" onClick={onVerifyHandler}>확인</button>
            </div>
          </dd>
        </dl>
      </div>

      <div className="submit">
        <ConfirmBtn
          className="btn btn_lg btn_outline"
          action={() => props.history.goBack()}
          label="취소"
          title=""
          msg="이전 페이지도 돌아갑니다. 취소를 진행하시겠습니까?"
        />
        <ConfirmBtn
          className="btn btn_lg btn_darkblue"
          action={onProfileUpdateHandler}
          label="완료"
          title=""
          msg="입력한 내용으로 정보를 변경하시겠습니까?"
        />
        {/* <div className="right_area">
                <button className="btn btn_lg btn_gray">회원탈퇴</button>
              </div> */}
      </div>
    </DefaultTemplate>
  )
};

export default UserInfoUpdatePage;
