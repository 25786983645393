import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;

class Voc {
  static URL = `${IP}`;

  @apiHandler()
  static async getVocs(payload: any) {
    const PATH = '/member/board/voc/';
    return await axios.get(`${Voc.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async getVocDetail(id: string) {
    const PATH = `/member/board/voc/`;
    return await axios.get(`${Voc.URL}${PATH}${id}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }


  // 공지사항 생성
  @apiHandler()
  static async createVoc(payload: any) {
    const PATH = `/member/board/voc/`;

    return await axios.post(`${Voc.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 공지사항 생성
  @apiHandler()
  static async updateVoc(id: string, payload: any) {
    const PATH = `/member/board/voc/`;

    return await axios.patch(`${Voc.URL}${PATH}${id}/`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 공지사항 삭제
  @apiHandler()
  static async deleteVoc(id: string) {
    const PATH = `/member/board/voc/`;

    return await axios.delete(`${Voc.URL}${PATH}${id}/`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
  
  // comment 생성
  @apiHandler()
  static async addComment(payload: any) {
    const PATH = `/member/board/voc/comment/`;

    return await axios.post(`${Voc.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
}


export default Voc;
