import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { onOff } from '../../apis';

import { onShowModal, SHOW_MODAL } from '../../store/modules/modal';

import { Props, WorkSite } from './interface';

const UseWorkSite = (props: Props): [any[], Function, Function] => {
  const [roomType, setRoomType] = useState(props.roomType)
  const [userWorkSite, setUserWorkSite] = useState(props.userWorkSite)

  const [workSites, setWorkSites] = useState<WorkSite[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        let { data } = await onOff.getWorksite({
          // work_site: props.value,
          user_work_site: userWorkSite,
          room_type: roomType
        })
        //console.log(data.length)
        if (!data.length) {
          dispatch(onShowModal({
            msg: '선택 가능한 사업장이 없습니다.',
            redirectPath: '/home'
          }))
          return
        }
        
        setWorkSites(data)
      } catch (err) {
        const { status, data } = err;

        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
  
      } 
    })()
  }, [roomType, userWorkSite])
  
  return [
    workSites, setUserWorkSite, setRoomType
  ]
}

export default UseWorkSite
