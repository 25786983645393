import React, { useState, useEffect, useContext } from 'react';
import { MessageContext } from '../../../routes';

import { auth } from '../../../apis';

import { VERIFY_CODE } from '../../../constant';
import { ERROR_CODE } from '../../../constant/error';

import PhoneInput from '../../atoms/input/phone';
import NameInput from '../../atoms/input/name';
import PasswordInput from '../../atoms/input/password';
import EmployeeInput from '../../atoms/input/employeeNumber';

import {
  SignupForm,
  Valid,
  MustAgreed,
  VERIFY_STATUS_CODE,
} from '../../../pages/signup/type.d';

import './index.scss';
import { SHOW_MODAL } from '../../../store/modules/modal';
import { reduceEachTrailingCommentRange } from 'typescript';

interface Props {
  prevVerifySendCheckValid(
    signupForm: SignupForm,
    isGetPersonnel: boolean,
  ): Valid;
  prevVerifyCheckValid(verityStatus: number, verifyNumber: string): Valid;
  prevSignupCheckValid(
    signupform: SignupForm,
    mustAgreed: MustAgreed,
    verityStatus: number,
  ): Valid;
  history: any;
}

const Signup = (props: Props) => {
  const { info } = useContext(MessageContext);
  const [employeeNumber, setEmployeeNumber] = useState<string>('');

  const [phone, setPhone] = useState<string>('');
  const [emailFront, setEmailFront] = useState<string>('');
  const [emailBack, setEmailBack] = useState<string>('autos.hmgc.net');

  const [password, setPassword] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');

  const [verifyType, setVerifyType] = useState<number>(VERIFY_CODE.phone); // 1: 휴대폰 인증, 2: 이메일 인증

  const [allAgreed, setAllAgreed] = useState<boolean>(false); // 전체동의
  const [termAgreed, setTermAgreed] = useState<boolean>(false); // 이용약관 동의 (필수)
  const [privacyAgreed, setPrivacyAgreed] = useState<boolean>(false); // 개인정보 수집 및 이용에 대한 동의 (필수)
  const [privacyPolicyAgreed, setPrivacyPolicyAgreed] = useState<boolean>(
    false,
  ); // 개인정보 취급, 처리방침에 대한 동의 (선택)
  // const [privacyUseAgreed, setPrivacyUseAgreed] = useState<boolean>(
  //   false,
  // ); // 개인정보 처리 위탁동의 (필수)

  const [isGetPersonnel, setIsGetPersonnel] = useState<boolean>(false);
  const [verityStatus, setVerifyStatus] = useState<number>(
    VERIFY_STATUS_CODE.default,
  ); // 1: 인증요청, 2: 인증완료

  const [verifyNumber, setVerifyNumber] = useState<string>(''); // 인증번호
  const [verifyToken, setVerifyToken] = useState<string>(''); // 인증문자(메일) 발송후 응답받은 토큰

  const [collapseIdx, setCollapse] = useState<number>(-1);

  const onAllAgreedChangeHandler = (e: any) => {
    setAllAgreed(e.target.checked);
    setTermAgreed(e.target.checked);
    setPrivacyAgreed(e.target.checked);
    setPrivacyPolicyAgreed(e.target.checked);
    // setPrivacyUseAgreed(e.target.checked)
  };

  useEffect(() => {
    setAllAgreed(isGreedAllCheck());
  }, [termAgreed, privacyAgreed, privacyPolicyAgreed]);

  // 적용버튼 클릭 - 사원번호 조회
  const onGetPersonnelHandler = async (e: any) => {
    if (!employeeNumber) {
      info('사번이 비었습니다.');
      return;
    }

    try {
      let { data } = await auth.signupCheckUser({
        employee_number: employeeNumber,
      });
      setIsGetPersonnel(true);
      setEmailFront(employeeNumber);
      setPhone(data.cellphone);
      info('사원번호 조회를 정상적으로 완료했습니다.');
    } catch ({ data, status }) {
      if (status === 404) {
        info(
          `사번이 올바르지 않거나 존재하지않습니다.\n(입력사번: ${employeeNumber})`,
        );
        return;
      } else if (status === 400) {
        if (data.code === 'exist_user') {
          info('이미 등록된 사용자입니다.');
          return;
        } else {
          info('사번이 올바르지 않습니다.');
          return;
        }
      } else if (status === 500) {
        info('인사정보를 불러오는 중 문제가 발생했습니다.');
        return;
      } else {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return;
      }
    }
  };

  // 인증번호 보내기 버튼 클릭
  const onVerifySendHandler = async (e: any) => {
    const { isValid, msg }: Valid = props.prevVerifySendCheckValid(
      {
        employeeNumber,
        password,
        password2,
        phone,
        emailFront,
        emailBack,
        privacyPolicyAgreed,
        verifyType,
      },
      isGetPersonnel,
    );
    if (isValid) return;

    try {
      let payload: any = {
        type: verifyType,
        employee_number: employeeNumber,
      };

      if (verifyType === VERIFY_CODE.email) {
        payload.email = `${emailFront}@${emailBack}`;
      } else if (verifyType === VERIFY_CODE.phone) {
        payload.cellphone = phone;
      } else {
        info('인증방식이 올바르지 않습니다.');
        return;
      }

      let { data } = await auth.signupAuth(payload);

      info('인증번호 발송');
      setVerifyToken(data.token);
      setVerifyStatus(VERIFY_STATUS_CODE.request);
    } catch ({ status, data }) {
      if (status === 400) {
        info(data.code);
        return;
      }

      info('인증번호 발송실패');
      return;
    }
  };

  // 인증확인 버튼 클릭
  const onVerifyHandler = async (e: any) => {
    const { msg, isValid }: Valid = props.prevVerifyCheckValid(
      verityStatus,
      verifyNumber,
    );

    if (isValid) return;

    try {
      let payload = {
        number: verifyNumber,
        token: verifyToken,
        employee_number: employeeNumber,
      };
      let { data } = await auth.signupVerify(payload);

      setVerifyStatus(VERIFY_STATUS_CODE.complete);
      info('인증완료');
    } catch ({ status, data }) {
      if (status === 400) {
        info(ERROR_CODE[data.code]);
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return;
    }
  };

  // 회원가입 버튼 클릭
  const onSubmit = async (e: any) => {
    const { msg, isValid }: Valid = props.prevSignupCheckValid(
      {
        employeeNumber,
        password,
        password2,
        phone,
        emailFront,
        emailBack,
        privacyPolicyAgreed,
        verifyType: VERIFY_CODE.phone,
      },
      { termAgreed, privacyAgreed },
      verityStatus,
    );

    if (isValid) return;

    try {
      const { data } = await auth.signup({
        employee_number: employeeNumber,
        password,
        password2,
        cellphone: phone,
        email: `${emailFront}@${emailBack}`,
        is_optional_collection_agreed: privacyPolicyAgreed,
      });

      props.history.push('/auth/signup/complete', {
        employeeNumber: data.employee_number,
        workplace: data.work_site,
        name: data.name,
      });
    } catch ({ status, data }) {
      // console.log(status);
      // console.log(data);

      if (status === 400) {
        // 파라미터가 잘못됐을 때
        if (ERROR_CODE[data.code]) {
          info(ERROR_CODE[data.code]);
          return;
        } else {
          if (data?.password?.length) {
            info(ERROR_CODE['PASSWORD_COMMON']);
            return;
          }
        }
        return;
      }
      info('회원가입 중 문제가 발생했습니다.');
      return;
    }
  };

  const isGreedAllCheck = () =>
    termAgreed && privacyAgreed && privacyPolicyAgreed;

  return (
    <div id="wrap" className="wrap_join">
      <header>
        <h1>
          <a
            href="javascript:void(0)"
            className="logo ir"
            onClick={() => props.history.push('/')}
          >
            AMS Platform
          </a>
        </h1>
        <div className="bar"></div>
      </header>

      <div id="container">
        <div className="row clearfix">
          <div className="col col_left group_title">
            <h2>회원가입</h2>
            <p className="desc">
              빠른 숙소 신청 서비스로 간편하게 숙소를 이용해보세요.
            </p>
            <div className="bg_img"></div>
          </div>

          <div id="contents" className="col col_right">
            <div className="title_contents">
              <h3 className="title">기본정보 입력</h3>
              <ul className="lst_help">
                <li>모든 추가정보 입력사항 필수 입력 입니다</li>
              </ul>
            </div>

            <div className="form_write clearfix">
              <div className="row">
                <div className="col">
                  <label htmlFor="input_phonenumber2" className="title_form">
                    사번
                  </label>
                  <p className="form_phone">
                    <EmployeeInput
                      type="text"
                      id="input_phonenumber2"
                      className="form_control"
                      placeholder="사번을 입력해주세요"
                      value={employeeNumber}
                      onChange={(e: any) => setEmployeeNumber(e.target.value)}
                      disabled={isGetPersonnel}
                    />
                    <button
                      className="btn btn_gray"
                      onClick={onGetPersonnelHandler}
                    >
                      적용
                    </button>
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <label htmlFor="input_pw" className="title_form">
                    비밀번호
                  </label>
                  <PasswordInput
                    id="input_pw"
                    className="form_control"
                    placeholder="비밀번호를 입력해주세요"
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value)}
                  />
                </div>
                <div className="col">
                  <label htmlFor="input_pw2" className="title_form">
                    비밀번호 확인
                  </label>
                  <PasswordInput
                    id="input_pw2"
                    className="form_control"
                    placeholder="비밀번호를 다시 한번 입력해주세요"
                    value={password2}
                    onChange={(e: any) => setPassword2(e.target.value)}
                  />
                </div>
              </div>

              <ul className="lst_warning">
                <li>
                  비밀번호는 8~20자로 영문 , 특수문자(!, @, #, $, %, ^, &, *),
                  숫자 중 세가지를 조합하여 입력해주세요. 영문 대문자와 소문자를
                  구별해주세요
                </li>
              </ul>

              <div className="row">
                <div className="col">
                  <label htmlFor="input_phonenumber" className="title_form">
                    휴대폰 번호
                  </label>
                  <div className="">
                    <PhoneInput
                      type="text"
                      id="input_phonenumber"
                      className="form_control"
                      placeholder="사번 입력 시 자동으로 입력됩니다."
                      value={phone}
                      disabled={true}
                      onChange={(e: any) => setPhone(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col">
                  <label htmlFor="input_email" className="title_form">
                    이메일 주소
                  </label>
                  <p className="form_email">
                    {/* 찐 */}
                    {/* <input type="text" id="input_email" className="form_control" placeholder="아이디만 입력해주세요" value={emailFront} disabled={true} />
                    <p className="dash">@</p>
                    <input type="text" className="form_control" value={emailBack} disabled={true} /> */}

                    {/* 임시 */}
                    <input
                      type="text"
                      className="form_control"
                      placeholder="사번 입력 시 자동으로 입력됩니다."
                      value={emailFront}
                      disabled={true}
                      onChange={(e: any) => setEmailFront(e.target.value)}
                    />
                    <p className="dash">@</p>
                    <input
                      type="text"
                      className="form_control"
                      value={emailBack}
                      disabled={true}
                      onChange={(e: any) => setEmailBack(e.target.value)}
                    />
                  </p>
                </div>
              </div>

              <ul className="lst_warning">
                <li>
                  핸드폰 번호 또는 이메일이 다를 경우, HMC 인사 DB에서 해당
                  정보를 변경하시면 해당 플랫폼에서도 자동으로 변경됩니다.
                </li>
                <li>
                  그룹사 공통계정으로 사번만 유효하면 정상 메일 수신 됩니다
                </li>
              </ul>
            </div>

            <div className="title_contents">
              <h3 className="title">인증하기</h3>
            </div>

            <div className="radio_terms">
              <dl>
                <dt>
                  <label className="input_radio">
                    <input
                      type="radio"
                      name="verify-type"
                      checked={verifyType === VERIFY_CODE.phone}
                      onClick={(e: any) => setVerifyType(VERIFY_CODE.phone)}
                    />
                    <span className="label_text">휴대폰 번호 인증</span>
                  </label>
                  <label className="input_radio">
                    <input
                      type="radio"
                      name="verify-type"
                      checked={verifyType === VERIFY_CODE.email}
                      onClick={(e: any) => setVerifyType(VERIFY_CODE.email)}
                    />
                    <span className="label_text">이메일 주소 인증</span>
                  </label>
                </dt>
                <dd>
                  <div className="col">
                    <button
                      className="btn btn_darkblue btn_outline"
                      onClick={onVerifySendHandler}
                    >
                      인증번호 보내기
                    </button>
                    <input
                      type="text"
                      className="form_control"
                      placeholder="인증번호를 입력해주세요"
                      value={verifyNumber}
                      onChange={(e: any) => setVerifyNumber(e.target.value)}
                      disabled={verityStatus !== VERIFY_STATUS_CODE.request}
                    />
                    <button className="btn btn_gray" onClick={onVerifyHandler}>
                      확인
                    </button>
                  </div>
                </dd>
              </dl>
            </div>

            <div className="title_contents">
              <h3 className="title">이용약관 동의</h3>
              <ul className="lst_help">
                <li>모든 내용 필수 동의를 하셔야 회원가입이 가능합니다</li>
              </ul>
            </div>

            {/* 약관동의 */}
            <dl className="lst_terms">
              <dt className="terms_allagree">
                <label className="input_check check_lg">
                  <input
                    type="checkbox"
                    checked={allAgreed}
                    onChange={onAllAgreedChangeHandler}
                  />
                  <span className="label_text">전체 동의하기</span>
                </label>
              </dt>

              <dt
                onClick={e => {
                  collapseIdx !== 0 ? setCollapse(0) : setCollapse(-1);
                }}
              >
                <label className="input_check">
                  <input
                    type="checkbox"
                    checked={termAgreed}
                    onChange={e => {
                      setTermAgreed(e.target.checked);
                    }}
                  />
                  <span className="label_text">이용약관 동의 (필수)</span>
                </label>
                <a href="javascript:void(0)" className="btn_toggle ir">
                  펼치기/접기
                </a>
              </dt>
              <dd className={collapseIdx === 0 ? 'on' : ''}>
                <div className="terms_service terms_wrap">
                  <dl>
                    <dt>제 1조 명칭</dt>
                    <dd>
                      본 시스템의 명칭은 "통합숙소관리시스템"(이하'시스템')이라
                      칭한다.{' '}
                    </dd>

                    <dt>제 2조 소재지</dt>
                    <dd>
                      본 시스템은 경기도 파주시 현대차그룹 데이터센터 내에
                      위치한다.
                    </dd>

                    <dt>제 3조 목적</dt>
                    <dd>
                      본 약관은 본 시스템의 운영에 관한 제반사항을 정함으로써
                      회원 상호간의 이해 증진을 도모함은 물론 회원들이 본
                      시스템을 가장 편리하고 효율적으로 이용할 수 있도록 함을 그
                      목적으로 한다.
                    </dd>

                    <dt>제 4조 운영</dt>
                    <dd>
                      <ul>
                        <li>
                          1. 본 시스템의 운영시간은 연중무휴 1일 24시간을
                          원칙으로 한다. 다만, 업무상 또는 기술상의 이 유로
                          서비스가 일시 중지될 수 있고, 또한 정기점검 등
                          운영상의 목적으로 서비스가 일시 중지될 수 있다. 이러한
                          경우 사전 또는 사후에 이를 공지한다.
                        </li>
                        <li>
                          2. 본 시스템의 정기휴일은 없으며, 본 시스템의 부득이한
                          사정이 있을 시에는 게시판 공고 후 휴일 로 한다.
                        </li>
                        <li>
                          3. 기타 운영에 관한 제반 사항은 시스템 내 게시판에
                          공고한다.
                        </li>
                      </ul>
                    </dd>

                    <dt>제 5조 회원의 등록 및 대상</dt>
                    <dd>
                      <ul>
                        <li>
                          1. 본 시스템의 회원대상은 본 사이트에 접속 승인을 받은
                          자로 한다.
                        </li>
                        <li>
                          2. 본 시스템의 정회원이 되고자 하는 자는 본 시스템에서
                          정한 소정의 가입 신청 절차를 거쳐야 하며 필수정보사번,
                          성명, 생년월일, 성별, 근무지명, 소속부서, 직책,
                          휴대폰번호, 이메일) 를 입력한 후 회원가입을 완료하여야
                          이용이 가능하다. 이때 관리자의 승인이 필요한 경우에
                          한하여 관리자의 승인이 완료되어야 이용이 가능하다.
                        </li>
                        <li>
                          3. 협력사 임직원의 경우, 본 시스템에서 정한 소정의
                          가입 신청 절차를 거쳐야 하며 필수정보(성명, 성별,
                          휴대폰번호, 소속회사, 근무지, 담당업무)를 입력한 후
                          회원가입을 완료하여야 이용이 가능하다. 이때 관리자의
                          승인이 필요한 경우에 한하여 관리자의 승인이 완료되어야
                          이용이 가능하다.
                        </li>
                      </ul>
                    </dd>

                    <dt>제 6조 숙소 사용료 및 이용기간</dt>
                    <dd>
                      <ul>
                        <li>
                          1. 숙소 사용료는 각 사 사업장의 기준에 따라 개별적으로
                          적용된다.
                        </li>
                        <li>
                          2. 이용기간은 본인이 이용신청시에 직접 정하거나 또는
                          각 사 사업장 기준에 따라 정해진다.
                        </li>
                      </ul>
                    </dd>

                    <dt>제 7조 숙소이용신청</dt>
                    <dd>
                      본 시스템의 숙소이용신청 방법은 다음과 같다.
                      <ul>
                        <li>1. 홈페이지 회원가입 신청을 통해 가입</li>
                        <li>2. 필수정보 입력</li>
                        <li>3. 숙소이용신청 (정상적인 가입시)</li>
                      </ul>
                    </dd>

                    <dt>제 8조 숙소 사용료 연기 및 환불</dt>
                    <dd>
                      <ul>
                        <li>
                          1. 각 사 사업장 기준에 따라 사용료에 대한 연기 및
                          환불을 처리한다.
                        </li>
                        <li>
                          2. 최초 회원가입 후 회사 회원탈퇴시 까지는 회원자격이
                          주어진다.
                        </li>
                        <li>
                          3. 숙소 이용 시 사용일수를 기준으로 하여 급여공제 또는
                          비용납부처리를 하는 것으로 한다.
                        </li>
                        <li>
                          4. 숙소 비품 관리규정 - 본인의 과실에 의한 숙소 Key,
                          비품 및 공공기물을 분실 또는 파손한 경우에는 그에
                          해당하는 모든 비용은 본인이 부담한다.
                        </li>
                      </ul>
                    </dd>

                    <dt>제 9조 회원 자격의 상실</dt>
                    <dd>
                      다음의 경우에는 회원자격이 상실된다.
                      <ul>
                        <li>
                          1. 전염성 질병에 감염된 회원 및 감염이 우려되는 회원
                        </li>
                        <li>2. 회원정보를 위/변조하여 가입한 회원</li>
                        <li>
                          3. 본 시스템의 약관이 정한 규정을 위반하였을 경우
                        </li>
                        <li>
                          4. 각 사 사업장별 숙소관리규정에 따른 회원자격 상실의
                          경우
                        </li>
                      </ul>
                    </dd>

                    <dt>제 10조 회원의 의무</dt>
                    <dd>
                      <ul>
                        <li>1. 회원은 본 약관을 성실하게 준수하여야 한다.</li>
                        <li>
                          2. 회원은 본 시스템의 모든 시설을 공동재산으로 여겨
                          이용 시 상호간에 양보하여야 한다.
                        </li>
                        <li>
                          3.회원은 부서 또는 연락처 등 가입시 정보에 변동이 있을
                          경우에는 그 변동 내용을 즉시 수정하여야 한다.
                        </li>
                      </ul>
                    </dd>

                    <dt>제 11조 안전사고 및 보상책임 사항</dt>
                    <dd>
                      <ul>
                        <li>
                          1. 회원이 본 시스템의 제반시설을 이용함에 있어서
                          천재지변이나 불가항력적인 재난 또는 본인의 귀책사유로
                          발생 한 사고나 부상 등에 대해서는 현대엔지니어링㈜가
                          책임지지 않는다.
                        </li>
                        <li>
                          2. 회원이 고의, 과실 등 귀책 사유로 시설물을 파괴,
                          분실 또는 훼손된 경우 이를 본인이 보상하여야 한다.
                        </li>
                        <li>
                          3. 회원 부주의로 인한 개인물품 분실에 대해서는
                          현대엔지니어링㈜가 책임지지 않는다.
                        </li>
                      </ul>
                    </dd>

                    <dt>제 12조 개정</dt>
                    <dd>
                      본 시스템의 운영상 부득이 필요하다고 인정할 때에는 본
                      약관을 개정 또는 변경할 수 있고 그 효력은 회원 전원에게
                      적용된다.
                    </dd>

                    <dt>제 13조 관습</dt>
                    <dd>
                      본 약관에 명시되지 않은 사항에 관하여는 일반적인 관례에
                      따른다.
                    </dd>

                    <dt>제 14조 효력발생 </dt>
                    <dd>본 약관은 2021. 07. 21부터 적용된다.</dd>
                  </dl>
                </div>
              </dd>

              <dt
                onClick={e =>
                  collapseIdx !== 1 ? setCollapse(1) : setCollapse(-1)
                }
              >
                <label className="input_check">
                  <input
                    type="checkbox"
                    checked={privacyAgreed}
                    onChange={e => {
                      setPrivacyAgreed(e.target.checked);
                    }}
                  />
                  <span className="label_text">
                    개인정보 수집 및 이용에 대한 동의 (필수)
                  </span>
                </label>
                <a href="javascript:void(0)" className="btn_toggle ir">
                  펼치기/접기
                </a>
              </dt>
              <dd className={collapseIdx === 1 ? 'on' : ''}>
                <div className="group_desc">
                  <div className="table_design_list col_width3">
                    <div id="table" className="table">
                      <div className="thead">
                        <div className="tr">
                          <div className="th">수집하는 개인정보 항목</div>
                          <div className="th">수집 및 이용 목적</div>
                          <div className="th">보유 및 이용기간</div>
                        </div>
                      </div>
                      <div className="tbody">
                        <div className="tr">
                          <div className="td">
                            사번, 성명, 생년월일, 성별, 근무지명, 소속부서,
                            직책, 휴대폰번호, 이메일, 이용신청, 사용로그
                          </div>
                          <div className="td">이용자 식별 및 본인여부 확인</div>
                          <div className="td" style={{textDecorationLine: 'underline', flexFlow:'bold'}}>회원 탈퇴 시 삭제</div>
                        </div>
                        <div className="tr">
                          <div className="td">
                            사번, 성명, 생년월일, 성별, 근무지명, 소속부서,
                            직책, 휴대폰번호, 이메일
                          </div>
                          <div className="td">
                            출장자숙소 이용신청 등 숙소업무처리
                          </div>
                          <div className="td" style={{textDecorationLine: 'underline', flexFlow:'bold'}}>
                            퇴숙완료처리 시점으로부터 1년 후 삭제 <br />
                            ※근무지명, 소속부서,직책 제외
                          </div>
                        </div>
                        <div className="tr">
                          <div className="td">사번, 성명, 휴대폰번호</div>
                          <div className="td">
                            민원 신청인 식별 및 정확한 민원업무처리
                          </div>
                          <div className="td" style={{textDecorationLine: 'underline', flexFlow:'bold'}}>
                            민원처리완료 시점으로부터 30일 후 삭제
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <ul className="lst_warning mt20">
                  <li>
                    서비스 이용 과정에서 IP 주소, 쿠키, 서비스 이용 신청 및
                    기록이 자동으로 수집될 수 있으며,
                    <br />
                    위의 개인정보 수집.이용에 대한 동의를 거부할 권리가
                    있습니다.
                    <br />
                    그러나 시스템을 사용하기 위해서 필요한 최소한의
                    개인정보이므로 동의를 해주셔야 서비스를 이용하실 수
                    있습니다.
                  </li>
                </ul>
              </dd>

              <dt
                onClick={e =>
                  collapseIdx !== 2 ? setCollapse(2) : setCollapse(-1)
                }
              >
                <label className="input_check">
                  <input
                    type="checkbox"
                    checked={privacyPolicyAgreed}
                    onChange={e => {
                      setPrivacyPolicyAgreed(e.target.checked);
                    }}
                  />
                  <span className="label_text">
                    선택적 개인정보 수집 및 이용 내역(선택사항)
                  </span>
                </label>
                <a href="javascript:void(0)" className="btn_toggle ir">
                  펼치기/접기
                </a>
              </dt>
              <dd className={collapseIdx === 2 ? 'on' : ''}>
                <div className="group_desc">
                  <div className="table_design_list col_width3">
                    <div id="table" className="table">
                      <div className="thead">
                        <div className="tr">
                          <div className="th">수집하는 개인정보 항목</div>
                          <div className="th">수집 및 이용 목적</div>
                          <div className="th">보유 및 이용기간</div>
                        </div>
                      </div>
                      <div className="tbody">
                        <div className="tr">
                          <div className="td">
                            주소, 결혼여부, 직종, 근무조,
                            주민등록등본(주민등록번호 뒷자리 미표시),
                            재산세과세증명
                          </div>
                          <div className="td">
                            이용자 식별 및 본인여부 확인
                            <br />
                            장기숙소(기숙사,사택 등) 이용 등 숙소업무처리
                          </div>
                          <div className="td">
                            퇴숙완료처리 시점으로부터 30일 후 삭제
                            <br />
                            ※근무조 제외
                          </div>
                        </div>
                        <div className="tr">
                          <div className="td">사번, 성명</div>
                          <div className="td">
                            장기숙소(기숙사,사택 등) 이용이력관리 및 이용내역
                            확인 등 분쟁조정을 위한 기록
                          </div>
                          <div className="td">
                            퇴숙완료처리 시점으로부터 5년 후 삭제
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <ul className="lst_warning mt20">
                  <li>
                    위의 개인정보 수집·이용에 대한 동의를 거부하셔도 회원가입은
                    가능합니다.
                    <br />
                    단, 동의를 거부하실 경우 장기숙소(기숙사,사택 등)를 이용하실
                    수 없습니다.
                  </li>
                </ul>
              </dd>

              {/* <dt onClick={(e) => collapseIdx !== 3 ? setCollapse(3) : setCollapse(-1)}>
                <label className="input_check">
                  <input
                    type="checkbox"
                    checked={privacyUseAgreed}
                    onChange={(e: any) => { setPrivacyUseAgreed(e.target.checked) }}
                  />
                  <span className="label_text">개인정보 처리 위탁동의 (필수)</span>
                </label>
                <a href="javascript:void(0)" className="btn_toggle ir">펼치기/접기</a>
              </dt> */}
              <dd className={collapseIdx === 3 ? 'on' : ''}>
                <div className="group_desc">
                  <div className="group_desc">
                    <div className="table_design_list col_width3">
                      <div id="table" className="table">
                        <div className="thead">
                          <div className="tr">
                            <div className="th">위탁업무</div>
                            <div className="th">수탁업체</div>
                            <div className="th">위탁의 범위</div>
                          </div>
                        </div>
                        <div className="tbody">
                          <div className="tr">
                            <div className="td">통합숙소관리시스템 관리</div>
                            <div className="td">현대오토에버㈜</div>
                            <div className="td">
                              통합숙소관리시스템 서버 및 운영관리
                            </div>
                          </div>
                          <div className="tr">
                            <div className="td">숙소업무 및 VOC관리</div>
                            <div className="td">
                              ㈜윈피앤에스
                              <br />
                              에프엠파트너㈜
                              <br />
                              ㈜아이비에스인더스트리
                              <br />
                              미성엠프로주식회사
                              <br />
                              ㈜지앤비시스템
                            </div>
                            <div className="td">
                              숙소이용관련 신청내용 확인 및 처리 업무
                              <br />
                              민원신청에 대한 조치 및 처리 업무
                              <br />
                              숙소이용현황 확인 업무
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </dd>
            </dl>

            <ul className="lst_warning mt20">
              <li>
                회사는 만 14세 미만의 아동(이하 '아동')의 회원정보는 원칙적으로
                수집하지 않습니다.
              </li>
            </ul>

            <div className="submit">
              <button
                className="btn btn_lg btn_outline"
                onClick={() => props.history.goBack()}
              >
                취소
              </button>
              <button className="btn btn_lg btn_darkblue" onClick={onSubmit}>
                완료
              </button>
            </div>
          </div>
          {/*#contents*/}
        </div>
      </div>
      {/*container*/}
    </div>
  );
};

export default Signup;
