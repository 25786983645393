import React, { useState, useEffect, useContext } from 'react';
import { ROOM_TYPE_CODE } from '../../../constant';
import _ from 'lodash';
import './businessGroupBackroom.scss';
import './backroom.scss';
import '../../../pages/businessRoomSingle/step2.scss';

// 출장자 단체 배방
const BusinessGroupbackroomConfirm = (props: any) => {

  if (!props.isShowGroupBackroomConfirmPop) return <></>;
  if (props.selectGroup.room_type !== ROOM_TYPE_CODE.business_room_group) return <></>;

  return (
    <div id="pop_request_group" className="pop_dim_wrap pop_modal pop_lg" style={{display: props.isShowGroupBackroomConfirmPop? 'block' : 'none'}}>
      <div className="pop_wrap">
          <div className="title_wrap">
              <h3>배방진행</h3>
              <button className="pop_btn_close pop_close" onClick={props.onClose}>닫기</button>
          </div>
          <div className="contents_wrap">
              <div className="pt0">
                  <div className="col col_list">
                      <h3 className="title_contents bd0">
                          신청정보 총 이용인원 {Object.keys(props.selectGroup.guests).length}명
                      </h3>
                      <div className="table_list">
                          <table>
                              <caption className="sr_only"></caption>
                              <colgroup>
                                <col width="*"/>
                                <col width="15%"/>
                                <col width="20%"/>
                                <col width="20%"/>
                                <col width="20%"/>
                              </colgroup>
                              <thead>
                              <tr>
                                  <th>성명</th>
                                  <th>성별</th>
                                  <th>입숙일</th>
                                  <th>퇴숙일</th>
                                  <th>숙소정보</th>
                              </tr>
                              </thead>
                              <tbody>
                              {Object.keys(props.selectGroup.guests).length > 0 ? props.selectGroup.guests.map((group: any, groupIdx: number) => (   
                                <tr key={groupIdx}>
                                    <td>{group.guest_name}</td>
                                    <td>{group.guest_gender === 1? '남' : '여'}</td>
                                    <td>{group.stay_after}</td>
                                    <td>{group.stay_before}</td>
                                    <td>{group.room_building_name} {group.room_building_dong} {group.room_name}호</td>
                                </tr>
                              )) : '' }
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
          <div className="button_wrap btn_length_1">
              <button type="button" className="btn btn_xlg btn_darkblue pop_close" onClick={props.onClose}>확인</button>
          </div>
      </div>
  </div>
  );
};

export default BusinessGroupbackroomConfirm;
