import React from 'react';
import intl from 'react-intl-universal';

interface Props {
  step: number
}

//가족숙소_lnb
const FamilyHouseNav = (props: Props) => {
  return (
    <>
      <ol className="lst_lnb">
        <li className={props.step === 1 ? "on" : ""}>
          <span className="num">01</span>
          <strong className="stit">{intl.get("투숙 인원 및 정보 등록")}</strong>
        </li>
        <li className={props.step === 2 ? "on" : ""}>
          <span className="num">02</span>
          <strong className="stit">{intl.get("기간선택")}</strong>
        </li>
        <li className={props.step === 3 ? "on" : ""}>
          <span className="num">03</span>
          <strong className="stit">{intl.get("입숙서약동의")}</strong>
        </li>
        <li className={props.step === 4 ? "on" : ""}>
          <span className="num">04</span>
          <strong className="stit">{intl.get("예약 완료")}</strong>
        </li>
      </ol>
    </>
  );
};

export default FamilyHouseNav;
