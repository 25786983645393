import { HeaderStore } from './headerStore';
import { LodgedStore } from './lodgedStore'
import { ReservationStore } from './reservationStore';

export class RootStore {
    lang: string = '';
    lodgedStore : LodgedStore;
    headerStore : HeaderStore;
    reservationStore : ReservationStore;
    constructor() {
        this.lodgedStore = new LodgedStore();
        this.headerStore = new HeaderStore();
        this.reservationStore = new ReservationStore();
    }
}