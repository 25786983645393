import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;

export interface Guests {
  guest_name: string;
  guest_gender: string;
  guest_cellphone: string;
  guest_relation: string;
}

export interface LodgeBookPayload {
  stay_after: string;
  stay_before: string;
  guests: Guests[];
}

class FamilyRoom {
  static URL = `${IP}`;

  /**
   * 가족숙소 이용신청
   */
  @apiHandler()
  static async lodgeBook(payload: LodgeBookPayload) {
    const PATH = `/member/lodge/family-room/book/`;

    return await axios.post(`${FamilyRoom.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
}

export default FamilyRoom;
