import React from 'react';

import SignupComplete from '../../components/templetes/signupComplete';

const SignupCompletePage = (props: any) => {
  return (
    <>
      <SignupComplete {...props} />
    </>
  );
};

export default SignupCompletePage;
