import _ from 'lodash';
import {action, computed, observable} from 'mobx';
import { useSelector } from 'react-redux';
import { lodge } from '../../apis';
import { LANG_CODE, LODGE_STATUS_CODE } from '../../constant';
import { SHOW_MODAL } from '../modules/modal';
export class HeaderStore {

  @observable
  _lang: number = LANG_CODE.kr;
  _showFamily : boolean = false;

  @computed
  get lang() : number {
    return this._lang;
  }
  
  @computed
  get showFamily() : boolean {
    return this._showFamily;
  }

  @action
  public isShowFamily() {
    (async()=> {
      try {
        const { data } = await lodge.getGuest({}); // 이용중
        let shows = data.filter((lodge: any) => [LODGE_STATUS_CODE.checkin].includes(lodge.status) && lodge.room_type === 2);   // 이용중
        if(shows.length >  0){
          this._showFamily = true;
        }
      }catch ({ status, data }) {
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
        
        return ;
      }
    })();
  }

  /*constructor(root: any) {
    this.rootStore = root;
    (this, {
      lang: observable
    });
  }*/


}
