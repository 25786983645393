import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;

class Plege {
  static URL = `${IP}`;

  // 입숙 서약서 조회
  @apiHandler()
  static async getPledges(params: {work_site: number, room_type: number, }) {
    const PATH = `/member/worksite/lodge/pledge/`;

    return await axios.get(`${Plege.URL}${PATH}`, {
      params,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
}

export default Plege;
