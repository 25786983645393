import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { MessageContext } from '../../routes';

import intl from 'react-intl-universal';

import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';

import DefaultTemplate from '../../components/templetes/default';
import StepNav from '../../components/molecules/reservationStepNav';
import { onShowModal, SHOW_MODAL } from '../../store/modules/modal';

import { ROOM_TYPE_CODE, WORK_SITE,  } from '../../constant';
import { getTodayDate, getNextDay, gettwoDay, getTwoWeeks } from '../../utils/date';
import { familyRoom, lodge } from '../../apis';
import { useStores } from '../../store/mobx';
import { observer } from 'mobx-react';
import { getWorkSitePhoneNum } from '../../utils/commons';
import OnOff from '../../apis/onOff';
import { Redirect } from "react-router-dom";

const FamilyHouseRoomPage = observer((props: any) => {
  const {reservationStore: store} = useStores();
  const { info } = useContext(MessageContext);
  // const { userInfo = {} } = useSelector((state: any) => ({
  //   userInfo: state.user,
  // }));
  const { userInfo = {}, workplaceMap = {} } = useSelector((state: any) => ({
    userInfo: state.user,
    workplaceMap: state.base.workplaceMap
  }));

  const [today, setToday] = useState(getTodayDate());
  const [step, setStep] = useState<number>(1);

  const [building, setBuilding] = useState({});
  const [room, setRoom] = useState<any>({});

  const [roomType, setRoomType] = useState<number>(ROOM_TYPE_CODE.family_room); // 출장자 숙소(1), 영빈관(2), 기숙사 숙소(3)
  const [workSite, setWorkSite] = useState(-1); // 울산(1), 남양(2),  아산(3), 전주(4)

  const [dateAfter, setDateAfter] = useState<string>(today[0]); // dateAfter ~ dateBefore
  const [dateBefore, setDateBefore] = useState<string>(getNextDay(today[0])[0]);

  const [twoWeeksDate, setTwoWeeksDate] = useState<string>(getTwoWeeks(today[0])[0]); // dateAfter ~ dateBefore
  const [nextTwoWeeksDate, setNextTwoWeeksDate] = useState<string>(getNextDay(twoWeeksDate)[0]);

  const [guests, setGuests] = useState<any>([]);
  const [guestCnt, setGuestCnt] = useState<number>(1);

  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(1);

  useEffect(() => {
    // if (userInfo.worksite) setWorkSite(userInfo.worksite);
    (async () => {
      if(userInfo.worksite !== 0){
        // 가족숙소 신청 가능한지 검사 -> 현재는 남양연구소만 가족숙소 사용중
        if(userInfo.worksite == 2) {
          var remainDay = 22;
          var remainMonth = -1;
          
          // 가족숙소 패널티 적용
          // 7일전 취소 : 재신청 가능
          // 6일 ~ 하루전 취소 : 당해년도 사용한 것으로 간주
          // 당일 취소 및 미통보 : 향후 1년간 재신청 불가(이전 신청일 기준)
          try {
            const payload: any = {
              employee_number: userInfo.employeenumber
            };
            let {data} = await lodge.getPenalty(payload);
            console.log(data);
            
            if(data.length > 0) {
              if(data[0].cancel_date) {
                if(data[0].stay_after) {
                  const t1 = new Date(data[0].cancel_date); //가족숙소 취소일
                  const t2 = new Date(data[0].stay_after);          //입숙예정일
                  const y = new Date();
                  var year = y.getFullYear();
                  var y_t1 = t1.getFullYear();
  
                  let diff = Math.abs(t1.getTime() - t2.getTime()); 
                  diff = Math.ceil(diff / (1000 * 60 * 60 * 24));
                  console.log(diff);
  
                  //6일 ~ 하루 전 취소
                  if(diff < 7 && diff > 1) {
                    //당해년도 신청 불가
                    if(year == y_t1) {
                      console.log(year + ', ' + y_t1);
                      info("당해년도 R&D STAY 입숙 " + diff + "일 전 취소하여 R&D STAY 신청이 불가능합니다.");
                      props.history.push('/home');
                      return;
                    }
                  }
                  //당일 취소
                  else if(diff == 0){
                    //향후 1년간 신청 불가
                    var oneYearLater = new Date(t2.setFullYear(t2.getFullYear() + 1));
                    var today = new Date();
  
                    var m1 = oneYearLater.getFullYear() + '-' + oneYearLater.getMonth() + '-' + oneYearLater.getDate();
                    var m2 = today.getFullYear() + '-' + today.getMonth() + '-' + today.getDate();
                    
                    if(m2 < m1) {
                      info("당해년도 R&D STAY 입숙 당일 취소하여 R&D STAY 신청이 불가능합니다.");
                      props.history.push('/home');
                      return;
                    }
                  }
                }
              }
            }
          }
          catch({status, data}) {
            if (status === 401) {
              window.location.href = '/force/signout';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }
  
            (window as any).store.dispatch({
              type: SHOW_MODAL,
              payload: {
                title: '문제발생',
                msg: '문제가 발생했습니다',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }

          // 미통보
          try {
            const payload: any = {
              employee_number: userInfo.employeenumber
            };
            let {data} = await lodge.getPenaltyNoReply(payload);
            
            if(data.length > 0) {
              if(data.checkin_id == '' || data.checkin_id == null) {
                console.log(data);
                info("당해년도 R&D STAY 입숙 당일 취소하여 R&D STAY 신청이 불가능합니다.");
                props.history.push('/home');
                return;
              }
            }
          }
          catch({status, data}) {
            if (status === 401) {
              window.location.href = '/force/signout';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }
  
            (window as any).store.dispatch({
              type: SHOW_MODAL,
              payload: {
                title: '문제발생',
                msg: '문제가 발생했습니다',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }

          // 잔여분 신청일 정보를 가져온다
          try {
            const payload: any = {
              roomType,
              workSite: userInfo.worksite,
            };
            let {data} = await lodge.getRemainRoom(payload);
            console.log(data);
            if(data[0].remain_day) {

              // 가족숙소 신청기간인지 검사 -> 가족숙소 신청 기간 : 1일 ~ 15일
              // 잔여분 신청일인지 검사 -> 잔여분 신청일 : 기본 22일 이지만 관리자 화면에서 설정한 값을 따른다
              remainDay = data[0].remain_day;
              var checkDate = new Date();
              var c_month = checkDate.getMonth() + 1;
              var c_day = checkDate.getDate();

              if(c_day < 16) {
                console.log('R&D STAY ok1');
              }
              //가족숙소 신청기간이 아닐 때
              else {
                //잔여분 신청일일 때
                if(c_day == remainDay) {
                  console.log('R&D STAY ok2');
                }
                //잔여분 신청일이 아닐 때
                else {
                  info("R&D STAY 신청기간이 아닙니다.\nR&D STAY 신청기간 : 매월 1일 ~ 15일\n잔여분 신청 : " + remainDay + "일");
                  props.history.push('/home');
                  return;        
                }
              }

              //if(remainMonth != c_month) {
              //  info("R&D STAY 신청기간이 아닙니다.\nR&D STAY 신청기간 : 매월 1일 ~ 15일\n잔여분 신청 : " + remainDay + "일");
              //  props.history.push('/home');
              //  return;                
              //}

              //if(c_day != remainDay && c_day > 15) {
              //  console.log(remainDay);
              //  info("R&D STAY 신청기간이 아닙니다.");
              //  props.history.push('/home');
              //  return;
              //}
            }
          }
          catch({status, data}) {
            if (status === 401) {
              window.location.href = '/force/signout';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }
  
            (window as any).store.dispatch({
              type: SHOW_MODAL,
              payload: {
                title: '문제발생',
                msg: '문제가 발생했습니다',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }
    
          // 해당년도 입숙 신청중인지
          try {
            const payload: any = {
              offset,
              limit,
            };
            console.log(userInfo);
            console.log(userInfo.employeenumber);
            if (userInfo.employeenumber) {
              console.log(userInfo.employeenumber);
              payload.employee_number = userInfo.employeenumber;
            }
            if (roomType) {
              payload.room_type = roomType;
            }
        
            let {data} = await lodge.getFamilyRoomHistory(payload);
            console.log(data)

            if(data.length > 0) {
              console.log('1');
              info("해당 년도 R&D STAY 신청을 할 수 없습니다.");
              props.history.push('/home');
            } 
            else {
              var date = new Date();
              var m = date.getMonth();
              var y = date.getFullYear();
              var startMonth = new Date(y, m+1, 1);
    
              const s_year = startMonth.getFullYear();
              const s_month = ((startMonth.getMonth() + 1) >= 10) ? startMonth.getMonth() + 1 : `0${startMonth.getMonth() + 1}`;
              const s_day = startMonth.getDate();
              setDateAfter([s_year, s_month, s_day].join('-'));
              setDateBefore([s_year, s_month, s_day + 1].join('-'));  
            }
          }
          catch({status, data}){
            if (status === 401) {
              window.location.href = '/force/signout';
              return ;
            } else if (status === 403) {
              (window as any).store.dispatch({
                type: SHOW_MODAL,
                payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
            }else if ( status === 500 ) {
              window.location.href = '/500';
              return;
            }
  
            (window as any).store.dispatch({
              type: SHOW_MODAL,
              payload: {
                title: '문제발생',
                msg: '문제가 발생했습니다',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }
        }
        try {
          // 사업장 초기값 세팅
          let { data } = await OnOff.getWorksite({
            user_work_site: userInfo.worksite,
            room_type: ROOM_TYPE_CODE.dormitory_room
          });
          
          // 초기 사업장 선택
          if(data.filter((d: any) => d.work_site === userInfo.worksite).length > 0){
            setWorkSite(userInfo.worksite);
          }else if(data[0].work_site) {
            setWorkSite(data[0].work_site);
          }
        }catch ({ status, data }) {
          if (status === 401) {
            window.location.href = '/force/signout';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }

          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '문제발생',
              msg: '문제가 발생했습니다',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }
      }
    })();
  }, [userInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);


  const onSubmitHandler = async () => {
    if (!guests.every((guest: any) => guest.room !== -1)) {
      info(intl.get('이용자 정보 입력이 충분하지 않습니다.'));
      return
    }

    try {
      let payload: any = {
        stay_after: dateAfter,
        stay_before: dateBefore,
        guests
      }

      let { data } = await familyRoom.lodgeBook(payload);
      store.roomInit();

      //let guestsCellPhone = guests.map((item: any) => {
      //  return item.guest_cellphone;
      //});
  
      // 예약 완료 문자 발송
      //let smsPayload: any = {
      //  cellphone : guestsCellPhone.join(','),
      //  message : '입숙신청되었습니다. \n신청정보 - 숙소유형 : R&D STAY \n- 이용 사업장 : '+ WORK_SITE[workSite] +'  \n- 이용 기간 : ' + dateAfter + ' - ' + dateBefore
      //          + '\n신청진행 확인은 "마이페이지>>신청관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 '+getWorkSitePhoneNum(workSite, ROOM_TYPE_CODE.family_room)
      //}
      //const { data: sms } = await lodge.sendSMS(smsPayload);

      setStep(4);
    } catch ({status, data}) {
      // console.log(err)
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else if (status === 400) {
        let msg = data.code
        if (!msg) {
          
        } else {
          info(intl.get(data.code))
          return;
        }
      }
      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
      return;
    }
  }
  
  window.addEventListener('popstate', function(event) { 
    props.history.push('/home');
  });

  return (
    <DefaultTemplate
      leftInfo={
        <>
          <h2>{intl.get("R&D STAY")}</h2>
          <StepNav.FamilyHouseNav step={step} />
        </>
      }
      id="wrap"
      className={`wrap_request request_step${step}`}
      {...props}
    >
      {
        step === 1 ? (
          <Step1
            next={() => setStep(2)}
            dateAfter={dateAfter}
            dateBefore={dateBefore}
            setDateAfter={setDateAfter}
            setDateBefore={setDateBefore}
            today={today[0]}
            workSite={workSite}
            roomType={roomType}
            building={building}
            room={room}
            setWorkSite={setWorkSite}
            setGuestCnt={setGuestCnt}
            guests={store._familyRoom.guests? store._familyRoom.guests : guests}
            //guests={guests}
            setGuests={setGuests}
            guestCnt={guestCnt}
            history={props.history}
            userInfo={userInfo}
          />
        ) : step === 2 ? (
          <Step2
            prev={() => setStep(1)}
            next={() => setStep(3)}
            dateAfter={dateAfter}
            dateBefore={dateBefore}
            setDateAfter={setDateAfter}
            setDateBefore={setDateBefore}
            afterDisablePoint={gettwoDay(dateAfter)[0]}
            twoWeeksDate={twoWeeksDate}
            nextTwoWeeksDate={nextTwoWeeksDate}
            setTwoWeeksDate={setTwoWeeksDate}
            setNextTwoWeeksDate={setNextTwoWeeksDate}
            today={today[0]}
            workSite={workSite}
            guestCnt={guestCnt}
            guests={guests}
            setGuests={setGuests}
            building={building}
            room={room}
            setBuilding={setBuilding}
            setRoom={setRoom}
            userInfo={userInfo}
          />
        ) : step === 3 ? (
          <Step3
            prev={() => setStep(2)}
            next={onSubmitHandler}
            workSite={workSite}
            roomType={roomType}
            building={building}
            room={room}
            userInfo={userInfo}
            guests={guests}
          />
        ) : step === 4 ? (
          <Step4
            guests={guests}
            dateAfter={dateAfter}
            dateBefore={dateBefore}
            twoWeeksDate={twoWeeksDate}
            nextTwoWeeksDate={nextTwoWeeksDate}
            workSite={workSite}
            building={building}
            room={room}
            history={props.history}
            userInfo={userInfo}
          />
        ) : <></>
      }
    </DefaultTemplate>
  );
});

export default FamilyHouseRoomPage;
