import React from 'react';

//이미지 tag
const ByServer = (props: any) => {
  // console.log(props)
  // console.log(process.env)
  return (<img src={`${process.env.REACT_APP_MEDIA}/${props.src}`} />)
};

export default ByServer;
