import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;

class OnOff {
  static URL = `${IP}`;
  
  // 이용자 타입 표시
  @apiHandler()
  static async getUserType(payload: { work_site: number, room_type: number }) {
    const PATH = '/member/worksite/display/guest/';
    return await axios.get(`${OnOff.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 사업장   
  @apiHandler()
  static async getWorksite(payload: {work_site?: number, user_work_site: number, room_type?: number}) {
    const PATH = '/member/worksite/display/menu/';
    return await axios.get(`${OnOff.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }


  // 업로드 해야할 파일 목록
  @apiHandler()
  static async getDocument(payload: {work_site?: number, work_site_name?: string, guest_type: number, room_type: number}) {
    const PATH = '/member/worksite/lodge/document/';
    return await axios.get(`${OnOff.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
}


export default OnOff
