import getAccessToken from './commons/getAccessToken';

import { SHOW_MODAL } from '../store/modules/modal';

function apiHandler(rules = { ERROR_CODE_CHECK: 'code' }): any {
  return function (target: any, property: any, descriptor: any): any {
    const originMethod = descriptor.value;

    descriptor.value = async function (...args: any[]) {
      let res = {
        status: 200,
        data: {},
        errorCode: false,
      };
      try {
        const { status, data } = await originMethod.apply(target, args);

        res = {
          status,
          data,
          errorCode: data[rules.ERROR_CODE_CHECK],
        };

        // 정상응답은 여기서 반환
        return res;
      } catch (err) {
        // 4xx, 5xx는 exception 처리된다.

        /*
         * 400: data validation
         * 401: accessToken 만료, 로그아웃 상태
         * 403: 권한없음.
         */

        const { status, data } = err.response;
        res = {
          status,
          data,
          errorCode: data[rules.ERROR_CODE_CHECK],
        };

        // *****trycatch안 error가 늦게 잡히는 현상으로 하단 에러는 각 try catch안에 넣어둠

        // if (status === 401) {
        //   window.location.href = '/force/signout';
        //   return res;
        // } else 
        // if (status === 403) {
        //   (window as any).store.dispatch({
        //     type: SHOW_MODAL,
        //     payload: {
        //       title: '권한오류',
        //       msg: '접근권한이 없습니다.',
        //       redirectPath: false,
        //       statusCode: err.response.status,
        //       errorCode: '',
        //       type: 'error',
        //       isModalShow: true,
        //     },
        //   });
        //   return res;
        // }
        // else if ( status === 500 ) {   // 500 error
        //   // console.log(window.location.href);
        //   // if(window.location.pathname === '/') {
        //   //   //alert("error");
        //   //   return;
        //   // }else {
        //   //   window.location.href = '/500';
        //   //   return res;
        //   // }
        // }

        throw {
          ...res,
          error: new Error('Error Force Raise By StatusCode'),
        };
      }
    };
  };
}

export default apiHandler;
