import React, { useState, useEffect, useContext } from 'react';
import { isMobile } from 'react-device-detect';

import { DownloadText } from '../../molecules/download';
import { core } from '../../../apis';
import { SHOW_MODAL } from '../../../store/modules/modal';
import { MessageContext, MessageImpl } from '../../../routes';

interface RowProps {
  file: any
  onChange: Function
  isUpdateMode: boolean
  fileIdx: number
  children: any
  isGroup?: boolean  // 출장자 단체인지
}

const Row = (props: RowProps) => {
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  const onAddHandler = async (e: any) => {
    if (!props.isUpdateMode) {
      return
    }
    
    let file = e.target.files[0]
    let form = new FormData();
    
    form.append("path", file);
    
    let extList = ['gif', 'jpg', 'jpeg', 'png', 'hwp', 'pdf', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'docx'];

    let fileData: any = e.target.files[0];
    let fileExt = (fileData.name).split('.').pop().toLowerCase();

    if(!extList.includes(fileExt)){
      let extText = extList.join(', ');
      error(`파일은 `+extText+` 만 가능합니다.`);
      return false;
    }else if (e.target.files[0].size >= 10485760) {      
      error(`파일 용량은 10MB 이상 첨부할 수 없습니다.`);
      return false;
    }

    try {
      let { data } = await core.fileUpload<{
        id?: number
        path?: string
        name?: string
        document: any
        file: any
      }>(form)

      data.id = data.id
      data.file = {...data}
      
      props.onChange({...data, document: props.file.document})
    } catch (err) {
      //console.log(err)

      const { status, data } = err;

      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;

    }
  }

  const onDeleteHandler = async () => {
    if (!props.isUpdateMode) {
      return
    }
    
    props.onChange(
      {document: props.file.document}
    )
  }
 // 파일의 확장자를 가져오는 함수
  const getFileExtension = (filename: string) => {
    const parts = filename.split('.');
    if (parts.length > 1) {
      return parts.pop()!.toLowerCase(); // 파일 이름이 마침표를 포함하는 경우에만 확장자 추출
    } else {
      return ''; // 마침표가 없는 경우 빈 문자열 반환
    }
  };

  const downloadFile = async (url: string, filename: string) => {
    const token = window.localStorage.getItem('accessToken');
    console.log('url : ' + url);
    console.log('filename : ' + filename);

    if (!url) {
      console.error('url 경로가 없습니다.');
      return;
    }

    if(!filename || filename.length == 0) {
      const name = url.split("/");
      filename = name[name.length - 1];
    }
  
    const unifiedUrl = url.replace(/\\/g, '/');
    const parts = unifiedUrl.split('/');
    const desiredParts = parts.slice(2).join('\\');

    // 파일의 확장자가 이미지나 PDF인지 확인하는 함수
    const isImageOrPDF = (extension: string) => {
      const supportedExtensions = ['gif', 'jpg', 'jpeg', 'png', 'pdf'];
      return supportedExtensions.includes(extension);
    };

    const fileExtension = getFileExtension(filename);
    const isImageOrPDFFile = isImageOrPDF(fileExtension);

    //fileDownload
    try {
      let payload: any = {
        filename: filename,
        url : desiredParts,
      };

      let { data } = await core.fileDownload(payload);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      //info("협조전 등록이 완료되었습니다.");
    } catch ({ data, status }) {
      console.log(data, status);
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }
      info("파일 다운로드 중 문제가 발생했습니다");
      return;
    }
  }; 
  return (
    <div className="tr">
      <div className="th">
          {props.isGroup ? '협조전' : props.file?.document.name }
        </div>
        {props.isUpdateMode? (
          <div className="td td_fileupload">
            <div className="wrap_file w100">
              <input id="input_copy" className="upload_name form_control" value={props.file?.file?.name} placeholder="파일찾기를 클릭하여 파일을 업로드 하세요" disabled />
              <input type="file" id={`input_upload_copy_${props.fileIdx}`} className="upload_hidden" onChange={onAddHandler} onClick={(e: any) => (e.target.value = null)} disabled={!props.isUpdateMode} />
              {/* <button type="button" className="btn_fileuploade_close" onClick={onDeleteHandler}></button> */}
              <label htmlFor={`input_upload_copy_${props.fileIdx}`} className="btn btn_sm btn_outline">파일찾기</label>
            </div>
          </div>
        ) :(props.file?.file?.path && (
            <div className="td">
              {props.file?.file?.name}
              <button className="btn_sm btn_darkblue btn_download"
              onClick={() => downloadFile(`${process.env.REACT_APP_MEDIA}/${props.file?.file?.path}`, '')}
              >
                다운로드
                </button>
              {/*<a 
                href={`${process.env.REACT_APP_MEDIA}/${props.file?.file?.path}`} 
                className="btn_sm btn_darkblue btn_download"
                target={`${props.file?.file?.path}`.split('.').pop() 
                    && ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(`${props.file?.file?.path}`.split('.').pop() || '' )
                    && !isMobile ? '_blank' : ''}
              >
                다운로드
              </a>*/}
            </div>
          ))
        }

        {/* <div className="table_design_list col_width2">
          <div className="th">{props.file?.document.name}</div>
          <div className="td pr20">
            <div className="upload_area pc mt10">
              <label htmlFor="input_copy">{props.file?.document.name}</label>
              <div className="wrap_file">
                
                <input id="input_copy" className="upload_name form_control" value="" placeholder="파일찾기를 클릭하여 파일을 업로드 하세요" disabled />
                <input type="file" id={`input_upload_copy_${props.fileIdx}`} className="upload_hidden" onChange={onAddHandler} onClick={(e: any) => (e.target.value = null)} disabled={!props.isUpdateMode} />
                <label htmlFor={`input_upload_copy_${props.fileIdx}`} className="btn btn_darkblue">파일찾기</label>
              </div>
            </div>
            <div className="uploadfile_area pb10 clearfix">
              <div style={{ margin: 5 }} key={props.fileIdx} className="btn_uploadfile">
                {props.file?.file?.path && (
                  <>
                    <DownloadText src={props.file?.file?.path} value={props.file?.file?.name} />
                    <span className="btn_uploadfile_close" onClick={onDeleteHandler} > 삭제 </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div> */}
    </div>
  )
}

interface FileFormProps {
  files: any[]
  isUpdateMode: boolean
  onChange: Function
  originFiles: any[]
  isGroup?: boolean  // 출장자 단체인지
  isClean?: boolean
  setIsClean?: Function
}

const FileForm = (props: FileFormProps) => {
  const [originFiles, setOriginFile] = useState<any>(props.originFiles || []);
  const [rfcs, setRfcs] = useState<any[]>(props.files || []);

  useEffect(() => {
    // TOOD: 추후 파일 종류가 늘어나면 rfcs.concat(file1, file2) 형태로 하나의 리스틀로 만들어 준다
    props.onChange(rfcs);
  }, [rfcs])

  useEffect(() => {
    // 수정 모드가 비활성화 되면 원래의 상태로 돌려준다.
    if (!props.isUpdateMode) {
      //console.log(originFiles);
      setRfcs( originFiles)
    }

    // 초기화인 경우 원래 상태로 돌리기
    if (props.isClean) {
      //console.log(originFiles);
      setRfcs(originFiles);
      if(props.setIsClean) props.setIsClean();
    }
  }, [props.isUpdateMode, props.isClean])

  return (
    <>
    {rfcs.length > 0 ? (
      <div className="title_contents">
          <h3 className="title">첨부서류</h3>
      </div>
    ) : ''}

    <div className="table_design_list col_width2">
      <div id="table" className="table">
          <div className="thead mobile">
              <div className="tr">
                  <div className="th"></div>
                  <div className="th">
                      첨부서류
                  </div>
              </div>
          </div>

          <div className="tbody">
          

          {rfcs.map((rfc: any, rfcIdx: number) => (
            <Row
              file={rfc}
              key={rfcIdx}
              fileIdx={rfcIdx}
              onChange={(file: any) => {
                let tempRfcs = [...rfcs]
                tempRfcs[rfcIdx] = file
                setRfcs(tempRfcs)
              }}
              isUpdateMode={props.isUpdateMode}
              isGroup={props.isGroup}
            >
              </Row>
          ))}

        </div>
      </div>
    </div>
    </>
  );
};

export default FileForm;
