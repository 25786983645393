import React, { useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { MessageContext } from '../../../routes';
import intl from 'react-intl-universal';
import { LOGIN_REQUEST } from '../../../store/modules/auth';
import PasswordInput from '../../atoms/input/password';

//로그인 Form
const SigninForm = (props: any) => {
  const { info } = useContext(MessageContext);
  const [employeeNumber, setEmployeeNumber] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [ipAddress, setIpAddress] = useState<string>('');
  const [isFootState, setIsFootState] = useState<boolean>(true);
  const dispatch = useDispatch();

  const move = (path: any) => {
    props.history.push(path);
  };

  const onClickSignupHandler = () => move('/auth/signup');

  var publicIp = [];
  useEffect(() => {
    (async () => {
      publicIp = require('public-ip');
      setIpAddress(await publicIp.v4());
    })()
  }, []);

  const onLoginHandler = () => {

    window.sessionStorage.setItem('fromLogin','Y');

    dispatch({
      type: LOGIN_REQUEST,
      payload: { employee_number: employeeNumber, password, ip_address: ipAddress },
      history: props.history,
    });
  };

  const onShowHideFooterHandler = (state: boolean) => {
    setIsFootState(state);
  };

  return (
    <>
      <div className="col">
        <div className="group_login">

          <h2 className="title pc">통합숙소관리시스템</h2>
          <h2 className="title mobile">{intl.get('_BASE_signin')}</h2>
          <ul className="form_login clearfix">
            <li>
              <label htmlFor="input_id">ID</label>
              <p>
                <input
                  type="text"
                  id="input_id"
                  className="form_control form_lg"
                  placeholder="아이디를 입력해주세요"
                  value={employeeNumber}
                  onChange={(e: any) => {
                    setEmployeeNumber(e.target.value);
                  }}
                  onFocus={(e: any) => {
                    onShowHideFooterHandler(false);
                  }}
                  onBlur={(e: any) => {
                    onShowHideFooterHandler(true);
                  }}
                />
              </p>
            </li>
            <li>
              <label htmlFor="input_pw">PASSWORD</label>
              <p>
                <PasswordInput
                  id="input_pw"
                  className="form_control form_lg"
                  placeholder="비밀번호를 입력해주세요"
                  value={password}
                  onChange={(e: any) => setPassword(e.target.value)}
                  onEnter={onLoginHandler}
                  onFocus={onShowHideFooterHandler}
                  onBlur={onShowHideFooterHandler}
                />
              </p>
            </li>
          </ul>

          <div className="submit">
            <button className="btn btn_xlg btn_darkblue" onClick={onLoginHandler}>로그인</button>
            <button className="btn btn_xlg btn_outline" onClick={onClickSignupHandler}>회원가입</button>

            <ul className="lst_srch">
              <li onClick={props.onShowSearchIdPopupHandler}><a href="#void" className="btn btn_sm btn_link">아이디찾기</a></li>
              <li onClick={props.onShowSearchPasswordPopupHandler}><a href="#void" className="btn btn_sm btn_link">비밀번호찾기</a></li>
            </ul>
          </div>

          <ul className={isFootState ? "lst_membership on" : "lst_membership"}>
            {/* <li onClick={() => props.onShowSignupHelperPopupHandler(1)}>
              <button className="btn btn_outline">가입관련문의</button>
            </li> */} {/* 노상용이사님 요청 가입관련문의 제거요청 05.13 */}
            <li
              onClick={() => props.onShowSignupHelperPopupHandler(2)}
            >
              <button className="btn btn_outline">서비스이용약관</button>
            </li>
            <li
              onClick={() => props.onShowSignupHelperPopupHandler(3)}
            >
              <button className="btn btn_darkblue">개인정보처리방침</button>
            </li>
          </ul>

          <div className="guide">
              <div className="fl">
                  <p className="qrcord android">App <br /> Download</p>
              </div>
              <div className="fr">
                  <p className="browser_pick">
                      통합숙소관리시스템은 <br />
                      Chrome / Microsoft Edge에 <br />
                      최적화 되어 있습니다.
                  </p>
              </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default SigninForm;
