import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { MessageContext } from '../../routes';

import ConfirmBtn from '../../components/atoms/button/confirm';
import SelectCompany from '../../components/atoms/select/company';
import EmployeeNumberInput from '../../components/atoms/input/employeeNumber';
import PhoneInput from '../../components/atoms/input/phone';
import NameInput from '../../components/atoms/input/name';
import DepartmentInput from '../../components/atoms/input/department';
import WorkSiteSelect from '../../components/atoms/select/workSite';
import GuestType from '../../components/molecules/tab/guestType';
import TermsDetail from '../../components/organismas/termsDetail';

import { blankRemoveJson } from '../../utils/commons';
import { GUEST_TYPE_CODE, GENDER, ROOM_TYPE_CODE, WORK_SITE_CODE } from '../../constant';
import { auth } from '../../apis';
import intl from 'react-intl-universal';
import './step1.scss';
import { observer } from 'mobx-react';
import { useStores } from '../../store/mobx';
import OnOff from '../../apis/onOff';
import { SHOW_MODAL } from '../../store/modules/modal';

const Step1 = observer((props: any) => {
  const {reservationStore: store} = useStores();
  //const store = props.reservationStore!
  const { info } = useContext(MessageContext);

  const [guestType, setGuestType] = useState<number>(store._businessRoomSingle.type? store._businessRoomSingle.type : 1);
  const [isSame, setIsSame] = useState<boolean>(store._businessRoomSingle.isSame? store._businessRoomSingle.isSame : false);
  const { userInfo = {}, workplaceMap = {} } = useSelector((state: any) => ({
    userInfo: state.user,
    workplaceMap: state.base.workplaceMap
  }));

  const [employeeNumber, setEmployeeNumber] = useState(store._businessRoomSingle.employee_number? store._businessRoomSingle.employee_number :'');
  const [newEmployeeNumber, setNewEmployeeNumber] = useState(store._businessRoomSingle.employee_number? store._businessRoomSingle.employee_number :'');
  const [name, setName] = useState(store._businessRoomSingle.name? store._businessRoomSingle.name : '');
  const [department, setDepartment] = useState(store._businessRoomSingle.department? store._businessRoomSingle.department :'');
  const [phone, setPhone] = useState(store._businessRoomSingle.cellphone? store._businessRoomSingle.cellphone :'');
  const [gender, setGender] = useState(store._businessRoomSingle.gender? store._businessRoomSingle.gender : 1); // 남자(1), 여자(2)
  const [office, setOffice] = useState(store._businessRoomSingle.company? store._businessRoomSingle.company :'');
  const [carNumber, setCarNumber] = useState(store._businessRoomSingle.car_number1? store._businessRoomSingle.car_number1 :'');
  const [meal_ticket_request_count, setMeal_ticket_request_count] = useState<number> (store._businessRoomSingle.meal_ticket_request_count? store._businessRoomSingle.meal_ticket_request_count : 0);
  // 이용약관, 개인정보 자세히보기 추가
  const [tabNumber, setTabNumber] = useState<number>(-1);
  const [privacyPolicyAgreed, setPrivacyPolicyAgreed] = useState<boolean>(store._businessRoomSingle.privacyPolicyAgreed? store._businessRoomSingle.privacyPolicyAgreed : false);

  const [personalInformationCollectionAgreed, setPersonalInformationCollectionAgreed] = useState<boolean>(store._businessRoomSingle.personalInformationCollectionAgreed? store._businessRoomSingle.personalInformationCollectionAgreed : false);

  const [ worksites, setWorksites ] = useState<any>([]);

  useEffect(() => {
    (async () => {
      if(userInfo.worksite !== 0){
        try {
            // setWorksites(useWorkSite({
            //   roomType: ROOM_TYPE_CODE.business_room,
            //   userWorkSite: userInfo.worksite
            // }));
    
            // 사업장 그리기
            let { data } = await OnOff.getWorksite({
              // work_site: props.value,
              user_work_site: userInfo.worksite,
              room_type: ROOM_TYPE_CODE.business_room
            });
    
            setWorksites(data);
            
            // 초기 사업장 선택
            if(store._businessRoomSingle.work_site && store._businessRoomSingle.work_site != 0) {
              // 이전버튼 누른 경우 데이터 세팅
              props.setWorkSite(store._businessRoomSingle.work_site);
            }else {
              if(data.filter((d: any) => d.work_site === userInfo.worksite).length > 0){
                props.setWorkSite(userInfo.worksite);
              }else if(data[0].work_site) {
                props.setWorkSite(data[0].work_site);
              }
            }
        }catch ({ status, data }) {
          if (status === 401) {
            window.location.href = '/force/signout';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }

          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '문제발생',
              msg: '문제가 발생했습니다',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }
      }
    })();
  }, [userInfo.worksite]);
 
 /* const [ worksites, setUserWorkSiteByWorksite, setRoomTypeByWorksite ] = useWorkSite({
    roomType: ROOM_TYPE_CODE.business_room,
    userWorkSite: userInfo.worksite
  })*/

  const numberRegex = new RegExp(/[^0-9]/, 'g');

  useEffect(() => {
    if (worksites.length) {
      //console.log(worksites);
      let tempChk = false;
      worksites.forEach((workplace: any, workplaceIdx: number) =>{
        if(props.workSite === workplace.work_site) {
          //props.setWorkSite(props.workSite);  
          tempChk = true;
        }
      });

      if(!tempChk) {
        //props.setWorkSite(worksites[0].work_site);
      }
      //props.setWorkSite(worksites[0].work_site);
    }
  }, [props.workSite]);

  useEffect(() => {
    // setEmployeeNumber(userInfo.employeenumber);
    // setName(userInfo.username);
    // setDepartment(userInfo.department);
    // setPhone(userInfo.phone);
    // setGender(userInfo.gender);
    // setCarNumber(userInfo.car_number1);
    // setPrivacyPolicyAgreed(true);
    // setPersonalInformationCollectionAgreed(true);
    // setUserWorkSiteByWorksite(userInfo.worksite);
    // console.log('==========USER_INFO==========',userInfo);
  }, [userInfo]);

  const init = () => {
    setEmployeeNumber('');
    setName('');
    setDepartment('');
    setPhone('');
    setGender(1);
    setOffice('');
    setCarNumber('');
    setMeal_ticket_request_count(0);
    setPrivacyPolicyAgreed(false);
    setPersonalInformationCollectionAgreed(false);
    setIsSame(false);
    setGuestType(1);
  };

  const onClickUserSame = (e: any) => {
    if (e.target.checked) {
      setGuestType(1);
      setIsSame(true);

      setEmployeeNumber(userInfo.employeenumber);
      setNewEmployeeNumber(userInfo.employeenumber);
      setName(userInfo.username);
      setDepartment(userInfo.department);
      setPhone(userInfo.phone);
      setGender(userInfo.gender);
      //setPrivacyPolicyAgreed(true);
      //setPersonalInformationCollectionAgreed(true)
      setCarNumber('');
      setMeal_ticket_request_count(0);
    } else {
      setIsSame(false);
      // setEmployeeNumber(userInfo.employeenumber);
      // setName(userInfo.username);
      // setDepartment(userInfo.department);
      // setPhone(userInfo.phone);
      // setGender(userInfo.gender);

      setEmployeeNumber('');
      setNewEmployeeNumber('');
      setName('');
      setDepartment('');
      setPhone('');
      setGender(1);
      setCarNumber('');

      //setPrivacyPolicyAgreed(false);
      //setPersonalInformationCollectionAgreed(false);
      setMeal_ticket_request_count(0);
    }
  };

  const next = (e: any) => {
    if (!name) {
      info(intl.get('성명을 입력해주세요.'));
      return;
    }

    if (!phone || !(phone.length === 11)) {
      info(intl.get('휴대폰번호 항목이 비었거나 잘못되었습니다.\n(휴대폰번호는 11자리입니다.)'));
      return;
    }

    if (!gender) {
      info(intl.get('성별 항목이 비었습니다.'));
      return;
    }

    if(guestType === 1 && (newEmployeeNumber !== employeeNumber)) {
      info(intl.get("사번을 적용해주세요."));
      return;
    }

    if (guestType === 1 && (!employeeNumber || !department)) {
      if (!employeeNumber) {
        info(intl.get('사번을 검색하여 이용자 정보를 등록해주세요.'));
        return;
      }
      if (!department) {
        info(intl.get('소속부서를 입력해주세요.'));
        return;
      }
      return;
    } else if (guestType === 2 && (!employeeNumber || !department || !office)) {
      if (!employeeNumber) {
        info(intl.get('사번을 입력해주세요.'));
        return;
      }
      if (!department) {
        info(intl.get('소속부서를 입력해주세요.'));
        return;
      }
      if (!office) {
        info(intl.get('회사명을 선택해주세요.'));
        return;
      }
      return;
    } else if (guestType === 3 && !office) {
      if (!office) {
        info(intl.get('회사명 또는 단체명을 입력해주세요.'));
        return;
      }
    }

    if(props.workSite === WORK_SITE_CODE.울산 || props.workSite === WORK_SITE_CODE.아산공장){
      if(meal_ticket_request_count < 0) {
        info(intl.get('신청하실 식권 수를 입력해주세요'));
        return;
      }
    }
    
    if (!privacyPolicyAgreed || !personalInformationCollectionAgreed) {
      info(intl.get('서비스이용약관 동의 또는 개인정보이용동의를 동의하지 않았습니다.'));
      return;
    }
    
    const userInfo: any = blankRemoveJson({
      type: guestType,
      employee_number: employeeNumber,
      name: name,
      department: department,
      cellphone: phone,
      gender: gender,
      company: office,
    });

    if(privacyPolicyAgreed) {
      userInfo.privacyAgree = 'Y'
    } else {
      userInfo.privacyAgree = 'N'
    }

    if(personalInformationCollectionAgreed) {
      userInfo.personalAgree = 'Y'
    } else {
      userInfo.personalAgree = 'N'
    }
    //빈값으로라도 보내줘야 한다고 함
    userInfo['car_number1'] = carNumber;
    userInfo['meal_ticket_request_count'] = meal_ticket_request_count;

    // store 에 모든 데이터 담기!!!!!
    store._businessRoomSingle = [];
    const reservationData: any = ({
      type: guestType,
      employee_number: employeeNumber,
      name: name,
      department: department,
      cellphone: phone,
      gender: gender,
      company: office,
      car_number1: carNumber,
      meal_ticket_request_count: meal_ticket_request_count,
      work_site: props.workSite,
      isSame: isSame,
      privacyPolicyAgreed: privacyPolicyAgreed,
      personalInformationCollectionAgreed: personalInformationCollectionAgreed
    });

    store._businessRoomSingle = reservationData;

    props.setUsers([userInfo]);
    // console.log(userInfo);
    props.nextStep();
  };

  const onClickPersonnelHandler = async (e: any) => {
    if (!employeeNumber) {
      info(intl.get('사번을 입력해주세요.'));
      return;
    }
    try {
      const { data } = await auth.isSignedUser(employeeNumber);
      //console.log(data);
      setName(data.name || '');
      setDepartment(data.personnel.department || '');
      setPhone(data.cellphone || '');
      setGender(data.personnel.gender);

      // 입력한 사번데이터 담기
      setNewEmployeeNumber(employeeNumber);
    } catch ({ status, data }) {
      init();
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else if (status === 404) {
        info(`${employeeNumber}`+intl.get('는 가입된 사번이 아닙니다.'));
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  };

  const onEmployeeNumberEnterHandler = async (e: any) => {
    if (e.key === "Enter") {
      onClickPersonnelHandler(e)
    }
  }

  const onShowtermsDetailPopupHandler = (idx: number) => {
    setTabNumber(idx);
  }; // 열기
  const onCloseTermsDetailPopupHandler = () => {
    setTabNumber(-1);
  }; // 닫기

  return (
    <>
      <ul className="lst_thumb_request">
        <li>
          <strong>{intl.get("숙소유형")}</strong>
          <span>{intl.get("출장자숙소(개인)")}</span>
        </li>
        <li>
          <strong>{intl.get("이용사업장")}</strong>
          <span>{workplaceMap[props.workSite]?.name}</span>
        </li>
        <li>
          <strong>{intl.get("입숙예정일")}</strong>
          <span></span>
        </li>
        <li>
          <strong>{intl.get("퇴숙예정일")}</strong>
          <span></span>
        </li>
        <li>
          <strong>{intl.get("배방정보")}</strong>
          <span>
            {intl.get("건물/동")}:
            <br />
            {intl.get("방호수")}: 
          </span>
        </li>
      </ul>

      <WorkSiteSelect value={props.workSite} options={worksites} onChange={(workSite: number) =>{init(); props.setWorkSite(workSite)}} />

      <div className="title_contents">
        <h3 className="title">{intl.get("신청자 정보")}</h3>
      </div>

      <div className="table_design_list col_width6">
        <div id="table" className="table">
          <div className="tbody">
            <div className="tr">
              <div className="th">{intl.get("사번 (아이디)")}</div>
              <div className="td">{userInfo.employeenumber}</div>
              <div className="th">{intl.get("성명")}</div>
              <div className="td">{userInfo.username}</div>
              <div className="th">{intl.get("성별")}</div>
              <div className="td">{GENDER[userInfo.gender]}</div>
            </div>
            <div className="tr">
              <div className="th">{intl.get("소속 부서")}</div>
              <div className="td">{userInfo.department}</div>
              <div className="th">{intl.get("휴대폰 번호")}</div>
              <div className="td">{userInfo.phone}</div>
              <div className="th"></div>
              <div className="td"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt20">
        <label className="input_check">
          <input
              type="checkbox"
              checked={isSame}
              onChange={onClickUserSame}
          />
          <span className="label_text">
            {intl.get("신청자와 이용자가 동일합니다 (신청자 본인이 이용할 경우 체크하시면 이용자 정보가 자동 입력 됩니다)")}
          </span>
        </label>
      </div>

      <div className="title_contents">
        <h3 className="title">{intl.get("이용자 정보")}</h3>
      </div>

      <div className="tabs_area">
        <GuestType
          value={guestType}
          selectWorkSite={props.workSite}
          roomType={props.roomType}
          onChange={(guestType: number) => {
            guestType === GUEST_TYPE_CODE.inner_employer ? setIsSame(true) : setIsSame(false);
            init()
            setIsSame(false)
            setGuestType(guestType)
          }}
        />
      </div>

      {/* 사내 임직원 */}
      <div className={`tab_contents ${guestType === 1 ? 'on' : ''}`}>
        <div className="table_design_list col_width4">
          <div id="table" className="table">
            <div className="tbody">
              <div className="tr">
                <div className="th">{intl.get("사번 (아이디)")}</div>
                <div className="td">
                  <div className="form_wrap">
                    <EmployeeNumberInput
                      type="text"
                      className="form_control"
                      placeholder={intl.get("사번을 입력하세요")}
                      value={employeeNumber}
                      onChange={(e: any) => {
                        userInfo.employeenumber === e.target.value ? setIsSame(true) : setIsSame(false);
                        setEmployeeNumber(e.target.value)
                      }}
                      onKeyDown={onEmployeeNumberEnterHandler}
                    />
                    <button className="btn_sm btn_gray" onClick={onClickPersonnelHandler}>{intl.get("적용")}</button>

                  </div>
                </div>
                <div className="td blank"></div>
                <div className="td blank"></div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("성명")}</div>
                <div className="td">
                  <NameInput
                    type="text"
                    className="form_control"
                    placeholder={intl.get("성명을 입력하세요")}
                    value={name}
                    onChange={(e: any) => setName(e.target.value)}
                    disabled={true}
                  />
                </div>
                <div className="th">{intl.get("성별")}</div>
                <div className="td">
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="sex_choice1"
                      name="similar-sex1"
                      checked={gender === 1}
                      onChange={e => setGender(e.target.checked ? 1 : 2)}
                      disabled={true}
                    />
                    <span className="label_text">{intl.get("남자")}</span>
                  </label>
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="sex_choice2"
                      name="similar-sex1"
                      checked={gender === 2}
                      onChange={e => setGender(e.target.checked ? 2 : 1)}
                      disabled={true}
                    />
                    <span className="label_text">{intl.get("여자")}</span>
                  </label>
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("소속 부서")}</div>
                <div className="td">
                  <DepartmentInput
                    type="text" className="form_control" placeholder={intl.get("소속부서를 입력하세요")}
                    value={department}
                    onChange={(e: any) => setDepartment(e.target.value)}
                    disabled={true}
                  />
                </div>
                <div className="th">{intl.get("휴대폰 번호")}</div>
                <div className="td">
                  <PhoneInput
                    type="text"
                    id="input_cphone"
                    className="form_control"
                    placeholder={intl.get("휴대폰 번호를 입력하세요")}
                    value={phone}
                    onChange={(e: any) => setPhone(e.target.value)}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("차량번호")}</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    value={carNumber}
                    onChange={e => e.target.value.length < 12 && setCarNumber(e.target.value)}
                    placeholder={intl.get("차량번호를 입력하세요")}
                  />
                </div>
                  <div className="th">{intl.get("식권 수 (장)")}</div>
                  <div className="td">
                    <input
                      type="text"
                      className="form_control"
                      placeholder={intl.get("신청하실 식권 수를 입력해주세요")}
                      disabled={props.workSite === WORK_SITE_CODE.울산 || props.workSite === WORK_SITE_CODE.아산공장? false : true}
                      value={meal_ticket_request_count}
                      onChange={(e: any) => {
                        !e.target.value.match(numberRegex) &&
                        e.target.value.length < 5 &&
                        setMeal_ticket_request_count(parseInt(e.target.value) || 0)
                      }}
                      maxLength={4}
                    />
                  </div>
              </div>
            </div>
          </div>

          <div className="tr group_agree">
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id="privacy_garee"
                  name="privacy_garee"
                  checked={privacyPolicyAgreed}
                  onChange={e =>
                    setPrivacyPolicyAgreed(e.target.checked)
                  }
                />
                <span className="label_text">{intl.get("서비스 이용약관 동의(필수)")}</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(1)}>{intl.get("자세히보기")}</button>
            </div>
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id="psersonal_agree"
                  name="psersonal_agree"
                  checked={personalInformationCollectionAgreed}
                  onChange={e =>
                    setPersonalInformationCollectionAgreed(
                      e.target.checked,
                    )
                  }
                />
                <span className="label_text">{intl.get("개인정보 이용 동의(필수)")}</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(2)}>{intl.get("자세히보기")}</button>
            </div>
          </div>
        </div>

        <ul className="lst_help bg_gray">
          <li>{intl.get("사내 임직원의 경우 사번을 입력하시면 인사 정보 연동하여 기본 정보가 자동 입력 됩니다. 차량번호를 제외한 모든 입력 사항은 필수로 입력하셔야 합니다.")}</li>
        </ul>
      </div>

      {/* 계열사 임직원 */}
      <div className={`tab_contents ${guestType === 2 ? 'on' : ''}`}>
        <div className="table_design_list col_width4">
          <div id="table" className="table">
            <div className="tbody">
              <div className="tr">
                <div className="th">{intl.get("회사명(단체명)")}</div>
                <div className="td">
                  <SelectCompany
                    name=""
                    value={office}
                    guestType={guestType}
                    onChange={(e: any) => setOffice(e.target.value)}
                  />
                </div>
                <div className="th">{intl.get("소속 부서")}</div>
                <div className="td">
                  <DepartmentInput
                    type="text"
                    id="input_sdm"
                    className="form_control"
                    placeholder={intl.get("소속부서를 입력하세요")}
                    value={department}
                    onChange={(e: any) => setDepartment(e.target.value)}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("사번")}</div>
                <div className="td">
                  <EmployeeNumberInput
                    type="text"
                    className="form_control"
                    placeholder={intl.get("사번을 입력하세요")}
                    value={employeeNumber}
                    onChange={(e: any) => setEmployeeNumber(e.target.value)}
                  />
                </div>
                <div className="th">{intl.get("성명")}</div>
                <div className="td">
                  <NameInput
                    type="text"
                    className="form_control"
                    placeholder={intl.get("성명을 입력하세요")}
                    value={name}
                    onChange={(e: any) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("성별")}</div>
                <div className="td">
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="sex_choice1"
                      name="similar-sex2"
                      checked={gender === 1}
                      // onChange={e => setGender(e.target.checked ? 1 : 2)}
                      onClick={e => setGender(1)}
                      disabled={false}
                    />
                    <span className="label_text">{intl.get("남자")}</span>
                  </label>
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="sex_choice2"
                      name="similar-sex2"
                      checked={gender === 2}
                      onClick={e => setGender(2)}
                      disabled={false}
                    />
                    <span className="label_text">{intl.get("여자")}</span>
                  </label>
                </div>

                <div className="th">{intl.get("휴대폰 번호")}</div>
                <div className="td">
                  <PhoneInput
                    type="text"
                    id="input_sphone"
                    className="form_control"
                    placeholder={intl.get("휴대폰 번호를 입력하세요")}
                    value={phone}
                    onChange={(e: any) =>
                      setPhone(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("차량번호")}</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    value={carNumber}
                    onChange={e => e.target.value.length < 12 && setCarNumber(e.target.value)}
                    placeholder={intl.get("차량번호를 입력하세요")}
                  />
                </div>
                  <div className="th">{intl.get("식권 수 (장)")}</div>
                  <div className="td">
                    <input
                      type="text"
                      className="form_control"
                      placeholder={intl.get("신청하실 식권 수를 입력해주세요")}
                      disabled={props.workSite === WORK_SITE_CODE.울산 || props.workSite === WORK_SITE_CODE.아산공장? false : true}
                      value={meal_ticket_request_count}
                      onChange={(e: any) => {
                        setMeal_ticket_request_count(parseInt(e.target.value) || 0)
                      }}
                      maxLength={4}
                    />
                  </div>
              </div>
            </div>
          </div>
          <div className="tr group_agree">
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term1"
                  checked={privacyPolicyAgreed}
                  onChange={e =>
                    setPrivacyPolicyAgreed(e.target.checked)
                  }
                />
                <span className="label_text">{intl.get("서비스 이용약관 동의(필수)")}</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(1)}>{intl.get("자세히보기")}</button>
            </div>
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term2"
                  checked={personalInformationCollectionAgreed}
                  onChange={e =>
                    setPersonalInformationCollectionAgreed(
                      e.target.checked,
                    )
                  }
                />
                <span className="label_text">{intl.get("개인정보 이용 동의(필수)")}</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(2)}>{intl.get("자세히보기")}</button>
            </div>
          </div>
        </div>

        <ul className="lst_help bg_gray">
          <li>{intl.get("차량번호를 제외한 모든 입력 사항은 필수로 입력하셔야 합니다.")}</li>
        </ul>
      </div>

      {/* 외부인 */}
      <div className={`tab_contents ${guestType === 3 ? 'on' : ''}`}>
        <div className="table_design_list col_width4">
          <div id="table" className="table">
            <div className="tbody">
              <div className="tr">
                <div className="th">{intl.get("회사명(단체명)")}</div>
                <div className="td">
                  <input type="text" className="form_control" placeholder={intl.get("회사명 또는 단체명을 입력하세요")} value={office} onChange={(e: any) => setOffice(e.target.value)} />
                </div>
                <div className="th">{intl.get("성명")}</div>
                <div className="td">
                  <NameInput
                    type="text"
                    className="form_control"
                    placeholder={intl.get("성명을 입력하세요")}
                    value={name}
                    onChange={(e: any) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("성별")}</div>
                <div className="td">
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="sex_choice1"
                      name="s3"
                      checked={gender === 1}
                      onChange={e => setGender(e.target.checked ? 1 : 2)}
                    />
                    <span className="label_text">{intl.get("남자")}</span>
                  </label>
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="sex_choice2"
                      name="s3"
                      checked={gender === 2}
                      onChange={e => setGender(e.target.checked ? 2 : 1)}
                    />
                    <span className="label_text">{intl.get("여자")}</span>
                  </label>
                </div>
                <div className="th">{intl.get("휴대폰 번호")}</div>
                <div className="td">
                  <PhoneInput
                    type="text"
                    className="form_control"
                    placeholder={intl.get("휴대폰 번호를 입력하세요")}
                    value={phone}
                    onChange={(e: any) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">{intl.get("차량번호")}</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    value={carNumber}
                    onChange={e => e.target.value.length < 12 && setCarNumber(e.target.value)}
                    placeholder={intl.get("차량번호를 입력하세요")}
                  />
                </div>
                  <div className="th">{intl.get("식권 수 (장)")}</div>
                  <div className="td">
                    <input
                      type="text"
                      className="form_control"
                      placeholder={intl.get("신청하실 식권 수를 입력해주세요")}
                      disabled={props.workSite === WORK_SITE_CODE.울산 || props.workSite === WORK_SITE_CODE.아산공장? false : true}
                      value={meal_ticket_request_count}
                      onChange={(e: any) => {
                        setMeal_ticket_request_count(parseInt(e.target.value) || 0)
                      }}
                      maxLength={4}
                    />
                  </div>
              </div>
            </div>
          </div>
          <div className="tr group_agree">
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term1"
                  checked={privacyPolicyAgreed}
                  onChange={e =>
                    setPrivacyPolicyAgreed(e.target.checked)
                  }
                />
                <span className="label_text">{intl.get("서비스 이용약관 동의(필수)")}</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(1)}>{intl.get("자세히보기")}</button>
            </div>
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term2"
                  checked={personalInformationCollectionAgreed}
                  onChange={e =>
                    setPersonalInformationCollectionAgreed(
                      e.target.checked,
                    )
                  }
                />
                <span className="label_text">{intl.get("개인정보 이용 동의(필수)")}</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(2)}>{intl.get("자세히보기")}</button>
            </div>
          </div>
        </div>

        <ul className="lst_help bg_gray">
          <li>{intl.get("차량번호를 제외한 모든 입력 사항은 필수로 입력하셔야 합니다.")}</li>
        </ul>
      </div>

      <div className="submit">
        <ConfirmBtn
          className="btn btn_lg btn_outline"
          action={() => {store.roomInit(); props.history.replace('/home');}}
          label={intl.get("취소")}
          title=""
          msg={intl.get("이용 예약을 취소하시겠습니까?")}
        />

        <button className="btn btn_lg btn_darkblue" onClick={next}>
          {intl.get("다음")}
        </button>
      </div>

      {/*이용약관 자세히보기 팝업*/}
      <TermsDetail
        classNameBySelect="form_control"
        tabNumber={tabNumber}
        setTabNumber={setTabNumber}
        onCloseTermsDetailPopupHandler={onCloseTermsDetailPopupHandler}
      />
    </>
  );
});

export default Step1;
