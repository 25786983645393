import React from 'react';
import intl from 'react-intl-universal';

interface Props {
  value: string
  onChange: any
  disabled?: boolean
  name?: string
  guestType?: any
}


//회사 select
const SelectCompany = ({value, onChange, name, disabled, guestType}: Props) => {
  let defaultClass = "form_control"
  return (
    <select
      value={ value}
      className={defaultClass}
      onChange={onChange}
      name={name}
      disabled={disabled}
    >
      <option value="">{intl.get("회사명을 선택하세요")}</option>
      {guestType !== 2 ?
      <option value="현대자동차">현대자동차</option> : <></>
      }
      <option value="기아">기아</option>
      <option value="현대제철">현대제철</option>
      <option value="현대비앤지스틸">현대비앤지스틸</option>
      <option value="현대종합특수강">현대종합특수강</option>
      <option value="현대건설">현대건설</option>
      <option value="현대엔지니어링">현대엔지니어링</option>
      <option value="현대스틸산업">현대스틸산업</option>
      <option value="현대종합설계">현대종합설계</option>
      <option value="현대도시개발">현대도시개발</option>
      <option value="현대모비스">현대모비스</option>
      <option value="현대트랜시스">현대트랜시스</option>
      <option value="현대위아">현대위아</option>
      <option value="현대케피코">현대케피코</option>
      <option value="현대엠시트">현대엠시트</option>
      <option value="현대아이에이치엘">현대아이에이치엘</option>
      <option value="현대파텍스">현대파텍스</option>
      <option value="현대캐피탈">현대캐피탈</option>
      <option value="현대카드">현대카드</option>
      <option value="현대커머셜">현대커머셜</option>
      <option value="현대차증권">현대차증권</option>
      <option value="현대글로비스">현대글로비스</option>
      <option value="현대로템">현대로템</option>
      <option value="이노션 월드와이드">이노션 월드와이드</option>
      <option value="해비치호텔&리조트">해비치호텔&리조트</option>
      <option value="현대오토에버">현대오토에버</option>
      <option value="현대엔지비">현대엔지비</option>
      <option value="현대서산농장">현대서산농장</option>
      <option value="지아이티">지아이티</option>
      <option value="지마린서비스">지마린서비스</option>
      {/* <option value="현대다이모스"> 현대다이모스</option> */}
    </select>
  );
};

export default SelectCompany;
