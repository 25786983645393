import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';


import DefaultTemplate from '../../components/templetes/default';
import UlsanMainNoti from '../../components/organismas/popup/UlsanMainNoti';

import { VIP_USABLE_BY_RANKS, STATUS_CODE, ROOM_TYPE, LODGE_STATUS_CODE, ROOM_TYPE_CODE } from '../../constant';

import { MessageContext } from '../../routes';
import { faq, lodge } from '../../apis';

import './index.scss';
import _ from 'lodash';
import Notice from '../../apis/notice';
import Voc from '../../apis/voc';
import { SHOW_MODAL } from '../../store/modules/modal';
import { isMobile } from 'react-device-detect';

const backImgs = ["bg_01", "bg_02", "bg_03", "bg_04"];

const showFamilies = [2, 4];
const hideCompanyHouses = [2, 5, 6, 7];
const hideDomitoryHouses= [1, 2, 5, 6, 7];
const hideNamyang = [2];
// TODO 남양(2), 아산(4) -> 가족숙소 노출
// TODO 울산(1) -> 사택 비 노출


const lodgeStatus = {
  USEABLE: 1,
  APPLY: -1,
  NONE: 0,
};

const statusApply = [
  LODGE_STATUS_CODE.opened,
  LODGE_STATUS_CODE.waiting
];

const statusUseable = [
  LODGE_STATUS_CODE.checkin
]

const statusDisable = [
  LODGE_STATUS_CODE.checkout,
  LODGE_STATUS_CODE.terminated
]

const Home = (props: any) => {
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user,
  }));
  const { info, warning, error } = useContext(MessageContext);
  const [selectImgIdx, setSelectImgIdx] = useState<number>(0);
  const [prevImgIdx, setPrevImgIdx] = useState<number>(4);

  const [notLodgeds, setNotLodgeds] = useState<any[]>([]);
  const [lodgeds, setLodgeds] = useState<any[]>([]);
  const [checkInlodgeds, setCheckInlodgeds] = useState<any[]>([]);

  // faq start
  const [limit, setLimit] = useState<number>(3);
  const [offset, setOffset] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [category, setCategory] = useState<number>(-1);
  const [search, setSearch] = useState<string>('');
  const [created_after, setCreated_after] = useState<string>('');
  const [created_before, setCreated_before] = useState<string>('');
  const [items, setItems] = useState<any[]>([]);
  const [lists, setLists] = useState<any[]>([]);
  const [vocs, setVocs] = useState<any[]>([]);
  const [guestData, setGuestData] = useState<any>();
  const [reload, setReload] = useState<boolean>(false);
  const [showAppDownload, setShowAppDownload] = useState<boolean>(true);
  const [ulsanNoti, setUlsanNoti] = useState<boolean>(false);

  //faq end
  const isShowFamily = () => _.includes(showFamilies, userInfo.worksite);
    // && (userInfo.lodging && (userInfo.lodging.room_type === 2 && userInfo.lodging.stay_after));

  useEffect(() => {
    (async () => {
      const data = await fetchItems({limit, offset: 0 });
      const list = await fetchItems2({limit, offset: 0 });
      const voc = await fetchItems3({limit, offset: 0 });
      setItems(data.results || []);
      setLists(list.results || []);
      setVocs(voc.results || []);

      if(window.sessionStorage.getItem('fromLogin') === 'Y') {
        setUlsanNoti(true);
        window.sessionStorage.setItem('fromLogin','');
      }
    })();
  }, [category]);

  //faq
  const fetchItems = async (payload: any) => {
    try {
      const { data } = await faq.getFaqs(payload);
      return data;
    }catch ({ status, data }) {
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  };

  //공지사항
  const fetchItems2 = async (payload: any) => {
    try {
      const { data } = await Notice.getNotices(payload);
      return data;
    }catch ({ status, data }) {
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  };

  //voc
  const fetchItems3 = async (payload: any) => {
    try {
      const { data } = await Voc.getVocs(payload);
      return data;
    }catch ({ status, data }) {
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  };

  const getGuest = async() => {
    try {
      const { data } = await lodge.getGuest({}); // 이용중
      setNotLodgeds(data.filter((lodge: any) => statusDisable.includes(lodge.status)));       // 이용 X
      setLodgeds(data.filter((lodge: any) => statusApply.includes(lodge.status) && lodge.booking.applicant_employee_number === userInfo.employeenumber));            // 신청중
      setCheckInlodgeds(data.filter((lodge: any) => statusUseable.includes(lodge.status) ));   // 이용중
      setReload(true);

      
    }catch ({ status, data }) {
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  }

  useEffect(() => {
    getGuest();
  }, [reload])

  useEffect(() => {
    let intervalIdx = setInterval(() => {
      nextSlide(selectImgIdx + 1);
    }, 3500)
    return () => clearInterval(intervalIdx)
  })

  const nextSlide = (idx: number) => {
    let nextIdx = idx >= backImgs.length ? 0 : idx;
    setPrevImgIdx(selectImgIdx)
    setSelectImgIdx(nextIdx)
  }

  const onClosePopup = () => {
    setUlsanNoti(false);
  }; // 가입관련 문의 닫기
  
  window.addEventListener('popstate', function(event) { 
    window.history.pushState(null, document.title, props.history.location.href);
  });

  return (
    <>
    <script>
      
    </script>
    {userInfo.worksite === 1 && ulsanNoti ? (<UlsanMainNoti onClosePopup={onClosePopup} />):(<></>)}
    <DefaultTemplate
      className="wrap_main"
      id="wrap"
      leftInfo={<></>}
      {...props}
      disableFooter
    >
      <ul className="lst_bg">
        <li className={`${backImgs[prevImgIdx]} prev`}></li>
        <li className={`${backImgs[selectImgIdx]} on`}></li>
        {/* <li className={`bg_01 ${selectImgIdx === 1 ? "on": ""} ${prevImgIdx === 1 ? "prev": ""}`}></li>
            <li className={`bg_02 ${selectImgIdx === 2 ? "on": ""} ${prevImgIdx === 2 ? "prev": ""}`}></li>
            <li className={`bg_03 ${selectImgIdx === 3 ? "on": ""} ${prevImgIdx === 3 ? "prev": ""}`}></li>
            <li className={`bg_04 ${selectImgIdx === 4 ? "on": ""} ${prevImgIdx === 4 ? "prev": ""}`}></li> */}
      </ul>

      <ul className="lst_pagination">
        <li onClick={() => nextSlide(0)}><a href="javascript:void(0);" className={selectImgIdx === 0 ? "on" : ""}></a></li>
        <li onClick={() => nextSlide(1)}><a href="javascript:void(0);" className={selectImgIdx === 1 ? "on" : ""}></a></li>
        <li onClick={() => nextSlide(2)}><a href="javascript:void(0);" className={selectImgIdx === 2 ? "on" : ""}></a></li>
        <li onClick={() => nextSlide(3)}><a href="javascript:void(0);" className={selectImgIdx === 3 ? "on" : ""}></a></li>
      </ul>

      <ul className="lst_massage">
        <li className="on">
        {lodgeds.length > 0 ? (
            <>
              <p className="msg">숙소 이용<br />신청중입니다!</p>
              <Link to="/mypage/applicant" className="btn_info">신청관리 바로가기</Link>
            </>
          ) : (
            <>
            {checkInlodgeds.length > 0 ? (
            <>
              <p className="msg">{checkInlodgeds[0].room_work_site} {ROOM_TYPE[checkInlodgeds[0].room_type]}<br />이용중입니다!</p>
              <Link to="/mypage/user/info" className="btn_info">마이페이지 바로가기</Link>
            </>
            ) : (
            <>
              <p className="msg">숙소 이용을<br />계획 중이신가요?</p>
              <Link to={`/guide?roomType=${ROOM_TYPE_CODE.business_room}&workSite=${userInfo.worksite}`} className="btn_info">이용안내 바로가기</Link>
            </>
            )}
            </>
          )}
        </li>
      </ul>

      {/*<div className={showAppDownload ? "pop_app on" : "pop_app"}>
        <img src="/static/img/pc/pop_app.png" alt=""/>
        <a href="javascript:void(0);" className="btn_close" onClick={() => setShowAppDownload(false)}></a>
      </div>*/}

      {!lodgeds.length && !checkInlodgeds.length
            ? (
              // 신청중, 이용중 상태 없을 때
              <>
                <ul className="lst_service">
                  <li className="request">
                    <a href="javascript:void(0)" className="service" onClick={() => props.history.push(`/reservation/business/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)}>
                      <div className="title" >
                        <p className="en">Reservation</p>
                        <p className="ko">이용신청</p>
                      </div>
                    </a>
                    <div className="submenu">
                      <Link to={`/reservation/business/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`} className="title">이용신청</Link>
                      <ul className="lst_submenu">
                      <li
                        onClick={() => props.history.push(
                          `/reservation/business/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                        // (window.location.href = `/reservation/business/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                        }
                      >
                        <a href="javascript:void(0)">출장자숙소(개인)</a>
                      </li>
                      <li
                        onClick={() => props.history.push(
                          `/reservation/business/group?workSite=${userInfo.worksite }&ts=${new Date().getTime()}`)
                          //(window.location.href = `/reservation/business/group?workSite=${userInfo.worksite }&ts=${new Date().getTime()}`)
                        }
                      >
                        <a href="javascript:void(0)">출장자숙소(단체)</a>
                      </li>

                      {!_.includes(hideDomitoryHouses, userInfo.worksite) || userInfo.isMarried === false ? (
                        <li
                          onClick={() => props.history.push(
                            `/reservation/dormitory/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                            //(window.location.href =  `/reservation/dormitory/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                          }
                        >
                          <a href="javascript:void(0)">기숙사</a>
                        </li>
                      ) : (
                        <>
                        {userInfo.worksite === 3 || userInfo.worksite === 4? (
                          <li
                          onClick={() => props.history.push(
                            `/reservation/dormitory/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                            //(window.location.href =  `/reservation/dormitory/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                          }
                        >
                          <a href="javascript:void(0)">기숙사</a>
                        </li>
                        ) : (
                          <></>
                        )}
                        </>
                      )}


                      <li style={{
                        display: VIP_USABLE_BY_RANKS.includes(userInfo.rank)
                            ? 'block'
                            : 'none',
                        }}
                        onClick={() => props.history.push(
                          `/reservation/vip/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                          // (window.location.href = `/reservation/vip/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                        }
                        >
                        <a href="javascript:void(0)">영빈관</a>
                      </li>

                      {userInfo.worksite === 2 && userInfo.isMarried === true ? (
                      <li
                        onClick={() => {
                          props.history.push(`/reservation/companyhouse/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                          // (window.location.href = `/reservation/companyhouse/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                        }}
                      >
                        <a href="javascript:void(0)">사택</a>
                      </li>
                      ) : (
                      <>
                      {userInfo.worksite === 3 || userInfo.worksite === 4? (
                      <li
                        onClick={() => {
                          props.history.push(`/reservation/companyhouse/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                          // (window.location.href = `/reservation/companyhouse/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                        }}
                        >
                        <a href="javascript:void(0)">사택</a>
                      </li>
                      ) : (
                      <></>
                      )}
                      </>
                      )}

                    {/* {isShowFamily() ? (
                      <li
                        onClick={() => props.history.push(
                          `/reservation/familyhouse/group?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                          // (window.location.href = `/reservation/familyhouse/group?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                        }
                      >
                        <a href="javascript:void(0)">가족숙소</a>
                      </li>
                    ) : (<></>)} */}
                    {checkInlodgeds.length > 0? (
                    <>
                    {isShowFamily() ? (
                    <li
                      onClick={() => props.history.push(
                        `/guide?roomType=${ROOM_TYPE_CODE.family_room}&workSite=${userInfo.worksite}`)
                        // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.family_room}&workSite=${userInfo.worksite}`)
                      }
                    >
                      <a href="javascript:void(0)">R&D STAY</a>
                    </li>
                    ) : (<></>)}
                    </>
                    ) : (
                    <></>
                    )}
                    </ul>
                  </div>
                </li>
                <li className="guide">
                  <a href="javascript:void(0)" className="service" onClick={() => props.history.push(`/guide?roomType=${ROOM_TYPE_CODE.business_room}&workSite=${userInfo.worksite}`)}>
                    <div className="title" >
                      <p className="en">Guide</p>
                      <p className="ko">이용안내</p>
                    </div>
                  </a>
                  <div className="submenu">
                    <Link to={`/guide?roomType=${ROOM_TYPE_CODE.business_room}&workSite=${userInfo.worksite}`} className="title">이용안내</Link>
                    <ul className="lst_submenu">
                    <li
                      onClick={() => props.history.push(
                        `/guide?roomType=${ROOM_TYPE_CODE.business_room}&workSite=${userInfo.worksite}`)
                        // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.business_room}&workSite=${userInfo.worksite}`)
                      }
                    >
                      <a href="javascript:void(0)">출장자숙소(개인)</a>
                    </li>
                    <li
                      onClick={() => props.history.push(
                        `/guide?roomType=${ROOM_TYPE_CODE.business_room_group}&workSite=${userInfo.worksite}`)
                        // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.business_room_group}&workSite=${userInfo.worksite}`)
                      }
                    >
                      <a href="javascript:void(0)">출장자숙소(단체)</a>
                    </li>
                    {!_.includes(hideDomitoryHouses, userInfo.worksite) || userInfo.isMarried === false ? (
                    <li
                      onClick={() => props.history.push(
                        `/guide?roomType=${ROOM_TYPE_CODE.dormitory_room}&workSite=${userInfo.worksite}`)
                        // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.dormitory_room}&workSite=${userInfo.worksite}`)
                      }
                    >
                      <a href="javascript:void(0)">기숙사</a>
                    </li>
                    ) :(
                      <>
                      {userInfo.worksite === 3 || userInfo.worksite === 4? (
                        <li
                        onClick={() => props.history.push(
                          `/guide?roomType=${ROOM_TYPE_CODE.dormitory_room}&workSite=${userInfo.worksite}`)
                          // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.dormitory_room}&workSite=${userInfo.worksite}`)
                        }
                      >
                        <a href="javascript:void(0)">기숙사</a>
                      </li>
                      ) : (
                        <></>
                      )}
                      </>
                    )}
                    <li
                      style={{
                        display: VIP_USABLE_BY_RANKS.includes(userInfo.rank)
                          ? 'block'
                          : 'none',
                      }}
                      onClick={() => props.history.push(
                        `/guide?roomType=${ROOM_TYPE_CODE.vip_room}&workSite=${userInfo.worksite}`)
                        // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.vip_room}&workSite=${userInfo.worksite}`)
                      }
                    >
                      <a href="javascript:void(0)">영빈관</a>
                    </li>

                    {userInfo.worksite === 2 && userInfo.isMarried === true ? (
                    <li
                      onClick={() => props.history.push(
                        `/guide?roomType=${ROOM_TYPE_CODE.company_room}&workSite=${userInfo.worksite}`)
                        // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.company_room}&workSite=${userInfo.worksite}`)
                      }
                    >
                      <a href="javascript:void(0)">사택</a>
                    </li>
                    ) : (
                      <>
                      {userInfo.worksite === 3 || userInfo.worksite === 4? (
                        <li
                        onClick={() => props.history.push(
                          `/guide?roomType=${ROOM_TYPE_CODE.company_room}&workSite=${userInfo.worksite}`)
                          // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.company_room}&workSite=${userInfo.worksite}`)
                        }
                      >
                        <a href="javascript:void(0)">사택</a>
                      </li>
                      ) : (
                        <></>
                      )}
                      </>
                    )}

                    {/* {isShowFamily() ? (
                    <li
                      onClick={() => props.history.push(
                        `/guide?roomType=${ROOM_TYPE_CODE.family_room}&workSite=${userInfo.worksite}`)
                        // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.family_room}&workSite=${userInfo.worksite}`)
                      }
                    >
                      <a href="javascript:void(0)">가족숙소</a>
                    </li>
                    ) : (<></>)} */}
                    {checkInlodgeds.length > 0? (
                    <>
                    {isShowFamily() ? (
                    <li
                      onClick={() => props.history.push(
                        `/guide?roomType=${ROOM_TYPE_CODE.family_room}&workSite=${userInfo.worksite}`)
                        // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.family_room}&workSite=${userInfo.worksite}`)
                      }
                    >
                      <a href="javascript:void(0)">R&D STAY</a>
                    </li>
                    ) : (<></>)}
                    </>
                    ) : (
                    <></>
                    )}
                    </ul>
                  </div>
                </li>

                <li className="notice">
                  <a href="javascript:void(0)" className="service" onClick={() => props.history.push('/customer/notice')}>
                    <div className="title">
                      <p className="en">Notice</p>
                      <p className="ko">공지사항</p>
                    </div>
                  </a>
                  <div className="submenu">
                  <a href="javascript:void(0)" className="title" onClick={() => props.history.push('/customer/notice')}>공지사항</a>
                    <ul className="lst_notice">
                    {!lists.length ? (
                          <li>
                            <a href="javascript:void(0);">
                                데이터가 없습니다
                            </a>
                          </li>
                      ) : (
                        lists.map((item, index) =>(
                          <li
                            key={index}
                            onClick={() => props.history.push(`/customer/notice/detail`, item)}
                          >
                            <a href="javascript:void(0);">
                              {item.title}
                            </a>
                          </li>
                        ))
                      )}
                    </ul>
                  </div>
                </li>

                <li className="faq">
                  <a href="javascript:void(0)" className="service" onClick={() => props.history.push('/customer/faq')}>
                      <div className="title">
                          <p className="en">FAQ</p>
                          <p className="ko">자주하는 질문</p>
                      </div>
                  </a>
                  <div className="submenu">
                      <Link to="/customer/faq" className="title">자주하는 질문</Link>
                      <ul className="lst_notice">
                        {!items.length ? (
                          <li>
                          <a href="javascript:void(0);">
                              데이터가 없습니다
                          </a>
                        </li>
                      ) : (
                        items.map((item, index) =>(
                          <li
                            key={index}
                            onClick={() => props.history.push(`/customer/faq/detail`, item)}
                          >
                            <a href="javascript:void(0);">
                              {item.title}
                            </a>
                          </li>
                        ))
                          )}
                      </ul>
                  </div>
                </li>
              </ul>
              </>
            ) : lodgeds.length > 0
              ? (
                // 신청중상태
                <>
                  <ul className="lst_service">
                  <li className="request">
                    <a href="javascript:void(0)" className="service" onClick={() => props.history.push('/mypage/applicant')}>
                      <div className="title" >
                        <p className="en">Application management</p>
                        <p className="ko">신청관리</p>
                      </div>
                    </a>
                    <div className="submenu">
                      <Link to="/mypage/applicant" className="title">신청관리</Link>
                      <ul className="lst_submenu">
                      <li
                      onClick={() => props.history.push(
                        `/reservation/business/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                      // (window.location.href = `/reservation/business/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                      }
                    >
                      <a href="javascript:void(0)">출장자숙소(개인)</a>
                    </li>
                    <li
                      onClick={() => props.history.push(
                        `/reservation/business/group?workSite=${userInfo.worksite }&ts=${new Date().getTime()}`)
                        //(window.location.href = `/reservation/business/group?workSite=${userInfo.worksite }&ts=${new Date().getTime()}`)
                      }
                    >
                      <a href="javascript:void(0)">출장자숙소(단체)</a>
                    </li>
                    {!_.includes(hideDomitoryHouses, userInfo.worksite) || userInfo.isMarried === false ? (
                    <li
                      onClick={() =>{ 
                        props.history.push(`/reservation/dormitory/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                        //(window.location.href =  `/reservation/dormitory/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                      }}
                    >
                      <a href="javascript:void(0)">기숙사</a>
                    </li>
                    ):(
                      <>
                    {userInfo.worksite === 3 || userInfo.worksite === 4? (
                    <li
                      onClick={() =>{ 
                        props.history.push(`/reservation/dormitory/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                        //(window.location.href =  `/reservation/dormitory/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                      }}
                      >
                      <a href="javascript:void(0)">기숙사</a>
                    </li>
                    ) : (
                      <></>
                    )}
                      </>
                    )}
                    <li
                      style={{
                        display: VIP_USABLE_BY_RANKS.includes(userInfo.rank)
                          ? 'block'
                          : 'none',
                      }}
                      onClick={() => props.history.push(
                        `/reservation/vip/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                        // (window.location.href = `/reservation/vip/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                      }
                    >
                      <a href="javascript:void(0)">영빈관</a>
                    </li>
                    {userInfo.worksite === 2 && userInfo.isMarried === true ? (
                    <li
                      onClick={() => {
                        props.history.push(`/reservation/companyhouse/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                        // (window.location.href = `/reservation/companyhouse/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                      }}
                    >
                      <a href="javascript:void(0)">사택</a>
                    </li>
                    ) : (
                    <>
                    {userInfo.worksite === 3 || userInfo.worksite === 4? (
                    <li
                      onClick={() => {
                        props.history.push(`/reservation/companyhouse/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                        // (window.location.href = `/reservation/companyhouse/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                      }}
                      >
                      <a href="javascript:void(0)">사택</a>
                    </li>
                    ) : (
                    <></>
                    )}
                    </>
                    )}
                    {/* {isShowFamily() ? (
                      <li
                        onClick={() => props.history.push(
                          `/reservation/familyhouse/group?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                          // (window.location.href = `/reservation/familyhouse/group?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                        }
                      >
                        <a href="javascript:void(0)">가족숙소</a>
                      </li>
                    ) : (<></>)} */}
                    {checkInlodgeds.length > 0? (
                    <>
                    {isShowFamily() ? (
                    <li
                      onClick={() => props.history.push(
                        `/guide?roomType=${ROOM_TYPE_CODE.family_room}&workSite=${userInfo.worksite}`)
                        // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.family_room}&workSite=${userInfo.worksite}`)
                      }
                    >
                      <a href="javascript:void(0)">R&D STAY</a>
                    </li>
                    ) : (<></>)}
                    </>
                    ) : (
                    <></>
                    )}
                      </ul>
                    </div>
                  </li>
                  <li className="guide">
                    <a href="javascript:void(0)" className="service" onClick={() => props.history.push(`/guide?roomType=${ROOM_TYPE_CODE.business_room}&workSite=${userInfo.worksite}`)}>
                      <div className="title" >
                        <p className="en">Guide</p>
                        <p className="ko">이용안내</p>
                      </div>
                    </a>
                    <div className="submenu">
                      <Link to={`/guide?roomType=${ROOM_TYPE_CODE.business_room}&workSite=${userInfo.worksite}`} className="title">이용안내</Link>
                      <ul className="lst_submenu">
                      <li
                      onClick={() => props.history.push(
                        `/guide?roomType=${ROOM_TYPE_CODE.business_room}&workSite=${userInfo.worksite}`)
                        // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.business_room}&workSite=${userInfo.worksite}`)
                      }
                    >
                      <a href="javascript:void(0)">출장자숙소(개인)</a>
                    </li>
                    <li
                      onClick={() => props.history.push(
                        `/guide?roomType=${ROOM_TYPE_CODE.business_room_group}&workSite=${userInfo.worksite}`)
                        // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.business_room_group}&workSite=${userInfo.worksite}`)
                      }
                    >
                      <a href="javascript:void(0)">출장자숙소(단체)</a>
                    </li>
                    {!_.includes(hideDomitoryHouses, userInfo.worksite) || userInfo.isMarried === false ? (
                    <li
                      onClick={() => props.history.push(
                        `/guide?roomType=${ROOM_TYPE_CODE.dormitory_room}&workSite=${userInfo.worksite}`)
                        // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.dormitory_room}&workSite=${userInfo.worksite}`)
                      }
                    >
                      <a href="javascript:void(0)">기숙사</a>
                    </li>
                    ) :(
                      <>
                      {userInfo.worksite === 3 || userInfo.worksite === 4? (
                        <li
                        onClick={() => props.history.push(
                          `/guide?roomType=${ROOM_TYPE_CODE.dormitory_room}&workSite=${userInfo.worksite}`)
                          // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.dormitory_room}&workSite=${userInfo.worksite}`)
                        }
                      >
                        <a href="javascript:void(0)">기숙사</a>
                      </li>
                      ) : (
                        <></>
                      )}
                      </>
                    )}
                    <li
                      style={{
                        display: VIP_USABLE_BY_RANKS.includes(userInfo.rank)
                          ? 'block'
                          : 'none',
                      }}
                      onClick={() => props.history.push(
                        `/guide?roomType=${ROOM_TYPE_CODE.vip_room}&workSite=${userInfo.worksite}`)
                        // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.vip_room}&workSite=${userInfo.worksite}`)
                      }
                    >
                      <a href="javascript:void(0)">영빈관</a>
                    </li>
                    {userInfo.worksite === 2 && userInfo.isMarried === true ? (
                    <li
                      onClick={() => props.history.push(
                        `/guide?roomType=${ROOM_TYPE_CODE.company_room}&workSite=${userInfo.worksite}`)
                        // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.company_room}&workSite=${userInfo.worksite}`)
                      }
                    >
                      <a href="javascript:void(0)">사택</a>
                    </li>
                    ) : (
                      <>
                      {userInfo.worksite === 3 || userInfo.worksite === 4? (
                        <li
                        onClick={() => props.history.push(
                          `/guide?roomType=${ROOM_TYPE_CODE.company_room}&workSite=${userInfo.worksite}`)
                          // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.company_room}&workSite=${userInfo.worksite}`)
                        }
                      >
                        <a href="javascript:void(0)">사택</a>
                      </li>
                      ) : (
                        <></>
                      )}
                      </>
                    )}
                    {/* {isShowFamily() ? (
                    <li
                      onClick={() => props.history.push(
                        `/guide?roomType=${ROOM_TYPE_CODE.family_room}&workSite=${userInfo.worksite}`)
                        // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.family_room}&workSite=${userInfo.worksite}`)
                      }
                    >
                      <a href="javascript:void(0)">가족숙소</a>
                    </li>
                    ) : (<></>)} */}
                    {checkInlodgeds.length > 0? (
                    <>
                    {isShowFamily() ? (
                    <li
                      onClick={() => props.history.push(
                        `/guide?roomType=${ROOM_TYPE_CODE.family_room}&workSite=${userInfo.worksite}`)
                        // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.family_room}&workSite=${userInfo.worksite}`)
                      }
                    >
                      <a href="javascript:void(0)">R&D STAY</a>
                    </li>
                    ) : (<></>)}
                    </>
                    ) : (
                    <></>
                    )}
                      </ul>
                    </div>
                  </li>
                  
                  <li className="notice">
                    <a href="javascript:void(0)" className="service" onClick={() => props.history.push('/customer/notice')}>
                      <div className="title">
                        <p className="en">Notice</p>
                        <p className="ko">공지사항</p>
                      </div>
                    </a>
                    <div className="submenu">
                    <a href="javascript:void(0)" className="title" onClick={() => props.history.push('/customer/notice')}>공지사항</a>
                      <ul className="lst_notice">
                      {!lists.length ? (
                            <li>
                              <a href="javascript:void(0);">
                                  데이터가 없습니다
                              </a>
                            </li>
                        ) : (
                          lists.map((item, index) =>(
                            <li
                              key={index}
                              onClick={() => props.history.push(`/customer/notice/detail`, item)}
                            >
                              <a href="javascript:void(0);">
                                {item.title}
                              </a>
                            </li>
                          ))
                        )}
                      </ul>
                    </div>
                  </li>
                  
                  <li className="faq">
                    <a href="javascript:void(0)" className="service" onClick={() => props.history.push('/customer/faq')}>
                        <div className="title">
                            <p className="en">FAQ</p>
                            <p className="ko">자주하는 질문</p>
                        </div>
                    </a>
                    <div className="submenu">
                        <Link to="/customer/faq" className="title">자주하는 질문</Link>
                        <ul className="lst_notice">
                          {!items.length ? (
                            <li>
                                <a href="javascript:void(0);">
                                    데이터가 없습니다
                                </a>
                            </li>
                        ) : (
                          items.map((item, index) =>(
                            <li
                              key={index}
                              onClick={() => props.history.push(`/customer/faq/detail`, item)}
                            >
                              <a href="javascript:void(0);">
                                {item.title}
                              </a>
                            </li>
                            // <li><a href="javascript:void(0);"></a></li>
                            // <li><a href="javascript:void(0);"></a></li>
                          ))
                            )}
                        </ul>
                    </div>
                  </li>
                </ul>
                </>
              )
              : (
                // 이용중상태
                <>
                <ul className="lst_service">
                  <li className="notice">
                    <a href="javascript:void(0)" className="service" onClick={() => props.history.push('/customer/notice')}>
                      <div className="title">
                        <p className="en">Notice</p>
                        <p className="ko">공지사항</p>
                      </div>
                    </a>
                    <div className="submenu">
                    <a href="javascript:void(0)" className="title" onClick={() => props.history.push('/customer/notice')}>공지사항</a>
                      <ul className="lst_notice">
                      {!lists.length ? (
                            <li>
                            <a href="javascript:void(0);">
                                데이터가 없습니다
                            </a>
                          </li>
                        ) : (
                          lists.map((item, index) =>(
                            <li
                              key={index}
                              onClick={() => props.history.push(`/customer/notice/detail`, item)}
                            >
                              <a href="javascript:void(0);">
                                {item.title}
                              </a>
                            </li>
                          ))
                        )}
                      </ul>
                    </div>
                  </li>

                  <li className="voc">
                    <a href="javascript:void(0)" className="service" onClick={() => props.history.push('/customer/voc')}>
                      <div className="title">
                        <p className="en">Voc</p>
                        <p className="ko">Voc</p>
                      </div>
                    </a>
                    <div className="submenu">
                    <a href="javascript:void(0)" className="title" onClick={() => props.history.push('/customer/voc')}>Voc</a>
                      <ul className="lst_submenu">
                      {!vocs.length ? (
                            <li>
                              <a href="javascript:void(0);">
                                  데이터가 없습니다
                              </a>
                            </li>
                        ) : (
                          vocs.map((item, index) =>(
                            <li
                              key={index}
                              onClick={() => props.history.push(`/customer/voc/${item.id}`, item)}
                            >
                              <a href="javascript:void(0);">
                                {item.title}
                              </a>
                            </li>
                          ))
                        )}
                      </ul>
                    </div>
                  </li>
                  
                  {!isMobile? (
                    <li className="manager">
                        <a href="#" className="service" onClick={() => props.history.push('/mypage/sms')}>
                            <div className="title">
                                <p className="en">Alarm Management</p>
                                <p className="ko">알림관리</p>
                            </div>
                        </a>
                        <div className="submenu">
                            <a href="javascript:void(0);" className="title" onClick={() => props.history.push('/mypage/sms')}>알림관리</a>
                            <ul className="lst_submenu">
                                <li><a href="javascript:void(0);" onClick={() => props.history.push('/mypage/sms')}>알림관리</a></li>
                            </ul>
                        </div>
                    </li>
                  ) : (
                    <li className="faq">
                      <a href="javascript:void(0)" className="service" onClick={() => props.history.push('/customer/faq')}>
                          <div className="title">
                              <p className="en">FAQ</p>
                              <p className="ko">자주하는 질문</p>
                          </div>
                      </a>
                      <div className="submenu">
                          <Link to="/customer/faq" className="title">자주하는 질문</Link>
                          <ul className="lst_notice">
                            {!items.length ? (
                              <li>
                                  <a href="javascript:void(0);">
                                      데이터가 없습니다
                                  </a>
                              </li>
                          ) : (
                            items.map((item, index) =>(
                              <li
                                key={index}
                                onClick={() => props.history.push(`/customer/faq/detail`, item)}
                              >
                                <a href="javascript:void(0);">
                                  {item.title}
                                </a>
                              </li>
                              // <li><a href="javascript:void(0);"></a></li>
                              // <li><a href="javascript:void(0);"></a></li>
                            ))
                              )}
                          </ul>
                      </div>
                    </li>
                  )}

                    <li className="manage">
                        <a href="#" className="service" onClick={() => props.history.push('/mypage/guest')} >
                            <div className="title">
                                <p className="en">Use management</p>
                                <p className="ko">이용관리</p>
                            </div>
                        </a>
                        <div className="submenu">
                            <a href="javascript:void(0);" className="title" onClick={() => props.history.push('/mypage/guest')}>이용관리</a>
                            <ul className="lst_submenu">
                            {!checkInlodgeds.length ? (
                            <li>
                              <a href="javascript:void(0);">
                                  데이터가 없습니다
                              </a>
                            </li>
                            ) : (
                              checkInlodgeds.map((item, index) =>( 
                                <>
                                {item.status === LODGE_STATUS_CODE.checkin ? (
                                <li
                                  key={index}
                                  onClick={() => props.history.push(`/mypage/guest/detail`, item)}
                                >
                                  <a href="javascript:void(0);">
                                    {ROOM_TYPE[item.room_type]}
                                  </a>
                                </li>
                                ) : (
                                  <></>
                                )}
                                </>
                              ))
                            )}
                            </ul>
                        </div>
                    </li>
                </ul>
                </>
              )
          }
    </DefaultTemplate>
    </>
  )
};

export default Home;
