import { takeEvery, put, call } from 'redux-saga/effects';

import { USERINFO_REQUEST, USERINFO_COMPLETE } from '../modules/user';

import { auth } from '../../apis';
import { SHOW_MODAL } from '../modules/modal';

function* getUserInfo({ payload, history }: any) {
  // console.log('[SAGA] API CALL: getUserInfo');
  try {
    const { data } = yield call(auth.getProfile, payload);
    yield put({ type: USERINFO_COMPLETE, payload: data });
  } catch ({ status, data }) {
    if (status === 401) {
      window.location.href = '/force/signout';
      return ;
    } else if (status === 403) {
      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '권한오류',
          msg: '접근권한이 없습니다.',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }else if ( status === 500 ) {
      window.location.href = '/500';
      return;
    }
  }
}

function* userSaga() {
  yield takeEvery(USERINFO_REQUEST, getUserInfo);
}

export default userSaga;
