import { observer } from 'mobx-react';
import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { lodge } from '../../apis';
import ConfirmBtn from '../../components/atoms/button/confirm';
import Pagination from '../../components/organismas/pagination';
import MoreBtn from '../../components/organismas/pagination/more';
import DefaultTemplete from '../../components/templetes/listDefault';
import { LODGE_MOVE_CODE, LODGE_STATUS_CODE, MOVE_STATUS_CODE, ROOM_TYPE, ROOM_TYPE_CODE, WORK_SITE, WORK_SITE_CODE } from '../../constant';
import { ApiErrorHandleContext, MessageContext } from '../../routes';
import { useStores } from '../../store/mobx';
import { SHOW_MODAL } from '../../store/modules/modal';
import { getWorkSitePhoneNum } from '../../utils/commons';
import { formatByString_YYYYMMDD } from '../../utils/date';


const LodgeMove = observer((props: any) => {
    const { lodgedStore: store } = useStores();
    const { userInfo = {} } = useSelector((state: any) => ({
        userInfo: state.user,
    }));
    const { info } = useContext(MessageContext);
    const [reload, setReload] = useState<boolean>(false);
    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(10);
    const [totalPage, setTotalPage] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [listData, setListData] = useState([]);
    const [category, setCategory] = useState<number>(store.category);
    const [isRejectPop, setIsRejectPop] = useState<boolean>(false);
    const [rejectDescription, setRejectDescription] = useState<string>('');
    const [workSite, setWorkSite] = useState<number>(0);

    useEffect(() => {
        (async () => {
          window.history.pushState(null, document.title, props.history.location.href); 
          window.addEventListener('popstate', function(event) { 
              window.history.pushState(null, document.title, props.history.location.href); 
          });
        })();
      }, []);
    useEffect(() => {
        (async () => {
            let data: any = [];
            if (category === LODGE_MOVE_CODE.one) {
                data = await oneFetchData(offset, limit);
                if(data){
                    const count: number = data.count;
                    setTotalPage(Math.ceil(count / limit));
                    setListData(data.results);
                    setTotalCount(count);
                }
            } else {
                data = await fetchData(offset, limit);
                if(data){
                    const count: number = data.count;
                    setTotalPage(Math.ceil(count / limit));
                    setListData(data.results);
                    setTotalCount(count);
                }
            }
        })();
    }, [offset, reload, limit, category]);

    // 정기, 상시 data
    const fetchData = async (offset: number, limit: number) => {
        try {
            const payload: any = {
                offset,
                limit,
                category: category
            };

            const { data }: any = await lodge.lodgeMoveList(payload);
            return data;
        } catch (error) {
            //console.log(error);

            const { status, data } = error;

            if (status === 401) {
                window.location.href = '/force/signout';
                return ;
              } else if (status === 403) {
                (window as any).store.dispatch({
                  type: SHOW_MODAL,
                  payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                  },
                });
                return ;
              }else if ( status === 500 ) {
                window.location.href = '/500';
                return;
              }

              
              (window as any).store.dispatch({
                type: SHOW_MODAL,
                payload: {
                  title: '문제발생',
                  msg: '문제가 발생했습니다',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
        }
    }

    // 1:1 data
    const oneFetchData = async (offset: number, limit: number) => {
        try {
            // 이용중인 기숙사 list
            const guestPayload: any = {
                status: LODGE_STATUS_CODE.checkin,
                room_type: ROOM_TYPE_CODE.dormitory_room
            };

            const { data:guestList }: any = await lodge.getGuest(guestPayload);

            // 1:1 이숙 리스트
            if(guestList && guestList.length > 0){
                setWorkSite(WORK_SITE_CODE[guestList[0].room_work_site]);
                
                const payload: any = {
                    offset,
                    limit,
                    employee_number: userInfo.employeenumber,
                    room_work_site: guestList[0].room_work_site
                };
    
                const { data }: any = await lodge.lodgeOneToOneMoveList(payload);
                return data;
            }
        } catch (error) {
            //console.log(error);

            const {status, data} = error;

            if (status === 401) {
                window.location.href = '/force/signout';
                return ;
              } else if (status === 403) {
                (window as any).store.dispatch({
                  type: SHOW_MODAL,
                  payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                  },
                });
                return ;
              }else if ( status === 500 ) {
                window.location.href = '/500';
                return;
              }

              
              (window as any).store.dispatch({
                type: SHOW_MODAL,
                payload: {
                  title: '문제발생',
                  msg: '문제가 발생했습니다',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
        }
    }

    // 정기 / 상시일경우, 이숙 코드
    const getMoveStatus = (status: number, des: string) => {
        if (status === MOVE_STATUS_CODE.apply) {
            return <span className="state_room case2">승인대기</span>
        } else if (status === MOVE_STATUS_CODE.approve) {
            return <span className="state_room case4">승인완료</span>
        } else if (status === MOVE_STATUS_CODE.complete) {
            return <span className="state_room case4">이숙완료</span>
        } else if (status === MOVE_STATUS_CODE.reject) {
            return (
                <>
                    <span className="state_room case1">승인거절</span>
                    <div className="right_area">
                        <button className="btn btn_sm btn_outline" onClick={() => { setRejectDescription(des); setIsRejectPop(true) }}>거절사유</button>
                    </div>
                </>
            )
        } else if (status === MOVE_STATUS_CODE.cancel) {
            return <span className="state_room case1">신청취소</span>
        }
    }

    // 1:1 이숙의 경우, 상태표시
    const getOneToOneStatus = (item: any, des: string) => {
        if (item.status === MOVE_STATUS_CODE.cancel) {       // 신청취소
            return <span className="state_room case1">신청취소</span>
        } else if (item.status === MOVE_STATUS_CODE.resApprove) {
            return <span className="state_room case2">관리자 승인대기</span>
        } else if (item.status === MOVE_STATUS_CODE.reject) {
            return (
                <>
                    <span className="state_room case1">관리자 승인거절</span>
                    <div className="right_area">
                        <button className="btn btn_sm btn_outline" onClick={() => { setRejectDescription(des); setIsRejectPop(true) }}>거절사유</button>
                    </div>
                </>
            )
        } else if (item.status === MOVE_STATUS_CODE.approve) {
            return <span className="state_room case2">승인완료</span>
        } else if (item.status === MOVE_STATUS_CODE.complete) {
            return <span className="state_room case4">이숙완료</span>
        }

        if (item.guest_employee_number1 === userInfo.employeenumber) {       // 본인이 신청자인 경우
            if (item.status === MOVE_STATUS_CODE.apply) {
                return <span className="state_room case2">응답자 승인대기</span>
            } else if (item.status === MOVE_STATUS_CODE.resReject) {
                return <span className="state_room case1">응답자 승인거절</span>
            }
        } else if (item.guest_employee_number2 === userInfo.employeenumber) {     // 응답자인 경우
            if (item.status === MOVE_STATUS_CODE.apply) {
                return (
                    <>
                        <span className="state_room case2">신청자 승인요청</span>
                        <div className="right_area">
                            <ConfirmBtn
                                action={() => resApprove(item.id, item.guest_cellphone1, item.guest_cellphone2)}
                                label={`승인`}
                                title=""
                                msg={`이숙신청을 승인하시겠습니까?`}
                                className="btn btn_sm btn_outline"
                            />
                            <ConfirmBtn
                                action={() => resReject(item.id, item.guest_cellphone1, item.guest_cellphone2)}
                                label={`거절`}
                                title=""
                                msg={`이숙신청을 거절하시겠습니까?`}
                                className="btn btn_sm btn_outline"
                            />
                        </div>
                    </>
                )
            } else if (item.status === MOVE_STATUS_CODE.resReject) {
                return <span className="state_room case1">승인거절</span>
            }
        }
        return ''
    }

    // 1:1 이숙의 경우, 상태표시
    const getOneToOneStatusMobile = (item: any, des: string) => {
        if (item.status === MOVE_STATUS_CODE.cancel) {       // 신청취소
            return <span className="state_room case1">신청취소</span>
        } else if (item.status === MOVE_STATUS_CODE.resApprove) {
            return <span className="state_room case2">관리자 승인대기</span>
        } else if (item.status === MOVE_STATUS_CODE.reject) {
            return (
                <>
                    <span className="state_room case1">관리자 승인거절</span>
                    <div className="right_area">
                        <button className="btn btn_sm btn_outline" onClick={() => { setRejectDescription(des); setIsRejectPop(true) }}>거절사유</button>
                    </div>
                </>
            )
        } else if (item.status === MOVE_STATUS_CODE.approve) {
            return <span className="state_room case2">승인완료</span>
        } else if (item.status === MOVE_STATUS_CODE.complete) {
            return <span className="state_room case4">이숙완료</span>
        }

        if (item.guest_employee_number1 === userInfo.employeenumber) {       // 본인이 신청자인 경우
            if (item.status === MOVE_STATUS_CODE.apply) {
                return <span className="state_room case2">응답자 승인대기</span>
            } else if (item.status === MOVE_STATUS_CODE.resReject) {
                return <span className="state_room case1">응답자 승인거절</span>
            }
        } else if (item.guest_employee_number2 === userInfo.employeenumber) {     // 응답자인 경우
            if (item.status === MOVE_STATUS_CODE.apply) {
                return (
                    <>
                        <span className="state_room case2">신청자 승인요청</span>
                        <div className="right_area mt10">
                            <ConfirmBtn
                                action={() => resApprove(item.id, item.guest_cellphone1, item.guest_cellphone2)}
                                label={`승인`}
                                title=""
                                msg={`이숙신청을 승인하시겠습니까?`}
                                className="btn btn_sm btn_outline"
                            />
                            <ConfirmBtn
                                action={() => resReject(item.id, item.guest_cellphone1, item.guest_cellphone2)}
                                label={`거절`}
                                title=""
                                msg={`이숙신청을 거절하시겠습니까?`}
                                className="btn btn_sm btn_outline"
                            />
                        </div>
                    </>
                )
            } else if (item.status === MOVE_STATUS_CODE.resReject) {
                return <span className="state_room case1">승인거절</span>
            }
        }
        return ''
    }

    // 1:1 응답자 승인
    const resApprove = async (id: number, phone1: string, phone2: string) => {
        try {
            const payload: any = {
                status: MOVE_STATUS_CODE.resApprove
            }
            await lodge.lodgeMoveChangeStatus(id, payload);
            info("승인이 완료되었습니다.");

            // 문자 발송
            let smsPayload: any = {
            cellphone : [phone1, phone2].join(','),
            message : '이숙대상이 이숙신청을 승인 되었습니다. \n관리자 승인 후 이숙이 완료됩니다.' 
                    +'\n신청진행 확인은 "마이페이지>>이숙관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 '+getWorkSitePhoneNum(workSite, ROOM_TYPE_CODE.dormitory_room)
            }
            const { data: sms } = await lodge.sendSMS(smsPayload);
            
            setReload(!reload);
        } catch (error) {
            //console.log(error);

            const {status, data} = error;

            if (status === 401) {
                window.location.href = '/force/signout';
                return ;
              } else if (status === 403) {
                (window as any).store.dispatch({
                  type: SHOW_MODAL,
                  payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                  },
                });
                return ;
              }else if ( status === 500 ) {
                window.location.href = '/500';
                return;
              }
        

            info('승인 중 문제가 발생하였습니다.');
            return;
        }
    }

    // 1:1 응답자 승인 거절
    const resReject = async (id: number, phone1: string, phone2: string) => {
        try {
            const payload: any = {
                status: MOVE_STATUS_CODE.resReject
            }
            await lodge.lodgeMoveChangeStatus(id, payload);
            info("승인이 거절되었습니다.");

            // 문자 발송
            let smsPayload: any = {
                cellphone : [phone1, phone2].join(','),
                message : '이숙대상이 이숙신청을 거절 되었습니다.' 
                        +'\n신청진행 확인은 "마이페이지>>이숙관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 '+getWorkSitePhoneNum(workSite, ROOM_TYPE_CODE.dormitory_room)
            }
            const { data: sms } = await lodge.sendSMS(smsPayload);

            setReload(!reload);
        } catch (error) {
            //console.log(error);

            const { status, data } = error;

            if (status === 401) {
                window.location.href = '/force/signout';
                return ;
              } else if (status === 403) {
                (window as any).store.dispatch({
                  type: SHOW_MODAL,
                  payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                  },
                });
                return ;
              }else if ( status === 500 ) {
                window.location.href = '/500';
                return;
              }
        
            info('승인거절 중 문제가 발생하였습니다.');
            return;
        }
    }

    // 팝업 초기화
    const popInit = () => {
        setRejectDescription('');
    }

    return (
        <DefaultTemplete
            leftInfo={
                <>
                    <h2>이숙관리</h2>
                    <p className="desc">기숙사 이숙신청 및 현황을 확인하실 수 있습니다</p>
                    <div className="bg_img change"></div>
                </>
            }
            id="wrap"
            className="wrap_mypage_manage"
            {...props}
        >
            <div className="group_tab">
                <div className="tabs_area">
                    <ul className="tabs">
                        <li>
                            <a href="javascript:void(0);" className={category === LODGE_MOVE_CODE.alway ? 'on' : ''}
                                onClick={() => {
                                    setOffset(0);
                                    setLimit(10);
                                    store._category = LODGE_MOVE_CODE.alway;
                                    setCategory(LODGE_MOVE_CODE.alway);
                                }}>
                                상시이숙
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" className={category === LODGE_MOVE_CODE.one ? 'on' : ''}
                                onClick={() => {
                                    setOffset(0);
                                    setLimit(10);
                                    store._category = LODGE_MOVE_CODE.one;
                                    setCategory(LODGE_MOVE_CODE.one);
                                }}>
                                1:1 이숙
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" className={category === LODGE_MOVE_CODE.fixed ? 'on' : ''}
                                onClick={() => {
                                    setOffset(0);
                                    setLimit(10);
                                    store._category = LODGE_MOVE_CODE.fixed;
                                    setCategory(LODGE_MOVE_CODE.fixed);
                                }}>
                                정기이숙
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            {/* <div className="title_contents">
                    <h3 className="title">기본 정보</h3>
                </div>
                <ul className="lst_status">
                    <li><strong>숙소유형</strong><span>기숙사</span></li>
                    <li><strong>이용사업장</strong><span>남양</span></li>
                    <li><strong>입숙일</strong><span>2020.09.15</span></li>
                    <li><strong>입숙만료일</strong><span>2020.09.15</span></li>
                    <li><strong>배방</strong><span>102동 519호</span></li>
                </ul> */}

            <div className="title_contents">
                <h3 className="title">신청 리스트</h3>
            </div>

            <div className="pc_view">
                <div className="table_normal_list mt20">
                    <table>
                        <caption className="sr_only">이숙신청 목록</caption>
                        <colgroup>
                            <col width="25%" />
                            <col width="15%" />
                            <col width="15%" />
                            <col width="15%" />
                            <col width="*" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>이숙희망숙소</th>
                                <th>신청일</th>
                                <th>이숙요청일</th>
                                <th>이숙확정일</th>
                                <th>진행상태</th>
                            </tr>
                        </thead>
                        <tbody>
                            {category !== LODGE_MOVE_CODE.one ?
                                listData.length === 0 ? (
                                    <tr>
                                        <td colSpan={5} className="blank_area type_white">
                                            <p className="txt_blank">데이터가 없습니다.</p>
                                        </td>
                                    </tr>
                                ) : (
                                    listData.map((data: any, idx: number) => (
                                        <tr className="" key={idx}>
                                            <td className="" style={{ 'cursor': 'pointer' }} onClick={() => { props.history.push('/mypage/move/detail', data); }}>{data.desired_room}</td>
                                            <td className="" style={{ 'cursor': 'pointer' }} onClick={() => { props.history.push('/mypage/move/detail', data); }}>{data.created_at ? formatByString_YYYYMMDD(data.created_at) : ''}</td>
                                            <td className="" style={{ 'cursor': 'pointer' }} onClick={() => { props.history.push('/mypage/move/detail', data); }}>{data.moved_at}</td>
                                            <td className="" style={{ 'cursor': 'pointer' }} onClick={() => { props.history.push('/mypage/move/detail', data); }}>{data.managed_at ? data.managed_at : '-'}</td>
                                            <td className="">
                                                {getMoveStatus(data.status, data.status_description)}
                                            </td>
                                        </tr>
                                    ))
                                ) :
                                listData.length === 0 ? (
                                    <tr>
                                        <td colSpan={5} className="blank_area type_white">
                                            <p className="txt_blank">데이터가 없습니다.</p>
                                        </td>
                                    </tr>
                                ) : (
                                    listData.map((data: any, idx: number) => ( // 1:1이숙의 경우
                                        <tr className="" key={idx}>
                                            <td className="" style={{ 'cursor': 'pointer' }} onClick={() => { props.history.push('/mypage/move/reqdetail', data); }}>
                                                {data.guest_employee_number2 === userInfo.employeenumber ? (     // 응답자일 경우, 방 정보 노출
                                                    data.room_building_name1 + data.room_building_dong1 + data.room_name1 + '호'
                                                ) :
                                                    data.status === MOVE_STATUS_CODE.complete ? (    // 신청자는 완료상태일때 노출
                                                        data.room_building_name2 + data.room_building_dong2 + data.room_name2 + '호'
                                                    ) : ''}
                                            </td>
                                            <td className="" style={{ 'cursor': 'pointer' }} onClick={() => { props.history.push('/mypage/move/reqdetail', data); }}>{data.created_at ? formatByString_YYYYMMDD(data.created_at) : ''}</td>
                                            <td className="" style={{ 'cursor': 'pointer' }} onClick={() => { props.history.push('/mypage/move/reqdetail', data); }}>{data.moved_at}</td>
                                            <td className="" style={{ 'cursor': 'pointer' }} onClick={() => { props.history.push('/mypage/move/reqdetail', data); }}>{data.managed_at ? data.managed_at : '-'}</td>
                                            <td className="">
                                                {getOneToOneStatus(data, data.status_description)}
                                            </td>
                                        </tr>
                                    ))
                                )}
                        </tbody>
                    </table>
                </div>  {/*table*/}
                <div className="board_paging">
                    <ul>
                        <Pagination
                            offset={offset}
                            limit={limit}
                            setOffset={setOffset}
                            totalPage={totalPage}
                        />
                    </ul>
                </div>  {/*board_paging*/}
            </div> {/* pc_view */}

            <div className="mb_view">
                {listData.length === 0 ? (
                    <div className="blank_area">
                        <p className="txt_blank">데이터가 없습니다.</p>
                    </div>
                ):(
                    <div className="lst_apply">
                        {listData.map((data: any, idx: number) => (
                            <>
                                <div className="sub_title">
                                    <p>
                                        {category !== LODGE_MOVE_CODE.one ? data.desired_room
                                            : (
                                                data.guest_employee_number2 === userInfo.employeenumber ? (     // 응답자일 경우, 방 정보 노출
                                                    data.room_building_name1 + data.room_building_dong1 + data.room_name1 + '호'
                                                ) :
                                                    data.status === MOVE_STATUS_CODE.complete ? (    // 신청자는 완료상태일때 노출
                                                        data.room_building_name2 + data.room_building_dong2 + data.room_name2 + '호'
                                                    ) : ''
                                            )
                                        }
                                    </p>
                                    <a href="javascript:void(0);"
                                        className="btn_detail"
                                        onClick={() => {
                                            if (category !== LODGE_MOVE_CODE.one) {
                                                props.history.push('/mypage/move/detail', data);
                                            } else {
                                                props.history.push('/mypage/move/reqdetail', data);
                                            }
                                        }}
                                    >
                                        상세보기
                                    </a>
                                </div>

                                <ul className="lst_item">
                                    <li>
                                        <p>이숙요청일</p>
                                        <p>{data.moved_at}</p>
                                    </li>
                                    <li>
                                        <p>신청일</p>
                                        <p>{data.created_at ? formatByString_YYYYMMDD(data.created_at) : ''}</p>
                                    </li>
                                    <li>
                                        <p>이숙확정일</p>
                                        <p>{data.managed_at ? data.managed_at : '-'}</p>
                                    </li>
                                    <li>
                                        <p>진행상태</p>
                                        <p>{getOneToOneStatusMobile(data, data.status_description)}</p>
                                    </li>
                                </ul>
                            </>
                        ))}
                    </div>
                )}

                <div className="more_area">
                    <MoreBtn
                        offset={offset}
                        limit={limit}
                        setLimit={setLimit}
                        totalCount={totalCount}
                    /*fetchData={fetchData}
                    data={listData}
                    onClick={(reqCheckout: any) => {
                        console.log(reqCheckout)
                        setListData(listData.concat(reqCheckout))
                    }}*/
                    />
                </div>
            </div>

            {/* 반려사유 팝업 */}
            <div id="pop_request_refuse" className="pop_dim_wrap pop_modal pop_sm" style={{ display: isRejectPop ? 'block' : 'none' }}>
                <div className="pop_wrap">
                    <div className="title_wrap">
                        <h3>승인거절사유</h3>
                        <button className="pop_btn_close pop_close" onClick={() => { setIsRejectPop(false); popInit(); }}>닫기</button>
                    </div>
                    <div className="contents_wrap">
                        <div className="process_list">
                            <dl>
                                <dt>거절사유</dt>
                                <dd>
                                    <textarea className="form_control" readOnly value={rejectDescription}></textarea>
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div className="button_wrap btn_length_1">
                        <button type="button" className="btn btn_darkblue pop_close" onClick={() => { setIsRejectPop(false); popInit(); }}>확인</button>
                    </div>
                </div>
            </div>
        </DefaultTemplete>
    );
});

export default LodgeMove;
