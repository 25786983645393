import React from 'react';
import Rooms from './room';
import intl from 'react-intl-universal';

import { Room, Floor } from './index.d'

interface Props {
  floors: Floor[]

  onRoomClickHandlerToSelect: Function
  onRoomClickHandlerToUnSelect: Function
  isDisable: Function
  isSelect: Function
  selectFloor: number
  floorHead?: string
  detail?: Function
  isGuide?: boolean
}

//배방 한줄
const Floors = ({
  floors,
  selectFloor,
  detail,
  floorHead,
  onRoomClickHandlerToSelect,
  onRoomClickHandlerToUnSelect,
  isGuide,
  isDisable, 
  isSelect, 
}: Props): any  => {
  
  //const isSelectFloor = (selectFloor === -1) || (selectFloor <= (floors.length - 1));
  /*floors = (selectFloor === -1) ? floors : 
          (selectFloor !== -1) || (selectFloor <= (floors.length - 1)) ? [floors[selectFloor]] : []; */
  
  const isAllSelectFloor = (selectFloor === -1) || (selectFloor > (floors.length - 1) );
  floors = isAllSelectFloor ? floors : [floors[selectFloor]];

  return (
    <React.Fragment>
      {floorHead}
      {isGuide
        ? (
          <dt className="">
          <ul className="lst_info clearfix">
            <li>{intl.get("이용 가능")}</li>
            <li>{intl.get("이용 불가능")}</li>
          </ul>
          </dt>
        )
        : <></>
      }
      <dd>
      {floors.map((floor: any, idx: number) =>
          floor.rooms.length ? (
            <ul className="lst_room clearfix">
              {floor.rooms.map((room: any, idx: number) => (
                <Rooms
                  room={room}
                  onRoomClickHandlerToSelect={onRoomClickHandlerToSelect}
                  onClickonRoomClickHandlerToUnSelect={onRoomClickHandlerToUnSelect}
                  key={idx}
                  isDisable={isDisable}
                  isSelect={isSelect}
                  detail={detail}
                />
              ))}
            </ul>
          ) : (
            <></>
          )
      )}
      </dd>
    </React.Fragment>
    );
  };

export default Floors;
