const initialState: any = {
  id: 0,
  username: '',
  employeenumber: '',
  gender: 0, // 남자(1)/여자(2)
  phone: '',
  email: '',
  department: '',
  position: '',
  worksite: 0,
  isMarried: false,
  birthdate: '',
  occupation: '',
  party: '',
  rank: '',
  is_naver_connected: false,
  is_kakao_connected: false,
  is_google_connected: false,
};

export const USERINFO_REQUEST = 'USER/USERINFO_REQUEST'; // 유저정보 요청
export const USERINFO_COMPLETE = 'USER/USERINFO_COMPLETE'; // 유저정보 요청성공
export const USERINFO_FAIL = 'USER/USERINFO_FAIL'; // 유저정보 요청실패

export const USERINFO_CLEAR = 'USER/USERINFO_CLEAR';

type UserAction = any;

function userReducer(state: any = initialState, action: UserAction) {
  switch (action.type) {
    case USERINFO_COMPLETE:
      const { payload } = action;
      // console.log('USERINFO_COMPLETE', payload);
      return {
        ...state,
        username: payload.name,
        employeenumber: payload.employee_number,
        gender: payload.personnel.gender,
        phone: payload.cellphone,
        email: payload.email,
        department: payload.personnel.department,
        position: payload.personnel.position,
        worksite: payload.work_site,
        isMarried: payload.personnel.is_married,
        birthdate: payload.personnel.birthdate,
        occupation: payload.personnel.occupation,
        party: payload.personnel.party,
        rank: payload.personnel.rank,
        joined_at: payload.personnel.joined_at,
        working_period:payload.personnel.working_period,
        zipcode:payload.personnel.zipcode1,
        zipcode2:payload.personnel.zipcode2,
        address:payload.personnel.address1,
        address2:payload.personnel.address2,
        lodging: payload.lodging,
        loginHistory: payload.last_login_history
      };

    case USERINFO_CLEAR:
      return {
        ...state,
        id: 0,
        username: '',
        employeenumber: '',
        gender: 0, // 남자(1)/여자(2)
        phone: '',
        email: '',
        department: '',
        position: '',
        worksite: 0,
        isMarried: false,
        birthdate: '',
        occupation: '',
        party: '',
        rank: '',
        is_naver_connected: false,
        is_kakao_connected: false,
        is_google_connected: false,
        loginHistory: ''
      };
    default:
      return state;
  }
}

export default userReducer;
