import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { MessageContext, ApiErrorHandleContext, ApiErrorHandleImpl, MessageImpl } from '../../routes';

import intl from 'react-intl-universal';

import StepNav from '../../components/molecules/reservationStepNav';
import DefaultTemplate from '../../components/templetes/default';
import { onShowModal, SHOW_MODAL } from '../../store/modules/modal';

import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

import { ROOM_TYPE_CODE, WORK_SITE } from '../../constant';

import { lodge } from '../../apis';

import './index.scss';
import { observer } from 'mobx-react';
import { useStores } from '../../store/mobx';
import { getWorkSitePhoneNum } from '../../utils/commons';

const CompanyHousePage = observer((props: any) => {
  const {reservationStore: store} = useStores();
  const { info }: MessageImpl = useContext(MessageContext);
  const { errorHandler }: ApiErrorHandleImpl = useContext(ApiErrorHandleContext);

  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user,
  }));

  const [step, setStep] = useState<number>(1);
  const [building, setBuilding] = useState({});
  const [room, setRoom] = useState<any>({});
  const [roomType, setRoomType] = useState<number>(ROOM_TYPE_CODE.company_room); // 출장자 숙소(1), 영빈관(2), 기숙사 숙소(3)
  const [workSite, setWorkSite] = useState(-1); // 울산(1), 남양(2),  아산(3), 전주(4)
  const [users, setUsers] = useState<any>([]); // 이용자가 단체인 것까지 고려하여 리스트로 관리한다
  const [files, setFiles] = useState([]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (userInfo.worksite) {
      setWorkSite(userInfo.worksite === 6 ? 2:userInfo.worksite);
      if(userInfo.worksite === 1) {
        dispatch(onShowModal({
          msg: '울산의 경우 사택 신청이 불가합니다.',
          redirectPath: '/home'
        }))
        return
      }
    }
  }, [userInfo])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const onSubmitHandler = async (e: any) => {
    let isFileUploaded = files.filter((file: any) => file.id < 1 && file.is_required)

    if (isFileUploaded.length) {
      info(intl.get("필수항목을 업로드하지 않았습니다."))
      return
    }

    const payload: any = {
      work_site: workSite,
      // mealTicket,

      // 실제 이용자
      files: files.map((file: any) => ({ file: file.id, document: file.document })).filter((file: any) => file.file !== -1),
      // guest: { ...users[0] },
      // 신청자 정보는 서버에서 accessToken을 가지고 얻을 수 있기 때문에 전달할 필요없음.

      guest_type: users[0].type,
      guest_name: users[0].name,
      guest_gender: users[0].gender,
      guest_employee_number: users[0].employee_number,
      guest_cellphone: users[0].cellphone,
      guest_department: users[0].department,
      guest_car_number1 : users[0].car_number1 ? users[0].car_number1 : '',
      guest_car_number2 : users[0].car_number2 ? users[0].car_number2 : '',
      guest_car_number3 : users[0].car_number3 ? users[0].car_number3 : '',
      is_cc: users[0].is_cc,
      is_supported: users[0].is_supported,
      privacyAgree: users[0].privacyAgree,
      personalAgree: users[0].personalAgree,
      checkin_hope: users[0].checkin_hope? users[0].checkin_hope : '',
      remainder : users[0].remainder ? users[0].remainder : ''
    };

    try {
      let { data } = await lodge.reservationByCompanyHouse(payload);
      let temp = users.map((user: any, idx:number) => {
        if(data.remainder) {
          user['remainder'] = data.remainder.split(',')[0].split(' ')[0] + '일';
        }
        return user;
      });
      setUsers(temp);
      store.roomInit();

      // 예약 완료 문자 발송
      let smsPayload: any = {
        cellphone : users[0].cellphone,
        message: '입숙신청되었습니다. \n신청정보 - 숙소유형 : 사택 \n- 이용 사업장 : '+ WORK_SITE[workSite] 
                +'\n신청진행 확인은 "마이페이지>>신청관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 '+getWorkSitePhoneNum(workSite, ROOM_TYPE_CODE.company_room)
      }
      const { data: sms } = await lodge.sendSMS(smsPayload);

      setStep(3);
    } catch ({ status, data }) {
      // console.log(status, data)
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else if (data.code === "already_exists") {
        info(intl.get('이미 이용중인 숙소가 있습니다.\n 퇴숙완료 후 신청 가능합니다.\n 신청관리 화면에서 확인해주세요.'));
        return;
      }else if (data.guest) {
        if (data.guest.employee_number && data.guest.employee_number.length) {
          info(intl.get('사번이 올바르지 않습니다.'));
          return;
        } else if (data.guest.cellphone && data.guest.cellphone.length) {
          info(intl.get('올바른 핸드폰 번호를 입력해 주세요.'));
          return;
        }
        info(intl.get('게스트정보가 올바르지 않습니다.'));
        return;
      }
      
      errorHandler({ status, data }, intl.get("예약 중 문제발생"));
      return;
    }
  };

  return (
    <DefaultTemplate
      leftInfo={
        <>
          <h2>{intl.get("사택")}</h2>
          <h4 className="title_step">{intl.get("신청단계")}</h4>
          <StepNav.CompanyHouseSingleNav step={step} />
        </>
      }
      className={`wrap_request request_step${step}`}
      id="wrap"
      {...props}
    >
      {step === 1 ? (
        <Step1
          roomType={roomType}
          workSite={workSite}
          setWorkSite={setWorkSite}
          building={building}
          nextStep={() => setStep(2)}
          room={room}
          setUsers={setUsers}
          history={props.history}
          files={files}
          setFiles={setFiles}
        />
      ) : step === 2 ? (
        <Step2
          user={users[0]}
          roomType={roomType}
          workSite={workSite}
          building={building}
          room={room}
          guest={users[0]}
          prevStep={() => setStep(1)}
          nextStep={() => setStep(3)}
          onSubmitHandler={onSubmitHandler}
          history={props.history}
        />
      ) : step === 3 ? (
        <Step3
          roomType={roomType}
          workSite={workSite}
          building={building}
          room={room}
          users={users}
          history={props.history}
        />
      ) : (
        <></>
      )}
    </DefaultTemplate>
  )
});

export default CompanyHousePage;


