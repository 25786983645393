import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;

class Notice {
  static URL = `${IP}`;

  /**
   * FAQ 내역 조회
   * @param payload
   */
  @apiHandler()
  static async getNotices(payload: any) {
    const PATH = `/member/board/notice/`;

    return await axios.get(`${Notice.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
}

export default Notice;
