import React from 'react';
import intl from 'react-intl-universal';

//건물명 select
const GroupBuildings = (props: any) => {
  
  return (
    <select
        name=""
        id="select_building"
        className="form_control form_sm"
        onChange={e => {props.changeBuildHandler(e)}}
      >
      {props.buildings.map((build: any, idx: number) => (
        <option
          value={idx}
          key={idx}
          selected={props.building ? props.building.id === build.id : !idx}
          label={`${build.name} ${build.dong}`}
        />
      ))}
    </select>
  );
};

export default GroupBuildings;