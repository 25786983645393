import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import LimitSelect from '../../components/atoms/select/limit';
import RoomType from '../../components/molecules/tab/roomType';
import GuestTable from '../../components/organismas/table/guest';
import SearchForm from '../../components/organismas/form/search';

import DefaultTemplete from '../../components/templetes/listDefault';

const LodgeGuestPage = (props: any) => {
  const [offset, setOffset] = useState<number>(props.offset || 0);

  const [limit, setLimit] = useState<number>(10);
  const [roomType, setRoomType] = useState<number>(0);

  const [searchOption, setSearchOption] = useState<{option: string, text: string}>({option: "", text: "선택"});

  const onClickHander = (guest: any) => props.history.push('/mypage/guest/detail', guest);

  const onSearchHandler = (option: string, text: string) => {
    setLimit(10);
    setOffset(0);
    setSearchOption({ option, text })
  }

  return (
    <DefaultTemplete
      id="wrap"
      className="wrap_mypage_manage"
      addClassName="w100"
      leftInfo={
        <>
          <h2 className="">숙소 이용정보</h2>
          <p className="desc">숙소이용 이력 및 상세내용을 확인하실 수 있습니다</p>
        </>
      }
      {...props}
    >
      <div className="group_search">
        <div className="search_form">
          <SearchForm
            options={[
              { value: "", label: "선택" },
              { value: "room_work_site", label: "신청지역" },
              { value: "room_building_name", label: "건물명" },
              { value: "room_building_dong", label: "동" },
              { value: "room_name", label: "호" },
            ]}
            onSearch={onSearchHandler}
          />
        </div>
      </div>

      <div className="group_tab mt40">
        <div className="tabs_area">
          <RoomType
            value={roomType}
            onChange={(roomType: number) => setRoomType(roomType)}
          />
        </div>

        <div className="right_area">
          <LimitSelect limit={limit} onChange={(limit: number) => { setLimit(limit); setOffset(0); }} />
        </div>
      </div>

      <GuestTable
        searchOption={searchOption}
        roomType={roomType}
        isPaginationView
        limit={limit}
        offset={offset}
        onItemClick={onClickHander}
        history={props.history}
      />
    </DefaultTemplete>
  );
};

export default LodgeGuestPage;
