import React, { useState, useEffect, useContext } from 'react';
import { auth, lodge } from '../../apis';
import ConfirmBtn from '../../components/atoms/button/confirm';
import DatePicker from '../../components/organismas/datePicker';
import DefaultTemplete from '../../components/templetes/default';
import { ROOM_TYPE, LODGE_MOVE_CODE, ROOM_TYPE_CODE, WORK_SITE_CODE } from '../../constant';
import { MessageContext } from '../../routes';
import { getTodayDate } from '../../utils/date';
import { getEscapeString, INPUT_TYPE } from '../../utils/escapestring';
import { useSelector } from 'react-redux';
import { observer } from 'mobx-react';
import { useStores } from '../../store/mobx';
import CustomCalendar from '../../components/organismas/customCalendar';
import { getWorkSitePhoneNum } from '../../utils/commons';
import { SHOW_MODAL } from '../../store/modules/modal';
import { EnterpriseCoreModule } from 'ag-grid-enterprise/dist/lib/agGridEnterpriseModule';

const LodgeMoveReqApply = observer((props: any) => {
  const {lodgedStore: store} = useStores();
  const { info } = useContext(MessageContext);
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user,
  }));
  const [roomWorkSite, setRoomWorkSite] = useState<string>(props.history.location.state.moveId ? WORK_SITE_CODE[props.history.location.state.work_site] : WORK_SITE_CODE[props.history.location.state.room_work_site]);
  const [employeeNum, setEmployeeNum] = useState<number>();
  const [employee, setEmployee] = useState<any>([]);
  const [category, setCategory] = useState<number>(LODGE_MOVE_CODE.one);
  const [isPartyCheck, setIsPartyCheck] = useState<boolean>(props.history.location.state.moveId && (props.history.location.state.original_party !== props.history.location.state.party)? false : true);
  const [party, setParty] = useState<string>(props.history.location.state.moveId ? props.history.location.state.party : props.history.location.state.guest_party);
  const [claim, setClaim] = useState<string>(props.history.location.state.claim ? props.history.location.state.claim : '');
  //const [desiredRoom, setDesiredRoom] = useState<string>(props.history.location.state.desired_room ? props.history.location.state.desired_room : '');
  //const [isEquimentOk, setIsEquimentOk] = useState<boolean>(props.history.location.state.is_equipment_ok ? props.history.location.state.is_equipment_ok : false);
  const [moveAt, setMoveAt] = useState<string>(props.history.location.state.moved_at ? props.history.location.state.moved_at : getTodayDate()[0]);
  
  useEffect(() => {
    (async () => {
        store._category = LODGE_MOVE_CODE.one;
        
        // 수정인 경우, 응답자 정보 노출
        if(props.history.location.state.moveId){
            setEmployeeNum(props.history.location.state.employee_number2);
            //setEmployee(props.history.location.state.employee);

            try {
              const payload : any = {
                employee_number : props.history.location.state.employee_number2,
                gender_type : userInfo.gender
              }
              if(Number(roomWorkSite) === 2 ) {
                payload["building_name"] = props.history.location.state.auth_building;
              }
              let { data } = await auth.getProfileDetail(payload);

              await setEmployee(data[0]);
            } catch (error) {
              //console.log(error);

              const { status, data }= error;

              if (status === 401) {
                window.location.href = '/force/signout';
                return ;
              } else if (status === 403) {
                (window as any).store.dispatch({
                  type: SHOW_MODAL,
                  payload: {
                    title: '권한오류',
                    msg: '접근권한이 없습니다.',
                    redirectPath: false,
                    statusCode: status,
                    errorCode: '',
                    type: 'error',
                    isModalShow: true,
                  },
                });
                return ;
              }else if ( status === 500 ) {
                window.location.href = '/500';
                return;
              }

              (window as any).store.dispatch({
                type: SHOW_MODAL,
                payload: {
                  title: '문제발생',
                  msg: '문제가 발생했습니다',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                },
              });
              return ;
        
            }
        }
    })();
  }, []);
  
  // 이숙신청 상대 정보 가져오기
  const getEmployeeInfo = async() => {
    try {
      const payload : any = {
        employee_number : employeeNum,
        gender_type : userInfo.gender
      }

      if(Number(roomWorkSite) === 2 ) {
        payload['building_name'] = props.history.location.state.auth_building;
      }

      // 수정의 경우,
      if(props.history.location.state.moveId && Number(roomWorkSite) === 2){
        payload['building_name'] = props.history.location.state.auth_building;
      }

      let { data } = await auth.getProfileDetail(payload);

      if(!data || data === null || data.length === 0
          || employeeNum === userInfo.employeenumber) {
        info("정확한 사번을 확인해 주세요");
        setEmployee([]);
        return;
      }

      if(roomWorkSite !== data[0].work_site_id) {
        info("사업장이 일치하지 않습니다");
        setEmployee([]);
        return;
      }
      
      await setEmployee(data[0]);
    } catch (error) {
      //console.log(error);

      const {status, data} = error;

      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }


      info("정확한 사번을 확인해 주세요");
      setEmployee([]);

      return;
    }
  }

  const onSubmitHandler = async() => {
    if(!employeeNum || !employee || !employee.id) {
      info("이숙신청 상대 정보를 입력해주세요");
      return false;
    }

    if(!party || !moveAt ) {
      info("필수입력 항목을 확인해주세요");
      return false;
    }

    if(props.history.location.state.moveId){    // 수정 X
      let payload = {
        party: party,
        claim: claim,
        //desired_room: desiredRoom,
        //is_equipment_ok: isEquimentOk,
        moved_at: moveAt,
        other_guest: employee.id
      }
  
      try {
        let { data } = await lodge.lodgeMoveOneApplyUpdate(props.history.location.state.moveId, payload);
        info('이숙신청이 수정되었습니다');
        props.history.push('/mypage/move');
      } catch (error) {
        //console.log(error);

        const {status, data} = error;

        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
  

        info("이숙신청 중 문제가 발생했습니다.")
        return;
      }
    }else {   // 등록
      let payload = {
        guest: props.history.location.state.id,
        category: category,
        party: party,
        claim: claim,
        //desired_room: desiredRoom,
        //is_equipment_ok: isEquimentOk,
        moved_at: moveAt,
        other_guest: employee.id
      }
  
      try {
        let { data } = await lodge.lodgeMoveApply(
          payload
        )
        info('이숙신청이 완료되었습니다');

        // 문자 발송 - 신청자 
        let smsPayload: any = {
          cellphone : props.history.location.state.guest_cellphone,
          message : '이숙신청이 되었습니다. \n신청자 : '+employee.guest_name+' \n신청자 호실 : '+ props.history.location.state.room_building_name + ' ' + props.history.location.state.room_building_dong + ' ' + props.history.location.state.room_name+'호'
                    +'\n이숙요청일 : ' + moveAt + '\n실제 근무조 : '+ party +'\n요청사항 : ' + claim
                    +'\n신청진행 확인은 "마이페이지>>이숙관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 '+getWorkSitePhoneNum(WORK_SITE_CODE[props.history.location.state.room_work_site], props.history.location.state.room_type)
        }
        const { data: sms } = await lodge.sendSMS(smsPayload);
        
        // 문자발송 - 승인자
        let smsPayload2: any = {
          cellphone : employee.guest_cellphone,
          message : '이숙신청이 되었습니다. \n신청자 : '+employee.guest_name+' \n신청자 호실 : '+ props.history.location.state.room_building_name + ' ' + props.history.location.state.room_building_dong + ' ' + props.history.location.state.room_name+'호'
                    +'\n승인자 호실 : ' +employee.room_building_name + ' ' + employee.room_building_dong + ' ' + employee.room_name + '호'
                    +'\n이숙요청일 : ' + moveAt + '\n실제 근무조 : '+ party +'\n요청사항 : ' + claim
                    +'\n신청진행 확인은 "마이페이지>>이숙관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 '+getWorkSitePhoneNum(WORK_SITE_CODE[props.history.location.state.room_work_site], props.history.location.state.room_type)
        }
        const { data: sms2 } = await lodge.sendSMS(smsPayload2);

        props.history.push('/mypage/move');
      } catch (error) {
        //console.log(error);

        const {status, data} = error;

        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        info("이숙신청 중 문제가 발생했습니다.")
        return;
      }
    }
  }

  return (
    <DefaultTemplete
      leftInfo={
        <>
          <h2>이숙신청</h2>
          <p className="desc">기숙사 이숙신청 및 현황을 확인하실 수 있습니다</p>
          <div className="bg_img change"></div>
        </>
      }
      id="wrap"
      className="wrap_mypage_manage"
      {...props}
    >
      <div className="title_contents">
          <h3 className="title">기본 정보</h3>
      </div>
      <ul className="lst_status">
        {props.history.location.state.moveId? (
          <>
          <li><strong>숙소유형</strong><span>{ROOM_TYPE[ROOM_TYPE_CODE.dormitory_room]}</span></li>
            <li><strong>이용사업장</strong><span>{props.history.location.state.work_site}</span></li>
            <li><strong>입숙일</strong><span>{props.history.location.state.managed_at1}</span></li>
            <li><strong>입숙만료일</strong><span>{props.history.location.state.expired_at1}</span></li>
            <li><strong>배방</strong><span>
              {props.history.location.state.building_name}
              {props.history.location.state.dong}
              {props.history.location.state.ho}
            </span></li>
          </>
        ) : (
          <>
            <li><strong>숙소유형</strong><span>{ROOM_TYPE[props.history.location.state.room_type]}</span></li>
            <li><strong>이용사업장</strong><span>{props.history.location.state.room_work_site}</span></li>
            <li><strong>입숙일</strong><span>{props.history.location.state.checkin_at}</span></li>
            <li><strong>입숙만료일</strong><span>{props.history.location.state.expired_at}</span></li>
            <li><strong>배방</strong><span>
              {props.history.location.state.room_building_name}
              {props.history.location.state.room_building_dong}
              {props.history.location.state.room_name}
            </span></li>
          </>
        ) }
      </ul>

      <div className="title_contents">
          <h3 className="title">이숙신청 상대 정보 입력</h3>
      </div>

      <div className="table_design_list row">
          <dl className="col">
              <dt>사번 (아이디)</dt>
              <dd>
                  <div className="form_wrap">
                      <input type="text" 
                        className="form_control" 
                        value={employeeNum} 
                        maxLength={7}
                        onChange={(e: any) => {
                          e.target.value = getEscapeString(e.target.value, INPUT_TYPE.employee);
                          setEmployeeNum(e.target.value)
                        }}/>
                      <button className="btn_sm btn_gray" onClick={getEmployeeInfo}>적용</button>
                  </div>
              </dd>
          </dl>
          <dl className="col">
              <dt>성명</dt>
              <dd>{employee.guest_name}</dd>
          </dl>
          <dl className="col">
              <dt>성별</dt>
              <dd>{employee.guest_gender? employee.guest_gender === 1? '남' : '여' : '' }</dd>
          </dl>
          <dl className="col">
              <dt>소속 부서</dt>
              <dd>{employee.guest_department}</dd>
          </dl>
          <dl className="col">
              <dt>휴대폰 번호</dt>
              <dd>{employee.guest_cellphone}</dd>
          </dl>
      </div> 

      <div className="table_design_list row mt10">
          <dl className="col">
              <dt>숙소유형</dt>
              <dd>{employee.room_work_site? '기숙사' : ''}</dd>
          </dl>
          <dl className="col">
              <dt>이용사업장</dt>
              <dd>{employee.room_work_site}</dd>
          </dl>
          <dl className="col">
              <dt>입숙일</dt>
              {/* <dd className="linethrough">{employee.lodging? employee.lodging.stay_after : ''}</dd> */}
              <dd></dd>
          </dl>
          <dl className="col">
              <dt>입숙만료일</dt>
              {/* <dd className="linethrough">{employee.lodging? employee.lodging.room_type : ''}</dd> */}
              <dd></dd>
          </dl>
          <dl className="col">
              <dt>배방</dt>
              {/* <dd className="linethrough">{employee.lodging? employee.lodging.room_type : ''}</dd> */}
              <dd></dd>
          </dl>
      </div> {/* table_design_list */}

      <div className="title_contents">
          <h3 className="title">이숙신청 정보입력</h3>
      </div>

      <div className="table_design_write col_width2">
          <div id="table" className="table">
              <div className="tbody">
                  <div className="tr">
                      <div className="th"><span className="">기존근무조</span></div>
                      <div className="td">
                        {props.history.location.state.moveId ? props.history.location.state.original_party : props.history.location.state.guest_party}
                      </div>
                  </div>
                  <div className="tr">
                      <div className="th"><span className="">실제근무조</span></div>
                      <div className="td">
                          <div className="check_area">
                              <label className="input_radio">
                                  <input type="radio" id="sample_case1" name="sample" checked={isPartyCheck} 
                                    onChange={() => {
                                      if(props.history.location.state.moveId) {
                                        setParty(props.history.location.state.original_party);
                                      }else {
                                        setParty(props.history.location.state.guest_party); 
                                      }
                                      setIsPartyCheck(true);
                                    }} 
                                  />
                                  <span className="label_text">동일</span>
                              </label>
                              <label className="input_radio">
                                  <input type="radio" id="sample_case2" name="sample" checked={!isPartyCheck} onChange={() => setIsPartyCheck(false)} />
                                  <span className="label_text">다름</span>
                              </label>

                              <select name="" id="" className="form_control form_inline" 
                                value={party} 
                                disabled={isPartyCheck? true: false} 
                                onChange={(e:any) => setParty(e.target.value)}
                              >
                                <option value="A">A조</option>
                                <option value="B">B조</option>
                                <option value="상시주간">상시주간</option>
                              </select>
                          </div>
                      </div>
                  </div>

                  <div className="tr">
                      <div className="th"><span className="">이숙요청일</span></div>
                      <div className="td">
                        {/* <DatePicker
                            value={moveAt}
                            width={250}
                            onChange={(date: string) => setMoveAt(date)}
                            isPrevMonthView={true}
                            isNextMonthNotView={false}
                            min={getTodayDate()[0]}
                          /> */}
                          <CustomCalendar
                            min={getTodayDate()[0]}
                            value={moveAt}
                            onChange={(date:string) => {setMoveAt(date)}}
                          ></CustomCalendar>
                      </div>
                  </div>

                  <div className="tr mix_width2">
                      <div className="th textarea">
                        <span className="">요청사항 입력 <br></br>
                          <ul className="lst_warning gray">
                              <li>이숙신청은 1년 1회만 가능합니다.</li>
                          </ul>
                        </span>
                      </div>
                      <div className="td textarea">
                          <textarea name="" id="" className="form_control" maxLength={400} value={claim} onChange={(e: any)=> setClaim(e.target.value)}></textarea>
                          <span className="size">{claim.length} byte / 400 byte</span>
                      </div>
                  </div>
              </div>
          </div>
      </div> {/* table_design_write */}

     {/* <div className="mb_view">
          <button type="button" className="btn btn_lg btn_gray">목록</button>
        </div> */}

      <div className="submit">
          {/* <div className="fl">
                <button type="button" className="btn btn_lg btn_gray">목록</button>
            </div> */}
          <div className="fr">
            <ConfirmBtn
              action={() => {
                if(props.history.location.state.moveId){
                  props.history.push('/mypage/move');
                }else {
                  props.history.push('/mypage/guest/detail', props.history.location.state);
                }
              }}
              label={`취소`}
              title=""
              msg={`이숙신청을 취소하시겠습니까?`}
              className="btn btn_lg btn_outline"
            />
            <ConfirmBtn
              action={onSubmitHandler}
              label={`완료`}
              title=""
              msg={`이숙신청을 진행하시겠습니까?`}
              className="btn btn_lg btn_darkblue"
            />
          </div>
      </div>
    </DefaultTemplete>
  );
});

export default LodgeMoveReqApply;
