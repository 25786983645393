import { getSelectedLang, setSelectedLang } from '../../utils/locales';

interface Workplace {
  id: number
  name: string
  is_active: boolean
  is_visible: boolean
}

interface WorkplaceMap {
  id: {
    name: string
    is_active: boolean
  }
}

interface SiteInfo {
  lang: string
}

interface State {
  workplaces: Workplace[]
  workplaceMap: WorkplaceMap | {}
  siteInfo: SiteInfo
}

const initialState: State = {
  workplaces: [],
  workplaceMap: {},
  siteInfo: {
    lang: getSelectedLang()
  }
};

export const INIT_REQUEST = "BASE/INIT_REQUEST";

export const WORKSITE_INIT_REQUEST = "BASE/WORKSITE_INIT_REQUEST";
export const WORKSITE_INIT_COMPLETE = "BASE/WORKSITE_INIT_COMPLETE";

export const SITEINFO_CHANGE_LANG = "BASE/SITEINFO_CHANGE_LANG";

export const onChnageLang = (payload: any): any => (dispatch: any) => {
  dispatch({
    type: SITEINFO_CHANGE_LANG,
    payload
  });
};

function baseReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case WORKSITE_INIT_COMPLETE:
      let { payload: workplaces } = action;
      
      let workplaceMap = workplaces.reduce((acc: any, curVal: Workplace, idx: number) => {
        let { name, is_active, is_visible } = curVal
        acc[curVal.id] = { name, is_active, is_visible }
        return acc
      }, {})
      
      return {
        ...state,
        workplaces,
        workplaceMap
      }
    case SITEINFO_CHANGE_LANG:
      let { payload: lang } = action;
      let siteInfo = {
        ...state.siteInfo,
        lang
      }
      setSelectedLang(lang);
      return {
        ...state,
        siteInfo
      }

    default:
      return state;
  }
}

export default baseReducer;
