import React from 'react';

import EmployeeNumberInput from '../../atoms/input/employeeNumber';
import PhoneInput from '../../atoms/input/phone';
import NameInput from '../../atoms/input/name';
import DepartmentInput from '../../atoms/input/department';
import { getPrevDay, getTodayDate } from '../../../utils/date';
import SelectCompany from '../../atoms/select/company';

import { auth } from '../../../apis';

import { ROOM_TYPE_CODE } from '../../../constant';
import DatePicker from '../datePicker';
import { SHOW_MODAL } from '../../../store/modules/modal';
import CustomCalendar from '../customCalendar';

const Type1 = (props: any) => {
  const onEmployeeNumberChange = (e: any) => {
    const temp = [...props.guests];

    temp[props.guestIdx].guest_employee_number = e.target.value;
    props.setGuests(temp);
  };

  const onNameChange = (e: any) => {
    const temp = [...props.guests];

    temp[props.guestIdx].guest_name = e.target.value;
    props.setGuests(temp);
  };
  const onDepartmentChange = (e: any) => {
    const temp = [...props.guests];

    temp[props.guestIdx].guest_department = e.target.value;
    props.setGuests(temp);
  };
  const onPhoneChange = (e: any) => {
    const temp = [...props.guests];

    temp[props.guestIdx].guest_cellphone = e.target.value;
    props.setGuests(temp);
  };
  const onCompanyChange = (e: any) => {
    const temp = [...props.guests];

    temp[props.guestIdx].guest_company = e.target.value;
    props.setGuests(temp);
  };
  const onCarNumberChange = (e: any) => {
    const temp = [...props.guests];

    temp[props.guestIdx].guest_car_number1 = e.target.value;
    props.setGuests(temp);
  };
  const onCarNumberChange2 = (e: any) => {
    const temp = [...props.guests];

    temp[props.guestIdx].guest_car_number2 = e.target.value;
    props.setGuests(temp);
  };
  const onCarNumberChange3 = (e: any) => {
    const temp = [...props.guests];

    temp[props.guestIdx].guest_car_number3 = e.target.value;
    props.setGuests(temp);
  };
  const onChangeGender = (g: number) => {
    if (!props.isUpdateMode) return;
    const temp = [...props.guests];
    temp[props.guestIdx].guest_gender = g;
    props.setGuests(temp);
  };
  const onChangeLeader = (l: boolean) =>  {
    if (!props.isUpdateMode) return;
    const temp = [...props.guests];
    temp[props.guestIdx].is_leader = l;
    props.setGuests(temp);
  }

  const onChangeSupported = (l: boolean) =>  {
    if (!props.isUpdateMode) return;
    const temp = [...props.guests];
    temp[props.guestIdx].is_supported = l;
    props.setGuests(temp);
  }

  const onCheckinHopeChange = (date: string) => {
    const temp = [...props.guests];
    
    temp[props.guestIdx].checkin_hope = date;
    props.setGuests(temp);
  };

  const onStayAfterChange = (date: string) => {
    const temp = [...props.guests];
    
    temp[props.guestIdx].stay_after = date;
    props.setGuests(temp);
  };

  const onStayBeforeChange = (date: string) => {
    const temp = [...props.guests];

    temp[props.guestIdx].stay_before = date;
    props.setGuests(temp);
  };

  const onClickGetPersonnelHandler = async () => {
    if (!props.guests[props.guestIdx].guest_employee_number) {
      //alert('123');
      return;
    }
    const temp = [...props.guests];

    try {
      const { data } = await auth.isSignedUser(props.guests[props.guestIdx].guest_employee_number);
      temp[props.guestIdx].guest_name = data.name;
      temp[props.guestIdx].guest_department = data.personnel.department;
      temp[props.guestIdx].guest_position = data.personnel.position;
      temp[props.guestIdx].guest_cellphone = data.cellphone;
      temp[props.guestIdx].guest_gender = data.personnel.gender;
      temp[props.guestIdx].guest_occupation = data.personnel.occupation;
      temp[props.guestIdx].guest_rank = data.personnel.rank;

      // console.log(data);
      props.setGuests(temp);
    } catch (err) {
      //console.log(err);

      const { status, data } = err;

      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;

    }
  };

  return (
    <div className="table_design_list table_write col_width6">
      {/* 출장자 단체일 경우 disabled 빼기 직접입력 가능하게 */}
      <div className="user_info">
        <span className="user_name">이용자</span>
        {props.roomType === ROOM_TYPE_CODE.business_room_group? (
          <NameInput
            type="text"
            className="form_control"
            value={props.guest.guest_name}
            disabled={!props.isUpdateMode}
            onChange={onNameChange}
          />
        ) : (
          <NameInput
            type="text"
            className="form_control"
            value={props.guest.guest_name}
            disabled={true}
            onChange={onNameChange}
          />
        )}
      </div>
      <div id="table" className="table">
        <div className="tbody">
          <div className="tr">
            <div className="th">사번 (아이디)</div>
            <div className="td">
              {props.isUpdateMode ? (
                <div className="form_wrap">
                  <EmployeeNumberInput
                    type="text"
                    className="form_control"
                    value={props.guest.guest_employee_number}
                    disabled={!props.isUpdateMode}
                    onChange={onEmployeeNumberChange}
                  />
                  {props.roomType === ROOM_TYPE_CODE.business_room_group? (
                    <>
                    {/* 출장자 단체일 경우 적용버튼 삭제 */}
                    {/* 출장자 단체일 경우 적용버튼 삭제 */}
                    </>
                  ) : (
                    <button
                      className="btn_sm btn_gray"
                      onClick={() => onClickGetPersonnelHandler()}
                    >
                      적용
                    </button>
                  )}
                </div>
              ) : (
                <input
                  type="text"
                  className="form_control"
                  value={props.guest.guest_employee_number}
                  disabled={!props.isUpdateMode}
                />
              )}
            </div>
            <div className="th">성별</div>
            <div className="td">
            {props.roomType === ROOM_TYPE_CODE.business_room_group? (
              <>
                <label
                  className="input_radio"
                  htmlFor="sex_choice1"
                  onClick={() => onChangeGender(1)}
                >
                  <input
                    type="radio"
                    name={`sex-${props.guestIdx}`}
                    checked={props.guest.guest_gender === 1 ? true : false}
                  />
                  <span className="label_text">남</span>
                </label>
                <label
                  className="input_radio"
                  htmlFor="sex_choice2"
                  onClick={() => onChangeGender(2)}
                >
                  <input
                    type="radio"
                    name={`sex-${props.guestIdx}`}
                    checked={props.guest.guest_gender === 2 ? true : false}
                  />
                  <span className="label_text">여</span>
                </label>
              </>
            ) : (
              <>
                <label className="input_radio" htmlFor="sex_choice1">
                  <input
                    type="radio"
                    name={`sex-${props.guestIdx}`}
                    value={1}
                    checked={props.guest.guest_gender === 1 ? true : false}
                  />
                  <span className="label_text">남</span>
                </label>
                <label className="input_radio" htmlFor="sex_choice2">
                  <input
                    type="radio"
                    name={`sex-${props.guestIdx}`}
                    value={2}
                    checked={props.guest.guest_gender === 2 ? true : false}
                  />
                  <span className="label_text">여</span>
                </label>
              </>
            )}
            </div>
            <div className="th">휴대폰번호</div>
            <div className="td">
              {props.roomType === ROOM_TYPE_CODE.business_room_group? (
                <PhoneInput
                  type="text"
                  className="form_control"
                  value={props.guest.guest_cellphone}
                  disabled={!props.isUpdateMode}
                  onChange={onPhoneChange}
                />
              ) : (
                <PhoneInput
                  type="text"
                  className="form_control"
                  value={props.guest.guest_cellphone}
                  disabled={true}
                  onChange={onPhoneChange}
                />
              )}
            </div>
          </div>
          <div className="tr">
            <div className="th">소속회사 유형</div>
            <div className="td">
              <select
                className="form_control"
                value={props.guest.guest_type}
                disabled={true}
              >
                <option value="1">사내 임직원</option>
                <option value="2">계열사 임직원</option>
                <option value="3">외부인</option>
              </select>
            </div>
            <div className="th">회사명</div>
            <div className="td">
              {props.roomType === ROOM_TYPE_CODE.business_room_group?(
                <SelectCompany
                  value={props.guest.guest_company}
                  disabled={!props.isUpdateMode}
                  onChange={onCompanyChange}
                />
              ):(
                <SelectCompany
                  value={props.guest.guest_company}
                  disabled={true}
                  onChange={onCompanyChange}
                />
              )}
            </div>
            <div className="th">소속부서</div>
            <div className="td">
              {props.roomType === ROOM_TYPE_CODE.business_room_group?(
                <DepartmentInput
                type="text"
                className="form_control"
                value={props.guest.guest_department}
                disabled={!props.isUpdateMode}
                onChange={onDepartmentChange}
              />
              ):(
                <DepartmentInput
                  type="text"
                  className="form_control"
                  value={props.guest.guest_department}
                  disabled={true}
                  onChange={onDepartmentChange}
                />
              )}
            </div>
          </div>
          <div className="tr">
            <div className="th">입숙예정일</div>
            <div className="td">
              <div className="datepicker">
                {props.isUpdateMode && 
                  (props.roomType !== ROOM_TYPE_CODE.dormitory_room && props.roomType !== ROOM_TYPE_CODE.company_room) ? (
                  // <DatePicker
                  //   value={props.guest.stay_after}
                  //   width={250}
                  //   onChange={(date: string) => onStayAfterChange(date)}
                  //   isPrevMonthView={true}
                  //   isNextMonthNotView={false}
                  //   min={getTodayDate()[0]}
                  //   max={props.guest.stay_before}
                  // />
                  <CustomCalendar
                    min={getTodayDate()[0]}
                    max={props.guest.stay_before}
                    value={props.guest.stay_after}
                    onChange={(date:string) => {onStayAfterChange(date)}}
                  ></CustomCalendar> 
                ): <>{props.guest.stay_after}</>}
              </div>
            </div>
            <div className="th">퇴숙예정일</div>
            <div className="td">
              <div className="datepicker">
                {props.isUpdateMode &&
                (props.roomType !== ROOM_TYPE_CODE.dormitory_room && props.roomType !== ROOM_TYPE_CODE.company_room) ? (
                  // <DatePicker
                  //   value={props.guest.stay_before}
                  //   width={250}
                  //   onChange={(date: string) => onStayBeforeChange(date)}
                  //   isPrevMonthView={true}
                  //   isNextMonthNotView={false}
                  //   min={props.guest.stay_after}
                  // />
                  <CustomCalendar
                    min={props.guest.stay_after}
                    value={props.guest.stay_before}
                    onChange={(date: string) => onStayBeforeChange(date)}
                  ></CustomCalendar> 
                ) : <>{props.guest.stay_before}</>}
              </div>
            </div>
            <div className="th">차량번호</div>
            <div className="td">
              <input type="text"
                className="form_control"
                value={props.guest.guest_car_number1}
                disabled={!props.isUpdateMode}
                onChange={onCarNumberChange}
              />
            </div>
          </div>

          {props.roomType === ROOM_TYPE_CODE.company_room 
            ? (
              <div className="tr">
                <div className="th">차량번호2</div>
                <div className="td">
                 <input type="text"
                    className="form_control"
                    value={props.guest.guest_car_number2}
                    disabled={!props.isUpdateMode}
                    onChange={onCarNumberChange2}
                  />
                </div>
                <div className="th">차량번호3</div>
                <div className="td">
                  <input type="text"
                    className="form_control"
                    value={props.guest.guest_car_number3}
                    disabled={!props.isUpdateMode}
                    onChange={onCarNumberChange3}
                  />
                </div>
                <div className="th">{"주거지원금 수혜여부"}</div>
                <div className="td">
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="guide_support1"
                      name={`guide_support${props.guestIdx}`}
                      checked={props.guest.is_supported}
                      onChange={() => onChangeSupported(true)}
                    />
                    <span className="label_text">여</span>
                  </label>
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="guide_support2"
                      name={`guide2_support${props.guestIdx}`}
                      checked={!props.guest.is_supported}
                      onChange={() => onChangeSupported(false)}
                    />
                    <span className="label_text">부</span>
                  </label>
                </div>
              </div>
            ): (
                <></>
            )}

            {props.roomType === ROOM_TYPE_CODE.company_room || props.roomType === ROOM_TYPE_CODE.dormitory_room
              ? (
              <div className="tr">
                <div className="th">입숙희망일</div>
                <div className="td">
                  {props.isUpdateMode?(
                    // <DatePicker
                    //   value={props.guest.checkin_hope}
                    //   width={250}
                    //   onChange={(date: string) => onCheckinHopeChange(date)}
                    //   isPrevMonthView={true}
                    //   isNextMonthNotView={false}
                    //   min={getTodayDate()[0]}
                    // />
                    <CustomCalendar
                      min={getTodayDate()[0]}
                      value={props.guest.checkin_hope}
                      onChange={(date:string) => {onCheckinHopeChange(date)}}
                    ></CustomCalendar> 
                  ):(
                    <>
                    {props.guest.checkin_hope}
                    </>
                  )}
                </div>
                <div className="th"></div>
                <div className="td">
                </div>
                <div className="th"></div>
                <div className="td">
                </div>
              </div>
            ): (
              <></>
            )}


          {props.roomType === ROOM_TYPE_CODE.business_room_group ? (
            <div className="tr">
              <div className="th">인솔자 여부</div>
              <div className="td">
                <label 
                  className="input_radio" 
                  //htmlFor="whether_select1"
                >
                  <input
                    type="radio"
                    name={`whether1-${props.guestIdx}`}
                    checked={props.guest.is_leader}
                    onClick={()=> onChangeLeader(true)}
                  />
                  <span className="label_text">여</span>
                </label>
                <label 
                  className="input_radio" 
                  //htmlFor="whether_select2"
                >
                  <input
                    type="radio"
                    name={`whether2-${props.guestIdx}`}
                    checked={!props.guest.is_leader}
                    onClick={()=> onChangeLeader(false)}
                  />
                  <span className="label_text">부</span>
                </label>
              </div>
              <div className="th blank"></div><div className="td blank"></div><div className="th blank"></div><div className="td blank"></div>
            </div>
          ): ''}
        </div>
      </div>
    </div>
  );
};

const Type2 = (props: any) => {
  const onNameChange = (e: any) => {
    const temp = [...props.guests];

    temp[props.guestIdx].guest_name = e.target.value;
    props.setGuests(temp);
  };
  const onDepartmentChange = (e: any) => {
    const temp = [...props.guests];

    temp[props.guestIdx].guest_department = e.target.value;
    props.setGuests(temp);
  };
  const onPhoneChange = (e: any) => {
    const temp = [...props.guests];

    temp[props.guestIdx].guest_cellphone = e.target.value;
    props.setGuests(temp);
  };
  const onCompanyChange = (e: any) => {
    const temp = [...props.guests];

    temp[props.guestIdx].guest_company = e.target.value;
    props.setGuests(temp);
  };
  const onEmployeeNumberChange = (e: any) => {
    const temp = [...props.guests];

    temp[props.guestIdx].guest_employee_number = e.target.value;
    props.setGuests(temp);
  };
  const onChangeGender = (g: number) => {
    if (!props.isUpdateMode) return;
    const temp = [...props.guests];
    temp[props.guestIdx].guest_gender = g;
    props.setGuests(temp);
  };
  const onCarNumberChange = (e: any) => {
    const temp = [...props.guests];

    temp[props.guestIdx].guest_car_number1 = e.target.value;
    props.setGuests(temp);
  };
  const onCarNumberChange2 = (e: any) => {
    const temp = [...props.guests];

    temp[props.guestIdx].guest_car_number2 = e.target.value;
    props.setGuests(temp);
  };
  const onCarNumberChange3 = (e: any) => {
    const temp = [...props.guests];

    temp[props.guestIdx].guest_car_number3 = e.target.value;
    props.setGuests(temp);
  };

  const onCheckinHopeChange = (date: string) => {
    const temp = [...props.guests];
    
    temp[props.guestIdx].checkin_hope = date;
    props.setGuests(temp);
  };

  const onStayAfterChange = (date: string) => {
    const temp = [...props.guests];

    temp[props.guestIdx].stay_after = date;
    props.setGuests(temp);
  };

  const onStayBeforeChange = (date: string) => {
    const temp = [...props.guests];

    temp[props.guestIdx].stay_before = date;
    props.setGuests(temp);
  };

  const onChangeLeader = (l: boolean) =>  {
    if (!props.isUpdateMode) return;
    const temp = [...props.guests];
    temp[props.guestIdx].is_leader = l;
    props.setGuests(temp);
  }
  return (
    <div className="table_design_list table_write col_width6">
      <div className="user_info">
        <span className="user_name">이용자</span>
        <NameInput
          type="text"
          className="form_control"
          value={props.guest.guest_name}
          disabled={!props.isUpdateMode}
          onChange={onNameChange}
        />
      </div>
      <div id="table" className="table">
        <div className="tbody">
          <div className="tr">
            <div className="th">사번 (아이디)</div>
            <div className="td">
              <EmployeeNumberInput
                type="text"
                className="form_control"
                value={props.guest.guest_employee_number}
                disabled={!props.isUpdateMode}
                onChange={onEmployeeNumberChange}
              />
            </div>
            <div className="th">성별</div>
            <div className="td">
              <label
                className="input_radio"
                htmlFor="sex_choice1"
                onClick={() => onChangeGender(1)}
              >
                <input
                  type="radio"
                  name={`sex2-${props.guestIdx}`}
                  checked={props.guest.guest_gender === 1 ? true : false}
                />
                <span className="label_text">남</span>
              </label>
              <label
                className="input_radio"
                htmlFor="sex_choice2"
                onClick={() => onChangeGender(2)}
              >
                <input
                  type="radio"
                  name={`sex2-${props.guestIdx}`}
                  checked={props.guest.guest_gender === 2 ? true : false}
                />
                <span className="label_text">여</span>
              </label>
            </div>
            <div className="th">휴대폰번호</div>
            <div className="td">
              <PhoneInput
                type="text"
                className="form_control"
                value={props.guest.guest_cellphone}
                disabled={!props.isUpdateMode}
                onChange={onPhoneChange}
              />
            </div>
          </div>
          <div className="tr">
            <div className="th">소속회사 유형</div>
            <div className="td">
              <select
                className="form_control"
                value={props.guest.guest_type}
                disabled={true}
              >
                <option value="1">사내 임직원</option>
                <option value="2">계열사 임직원</option>
                <option value="3">외부인</option>
              </select>
            </div>
            <div className="th">회사명</div>
            <div className="td">
              <SelectCompany
                value={props.guest.guest_company}
                disabled={!props.isUpdateMode}
                onChange={onCompanyChange}
              />
            </div>
            <div className="th">소속부서</div>
            <div className="td">
              <DepartmentInput
                type="text"
                className="form_control"
                value={props.guest.guest_department}
                disabled={!props.isUpdateMode}
                onChange={onDepartmentChange}
              />
            </div>
          </div>
          <div className="tr">
            <div className="th">입숙예정일</div>
            <div className="td">
              <div className="datepicker">
                {props.isUpdateMode && 
                (props.roomType !== ROOM_TYPE_CODE.dormitory_room && props.roomType !== ROOM_TYPE_CODE.company_room) ? (
                  // <DatePicker
                  //   value={props.guest.stay_after}
                  //   width={250}
                  //   onChange={(date: string) => onStayAfterChange(date)}
                  //   isPrevMonthView={true}
                  //   isNextMonthNotView={false}
                  //   min={getTodayDate()[0]}
                  //   max={props.guest.stay_before}
                  // />
                  <CustomCalendar
                    min={getTodayDate()[0]}
                    max={props.guest.stay_before}
                    value={props.guest.stay_after}
                    onChange={(date: string) => onStayAfterChange(date)}
                  ></CustomCalendar> 
                ): <>{props.guest.stay_after}</>}
              </div>
            </div>
            <div className="th">퇴숙예정일</div>
            <div className="td">
              <div className="datepicker">
                {props.isUpdateMode &&
                (props.roomType !== ROOM_TYPE_CODE.dormitory_room && props.roomType !== ROOM_TYPE_CODE.company_room) ? (
                  // <DatePicker
                  //   value={props.guest.stay_before}
                  //   width={250}
                  //   onChange={(date: string) => onStayBeforeChange(date)}
                  //   isPrevMonthView={true}
                  //   isNextMonthNotView={false}
                  //   min={props.guest.stay_after}
                  // />
                  <CustomCalendar
                    min={props.guest.stay_after}
                    value={props.guest.stay_before}
                    onChange={(date: string) => onStayBeforeChange(date)}
                  ></CustomCalendar> 
                ) : <>{props.guest.stay_before}</>}
              </div>
            </div>
            <div className="th">차량번호</div>
            <div className="td">
              <input type="text"
                className="form_control"
                value={props.guest.guest_car_number1}
                disabled={!props.isUpdateMode}
                onChange={onCarNumberChange}
              />
            </div>
          </div>

          {props.roomType === ROOM_TYPE_CODE.company_room 
            ? (
              <div className="tr">
                <div className="th">차량번호2</div>
                <div className="td">
                  <input type="text"
                    className="form_control"
                    value={props.guest.guest_car_number2}
                    disabled={!props.isUpdateMode}
                    onChange={onCarNumberChange2}
                  />
                </div>
                <div className="th">차량번호3</div>
                <div className="td">
                  <input type="text"
                    className="form_control"
                    value={props.guest.guest_car_number3}
                    disabled={!props.isUpdateMode}
                    onChange={onCarNumberChange3}
                  />
                </div>
                <div className="th blank"></div><div className="td blank"></div>
              </div>
            ): (
                <></>
            )}
          
          {/* 출장자 숙소 - 단체신청만 표시 */}
          {props.roomType === ROOM_TYPE_CODE.business_room_group 
              ? (
                <div className="tr">
                  <div className="th">인솔자 여부</div>
                  <div className="td">
                    <label 
                      className="input_radio" 
                      //htmlFor="whether_select1"
                    >
                      <input
                        type="radio"
                        name={`whether1-${props.guestIdx}`}
                        checked={props.guest.is_leader}
                        onClick={()=> onChangeLeader(true)}
                      />
                      <span className="label_text">여</span>
                    </label>
                    <label 
                      className="input_radio" 
                      //htmlFor="whether_select2"
                    >
                      <input
                        type="radio"
                        name={`whether2-${props.guestIdx}`}
                        checked={!props.guest.is_leader}
                        onClick={()=> onChangeLeader(false)}
                      />
                      <span className="label_text">부</span>
                    </label>
                  </div>
                  <div className="th blank"></div><div className="td blank"></div><div className="th blank"></div><div className="td blank"></div>
                </div>   
              ) 
              : (<></>)
            }

            {props.roomType === ROOM_TYPE_CODE.company_room || props.roomType === ROOM_TYPE_CODE.dormitory_room
              ? (
              <div className="tr">
                <div className="th">입숙희망일</div>
                <div className="td">
                  {props.isUpdateMode?(
                    // <DatePicker
                    //   value={props.guest.checkin_hope}
                    //   width={250}
                    //   onChange={(date: string) => onCheckinHopeChange(date)}
                    //   isPrevMonthView={true}
                    //   isNextMonthNotView={false}
                    //   min={getTodayDate()[0]}
                    // />
                    <CustomCalendar
                      min={getTodayDate()[0]}
                      value={props.guest.checkin_hope}
                      onChange={(date: string) => onCheckinHopeChange(date)}
                    ></CustomCalendar> 
                  ):(
                    <>
                    {props.guest.checkin_hope}
                    </>
                  )}
                </div>
                <div className="th"></div>
                <div className="td">
                </div>
                <div className="th"></div>
                <div className="td">
                </div>
              </div>
            ): (
              <></>
            )}
        </div>
      </div>
    </div>
  );
};

const Type3 = (props: any) => {
  const onNameChange = (e: any) => {
    const temp = [...props.guests];

    temp[props.guestIdx].guest_name = e.target.value;
    props.setGuests(temp);
  };

  const onPhoneChange = (e: any) => {
    const temp = [...props.guests];

    temp[props.guestIdx].guest_cellphone = e.target.value;
    props.setGuests(temp);
  };

  const onChangeGender = (g: number) => {
    if (!props.isUpdateMode) return;
    const temp = [...props.guests];
    temp[props.guestIdx].guest_gender = g;
    props.setGuests(temp);
  };
  const onCarNumberChange = (e: any) => {
    const temp = [...props.guests];

    temp[props.guestIdx].guest_car_number1 = e.target.value;
    props.setGuests(temp);
  };
  const onCarNumberChange2 = (e: any) => {
    const temp = [...props.guests];

    temp[props.guestIdx].guest_car_number2 = e.target.value;
    props.setGuests(temp);
  };
  const onCarNumberChange3 = (e: any) => {
    const temp = [...props.guests];

    temp[props.guestIdx].guest_car_number3 = e.target.value;
    props.setGuests(temp);
  };

  const onCheckinHopeChange = (date: string) => {
    const temp = [...props.guests];
    
    temp[props.guestIdx].checkin_hope = date;
    props.setGuests(temp);
  };
  
  const onStayAfterChange = (date: string) => {
    const temp = [...props.guests];

    temp[props.guestIdx].stay_after = date;
    props.setGuests(temp);
  };

  const onStayBeforeChange = (date: string) => {
    const temp = [...props.guests];

    temp[props.guestIdx].stay_before = date;
    props.setGuests(temp);
  };

  const onChangeLeader = (l: boolean) =>  {
    if (!props.isUpdateMode) return;
    const temp = [...props.guests];
    temp[props.guestIdx].is_leader = l;
    props.setGuests(temp);
  }

  return (
    <div className="table_design_list table_write col_width6">
      <div className="user_info">
        <span className="user_name">이용자</span>
        <NameInput
          type="text"
          className="form_control"
          value={props.guest.guest_name}
          disabled={!props.isUpdateMode}
          onChange={onNameChange}
        />
      </div>
      <div id="table" className="table">
        <div className="tbody">
          <div className="tr">
            <div className="th">성별</div>
            <div className="td">
              <label
                className="input_radio"
                htmlFor="sex_choice1"
                onClick={() => onChangeGender(1)}
              >
                <input
                  type="radio"
                  name={`sex-${props.guestIdx}`}
                  checked={props.guest.guest_gender === 1 ? true : false}
                />
                <span className="label_text">남</span>
              </label>
              <label
                className="input_radio"
                htmlFor="sex_choice2"
                onClick={() => onChangeGender(2)}
              >
                <input
                  type="radio"
                  name={`sex-${props.guestIdx}`}
                  checked={props.guest.guest_gender === 2 ? true : false}
                />
                <span className="label_text">여</span>
              </label>
            </div>
            <div className="th">휴대폰번호</div>
            <div className="td">
              <PhoneInput
                type="text"
                className="form_control"
                value={props.guest.guest_cellphone}
                disabled={!props.isUpdateMode}
                onChange={onPhoneChange}
              />
            </div>
            <div className="th">소속회사 유형</div>
            <div className="td">
              <select
                id=""
                className="form_control"
                value={props.guest.guest_type}
                disabled={true}
              >
                <option value="1">사내 임직원</option>
                <option value="2">계열사 임직원</option>
                <option value="3">외부인</option>
              </select>
            </div>
          </div>
          <div className="tr">
            <div className="th">입숙예정일</div>
            <div className="td">
              <div className="datepicker">
                {props.isUpdateMode && 
                (props.roomType !== ROOM_TYPE_CODE.dormitory_room && props.roomType !== ROOM_TYPE_CODE.company_room && props.roomType !== ROOM_TYPE_CODE.family_room) ? (
                  // <DatePicker
                  //   value={props.guest.stay_after}
                  //   width={250}
                  //   onChange={(date: string) => onStayAfterChange(date)}
                  //   isPrevMonthView={true}
                  //   isNextMonthNotView={false}
                  //   min={getTodayDate()[0]}
                  //   max={props.guest.stay_before}
                  // />
                  <CustomCalendar
                    min={getTodayDate()[0]}
                    max={props.guest.stay_before}
                    value={props.guest.stay_after}
                    onChange={(date: string) => onStayAfterChange(date)}
                  ></CustomCalendar> 
                ): <>{props.guest.stay_after}</>}
              </div>
            </div>
            <div className="th">퇴숙예정일</div>
            <div className="td">
              <div className="datepicker">
                {props.isUpdateMode &&
                (props.roomType !== ROOM_TYPE_CODE.dormitory_room && props.roomType !== ROOM_TYPE_CODE.company_room && props.roomType !== ROOM_TYPE_CODE.family_room) ? (
                  // <DatePicker
                  //   value={props.guest.stay_before}
                  //   width={250}
                  //   onChange={(date: string) => onStayBeforeChange(date)}
                  //   isPrevMonthView={true}
                  //   isNextMonthNotView={false}
                  //   min={props.guest.stay_after}
                  // />
                  <CustomCalendar
                    min={props.guest.stay_after}
                    value={props.guest.stay_before}
                    onChange={(date: string) => onStayBeforeChange(date)}
                  ></CustomCalendar> 
                ) : <>{props.guest.stay_before}</>}
              </div>
            </div>
            <div className="th">차량번호</div>
            <div className="td">
              <input type="text"
                className="form_control"
                value={props.guest.guest_car_number1}
                disabled={!props.isUpdateMode}
                onChange={onCarNumberChange}
              />
            </div>
          </div>

          {props.roomType === ROOM_TYPE_CODE.company_room
            ? (
              <div className="tr">
                <div className="th">차량번호2</div>
                <div className="td">
                  <input type="text"
                    className="form_control"
                    value={props.guest.guest_car_number2}
                    disabled={!props.isUpdateMode}
                    onChange={onCarNumberChange2}
                  />
                </div>
                <div className="th">차량번호3</div>
                <div className="td">
                  <input type="text"
                    className="form_control"
                    value={props.guest.guest_car_number3}
                    disabled={!props.isUpdateMode}
                    onChange={onCarNumberChange3}
                  />
                </div>
                <div className="th blank"></div><div className="td blank"></div>
              </div>
            ): (
                <></>
            )}
          
          {props.roomType === ROOM_TYPE_CODE.business_room_group
              ? (
                <div className="tr">
                  <div className="th">인솔자 여부</div>
                  <div className="td">
                    <label 
                      className="input_radio" 
                      //htmlFor="whether_select1"
                    >
                      <input
                        type="radio"
                        name={`whether1-${props.guestIdx}`}
                        checked={props.guest.is_leader}
                        onClick={()=> onChangeLeader(true)}
                      />
                      <span className="label_text">여</span>
                    </label>
                    <label 
                      className="input_radio" 
                      //htmlFor="whether_select2"
                    >
                      <input
                        type="radio"
                        name={`whether2-${props.guestIdx}`}
                        checked={!props.guest.is_leader}
                        onClick={()=> onChangeLeader(false)}
                      />
                      <span className="label_text">부</span>
                    </label>
                  </div>
                  <div className="th blank"></div><div className="td blank"></div><div className="th blank"></div><div className="td blank"></div>
                </div>   
              ) 
              : (<></>)
            }

            {props.roomType === ROOM_TYPE_CODE.company_room || props.roomType === ROOM_TYPE_CODE.dormitory_room
              ? (
              <div className="tr">
                <div className="th">입숙희망일</div>
                <div className="td">
                  {props.isUpdateMode?(
                    // <DatePicker
                    //   value={props.guest.checkin_hope}
                    //   width={250}
                    //   onChange={(date: string) => onCheckinHopeChange(date)}
                    //   isPrevMonthView={true}
                    //   isNextMonthNotView={false}
                    //   min={getTodayDate()[0]}
                    // />
                    <CustomCalendar
                      min={getTodayDate()[0]}
                      value={props.guest.checkin_hope}
                      onChange={(date: string) => onCheckinHopeChange(date)}
                    ></CustomCalendar> 
                  ):(
                    <>
                    {props.guest.checkin_hope}
                    </>
                  )}
                </div>
                <div className="th"></div>
                <div className="td">
                </div>
                <div className="th"></div>
                <div className="td">
                </div>
              </div>
            ): (
              <></>
            )}
        </div>
      </div>
    </div>
  );
};

const GuestForm = (props: any) => {

  const onStayAfterChange = (date: string) => {
    const temp = [...props.guests];

    props.guests.map((guest: any, guestIdx: number) => 
      temp[guestIdx].stay_after = date
    );

    props.setGuests(temp);
  };

  const onStayBeforeChange = (date: string) => {
    const temp = [...props.guests];

    props.guests.map((guest: any, guestIdx: number) => 
      temp[guestIdx].stay_before = date
    );

    props.setGuests(temp);
  };

  if(!props.guests || props.guests.length <= 0) return <></>;
  
  return (
    <>
    {props.roomType === ROOM_TYPE_CODE.family_room ? (
      <>
        <div className="title_contents">
          <h3 className="title">입숙/퇴숙예정일</h3>
        </div>
        <div className="table_design_list col_width6">
          <div id="table" className="table">
            <div className="tbody">
              <div className="tr">
                <div className="th">입숙예정일</div>
                <div className="td">
                  {props.isUpdateMode? (
                      // <DatePicker
                      //   value={props.guests[0].stay_after}
                      //   width={250}
                      //   onChange={(date: string) => onStayAfterChange(date)}
                      //   isPrevMonthView={true}
                      //   isNextMonthNotView={false}
                      //   min={getTodayDate()[0]}
                      //   max={props.guests[0].stay_before}
                      // />
                      //2024-04-17 수정
                      //<CustomCalendar
                      //  min={getTodayDate()[0]}
                      //  max={props.guests[0].stay_before}
                      //  value={props.guests[0].stay_after}
                      //  onChange={(date: string) => onStayAfterChange(date)}
                      //></CustomCalendar>
                      <>{props.guests[0].stay_after}</>
                    ): <>{props.guests[0].stay_after}</>}
                </div>
                <div className="th">퇴숙예정일</div>
                <div className="td">
                {props.isUpdateMode ? (
                    // <DatePicker
                    //   value={props.guests[0].stay_before}
                    //   width={250}
                    //   onChange={(date: string) => onStayBeforeChange(date)}
                    //   isPrevMonthView={true}
                    //   isNextMonthNotView={false}
                    //   min={props.guests[0].stay_after}
                    // />
                    //2024-04-17 수정
                    //<CustomCalendar
                    //  min={props.guests[0].stay_after}
                    //  value={props.guests[0].stay_before}
                    //  onChange={(date: string) => onStayBeforeChange(date)}
                    //></CustomCalendar> 
                    <>{props.guests[0].stay_before}</>
                  ) : <>{props.guests[0].stay_before}</>}
                </div>
                <div className="th"></div>
                <div className="td"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    ): ''}
    {props.guests.map((guest: any, guestIdx: number) =>
      guest.guest_type === 1 ? (//사내임직원
        <Type1
          isUpdateMode={props.isUpdateMode}
          guest={guest}
          guestIdx={guestIdx}
          key={guestIdx}
          setGuests={props.setGuests}
          guests={props.guests}
          roomType={props.roomType}
        />
      ) : guest.guest_type === 2 ? (//계열사 임직원
        <Type2
          isUpdateMode={props.isUpdateMode}
          guest={guest}
          guestIdx={guestIdx}
          key={guestIdx}
          setGuests={props.setGuests}
          guests={props.guests}
          roomType={props.roomType}
        />
      ) : guest.guest_type === 3 ? (//외부인
        <Type3
          isUpdateMode={props.isUpdateMode}
          guest={guest}
          guestIdx={guestIdx}
          key={guestIdx}
          setGuests={props.setGuests}
          guests={props.guests}
          roomType={props.roomType}
        />
      ) : (
        <></>
      ),
    )}
  </>);
};

export default GuestForm;
