import React from 'react';
import intl from 'react-intl-universal';

interface Props {
  step: number
}

//기숙사_lnb
const DomitorySingleNav = (props: Props) => {

  return (
    <>
      <ol className="lst_lnb">
        <li className={props.step === 1 ? "on" : ""}>
          <span className="num">01</span>
          <strong className="stit">{intl.get("정보 등록")}</strong>
        </li>
        <li className={props.step === 2 ? "on" : ""}>
          <span className="num">02</span>
          <strong className="stit">{intl.get("입숙서약동의")}</strong>
        </li>
        <li className={props.step === 3 ? "on" : ""}>
          <span className="num">03</span>
          <strong className="stit">{intl.get("신청완료")}</strong>
        </li>
      </ol>

      <h4 className="title_step">{intl.get("신청 완료 이후")}</h4>
      <ol className="lst_lnb after">
        <li><span className="num">01</span><strong className="stit">{intl.get("관리자 승인 및 배방오픈")}</strong></li>
        <li><span className="num">02</span><strong className="stit">{intl.get("신청관리에서 배방신청 및 입숙 예정일 입력")}</strong></li>
        <li><span className="num">03</span><strong className="stit">{intl.get("예약 완료")}</strong></li>
      </ol>
    </>
  );
};

export default DomitorySingleNav;
