import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { isMobile } from 'react-device-detect';
import { core } from '../../../apis';
import { MessageContext, MessageImpl } from '../../../routes';
import OnOff from '../../../apis/onOff';
import { FILE_CODE } from '../../../constant';
import { SHOW_MODAL } from '../../../store/modules/modal';

export interface Document {
  id: number
  name: string
  is_required: true
  visible_type: number
}

export interface File {
  id: number
  path: string  // 실제 서버경로
  size: string
  name: string  // 업로드 했을 때 파일명
} 

export interface FlatFile {
  document: number
  id: number
  is_required: true
  name: string
  title: string
  visible_type: number
}

interface Rfc {
  id?: number
  path?: string
  name?: string
}

interface FileFormProps {
  files: any[]
  isUpdateMode: boolean
  onChange: Function
  originFiles: any[]
  isGroup?: boolean  // 출장자 단체인지
  isClean?: boolean
  setIsClean?: Function

  category: number
  guestType: number
  roomType: number
  visibleType: number
  workSite?: number
  employeenumber : string
}

const FileFrm = (props: FileFormProps) => {
  const [originFiles, setOriginFile] = useState<any>(props.originFiles || []);
  const [rfcs, setRfcs] = useState<any[]>(props.files || []);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const [files, setFiles] = useState<FlatFile[]>([]);
  const [rfcFileText, setRfcFileText] = useState<string>(props.files && props.files[0] && props.files[0].file? props.files[0].file.name : '');
  const [reload, setReload] = useState<boolean>(false);
  const [employeenumber, setEmployeenumber] = useState<string>(props.employeenumber);

  /* useEffect(() => {
    // TOOD: 추후 파일 종류가 늘어나면 rfcs.concat(file1, file2) 형태로 하나의 리스틀로 만들어 준다
    props.onChange(rfcs);
  }, [rfcs]) */

  useEffect(() => {
    // 수정 모드가 비활성화 되면 원래의 상태로 돌려준다.
    if (!props.isUpdateMode) {
      setRfcs(originFiles);
      if(props.isGroup && originFiles[0] && originFiles[0].file){
        setRfcFileText(originFiles[0].file.name);
        props.onChange({
          file: originFiles[0].file.id,
          document: originFiles[0].document.id
        });
      }else {
        setReload(!reload);
      }
    }

    // 초기화인 경우 원래 상태로 돌리기
    if (props.isClean) {
      setRfcs(originFiles);
      if(props.isGroup && originFiles[0] && originFiles[0].file){
        setRfcFileText(originFiles[0].file.name);
        props.onChange({
          file: originFiles[0].file.id,
          document: originFiles[0].document.id
        });
      }else{
        setReload(!reload);
      }
      if(props.setIsClean) props.setIsClean();
    }
  }, [props.isUpdateMode, props.isClean]);

  // 수정모드용 -------------------->
  useEffect(() => {
    if ((props.workSite && props.workSite < 0) || props.guestType < 0 || props.roomType < 0) return

    let payload: any = {
      guest_type: props.guestType,
      room_type: props.roomType,
      visible_type: props.visibleType
    };
      
    if (props.workSite) payload.work_site = props.workSite;

    (async () => {
      try {
        let { data } = await OnOff.getDocument(payload)
        
        let files = []
        files = data.map((document: Document) => {
          return rfcs.reduce((acc: any, curVal: any, idx: number) => { 
            // 업로드 했던 파일 추가하기
            if (
              curVal.document?.id === document.id 
            ) {
              acc.document = document.id
              acc.title = document.name
              acc.name = curVal.file.name
              acc.id = curVal.file.id
              acc.is_required = document.is_required
              acc.visible_type = document.visible_type
            }
            
            return acc
          }, {
            document: document.id,
            title: document.name,
            id: -1,
            is_required: document.is_required,
            name: '',
            visible_type: document.visible_type
          })
        }).filter(
          (file: { visible_type: number }) => file.visible_type === props.category
        )

        setFiles(files);
      }catch ({ status, data }) {
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }
    })()
  }, [props.workSite, props.guestType, props.roomType, reload])

  useEffect(() => {
    // console.log(files.filter((file: FlatFile) => file?.document))
    props.onChange(
      files.filter((file: FlatFile) => file?.document)
    );
  }, [files])

  const onUploadHandler = async (e: any, fileIdx: number) => {
    let tempFiles = _.cloneDeep(files);
    let frm = new FormData();

    frm.append("path", e.target.files[0]);

    let extList = ['gif', 'jpg', 'jpeg', 'png', 'hwp', 'pdf', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'docx'];

    let fileData: any = e.target.files[0];
    let fileExt = (fileData.name).split('.').pop().toLowerCase();

    if(!extList.includes(fileExt)){
      let extText = extList.join(', ');
      error(`파일은 `+extText+` 만 가능합니다.`);
      return false;
    }else if (e.target.files[0].size >= 10485760) {      
      error(`파일 용량은 10MB 이상 첨부할 수 없습니다.`);
      return false;
    }

    try {
      let { data } = await core.fileUpload<FlatFile>(frm);
      tempFiles[fileIdx].id = data.id;
      tempFiles[fileIdx].name = data.name;
      setFiles(tempFiles);
      //props.onChange(tempFiles);
    } catch (err) {
      //console.log(err)

      const { status, data } = err;
      // console.log(status, data)

      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;

    }
  }

  // 협조전(RFC) 업로드
  const onRFCUploadHandler = async (e: any) => {
    let frm = new FormData();
    let name = e.target.files[0].name;

    let extList = ['gif', 'jpg', 'jpeg', 'png', 'hwp', 'pdf', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'docx'];

    let fileData: any = e.target.files[0];
    let fileExt = (fileData.name).split('.').pop().toLowerCase();

    if(!extList.includes(fileExt)){
      let extText = extList.join(', ');
      error(`파일은 `+extText+` 만 가능합니다.`);
      return false;
    }else if (e.target.files[0].size >= 10485760) {      
      error(`파일 용량은 10MB 이상 첨부할 수 없습니다.`);
      return false;
    }

    // 10MB 미만일때 업로드
    if (e.target.files[0].size < 10485760) {
      frm.append("path", e.target.files[0]);
      // frm.append('document', FILE_CODE["RFC"].toString());

      try {
        let { data } = await core.fileUpload<Rfc>(frm);

        props.onChange({
          file: data.id,
          path: data.path,
          name: name,
          document: FILE_CODE["RFC"].toString()
        });

        setRfcFileText(name);
        //info("협조전 등록이 완료되었습니다.");
      } catch ({ data, status }) {
        console.log(data, status);

        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        info("협조전 업로드 중 문제가 발생했습니다");
        return;
      }
    } else {
      info("파일의 용량이 큽니다.\n10M 미만의 파일을 등록해 주세요");
      return;
    }
  }
  // 파일의 확장자를 가져오는 함수
  const getFileExtension = (filename: string) => {
    const parts = filename.split('.');
    if (parts.length > 1) {
      return parts.pop()!.toLowerCase(); // 파일 이름이 마침표를 포함하는 경우에만 확장자 추출
    } else {
      return ''; // 마침표가 없는 경우 빈 문자열 반환
    }
  };

  const downloadFile = async (url: string, filename: string) => {
    const token = window.localStorage.getItem('accessToken');
    
    if (!url) {
      console.error('url 경로가 없습니다.');
      return;
    }

    if(!filename || filename.length == 0) {
      const name = url.split("/");
      filename = name[name.length - 1];
    }
   
    const unifiedUrl = url.replace(/\\/g, '/');
    const parts = unifiedUrl.split('/');
    const desiredParts = parts.slice(2).join('\\');
    console.log(desiredParts);
    // 파일의 확장자가 이미지나 PDF인지 확인하는 함수
    const isImageOrPDF = (extension: string) => {
      const supportedExtensions = ['gif', 'jpg', 'jpeg', 'png', 'pdf'];
      return supportedExtensions.includes(extension);
    };
  
    const fileExtension = getFileExtension(filename);
    const isImageOrPDFFile = isImageOrPDF(fileExtension);
  
    //fileDownload
    try {
      let payload: any = {
        filename: filename,
        url : desiredParts,
      };
      
      let { data } = await core.fileDownload(payload);
      console.log(data);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      //info("협조전 등록이 완료되었습니다.");
    } catch ({ data, status }) {
      console.log(data, status);
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }
      info("파일 다운로드 중 문제가 발생했습니다");
      return;
    }
  };
  
  return (
    <>
    {rfcs.length > 0 || (files.length > 0 && props.isUpdateMode)? (
      <div className="title_contents">
          <h3 className="title">첨부서류</h3>
      </div>
    ) : ''}

    <div className="table_design_list col_width2">
      <div id="table" className="table">
          {rfcs.length > 0 || (files.length > 0 && props.isUpdateMode)? (
            <div className="thead mobile">
                <div className="tr">
                    <div className="th"></div>
                    <div className="th">
                        첨부서류
                    </div>
                </div>
            </div>
          ) :''}
          <div className="tbody">
          {!props.isGroup && props.isUpdateMode? ( 
            files.map((file: any, fileIdx: number) => (
              <div className="tr">
                <div className="th">{file.title}{file.is_required ? ' (필수)' : ''}</div>
                <div className="td td_fileupload">
                  <div className="wrap_file w100">
                    <input id="input_copy" className="upload_name form_control" 
                      placeholder={isMobile? file.title + (file.is_required ? ' (필수)' : '') :"파일찾기를 클릭하여 파일을 업로드 하세요"} value={file.name} disabled 
                    />
                    <input
                      type="file"
                      id={`input_upload_copy_${fileIdx}`}
                      className="upload_hidden"
                      onClick={(e: any) => e.target.value = null}
                      onChange={(e: any) => onUploadHandler(e, fileIdx)}
                    />
                    <label htmlFor={`input_upload_copy_${fileIdx}`} className="btn btn_sm btn_outline">파일찾기</label>
                  </div>
                </div>
              </div>
            ))
          ) : props.isGroup && props.isUpdateMode? (
                <div className="tr">
                  <div className="th">협조전</div>
                  <div className="td td_fileupload">
                    <div className="wrap_file w100">
                      <input id="input_copy" className="upload_name form_control" 
                        placeholder={isMobile? '협조전 (필수)' :"파일찾기를 클릭하여 파일을 업로드 하세요"} value={rfcFileText} disabled 
                      />
                      <input
                        type="file"
                        id={`input_upload_copy`}
                        className="upload_hidden"
                        onClick={(e: any) => e.target.value = null}
                        onChange={(e: any) => onRFCUploadHandler(e)}
                      />
                      <label htmlFor={`input_upload_copy`} className="btn btn_sm btn_outline">파일찾기</label>
                    </div>
                  </div>
                </div>
          ) : (
            rfcs.map((rfc: any, rfcIdx: number) => (
              <div className="tr">
                <div className="th">
                  {props.isGroup ? '협조전' : rfc?.document.name }
                </div>
                <div className="td">
                  {rfc?.file?.name}
                  {<button 
                    className="btn_sm btn_darkblue btn_download"
                    onClick={() => downloadFile(`${process.env.REACT_APP_MEDIA}/${rfc?.file?.path}`, rfc?.file?.name)}
                  >
                    다운로드
                   </button>}
                  {/*employeenumber == '1000001'? <button 
                    className="btn_sm btn_darkblue btn_download"
                    onClick={() => downloadFile(`${process.env.REACT_APP_MEDIA}/${rfc?.file?.path}`, rfc?.file?.name)}
                  >
                    다운로드
                  </button>:<a href={`${process.env.REACT_APP_MEDIA}/${rfc?.file?.path}`} className="btn_sm btn_darkblue btn_download"
                    target={`${rfc?.file?.path}`.split('.').pop() 
                        && ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(`${rfc?.file?.path}`.split('.').pop() || '' )
                        && !isMobile ? '_blank' : ''}
                  >다운로드</a>*/}
                  {/*<a href={`${process.env.REACT_APP_MEDIA}/${rfc?.file?.path}`} className="btn_sm btn_darkblue btn_download"
                    target={`${rfc?.file?.path}`.split('.').pop() 
                        && ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(`${rfc?.file?.path}`.split('.').pop() || '' )
                        && !isMobile ? '_blank' : ''}
                  >다운로드</a>*/}
                  {/*<button 
                    className="btn_sm btn_darkblue btn_download"
                    onClick={() => downloadFile(`${process.env.REACT_APP_MEDIA}/${rfc?.file?.path}`, rfc?.file?.name)}
                  >
                    다운로드
                </button>*/}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
    </>
  );
};

export default FileFrm;
