import React, {useState, useEffect} from 'react';
import { useSelector } from 'react-redux';

import intl from 'react-intl-universal';

interface Props {
  value: number
  options: Worksite[]
  onChange: Function
}

interface Worksite {
  is_visible: boolean
  room_type: number
  user_work_site: number
  work_site: number
}

// 신청자 사업장, 선택된 방 타입으로 사업장 표시
const WorkSite = (props: Props) => {
  const { workplaceMap = {} } = useSelector((state: any) => ({
    workplaceMap: state.base.workplaceMap
  }));

  return (
    <>
      <div className="title_contents">
        <h3 className="title">{intl.get("사업장 선택")}</h3>
        <ul className="lst_help">
          <li>{intl.get("이용 사업장을 선택 후 이용자 정보를 입력하여 주세요")}</li>
        </ul>
      </div>

      <div className="form_write form_lg form_select clearfix">
        <div className="row">
          <div className="col">
            {props.options.map((workplace: Worksite, workplaceIdx: number) => (
              <label className="input_radio" key={workplaceIdx}>
                {workplace.is_visible ? 
                  <>
                    <input
                      type="radio"
                      id="radio_workplace1"
                      name="radio_workplace"
                      checked={props.value === workplace.work_site}
                      onChange={e => {props.onChange(workplace.work_site);}}
                    />
                    <span className="label_text">{workplaceMap[workplace.work_site]?.name || workplace.work_site}</span>
                  </>
                :''}
              </label>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkSite;
