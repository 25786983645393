import {action, computed, observable} from 'mobx';
import { LANG_CODE } from '../../constant';

export class ReservationStore {
  
  @observable
  _businessRoomSingle: any = [];
  _dormitoryRoom: any = [];
  _companyRoom: any = [];
  _vipRoom: any = [];
  _familyRoom: any = [];

  @computed
  get businessRoomSingle() : any[] {
    return this._businessRoomSingle;
  }

  @computed
  get dormitoryRoom() : any[] {
    return this._dormitoryRoom;
  }

  @computed
  get companyRoom() : any[] {
    return this._companyRoom;
  }

  @computed
  get vipRoom() : any[] {
    return this._vipRoom;
  }

  @computed
  get familyRoom() : any[] {
    return this._familyRoom;
  }




  /*constructor(root: any) {
    observable(this, {
      businessRoomSingle: observable,
      dormitoryRoom: observable,
      companyRoom: observable,
      vipRoom: observable,
      familyRoom: observable,

      roomInit: action
    });
  }*/

  @action
  public roomInit() {
    this._businessRoomSingle = [];
    this._dormitoryRoom = [];
    this._companyRoom = [];
    this._vipRoom = [];
    this._familyRoom = [];
  }
}
