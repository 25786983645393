import React from 'react';
import { useSelector } from 'react-redux';

import StepNav from '../../components/molecules/reservationStepNav';

import { ROOM_TYPE } from '../../constant';

import intl from 'react-intl-universal';

import './step3.scss';

const Step3 = (props: any) => {
  const { workplaceMap = {} } = useSelector((state: any) => ({
    workplaceMap: state.base.workplaceMap
  }));

  return (
    <>
      <dl className="lst_complete">
        <dt>{intl.get("신청이 완료되었습니다")}</dt>
        {Number(props.workSite) === 2? (
          <>
            <dd>
              {intl.get("이용 신청이 정상적으로 접수 되었습니다. 관리자가 신청 내용 확인 후 사업장 별로 승인 및 배방 오픈이 이루어지며 관련 내용은 마이페이지 > 신청관리 메뉴에서 확인이 가능합니다.")}
              <br />
              {intl.get("코로나 관련하여 개인 위생을 철저히 지켜주시기 바라며, 정부의 행동지침에 따라 주시기 바랍니다. 코로나 의심증상 있을시 연구소 코로나 상황실(031-368-6510)연락주시기 바랍니다.")}
            </dd>
          </>
        ) : (
          <>
            <dd>
              {intl.get("이용 신청이 정상적으로 접수 되었습니다.")}
              <br/>{intl.get("관리자가 신청 내용 확인 후 사업장 별로 승인 및 배방 오픈이 이루어지며")}
              <br/>{intl.get("관련 내용은 마이페이지 > 신청관리 메뉴에서 확인이 가능합니다.")}
            </dd>
          </>
        )}
      </dl>

      <div className="title_contents">
        <h3 className="title">{intl.get("예약정보")}</h3>
        <div className="right_area">
          <button
            className="btn btn_md btn_darkblue"
            onClick={() => props.history.push('/mypage/applicant')}
          >
            {intl.get("신청관리 바로가기")}
          </button>
        </div>
      </div>

      <dl className="lst_reservation">
        <dt>{intl.get("숙소유형")}</dt>
        <dd>{ROOM_TYPE[props.roomType]}</dd>
        <dt>{intl.get("이용사업장")}</dt>
        <dd>{workplaceMap[props.workplace]?.name}</dd>
        {/* <dt>{intl.get("신청 총 이용 인원")}</dt>
        <dd>{props.guests.length}</dd>
        <dt className="blank"></dt>
        <dd className="blank"></dd> */}
      </dl>

      <div className="title_contents">
        <h3 className="title">{intl.get("신청 접수된 이용자 정보")}</h3>
      </div>

      <div className="table_list" style={{ marginTop: 10 }}>
        <table>
          <caption className="sr_only">
            문의안내 - 숙소관리자, HMC 관리자, HEC 관리자, 플랫폼 관리자
            연락처 리스트
                </caption>
          <colgroup>
            <col width="5%" />
            <col width="8%" />
            <col width="8%" />
            <col width="8%" />
            <col width="*" />
            <col width="8%" />
            <col width="8%" />
            <col width="8%" />
            <col width="8%" />
            {/* <col width="8%" /> */}
            <col width="8%" />
            <col width="8%" />
          </colgroup>
          <thead>
            <tr>
              <th>No</th>
              <th>{intl.get("성명")}</th>
              <th>{intl.get("성별")}</th>
              <th>{intl.get("휴대폰")}<br/>{intl.get("번호")}</th>
              <th>{intl.get("회사명(단체명)")}</th>
              <th>{intl.get("소속")}<br/>{intl.get("부서")}</th>
              <th>{intl.get("사번")}<br/>{intl.get("(아이디)")}</th>
              <th>{intl.get("입숙")}<br/>{intl.get("예정일")}</th>
              <th>{intl.get("퇴숙")}<br/>{intl.get("예정일")}</th>
              {/* <th>{intl.get("식권 수 (장)")}</th> */}
              <th>{intl.get("차량번호")}</th>
              <th>{intl.get("인솔자")}<br/>{intl.get("여부")}</th>
            </tr>
          </thead>
          <tbody>
            {props.guests.map((guest: any, idx: number) => (
              <tr key={idx}>
                <td>{idx + 1}</td>
                <td>{guest.guest_name}</td>
                <td>{guest.guest_gender === 1 ? '남자' : '여자'}</td>
                <td>{guest.guest_cellphone}</td>
                <td>{guest.guest_company}</td>
                <td>{guest.guest_department}</td>
                <td>{guest.guest_employee_number}</td>
                <td>{guest.stay_after}</td>
                <td>{guest.stay_before}</td>
                {/* <td>{guest.meal_ticket_request_count}</td> */}
                <td>{guest.guest_car_number1}</td>
                <td>{guest.is_leader ? '여' : '부'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <ul className="lst_help">
      {Number(props.workSite) === 2? (
          <li>
            {intl.get("이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 출장자(단체) 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.")}
          </li>
        ) :(
          <>
            <li>
              {intl.get("신청 접수된 이용자 정보의 본인만이 숙소이용이 가능합니다.")}
            </li>
            <li>
              {intl.get("각 현장별로 관리자가 승인완료 후 신청자가 방선택을 완료하면 관리자 최종승인 후 예약이 완료됩니다.")}
            </li>
          </>
        )}
      </ul>

      <div className="submit btn_length_1">
        <button
          className="btn btn_lg btn_darkblue"
          onClick={() => props.history.push('/home')}
        >
          {intl.get("확인")}
        </button>
      </div>
    </>
  );
};

export default Step3;
