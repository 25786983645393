import React, { useState, useEffect, useContext } from 'react';
import { lodge } from '../../apis';
import ConfirmBtn from '../../components/atoms/button/confirm';
import DefaultTemplete from '../../components/templetes/default';
import { JOB_CODE, MOVE_STATUS_CODE, ROOM_TYPE } from '../../constant';
import { MessageContext } from '../../routes';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react';
import { useStores } from '../../store/mobx';
import { SHOW_MODAL } from '../../store/modules/modal';

const LodgeMoveDetail = observer((props: any) => {
  const {lodgedStore: store} = useStores();
  const { info } = useContext(MessageContext);
  const [deTailData, setDeTailData] = useState<any>([]);
  const [reload, setReload] = useState<boolean>(false);
  const [isImgPop, setIsImgPop] = useState<boolean>(false);
  const [imgPath, setImgPath] = useState<string>('');

  const fetchData = async () => {
    try  {
        let guestId = props.history.location.state.id //guestId 가져오기
        const { data }: any = await lodge.getMoveDetail(guestId);
        
        setReload(true);
        setDeTailData(data);
        return data
    }catch ({ status, data }) {
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '문제발생',
              msg: '문제가 발생했습니다',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
      }
  }

  const moveCancel = async() => {
    try {
        let { data } = await lodge.lodgeMoveApplyDelete(
          props.history.location.state.id
        );
        
        info('이숙 신청이 취소되었습니다.');
        props.history.push('/mypage/move');
      }catch({status, data}){

        if (status === 401) {
            window.location.href = '/force/signout';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }
    

        info('퇴숙신청 중 문제가 발생했습니다.');
        return;
      }
  }

  useEffect(() => {
    (async () => {
        const data = await fetchData();
        store._category = data.category;

        ReactDOM.render(
        <>
        <li><strong>숙소유형</strong><span>{ROOM_TYPE[data.guest.room_type]}</span></li>
        <li><strong>이용사업장</strong><span>{data.guest.room_work_site}</span></li>
        <li><strong>입숙일</strong><span>{data.guest.checkin_at}</span></li>
        <li><strong>입숙만료일</strong><span>{data.guest.expired_at}</span></li>
        <li><strong>배방</strong><span>{data.guest.room_building_name} {data.guest.room_building_dong} {data.guest.room_name}</span></li>
        </>
        , document.getElementById("booking"));

        ReactDOM.render(
            <>
                <div className="th"><span>기존 근무조</span></div>
                <div className="td">{data.guest.guest_party}</div>
            </>
            , document.getElementById("gParty"));

        ReactDOM.render(
            <>
                <div className="th textarea"><span>요청사항 입력</span></div>
                <div className="td textarea">
                    <textarea name="" id="" className="form_control" disabled>{data.claim? data.claim :''}</textarea>
                    <span className="size">{data.claim? data.claim.length : 0} byte / 400 byte</span>
                </div>
            </>
            , document.getElementById("claim"));
            
        ReactDOM.render(
            <>
            <div className="check_area">
                <label className="input_radio">
                    <input type="radio" id="sample_case1" name="sample" checked={data.guest.guest_party === data.party} disabled/><span className="label_text">동일</span>
                </label>
                <label className="input_radio">
                    <input type="radio" id="sample_case2" name="sample" checked={data.guest.guest_party !== data.party} disabled/><span className="label_text">다름</span>
                </label>
                    
                <p className="txt_etc">
                    {JOB_CODE[data.party]}
                </p>
            </div>
            </>
            , document.getElementById("party"));
        })();
  }, [reload]);
  return (
    <DefaultTemplete
      leftInfo={
        <>
          <h2>이숙관리</h2>
          <p className="desc">기숙사 이숙신청 및 현황을 확인하실 수 있습니다</p>
          <div className="bg_img change"></div>
        </>
      }
      id="wrap"
      className="wrap_mypage_manage"
      {...props}
    >
        <div className="title_contents">
            <h3 className="title">기본 정보</h3>
        </div>
        <ul className="lst_status" id="booking">
            
        </ul>

        <div className="title_contents">
            <h3 className="title">이숙신청 정보입력</h3>
            {/*<span className="info_state"><span className="state_room case2">대기5번</span> 입니다</span>*/}
        </div>

        <div className="table_design_write col_width2 detail">
            <div id="table" className="table">
                <div className="tbody">
                    <div className="tr" id="gParty">
                        
                    </div>
                    <div className="tr" >
                        <div className="th"><span className="">실제근무조</span></div>
                        <div className="td" id="party">
                            
                        </div>
                    </div>
                    <div className="tr">
                        <div className="th"><span>이숙요청일</span></div>
                        <div className="td">{deTailData.moved_at}</div>
                    </div>
                    <div className="tr">
                        <div className="th"><span>이숙희망숙소</span></div>
                        <div className="td">{deTailData.desired_room}</div>
                    </div>
                    <div className="tr mix_width2" id="claim">
                        
                    </div>
                    <div className="tr mix_width2 mb_w100">
                        <div className="th"><span className="">비품 이상유무 체크</span></div>
                        <div className="td row">
                            <div className="contents_area images">
                                {deTailData.guest && deTailData.guest.checkin_files ? (
                                <div className="images_area">
                                    {deTailData.guest.checkin_files.map((image: any, index: number) => (
                                        <div key={index} className="img" style={{cursor:'pointer'}} onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                                        <a download>
                                            <img
                                            src={`${process.env.REACT_APP_MEDIA}/${image.path}`}
                                            alt={image.name}
                                            />
                                        </a>
                                        </div>
                                    ))}
                                </div>
                                ) : ''}
                                <ul className="lst_warning gray">
                                    <li>입숙절차 시 관리자와 같이 확인했던 내용입니다. 이미지를 보시고 현재 이용중이 숙소의 이상 유무를 체크해 주세요.
                                    </li>
                                </ul>
                            </div>

                            <div className="radio_area">
                                <label className="input_radio" htmlFor="radio_warning1">
                                    <input type="radio" id="radio_warning1" name="radio_warning" checked={deTailData.is_equipment_ok} disabled />
                                    <span className="label_text">숙소 상태 및 비품이 이상이 있습니다.</span>
                                </label>
                                <label className="input_radio" htmlFor="radio_warning2">
                                    <input type="radio" id="radio_warning2" name="radio_warning" checked={!deTailData.is_equipment_ok} disabled/>
                                    <span className="label_text">숙소 상태 및 비품 등에 이상이 없습니다.</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    {deTailData.is_equipment_ok? (
                        <div className="tr mix_width2">
                            <div className="th textarea"><span>이상 품목 입력</span></div>
                            <div className="td textarea">
                                <textarea name="" id="" className="form_control" disabled>{deTailData.equipment_description? deTailData.equipment_description :''}</textarea>
                                <span className="size">{deTailData.equipment_description? deTailData.equipment_description.length : 0} byte / 400 byte</span>
                            </div>
                        </div>
                    ) :''}
                </div>
            </div>
        </div> {/* table_design_list */}

        <ul className="lst_help mt15">
            <li>이숙 시 숙소 상태 및 비품 등 이상유무를 점검합니다. 관리자가 입력한 이숙확정일이 이숙일로 기산됩니다</li>
            <li>이숙 시 숙소 상태 및 비품 등에 이상이 있거나 짐이 남아 있으면 원상복구 요청 등을 할 수 있으며 이숙완료 처리가 되지 않습니다</li>
        </ul>

        <div className="mb_view">
            <button className="btn btn_lg btn_gray" onClick={() => props.history.push('/mypage/move')}>목록</button>
        </div>

        <div className="submit pt20">
            <div className="fl">
                <button className="btn btn_lg btn_gray" onClick={() => props.history.push('/mypage/move')}>목록</button>
            </div>
                {deTailData.status === MOVE_STATUS_CODE.apply ? (
                <div className="fr">
                    <button className="btn btn_lg btn_outline" 
                        onClick={() => {deTailData.moveId = deTailData.id; props.history.push('/mypage/move/apply', deTailData)}}>
                            수정
                    </button>
                    <ConfirmBtn
                    className="btn btn_lg btn_darkblue"
                    action={() => moveCancel()}
                    label={"신청취소"}
                    title=""
                    msg={"이숙신청을 취소하시겠습니까?"}
                  />
                </div>
                ) : ''}
        </div>

        {/* 이미지 팝업 */}
        <div id="pop_image" className="pop_dim_wrap pop_modal" style={{display: isImgPop? 'block' : 'none'}}>
            <div className="pop_wrap">
                <div className="title_wrap">
                    <a href="javascript:void(0);" className="pop_btn_close" onClick={()=> setIsImgPop(false)}>닫기</a>
                </div>
                <div className="contents_wrap">
                    <div className="slide">
                        <p className="img_area">
                            <img src={imgPath} alt=""/>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </DefaultTemplete>
  );
});

export default React.memo(LodgeMoveDetail);
