type ErrorType = {
  msg: string;
  redirectPath: string | boolean;
};

const initialState: any = {
  msgPopup: {
    title: '',
    msg: '',
    redirectPath: '',
    statusCode: 200,
    errorCode: 0,
    type: 'info',
    isModalShow: false
  },
  confirmPopup: {
    isModalShow: false,
    title: '',
    msg: '',
    action: () => { },
  }
};

export const SHOW_MODAL = 'MODAL/SHOW_MODAL'; // 모달열기
export const CLOSE_MODAL = 'MODAL/CLOSE_MODAL'; // 모달닫기

export const SHOW_CONFIRM_MODAL = 'MODAL/SHOW_CONFIRM_MODAL'; 
export const CLOSE_CONFIRRM_MODAL = 'MODAL/CLOSE_CONFIRRM_MODAL'; 

export const onShowModal = (payload: ErrorType): any => (dispatch: any) => {
  dispatch({
    type: SHOW_MODAL,
    payload,
  });
};

type ModalAction = ReturnType<typeof onShowModal>;

function modalReducer(state: any = initialState, action: ModalAction) {
  switch (action.type) {
    case SHOW_MODAL:
      const { payload }: any = action;
      return {
        ...state,
        msgPopup: {
          title: '',
          msg: payload.msg,
          redirectPath: payload.redirectPath,
          statusCode: 200,
          errorCode: 0,
          type: 'warning',
          isModalShow: true
        }
      };
    
    case CLOSE_MODAL:
      return {
        ...state,
        msgPopup: {
          title: '',
          msg: '',
          redirectPath: '',
          statusCode: 200,
          errorCode: 0,
          type: 'info',
          isModalShow: false
        }
      };
    
    case SHOW_CONFIRM_MODAL:
      const { payload: confirmPopup } = action;
      return {
        ...state,
        confirmPopup
      };
    
    case CLOSE_CONFIRRM_MODAL:
      return {
        ...state,
        confirmPopup: {
          isModalShow: false,
          title: '',
          msg: '',
          action: () => { },
        }
      };
    default:
      return state;
  }
}

export default modalReducer;
