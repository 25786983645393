import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

import useWorkSite from '../../hooks/useWorkSite';

import ConfirmBtn from '../../components/atoms/button/confirm';
import SelectCompany from '../../components/atoms/select/company';
import EmployeeNumberInput from '../../components/atoms/input/employeeNumber';
import PhoneInput from '../../components/atoms/input/phone';
import NameInput from '../../components/atoms/input/name';
import DepartmentInput from '../../components/atoms/input/department';
import WorkSiteSelect from '../../components/atoms/select/workSite';

import GuestType from '../../components/molecules/tab/guestType';

import TermsDetail from '../../components/organismas/termsDetail';

import { MessageContext } from '../../routes';

import { GENDER, WORK_SITE_CODE, ROOM_TYPE_CODE } from '../../constant';

import { blankRemoveJson } from '../../utils/commons';
import { getTodayDate } from '../../utils/date';
import { auth } from '../../apis';

import './step1.scss';
import { observer } from 'mobx-react';
import { useStores } from '../../store/mobx';
import OnOff from '../../apis/onOff';
import { SHOW_MODAL } from '../../store/modules/modal';
import Core from '../../apis/core';

const Step1 = observer((props: any) => {
  const {reservationStore: store} = useStores();
  const today = getTodayDate()
  const { info } = useContext(MessageContext)
  const [ guestType, setGuestType ] = useState<number>(store._vipRoom.type? store._vipRoom.type : 1);
  const [ isSame, setIsSame ] = useState<boolean>(store._vipRoom.isSame? store._vipRoom.isSame : false);
  const todayDate: Number = new Date().getHours();
  const { userInfo = {}, workplaceMap = {} } = useSelector((state: any) => ({
    userInfo: state.user,
    workplaceMap: state.base.workplaceMap
  }));

  const [employeeNumber, setEmployeeNumber] = useState(store._vipRoom.employee_number? store._vipRoom.employee_number : '');
  const [newEmployeeNumber, setNewEmployeeNumber] = useState(store._vipRoom.employee_number? store._vipRoom.employee_number : '');
  const [name, setName] = useState(store._vipRoom.name? store._vipRoom.name : '');
  const [department, setDepartment] = useState(store._vipRoom.department? store._vipRoom.department : '');
  const [phone, setPhone] = useState(store._vipRoom.cellphone? store._vipRoom.cellphone : '');
  const [gender, setGender] = useState(store._vipRoom.gender? store._vipRoom.gender : 1); // 남자(1), 여자(2)
  const [office, setOffice] = useState(store._vipRoom.company? store._vipRoom.company : '');
  const [carNumber, setCarNumber] = useState(store._vipRoom.car_number1? store._vipRoom.car_number1 : '');

  const [privacyPolicyAgreed, setPrivacyPolicyAgreed] = useState<boolean>(store._vipRoom.privacyPolicyAgreed? store._vipRoom.privacyPolicyAgreed : false);

  const [isPosible, setIsPosible] = useState<boolean>(true);

  // const [ worksites, setUserWorkSiteByWorksite, setRoomTypeByWorksite ] = useWorkSite({
  //   roomType: ROOM_TYPE_CODE.vip_room,
  //   userWorkSite: userInfo.worksite
  // })
  const [ worksites, setWorksites ] = useState<any>([]);
  
  useEffect(() => {
    (async () => {
      try {
        if(userInfo.worksite !== 0){
          // 사업장 그리기
          let { data } = await OnOff.getWorksite({
            // work_site: props.value,
            user_work_site: userInfo.worksite,
            room_type: ROOM_TYPE_CODE.vip_room
          });
          
          let { data : workSiteAll } = await Core.getWorkSites({});

          let time_filter: any;
          workSiteAll.forEach((data:any, idx: number)=>{
            if(data.id === 2) time_filter = data;
          })
          
          let current_time = '';
          let hours = new Date().getHours()+"";
          if(hours.length <= 1) {
            hours = "0"+hours;
          }
          let minutes = new Date().getMinutes()+"";
          if(minutes.length <= 1) {
            minutes = "0"+minutes;
          }
          let seconds = new Date().getSeconds()+"";
          if(seconds.length <= 1) {
            seconds = "0"+seconds;
          }
          current_time = hours + ":" + minutes + ":" + seconds;

          let current_day = getTodayDate()[0] + " ";

          let temp = data.map((data:any, idx:number)=>{
            if(data.work_site === 2) {
              if(!(new Date(current_day + time_filter.booking_vip_room_opened_at).getTime() <= new Date(current_day + current_time).getTime() && 
                new Date(current_day + current_time).getTime() <= new Date(current_day + time_filter.booking_vip_room_closed_at).getTime())) {
                data.is_visible = false;
              }
              return data;
            }else {
              return data;
            }
          })
          
          setWorksites(temp);
          
          // 초기 사업장 선택
          if(store._vipRoom.work_site && store._vipRoom.work_site != 0) {
            // 이전버튼 누른 경우 데이터 세팅
            props.setWorkSite(store._vipRoom.work_site);
          }else {
            if(data.filter((d: any) => d.work_site === userInfo.worksite).length > 0){
              props.setWorkSite(userInfo.worksite);
            }else if(data[0].work_site) {
              props.setWorkSite(data[0].work_site);
            }
          }
        }
      }catch ({ status, data }) {
        // console.log(status);
        // console.log(data);
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }
    })();
  }, [userInfo.worksite]);

  useEffect(() => {
    //setUserWorkSiteByWorksite(userInfo.worksite)
  }, [userInfo.worksite])

  const [personalInformationCollectionAgreed, setPersonalInformationCollectionAgreed] = useState<boolean>(store._vipRoom.personalInformationCollectionAgreed? store._vipRoom.personalInformationCollectionAgreed : false);

  useEffect(() => {
    if (props.workSite === WORK_SITE_CODE['남양']) {
      // 08:00~23:59 시간 확인하기
      let [year, month, day] = today[0].split('-');

      let dateS = new Date(parseInt(year), parseInt(month), parseInt(day), 7, 59, 59);
      let dateE = new Date(parseInt(year), parseInt(month), parseInt(day), 23, 59, 59);
      let t = new Date();
      if (dateS < t && t < dateE) {
        setIsPosible(false)
      }
    }
  }, [props.workSite])

  useEffect(() => {
  if(todayDate > 8){
      if (!isPosible) {
        info("예약 불가능 시간입니다.");
        return
      }
    }
  }, [isPosible])

  useEffect(() => {
    /* setEmployeeNumber(userInfo.employeenumber);
    setName(userInfo.username);
    setDepartment(userInfo.department);
    setPhone(userInfo.phone);
    setGender(userInfo.gender);
    setCarNumber(userInfo.car)
    setPrivacyPolicyAgreed(true);
    setPersonalInformationCollectionAgreed(true) */
    // console.log('==========userInfo==========',userInfo)
  }, [userInfo]);

  const init = () => {
    setEmployeeNumber('');
    setName('');
    setDepartment('');
    setPhone('');
    setGender(1);
    setOffice('');
    setCarNumber('');
    setPrivacyPolicyAgreed(false);
    setPersonalInformationCollectionAgreed(false);
    setIsSame(false);
    setGuestType(1);
  };

  const onClickUserSame = (e: any) => {
    if (e.target.checked) {
      setGuestType(1);
      setIsSame(true);

      setEmployeeNumber(userInfo.employeenumber);
      setNewEmployeeNumber(userInfo.employeenumber);
      setName(userInfo.username);
      setDepartment(userInfo.department);
      setPhone(userInfo.phone);
      setGender(userInfo.gender);
      //setPrivacyPolicyAgreed(true);
      //setPersonalInformationCollectionAgreed(true);
      setCarNumber('');

    } else {
      setIsSame(false);
      setEmployeeNumber('');
      setNewEmployeeNumber('');
      setName('');
      setDepartment('');
      setPhone('');
      setGender(1);
      //setPrivacyPolicyAgreed(false);
      //setPersonalInformationCollectionAgreed(false);
      setCarNumber('');
    }
  };

  const next = (e: any) => {
    if(todayDate > 8){
      if (!isPosible) {
        info("예약 불가능 시간입니다.");
        return;
      }
    }

    if (!name) {
      info('성명을 입력해주세요.');
      return;
    }

    if (!phone || !(phone.length === 11)) {
      info('휴대폰번호 항목이 비었거나 잘못되었습니다.\n(휴대폰번호는 11자리입니다.)');
      return;
    }
    if (!gender) {
      info('성별 항목이 비었습니다.');
      return;
    }

    if(guestType === 1 && (newEmployeeNumber !== employeeNumber)) {
      info("사번을 적용해주세요.");
      return;
    }

    if (guestType === 1 && (!employeeNumber || !department)) {
      if (!employeeNumber) {
        info('사번을 검색하여 이용자 정보를 등록해주세요.');
        return;
      }
      if (!department) {
        info('소속부서 항목이 비었습니다.');
        return;
      }
      return;
    } else if (guestType == 2 && (!employeeNumber || !department || !office)) {
      if (!employeeNumber) {
        info('사번을 입력해주세요.');
        return;
      }
      if (!department) {
        info('소속부서 항목이 비었습니다.');
        return;
      }
      if (!office) {
        info('회사 항목이 비었습니다.');
        return;
      }
      return;
    } else if (guestType == 3 && (!office)) {
      if (!office) {
        info('회사 항목이 비었습니다.');
        return;
      }
      return;
    }

    if (!privacyPolicyAgreed || !personalInformationCollectionAgreed) {
      info('서비스이용약관 동의 또는 개인정보이용동의를 동의하지 않았습니다.');
      return;
    }

    const userInfo: any = blankRemoveJson({
      type: guestType,
      employee_number: employeeNumber,
      name: name,
      department: department,
      cellphone: phone,
      gender: gender,
      company: office,
    });

    //빈값으로라도 보내줘야 한다고 함
    userInfo['car_number1'] = carNumber;

    props.setUsers([userInfo]);

    // store 에 모든 데이터 담기!!!!!
    store._vipRoom = [];
    const reservationData: any = ({
      type: guestType,
      employee_number: employeeNumber,
      name: name,
      department: department,
      cellphone: phone,
      gender: gender,
      company: office,
      car_number1: carNumber,
      work_site: props.workSite,
      isSame: isSame,
      privacyPolicyAgreed: privacyPolicyAgreed,
      personalInformationCollectionAgreed: personalInformationCollectionAgreed
    });

    store._vipRoom = reservationData;
    
    // console.log(userInfo);
    props.nextStep();
  };

  const onClickPersonnelHandler = async (e: any) => {
    if(!employeeNumber) {
      info('사번을 입력해주세요.');
      return;
    }
    try {
      const { data } = await auth.isSignedUser(employeeNumber);
      setName(data.name || '');
      setDepartment(data.personnel.department || '');
      setPhone(data.cellphone || '');
      setGender(data.personnel.gender);

      // 입력한 사번데이터 담기
      setNewEmployeeNumber(employeeNumber);
    } catch ({ status, data }) {
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if (status === 404) {
        info(`${employeeNumber}는 가입된 사번이 아닙니다.`);
        return;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  };

  const onEmployeeNumberEnterHandler = async (e: any) => {
    if (e.key === "Enter") {
      onClickPersonnelHandler(e)
    }
  }

  // 이용약관, 개인정보 자세히보기 추가
  const [tabNumber, setTabNumber] = useState<number>(-1);
  const onShowtermsDetailPopupHandler = (idx: number) => {
    setTabNumber(idx);
  }; // 열기
  const onCloseTermsDetailPopupHandler = () => {
    setTabNumber(-1);
  }; // 닫기

  return (
    <>
      <ul className="lst_thumb_request">
        <li>
          <strong>숙소유형</strong>
          <span>영빈관</span>
        </li>
        <li>
          <strong>이용사업장</strong>
          <span>{workplaceMap[props.workSite]?.name}</span>
        </li>
        <li>
          <strong>입숙예정일</strong>
          <span></span>
        </li>
        <li>
          <strong>퇴숙예정일</strong>
          <span></span>
        </li>
      </ul>

      <WorkSiteSelect value={props.workSite} options={worksites} onChange={(workSite: number) => {init(); props.setWorkSite(workSite)}} />

      <div className="title_contents">
        <h3 className="title">신청자 정보</h3>
      </div>

      <div className="table_design_list col_width6">
        <div id="table" className="table">
          <div className="tbody">
            <div className="tr">
              <div className="th">성명</div>
              <div className="td">{userInfo.username}</div>
              <div className="th">사번 (아이디)</div>
              <div className="td">{userInfo.employeenumber}</div>
              <div className="th">성별</div>
              <div className="td">{GENDER[userInfo.gender]}</div>
            </div>
            <div className="tr">
              <div className="th">휴대폰번호</div>
              <div className="td">{userInfo.phone}</div>
              <div className="th">소속부서</div>
              <div className="td">{userInfo.department}</div>
              <div className="th"></div>
              <div className="td"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt20">
        <label className="input_check">
          <input
              type="checkbox"
              checked={isSame}
              onChange={onClickUserSame}
          />
          <span className="label_text">
              신청자와 이용자가 동일합니다 (신청자 본인이 이용할 경우 체크하시면 이용자 정보가 자동 입력 됩니다)
          </span>
        </label>
      </div>

      <div className="title_contents">
        <h3 className="title">이용자 정보</h3>
      </div>

      <div className="tabs_area">
        <GuestType
          value={guestType}
          selectWorkSite={props.workSite}
          roomType={props.roomType}
          onChange={(guestType: number) => {
            //guestType === GUEST_TYPE_CODE.inner_employer ? setIsSame(true) : setIsSame(false);
            setIsSame(false);
            // init()
            setGuestType(guestType)
          }}
        />
      </div>

      {/* 사내 임직원 */}
      <div className={`tab_contents ${guestType === 1 ? 'on' : ''}`}>
        <div className="table_design_list col_width4">
          <div id="table" className="table">
            <div className="tbody">
              <div className="tr">
                <div className="th">사번 (아이디)</div>
                <div className="td">
                  <div className="form_wrap">
                    <EmployeeNumberInput
                      type="text"
                      className="form_control"
                      placeholder="사번을 입력하세요"
                      value={employeeNumber}
                      onChange={(e: any) => {
                        userInfo.employeenumber === e.target.value ? setIsSame(true) : setIsSame(false);
                        setEmployeeNumber(e.target.value)
                      }}
                      onKeyDown={onEmployeeNumberEnterHandler}
                    />
                    <button className="btn_sm btn_gray" onClick={onClickPersonnelHandler}>적용</button>

                  </div>
                </div>
                <div className="td blank"></div>
                <div className="td blank"></div>
              </div>
              <div className="tr">
                <div className="th">성명</div>
                <div className="td">
                  <NameInput
                    type="text"
                    className="form_control"
                    placeholder="성명을 입력하세요"
                    value={name}
                    onChange={(e: any) => setName(e.target.value)}
                    disabled={true}
                  />
                </div>
                <div className="th">성별</div>
                <div className="td">
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="sex_choice1"
                      name="similar-sex1"
                      checked={gender === 1}
                      onChange={e => setGender(e.target.checked ? 1 : 2)}
                      disabled={true}
                    />
                    <span className="label_text">남자</span>
                  </label>
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="sex_choice2"
                      name="similar-sex1"
                      checked={gender === 2}
                      onChange={e => setGender(e.target.checked ? 2 : 1)}
                      disabled={true}
                    />
                    <span className="label_text">여자</span>
                  </label>
                </div>
              </div>
              <div className="tr">
                <div className="th">소속부서</div>
                <div className="td">
                  <DepartmentInput
                    type="text" className="form_control" placeholder="소속부서를 입력하세요"
                    value={department}
                    onChange={(e: any) => setDepartment(e.target.value)}
                    disabled={true}
                  />
                </div>
                <div className="th">휴대폰번호</div>
                <div className="td">
                  <PhoneInput
                    type="text"
                    id="input_cphone"
                    className="form_control"
                    placeholder="휴대폰 번호를 입력하세요"
                    value={phone}
                    onChange={(e: any) => setPhone(e.target.value)}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">차량번호</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    value={carNumber}
                    onChange={e => e.target.value.length < 12 && setCarNumber(e.target.value)}
                    placeholder="차량번호를 입력해주세요"
                  />
                </div>
                <div className="td blank"></div>
                <div className="td blank"></div>
              </div>
            </div>
          </div>

          <div className="tr group_agree">
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term1"
                  checked={privacyPolicyAgreed}
                  onChange={e =>
                    setPrivacyPolicyAgreed(e.target.checked)
                  }
                />
                <span className="label_text">서비스 이용약관 동의(필수)</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(1)}>자세히보기</button>
            </div>
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term2"
                  checked={personalInformationCollectionAgreed}
                  onChange={e =>
                    setPersonalInformationCollectionAgreed(
                      e.target.checked,
                    )
                  }
                />
                <span className="label_text">개인정보 이용 동의(필수)</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(2)}>자세히보기</button>
            </div>
          </div>
        </div>

        <ul className="lst_help bg_gray">
          <li>대리 신청의 경우 사번을 입력하시면 인사 정보 연동하여 기본 정보가 자동 입력 됩니다. 차량번호를 제외한 모든 입력 사항은 필수로 입력하셔야 합니다.</li>
        </ul>
      </div>

      {/* 계열사 임직원 */}
      <div className={`tab_contents ${guestType === 2 ? 'on' : ''}`}>
        <div className="table_design_list col_width4">
          <div id="table" className="table">
            <div className="tbody">
              <div className="tr">
                <div className="th">회사명(단체명)</div>
                <div className="td">
                  <SelectCompany
                    name=""
                    value={office}
                    guestType={guestType}
                    onChange={(e: any) => setOffice(e.target.value)}
                  />
                </div>
                <div className="th">소속부서</div>
                <div className="td">
                  <DepartmentInput
                    type="text"
                    id="input_sdm"
                    className="form_control"
                    placeholder="소속부서를 입력하세요"
                    value={department}
                    onChange={(e: any) => setDepartment(e.target.value)}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">사번</div>
                <div className="td">
                  <EmployeeNumberInput
                    type="text"
                    className="form_control"
                    placeholder="사번을 입력하세요"
                    value={employeeNumber}
                    onChange={(e: any) => setEmployeeNumber(e.target.value)}
                  />
                </div>
                <div className="th">성명</div>
                <div className="td">
                  <NameInput
                    type="text"
                    className="form_control"
                    placeholder="성명을 입력하세요"
                    value={name}
                    onChange={(e: any) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">성별</div>
                <div className="td">
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="sex_choice1"
                      name="similar-sex2"
                      checked={gender === 1}
                      onClick={e => setGender(1)}
                      disabled={false}
                    />
                    <span className="label_text">남자</span>
                  </label>
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="sex_choice2"
                      name="similar-sex2"
                      checked={gender === 2}
                      onClick={e => setGender(2)}
                      disabled={false}
                    />
                    <span className="label_text">여자</span>
                  </label>
                </div>

                <div className="th">휴대폰번호</div>
                <div className="td">
                  <PhoneInput
                    type="text"
                    id="input_sphone"
                    className="form_control"
                    placeholder="휴대폰 번호를 입력하세요"
                    value={phone}
                    onChange={(e: any) =>
                      setPhone(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="tr">
                <div className="th">차량번호</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    value={carNumber}
                    onChange={e => e.target.value.length < 12 && setCarNumber(e.target.value)}
                    placeholder="차량번호를 입력해주세요"
                  />
                </div>
                <div className="td blank"></div>
                <div className="td blank"></div>
              </div>
            </div>
          </div>
          <div className="tr group_agree">
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term1"
                  checked={privacyPolicyAgreed}
                  onChange={e =>
                    setPrivacyPolicyAgreed(e.target.checked)
                  }
                />
                <span className="label_text">서비스 이용약관 동의(필수)</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(1)}>자세히보기</button>
            </div>
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term2"
                  checked={personalInformationCollectionAgreed}
                  onChange={e =>
                    setPersonalInformationCollectionAgreed(
                      e.target.checked,
                    )
                  }
                />
                <span className="label_text">개인정보 이용 동의(필수)</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(2)}>자세히보기</button>
            </div>
          </div>
        </div>

        <ul className="lst_help bg_gray">
          <li>차량번호를 제외한 모든 입력 사항은 필수로 입력하셔야 합니다. </li>
        </ul>
      </div>

      {/* 외부인 */}
      <div className={`tab_contents ${guestType === 3 ? 'on' : ''}`}>
        <div className="table_design_list col_width4">
          <div id="table" className="table">
            <div className="tbody">
              <div className="tr">
                <div className="th">회사명(단체명)</div>
                <div className="td">
                  <input type="text"
                    className="form_control"
                    value={office}
                    onChange={(e: any) => setOffice(e.target.value)}
                    placeholder="회사명을 입력하세요"
                  />
                </div>
                <div className="th">성명</div>
                <div className="td">
                  <NameInput
                    type="text"
                    className="form_control"
                    placeholder="성명을 입력하세요"
                    value={name}
                    onChange={(e: any) => setName(e.target.value)}
                  />
                </div>

              </div>
              <div className="tr">
                <div className="th">휴대폰번호</div>
                <div className="td">
                  <PhoneInput
                    type="text"
                    className="form_control"
                    placeholder="휴대폰 번호를 입력하세요"
                    value={phone}
                    onChange={(e: any) => setPhone(e.target.value)}
                  />
                </div>
                <div className="th">성별</div>
                <div className="td">
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="sex_choice1"
                      name="s3"
                      checked={gender === 1}
                      onChange={e => setGender(e.target.checked ? 1 : 2)}
                    />
                    <span className="label_text">남자</span>
                  </label>
                  <label className="input_radio">
                    <input
                      type="radio"
                      id="sex_choice2"
                      name="s3"
                      checked={gender === 2}
                      onChange={e => setGender(e.target.checked ? 2 : 1)}
                    />
                    <span className="label_text">여자</span>
                  </label>
                </div>
              </div>
              <div className="tr">
                <div className="th">차량번호</div>
                <div className="td">
                  <input
                    type="text"
                    className="form_control"
                    value={carNumber}
                    onChange={e => e.target.value.length < 12 && setCarNumber(e.target.value)}
                    placeholder="차량번호를 입력해주세요"
                  />
                </div>
                <div className="td blank"></div>
                <div className="td blank"></div>
              </div>

            </div>
          </div>

          <div className="tr group_agree">
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term1"
                  checked={privacyPolicyAgreed}
                  onChange={e =>
                    setPrivacyPolicyAgreed(e.target.checked)
                  }
                />
                <span className="label_text">서비스 이용약관 동의(필수)</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(1)}>자세히보기</button>
            </div>
            <div className="th agree_area">
              <label className="input_check check_agree">
                <input
                  type="checkbox"
                  id=""
                  name="check_term2"
                  checked={personalInformationCollectionAgreed}
                  onChange={e =>
                    setPersonalInformationCollectionAgreed(
                      e.target.checked,
                    )
                  }
                />
                <span className="label_text">개인정보 이용 동의(필수)</span>
              </label>
            </div>
            <div className="td agree_area ar">
              <button className="btn btn_sm btn_outline btn_detail" onClick={() => onShowtermsDetailPopupHandler(2)}>자세히보기</button>
            </div>
          </div>
        </div>
        <ul className="lst_help bg_gray">
          <li>차량번호를 제외한 모든 입력 사항은 필수로 입력하셔야 합니다.</li>
        </ul>
      </div>

      <div className="submit">
        <ConfirmBtn
          className="btn btn_lg btn_outline"
          action={() => props.history.replace('/home')}
          label="취소"
          title=""
          msg="이용 신청을 취소 하시겠습니까?"
        />

        <button className="btn btn_lg btn_darkblue" onClick={next}>
          다음
        </button>
      </div>

      {/*이용약관 자세히보기 팝업*/}
      <TermsDetail
        classNameBySelect="form_control"
        tabNumber={tabNumber}
        setTabNumber={setTabNumber}
        onCloseTermsDetailPopupHandler={onCloseTermsDetailPopupHandler}
      />
    </>
  );
});

export default Step1;





