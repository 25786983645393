import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import DefaultTemplete from '../../components/templetes/default';
import Pagination from '../../components/organismas/pagination';
// import Faq from '../../components/templetes/faq';
import { boardSms as api } from '../../apis';
import { formatByString } from '../../utils/date';
import _ from 'lodash';
import { SHOW_MODAL } from '../../store/modules/modal';
import { getViewPageIdx } from '../../utils/commons';

const BoardSmsPage = (props: any) => {
  const [items, setItems] = useState<any[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    (async () => {
      try {
        const payload = {
          offset : offset,
          limit : limit
        }
        const { data } = await api.getSms(payload);
        if(data){
          const count: number = data.count;
          setTotalPage(Math.ceil(count / limit));
          setItems(data.results);
          setTotalCount(count);
      }
      }catch ({ status, data }) {
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }
    })();
  }, []);

  return (
    <DefaultTemplete
      leftInfo={
        <>
          <h2>{intl.get('알림관리')}</h2>
          <p className="desc">
            {intl.get('최근 한달간 알림을 확인하실 수 있습니다')}
          </p>
          <div className="bg_img alarm"></div>
        </>
      }
      id="wrap"
      className="wrap_customer"
      {...props}
    >
      <div className="pc_view">
        <div className="table_normal_list" style={{overflowX: "auto"}}>
          <table>
            <caption className="sr_only">이용관리 목록</caption>
            <colgroup>
              <col width="60px" />
              <col width="*" />
              <col width="120px" />
            </colgroup>
            <thead>
              <tr>
                <th>NO</th>
                <th>내용</th>
                <th>알림일</th>
              </tr>
            </thead>
            <tbody>
              {!items.length ? (
                <tr>
                    <td colSpan={3} className="blank_area type_white">
                        <p className="txt_blank">데이터가 없습니다.</p>
                    </td>
                </tr>
              ) : (
                items.map((item, index) => (
                <tr className="" key={1}>
                  <td>{getViewPageIdx(offset, limit, index)}</td>
                  <td className="al">{item.message}</td>
                  <td>{formatByString(item.send_date, 'yyyy-MM-dd')}</td>
                </tr>
              )))}
            </tbody>
          </table>
        </div>
        <div className="board_paging">
            <ul>
                <Pagination
                    offset={offset}
                    limit={limit}
                    setOffset={setOffset}
                    totalPage={totalPage}
                />
            </ul>
        </div>  {/*board_paging*/}
      </div>
    </DefaultTemplete>
  );
};

export default BoardSmsPage;
