import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;

class BoardSms {
  static URL = `${IP}`;

  /**
   * 알림관리 내역 조회
   * @param payload
   */
  @apiHandler()
  static async getSms(payload: any) {
    // const PATH = `/member/board/sms/`;
    const PATH = `/member/board/smslist/`;

    return await axios.get(`${BoardSms.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
}

export default BoardSms;
