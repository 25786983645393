import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ConfirmBtn from '../../components/atoms/button/confirm';

import { DownloadButton } from '../../components/molecules/download';
import CollapseView from '../../components/molecules/collapse';
import PledgeView from '../../components/molecules/pledge';

import { formatByString_YYYYMMDD } from '../../utils/date';
import { GENDER } from '../../constant';

import intl from 'react-intl-universal';

import './step3.scss';

{/* 사택은 입숙서약서 없음 */ }
const Step2 = (props: any) => {
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user,
  }));
  
  const [isShow, setIsShow] = useState(false);
  const [isAgreed, setIsAgreed] = useState(props.roomType === 1 ? true : true);

  const [guideFile, setGuide] = useState<string>("");
  const [pledgeFile, setPledgeFile] = useState<string>("");

  const next = () => {
    if (isAgreed) {
      props.onSubmitHandler();
      return;
    } else {
      setIsShow(true);
      return;
    }
  };

  return (
    <>
      <div className="title_contents">
        <h3 className="title">{intl.get("신청자 정보")}</h3>
      </div>

      <div className="table_design_list col_width6">
        <div id="table" className="table">
          <div className="tbody">
            <div className="tr">
              <div className="th">{intl.get("사번 (아이디)")}</div>
              <div className="td">{userInfo.employeenumber}</div>
              <div className="th">{intl.get("성명")}</div>
              <div className="td">{userInfo.username}</div>
              <div className="th">{intl.get("성별")}</div>
              <div className="td">{GENDER[userInfo.gender]}</div>
            </div>
            <div className="tr">
              <div className="th">{intl.get("소속 부서")}</div>
              <div className="td">{userInfo.department}</div>
              <div className="th">{intl.get("휴대폰 번호")}</div>
              <div className="td">{userInfo.phone}</div>
              <div className="th"></div>
              <div className="td"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="title_contents">
        <h3 className="title">{intl.get("숙소 이용안내")}</h3>
        <div className="right_area">
          {guideFile && (
            <DownloadButton 
              src={guideFile}
              value={intl.get("파일 다운로드")}
              pc
            />
          )}
        </div>
      </div>

      <CollapseView
        workSite={props.workSite}
        roomType={props.roomType}
        onChangeGuideFile={setGuide}
      />

      <div className="title_contents">
        <h3 className="title">{intl.get("입숙 서약서")}</h3>
        <div className="right_area">
          {pledgeFile && (
            <DownloadButton 
              src={pledgeFile}
              value={intl.get("파일 다운로드")}
              pc
            />
          )}
        </div>
      </div>

      <div className="table_design_list col_width6">
        <div id="table" className="table">
          <div className="tbody">
            <div className="tr">
              <div className="th">{intl.get("사번 (아이디)")}</div>
              <div className="td">{props.guest.type !== 3 ? props.user.employee_number : ""}</div>
              <div className="th">{intl.get("성명")}</div>
              <div className="td">{props.user.name}</div>
              <div className="th">{intl.get("성별")}</div>
              <div className="td">{GENDER[props.user.gender]}</div>
            </div>
            <div className="tr">
              <div className="th">{intl.get("소속 부서")}</div>
              <div className="td">{props.user.department}</div>
              <div className="th">{intl.get("휴대폰 번호")}</div>
              <div className="td">{props.user.cellphone}</div>
              <div className="th"></div>
              <div className="td"></div>
            </div>
          </div>
        </div>
      </div>

      <PledgeView
        workSite={props.workSite}
        roomType={props.roomType}
        onChangePledgeFile={setPledgeFile}
      />

      <div className="form_agree">
        <p className="desc_area">
          {intl.get("사택 입숙 서약서 및 입숙 준비사항에 대한 모든 항목을 확인하였으며 해당 내용에 동의하며 이를 충실히 이행 할 것을 서약합니다.")}
              <span className="right_area">{intl.get("서약 일자")} : {formatByString_YYYYMMDD(new Date().toString())}</span>
        </p>
        <p className="check_area">
          <label className="input_radio">
            <input type="radio" id="radio_sex_omale" name="radio_osex" checked={isAgreed === true} onClick={() => setIsAgreed(true)} /><span className="label_text">{intl.get("동의합니다")}</span>
          </label>
          <label className="input_radio">
            <input type="radio" id="radio_sex_ofemale" name="radio_osex" checked={isAgreed === false} onClick={() => { setIsAgreed(false); setIsShow(true) }} /><span className="label_text">{intl.get("동의하지 않습니다")}</span>
          </label>
        </p>
      </div>

      <div className="submit">
        <button
          className="btn btn_lg btn_outline"
          onClick={() => props.prevStep()}
        >
          {intl.get("이전")}
        </button>
        {isAgreed ? (
          <ConfirmBtn
            className="btn btn_lg btn_darkblue"
            action={() => next()}
            label={intl.get("완료")}
            title=""
            msg={intl.get("예약을 진행하시겠습니까?")}
          />
        ) : (
          <button
            className="btn btn_lg btn_darkblue"
            onClick={() => setIsShow(true)}
          >
            {intl.get("완료")}
          </button>
        )}
      </div>

      {isShow ? (
        <div id="pop_alert_termsagree" className="pop_dim_wrap pop_alert">
          <div className="pop_wrap">
            <button
              className="pop_btn_close pop_close"
              onClick={() => setIsShow(false)}
            >
              {intl.get("닫기")}
            </button>
            <div className="message_table">
              <div className="messageWrap">
              {intl.get("입숙 서약서 내용에 동의를 \n하셔야만 이용 신청이 가능합니다\n동의 여부를 확인하여 주세요")}
                  {/*<br /> intl.get("하셔야만 이용 신청이 가능합니다")}<br />
                  {intl.get("동의 여부를 확인하여 주세요")*/}
              </div>
            </div>
            <div className="button_wrap">
              <button
                type="button"
                className="btn btn_lg btn_darkblue pop_close"
                onClick={() => setIsShow(false)}
              >
                {intl.get("확인")}
              </button>
            </div>
          </div>
        </div>
      ) : (
          <></>
        )}
    </>
  );
};

export default Step2;
