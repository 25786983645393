import React, { useState, useEffect, useContext } from 'react';
import { build, lodge } from '../../../apis';
import Floors from '../../molecules/floor';
import { ROOM_TYPE_CODE } from '../../../constant';
import { MessageContext } from '../../../routes';
import _ from 'lodash';
import './businessGroupBackroom.scss';
import './backroom.scss';
import '../../../pages/businessRoomSingle/step2.scss';
import GroupBuildings from '../../atoms/select/groupBuildings';
import Groupfloor from '../../atoms/select/groupfloor';
import { getStayConfirm } from '../../../utils/date';
import { SHOW_MODAL } from '../../../store/modules/modal';
import { roundToNearestMinutesWithOptions } from 'date-fns/fp';

// 출장자 단체 배방
const BusinessGroupbackroom = (props: any) => {
  const { info } = useContext(MessageContext);
  const [floors, setFloors] = useState<any>([]);
  const [buildings, setBuildings] = useState<any>([]);
  const [building, setBuilding] = useState<any>([]);
  const [selectFloor, setSelectFloor] = useState<number>(0);
  const [stayAfter, setStayAfter] = useState<string>('');
  const [stayBefore, setStayBefore] = useState<string>('');
  const [clickGuest, setClickGuest] = useState<any>([]);
  const [guestRoom, setGuestRoom] = useState<any>();
  const [roomList, setRoomList] = useState<any>([]);

  useEffect(() => {
    if (clickGuest.length === 0) return;
    if (!props.isShowGroupBackroomPop) return;
    if (props.selectGroup.room_type !== ROOM_TYPE_CODE.business_room_group) return;

    (async () => {
      try {
        
        // building 이 바뀔 때마다 floor 다시 그리기
        setSelectFloor(0);
        
        let { data: floors } = await build.getFloors({
          building: building.id,
          room_type: ROOM_TYPE_CODE.business_room_group,
          stay_after: clickGuest.stay_after,
          stay_before: clickGuest.stay_before,
          gender_type: clickGuest.guest_gender
        })
  
        let tempFloors = floors.map((floor: any) => {
          return {
            name: floor.name,
            rooms: floor.rooms.filter((room: any) => {
              room.building_name = building.name + ' ' + building.dong
              return room.room_type === ROOM_TYPE_CODE.business_room_group    // 해당 유형의 숙소타입만 보내기
            })
          }
        }).filter((floor: any) => floor.rooms.length);
        
        setFloors(tempFloors);
      } catch (error) {
        //console.log(error);
        const { status, data }= error;

        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
  
      }
    })();
  }, [building]);

  // 이용자 tr 선택시 배방 그리는 부분
  useEffect(() => {
    if (clickGuest.length === 0) return;
    if (!props.isShowGroupBackroomPop) return;
    if (props.selectGroup.room_type !== ROOM_TYPE_CODE.business_room_group) return;

    (async () => {
      if(roomList && roomList[clickGuest.id]) {
        await setRoomData(clickGuest);
      }
  
      await setStayAfter(clickGuest.stay_after);
      await setStayBefore(clickGuest.stay_before);
  
      try {
        let { data } = await build.getBuilds({
          work_site: props.selectGroup.room_work_site,
          room_type: ROOM_TYPE_CODE.business_room_group
        });
        
        setBuildings(data);

        // 선택한 건물이 있다면 유지
        let tempBuilding = [];
        if(building.id){
          tempBuilding = data.filter((b: any) => b.id === building.id);
          
        }else {
          
          let temp = data.length? data[0]: [];
          tempBuilding.push(temp);
          setBuilding(data.length? data[0]: []);
          setSelectFloor(0);
        }
        //setBuilding(data.length? data[0]: []);
        let { data: floors } = await build.getFloors({
          building: tempBuilding[0].id,
          room_type: ROOM_TYPE_CODE.business_room_group,
          stay_after: clickGuest.stay_after,
          stay_before: clickGuest.stay_before
        })
  
        let tempFloors = floors.map((floor: any) => {
          return {
            name: floor.name,
            rooms: floor.rooms.filter((room: any) => {
              room.building_name = data[0].name + ' ' + data[0].dong
              return room.room_type === ROOM_TYPE_CODE.business_room_group    // 해당 유형의 숙소타입만 보내기
            })
          }
        }).filter((floor: any) => floor.rooms.length);
  
        setFloors(tempFloors);
      } catch (error) {
        // console.log(error);
        const { status, data } = error;

        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
  
      }
    })();
  }, [clickGuest]);

  const roomInit = () => {
    setStayAfter('');
    setStayBefore('');
    setClickGuest([]);
    setGuestRoom([])
    setRoomList([]);
    setFloors([]);
    setBuilding([]);
    setBuildings([]);
    setSelectFloor(0);
  }

  // 배방시 room 칸 클릭
  const onRoomClickHandlerToSelect = (selectedRoom: any, idx: number) => {
    let tempList : any = {...roomList};
    //let tempRoom: any = _.cloneDeep(store.room);
    
    let tempRoom: any = {};
    tempRoom[selectedRoom.id] = selectedRoom;
    tempRoom['room'] = selectedRoom;
    tempRoom[clickGuest.id] = clickGuest;
    tempRoom['guest'] = clickGuest;
    tempRoom['badIdx'] = idx;     // 선택한 방이 2인 이상인 경우때문에 badIdx 담기
    // store.room = tempRoom;        // 선택한 방 담기
    tempList[clickGuest.id] = tempRoom;

    props.selectGroup.guests.map((temp: any) => {
      if(temp.id === clickGuest.id){
          temp.tempRoom = selectedRoom.building_name + ' ' + selectedRoom.name; 
      }
    });

    setRoomList(tempList);
    // console.log('roomlist', roomList);
    //setSelectBadIdx(idx);  
  }

  // 선택 풀기
  const onRoomClickHandlerToUnSelect = (room: any) => {
    //store.room = [];
  }

  // 배방 등록
  const onCompleteHandler = async () => {
    // console.log(roomList);
    if(Object.keys(roomList).length <= 0){
      info('배방정보를 입력해주세요');
      return false;
    }else if(Object.keys(roomList).length !== Object.keys(props.selectGroup.guests).length) {
      info('선택하신 방과 이용자 수가 맞지 않습니다');
      return false;
    }

    let pairs: any = Object.keys(roomList).map((room: any, idx: number) => {
          // console.log(room)
          let temp: any = {};
          temp["room"] = roomList[room]['room'].id;
          temp["guest"] = roomList[room]['guest'].id;
          return temp;
    });

    let payload: any ={
      "pairs" : pairs
    }
    // console.log('payload', payload);
    try {   // 단체
      const { data } = await lodge.assignBackroom(props.selectGroup.id, payload);
      //store.roomInit();
      info('배방이 완료되었습니다.');
      roomInit();
      props.onClose();
      props.onRefresh();
    } catch ({ data, status }) {
      //console.log(data, status);

      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info(data?.code);
      return;
    }
  }
  
  // 사용 불가능한 방
  const isDisable = (room: any, idx: number) => {
    let chk = true;
    let tempChk = false;

    props.selectGroup.available_rooms.map((ar: any, aIdx: number) => {
      if(ar.id === room.id && !tempChk){
        tempChk = true;
        chk = false;
      }
    });

    if(roomList){
      Object.keys(roomList).map((listId: any, gidx: number) =>  {
        if(roomList[listId][room.id] && roomList[listId]['guest'].id !== clickGuest.id && 
            //(roomList[listId]['guest'].stay_after === stayAfter || roomList[listId]['guest'].stay_before === stayBefore) 
            //getStayConfirm(roomList[listId]['guest'].stay_after, roomList[listId]['guest'].stay_before, stayAfter, stayBefore) && 
            roomList[listId]['badIdx'] === idx){
          chk = true;
        }
      })
    }

    if(room.room_type !== ROOM_TYPE_CODE.business_room_group ||
      (room.gender_type !== 0 && room.gender_type !== clickGuest.guest_gender)){
        chk = true;
    }
    
    return chk;
  }

  // 방 선택 표시
  const isSelect = (room: any, idx: number) => {
    if(roomList && roomList[clickGuest.id]){
      let temp : any = roomList[clickGuest.id]
      return (temp[room.id] && temp['badIdx'] === idx);
    } 

    /*if(props.selectGroup.available_rooms[room.id]){
      return room
    }*/
    //return props.selectGroup.available_rooms[room.id]
  }

  const setRoomData = async (guest : any) => {
    let roomData: any = [];
    let listData: any =roomList[guest.id];
    let roomId: string = listData['room'].id;
    roomData[roomId] = listData['room'];
    setGuestRoom(roomData);
  }

  // 배방 진행 초기화
  const roomListInit = () => {
    setRoomList([]);
    props.selectGroup.guests.map((temp: any) => {
      temp.tempRoom = '';
    });
  }

  if (!props.isShowGroupBackroomPop) return <></>;
  if (props.selectGroup.room_type !== ROOM_TYPE_CODE.business_room_group) return <></>;

  return (
    <div id="pop_request_group" className="pop_dim_wrap pop_modal pop_lg" style={{display: props.isShowGroupBackroomPop? 'block' : 'none'}}>
      <div className="pop_wrap">
          <div className="title_wrap">
              <h3>배방진행</h3>
              <button className="pop_btn_close pop_close" onClick={props.onClose}>닫기</button>
          </div>
          <div className="contents_wrap">
              <div className="row pt0">
                  <div className="col col_list">
                      <h3 className="title_contents bd0">
                          신청정보 총 이용인원 {Object.keys(props.selectGroup.guests).length}명
                      </h3>
                      <div className="table_list">
                          <table>
                              <caption className="sr_only"></caption>
                              <colgroup>
                                <col width="*"/>
                                <col width="15%"/>
                                <col width="20%"/>
                                <col width="20%"/>
                                <col width="20%"/>
                              </colgroup>
                              <thead>
                              <tr>
                                  <th>성명</th>
                                  <th>성별</th>
                                  <th>입숙일</th>
                                  <th>퇴숙일</th>
                                  <th>숙소정보</th>
                              </tr>
                              </thead>
                              <tbody>
                              {Object.keys(props.selectGroup.guests).length > 0 ? props.selectGroup.guests.map((group: any, groupIdx: number) => (   
                                <tr key={groupIdx} 
                                  onClick={() => setClickGuest(group)} 
                                  style={{cursor: 'pointer'}} 
                                  className={group.tempRoom? 'selected' : clickGuest && clickGuest.id === group.id? 'active' : ''}
                                >
                                    <td>{group.guest_name}</td>
                                    <td>{group.guest_gender === 1? '남' : '여'}</td>
                                    <td>{group.stay_after}</td>
                                    <td>{group.stay_before}</td>
                                    <td>{group.tempRoom}</td>
                                </tr>
                              )) : '' }
                              <tr>
                                  <td colSpan={5}>
                                    배방 오픈정보
                                  </td>
                              </tr>
                              <tr>
                                  <td colSpan={5}>
                                      <ul className="lst_dong clearfix">
                                          {props.selectGroup.available_rooms.map((room: any, idx: number) => 
                                            (
                                              <li key={idx}>
                                                  <span className="dong">{room.building_name + ' ' + room.building_dong}</span>
                                                  <span className="ho">{room.name}호</span>
                                              </li>
                                            ))
                                          }
                                      </ul>
                                  </td>
                              </tr>
                              </tbody>
                          </table>
                      </div>
                  </div>

                  <div className="col col_room">
                      <h3 className="title_contents">
                          배방 정보
                          <div className="function_area" style={{float: 'right'}}>
                            <button className="btn btn_sm btn_reset" onClick={() => roomListInit()}>초기화</button>
                          </div>
                      </h3>

                      <div className="form_select clearfix" id="building">
                        <GroupBuildings
                            changeBuildHandler={(e: any) => {
                              setBuilding(buildings[parseInt(e.target.value)]);
                              //getFloorList();
                            }}
                            building={building}
                            buildings={buildings}
                            dongs={[]}
                          />
                        <Groupfloor 
                            floors={floors} value={selectFloor} isShowAllOption={false}
                            onChangeFloor={(e:any) => {
                                setSelectFloor(e.target.value); //store.room = store.floors[e.target.value]
                            }} 
                        />
                      </div>

                      <div className="box_scroll_x mt20" >
                        <Floors
                          floors={floors}
                          selectFloor={selectFloor}
                          onRoomClickHandlerToSelect={onRoomClickHandlerToSelect}
                          onRoomClickHandlerToUnSelect={onRoomClickHandlerToUnSelect}
                          //detail={detail}
                          isDisable={isDisable}
                          isSelect={isSelect}
                          floorHead=""
                        />
                      </div>
                      <p className="txt_help">방을 클릭하시면 순서대로 배방이 됩니다</p>
                      <p className="txt_help" style={{color:'#f1615d'}}>왼쪽 리스트에서 배방하실 사람을 클릭하시면 입숙, 퇴숙일에 맞는 방정보가 표시됩니다</p>
                  </div>
              </div>
          </div>
          <div className="button_wrap btn_length_1">
              <button type="button" className="btn btn_xlg btn_darkblue pop_close" onClick={onCompleteHandler}>완료</button>
          </div>
      </div>
  </div>
  );
};

export default BusinessGroupbackroom;
