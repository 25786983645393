import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { withRouter } from 'react-router-dom';

import { CLOSE_MODAL } from '../store/modules/modal';

/**
 * 
 * @param type
 *     info
 *     warning
 *     error
 */

const _Modal = (props: any) => {
  const {
    title,
    msg,
    redirectPath,
    statusCode,
    errorCode,
    type,
    isModalShow,
  } = useSelector((state: any) => ({
    title: state.modal.msgPopup.title,
    msg: state.modal.msgPopup.msg,
    redirectPath: state.modal.msgPopup.redirectPath,
    statusCode: state.modal.msgPopup.statusCode,
    errorCode: state.modal.msgPopup.errorCode,
    type: state.modal.msgPopup.type,
    isModalShow: state.modal.msgPopup.isModalShow,
  }));

  const dispatch = useDispatch();

  const close = () => {
    dispatch({ type: CLOSE_MODAL });
    if (redirectPath) {
      props.history.push(redirectPath);
    }
  };

  const getMsg = () => msg && msg.split('\n').map((word: any) => <p>{word}</p>);

  const colors: {[key: string]: string} = {
    info: '',
    // warning: '#F6B217',
    // error: '#FF3C2F'
    warning: '',
    error: ''
  }
  {
  }
  const style: React.CSSProperties = {
    /*position: "fixed",
    right: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    zIndex: 99999,
    background: "#00000080",*/
  }

  return (
    <>
      <div
        id="pop_alert_pw"
        className="pop_dim_wrap pop_alert"
        style={{ ...style, display: isModalShow ? 'block' : 'none',  }}
      >
        <div className="pop_wrap">
          <button
            className="pop_btn_close pop_close"
            onClick={() => close()}
          >
            닫기
          </button>

          <div className="message_table">
            <div className="messageWrap" style={{color: colors[type]}}>{getMsg()}</div>
          </div>
          <div className="button_wrap">
            <button
              type="button"
              className="btn btn_lg btn_darkblue pop_close"
              style={{background: colors[type], borderColor: colors[type]}}
              onClick={() =>close()}
            >
              확인
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(withRouter(_Modal));
