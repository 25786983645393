import React from 'react';
import { useState, useEffect } from 'react';

import CalendarNavButton from '../../atoms/button/calendarNavBtn';

import {
  getPrevDay, getPrevMonth, getNextMonth, getTodayDate, rightBig
} from '../../../utils/date';

// focusDate = ['yyyy.MM.dd', 0]
// type = day/month
// onClickByNavPrev => 이전버튼 콜백(다음날, 다음달 날짜)
// onClickByNavNext => 다음버튼 콜백(다음날, 다음달 날짜)

const DayNav = (props: any) => {
  const today = getTodayDate();
  //const [isNextMonthButtonView, setIsNextMonthButtonView] = useState<boolean>(true);

  //if(!props.isNextMonthButtonView)
  //  setIsNextMonthButtonView(props.isNextMonthButtonView);

  const onClickPrev = () => {
    if (!props.type || props.type === 'day') {
      const prevDate: [string, number] = getPrevDay(props.focusDate[0], 1);
      props.onClickByNavPrev(prevDate);
    } else if (props.type === 'month') {
      const prevDate: [string, number] = getPrevMonth(props.focusDate[0], 1);
      props.onClickByNavPrev(prevDate);
    }
  };

  const onClickNext = () => {
    if (!props.type || props.type === 'day') {
      const nextDate: [string, number] = getNextMonth(props.focusDate[0], 1);
      props.onClickByNavPrev(nextDate);
    } else if (props.type === 'month') {
      const nextDate: [string, number] = getNextMonth(props.focusDate[0], 1);
      props.onClickByNavPrev(nextDate);
    }
  };
  
  return (
    <div
      style={{
        clear: 'both',
        textAlign: 'center',
        padding: 20,
        marginBottom: 5,
      }}
    >
      {/*props.isPrevMonthView || rightBig(today[0], props.focusDate[0])
        ? (
          <div style={{ display: 'inline-block', float: 'left', marginBottom: 5, width: 36 }}>
            <CalendarNavButton onClick={onClickPrev}>&lt;</CalendarNavButton>
          </div>
        )
        : (<div style={{ display: 'inline-block', float: 'left', marginBottom: 5, width: 36 }} />)
      */}

      {props.isNextMonthButtonView ? (props.isPrevMonthView || rightBig(today[0], props.focusDate[0])
        ? (
          <div style={{ display: 'inline-block', float: 'left', marginBottom: 5, width: 36 }}>
            <CalendarNavButton onClick={onClickPrev}>&lt;</CalendarNavButton>
          </div>
        )
        : (<div style={{ display: 'inline-block', float: 'left', marginBottom: 5, width: 36 }} />)) : 
        (<div style={{ display: 'inline-block', float: 'left', marginBottom: 5, width: 36 }} />)
      }

      <div style={{ display: 'inline-block', marginBottom: 5, fontSize: 17, fontWeight: 900, padding: 5 }}>
        {props.focusDate[0].split('-').slice(0, 2).join('. ')}
        {/* ({dayByNumber[props.focusDate[1]]}) */}
      </div>

      {props.isNextMonthButtonView ? (!props.isNextMonthNotView
      ? (
        <div style={{ display: 'inline-block', float: 'right', marginBottom: 5 }}>
          <CalendarNavButton onClick={onClickNext} marginLeft={4}>&gt;</CalendarNavButton>
        </div>
      ) : (
        <div style={{ display: 'inline-block', float: 'right', marginBottom: 5 }}>        
        </div>
      )) : (<div style={{ display: 'inline-block', float: 'right', marginBottom: 5 }}>        
      </div>)}
    </div>
  );
};

export default DayNav;
