import React, {useEffect, useState, useRef} from 'react';
import { passwordValid } from '../../../utils/validation';

interface Props {
  onChange: Function
  onFocus?: Function
  onEnter?: Function
  onBlur?: Function
  id?: string
  className?: string
  placeholder: string
  value: string
  validMsg?: string
}


//비밀번호 input
const Password = (props: Props) => {
  const inputRef = useRef<any>(null);
  const [isCapsLock, setIsCapsLock] = useState<boolean>(false);
  const [value, setValue] = useState<string>(props.value);
  const [isValid, setIsVaild] = useState<boolean>(false);

  useEffect(() => {
    let timeoutId: any;
    if (isCapsLock) {
      timeoutId = setTimeout(() => {
        setIsCapsLock(false)
        clearTimeout(timeoutId)
      }, 1000)
    }
    return () => clearTimeout(timeoutId);
  }, [isCapsLock])

  const changedProps = {
    ...props,
    
    onChange: (e: any) => {
      if (e.target.value.length <= 20) {
        setValue(e.target.value);
        props.onChange(e);
      }
    },

    onKeyDown: (e: any) => {
      let isCapsLock = e.getModifierState('CapsLock')
      setIsCapsLock(isCapsLock);
    
      if (e.key === 'Enter' && props.onEnter) props.onEnter()
    },

    onBlur: (e: any) => {

      setIsVaild(passwordValid(value, value))
      
      if(props.onBlur){
        props.onBlur(true);
      }
    },

    onClick: (e: any) => {
      let isCapsLock = e.getModifierState('CapsLock')
      setIsCapsLock(isCapsLock);
    },

    onFocus: (e: any) => {
      // e.getModifierState('CapsLock')
      // console.log(e);
      // console.log(props);
      // let a = inputRef?.current?.click();
      let B = inputRef?.current?.focus();

      if(props.onFocus){
        props.onFocus(false);
      }
    }

  };

  return (
    <>
      <input type="password" {...changedProps} ref={inputRef}/>
      {/*<span style={{color: "red"}}>{isCapsLock? "캡슬락키가 활성화 됐습니다": ""}</span>*/}
      <ul className="lst_warning">
        {isCapsLock? <li>Capslock 키가 활성화 됐습니다</li>: ""}
        {isValid && props.validMsg ? <li>{props.validMsg}</li> : ""}
      </ul>
    </>
  );
};

export default Password;
