import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;

class Audit {
  static URL = `${IP}`;

  /**
   * 정기서류관리 내역
   * @param payload
   */
  @apiHandler()
  static async getAuditList(payload: any) {
    const PATH = `/member/board/audit/exhibit/`;

    return await axios.get(`${Audit.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  /**
   * 정기서류관리 내역
   * @param payload
   */
  @apiHandler()
  static async attachAudit(id: string, payload: any) {
    const PATH = `/member/board/audit/exhibit/${id}/`;

    return await axios.patch(`${Audit.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
}

export default Audit;
