import React from 'react';

const LodgeBad = (props: any) => {
 /* if (props.isDisable) {
    return <DisableBad />;
  } */ 
  if (props.selected) {
    return (
      <a href="javascript:void(0);" className="on">{props.room.name}</a>
    )
  }
  
  return (
    <a href="javascript:void(0);">{props.room.name}</a>
  )
};

export default LodgeBad;
