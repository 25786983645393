import React, { useState, useEffect, useContext } from 'react';
import intl from 'react-intl-universal';
import DefaultTemplete from '../../components/templetes/listDefault';
import { NOTICE_TYPE, NOTICE_TO_CASE, SEARCH_TYPE } from '../../constant';
import { notice } from '../../apis';
import { formatByString, isAfterAndSame, getNextDay, getPrevDay, getTodayDate, getNextMonth, getPrevMonth, getTwoLatersMonthDate, getTwoLatersOldMonthDate } from '../../utils/date';
import DatePicker from '../../components/organismas/datePicker';
import Select from '../../components/atoms/select';
import { MessageContext, MessageImpl } from '../../routes';
import { useSelector } from 'react-redux';
import { parserEscape } from '../../utils/commons';
import CustomCalendar from '../../components/organismas/customCalendar';
import { SHOW_MODAL } from '../../store/modules/modal';


const NoticePage = (props: any) => {

  const [reload, setReload] = useState<boolean>(false);
  
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user,
  }));
  const [limit, setLimit] = useState<number>(12);
  const [offset, setOffset] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [category, setCategory] = useState<number>(-1);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [searchType, setSearchType] = useState<number>(-1);
  const [created_after, setCreated_after] = useState<string>(getTwoLatersOldMonthDate(2)[1]);
  const [created_before, setCreated_before] = useState<string>(getTodayDate()[0]);
  const [search, setSearch] = useState<string>('');
  const [searchCategory, setSearchCategory] = useState<number>(-1);
  const [items, setItems] = useState<any[]>([]);
  const [isNext, setIsNext] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const searchTypes = [{ value: '-1', label: '전체', optionIdx: 0 }];
  Object.keys(SEARCH_TYPE).forEach((key: string, index: number) => {
    searchTypes.push({
      value: key,
      label: SEARCH_TYPE[Number(key)],
      optionIdx: index + 1,
    });
  });

  useEffect(() => {

    (async () => {
      let employee_number = userInfo.employeenumber;

      setLimit(12);
      setOffset(0);
      setSearchType(-1);
      setSearch('');
      setCreated_after(getTwoLatersOldMonthDate(2)[1]);
      setCreated_before(getTodayDate()[0]);

      const data = await fetchItems({
        category,
        limit : 12,
        offset: 0,
        searchType : -1,
        search : '',
        created_after : getTwoLatersOldMonthDate(2)[1],
        created_before : getTodayDate()[0],
      });
      
      //const data = await fetchItems({ category, limit, offset: 0});
      // console.log('userInfo>>>>>>>', userInfo);
      setIsSearch(false);
      setLimit(12);
      setOffset(0);
      setSearchType(-1);
      setSearch('');
      setCreated_after(getTwoLatersOldMonthDate(2)[1]);
      setCreated_before(getTodayDate()[0]);
      setItems(data.results || []);
      setIsNext(!!data.next);
      setTotalPage(Math.ceil(data.count / limit));
      
    })();
  }, [category, reload]);

  const fetchItems = async (payload: any) => {

    // console.log('payload', payload);

    if (!payload.category || payload.category < 0) {
      delete payload.category;
    }

    switch (payload.searchType) {
      case 1: payload.title = payload.search; break;
      case 2: payload.contents = payload.search; break;
      case 3: payload.author = payload.search; break;
    }
    //delete payload.searchType;

    if (!created_after) {
      delete payload.created_after;
    }

    if (!created_before) {
      delete payload.created_before;
    }
    
    try {
      const { data } = await notice.getNotices(payload);
      setReload(true);
      return data;
    }catch ({ status, data }) {
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  };

  const onSearchTypeFilter = (_searchType: number) => {
    // console.log(_searchType);
    setSearchType(_searchType);
  };

  const onSearchHandler = async () => {
    const data = await fetchItems({
      category,
      limit,
      offset: 0,
      searchType,
      search,
      created_after,
      created_before,
    });
    setIsSearch(true);
    setItems(data.results);
    setIsNext(!!data.next);
    setTotalPage(Math.ceil(data.count / limit));
  };

  const onNextHandler = async () => {
    if (isNext) {

      const payload: any = {
        isSearch: isSearch ? searchCategory : category,
        limit,
        offset: limit * (currentPage + 1),
      }
      if (search) {
        payload.title = search;
      }
      const data = await fetchItems(payload);
      const tmpItems =
        items.length > 0 ? [...items, ...data.results] : data.results;
      setItems(tmpItems);
      setIsNext(!!data.next);
      setTotalPage(Math.ceil(data.count / limit));
      setCurrentPage(currentPage + 1);
    }
  };

  const removeTags = (text: string) =>
    text.replace(/<(\/)?([a-zA-Z]*)(\s[a-zA-Z]*=[^>]*)?(\s)*(\/)?>/gi, '');

  const { info }: MessageImpl = useContext(MessageContext);
  //등록일자 관련 handler
  const onClickDateAfterByCalendar = (day: string) => {
    // dateAfter <= dateBefore
    const _isAfter = isAfterAndSame(day, created_before);
    //props.setDateAfter(day);
    setCreated_after(day);

    if (!_isAfter || day === created_before) {
      setCreated_after(getPrevDay(created_before)[0]);
      info(`${created_before}보다 이후가 될 수 없습니다.`);
    }
  };

  const onClickDateBeforeByCalendar = (day: string) => {
    // dateAfter <= dateBefore
    const _isAfter = isAfterAndSame(created_after, day);
    setCreated_before(day);
    if (!_isAfter) {
      setCreated_before(getNextDay(created_after)[0]);
      info(`${created_after}보다 이전이 될 수 없습니다.`);
    }
  };

  return (
    <DefaultTemplete
      leftInfo={
        <>
          <h2>{intl.get('공지사항')}</h2>
          <p className="desc">
            {intl.get('숙소이용을 위한 공지사항을 확인하실 수 있습니다')}
          </p>
          <div className="bg_img notice"></div>
        </>
      }
      id="wrap"
      className="wrap_customer"
      {...props}
    >
      <div className="tabs_area">
        <ul className="tabs">
          <li style={{ display: '' }} onClick={() => setCategory(-1)}>
            <a
              href="javascript:void(0)"
              className={`${category < 1 ? 'on' : ''}`}
            >
              {intl.get('전체')}
            </a>
          </li>
          {Object.keys(NOTICE_TYPE).map((key: string, index: number) => (
            <li
              key={index}
              style={{ display: '' }}
              onClick={() => setCategory(Number(key))}
            >
              <a
                href="javascript:void(0)"
                className={`${category === Number(key) ? 'on' : ''}`}
              >
                {NOTICE_TYPE[Number(key)]}
              </a>
            </li>
          ))}
        </ul>
      </div>

      <div className="table_design_list col_width2">
        <div id="table" className="table">
          <div className="thead mobile">
            <div className="tr">
              <div className="th"></div>
              <div className="th">
                {intl.get('검색')}
              </div>
            </div>
          </div>
          <div className="tbody">
            <div className="tr">
              <div className="th">{intl.get('등록일자')}</div>
              <div className="td">
                <div className="group_datepicker">
                  {/* <DatePicker
                    value={created_after}
                    width={250}
                    onChange={(date: string) => onClickDateAfterByCalendar(date)}
                    isPrevMonthView={true}
                    isNextMonthNotView={false}
                    max={getTwoLatersMonthDate(2)[1]}
                  /> */}
                  <CustomCalendar
                    max={getTwoLatersMonthDate(2)[1]}
                    value={created_after}
                    onChange={(date:string) => {onClickDateAfterByCalendar(date)}}
                  ></CustomCalendar>
                  <span>~</span>
                  {/* <DatePicker
                    value={created_before}
                    width={250}
                    onChange={(date: string) => onClickDateBeforeByCalendar(date)}
                    isPrevMonthView={true}
                    isNextMonthNotView={false}
                    max={getTwoLatersMonthDate(2)[1]}
                  /> */}
                  <CustomCalendar
                    max={getTwoLatersMonthDate(2)[1]}
                    value={created_before}
                    onChange={(date:string) => {onClickDateBeforeByCalendar(date)}}
                  ></CustomCalendar>
                </div>
              </div>
            </div>
            <div className="tr">
              <div className="th">검색어</div>
              <div className="td">
                <div className="search_form">
                  <Select
                    className="form_control"
                    value ={searchType}
                    options={searchTypes}
                    onChange={(e: any) => onSearchTypeFilter(Number(e.target.value))}
                  />
                  <input
                    type="text"
                    value={search}
                    className="form_control"
                    onKeyUp={e => (e.key === 'Enter' ? onSearchHandler() : null)}
                    onChange={(e: any) => setSearch(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn btn_darkblue btn_search"
                    onClick={onSearchHandler}>
                    {intl.get('검색')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> {/* table_design_list */}

      
      {!items.length ? (
        <ul className="lst_notice blank_area">
          <li className="txt_blank">데이터가 없습니다</li>
        </ul>
      ) : (
        <ul className="lst_notice">
            {items.map((item, index) => ( 
              <li
                key={index}
                onClick={() => props.history.push(`/customer/notice/detail`, item)}
              >
                <a href="javascript:void(0);" className="box">
                  {/* &.case3   //기숙사, 영빈관, 사택, 가족숙소
                      &.case6   //전체*/}
                  <span className={['state_room', NOTICE_TO_CASE[item.category]].join(' ')}>
                    {NOTICE_TYPE[item.category]}
                  </span>
                  <p className="title">{item.title}</p>
                  <p className="desc" dangerouslySetInnerHTML= {{ __html: parserEscape(item.contents)}} ></p>
                  {/* <p className="desc">{item.contents}</p> {/*dangerouslySetInnerHTML= {{ __html: parserEscape(item.contents)}}*/}
                  <p className="etc">
                    {formatByString(item.created_at, 'yyyy.MM.dd HH:mm')}
                  </p>
                </a>
              </li>
            ))}
        </ul>
      )}
      
    

      <div className="more_area ac">
        {isNext ? (
          <button className="btn btn_lg btn_outline" onClick={onNextHandler}>
            {intl.get('더보기')} <i className="ico_more"></i>
          </button>
        ) : (
          <></>
        )}
      </div>
    </DefaultTemplete>
  );
};

export default NoticePage;
