import React, { useState, useEffect } from 'react';

import SelectBuilding from '../../atoms/select/buildings';
import SelectFloor from '../../atoms/select/floor';

import ImageByServer from '../../atoms/image/byServer';

import { build } from '../../../apis';

import './drawing.scss';
import { SHOW_MODAL } from '../../../store/modules/modal';

const Drawing = (props: any) => {
  const [buildings, setBuildings] = useState<any>([]);
  
  const [selectBuild, setSelectBuild] = useState<any>({});
  const [selectFloor, setSelectFloor] = useState(-1);
  
  useEffect(() => {
    (async () => {
      try {
        const { data } = await build.getBuilds({
          work_site: props.workSite,
          room_type: props.roomType,
        });

        setBuildings(data);
        if (data.length) {
          setSelectBuild(data[0])
        }
      }catch ({ status, data }) {
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }
    })()
  }, [])

  useEffect(() => {
    if (selectBuild?.floors) {
      setSelectFloor(0)
    } else {
      setSelectFloor(-1)
    }
  }, [selectBuild])

  const getFetchFloor = async (id: number) => {
    try {
      const { data } = await build.getFloors({
        building: id,
        stay_after: props.dateAfter,
        stay_before: props.dateBefore,
      });
      return data
    }catch ({ status, data }) {
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  }

  const changeBuildHandler = (e: any) => {
    const { selectedIndex } = e.target.options;
    setSelectBuild(buildings[selectedIndex]);
  };

  return (
    <div
      id="pop_view_dong"
      className="pop_dim_wrap pop_modal pop_md"
      style={{ display: props.isDrawingShow ? 'block' : 'none' }}
    >
      <div className="pop_wrap">
        <div className="title_wrap">
          <h3>위치보기</h3>
          <button
            className="pop_btn_close pop_close"
            onClick={() => props.onClose()}
          >
            닫기
          </button>
        </div>
        <div className="contents_wrap">
          <div className="form_write form_lg clearfix">
            <div className="row">
              <SelectBuilding
                  changeBuildHandler={changeBuildHandler}
                  buildings={buildings}
              />

              <SelectFloor floors={selectBuild?.floors || []} value={ selectFloor } onChangeFloor={setSelectFloor} />
            </div>
          </div>
          
          <div className="plan_floor">
            {
              selectFloor > -1
              && <ImageByServer src={selectBuild?.floors[selectFloor]?.layout_image} />
            }
          </div>
        </div>

        <div className="button_wrap btn_length_1">
          <button
            type="button"
            className="btn btn_xlg btn_darkblue pop_close"
            onClick={() => props.onClose()}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default Drawing;
