import React, { useState } from 'react';

import './drawing.scss';

// 위치보기 팝업(모바일)
const Drawing = (props: any) => {
  const [floorType, setFloorType] = useState(props.floorType || 2);

  const onClickChangeFloorHandler = (idx: number) => {
    setFloorType(idx);
  };

  return (
    <div
      id="pop_view_dong"
      className="pop_dim_wrap pop_modal pop_md"
      style={{ display: props.isShow ? 'block' : 'none' }}
    >
      <div className="pop_wrap">
        <div className="title_wrap">
          <h3>동호수표 및 층별 도면</h3>
          <button
            className="pop_btn_close pop_close"
            onClick={() => props.onClose()}
          >
            닫기
          </button>
        </div>
        <div className="contents_wrap">
          <div className="select_area">
            <select name="" id="" className="form_control form_lg">
              <option value="">본동 102동</option>
            </select>
          </div>

          <div className="floor_area">
            {/*출장자 숙소*/}

            <ul className={`lst_floor ${props.roomType === 1 ? 'on' : ''}`}>
              {' '}
              {/*on 추가 시 show*/}
              <li className="floor_top">
                <a href="javascript:void(0)" className="disabled">
                  {' '}
                  {/*클릭 시 view_floor(도면) on 추가*/}
                  <p className="txt_floor">9F</p>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" className="disabled">
                  <p className="txt_floor">8F</p>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" className="disabled">
                  <p className="txt_floor">7F</p>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" className="disabled">
                  <p className="txt_floor">6F</p>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" className="disabled">
                  <p className="txt_floor">5F</p>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" className="disabled">
                  <p className="txt_floor">4F</p>
                </a>
              </li>
              <li
                onClick={() => {
                  onClickChangeFloorHandler(3);
                }}
              >
                <a href="javascript:void(0)" className="type3 on">
                  <p className="txt_floor">3F</p>
                </a>
              </li>
              <li
                onClick={() => {
                  onClickChangeFloorHandler(2);
                }}
              >
                <a href="javascript:void(0)" className="type3 on">
                  <p className="txt_floor">2F</p>
                </a>
              </li>
              <li className="floor_1f">
                <a href="javascript:void(0)" className="disabled">
                  <p className="txt_floor">1F</p>
                </a>
              </li>
            </ul>

            {/*영빈관*/}
            <ul className={`lst_floor ${props.roomType === 2 ? 'on' : ''}`}>
              {' '}
              {/*on 추가 시 show*/}
              <li
                className="floor_top"
                onClick={() => onClickChangeFloorHandler(3)}
              >
                <a href="javascript:void(0)" className="type4 on">
                  {' '}
                  {/*클릭 시 view_floor(도면) on 추가*/}
                  <p className="txt_floor">3F</p>
                </a>
              </li>
              <li onClick={() => onClickChangeFloorHandler(2)}>
                <a href="javascript:void(0)" className="type4 on">
                  <p className="txt_floor">2F</p>
                </a>
              </li>
              <li className="floor_1f">
                <a href="javascript:void(0)" className="disabled">
                  <p className="txt_floor">1F</p>
                </a>
              </li>
            </ul>
          </div>

          <h3 className="title_contents">층별도면</h3>

          <div className="plan_floor">
            {props.roomType === 1 && floorType === 2 && (
              <img src="/static/img/pc/plan_type3_2f.jpg" alt="" />
            )}
            {props.roomType === 1 && floorType === 3 && (
              <img src="/static/img/pc/plan_type3_3f.jpg" alt="" />
            )}

            {props.roomType === 2 && floorType === 2 && (
              <img src="/static/img/pc/plan_type4_2f.jpg" alt="" />
            )}
            {props.roomType === 2 && floorType === 3 && (
              <img src="/static/img/pc/plan_type4_3f.jpg" alt="" />
            )}
          </div>
        </div>
        <div className="button_wrap btn_length_1">
          <button
            type="button"
            className="btn btn_xlg btn_darkblue pop_close"
            onClick={() => props.onClose()}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default Drawing;
