import _ from 'lodash';
import { ROOM_TYPE_CODE } from '../constant';
import * as xssLib from 'xss';
import {XmlEntities} from 'html-entities';

//json데이터 중 빈값 field 지우기
export const blankRemoveJson = (obj: any) => {
  _.mapKeys(
    obj,
    (value, key) => _.isEmpty(value.toString()) && delete obj[key],
  );
  return obj;
};


export const getViewPageIdx = (offset: number, limit: number, idx: number) => Math.floor(offset / limit) * limit + idx + 1;

// 사업장별 관리사무소 번호
export function getWorkSitePhoneNum(workSite: number, roomType: number) {
  let tempPhone = '';
  if(workSite === 2 && (roomType === ROOM_TYPE_CODE.dormitory_room || roomType === ROOM_TYPE_CODE.family_room)){
    // 남양 - 기숙사, 가족숙소
    tempPhone = '031-356-4286';
  }else if(workSite === 2 && (roomType === ROOM_TYPE_CODE.company_room || roomType === ROOM_TYPE_CODE.business_room_group || roomType === ROOM_TYPE_CODE.business_room || roomType === ROOM_TYPE_CODE.vip_room )){
    // 남양 - 그외
    tempPhone = '031-356-9925';
  }else if(workSite === 1 ){
    // 울산
    tempPhone = '052-219-2330';
  }else if(workSite === 3 ){
    // 전주
    tempPhone = '063-260-2534';
  }else if(workSite === 4 ){
    // 아산
    tempPhone = '041-531-0750';
  }
  
  return tempPhone;
}

// 사업장별 관리사무소 번호
export function getWorkSitePhoneNum2(workSite: number) {
  let tempPhone = '';
  if(workSite === 2){
    // 남양 - 기숙사, 가족숙소
    tempPhone = '031-356-4286 또는 031-356-9925';
  }
  /* else if(workSite === 2 && (roomType === ROOM_TYPE_CODE.company_room || roomType === ROOM_TYPE_CODE.business_room_group || roomType === ROOM_TYPE_CODE.business_room || roomType === ROOM_TYPE_CODE.vip_room )){
    // 남양 - 그외
    tempPhone = '031-356-9925';
  }*/
  else if(workSite === 1 ){
    // 울산
    tempPhone = '052-219-2330';
  }else if(workSite === 3 ){
    // 전주
    tempPhone = '063-260-2534';
  }else if(workSite === 4 ){
    // 아산
    tempPhone = '041-531-0750';
  }
  
  return tempPhone;
}

//에디터 값 html tag 포맷
const escapeHtml = (html: string) => {
  return html.replace(/</g, '&lt;').replace(/>/g, '&gt;')
}

// whiteList 에 정의되지 않은 태그는 escapeHtml()를 타서 치환됨
const xssFilter = new xssLib.FilterXSS({
  whiteList: {
    p: ['style', 'class'],
    strong: ['style'],
    sup: ['style'],
    span: ['style', 'class'],
    ul:['style', 'class'],
    li:['style', 'class'],
    h1:['style'],
    h2:['style'],
    h3:['style'],
    h4:['style'],
    del:['style'],
    em:['style'],
    ins:['style'],
    sub:['style'],
    table:['style'],
    tbody:['style'],
    tr:['style'],
    td:['style'],
    br:['style'],
    img:['style', 'class', 'src'],
    a: ['style', 'href'],
    abbr: ['style'],
    address: ['style'],
    area: ['style'],
    article: ['style'],
    aside: ['style'],
    audio: ['style'],
    b: ['style'],
    bdi: ['style'],
    bdo: ['style'],
    big: ['style'],
    blockquote: ['style'],
    caption: ['style'],
    center: ['style'],
    cite: ['style'],
    code: ['style'],
    col: ['style'],
    colgroup: ['style'],
    dd: ['style'],
    details: ['style'],
    div: ['style'],
    dl: ['style'],
    dt: ['style'],
    figure: ['style'],
    figcaption: ['style'],
    font: ['style'],
    footer: ['style'],
    h5: ['style'],
    h6: ['style'],
    header: ['style'],
    hr: ['style'],
    i: ['style'],
    mark: ['style'],
    nav: ['style'],
    ol: ['style'],
    pre: ['style'],
    s: ['style'],
    section: ['style'],
    small: ['style'],
    strike: ['style'],
    summary: ['style'],
    tfoot: ['style'],
    th: ['style'],
    thead: ['style'],
    tt: ['style'],
    u: ['style'],
    video: ['style', 'src']
  },
  onTag: (tag: any, html: string, options: any) => {
    return options.isWhite ? html : escapeHtml(html)
  }
});

export const xss = (value: any) => {
  return xssFilter.process(value)
}

export const parserEscape = (data: string) => {
  return xss(XmlEntities.decode(data));
};

//숫자 콤마
export const onlyNumberFormatter = (number: any) => {
  var number_string = number.toString();
  var number_parts = number_string.split('.');
  var regexp = /\B(?=(\d{3})+(?!\d))/g;
  
  if(number)  {
      if (number_parts.length > 1) { 
        return number_parts[0].replace( regexp, ',' ).replace(/[^0-9]*$/gi, '') + '.' + number_parts[1].replace(/[^0-9]*$/gi, ''); 
      } else { 
        return number_string.replace( regexp, ',' ).replace(/[^0-9]*$/gi, ''); 
      }  
  } else {
    return 0;
  }
}