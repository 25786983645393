import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import { getTodayDate } from '../../../../utils/date';
import Footer from '../../../organismas/footer';
import Header from '../../../organismas/header';
import ViewContent from './ViewContent';
import WriteContent from './WriteContent';

const VocDetail = (props: any) => {
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user,
  }));

  const [orgItem, setOrgItem] = useState<any>(null);
  const [item, setItem] = useState<any>(null);
  const [isEdit, setIsEdit] = useState<any>(props.isEdit);

  const init = () => {
    return {
      id: -1,
      work_site: userInfo.worksite,
      category1: '',
      category2: '',
      category3: '',
      category4: '',
      author_name: userInfo.username,
      author_employee_number: userInfo.employeenumber,
      title: '',
      contents: '',
      found_at: `${getTodayDate()[0]}   :00:00`,
      places: [],
      images: [],
      files: [],
      send_at: '',
      created_at: new Date(),
    };
  };

  useEffect(() => {
    const _isCreate = props.isEdit && !props.match.params.id;
    // console.log(_isCreate, userInfo)
    const _orgItem = _isCreate ? init() : { ...props.history.location.state };
    setOrgItem(_orgItem);
    setItem(_orgItem);
  }, [userInfo]);

  if (!item) return <></>;

  return (
    <div id="wrap" className="wrap_customer">
      <Header history={props.history} /> {/*header*/}
      <div id="container">
        <div className="row clearfix">
          <div className="col col_left group_title">
            <h2 className="">My VoC</h2>
            <p className="desc">{intl.get('숙소이용 불편사항 등을 게시하고 답변 받을 수 있습니다')}</p>
            <div className="bg_img voc"></div>
          </div>

          <div id="contents" className="col col_right">
          {isEdit ? (
            <WriteContent item={item} setIsEdit={setIsEdit} user={userInfo} {...props} />
          ) : (
            <ViewContent item={item} setIsEdit={setIsEdit} user={userInfo} {...props} />
          )}
          </div>  {/*#contents*/}
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default VocDetail;
