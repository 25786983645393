import React, { useState } from 'react';

import SearchId from '../../organismas/searchId';
import SearchPassword from '../../organismas/searchPassword';
import SignupHelper from '../../organismas/signupHelper';

import SigninForm from '../../organismas/signinForm';

import './index.scss';

const Signin = (props: any) => {
  const [isShowSearchId, setIsShowSearchId] = useState<boolean>(false);
  const [isShowSearchPassword, setIsShowSearchPassword] = useState<boolean>(
    false,
  );
  const [tabNumber, setTabNumber] = useState<number>(-1);

  const onShowSearchIdPopupHandler = () => {
    setIsShowSearchId(true);
  }; // 아이디 찾기 열기
  const onCloseSearchIdPopupHandler = () => {
    setIsShowSearchId(false);
  }; // 아이디 찾기 닫기

  const onShowSearchPasswordPopupHandler = () => {
    setIsShowSearchPassword(true);
  }; // 비밀번호 찾기 열기
  const onCloseSearchPasswordPopupHandler = () => {
    setIsShowSearchPassword(false);
  }; // 비밀번호 찾기 닫기

  const onShowSignupHelperPopupHandler = (tab: number) => {
    setTabNumber(tab);
  }; // 가입관련 문의 열기
  const onCloseSignupHelperPopupHandler = () => {
    setTabNumber(-1);
  }; // 가입관련 문의 닫기

  const onMoveIdToPasswordHandler = () => {
    onCloseSearchIdPopupHandler();
    onShowSearchPasswordPopupHandler();
  }; // 아이디 찾기 => 비밀번호 찾기
  const onMovePasswordToIdHandler = () => {
    onCloseSearchPasswordPopupHandler();
    onShowSearchIdPopupHandler();
  }; // 비밀번호 찾기 => 아이디 찾기

  return (
    <>
      <div id="wrap" className="wrap_login">
        <div id="container">
          <h1 className="ir logo">WESN AMS Platform - West East South North</h1>
          <div className="row">
            <div className="col"></div>
            <SigninForm
              {...props}
              setIsShowSearchId={setIsShowSearchId}
              onShowSearchIdPopupHandler={onShowSearchIdPopupHandler}
              onShowSearchPasswordPopupHandler={onShowSearchPasswordPopupHandler}
              onShowSignupHelperPopupHandler={onShowSignupHelperPopupHandler}
            />
          </div>
        </div>
      </div>

      <SignupHelper
        classNameBySelect="form_control"
        tabNumber={tabNumber}
        setTabNumber={setTabNumber}
        onCloseSignupHelperPopupHandler={onCloseSignupHelperPopupHandler}
      />
      <SearchId
        isShow={isShowSearchId}
        onCloseSearchIdPopupHandler={onCloseSearchIdPopupHandler}
        onMoveIdToPasswordHandler={onMoveIdToPasswordHandler}
      />
      <SearchPassword
        isShow={isShowSearchPassword}
        onCloseSearchPasswordPopupHandler={onCloseSearchPasswordPopupHandler}
        onMovePasswordToIdHandler={onMovePasswordToIdHandler}
      />
    </>
  );
};

export default Signin;
