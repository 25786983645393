// 이용약관, 개인정보처리방침 자세히보기
import React from 'react';

import './index.scss';

//서비스 이용약관 팝업
const termsDetail = (props: any) => {
  return (
    <div
      id="pop_termsdetail"
      className="pop_dim_wrap pop_modal pop_lg"
      style={{ display: props.tabNumber !== -1 ? 'block' : 'none' }}
    >
      {props.tabNumber === 1 ? (
        <div className="pop_wrap">
          <div className="title_wrap">
            <h3>서비스 이용약관</h3>
            <button
              className="pop_btn_close pop_close"
              onClick={() => props.onCloseTermsDetailPopupHandler()}
            >
              닫기
            </button>
          </div>
          <div className="contents_wrap">
            <div className="title_contents">
              <h4 style={{ minHeight: '50px' }}></h4>
              <label className="lst_select">
                <select
                  name=""
                  id=""
                  className="form_control form_lg"
                  value={props.tabNumber}
                  onChange={e => props.setTabNumber(parseInt(e.target.value))}
                >
                  <option value="1">서비스 이용약관</option>
                  <option value="2">개인정보 이용 동의</option>
                </select>
              </label>
            </div>

            <div className="terms_service terms_wrap">
              <dl>
                <dt>제 1조 명칭</dt>
                <dd>
                  본 시스템의 명칭은 "통합숙소관리시스템"(이하'시스템')이라
                  칭한다.{' '}
                </dd>

                <dt>제 2조 소재지</dt>
                <dd>
                  본 시스템은 경기도 파주시 현대차그룹 데이터센터 내에 위치한다.
                </dd>

                <dt>제 3조 목적</dt>
                <dd>
                  본 약관은 본 시스템의 운영에 관한 제반사항을 정함으로써 회원
                  상호간의 이해 증진을 도모함은 물론 회원들이 본 시스템을 가장
                  편리하고 효율적으로 이용할 수 있도록 함을 그 목적으로 한다.
                </dd>

                <dt>제 4조 운영</dt>
                <dd>
                  <ul>
                    <li>
                      1. 본 시스템의 운영시간은 연중무휴 1일 24시간을 원칙으로
                      한다. 다만, 업무상 또는 기술상의 이 유로 서비스가 일시
                      중지될 수 있고, 또한 정기점검 등 운영상의 목적으로
                      서비스가 일시 중지될 수 있다. 이러한 경우 사전 또는 사후에
                      이를 공지한다.
                    </li>
                    <li>
                      2. 본 시스템의 정기휴일은 없으며, 본 시스템의 부득이한
                      사정이 있을 시에는 게시판 공고 후 휴일 로 한다.
                    </li>
                    <li>
                      3. 기타 운영에 관한 제반 사항은 시스템 내 게시판에
                      공고한다.
                    </li>
                  </ul>
                </dd>

                <dt>제 5조 회원의 등록 및 대상</dt>
                <dd>
                  <ul>
                    <li>
                      1. 본 시스템의 회원대상은 본 사이트에 접속 승인을 받은
                      자로 한다.
                    </li>
                    <li>
                      2. 본 시스템의 정회원이 되고자 하는 자는 본 시스템에서
                      정한 소정의 가입 신청 절차를 거쳐야 하며 필수정보사번,
                      성명, 생년월일, 성별, 근무지명, 소속부서, 직책,
                      휴대폰번호, 이메일) 를 입력한 후 회원가입을 완료하여야
                      이용이 가능하다. 이때 관리자의 승인이 필요한 경우에 한하여
                      관리자의 승인이 완료되어야 이용이 가능하다.
                    </li>
                    <li>
                      3. 협력사 임직원의 경우, 본 시스템에서 정한 소정의 가입
                      신청 절차를 거쳐야 하며 필수정보(성명, 성별, 휴대폰번호,
                      소속회사, 근무지, 담당업무)를 입력한 후 회원가입을
                      완료하여야 이용이 가능하다. 이때 관리자의 승인이 필요한
                      경우에 한하여 관리자의 승인이 완료되어야 이용이 가능하다.
                    </li>
                  </ul>
                </dd>

                <dt>제 6조 숙소 사용료 및 이용기간</dt>
                <dd>
                  <ul>
                    <li>
                      1. 숙소 사용료는 각 사 사업장의 기준에 따라 개별적으로
                      적용된다.
                    </li>
                    <li>
                      2. 이용기간은 본인이 이용신청시에 직접 정하거나 또는 각 사
                      사업장 기준에 따라 정해진다.
                    </li>
                  </ul>
                </dd>

                <dt>제 7조 숙소이용신청</dt>
                <dd>
                  본 시스템의 숙소이용신청 방법은 다음과 같다.
                  <ul>
                    <li>1. 홈페이지 회원가입 신청을 통해 가입</li>
                    <li>2. 필수정보 입력</li>
                    <li>3. 숙소이용신청 (정상적인 가입시)</li>
                  </ul>
                </dd>

                <dt>제 8조 숙소 사용료 연기 및 환불</dt>
                <dd>
                  <ul>
                    <li>
                      1. 각 사 사업장 기준에 따라 사용료에 대한 연기 및 환불을
                      처리한다.
                    </li>
                    <li>
                      2. 최초 회원가입 후 회사 회원탈퇴시 까지는 회원자격이
                      주어진다.
                    </li>
                    <li>
                      3. 숙소 이용 시 사용일수를 기준으로 하여 급여공제 또는
                      비용납부처리를 하는 것으로 한다.
                    </li>
                    <li>
                      4. 숙소 비품 관리규정 - 본인의 과실에 의한 숙소 Key, 비품
                      및 공공기물을 분실 또는 파손한 경우에는 그에 해당하는 모든
                      비용은 본인이 부담한다.
                    </li>
                  </ul>
                </dd>

                <dt>제 9조 회원 자격의 상실</dt>
                <dd>
                  다음의 경우에는 회원자격이 상실된다.
                  <ul>
                    <li>
                      1. 전염성 질병에 감염된 회원 및 감염이 우려되는 회원
                    </li>
                    <li>2. 회원정보를 위/변조하여 가입한 회원</li>
                    <li>3. 본 시스템의 약관이 정한 규정을 위반하였을 경우</li>
                    <li>
                      4. 각 사 사업장별 숙소관리규정에 따른 회원자격 상실의 경우
                    </li>
                  </ul>
                </dd>

                <dt>제 10조 회원의 의무</dt>
                <dd>
                  <ul>
                    <li>1. 회원은 본 약관을 성실하게 준수하여야 한다.</li>
                    <li>
                      2. 회원은 본 시스템의 모든 시설을 공동재산으로 여겨 이용
                      시 상호간에 양보하여야 한다.
                    </li>
                    <li>
                      3.회원은 부서 또는 연락처 등 가입시 정보에 변동이 있을
                      경우에는 그 변동 내용을 즉시 수정하여야 한다.
                    </li>
                  </ul>
                </dd>

                <dt>제 11조 안전사고 및 보상책임 사항</dt>
                <dd>
                  <ul>
                    <li>
                      1. 회원이 본 시스템의 제반시설을 이용함에 있어서
                      천재지변이나 불가항력적인 재난 또는 본인의 귀책사유로 발생
                      한 사고나 부상 등에 대해서는 현대엔지니어링㈜가 책임지지
                      않는다.
                    </li>
                    <li>
                      2. 회원이 고의, 과실 등 귀책 사유로 시설물을 파괴, 분실
                      또는 훼손된 경우 이를 본인이 보상하여야 한다.
                    </li>
                    <li>
                      3. 회원 부주의로 인한 개인물품 분실에 대해서는
                      현대엔지니어링㈜가 책임지지 않는다.
                    </li>
                  </ul>
                </dd>

                <dt>제 12조 개정</dt>
                <dd>
                  본 시스템의 운영상 부득이 필요하다고 인정할 때에는 본 약관을
                  개정 또는 변경할 수 있고 그 효력은 회원 전원에게 적용된다.
                </dd>

                <dt>제 13조 관습</dt>
                <dd>
                  본 약관에 명시되지 않은 사항에 관하여는 일반적인 관례에
                  따른다.
                </dd>

                <dt>제 14조 효력발생 </dt>
                <dd>본 약관은 2021. 07. 21부터 적용된다.</dd>
              </dl>
            </div>
          </div>

          <div className="button_wrap btn_length_1">
            <button
              type="button"
              className="btn btn_xlg btn_darkblue pop_close"
              onClick={() => props.onCloseTermsDetailPopupHandler()}
            >
              확인
            </button>
          </div>
        </div>
      ) : props.tabNumber === 2 ? (
        <div className="pop_wrap">
          <div className="title_wrap">
            <h3>개인정보 이용 동의</h3>
            <button
              className="pop_btn_close pop_close"
              onClick={() => props.onCloseTermsDetailPopupHandler()}
            >
              닫기
            </button>
          </div>
          <div className="contents_wrap">
            <div className="title_contents">
              <h4 style={{ minHeight: '50px' }}></h4>
              <label className="lst_select">
                <select
                  name=""
                  id=""
                  className="form_control form_lg"
                  value={props.tabNumber}
                  onChange={e => props.setTabNumber(parseInt(e.target.value))}
                >
                  <option value="1">서비스 이용약관</option>
                  <option value="2">개인정보 이용 동의</option>
                </select>
              </label>
            </div>

            <div className="group_desc">
              <div className="table_design_list col_width3">
                <div id="table" className="table">
                  <div className="thead">
                    <div className="tr">
                      <div className="th">수집하는 개인정보 항목</div>
                      <div className="th">수집 및 이용 목적</div>
                      <div className="th">보유 및 이용기간</div>
                    </div>
                  </div>
                  <div className="tbody">
                    <div className="tr">
                      <div className="td">
                        사번, 성명, 생년월일, 성별, 근무지명, 소속부서, 직책,
                        휴대폰번호, 이메일
                      </div>
                      <div className="td">이용자 식별 및 본인여부 확인</div>
                      <div className="td" style={{textDecorationLine: 'underline', flexFlow:'bold'}}>회원 탈퇴 시 삭제</div>
                    </div>
                    <div className="tr">
                      <div className="td">
                        사번, 성명, 생년월일, 성별, 근무지명, 소속부서, 직책,
                        휴대폰번호, 이메일
                      </div>
                      <div className="td">
                        출장자숙소 이용신청 등 숙소업무처리
                      </div>
                      <div className="td" style={{textDecorationLine: 'underline', flexFlow:'bold'}}>
                        퇴숙완료처리 시점으로부터 1년 후 삭제 <br />
                        ※근무지명, 소속부서,직책 제외
                      </div>
                    </div>
                    <div className="tr">
                      <div className="td">사번, 성명, 휴대폰번호</div>
                      <div className="td">
                        민원 신청인 식별 및 정확한 민원업무처리
                      </div>
                      <div className="td" style={{textDecorationLine: 'underline', flexFlow:'bold'}}>
                        민원처리완료 시점으로부터 30일 후 삭제
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ul className="lst_warning mt20">
              <li>
                위의 개인정보 수집·이용에 대한 동의를 거부할 권리가 있습니다.
                <br />
                그러나 시스템을 사용하기 위해서 필요한 최소한의 개인정보이므로
                동의를 해주셔야 서비스를 이용하실 수 있습니다.
              </li>
            </ul>
          </div>

          <div className="button_wrap btn_length_1">
            <button
              type="button"
              className="btn btn_xlg btn_darkblue pop_close"
              onClick={() => props.onCloseTermsDetailPopupHandler()}
            >
              확인
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default termsDetail;
