import React from 'react';

import { useSelector } from 'react-redux';

import { GENDER } from '../../constant';

import intl from 'react-intl-universal';

const Step4 = (props: any) => {
  const { workplaceMap = {} } = useSelector((state: any) => ({
    workplaceMap: state.base.workplaceMap
  }));

  return (
    <>
      <dl className="lst_complete">
        <dt>{intl.get("신청이 완료되었습니다")}</dt>
        {Number(props.workSite) === 2? (
          <>
            <dd>
              {intl.get("이용 신청이 정상적으로 접수되었습니다. 관리자가 신청 내용 확인 후 사업장 별로 승인 및 배방이 완료됩니다. 관련 내용은 마이페이지 > 신청관리 메뉴에서 확인이 가능합니다.")}
              <br />
              {intl.get("코로나 관련하여 개인 위생을 철저히 지켜주시기 바라며, 정부의 행동지침에 따라 주시기 바랍니다. 코로나 의심증상 있을시 연구소 코로나 상황실(031-368-6510)연락주시기 바랍니다.")}
            </dd>
          </>
        ) : (
          <>
            <dd>
              {intl.get("이용 신청이 정상적으로 접수되었습니다")}.<br />
              {intl.get("관리자가 신청 내용 확인 후 사업장 별로 승인 및 배방이 완료됩니다")}.<br/>
              {intl.get("관련 내용은 마이페이지 > 신청관리 메뉴에서 확인이 가능합니다.")}
            </dd>
          </>
        )}
      </dl>

      <div className="title_contents">
        <h3 className="title">{intl.get("예약정보")}</h3>
        <div className="right_area">
            <button 
              className="btn btn_md btn_darkblue"
              onClick={() => props.history.push('/mypage/applicant')}
            >{intl.get("신청관리 바로가기")}</button>
        </div>
      </div>

      <dl className="lst_reservation">
        <dt>{intl.get("숙소유형")}</dt>
        <dd>{intl.get("R&D STAY")}</dd>
        <dt>{intl.get("이용사업장")}</dt>
        <dd>{workplaceMap[props.workSite]?.name}</dd>
        <dt>{intl.get("입숙예정일")}</dt>
        <dd>{props.dateAfter}</dd>
        <dt>{intl.get("퇴숙예정일")}</dt>
        <dd>{props.dateBefore}</dd>
        {/*<dt>{intl.get("배방정보")}</dt>
        <dd>{props.building.name} {props.building.number} {props.room.name}{intl.get("호")}</dd>
        <dt className="blank"></dt>
        <dd className="blank"></dd>*/}
      </dl>

      {props.guests.map((guest: any, guestIdx: number) => (
        <ul className="lst_userinfo">
          {/*{!guestIdx && (
            <li className="user_area">
              <span className="img_user"><img src="/static/img/pc/userimg_default.png" alt="" /></span>
              <span className="username">이용자 정보</span>
            </li>
          )}*/}
          { guestIdx === 0 ? (
            <li className="user_area">
              <span className="img_user"><img src="/static/img/pc/userimg_default.png" alt="" /></span>
              <span className="username">{intl.get("이용자 정보")}</span>
            </li>
          ) : (
            <li className="user_area">
            </li>
          )}
          <li>
            <strong>{intl.get("성명")}</strong>
            <span>{guest.guest_name}</span>
          </li>
          <li>
            <strong>{intl.get("성별")}</strong>
            <span>{GENDER[guest.guest_gender]}</span>
          </li>
          <li>
            <strong>{intl.get("휴대폰 번호")}</strong>
            <span>{guest.guest_cellphone}</span>
          </li>
          <li>
            <strong>{intl.get("신청자와의 관계")}</strong>
            <span>{guest.guest_relation}</span>
          </li>
          <li>
            <strong>{intl.get("차량번호")}</strong>
            <span>{guest.guest_car_number1}</span>
          </li>
        </ul>
      ))}

      <ul className="lst_help">
        {Number(props.workSite) === 2? (
          <li>
            {intl.get("이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 R&D STAY 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.")}
          </li>
        ) :(
          <>
            <li>{intl.get("신청 접수된 이용자 정보의 본인만이 숙소 이용이 가능합니다.")}</li>
            <li>{intl.get("사업장별로 관리자 승인 및 배방 완료 후 예약이 완료됩니다.")}</li>
            {/* <li>{intl.get("가족숙소는 신청자의 가족이신 분들만 사용가능합니다. 입숙시, 가족관계증명서를 지참하시기 바랍니다.")}</li> */}
          </>
        )}
      </ul>

      <div className="submit btn_length_1">
        <button className="btn btn_lg btn_darkblue" onClick={() => props.history.push("/home")}>{intl.get("확인")}</button>
      </div>
    </>
  );
};

export default Step4;
