import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SelectBuilding from '../../components/atoms/select/buildings';
import SelectFloor from '../../components/atoms/select/floor';
import ConfirmBtn from '../../components/atoms/button/confirm';
import Drawing from '../../components/organismas/popup/drawing';
import Floors from '../../components/molecules/floor';

import { SHOW_MODAL } from '../../store/modules/modal';
import { build } from '../../apis';

import _ from 'lodash';

import intl from 'react-intl-universal';
import './step3.scss';
import { GENDER, ROOM_TYPE_CODE } from '../../constant';

const Step3 = (props: any) => {
  const dispatch = useDispatch();

  const { userInfo = {}, workplaceMap = {} } = useSelector((state: any) => ({
    userInfo: state.user,
    workplaceMap: state.base.workplaceMap
  }));

  const [isDrawingShow, setIsDrawingShow] = useState(false);

  const [building, setBuilding] = useState(-1); // 사용자가 선택한 building
  const [selectBuildIdx, setSelectBuildIdx] = useState(-1);

  const [buildings, setBuildings] = useState<any>([]);
  const [floors, setFloors] = useState<any>([]);
  const [selectRoom, setSelectRoom] = useState<any>({});

  const [selectFloor, setSelectFloor] = useState<number>(-1);
  const [badIdx, setSelectBadIdx] = useState<number>();
  
  // 초기 렌더링시 건물명 및 동 가져오기
  useEffect(() => {
    (async () => {
      try {
        const { data } = await build.getBuilds({
          work_site: props.workSite,
          room_type: props.roomType,
        });
  
        setSelectFloor(-1);
        setBuildings(data);
        setBuilding(data.length ? data[0].id : -1);
  
        setSelectBuildIdx(data.length ? 0 : -1);
        props.setBuilding(data.length ? data[0] : -1);
      }catch ({ status, data }) {
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }
    })();
  }, []);

  useEffect(() => {
    setSelectFloor(-1);
    (async () => {
      if (building > -1) {
        try {
          const { data: resFloors } = await build.getFloors({//floors 이용자 gender 값 보내기
            building,
            stay_after: props.dateAfter,
            stay_before: props.dateBefore,
            gender_type : props.user.gender
          });
   
          setFloors(resFloors);
          setSelectRoom({});
        }catch ({ status, data }) {
          if (status === 401) {
            window.location.href = '/force/signout';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }

          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '문제발생',
              msg: '문제가 발생했습니다',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }
      } else {
        setFloors([]);
      }
    })();
  }, [building]);

  // 건물명 및 동 선택 시 floor[0]의 building으로 설정
  const changeBuildHandler = (e: any) => {
    // console.log(e.target.value);
    const { selectedIndex } = e.target.options;
    setSelectBuildIdx(selectedIndex);
    setBuilding(buildings[selectedIndex].id);
    props.setBuilding(buildings[selectedIndex]);
  };

  const onRoomClickHandler = (room: any, idx:number) => {
    props.setRoom(room);
    setSelectRoom(room);
    setSelectBadIdx(idx);
  };

  const next = () => {
    if (selectBuildIdx < 0 || !Object.keys(selectRoom).length) {
      dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '',
          msg: intl.get('방을 선택하지 않았습니다.'),
          redirectPath: false,
          statusCode: false,
          errorCode: '',
          type: 'warning',
          isModalShow: true,
        },
      });
      return;
    }

    props.nextStep();
  };

  const isDisable = (room: any, badIdx: number) => {
    return !room.roomstatus[badIdx] || (room.roomstatus[badIdx] && room.roomstatus[badIdx].status !== 1) || room.guests.length - 1 >= badIdx || (room.gender_type !== 0 && room.gender_type !== props.user.gender) || room.room_type !== ROOM_TYPE_CODE.business_room
  }

  const isSelect = (room: any, idx: number) => {
    return (room.id === selectRoom.id && badIdx === idx);
  }

  const detail = (room: any, badIdx: number) => ""

  let msg = intl.get('선택한 방은 총원 ') + `${selectRoom?.quota}` + intl.get('명 중 ') + `${selectRoom?.guests?.length}` + intl.get('명이 사용중입니다. \n정말 예약을 진행하시겠습니까?')
  return (
    <>

      <ul className="lst_thumb_request">
        <li>
          <strong>{intl.get("숙소유형")}</strong>
          <span>{intl.get("출장자숙소(개인)")}</span>
        </li>
        <li>
          <strong>{intl.get("이용사업장")}</strong>
          <span>{workplaceMap[props.workSite]?.name}</span>
        </li>
        <li>
          <strong>{intl.get("입숙예정일")}</strong>
          <span>{props.dateAfter}</span>
        </li>
        <li>
          <strong>{intl.get("퇴숙예정일")}</strong>
          <span>{props.dateBefore}</span>
        </li>
        <li>
          <strong>{intl.get("배방정보")}</strong>
          <span>
            {intl.get("건물/동")}:{' '}
            {selectBuildIdx > -1 &&
              `${buildings[selectBuildIdx].name} ${buildings[selectBuildIdx].dong}`}
            <br />
            {intl.get("방호수")}: {selectRoom.name}
          </span>
        </li>
      </ul>

      <div className="title_contents">
        <h3 className="title">{intl.get("신청자 정보")}</h3>
      </div>

      <div className="table_design_list col_width6">
        <div id="table" className="table">
          <div className="tbody">
            <div className="tr">
              <div className="th">{intl.get("사번 (아이디)")}</div>
              <div className="td">{userInfo.employeenumber}</div>
              <div className="th">{intl.get("성명")}</div>
              <div className="td">{userInfo.username}</div>
              <div className="th">{intl.get("성별")}</div>
              <div className="td">{GENDER[userInfo.gender]}</div>
            </div>
            <div className="tr">
              <div className="th">{intl.get("소속 부서")}</div>
              <div className="td">{userInfo.department}</div>
              <div className="th">{intl.get("휴대폰 번호")}</div>
              <div className="td">{userInfo.phone}</div>
              <div className="th"></div>
              <div className="td"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="title_contents">
        <h3 className="title">{intl.get("이용자 정보")}</h3>
      </div>
      <div className="table_design_list col_width6">
        <div id="table" className="table">
          <div className="tbody">
            <div className="tr">
              <div className="th">{intl.get("사번 (아이디)")}</div>
              <div className="td">{props.user.employee_number}</div>
              <div className="th">{intl.get("성명")}</div>
              <div className="td">{props.user.name}</div>
              <div className="th">{intl.get("성별")}</div>
              <div className="td">{GENDER[props.user.gender]}</div>
            </div>
            <div className="tr">
              <div className="th">{intl.get("소속 부서")}</div>
              <div className="td">{props.user.department}</div>
              <div className="th">{intl.get("휴대폰 번호")}</div>
              <div className="td">{props.user.cellphone}</div>
              <div className="th"></div>
              <div className="td"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="title_contents">
        <h3 className="title">{intl.get("배방신청")}</h3>
        <ul className="lst_help">
          <li>
            {intl.get("동을 선택하고 층수를 선택하여 빈방을 검색하여 선택하여 주세요")}
          </li>
        </ul>
      </div>

      <div className="form_write form_lg clearfix">
        <div className="row">
          <SelectBuilding
            changeBuildHandler={changeBuildHandler}
            buildings={buildings}
          />

          <SelectFloor floors={floors} value={selectFloor} onChangeFloor={setSelectFloor} isShowAllOption />

          {/* <div className="col col_plan">
            <button
              className="btn btn_gray"
              onClick={() => setIsDrawingShow(true)}
            >
              {intl.get("위치보기")}
            </button>
          </div> */}
        </div>
      </div>

      <dl className="lst_floorplan">
        <Floors
          floors={floors}
          selectFloor={selectFloor}
          onRoomClickHandlerToSelect={onRoomClickHandler}
          onRoomClickHandlerToUnSelect={() => { }}
          isGuide={true}
          isDisable={isDisable}
          isSelect={isSelect}
          detail={detail}
        />
      </dl>

      <div className="submit">
        <button className="btn btn_lg btn_outline" onClick={props.prevStep}>
          {intl.get("이전")}
            </button>
        {
          selectRoom?.guests?.length ? (
            <ConfirmBtn
              className="btn btn_lg btn_darkblue"
              action={next}
              label={intl.get("다음")}
              title=""
              msg={msg}
            />
          ) : (
              <button className="btn btn_lg btn_darkblue" onClick={next}> {intl.get("다음")}</button>
            )
        }

      </div>

      <Drawing
        isDrawingShow={isDrawingShow}
        dateAfter={props.dateAfter}
        dateBefore={props.dateBefore}
        onClose={() => setIsDrawingShow(false)}
        workSite={props.workSite}
        roomType={props.roomType}
      />
    </>
  );
};

export default Step3;
