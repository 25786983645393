import React from 'react';

const Row = (props: any) => {
  const s: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  };

  return (
    <div style={s} {...props}>
      {props.children}
    </div>
  );
};

export default Row;
