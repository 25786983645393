import React from 'react';
import { useHistory } from 'react-router-dom';

import SigninForm from '../components/templetes/signin';

const SigninPage = (props: any) => {
  const history = useHistory();

  return (
    <>
      <SigninForm {...props} />
    </>
  );
};

export default SigninPage;
