import FileSaver from 'file-saver';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import intl from 'react-intl-universal';
import xss from 'xss';
import { core } from '../../../apis';
import DefaultTemplete from '../../../components/templetes/default';
import { NOTICE_TYPE } from '../../../constant';
import { parserEscape } from '../../../utils/commons';
import { formatByString } from '../../../utils/date';
// import { DownloadText } from '../../../components/molecules/download';

const NoticeDetailPage = (props: any) => {
  const item: any = props.history.location.state;
  const [isImgPop, setIsImgPop] = useState<boolean>(false);
  const [imgPath, setImgPath] = useState<string>('');

  return (
    <DefaultTemplete
      leftInfo={
        <>
          <h2>{intl.get('공지사항')}</h2>
          <p className="desc">
            {intl.get('숙소이용을 위한 공지사항을 확인하실 수 있습니다')}
          </p>
          <div className="bg_img notice"></div>
        </>
      }
      id="wrap"
      className="wrap_customer"
      {...props}
    >
      <div className="table_design_write detail col_width4 mt0">
        <div id="table" className="table">
          <div className="tbody">
            <div className="tr">
              <div className="th">
                <span className="">{intl.get('유형')}</span>
              </div>
              <div className="td">{NOTICE_TYPE[item.category]}</div>

              <div className="th">
                <span className="">{intl.get('작성일시')}</span>
              </div>
              <div className="td">
                {formatByString(item.created_at, 'yyyy.MM.dd HH:mm')}
              </div>
            </div>

            <div className="tr">
              <div className="th">
                <span className="">{intl.get('제목')}</span>
              </div>
              <div className="td">{item.title}</div>

              <div className="th">
                <span className="">{intl.get('작성자')}</span>
              </div>
              <div className="td">{item.author_name}</div>
            </div>

            <div className="tr mix_width2 mb_w100">
              <div className="th">
                <span className="">{intl.get('내용')}</span>
              </div>
              <div className="td">
                <p
                  id="editor"
                  className="contents_area"
                  dangerouslySetInnerHTML={{ __html: parserEscape(item.contents) }}
                ></p>
              </div>
            </div>
            <div className="tr mix_width2 mb_w100">
              <div className="th">
                <span className="">{intl.get('사진첨부')}</span>
              </div>
              <div className="td">
                <p className="contents_area images">
                  <div className="images_area">
                    {item.images.map((image: any, index: number) => (
                      <div key={index} className="img" style={{cursor:'pointer'}} onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                        <a className="">
                          <img
                            src={`${process.env.REACT_APP_MEDIA}/${image.path}`}
                            alt={image.name}
                          />
                        </a>
                      </div>
                    ))}
                  </div>
                </p>
              </div>
            </div>
            <div className="tr mix_width2 mb_w100">
              <div className="th">
                <span className="">{intl.get('파일첨부')}</span>
              </div>
              <div className="td">
                {item.files.map((file: any, index: number) => (
                  // <DownloadText className={'item file'} key={index} value={file.name} src={file.path} />
                  <a
                    key={index}
                    href={`${process.env.REACT_APP_MEDIA}/${file.path}`}
                    target={`${file.path}`.split('.').pop() 
                          && ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(`${file.path}`.split('.').pop() || '' )
                          && !isMobile ? '_blank' : ''}
                    className="item file"
                  >
                    {file.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* table_design_list */}
      <div className="mb_view">
        <button type="button" className="btn btn_lg btn_gray" 
        onClick={() => {
          //props.history.goBack();
          props.history.push('/customer/notice');
        }}> {/*props.history.push('/customer/notice') */}
          {intl.get('목록')}
        </button>
      </div>
      <div className="submit pt20">
        <div className="fl">
          <button
            type="button"
            className="btn btn_lg btn_gray"
            onClick={() => {
              //props.history.goBack();
              props.history.push('/customer/notice');
            }}> {/*props.history.push('/customer/notice') */}
            {intl.get('목록')}
          </button>
        </div>
      </div>

      {/* 이미지 팝업 */}
      <div id="pop_image" className="pop_dim_wrap pop_modal" style={{display: isImgPop? 'block' : 'none'}}>
          <div className="pop_wrap">
              <div className="title_wrap">
                  <a href="javascript:void(0);" className="pop_btn_close" onClick={()=> setIsImgPop(false)}>닫기</a>
              </div>
              <div className="contents_wrap">
                  <div className="slide">
                      <p className="img_area">
                          <img src={imgPath} alt=""/>
                      </p>
                  </div>
              </div>
          </div>
      </div>
    </DefaultTemplete>
  );
};

export default NoticeDetailPage;
