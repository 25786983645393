import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import intl from 'react-intl-universal';
import _ from 'lodash';

import { MessageContext } from '../../../routes';

import { LOGOUT_REQUEST } from '../../../store/modules/auth';
import {
  INIT_REQUEST,
  SITEINFO_CHANGE_LANG,
} from '../../../store/modules/base';

import {
  LANG,
  LANG_CODE,
  VIP_USABLE_BY_RANKS,
  ROOM_TYPE_CODE,
  LODGE_MOVE_CODE,
  LODGE_STATUS_CODE,
  VIP_USABLE_BY_POSITIONS_JEONJU,
  VIP_USABLE_BY_POSITIONS_ASAN
} from '../../../constant';

import './special.scss';
import { observer } from 'mobx-react';
import { useStores } from '../../../store/mobx';
import { isMobile } from 'react-device-detect';
import { formatByString_YYYYMMDD } from '../../../utils/date';
import { lodge } from '../../../apis';

// 남양 사업장과 아산일 경우 가족숙소 보이도록 수정
//const showFamilies = [2, 4];

// 가족숙소 아산만 보이게
const showFamilies = [2, 4];
const hideCompanyHouses = [2, 5, 6, 7];
const hideDomitoryHouses= [1, 2, 5, 6, 7];
const hideNamyang = [2];
// TODO 남양(2), 아산(4) -> 가족숙소 노출
// TODO 울산(1) -> 사택 비 노출
const statusUseable = [
  LODGE_STATUS_CODE.checkin
]

//뒤로가기(history.back) 으로 가는 header
const Sub = (props: any) => {
  //const reservationStore = props.reservationStore!
  const {reservationStore: reservationStore} = useStores();
  const {lodgedStore: lodgedStore} = useStores();
  const {headerStore: headerStore } = useStores();
  //const lodgedStore = props.lodgedStore!
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user,
  }));
  
  const [checkInlodgeds, setCheckInlodgeds] = useState<any[]>([]);
  const [reload, setReload] = useState<boolean>(false);

  // const getGuest = async() => {
  //   const { data } = await lodge.getGuest({}); // 이용중
  //     setCheckInlodgeds(data.filter((lodge: any) => statusUseable.includes(lodge.status) && lodge.room_type === 2 && lodge.status === 3 ));   // 이용중
  //     setReload(true);
  // }

  // useEffect(() => {
  //   headerStore.showFamily
  // }, [reload]);

  // 가족숙소 노출 여부
  const isShowFamily = () => _.includes(showFamilies, userInfo.worksite);

    // (userInfo.lodging && (userInfo.lodging.room_type === 2 && userInfo.lodging.stay_after));

    
  //back 막기
  /*if(isMobile) {
    //window.history.pushState(null, document.title, props.history.location.href); 
    //window.addEventListener('popstate', function(event) { 
    //    window.history.pushState(null, document.title, props.history.location.href); 
    //});
  }*/
  
  return (
    <>
      {/* 이용신청 서브탭 */}
      <dl className="lst_submenu">
        <dt>개인</dt>
        <dd>
          <ul>
            <li
              onClick={() => {
                reservationStore.roomInit();
                props.history.push(`/reservation/business/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
              // (window.location.href = `/reservation/business/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
              }}
            >
              <a href="javascript:void(0)">출장자숙소(개인)</a>
            </li>
            {userInfo.worksite != 2 ? (<li
              onClick={() => {
                reservationStore.roomInit();
                props.history.push(`/reservation/business/group?workSite=${userInfo.worksite }&ts=${new Date().getTime()}`)
                //(window.location.href = `/reservation/business/group?workSite=${userInfo.worksite }&ts=${new Date().getTime()}`)
              }}
            >
              <a href="javascript:void(0)">출장자숙소(단체)</a>
            </li>) : (<div></div>)}
            
            {/* domitory */}
            {!_.includes(hideDomitoryHouses, userInfo.worksite) || userInfo.isMarried === false ? (
              <li
                onClick={() =>{ 
                  reservationStore.roomInit();
                  props.history.push(`/reservation/dormitory/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                  //(window.location.href =  `/reservation/dormitory/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                }}
              >
                <a href="javascript:void(0)">기숙사</a>
              </li>
            ) : (
              <>
              {userInfo.worksite === 3 || userInfo.worksite === 4? (
                <li
                onClick={() =>{ 
                  reservationStore.roomInit();
                  props.history.push(`/reservation/dormitory/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                  //(window.location.href =  `/reservation/dormitory/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                }}
              >
                <a href="javascript:void(0)">기숙사</a>
              </li>
              ):(
                <></>
              )}
              </>
            )}
            {/* domitory */}
            
            {userInfo.worksite === 2? (
            <li
              style={{
                display: VIP_USABLE_BY_RANKS.includes(userInfo.rank)
                  ? 'block'
                  : 'none',
              }}
              onClick={() => {
                reservationStore.roomInit();
                props.history.push(`/reservation/vip/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                // (window.location.href = `/reservation/vip/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
              }}
            >
              <a href="javascript:void(0)">영빈관</a>
            </li>
            ): (
            <>
              {userInfo.worksite === 3? (
                <li
                style={{
                  display: VIP_USABLE_BY_POSITIONS_JEONJU.includes(userInfo.position)
                    ? 'block'
                    : 'none',
                }}
                onClick={() => {
                  reservationStore.roomInit();
                  props.history.push(`/reservation/vip/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                  // (window.location.href = `/reservation/vip/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                }}
              >
                <a href="javascript:void(0)">영빈관</a>
              </li>
              ) : (
                <>
                {userInfo.worksite === 4? (
                  <li
                  style={{
                    display: VIP_USABLE_BY_POSITIONS_ASAN.includes(userInfo.position)
                      ? 'block'
                      : 'none',
                  }}
                  onClick={() => {
                    reservationStore.roomInit();
                    props.history.push(`/reservation/vip/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                    // (window.location.href = `/reservation/vip/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                  }}
                >
                  <a href="javascript:void(0)">영빈관</a>
                </li>
                ) : (
                  <li
                    style={{
                      display: VIP_USABLE_BY_RANKS.includes(userInfo.position)
                        ? 'block'
                        : 'none',
                    }}
                    onClick={() => {
                      reservationStore.roomInit();
                      props.history.push(`/reservation/vip/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                      // (window.location.href = `/reservation/vip/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                    }}
                  >
                    <a href="javascript:void(0)">영빈관</a>
                  </li>
                )}
                </>
              )}
            </>
            )}

            {/* companyHouse */}
            {
              (userInfo.worksite === 2 && userInfo.isMarried === true)
              || (userInfo.worksite === 6 && userInfo.isMarried === true)
              ? (
              <li
                onClick={() => {
                  reservationStore.roomInit();
                  props.history.push(`/reservation/companyhouse/single?workSite=2&ts=${new Date().getTime()}`)
                  // (window.location.href = `/reservation/companyhouse/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                }}
              >
                <a href="javascript:void(0)">사택</a>
              </li>
            ) :(
              <>
            {userInfo.worksite === 3 || userInfo.worksite === 4? (
            <li
              onClick={() => {
                reservationStore.roomInit();
                props.history.push(`/reservation/companyhouse/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                // (window.location.href = `/reservation/companyhouse/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
              }}
              >
              <a href="javascript:void(0)">사택</a>
            </li>
            ) : ( <></>)}
            </>
            )}
            {/* companyHouse */}

            {headerStore._showFamily && isShowFamily() ? (
              <li
                onClick={() => {
                  reservationStore.roomInit();
                  props.history.push(`/reservation/familyhouse/group?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                  // (window.location.href = `/reservation/familyhouse/group?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                }}
              >
                <a href="javascript:void(0)">R&D STAY</a>
              </li>
            ) : (<></>)}
          </ul>
        </dd>
      </dl>

      {/* 이용안내 서브탭 */}
      <dl className="lst_submenu">
        <dd>
          <ul>
            <li
              onClick={() => {
                props.history.push(`/guide?roomType=${ROOM_TYPE_CODE.business_room}&workSite=${userInfo.worksite}`);
                // window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.business_room}&workSite=${userInfo.worksite}`
                if(props.allCloseTab) props.allCloseTab();
              }}
            >
              <a href="javascript:void(0)">출장자숙소(개인)</a>
            </li>
            {userInfo.worksite != 2 ? (<li
              onClick={() => {
                props.history.push(`/guide?roomType=${ROOM_TYPE_CODE.business_room_group}&workSite=${userInfo.worksite}`);
                // window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.business_room_group}&workSite=${userInfo.worksite}`;
                if(props.allCloseTab) props.allCloseTab();
              }}
            >
              <a href="javascript:void(0)">출장자숙소(단체)</a>
            </li>) : (<div></div>)}
            
            {/* domitory */}
            {!_.includes(hideDomitoryHouses, userInfo.worksite) || userInfo.isMarried === false ? (
              <li
              onClick={() => {
                props.history.push(`/guide?roomType=${ROOM_TYPE_CODE.dormitory_room}&workSite=${userInfo.worksite}`);
                // window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.dormitory_room}&workSite=${userInfo.worksite}`
                if(props.allCloseTab) props.allCloseTab();
              }}
              >
              <a href="javascript:void(0)">기숙사</a>
            </li>
            ) :(
              <>
              {userInfo.worksite === 3 || userInfo.worksite === 4? (
                <li
                onClick={() => {
                  props.history.push(`/guide?roomType=${ROOM_TYPE_CODE.dormitory_room}&workSite=${userInfo.worksite}`);
                  // window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.dormitory_room}&workSite=${userInfo.worksite}`
                  if(props.allCloseTab) props.allCloseTab();
                }}
                >
                <a href="javascript:void(0)">기숙사</a>
              </li>
              ) : (
                <></>
                )}
              </>
            )}
            {/* domitory */}


            {/* <li
              style={{
                display: VIP_USABLE_BY_RANKS.includes(userInfo.rank)
                  ? 'block'
                  : 'none',
              }}
              onClick={() => {
                //props.history.push(`/guide?roomType=${ROOM_TYPE_CODE.vip_room}&workSite=${userInfo.worksite}`)
                window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.vip_room}&workSite=${userInfo.worksite}`
              }}
            >
              <a href="javascript:void(0)">영빈관</a>
            </li> */}

            {userInfo.worksite === 2? (
            <li
              style={{
                display: VIP_USABLE_BY_RANKS.includes(userInfo.rank)
                  ? 'block'
                  : 'none',
              }}
              onClick={() => {
                props.history.push(`/guide?roomType=${ROOM_TYPE_CODE.vip_room}&workSite=${userInfo.worksite}`);
                // window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.vip_room}&workSite=${userInfo.worksite}`
                if(props.allCloseTab) props.allCloseTab();
              }}
            >
              <a href="javascript:void(0)">영빈관</a>
            </li>
            ): (
            <>
              {userInfo.worksite === 3? (
                <li
                style={{
                  display: VIP_USABLE_BY_POSITIONS_JEONJU.includes(userInfo.position)
                    ? 'block'
                    : 'none',
                }}
                onClick={() => {
                  props.history.push(`/guide?roomType=${ROOM_TYPE_CODE.vip_room}&workSite=${userInfo.worksite}`);
                  // window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.vip_room}&workSite=${userInfo.worksite}`
                  if(props.allCloseTab) props.allCloseTab();
                }}
              >
                <a href="javascript:void(0)">영빈관</a>
              </li>
              ) : (
                <>
                {userInfo.worksite === 4? (
                  <li
                  style={{
                    display: VIP_USABLE_BY_POSITIONS_ASAN.includes(userInfo.position)
                      ? 'block'
                      : 'none',
                  }}
                  onClick={() => {
                    props.history.push(`/guide?roomType=${ROOM_TYPE_CODE.vip_room}&workSite=${userInfo.worksite}`);
                    // window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.vip_room}&workSite=${userInfo.worksite}`
                    if(props.allCloseTab) props.allCloseTab();
                  }}
                >
                  <a href="javascript:void(0)">영빈관</a>
                </li>
                ) : (
                  <li
                    style={{
                      display: VIP_USABLE_BY_RANKS.includes(userInfo.position)
                        ? 'block'
                        : 'none',
                    }}
                    onClick={() => {
                      props.history.push(`/guide?roomType=${ROOM_TYPE_CODE.vip_room}&workSite=${userInfo.worksite}`);
                      // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.vip_room}&workSite=${userInfo.worksite}`)
                      if(props.allCloseTab) props.allCloseTab();
                    }}
                  >
                    <a href="javascript:void(0)">영빈관</a>
                  </li>
                )}
                </>
              )}
            </>
            )}

            {userInfo.worksite === 2 && userInfo.isMarried === true? (
            <li
              onClick={() => {
                props.history.push(`/guide?roomType=${ROOM_TYPE_CODE.company_room}&workSite=${userInfo.worksite}`);
                // window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.company_room}&workSite=${userInfo.worksite}`
                if(props.allCloseTab) props.allCloseTab();
              }}
            >
              <a href="javascript:void(0)">사택</a>
            </li>
            ) : (
              <>
              {userInfo.worksite === 3 || userInfo.worksite === 4? (
                <li
                onClick={() => {
                  props.history.push(`/guide?roomType=${ROOM_TYPE_CODE.company_room}&workSite=${userInfo.worksite}`);
                  // window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.company_room}&workSite=${userInfo.worksite}`
                  if(props.allCloseTab) props.allCloseTab();
                }}
              >
                <a href="javascript:void(0)">사택</a>
              </li>
              ) : (
                <></>
              )}
              </>
            )}

            {headerStore._showFamily && isShowFamily() ? (
              <li
                onClick={() => {
                  // reservationStore.roomInit();
                  props.history.push(`/guide?roomType=${ROOM_TYPE_CODE.family_room}&workSite=${userInfo.worksite}`);
                  // window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.family_room}&workSite=${userInfo.worksite}`
                  if(props.allCloseTab) props.allCloseTab();
                }}
              >
                <a href="javascript:void(0)">R&D STAY</a>
              </li>
            ) : (<></>)}
          </ul>
        </dd>
      </dl>

      {/* 고객센터 서브탭 */}
      <dl className="lst_submenu">
        <dt className="sr_only">고객센터</dt>
        <dd>
          <ul>
            <li onClick={() => props.history.push(`/customer/notice`) /*(window.location.href = `/customer/notice`)*/}>
              <a href="javascript:void(0)">공지사항</a>
            </li>
            {/* <li><a href="javascript:void(0)">My Voc</a></li> */}
            <li onClick={() => props.history.push(`/customer/voc`) /*(window.location.href = `/customer/voc`)*/}>
              <a href="javascript:void(0)">My VoC</a>
            </li>
            {/* <li onClick={() => (window.location.href = `/customer/audit`)}>
              <a href="javascript:void(0)">환경심사관리</a>
            </li> */}
            <li onClick={() => props.history.push(`/customer/audit`) /*(window.location.href = `/customer/audit`)*/}>
              <a href="javascript:void(0)">정기서류검사</a>
            </li>
            <li onClick={() => props.history.push(`/customer/faq`) /*(window.location.href = `/customer/faq`)*/}>
              <a href="javascript:void(0)">FAQ</a>
            </li>

            {/* <li><a href="javascript:void(0)">고충처리</a></li>
            <li><a href="javascript:void(0)">분실물</a></li>
            <li><a href="javascript:void(0)">나눔장터</a></li> */}
          </ul>
        </dd>
      </dl>

      {/* 마이페이지 서브탭 */}
      <dl className="lst_submenu">
        <dt className="sr_only">마이페이지</dt>
        <dd>
          <ul>
            <li onClick={() => props.history.push(`/mypage/sms`) /* (`/customer/sms`) (window.location.href = `/customer/sms`)*/}>
              <a href="javascript:void(0)">알림관리</a>
            </li>
            {/* <li><a href="javascript:void(0)">정보관리</a></li> */}
            <li onClick={() => props.history.push(`/mypage/user/info`) /*(window.location.href = '/mypage/user/info')*/}>
              <a href="javascript:void(0)">정보관리</a>
            </li>
            <li onClick={() => props.history.push(`/mypage/applicant`) /*(window.location.href = '/lodge/applicant')*/}>
              <a href="javascript:void(0)">신청관리</a>
            </li>
            <li onClick={() => props.history.push(`/mypage/guest`) /*(window.location.href = '/lodge/guest')*/}>
              <a href="javascript:void(0)">숙소이용정보</a>
            </li>
            <li onClick={() => props.history.push(`/mypage/checkout`) /*(window.location.href = '/lodge/checkout')*/}>
              <a href="javascript:void(0)">퇴숙관리</a>
            </li>
            <li onClick={() => {lodgedStore._category = LODGE_MOVE_CODE.alway; props.history.push(`/mypage/move`)} /*(window.location.href = '/lodge/checkout')*/}>
              <a href="javascript:void(0)">이숙관리</a>
            </li>
          </ul>
        </dd>
      </dl>
    </>
  );
}

const Header = observer((props: any) => {
  const {headerStore: headerStore} = useStores();
  const {reservationStore: reservationStore} = useStores();
  //const headerStore = props.headerStore!
  //const reservationStore = props.reservationStore!
  const { info } = useContext(MessageContext);
  const { userInfo = {}, workplaceMap = {} } = useSelector((state: any) => ({
    userInfo: state.user,
    workplaceMap: state.base.workplaceMap,
  }));
  const [isMobileMenu, setIsMenu] = useState<boolean>(false);

  const [isReservationTab, setIsReservationTab] = useState<boolean>(false); // 이용신청
  const [isUseGuideTab, setIsUseGuideTab] = useState<boolean>(false); // 이용안내
  const [isFacilityTab, setIsFacilityTab] = useState<boolean>(false); // 시설안내
  const [isServiceCenterTab, setIsServiceCenterTab] = useState<boolean>(false); // 고객센터
  const [isMypageTab, setIsMypageTab] = useState<boolean>(false); // 마이페이지

  const [isLangSelectorClick, setIsLangSelectorClick] = useState<boolean>(
    false,
  );
  const [selectLang, setSelectLang] = useState<number>(headerStore._lang);

  const dispatch = useDispatch();

  useEffect(() => {
    headerStore.isShowFamily();
  }, []);

  useEffect(() => {
    if (!props.isApiDisabled) {
      if (
        !window.localStorage.getItem('accessToken') ||
        !window.localStorage.getItem('refreshToken')
      ) {
        props.history.push('/');
        return;
      }
      const payload = {
        accessToken: window.localStorage.getItem('accessToken'),
        refreshToken: window.localStorage.getItem('refreshToken'),
      };

      dispatch({
        type: INIT_REQUEST,
        payload: payload,
        history: props.history,
      });
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: SITEINFO_CHANGE_LANG,
      payload: LANG[selectLang],
    });
  }, [selectLang]);

  const PATH_MAP: any = {
    '/guide': '이용안내',
    '/reservation/vip/single': '이용신청',
    '/reservation/business/single': '이용신청',
    '/reservation/business/group': '이용신청',
    '/reservation/dormitory/single': '이용신청',
    '/reservation/companyhouse/single': '이용신청',
    '/reservation/familyhouse/group': '이용신청',
    '/facility/information': '시설안내',
    '/lodge': '신청관리',
    '/customer/notice': '공지사항',
    '/customer/notice/detail': '공지사항',
    '/customer/voc': 'My VoC',
    '/customer/voc/create': 'My VoC',
    '/customer/voc/detail': 'My VoC',
    '/customer/audit': '정기서류검사',
    '/customer/faq': 'FAQ',
    '/customer/faq/detail': 'FAQ',
    '/mypage/user/info': '마이페이지',
    '/mypage/applicant': '신청관리',
    '/mypage/applicant/detail': '신청관리',
    '/mypage/guest': '이용관리',
    '/mypage/guest/detail': '이용관리',
    '/mypage/checkout': '퇴숙관리',
    '/mypage/checkout/detail' : '퇴숙관리',
    '/mypage/move': '이숙관리',
    '/mypage/move/detail' : '이숙관리',
    '/mypage/move/reqdetail' : '이숙관리',
    '/mypage/move/apply' : '이숙신청',
    '/mypage/move/reqapply' : '이숙신청',

  };

  const allCloseTab = () => {
    setIsReservationTab(false);
    setIsUseGuideTab(false);
    setIsFacilityTab(false);
    setIsServiceCenterTab(false);
    setIsMypageTab(false);
  };

  // 언어변환
  const onLangChangeHandler = (langCode: number) => {
    setIsLangSelectorClick(!isLangSelectorClick);
    headerStore._lang = langCode;
    setSelectLang(langCode);
    // console.log('langCode>>>>>>>>>>', langCode);
    dispatch({
      type: SITEINFO_CHANGE_LANG,
      payload: LANG[langCode],
    });
  };

  const [checkInlodgeds, setCheckInlodgeds] = useState<any[]>([]);
  const [reload, setReload] = useState<boolean>(false);

  // const getGuest = async() => {
  //   const { data } = await lodge.getGuest({}); // 이용중
  //     setCheckInlodgeds(data.filter((lodge: any) => statusUseable.includes(lodge.status) && lodge.room_type === 2 && lodge.status === 3 ));   // 이용중
  //     setReload(true);
  // }

  // useEffect(() => {
  //   getGuest();
  // }, [reload]);

  // 가족숙소 노출 여부
  const isShowFamily = () => _.includes(showFamilies, userInfo.worksite)
    // && (userInfo.lodging && (userInfo.lodging.room_type === 2 && userInfo.lodging.stay_after));

  return (
    <header id="header" className="">
      <h1 onClick={() => props.history.push('/home') /*(window.location.href = '/home')*/}>
        <a href="javascript:void(0)" className="logo ir">
          AMS Platform
        </a>
      </h1>

      <ul className="lst_gnb">
        <li
          onMouseEnter={() => setIsReservationTab(true)}
          onMouseLeave={() => setIsReservationTab(false)}
        >
          <a
            href="javascript:void(0)"
            className={`${
              props.history.location.pathname.includes('/reservation')
                ? 'on'
                : ''
            }`}
            // onClick={() => props.history.push(`/reservation/business/single?workSite=${userInfo.worksite}`)
            //   // (window.location.href = `/reservation/business/group?workSite=${userInfo.worksite}`)
            // }
          >
            이용신청
          </a>
          <div className={`submenu request ${isReservationTab ? 'on' : ''}`}>
            {' '}
            {/*on 추가 시 show*/}
            <div className="title_area">
              <p className="title">이용신청</p>
              <p className="desc">
                숙소 유형별로 숙소 이용신청을 진행하실 수 있습니다
              </p>
            </div>
            <Sub history={props.history} />
          </div>
        </li>
        <li
          onMouseEnter={() => setIsUseGuideTab(true)}
          onMouseLeave={() => setIsUseGuideTab(false)}
        >
          <a
            href="javascript:void()"
            className={`${
              props.history.location.pathname.includes('/guide') ? 'on' : ''
            }`}
            // onClick={() => props.history.push(
            //   `/guide?workSite=${userInfo.worksite}&roomType=${ROOM_TYPE_CODE.business_room}`)
            //   // (window.location.href = `/guide?workSite=${userInfo.worksite}&roomType=${ROOM_TYPE_CODE.business_room_group}`)
            // }
          >
            이용안내
          </a>
          <div className={`submenu request ${isUseGuideTab ? 'on' : ''}`}>
            <div className="title_area">
              <p className="title">이용안내</p>
              <p className="desc">숙소이용을 위해 필요한 사항을 안내드립니다</p>
            </div>

            <Sub history={props.history} allCloseTab={allCloseTab} />
          </div>
        </li>

        <li
          onMouseEnter={() => setIsServiceCenterTab(true)}
          onMouseLeave={() => setIsServiceCenterTab(false)}
        >
          <a href="javascript:void(0)"
            className={`${
              props.history.location.pathname.includes('/customer') ? 'on' : ''
            }`}
            // onClick={() => props.history.push(`/customer/notice`)
            //   // (window.location.href = `/guide?workSite=${userInfo.worksite}&roomType=${ROOM_TYPE_CODE.business_room_group}`)
            // }  
          >
            고객센터
          </a>
          <div className={`submenu request ${isServiceCenterTab ? 'on' : ''}`}>
            <div className="title_area">
              <p className="title">고객센터</p>
              <p className="desc">공지사항, FAQ, VoC등을 확인하실 수 있습니다</p>
            </div>
            <Sub history={props.history} />
          </div>
        </li>

        <li
          onMouseEnter={() => setIsMypageTab(true)}
          onMouseLeave={() => setIsMypageTab(false)}
        >
          <a href="javascript:void(0)"
            className={`${
              props.history.location.pathname.includes('/mypage') ? 'on' : ''
            }`}
            // onClick={() => props.history.push(`/mypage/sms`)
            //   // (window.location.href = `/guide?workSite=${userInfo.worksite}&roomType=${ROOM_TYPE_CODE.business_room_group}`)
            // }
          >
            마이페이지
          </a>
          <div className={`submenu request ${isMypageTab ? 'on' : ''}`}>
            <div className="title_area">
              <p className="title">마이페이지</p>
              <p className="desc">
                나의 신청정보 및 이용정보 등을 관리하실 수 있습니다
              </p>
            </div>
            <Sub history={props.history} />
          </div>
        </li>

        <li>
          <h3 className="sr_only">전체메뉴</h3>
          <div className="submenu allmenu">
            <div className="title_area">
              <p className="title">전체보기</p>
              <p className="desc">
                AMS Platform의 메뉴를 한눈에
                <br />
                쉽게 보실수 있습니다.
              </p>
            </div>

            <dl className="lst_submenu">
              <dt className="sr_only">이용신청</dt>
              <dd>
                <ul>
                  <li>
                    <a href="javascript:void(0)">기숙사</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">사택</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" className="on">
                      출장자숙소
                    </a>
                  </li>
                  <li
                    style={{
                      display: VIP_USABLE_BY_RANKS.includes(userInfo.rank)
                        ? 'block'
                        : 'none',
                    }}
                  >
                    <a href="javascript:void(0)">영빈관</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">R&D STAY</a>
                  </li>
                </ul>
              </dd>
            </dl>
            <dl className="lst_submenu">
              <dt className="sr_only">이용안내</dt>
              <dd>
                <ul>
                  <li>
                    <a href="javascript:void(0)">남양</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">울산</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">아산</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">전주</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">마북</a>
                  </li>
                </ul>
              </dd>
            </dl>
            <dl className="lst_submenu">
              <dt className="sr_only">시설안내</dt>
              <dd>
                <ul>
                  <li>
                    <a href="javascript:void(0)">남양</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">울산</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">아산</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">전주</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">마북</a>
                  </li>
                </ul>
              </dd>
            </dl>
            <dl className="lst_submenu">
              <dt className="sr_only">고객센터</dt>
              <dd>
                <ul>
                  <li>
                    <a href="javascript:void(0)">공지사항</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">My Voc</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">FAQ</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">고충처리</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">분실물</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">나눔장터</a>
                  </li>
                </ul>
              </dd>
            </dl>
            <dl className="lst_submenu">
              <dt className="sr_only">My Page</dt>
              <dd>
                <ul>
                  <li>
                    <a href="javascript:void(0)">정보관리</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">신청관리</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">퇴숙신청</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">이숙신청</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">숙소이용정보</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">증명서 발급</a>
                  </li>
                </ul>
              </dd>
            </dl>
          </div>
        </li>
      </ul>

      {/* <div className="bar" style={{ width: 675 + 'px'}}></div> */}

      <ul className="lst_log">
        <li className="user">
          <span>{userInfo.username}</span> 님은{' '}
          {workplaceMap[userInfo.worksite]?.name}에 근무 중 입니다!
        </li>
        <li
          className="log"
          onClick={() => {
            headerStore._lang = LANG_CODE.kr;
            dispatch({ type: LOGOUT_REQUEST, history: props.history })
          }}
        >
          <a href="javascript:void(0)">Log-Out</a>
        </li>
        <li className="global">
          <a
            style={{cursor: 'pointer'}}
            //href="#"
            className="selected_dropdown"
            onClick={() => setIsLangSelectorClick(!isLangSelectorClick)}
          >
            {intl.get(LANG[selectLang])}
          </a>
          <ul className={`lst_dropdown ${isLangSelectorClick ? "on": ""}`}>
            <li>
              <a
                style={{cursor: 'pointer'}}
                //href="javascript:void(0)"
                onClick={() => {
                  onLangChangeHandler(LANG_CODE.kr);
                }}
              >
                {intl.get(LANG[LANG_CODE.kr])}
              </a>
            </li>
            <li>
              <a
                style={{cursor: 'pointer'}}
                //href="javascript:void(0)"
                onClick={() => {
                  onLangChangeHandler(LANG_CODE.en);
                }}
              >
                {intl.get(LANG[LANG_CODE.en])}
              </a>
            </li>
            {/* <li>
              <a href="javascript:void(0)">일본어</a>
            </li> */}
          </ul>
        </li>
	      <li>
          <p className="fixed_text">
            {userInfo.loginHistory && userInfo.loginHistory.created_at? '최근 접속 일자: '+formatByString_YYYYMMDD(userInfo.loginHistory.created_at) : ''} 
            <span className="ml20">{userInfo.loginHistory && userInfo.loginHistory.ip_address? 'IP: '+userInfo.loginHistory.ip_address : ''}</span>
          </p>
        </li>
      </ul>

      <ul className="lst_member special-mung">
        {/* <li className="alarm"><a href="javascript:void(0)"><span className="count">{ }</span></a></li> */}
        <li className="allmenu" onClick={() => setIsMenu(!isMobileMenu)}>
          <a href="javascript:void(0)">
            <span></span>
            <span></span>
            <span></span>
          </a>
        </li>
      </ul>

      <div className="header_sub_mobile">
        <button className="btn_prev" onClick={() => { props.history.goBack(); }}>
          <span className="sr_only">뒤로가기</span>
        </button>
        <h2 className="title_sub">{PATH_MAP[window.location.pathname]}</h2>
      </div>

      {/*allmenu mobile*/}
      <div className={`allmenu_mobile ${isMobileMenu ? 'on' : ''}`}>
        {' '}
        {/*on 추가 시 show*/}
        <div className="allmenu_area">
          <ul className="lst_member">
            <li className="home"
              onClick ={() => {
                props.history.push('/home');
              }}
            >
              <a href="javascript:void(0);">Home</a>
            </li>
            <li
              className="log"
              onClick={() => {
                headerStore._lang = LANG_CODE.kr;
                dispatch({ type: LOGOUT_REQUEST, history: props.history })
              }}
            >
              <a href="javascript:void(0)">Log-Out</a>
            </li>
            <li className="alarm">
              <a href="javascript:void(0)">
                <span className="count">{ }</span>
              </a>
            </li>
            <li className="allmenu" onClick={() => setIsMenu(!isMobileMenu)}>
              <a href="javascript:void(0)" className="ir">
                닫기
              </a>
            </li>
          </ul>

          <div className="userinfo">
            <p className="userimg">
              <img src="/static/img/mobile/ico_user.png" alt="" />
            </p>
            <p>
              {userInfo.username}님은
              <br />
              {workplaceMap[userInfo.worksite]?.name}에서 근무 중 입니다.
            </p>
          </div>

          {/* 모바일은 그룹(단체)신청 안됨 */}
          <ul className="lst_allmenu">
            <li>
              <a
                href="javascript:void(0)"
                className={isReservationTab ? 'on' : ''}
                onClick={() => {
                  allCloseTab();
                  setIsReservationTab(!isReservationTab);
                }}
              >
                이용신청
              </a>
              <div
                className={`submenu installation ${
                  isReservationTab ? 'on' : ''
                }`}
              >
                <dl className={`lst_submenu ${isReservationTab ? 'on' : ''}`}>
                  <dd>
                    <ul>
                      <li
                        onClick={() => {
                          reservationStore.roomInit();
                          props.history.push(`/reservation/business/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                        }}
                      >
                        <a href="javascript:void(0)">출장자숙소(개인)</a>
                      </li>
                      {!_.includes(hideDomitoryHouses, userInfo.worksite) || userInfo.isMarried === false ? (
                      <li
                        onClick={() =>{ 
                          reservationStore.roomInit();
                          props.history.push(`/reservation/dormitory/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                          //(window.location.href =  `/reservation/dormitory/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                        }}
                      >
                        <a href="javascript:void(0)">기숙사</a>
                      </li>
                      ):(
                        <>
                      {userInfo.worksite === 3 || userInfo.worksite === 4? (
                      <li
                        onClick={() =>{ 
                          reservationStore.roomInit();
                          props.history.push(`/reservation/dormitory/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                          //(window.location.href =  `/reservation/dormitory/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                        }}
                        >
                        <a href="javascript:void(0)">기숙사</a>
                      </li>
                      ) : (
                        <></>
                      )}
                        </>
                      )}

                      {/* <li
                        style={{
                          display: VIP_USABLE_BY_RANKS.includes(userInfo.rank)
                            ? 'block'
                            : 'none',
                        }}
                      >
                        <Link
                          to={`/reservation/vip/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`}
                          onClick={() => {reservationStore.roomInit(); setIsMenu(false)}}
                        >
                          영빈관
                        </Link>
                      </li> */}

                      {userInfo.worksite === 2? (
                      <li
                        style={{
                          display: VIP_USABLE_BY_RANKS.includes(userInfo.rank)
                            ? 'block'
                            : 'none',
                        }}
                        onClick={() => {
                          reservationStore.roomInit();
                          props.history.push(`/reservation/vip/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                          // (window.location.href = `/reservation/vip/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                        }}
                      >
                        <a href="javascript:void(0)">영빈관</a>
                      </li>
                      ): (
                      <>
                        {userInfo.worksite === 3? (
                          <li
                          style={{
                            display: VIP_USABLE_BY_POSITIONS_JEONJU.includes(userInfo.position)
                              ? 'block'
                              : 'none',
                          }}
                          onClick={() => {
                            reservationStore.roomInit();
                            props.history.push(`/reservation/vip/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                            // (window.location.href = `/reservation/vip/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                          }}
                        >
                          <a href="javascript:void(0)">영빈관</a>
                        </li>
                        ) : (
                          <>
                          {userInfo.worksite === 4? (
                            <li
                            style={{
                              display: VIP_USABLE_BY_POSITIONS_ASAN.includes(userInfo.position)
                                ? 'block'
                                : 'none',
                            }}
                            onClick={() => {
                              reservationStore.roomInit();
                              props.history.push(`/reservation/vip/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                              // (window.location.href = `/reservation/vip/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                            }}
                          >
                            <a href="javascript:void(0)">영빈관</a>
                          </li>
                          ) : (
                            <li
                            style={{
                              display: VIP_USABLE_BY_RANKS.includes(userInfo.position)
                                ? 'block'
                                : 'none',
                            }}
                            onClick={() => {
                              reservationStore.roomInit();
                              props.history.push(`/reservation/vip/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                              // (window.location.href = `/reservation/vip/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                            }}
                          >
                            <a href="javascript:void(0)">영빈관</a>
                          </li>
                          )}
                          </>
                        )}
                      </>
                      )}

                      {/* {!_.includes(hideCompanyHouses, userInfo.worksite) && userInfo.isMarried === true ? (
                      <li
                        onClick={() => {
                          reservationStore.roomInit();
                          props.history.push(`/reservation/companyhouse/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                          // (window.location.href = `/reservation/companyhouse/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                        }}
                      >
                        <a href="javascript:void(0)">사택</a>
                      </li>
                      ) : (
                      <>
                      {userInfo.worksite === 3 || userInfo.worksite === 4? (
                      <li
                        onClick={() => {
                          reservationStore.roomInit();
                          props.history.push(`/reservation/companyhouse/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                          // (window.location.href = `/reservation/companyhouse/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                        }}
                        >
                        <a href="javascript:void(0)">사택</a>
                      </li>
                      ) : (
                      <></>
                      )}
                      </>
                      )} */}
                      {/* companyHouse */}
                      {userInfo.worksite === 2 && userInfo.isMarried === true? (
                        <li
                          onClick={() => {
                            reservationStore.roomInit();
                            props.history.push(`/reservation/companyhouse/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                            // (window.location.href = `/reservation/companyhouse/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                          }}
                        >
                          <a href="javascript:void(0)">사택</a>
                        </li>
                      ) :(
                        <>
                      {userInfo.worksite === 3 || userInfo.worksite === 4? (
                      <li
                        onClick={() => {
                          reservationStore.roomInit();
                          props.history.push(`/reservation/companyhouse/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                          // (window.location.href = `/reservation/companyhouse/single?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                        }}
                        >
                        <a href="javascript:void(0)">사택</a>
                      </li>
                      ) : ( <></>)}
                      </>
                      )}
                      {/* companyHouse */}
                      {/* {_.includes(showFamilies, userInfo.worksite) ? ( */}
                      {/* {isShowFamily() ? (
                        <li>
                          <Link
                            to={`/reservation/familyhouse/group?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`}
                            onClick={() => {reservationStore.roomInit(); setIsMenu(false)}}
                          >
                            가족숙소
                          </Link>
                        </li>
                      ) : (<></>)} */}
                      {headerStore._showFamily && isShowFamily() ? (
                        <li
                          onClick={() => {
                            reservationStore.roomInit();
                            props.history.push(`/reservation/familyhouse/group?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                            // (window.location.href = `/reservation/familyhouse/group?workSite=${userInfo.worksite}&ts=${new Date().getTime()}`)
                          }}
                        >
                          <a href="javascript:void(0)">R&D STAY</a>
                        </li>
                      ) : (<></>)}
                    </ul>
                  </dd>
                </dl>

              </div>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className={isUseGuideTab ? 'on' : ''}
                onClick={() => {
                  allCloseTab();
                  setIsUseGuideTab(!isUseGuideTab);
                }}
              >
                이용안내
              </a>
              <div
                className={`submenu installation ${isUseGuideTab ? 'on' : ''}`}
              >
                <dl className={`lst_submenu ${isUseGuideTab ? 'on' : ''}`}>
                  <dd>
                    <ul>
                      <li>
                        <Link
                          to={`/guide?roomType=${ROOM_TYPE_CODE.business_room}&workSite=${userInfo.worksite}`}
                          onClick={() => setIsMenu(false)}
                        >
                          출장자숙소(개인)
                        </Link>
                      </li>
                      {/* <li
                        style={{
                          display: VIP_USABLE_BY_RANKS.includes(userInfo.rank)
                            ? 'block'
                            : 'none',
                        }}
                      >
                        <Link
                          to={`/guide?roomType=${ROOM_TYPE_CODE.vip_room}&workSite=${userInfo.worksite}`}
                          onClick={() => setIsMenu(false)}
                        >
                          영빈관
                        </Link>
                      </li> */}
                      
                      {userInfo.worksite === 2? (
                      <li
                        style={{
                          display: VIP_USABLE_BY_RANKS.includes(userInfo.rank)
                            ? 'block'
                            : 'none',
                        }}
                      >
                        <Link
                          to={`/guide?roomType=${ROOM_TYPE_CODE.vip_room}&workSite=${userInfo.worksite}`}
                          onClick={() => setIsMenu(false)}
                        >
                          영빈관
                        </Link>
                      </li>
                      ): (
                      <>
                        {userInfo.worksite === 3? (
                          <li
                          style={{
                            display: VIP_USABLE_BY_POSITIONS_JEONJU.includes(userInfo.position)
                              ? 'block'
                              : 'none',
                          }}
                        >
                          <Link
                          to={`/guide?roomType=${ROOM_TYPE_CODE.vip_room}&workSite=${userInfo.worksite}`}
                          onClick={() => setIsMenu(false)}
                        >
                          영빈관
                        </Link>
                        </li>
                        ) : (
                          <>
                          {userInfo.worksite === 4? (
                            <li
                            style={{
                              display: VIP_USABLE_BY_POSITIONS_ASAN.includes(userInfo.position)
                                ? 'block'
                                : 'none',
                            }}
                          >
                            <Link
                            to={`/guide?roomType=${ROOM_TYPE_CODE.vip_room}&workSite=${userInfo.worksite}`}
                            onClick={() => setIsMenu(false)}
                            >
                              영빈관
                            </Link>
                          </li>
                          ) : (
                            <li
                              style={{
                                display: VIP_USABLE_BY_RANKS.includes(userInfo.position)
                                  ? 'block'
                                  : 'none',
                              }}
                            >
                              <Link
                                to={`/guide?roomType=${ROOM_TYPE_CODE.vip_room}&workSite=${userInfo.worksite}`}
                                onClick={() => setIsMenu(false)}
                              >
                                영빈관
                              </Link>
                            </li>
                          )}
                          </>
                        )}
                      </>
                      )}


                      {!_.includes(hideDomitoryHouses, userInfo.worksite) || userInfo.isMarried === false ? (
                      <li>
                        <Link
                          to={`/guide?roomType=${ROOM_TYPE_CODE.dormitory_room}&workSite=${userInfo.worksite}`}
                          onClick={() => setIsMenu(false)}
                        >기숙사</Link>
                      </li>
                      ) :(
                        <>
                        {userInfo.worksite === 3 || userInfo.worksite === 4? (
                        <li>
                          <Link
                          to={`/guide?roomType=${ROOM_TYPE_CODE.dormitory_room}&workSite=${userInfo.worksite}`}
                          onClick={() => setIsMenu(false)}
                          >기숙사</Link>
                        </li>
                        ) : (
                          <></>
                        )}
                        </>
                      )}

                      {userInfo.worksite === 2 && userInfo.isMarried === true ? (
                      <li>
                        <Link
                          to={`/guide?roomType=${ROOM_TYPE_CODE.company_room}&workSite=${userInfo.worksite}`}
                          onClick={() => setIsMenu(false)}
                          >사택</Link>
                      </li>
                      ) : (
                        <>
                        {userInfo.worksite === 3 || userInfo.worksite === 4? (
                        <li>
                          <Link
                          to={`/guide?roomType=${ROOM_TYPE_CODE.company_room}&workSite=${userInfo.worksite}`}
                          onClick={() => setIsMenu(false)}
                          >사택</Link>
                        </li>
                        ) : (
                          <></>
                        )}
                        </>
                      )}
                      {/* {isShowFamily() ? (
                      <li>
                        <Link
                          to={`/guide?roomType=${ROOM_TYPE_CODE.family_room}&workSite=${userInfo.worksite}`}
                          onClick={() => setIsMenu(false)}
                        >
                          가족숙소
                        </Link>
                      </li>
                      ) : (<></>)} */}
                      {/* {checkInlodgeds.length > 0? (
                      <>
                      {isShowFamily() ? (
                      <li
                        onClick={() => props.history.push(
                          `/guide?roomType=${ROOM_TYPE_CODE.family_room}&workSite=${userInfo.worksite}`)
                          // (window.location.href = `/guide?roomType=${ROOM_TYPE_CODE.family_room}&workSite=${userInfo.worksite}`)
                        }
                      >
                        <a href="javascript:void(0)">R&D STAY</a>
                      </li>
                      ) : (<></>)}
                      </>
                      ) : (
                      <></>
                      )} */}
                      {headerStore._showFamily && isShowFamily() ? (
                        <li>
                          <Link
                          to={`/guide?roomType=${ROOM_TYPE_CODE.family_room}&workSite=${userInfo.worksite}`}
                          onClick={() => setIsMenu(false)}
                          >R&D STAY</Link>
                        </li>
                      ) : (<></>)}
                    </ul>
                  </dd>
                </dl>
              </div>
            </li>
            <li>
              <a 
                href="javascript:void(0)"
                className={isServiceCenterTab ? 'on' : ''}
                onClick={() => { allCloseTab(); setIsServiceCenterTab(!isServiceCenterTab) }}
              >
                고객센터
              </a>
              <div
                className={`submenu customer ${isServiceCenterTab ? 'on' : ''}`}
              >
                <dl className={`lst_submenu ${isServiceCenterTab ? 'on' : ''}`}>
                  <dd>
                    <ul>
                      <li>
                        <Link
                          to={`/customer/notice`}
                          onClick={() => setIsMenu(false)}
                        >
                          공지사항
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/customer/voc`}
                          onClick={() => setIsMenu(false)}
                        >
                          My VoC
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/customer/audit`}
                          onClick={() => setIsMenu(false)}
                        >
                          정기서류검사
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/customer/faq`}
                          onClick={() => setIsMenu(false)}
                        >
                          FAQ
                        </Link>
                      </li>
                    </ul>
                  </dd>
                </dl>
              </div>
            </li>
            <li>
              <a
                href="javascript:void(0)"
                className={isMypageTab ? 'on' : ''}
                onClick={() => {
                  allCloseTab();
                  setIsMypageTab(!isMypageTab);
                }}
              >
                마이페이지
              </a>
              <div className={`submenu mypage ${isMypageTab ? 'on' : ''}`}>
                <dl className="lst_submenu on">
                  <dd>
                    <ul>
                      {/* <li><a href="javascript:void(0)">정보관리</a></li> */}
                      <li
                        onClick={() => props.history.push(`/mypage/user/info`)
                          // (window.location.href = '/mypage/user/info')
                        }
                      >
                        <a href="javascript:void(0)">정보관리</a>
                      </li>
                      <li
                        onClick={() => props.history.push(`/mypage/applicant`)
                          // (window.location.href = '/lodge/applicant')
                        }
                      >
                        <a href="javascript:void(0)">신청관리</a>
                      </li>
                      <li
                        onClick={() => props.history.push(`/mypage/guest`)
                        // (window.location.href = '/lodge/guest')
                      }
                      >
                        <a href="javascript:void(0)">이용관리</a>
                      </li>
                      <li
                        onClick={() => props.history.push(`/mypage/checkout`)
                          // (window.location.href = '/lodge/checkout')
                        }
                      >
                        <a href="javascript:void(0)">퇴숙관리</a>
                      </li>
                      <li onClick={() => props.history.push(`/mypage/move`)}>
                        <a href="javascript:void(0)">이숙관리</a>
                      </li>
                    </ul>
                  </dd>
                </dl>
              </div>
            </li>
          </ul>
          <p className="fixed_text">
            {userInfo.loginHistory && userInfo.loginHistory.created_at? '최근 접속 일자: '+formatByString_YYYYMMDD(userInfo.loginHistory.created_at) : ''} 
            <span className="ml20">{userInfo.loginHistory && userInfo.loginHistory.ip_address? 'IP: '+userInfo.loginHistory.ip_address : ''}</span>
          </p>
        </div>
      </div>
    </header >
  );
});

export default Header;
