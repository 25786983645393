import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';

import { MessageContext } from '../../routes';

import useWorkSite from '../../hooks/useWorkSite';

import WorkSiteSelect from '../../components/atoms/select/workSite';
import Calendar from '../../components/molecules/calendar';
import ConfirmBtn from '../../components/atoms/button/confirm';
import PhoneInput from '../../components/atoms/input/phone';

import { GENDER } from '../../constant';

import intl from 'react-intl-universal';

import {
  isAfterAndSame,
  getTodayDate,
  getNextDay,
  getPrevDay,
} from '../../utils/date';

import { ROOM_TYPE_CODE } from '../../constant';

import _ from 'lodash';
import { reduceEachTrailingCommentRange } from 'typescript';
import { observer } from 'mobx-react';
import { useStores } from '../../store/mobx';
import OnOff from '../../apis/onOff';
import { SHOW_MODAL } from '../../store/modules/modal';

const Step1 = observer((props: any) => {
  const {reservationStore: store} = useStores();
  const { info } = useContext(MessageContext);
  const [selectRoom, setSelectRoom] = useState<any>({});
  const [guestList, setGuestList] = useState<any>(store._familyRoom.guests? store._familyRoom.guests : props.guests);

  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user,
  }));

  const { workplaceMap = {} } = useSelector((state: any) => ({
    workplaceMap: state.base.workplaceMap
  }))

  // const [ worksites, setUserWorkSiteByWorksite, setRoomTypeByWorksite ] = useWorkSite({
  //   roomType: ROOM_TYPE_CODE.family_room,
  //   userWorkSite: userInfo.worksite
  // })
  const [ worksites, setWorksites ] = useState<any>([]);

  useEffect(() => {
    (async () => {
      if(userInfo.worksite !== 0){
        try {
          // 사업장 그리기
          let { data } = await OnOff.getWorksite({
            // work_site: props.value,
            user_work_site: userInfo.worksite,
            room_type: ROOM_TYPE_CODE.family_room
          });

          setWorksites(data);
          
          // 초기 사업장 선택
          if(store._familyRoom.work_site && store._familyRoom.work_site != 0) {
            // 이전버튼 누른 경우 데이터 세팅
            props.setWorkSite(store._familyRoom.work_site);
          }else {
            if(data.filter((d: any) => d.work_site === userInfo.worksite).length > 0){
              props.setWorkSite(userInfo.worksite);
            }else if(data[0].work_site) {
              props.setWorkSite(data[0].work_site);
            }
          }
        }catch ({ status, data }) {
          if (status === 401) {
            window.location.href = '/force/signout';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }

          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '문제발생',
              msg: '문제가 발생했습니다',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }
      }
    })();
  }, [userInfo.worksite]);

  useEffect(() => {
    if (worksites.length) {
      //props.setWorkSite(userInfo.worksite);
    }
  }, [worksites])

  useEffect(() => {
    // setUserWorkSiteByWorksite(userInfo.worksite)
  }, [userInfo.worksite])

  useEffect(() => {
    let guests = Array
      .from(Array(parseInt(props.guestCnt)).keys())
      .map(() => ({
        guest_name: '',
        guest_gender: 1,
        guest_cellphone: '',
        guest_relation: ''
      }))
    props.setGuests(guests);
    //store._familyRoom = guests;
  }, [props.guestCnt])

  const changeGuestCnt = () => {
    let guests = Array
      .from(Array(parseInt(props.guestCnt)).keys())
      .map(() => ({
        guest_name: '',
        guest_gender: 1,
        guest_cellphone: '',
        guest_relation: ''
      }))
    props.setGuests(guests);
    store._familyRoom = guests;
  }

  const todayDate = getTodayDate();

  const onChangeGuestCntHandler = (e: any) => {
    props.setGuestCnt(e.target.value);
    //changeGuestCnt();
    store._familyRoom = [];
  }

  const onChangeGuestInfoHandler = (idx: number, key: string, value: string | number) => {

    let tempGuests = _.cloneDeep(props.guests);
    tempGuests[idx][key] = value;

    props.setGuests(tempGuests);
    store._familyRoom.guests = tempGuests;
  }

  const onNextHandler = () => {
    
    let nextChk : boolean = false;
    let nextChkNum : number = 0;
    props.guests.map((guest: any, guestIdx: number) => {
      let target = '이용자 '+ (guestIdx+1);
      
      if(!guest.guest_name) {
        nextChk = false;
        nextChkNum ++;
        info(target+' 성명을 입력해주세요.');
        return;
      }else if(!guest.guest_cellphone || !(guest.guest_cellphone.length === 11)) {
        nextChk = false;
        nextChkNum ++;
       info(target+' 휴대폰번호 항목이 비었거나 잘못되었습니다.\n(휴대폰번호는 11자리입니다.)');
       return;
      }else if(!guest.guest_gender) {
        nextChk = false;
        nextChkNum ++;
       info(target+' 성별 항목이 비었습니다.');
       return;
      }else if(!guest.guest_relation) {
        nextChk = false;
        nextChkNum ++;
        info(target+' 신청자와의 관계 항목이 비었습니다.');
        return;
      }else {
        nextChk = true;
      }

      if(!guest.guest_car_number1){   // 차량 번호 X -> 빈값으로라도 전달하기
        guest.guest_car_number1 = '';
      }
    });

    store._familyRoom = [];
    store._familyRoom.guests = props.guests;
    
    if(nextChkNum === 0) {
      props.next();
    }
  }

  return (
    <>
      <ul className="lst_thumb_request">
        <li>
          <strong>{intl.get("숙소유형")}</strong>
          <span>{intl.get("R&D STAY")}</span>
        </li>
        <li>
          <strong>{intl.get("이용사업장")}</strong>
          <span>{workplaceMap[userInfo.worksite]?.name}</span>
        </li>
        <li>
          <strong>{intl.get("입숙예정일")}</strong>
          <span></span>
        </li>
        <li>
          <strong>{intl.get("퇴숙예정일")}</strong>
          <span></span>
        </li>

      </ul>

      <WorkSiteSelect value={props.workSite} options={worksites} onChange={(workSite: number) => props.setWorkSite(workSite)} />

      <hr className="bold mobile" />

      <div className="title_contents title_selectdate">
        <h3 className="title">{intl.get("이용자 정보")}</h3>
        <div className="right_area">
          <div className="form_select">
            <label htmlFor="select_usernum">{intl.get("투숙인원을 선택 후 투숙자의 정보를 입력해 주세요")}</label>
            <select name="" id="select_usernum" className="form_control" value={props.guestCnt} onChange={onChangeGuestCntHandler}>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </div>
        </div>
      </div>

      {props.guests.map((guest: any, guestIdx: number) => (
        <>
          <div className="title_contents">
          {/*<h3 className="title">이용자 정보</h3>*/}
            <h3 className="title">{intl.get("이용자")} {guestIdx + 1}</h3>
            {!guestIdx && (
              <ul className="lst_help">
                <li>{intl.get("성명, 성별, 신청자와의 관계는 필수로 입력하셔야 합니다.")} </li>
                {userInfo.worksite == 2 ? (<li>
                  투숙인원은 입숙예정인 가족 정보임
                  <br />[신청자] 제외 입력하셔야 합니다.
                </li>) : <div></div>}
              </ul>
            )}
          </div>

          <div className="table_design_list col_width4">
            <div id="table" className="table">
              <div className="tbody">
                <div className="tr">
                  <div className="th">{intl.get("성명")}</div>
                  <div className="td">
                     <input 
                      type="text" 
                      className="form_control" 
                      value={guest.guest_name} 
                      onChange={(e: any) => onChangeGuestInfoHandler(guestIdx, "guest_name", e.target.value)}
                      placeholder={intl.get("성명을 입력하세요")} />
                  </div>
                  <div className="th">{intl.get("휴대폰 번호")}</div>
                  <div className="td">
                    <PhoneInput
                      type="text"
                      className="form_control"
                      placeholder={intl.get("휴대폰 번호를 입력하세요")}
                      value={guest.guest_cellphone}
                      onChange={(e: any) => onChangeGuestInfoHandler(guestIdx, "guest_cellphone", e.target.value)}
                    />
                  </div>
                </div>
                <div className="tr">
                  <div className="th">{intl.get("성별")}</div>
                  <div className="td">
                    <select name="" id="" className="form_control" value={guest.guest_gender} 
                       onChange={(e: any) => onChangeGuestInfoHandler(guestIdx, "guest_gender", parseInt(e.target.value))}
                    >
                      <option value="">{intl.get("성별을 선택하세요")}</option>
                      <option value="1">{GENDER[1]}</option>
                      <option value="2">{GENDER[2]}</option>
                    </select>
                  </div>
                  <div className="th">{intl.get("신청자와의 관계")}</div>
                  <div className="td">
                    <input type="text" className="form_control"  
                      value={guest.guest_relation} 
                      onChange={(e: any) => onChangeGuestInfoHandler(guestIdx, "guest_relation", e.target.value)}
                      placeholder={intl.get("이용자의 관계를 입력하세요")} />
                  </div>
                </div>
                <div className="tr">
                  <div className="th">{intl.get("차량번호")}</div>
                  <div className="td">
                    <input
                      type="text"
                      className="form_control"
                      value={guest.guest_car_number1}
                      onChange={(e: any) => e.target.value.length < 12 && onChangeGuestInfoHandler(guestIdx, "guest_car_number1", e.target.value)}
                      placeholder={intl.get("차량번호를 입력하세요")}
                    />
                  </div>
                  <div className="td blank"></div>
                  <div className="td blank"></div>
                </div>
              </div>
            </div>
          </div> {/* table_design_list */}
        </>
      ))}

      <div className="submit">
        {/*<button className="btn btn_lg btn_outline">취소</button>*/}
        <ConfirmBtn
          className="btn btn_lg btn_outline"
          action={() => {store.roomInit(); props.history.replace('/home');}}
          label={intl.get("취소")}
          title=""
          msg={intl.get("이용 예약을 취소하시겠습니까?")}
        />
        <button className="btn btn_lg btn_darkblue" onClick={onNextHandler}>{intl.get("다음")}</button>
      </div>
      {userInfo.worksite == 2 ? (<div>
        <ul className="lst_help">
          <li>지원 대상 : 남양기숙사 입숙자(단, 한시입숙자 및 만기자 제외)</li>
          <li>입숙 대상 : 부모님 포함 4인까지(부,모 중 한분은 필수 참석)</li> 
          <li>운영 기간 : 매월 1일~말일 </li>
          <li>입숙 요일 : 최대 2박3일(1박2일 가능, 단 분할 사용 불가), 주 2회 운영(화수목 또는 금토일)  
          <br />※  마지막 주의 요일이 금,토를 포함할 경우, 그 주 까지 신청 가능
          <br />(예시 1) 12월은 31일까지로 12/29(금)~31(일) or  12/29(금)~30(토) or 12/30(토)~12/31(일) 가능 /  12/31(일)~1/2(화) 신청 불가         
          </li>  
          <li>입/퇴숙 : 입실 14시, 퇴실 11시</li> 
          <li>신청 및 선정 안내 :  신청 기간 : 1일~15일,  선정 안내일 : 20일 / 잔여분 신청일 : 22일 , 선정 안내일 : 25일 (선정 안내 및 잔여분 신청일 주말/공휴일인 경우 → 첫째 업무일 진행)</li> 
          <li>선정 안내 방법 : 메일, 문자메세지</li>  
        </ul>
      </div>) : <div></div>}
    </>
  )
});

export default Step1;
