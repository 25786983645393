import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;
const PORT = (config as any)[MODE].PORT;

interface loginPayload {
  employee_number: string;
  password: string;
}

interface snsLoginPayload {
  username: string;
  sns: {
    key: string;
    platform: string;
  };
}

class Auth {
  static URL = `${IP}`;

  static async login(payload: loginPayload) {
    //console.log(`${Auth.URL}`);
    const PATH = '/member/auth/signin/';
    //console.log(payload);
    return await axios.post(
      `${Auth.URL}${PATH}`,
      {},
      {
        auth: {
          username: payload.employee_number,
          password: payload.password,
        },
      },
    );
  }

  @apiHandler()
  static async snsLogin(payload: snsLoginPayload) {
    const PATH = '/auth/signin/sns/';
    return await axios.post(`${Auth.URL}${PATH}`, payload);
  }

  @apiHandler()
  static async snsIntegrate(payload: snsLoginPayload) {
    const PATH = '/auth/profile/';
    return await axios.put(`${Auth.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 사용자 사번 조회
  @apiHandler()
  static async signupCheckUser(payload: any) {
    const PATH = '/member/auth/signup/check/user/';
    return await axios.post(`${Auth.URL}${PATH}`, payload);
  }

  // 인증요청(문자 or 이메일) 발송
  @apiHandler()
  static async signupAuth(payload: any) {
    const PATH = '/member/auth/signup/auth/';
    return await axios.post(`${Auth.URL}${PATH}`, payload);
  }

  // 인증확인
  @apiHandler()
  static async signupVerify(payload: any) {
    const PATH = '/member/auth/signup/verify/';
    // console.log(document.cookie);
    return await axios.post(`${Auth.URL}${PATH}`,
      payload,
    );
  }

  @apiHandler()
  static async signup(payload: {
    employee_number: string,
    password: string,
    password2: string,
    cellphone: string,
    email: string,
    is_optional_collection_agreed: boolean
  }) {
    const PATH = '/member/auth/signup/';
    return await axios.post(`${Auth.URL}${PATH}`, payload);
  }

  @apiHandler()
  static async getProfile(payload: any) {
    const PATH = '/member/auth/profile/';
    return await axios.get(`${Auth.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async isSignedUser(employeeNumber: string) {
    const PATH = `/member/auth/profile/${employeeNumber}/`;
    return await axios.get(`${Auth.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async logout(payload: any) {
    const PATH = '/member/auth/signout/';
    return await axios.post(
      `${Auth.URL}${PATH}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      },
    );
  }

  // 아이디 찾기 인증하기 버튼 클릭
  @apiHandler()
  static async findIdAuth(payload: {cellphone: string, name: string}) {
    const PATH = '/member/auth/find/id/auth/';
    return await axios.post(
      `${Auth.URL}${PATH}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      },
    );
  }

  // 인증번호 확인후 아이디 받기
  @apiHandler()
  static async findIdVerify(payload: { token: string, name: string, cellphone: string, number: string }) {
    const PATH = '/member/auth/find/id/verify/'
    return await axios.post(
      `${Auth.URL}${PATH}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      },
    );
  }

  // 비밀번호 찾기 - 비밀번호 찾기 인증하기 버튼 클릭
  @apiHandler()
  static async findPasswordAuth(payload: {employee_number: string, cellphone: string}) {
    const PATH = '/member/auth/find/password/auth/'
    return await axios.post(
      `${Auth.URL}${PATH}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      },
    );
  }

  // 비밀번호 찾기 - 인증번호 확인후 아이디 받기
  @apiHandler()
  static async findPasswordVerify(payload: { token: string, employee_number: string, cellphone: string, number: string }) {
    const PATH = '/member/auth/find/password/verify/'
    return await axios.post(
      `${Auth.URL}${PATH}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      },
    );
  }

  // 비밀번호 찾기 - 비밀번호 변경
  @apiHandler()
  static async findPasswordChange(payload: { token: string, employee_number: string, cellphone: string, password: string, password2: string }) {
    const PATH = '/member/auth/find/password/change/'
    return await axios.post(
      `${Auth.URL}${PATH}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      },
    );
  }

  // 마이 페이지 - 인증번호 보내기
  @apiHandler()
  static async profileAuth(payload: { type: number }) {
    const PATH = '/member/auth/profile/auth/'
    return await axios.post(
      `${Auth.URL}${PATH}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      },
    );
  }
  // 마이 페이지 - 인증하기
  @apiHandler()
  static async profileVerify(payload: { token: string, number: string }) {
    const PATH = '/member/auth/profile/verify/'
    return await axios.post(
      `${Auth.URL}${PATH}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      },
    );
  }

  // 마이 페이지 - 회원정보 수정 (현재 비밀번호 추가되어야함)
  @apiHandler()
  static async profileUpdate(payload: { old_password: string, new_password: string, new_password2: string }) {
    const PATH = '/member/auth/profile/password/'
    return await axios.patch(
      `${Auth.URL}${PATH}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      },
    );
  }
  
  // 마이 페이지 - 회원탈퇴
  @apiHandler()
  static async profileDelete(payload: { password: string, category: string, description: string }) {
    const PATH = '/member/auth/profile/withdraw/'
    return await axios.post(
      `${Auth.URL}${PATH}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      },
    );
  }

  // 1:1 이숙 신청 페이지 -> 이용자 사번으로 정보 가져오기
  @apiHandler()
  static async getProfileDetail(payload: any) {
    const PATH = `/member/lodge/moveemployee/`;

    return await axios.get(`${Auth.URL}${PATH}`, {
    params: payload,
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
    },
    });
  }
}


export default Auth;
