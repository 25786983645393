import BusinessSingle from './businessSingle';
import BusinessGroup from './businessGroup';

import VipSingleNav from './vipSingle';
import DomitorySingleNav from './dormitorySingle';
import FamilyHouseNav from './familyHouse';
import CompanyHouseSingleNav from './companyHouseSingle';

export default {
  BusinessSingleNav: BusinessSingle,
  BusinessGroupNav: BusinessGroup,
  VipSingleNav: VipSingleNav,
  DomitorySingleNav: DomitorySingleNav,
  FamilyHouseNav: FamilyHouseNav,
  CompanyHouseSingleNav: CompanyHouseSingleNav
};