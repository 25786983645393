import React from 'react';
import intl from 'react-intl-universal';

interface Props {
  floors: [Floor]
  onChangeFloor: Function
  isShowAllOption?: boolean
  value?: number
}

interface Floor {
  id: number
  name: string | number
  layout_image: string
}

//층 select
const SelectFloor = (props: Props) => {
  return (
    <div className="col col_floor">
      <label htmlFor="select_floor" className="title_form">
        {intl.get("층 수")}
      </label>
      <select
        name=""
        id="select_floor"
        className="form_control"
        value={props.value}
        onChange={e => props.onChangeFloor(parseInt(e.target.value))}
      >
        {props.isShowAllOption
          ? (
            <option value="-1">{intl.get("전체")}</option>
          ) : (
            <></>
          )}
        
        {props.floors.map((floor: Floor, floorIdx: number) => (
          <option value={floorIdx} label={`${floor.name} 층`} />
        ))}
      </select>
    </div>
  );
};

export default SelectFloor;
