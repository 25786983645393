import React, { useState, useEffect, useContext } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { MessageContext } from '../../routes';

import ConfirmBtn from '../../components/atoms/button/confirm';
import DetaultTemplate from '../../components/templetes/listDefault';

import { GENDER, LODGE_STATUS_CODE, MEMBER_DEL_CATEGORY, ROOM_TYPE, ROOM_TYPE_CODE, STATUS_CODE } from '../../constant';
import { getTodayDate, getRangeDates } from '../../utils/date';
import { formatByString_YYYYMMDD } from '../../utils/date';
import { passwordValid } from '../../utils/validation';

import { auth } from '../../apis';

import { ERROR_CODE } from '../../constant/error';
import { VERIFY_CODE } from '../../constant';

import { lodge } from '../../apis';

import './index.scss';
import fi from 'date-fns/esm/locale/fi/index.js';
import { SHOW_MODAL } from '../../store/modules/modal';

interface Lodged {
  totalDate: number;
  useDate: number;
  remainDate: number;
}

const UserInfoManagementPage = (props: any) => {
  const { info } = useContext(MessageContext);
  const { userInfo = {}, workplaceMap = {} } = useSelector((state: any) => ({
    userInfo: state.user,
    workplaceMap: state.base.workplaceMap,
  }));

  const [lodgedGuests, setLodgedGuests] = useState<any[]>([]);

  const [isDeletePop, setIsDeletePop] = useState<boolean>(false);
  const [isUpdatePop, setIsUpdatePop] = useState<boolean>(false);
  const [isDelDisabled, setIsDelDisabled] = useState<boolean>(true);

  const [oldpassword, setOldPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");
  const [category, setCategory] = useState<string>("1");
  const [description, setDescription] = useState<string>("");
  const [delPassword, setDelPassword] = useState<string>("");

  const dispatch = useDispatch();
  const [offset, setOffset] = useState<number>(100);
  const [limit, setLimit] = useState<number>(100);
  
  
  useEffect(() => {
    (async () => {
      const payload = {
        status: STATUS_CODE.checkin_completed,
      };

      let data: any = await fetchData(props.offset, props.limit);
      // const { data: guest }: any = await lodge.getGuest(payload);
      // console.log('guest==>' + guest);
      //  console.log('userInfo==>' , userInfo);
       
       if (data.results) {
         let newData: any[] = [];
         let count: number = 0;

         //let list = data.results.filter((item: any) => item.status === LODGE_STATUS_CODE.checkin && (item.room_type === 2 || item.room_type === 3));
         data.results.map((item: any) => {
              if(item.status === LODGE_STATUS_CODE.checkin && (item.room_type === ROOM_TYPE_CODE.dormitory_room || item.room_type === ROOM_TYPE_CODE.company_room)){
                newData.push({...item});
              }else if(item.status === LODGE_STATUS_CODE.checkin && (item.room_type !== ROOM_TYPE_CODE.dormitory_room || item.room_type !== ROOM_TYPE_CODE.company_room) && count===0) {
                count ++;
                newData.push({...item});
              }
         });

         setLodgedGuests(newData);
        //  console.log('guest==>' ,lodgedGuests);
       }  
    })();
  }, []);

  const init = () => {
    setOldPassword("");
    setPassword("");
    setPassword2("");
    setDelPassword("");
    setDescription("");
    setCategory("1");
  }

  const fetchData = async (offset: number=0, limit: number=10) => {
    const payload: any = {
      offset,
      limit,
      status: [LODGE_STATUS_CODE.checkin, LODGE_STATUS_CODE.checkout].join(',')
    };

    if (props.roomType) {
      payload.room_type = props.roomType
    }

    if (props?.searchOption?.option) {
      payload[props?.searchOption?.option] = props?.searchOption?.text
    }

    try {
      const { data }: any = await lodge.getGuest(payload);
      return data
    }catch ({ status, data }) {
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  }


  function isLodge(guest: any): Lodged {
    const today = getTodayDate();

    if(!guest.expired_at) {
      return {
        totalDate : 0,
        remainDate : 0,
        useDate : 0,
      };
    }
    try {
      const totalDate: number = getRangeDates(
        guest.checkin_at,
        guest.expired_at,
      ).length; // 총 기간
      const useDate: number = getRangeDates(guest.checkin_at, today[0]).length; // 사용기간
      const remainDate: number = totalDate - useDate; // 남은기간

      return {
        totalDate,
        remainDate,
        useDate,
      };
    } catch (err) {
      //console.log(err);

      const { status, data } = err;

      if (status === 401) {
        window.location.href = '/force/signout';
        return {
          totalDate: 0,
          remainDate: 0,
          useDate: 0,
        };
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return {
          totalDate: 0,
          remainDate: 0,
          useDate: 0,
        };
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return {
          totalDate: 0,
          remainDate: 0,
          useDate: 0,
        };
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      

      return {
        totalDate: 0,
        remainDate: 0,
        useDate: 0,
      };
    }
  }

  const splitedEmail = userInfo.email && userInfo.email.split('@');
  const emailFront = splitedEmail && splitedEmail.length ? splitedEmail[0] : '';
  const emailBack = splitedEmail && splitedEmail.length ? splitedEmail[1] : '';

  const onChangePwd = async (e : any) => {
    if(oldpassword === '') {
      info('기존 비밀번호를 입력해주세요.');
      return;
    }else if(password === '' || password2 === '') {
      info('변경할 비밀번호를 입력해주세요.');
      return;
    }else if(password !== password2) {
      info('비밀번호가 일치하지 않습니다.');
      return;
    }else if (passwordValid(password, password2)) {
      info("비밀번호 생성 규칙을 확인하여 주세요");
      return
    }
    // else if(oldpassword === password) {
    //   info('기존 비밀번호와 동일합니다.');
    //   return;
    // }

    try {
      let { data } = await auth.profileUpdate({ 
        old_password: oldpassword, 
        new_password: password,
        new_password2: password2 
      });

      init();
      info("비밀번호 변경이 정상적으로 완료되었습니다.");
      setIsUpdatePop(false);
    } catch ({ data, status }) {
      // console.log(data)
      // console.log(status)
      if (status === 400) {
        if (data?.code === "invalid_old_password") {
          info("기존 비밀번호가 일치하지 않습니다. \n 정확한 정보를 입력해주세요.");
          return
        }else if(data?.code === "invalid_new_password") {
          info("비밀번호가 일치하지 않습니다");
          return
        }else if(data?.code === "invalid_new_password2") {
          info("비밀번호가 일치하지 않습니다");
          return
        }
      }else  if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info("비밀번호가 올바르지 않습니다.");
      return;
    }
  }

  const onChangeCategory = (e:any) => {
    setCategory(e.value);
    if(e.value === '5'){
      setIsDelDisabled(false);
    }else {
      setDescription("");
      setIsDelDisabled(true);
    }
  }

  // 회원 탈퇴
  const onProfileDel = async (e: any) => {
  
   if(category === "" || category === null) {
     info("탈퇴사유를 선택해주세요.");
     return
   }else if(category === "5" && (description === "" || description === null)) {
     info("탈퇴사유 ‘기타’ 선택 시, \n 탈퇴사유에 대한 내용을 반드시 적어주세요.");
     return
   }else if(delPassword === "" || delPassword === null) {
     info("비밀번호를 입력해주세요.")
     return
   }

   try {
    let { data } = await auth.profileDelete({
      password: delPassword,
      category: category,
      description: description
    })

    init();
    info("회원 탈퇴가 완료되었습니다. \n 서비스를 이용해 주셔서 감사합니다.");
    //dispatch({ type: LOGOUT_REQUEST, history: props.history });
    auth.logout({history: props.history});
    window.location.href = '/';
  } catch ({ data, status }) {
    // console.log(data)
    // console.log(status)
    if (status === 400) {
      if (data?.code === "invalid_password") {
        info("비밀번호가 일치하지 않습니다. \n 정확한 정보를 입력해주세요.");
        return
      }else  if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else if(data?.code === "invalid_status") {
        info("현재 신청/이용중인 숙소가 있어 탈퇴를 진행할 수 없습니다.");
        return
      }
    }
    //info("비밀번호가 너무 일상적인 단어입니다.");
    (window as any).store.dispatch({
      type: SHOW_MODAL,
      payload: {
        title: '문제발생',
        msg: '문제가 발생했습니다',
        redirectPath: false,
        statusCode: status,
        errorCode: '',
        type: 'error',
        isModalShow: true,
      },
    });
    
    return;
  }
  }

  return (
    <>
    <DetaultTemplate
      id="wrap"
      className="wrap_mypage wrap_join"
      leftInfo={
        <>
            <h2>정보관리</h2>
            <p className="desc">회원가입 정보를 확인하고 수정하실 수 있습니다</p>
            <div className="bg_img info"></div>
        </>
      }
      {...props}
    >
      <div className="title_contents">
        <h3 className="title">My Info</h3>
      </div>
      <div className="table_design_write col_width6">
        <div id="table" className="table">
            <div className="tbody">
                <div className="tr">
                    <div className="th">이름</div>
                    <div className="td">{userInfo.username}</div>
                    <div className="th">성별</div>
                    <div className="td">{userInfo.gender === 1 ? "남" : "여"}</div>
                    <div className="th">생년월일</div>
                    <div className="td">{userInfo.birthdate}</div>
                </div>

                <div className="tr">
                    <div className="th">사번(아이디)</div>
                    <div className="td">{userInfo.employeenumber}</div>
                    <div className="th">휴대폰번호</div>
                    <div className="td">{userInfo.phone}</div>
                    <div className="th">이메일</div>
                    <div className="td">{userInfo.email}</div>
                </div>

                <div className="tr">
                    <div className="th">회사명</div>
                    <div className="td">현대자동차</div>
                    <div className="th">근무지명</div>
                    <div className="td">{workplaceMap[userInfo.worksite]?.name}</div>
                    <div className="th">부서</div>
                    <div className="td">{userInfo.department}</div>
                </div>

                <div className="tr">
                    <div className="th">직위/직책</div>
                    <div className="td">
                        <ul className="desc_area">
                            <li>{userInfo.rank}</li>
                            <li>{userInfo.position}</li>
                        </ul>
                    </div>
                    {/*<div className="th">직종/근무조</div>
                    <div className="td">{userInfo.party}</div>*/}
                    <div className="th">직군</div>
                    <div className="td">{userInfo.occupation}</div>
                    <div className="th">결혼유무</div>
                    <div className="td">{userInfo.isMarried ? "유" : "무"}</div>
                </div>

                <div className="tr">
                    <div className="th">주민등록지 주소</div>
                    <div className="td">{`(${userInfo.zipcode}) ${userInfo.address}`}</div>
                    <div className="th">거주지 주소</div>
                    <div className="td">{`(${userInfo.zipcode2}) ${userInfo.address2}`}</div>
                    <div className="th">당사입사일</div>
                    <div className="td">{userInfo.joined_at && formatByString_YYYYMMDD(userInfo.joined_at)}</div>
                </div>

                <div className="tr">
                    <div className="th">근속년</div>
                    <div className="td">{`${userInfo.working_period}년`}</div>
                    <div className="th blank"></div>
                    <div className="td blank"></div>
                    <div className="th blank"></div>
                    <div className="td blank"></div>
                </div>
                
            </div>
        </div>
    </div> {/* table_design_list */}

      <ul className="lst_help mt20">
        <li>
            기본정보는 인사DB 정보를 연동하여 해당 정보를 표시하기 때문에 수정이 불가합니다. 숙소 이용신청 시 위 기본정보가 반영됩니다. 
            만약 본인 정보와 상이한 정보가 있다면 인사DB 정보를 변경하시면 기본정보도 자동변경 됩니다. 반드시 숙소 이용신청 전에 해당 정보 변경을 완료하시어 인사상 불이익이 발생되지 않도록 조치하여 주십시오
        </li>
      </ul>

      <div className="submit">
          <div className="fr">
              <button className="btn btn_lg btn_outline" onClick={(e) => setIsUpdatePop(true)}>비밀번호 변경</button>
              <button className="btn btn_lg btn_darkblue" onClick={(e) => setIsDeletePop(true)}>회원탈퇴</button>
          </div>
      </div>

      {/*<div className="title_contents">
        <h3 className="title">추가정보</h3>
        <div className="right_area">
          <ConfirmBtn
            className="btn_sm btn_darkblue"
            action={() => props.history.push('/mypage/user/update')}
            label="정보수정"
            title=""
            msg="정보수정 페이지로 이동하시겠습니까?"
          />
        </div>
      </div>
      <div className="form_write clearfix">
        <div className="row">
          <div className="col">
            <label htmlFor="input_phonenumber" className="title_form">휴대폰 번호</label>
            <div className="">
              <input type="text" id="input_phonenumber" className="form_control" placeholder="" value={userInfo.phone} disabled />
            </div>
          </div>
        </div>

        <div className="row">
          <label htmlFor="input_email" className="title_form">이메일</label>
          <p className="form_email">
            <input type="text" id="input_email" className="form_control" placeholder="" value={emailFront} disabled />
            <span className="dash">@</span>
            <input type="text" className="form_control" placeholder="" value={emailBack} disabled />
          </p>
        </div>
      </div>*/}

    {lodgedGuests.length > 0 ? lodgedGuests.map((lodgeGuest: any, lodgeGuestIdx: number) => (
        <React.Fragment key={lodgeGuestIdx}>
            <>
              <div className="title_contents">
                <h3 className="title">숙소이용현황</h3>
                {lodgeGuest.booking.room_work_site !== 1 && lodgeGuest.booking.room_work_site !== 4 ? 
                (<>
                  {lodgeGuest && isLodge(lodgeGuest).totalDate !== 0
                    ? (<div className="message">총 <strong> {isLodge(lodgeGuest).totalDate}일 중 </strong>
                        <strong>{isLodge(lodgeGuest).useDate}일</strong> 
                        <span className="user_name">사용중</span>이며 
                        <strong>{isLodge(lodgeGuest).remainDate}일</strong> 남았습니다.
                        </div>)
                    : ''}
                 </>
                ): ''}
              </div>
              <ul className="lst_status">
                 <li><strong>숙소유형</strong><span>{ROOM_TYPE[lodgeGuest.room_type]}</span></li>
                 <li><strong>이용사업장</strong><span>{lodgeGuest.room_work_site}</span></li>
                 <li><strong>입숙일</strong><span>{lodgeGuest.checkin_at}</span></li>
                 {lodgeGuest.booking.room_work_site !== 1 && lodgeGuest.booking.room_work_site !== 4 ?  (<li><strong>입숙만료일</strong><span>{lodgeGuest.expired_at}</span></li>) 
                 : (<li><strong>입숙만료일</strong><span></span></li>)}
                 <li><strong>배방</strong><span>{lodgeGuest.room_building_name} {lodgeGuest.room_building_dong} {lodgeGuest.room_name}</span></li>
              </ul>
            </>
        </React.Fragment>
      )) : <React.Fragment>
            <div className="title_contents">
              <h3 className="title">숙소이용현황</h3>
            </div>
            <ul className="lst_status">
              <li className="disabled">이용중인 숙소가 없습니다.</li>
            </ul> 
          </React.Fragment>}
      <div id="pop_confirm_pw" className="pop_dim_wrap pop_modal pop_sm"
        style={{ display: isUpdatePop ? 'block' : 'none' }}
      >
        <div className="pop_wrap">
            <div className="title_wrap">
              <h3>비밀번호 확인</h3>
              <button className="pop_btn_close pop_close" onClick={(e) => {init(); setIsUpdatePop(false)}}>닫기</button>
            </div>
            <div className="contents_wrap">
              <div className="form_write form_lg">
                  <div className="row">
                      <div className="col w100">
                        <label htmlFor="input_existing_pw" className="title_form">기존 비밀번호 확인</label>
                        {/* <div className="form_password"> */}
                            <input 
                              type="password" 
                              id="input_existing_pw" 
                              className="form_control" 
                              placeholder="기존 비밀번호를 입력해주세요"
                              value={oldpassword}
                              onChange={(e: any) => setOldPassword(e.target.value)}
                            />
                            {/* <button className="btn btn_sm btn_gray" onClick={onOriginPwd}>완료</button> */}
                            {/*<button className="btn btn_lg btn_gray">재전송</button>*/}
                        {/* </div> */}
                      </div>
                  </div>

                  <ul className="lst_warning">
                    <li>정보변경을 위해 비밀번호를 입력하여 본인임을 확인하여 주세요.</li>
                  </ul>

                  <div className="row">
                    <div className="col w100">
                      <label htmlFor="input_confirm_pw" className="title_form">변경할 비밀번호</label>
                      <input 
                        type="password" 
                        id="input_confirm_pw" 
                        className="form_control" 
                        placeholder="변경할 비밀번호를 입력해주세요"
                        value={password}
                        onChange={(e: any) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>

                  <ul className="lst_warning">
                    <li>비밀번호는 8~20자로 영문, 특수문자, 숫자를 조합하여 입력해주세요.영문 대문자와 소문자를 구별해주세요</li>
                  </ul>

                  <div className="row">
                    <div className="col w100">
                      <label htmlFor="input_confirm_pw2" className="title_form">변경할 비밀번호 확인</label>
                      <input 
                        type="password" 
                        id="input_confirm_pw2" 
                        className="form_control" 
                        placeholder="변경할 비밀번호를 다시 한번 입력해주세요"
                        value={password2}
                        onChange={(e: any) => setPassword2(e.target.value)}
                      />
                    </div>
                  </div>
              </div>
            </div>

            <div className="button_wrap">
              <ConfirmBtn
                action={() => {init(); setIsUpdatePop(false)}}
                label="취소"
                title=""
                msg="비밀번호 변경을 취소하시겠습니까?"
                className="btn btn_lg btn_outline w50"
              />
              <button type="button" className="btn btn_lg btn_darkblue w50"
                onClick={onChangePwd}
              >완료</button>
            </div>
        </div>
      </div>

      <div id="pop_out_member" className="pop_dim_wrap pop_modal pop_sm"
        style={{ display: isDeletePop ? 'block' : 'none' }}
      >
        <div className="pop_wrap">
          <div className="title_wrap">
            <h3>정말로 탈퇴하시겠습니까?</h3>
            <button className="pop_btn_close pop_close" onClick={(e) => {init(); setIsDeletePop(false)}}>닫기</button>
          </div>
          <div className="contents_wrap">
            <div className="form_write form_lg">
              <div className="row">
                <div className="col w100">
                  <label htmlFor="select_reason" className="title_form">탈퇴 사유 선택</label>
                  <select name="" id="select_reason" className="form_control" value={category} onChange={(e: any) => onChangeCategory(e.target)}>
                      {Object.keys(MEMBER_DEL_CATEGORY).map((key: string, index: number) => (
                          <option key={index} value={key}>
                              {MEMBER_DEL_CATEGORY[Number(key)]}
                          </option>
                      ))}
                  </select>
                  <textarea name="" id="description" className="form_control" 
                    disabled={isDelDisabled} value={description}
                    onChange={(e: any) => setDescription(e.target.value)}></textarea>
                </div>
              </div>

              <div className="desc_thumb ac">비밀번호 입력 후 탈퇴를 클릭 하시면 <br/> 탈퇴 절차가 마무리됩니다.</div>

              <div className="row">
                  <div className="col w100">
                      <label htmlFor="input_cnumber" className="title_form">사번</label>
                      <input type="text" id="input_cnumber" className="form_control" value={userInfo.employeenumber} placeholder="" disabled/>
                  </div>
              </div>

              <div className="row">
                  <div className="col w100">
                      <label htmlFor="input_pw" className="title_form">비밀번호</label>
                      <input type="password" id="input_pw" className="form_control" 
                        placeholder="비밀번호를 입력해주세요" 
                        value={delPassword} 
                        onChange={(e: any) => setDelPassword(e.target.value)}/>
                  </div>
              </div>

              <ul className="lst_warning">
                  <li>사용하고 계신 아이디를 탈퇴할 경우 재사용 및 복구가 불가능 합니다. 탈퇴한 아이디는 본인과 타인 모두 재사용 및 복구가 불가하니 신중하게 선택하시기 바랍니다.</li>
              </ul>
            </div>
          </div>
          <div className="button_wrap">
            <ConfirmBtn
              action={() => {init(); setIsDeletePop(false)}}
              label="취소"
              title=""
              msg="회원 탈퇴를 취소하시겠습니까?"
              className="btn btn_lg btn_outline w50"
            />
            <button type="button" className="btn btn_lg btn_darkblue w50" onClick={onProfileDel}>탈퇴</button>
          </div>
        </div>
      </div>
    </DetaultTemplate >
    
    </>
  );
};

export default UserInfoManagementPage;
