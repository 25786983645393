import React from 'react';

//성명 input
const Name = (props: any) => {
  const changedProps = {
    ...props,
    onChange: (e: any) => {
      if (e.target.value.length <= 10) {
        props.onChange(e);
      }
    },
  };

  return <input {...changedProps} />;
};

export default Name;
