import React from 'react';


//소속부서 input
const Department = (props: any) => {
  const changedProps = {
    ...props,
    onChange: (e: any) => {
      if (e.target.value.length <= 20) {
        props.onChange(e);
      }
    },
  };

  return <input {...changedProps} />;
};

export default Department;
