import { takeEvery, put, call } from 'redux-saga/effects';

import { INIT_REQUEST, WORKSITE_INIT_REQUEST, WORKSITE_INIT_COMPLETE } from '../modules/base';
import { USERINFO_REQUEST } from '../modules/user';

import { core } from '../../apis';
import { SHOW_MODAL } from '../modules/modal';

function* init({ payload, history }: any) {
  // console.log('[SAGA] API CALL: INIT');
  try {
    yield put({ type: USERINFO_REQUEST, payload, history });
    yield put({ type: WORKSITE_INIT_REQUEST, payload, history });
  } catch (err) {
    // console.log('[SAGA] getUserInfo API error');
    // console.log(err)
  }

}

function* workSiteInit({ payload, history }: any) {
  try {
    const { data } = yield call(core.getWorkSites, {});
    yield put({type: WORKSITE_INIT_COMPLETE, payload: data})
  } catch (err) {
    
    // console.log(err);

    const { status, data } = err;

    if (status === 401) {
      window.location.href = '/force/signout';
      return ;
    } else if (status === 403) {
      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '권한오류',
          msg: '접근권한이 없습니다.',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }else if ( status === 500 ) {
      window.location.href = '/500';
      return;
    }

  }
}

function* baseSaga() {
  yield takeEvery(INIT_REQUEST, init);
  yield takeEvery(WORKSITE_INIT_REQUEST, workSiteInit);
}

export default baseSaga;
