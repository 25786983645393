import { intlFormat } from 'date-fns';
import React from 'react';

import { useSelector } from 'react-redux';

import StepNav from '../../components/molecules/reservationStepNav';

import { GENDER } from '../../constant';
import intl from 'react-intl-universal';

import './step4.scss';

const Step4 = (props: any) => {
  const { workplaceMap = {} } = useSelector((state: any) => ({
    workplaceMap: state.base.workplaceMap
  }));
  return (
    <>
      <dl className="lst_complete">
        <dt>{intl.get("신청이 완료되었습니다")}</dt>
        {Number(props.workSite) === 2? (
          <>
            <dd>
              {intl.get("이용 신청이 접수되었습니다. 관리자가 신청 내용 서류 확인 후 사업장 별로 승인 및 배방이 완료됩니다. 관련 내용은 마이페이지 > 신청 관리 메뉴에서 확인이 가능합니다.")}
              <br />
              {intl.get("코로나 관련하여 개인 위생을 철저히 지켜주시기 바라며, 정부의 행동지침에 따라 주시기 바랍니다. 코로나 의심증상 있을시 연구소 코로나 상황실(031-368-6510)연락주시기 바랍니다.")}
            </dd>
          </>
        ) : (
          <>
            <dd>
            {intl.get("이용 신청이 접수되었습니다.")}
              <br />
              {intl.get("관리자가 신청 내용 서류 확인 후 사업장 별로 승인 및 배방이 완료됩니다.")}
              <br />
              {intl.get("관련 내용은 마이페이지 > 신청 관리 메뉴에서 확인이 가능합니다.")} 
            </dd>
          </>
        )}
      </dl>

      <div className="title_contents">
        <h3 className="title">예약정보</h3>
      </div>

      <dl className="lst_reservation">
        <dt>숙소유형</dt>
        <dd>영빈관</dd>
        <dt>이용사업장</dt>
        <dd>{workplaceMap[props.workSite]?.name}</dd>
        <dt>입숙예정일</dt>
        <dd>{props.dateAfter}</dd>
        <dt>퇴숙예정일</dt>
        <dd>{props.dateBefore}</dd>
      </dl>

      <ul className="lst_userinfo">
        {props.users.map((uesr: any, idx: number) => (
          <React.Fragment key={idx}>
            <li className="user_area">
              <span className="img_user">
                <img src="/static/img/pc/userimg_default.png" alt="" />
              </span>
              <span className="username">이용자 정보</span>
            </li>
            <li>
              <strong>성명</strong>
              <span>{uesr.name}</span>
            </li>
            <li>
              <strong>성별</strong>
              <span>{GENDER[uesr.gender]}</span>
            </li>
            <li>
              <strong>휴대폰 번호</strong>
              <span>{uesr.cellphone}</span>
            </li>
          </React.Fragment>
        ))}
      </ul>

      <ul className="lst_help">
        {Number(props.workSite) === 2? (
          <li>
            {intl.get("이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 영빈관 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.")}
          </li>
        ) :(
          <li>
            이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며
            신청된 입숙일에 해당 사업장의 경비실로 방문하시어 간단한
            인적사항 확인 후 키를 수령 하실 수 있습니다
          </li>
        )}
      </ul>

      <div className="submit submit btn_length_1">
        <button
          className="btn btn_lg btn_darkblue"
          onClick={() => props.history.push('/mypage/applicant')}
        >
          {"신청관리 바로가기"}
        </button>
      </div>
    </>
  );
};

export default Step4;
