import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;

interface File {
  file: number
  document: number
}

class Lodge {
  static URL = `${IP}`;

  // 출장자 숙소 예약 - 개인
  @apiHandler()
  static async reservationByBusinessRoom(payload: any) {
    const PATH = '/member/lodge/business-room-single/book/';
    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 출장자 숙소예약 - 단체
  @apiHandler()
  static async reservationByBusinessRoomGroup(payload: any) {
    const PATH = '/member/lodge/business-room-group/book/';
    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 영빈관 숙소예약 - 개인
  @apiHandler()
  static async reservationByVipRoom(payload: any) {
    const PATH = '/member/lodge/vip-room/book/';
    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
  
  // 사택 숙소예약
  @apiHandler()
  static async reservationByCompanyHouse(payload: any) {
    const PATH = '/member/lodge/company-house/book/';
    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 기숙사 숙소예약 - 개인
  @apiHandler()
  static async reservationByDormitoryRoom(payload: {files: any[], guest: any, work_site: number }) {
    const PATH = '/member/lodge/dormitory/book/'
    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 가족숙소 숙소예약 - 단체
  @apiHandler()
  static async reservationByFamilyRoom(payload: {files: any[], guest: any, work_site: number }) {
    const PATH = '/member/lodge/family-room/book/'
    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 마이페이지 - 신청관리
  @apiHandler()
  static async getBookingsByLodge(payload: any) {
    const PATH = '/member/lodge/booking/';
    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 마이페이지 - 신청관리 - 상세
  @apiHandler()
  static async getBookingsByLodgeDetail(id: number) {
    const PATH = `/member/lodge/booking/${id}/`;
    return await axios.get(`${Lodge.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 마이페이지 - 신청관리 - 출장자 단체배방
  @apiHandler()
  static async assignBackroom(id: number, payload: any) {
    //const PATH = `/member/lodge/booking/${id}/assign/`;
    const PATH = `/member/lodge/business-room-group/${id}/assign/`;
    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 마이페이지 - 신청관리 - 기숙사 배방
  @apiHandler()
  static async assignDormitoryBackroom(bookingId: number, payload: {stay_after: string}) {
    const PATH = `/member/lodge/booking/${bookingId}/schedule/`;
    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
  // 마이페이지 - 신청관리 - 사택 배방
  @apiHandler()
  static async assignCompanyHouseBackroom(bookingId: number, payload: {stay_after: string, file: File}) {
    const PATH = `/member/lodge/booking/${bookingId}/schedule/`;
    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 마이페이지 - 신청관리 취소
  @apiHandler()
  static async cancel(id: number) {
    //const PATH = `/member/lodge/guest/bulk/cancel/?ids=${ids}`;
    const PATH = `/member/lodge/booking/${id}/cancel/`;
    return await axios.post( `${Lodge.URL}${PATH}`, {}, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      },
    );
  }

  // 마이페이지 - 신청관리 상세 - 수정
  @apiHandler()
  static async setBookingsByLodge(id: number, payload: any) {
    const PATH = `/member/lodge/booking/${id}/`;
    return await axios.patch(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }


  // 마이페이지 - 이용안내
  @apiHandler()
  static async getGuest(payload: any) {
    const PATH = '/member/lodge/guest/';
    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      }
    });
  }

  // 마이페이지 - 이용안내
  @apiHandler()
  static async getGuestDetail(guestId: number) {
    const PATH = `/member/lodge/guest/${guestId}/`;
    return await axios.get(`${Lodge.URL}${PATH}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      }
    });
  }
  
  // 마이페이지 - 퇴숙안내
  @apiHandler()
  static async getCheckout(payload: any) {
    const PATH = '/member/lodge/checkout/';
    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      }
    });
  }

  // 신청관리 - 단체예약 배방오픈 정보 조회
  @apiHandler()
  static async getBusinessGroupReservation(payload: {booking: number}) {
    const PATH = '/member/lodge/guest-group/';
    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      }
    });
  }

  @apiHandler()
  static async lodgeBookingPatch(bookingId: number, payload: { files?: number[], guests?: number[] }) {
    const PATH = `/member/lodge/booking/${bookingId}/`;

    return await axios.patch(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async lodgeReapply(bookingId:number, payload: {}) {

    const PATH = `/member/lodge/booking/${bookingId}/reapply/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 사택 - 입숙 예정일 입력
  @apiHandler()
  static async lodgeSchedule(bookingId:number, payload: {stay_after: string}) {
    const PATH = `/member/lodge/company-house/${bookingId}/schedule/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 사택 - 입숙 보류
  @apiHandler()
  static async lodgeScheduleDelay(bookingId:number) {
    const PATH = `/member/lodge/booking/${bookingId}/delay/`;

    return await axios.post(`${Lodge.URL}${PATH}`, {}, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 기숙사 - 입숙 예정일 입력
  @apiHandler()
  static async lodgeScheduleDormitory(bookingId:number, payload: {stay_after: string}) {
    const PATH = `/member/lodge/dormitory/${bookingId}/schedule/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 사택 - 증빙서류 업로드
  @apiHandler()
  static async lodgeDocumentUpload(bookingId:number, payload: any) {
    const PATH = `/member/lodge/company-house/${bookingId}/submit/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 사택 - 퇴숙신청
  @apiHandler()
  static async checkoutCompanyApply(payload: any) {
    const PATH = `/member/lodge/company-house/checkout/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

   // 기숙사 - 퇴숙신청
   @apiHandler()
   static async checkoutDormitoryApply(payload: any) {
     const PATH = `/member/lodge/dormitory/checkout/`;
 
     return await axios.post(`${Lodge.URL}${PATH}`, payload, {
       headers: {
         Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
       },
     });
   }

   // 기숙사 - 퇴숙신청삭제
   @apiHandler()
   static async checkoutDeleteApply(id: number) {
     const PATH = `/member/lodge/checkout/${id}/`;
 
     return await axios.delete(`${Lodge.URL}${PATH}`,{
       headers: {
         Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
       },
     });
   }

   // 이숙 신청 리스트 조회
   @apiHandler()
   static async lodgeMoveList(payload: any) {
     const PATH = `/member/lodge/move/`;
 
     return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
     });
   }

   // 1:1 이숙 신청 리스트 조회
   @apiHandler()
   static async lodgeOneToOneMoveList(payload: any) {
     const PATH = `/member/lodge/onetoonelist/`;
 
     return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
     });
   }

    // 이숙 신청 리스트 조회 (id로 조회)
    @apiHandler()
    static async getMoveDetail(guestId: number) {
      const PATH = `/member/lodge/move/${guestId}/`;
  
      return await axios.get(`${Lodge.URL}${PATH}`, {
       headers: {
         Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
       },
      });
    }

    // 1:1 이숙 신청 상세 조회 (id로 조회)
    @apiHandler()
    static async getOneToOneMoveDetail(guestId: number) {
      const PATH = `/member/lodge/onetoonedetail/${guestId}/`;
  
      return await axios.get(`${Lodge.URL}${PATH}`, {
       headers: {
         Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
       },
      });
    }
   
   // 이숙 신청
   @apiHandler()
   static async lodgeMoveApply(payload: any) {
     const PATH = `/member/lodge/move/`;
 
     return await axios.post(`${Lodge.URL}${PATH}`, payload, {
       headers: {
         Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
       },
     });
   }

   // 이숙 수정
   @apiHandler()
   static async lodgeMoveApplyUpdate(id: number, payload: any) {
     const PATH = `/member/lodge/move/${id}/`;
 
     return await axios.patch(`${Lodge.URL}${PATH}`, payload, {
       headers: {
         Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
       },
     });
   }

   // 이숙 신청 취소
   @apiHandler()
   static async lodgeMoveApplyDelete(id: number) {
     const PATH = `/member/lodge/move/${id}/cancel/`;
 
     return await axios.post(`${Lodge.URL}${PATH}`, {}, {
       headers: {
         Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
       },
     });
   }

   // 1:1 이숙 수정
   @apiHandler()
   static async lodgeMoveOneApplyUpdate(id: number, payload: any) {
     const PATH = `/member/lodge/onetoonedetail/${id}/`;
 
     return await axios.put(`${Lodge.URL}${PATH}`, payload, {
       headers: {
         Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
       },
     });
   }

   // 1:1 이숙 status 변경
   @apiHandler()
   static async lodgeMoveChangeStatus(id:number, payload: any) {
     const PATH = `/member/lodge/onetoonelist/${id}/`;
 
     return await axios.put(`${Lodge.URL}${PATH}`, payload, {
       headers: {
         Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
       },
     });
   }

   // 1:1 이숙 응답자 승인
   @apiHandler()
   static async lodgeMoveCheck(id: number) {
     const PATH = `/member/lodge/move/${id}/check/`;
 
     return await axios.post(`${Lodge.URL}${PATH}`, {}, {
       headers: {
         Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
       },
     });
   }

  // 1:1 이숙 응답자 승인거절
  @apiHandler()
  static async lodgeMoveReject(id: number) {
    const PATH = `/member/lodge/move/${id}/reject/`;

    return await axios.post(`${Lodge.URL}${PATH}`, {}, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async checkoutApply(
    checkoutId: number,
    payload: {
      stay_before: string,
      checkout_checklist: string,
      checkout_reason: string,
      checkout_reason_detail: string,
      checkout_memo: string,
      files: {
        document: number,
        file: number
      }
    }) {
    const PATH = `/member/lodge/checkout/${checkoutId}/apply/`;

    return await axios.post(`${Lodge.URL}${PATH}`, payload, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  //퇴숙 신청 리스트 -> 상세보기 넘어갈때 해당 row의 id로 조회
  @apiHandler()
    static async getCheckOutDetail(guestId: number) {
      const PATH = `/member/lodge/checkout/${guestId}/`;
  
      return await axios.get(`${Lodge.URL}${PATH}`, {
       headers: {
         Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
       },
      });
    }

    //퇴숙 신청 버튼
    @apiHandler()
    static async getCheckOutModify(guestId: number, payload: any) {
      const PATH = `/member/lodge/checkout/${guestId}/`;
  
      return await axios.patch(`${Lodge.URL}${PATH}`, payload, {
       headers: {
         Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
       },
      });
    }
    
    // 정기이숙 가능 조회
    @apiHandler()
     static async getFixedList(payload: any) {
       const PATH = `/checkin/movesetlist/`;
   
       return await axios.get(`${Lodge.URL}${PATH}`, {
        params: payload,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
       });
    }

    // 입숙보류, 이숙 1년내 체크로직
    @apiHandler()
    static async lodgeDelayCount(employee_number: number, status: number, payload: any) {
      const PATH = `/member/lodge/delay/?employee_number=${employee_number}&status=${status}`;
  
      return await axios.post(`${Lodge.URL}${PATH}`, payload, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      });
    }

    // 이숙 1년내 데이터 있는지 없는지
    @apiHandler()
    static async lodgeMoveCount(employee_number: number, status: number) {
      const PATH = `/member/lodge/delay/?status=${status}&classify=${employee_number}`;
  
      return await axios.get(`${Lodge.URL}${PATH}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      });
    }

    // sms 발송 
    // cellphone : "01097552985,01034568765"
    // message : "이것은 메시지 발송테스트입니다."
    @apiHandler()
    static async sendSMS(payload: any) {
      const PATH = `/member/lodge/sendsms/`;
  
      return await axios.post(`${Lodge.URL}${PATH}`, payload, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
      });
    }

  // 마이페이지 - 이용안내
  @apiHandler()
  static async getDeductDetail(payload: any) {
    const PATH = `/deduct/memberdeduct/`;
    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      }
    });
  }

  // 가족숙소 신청 이력 조회
  @apiHandler()
  static async getFamilyRoomHistory(payload: any) {
    const PATH = '/member/lodge/familyroomHistory/';
    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 가족숙소 잔여분 신청가능일 조회
  @apiHandler()
  static async getRemainRoom(payload: any) {
    const PATH = '/member/lodge/RemainRoom/';
    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  // 가족숙소 패널티 조회
  @apiHandler()
  static async getPenalty(payload: any) {
    const PATH = '/member/lodge/Penalty/';
    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async getPenaltyNoReply(payload: any) {
    const PATH = '/member/lodge/PenaltyNoReply/';
    return await axios.get(`${Lodge.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
}

export default Lodge;
