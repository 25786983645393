import { observer } from 'mobx-react';
import React, { useState, useEffect, useContext } from 'react';
import { lodge } from '../../apis';
import ConfirmBtn from '../../components/atoms/button/confirm';
import CustomCalendar from '../../components/organismas/customCalendar';
import DatePicker from '../../components/organismas/datePicker';
import DefaultTemplete from '../../components/templetes/default';
import { LODGE_MOVE_CODE, ROOM_TYPE, WORK_SITE_CODE } from '../../constant';
import { MessageContext } from '../../routes';
import { useStores } from '../../store/mobx';
import { SHOW_MODAL } from '../../store/modules/modal';
import { getWorkSitePhoneNum } from '../../utils/commons';
import { getPrevDay, getTodayDate } from '../../utils/date';

const LodgeMoveApply = observer((props: any) => {
  const {lodgedStore: store} = useStores();
  const { info } = useContext(MessageContext);
  const [category, setCategory] = useState<number>(props.history.location.state.category);
  const [isPartyCheck, setIsPartyCheck] = useState<boolean>(props.history.location.state.guest && (props.history.location.state.guest.guest_party !== props.history.location.state.party)? false : true);
  const [party, setParty] = useState<string>(props.history.location.state.party? props.history.location.state.party : props.history.location.state.guest_party);
  const [claim, setClaim] = useState<string>(props.history.location.state.claim ? props.history.location.state.claim : '');
  const [desiredRoom, setDesiredRoom] = useState<string>(props.history.location.state.desired_room ? props.history.location.state.desired_room : '');
  const [isEquimentOk, setIsEquimentOk] = useState<boolean>(props.history.location.state.is_equipment_ok ? props.history.location.state.is_equipment_ok : false);
  const [equipmentDesc, setEquipmentDesc] = useState<string>(props.history.location.state.equipment_description ? props.history.location.state.equipment_description : '');
  const [moveAt, setMoveAt] = useState<string>(props.history.location.state.moved_at ? props.history.location.state.moved_at : getTodayDate()[0]);
  const [isImgPop, setIsImgPop] = useState<boolean>(false);
  const [imgPath, setImgPath] = useState<string>('');

  useEffect(() => {
    (async () => {
      store._category = props.history.location.state.category;
    })();
  }, []);
  
  const onSubmitHandler = async() => {
    if(!party || !moveAt || !desiredRoom) {
      info("필수입력 항목을 확인해주세요");
      return false;
    }

    if(isEquimentOk && !equipmentDesc) {
      info("이상 품목을 입력해주세요");
      return false;
    }

    if(props.history.location.state.moveId){    // 수정
      let payload = {
        party: party,
        claim: claim,
        desired_room: desiredRoom,
        is_equipment_ok: isEquimentOk,
        moved_at: moveAt,
        equipment_description: equipmentDesc
      }
  
      try {
        let { data } = await lodge.lodgeMoveApplyUpdate(props.history.location.state.moveId, payload);
        info('이숙신청이 수정되었습니다');
        props.history.push('/mypage/move');
      } catch (error) {
        //console.log(error);

        const {status, data} = error;

        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
  
        info("이숙신청 중 문제가 발생했습니다.")
        return;
      }
    }else {   // 등록
      let payload = {
        guest: props.history.location.state.id,
        category: category,
        party: party,
        claim: claim,
        desired_room: desiredRoom,
        is_equipment_ok: isEquimentOk,
        moved_at: moveAt,
        equipment_description: equipmentDesc
      }
  
      try {
        let { data } = await lodge.lodgeMoveApply(
          payload
        )
        info('이숙신청이 완료되었습니다');

        if(category === LODGE_MOVE_CODE.alway) {
          // 문자 발송 - 상시
          let equi = '없음';
          if(isEquimentOk){equi = '있음';}
          let smsPayload: any = {
            cellphone : props.history.location.state.guest_cellphone,
            message : '이숙신청이 되었습니다. \n이숙요청일 : '+moveAt+' \n이숙 요청 세대 : '+desiredRoom+'\n비품이상유무 : '+ equi 
                      +'\n신청진행 확인은 "마이페이지>>이숙관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 '+getWorkSitePhoneNum(WORK_SITE_CODE[props.history.location.state.room_work_site], props.history.location.state.room_type)
          }
          const { data: sms } = await lodge.sendSMS(smsPayload);
        }else if(category === LODGE_MOVE_CODE.fixed) {
          // 문자 발송 - 정기
          let equi = '없음';
          if(isEquimentOk){equi = '있음';}
          let smsPayload: any = {
            cellphone : props.history.location.state.guest_cellphone,
            message : '이숙신청이 되었습니다. \n신청자 : '+ props.history.location.state.guest_name 
                      +'\n신청자 호실 : '+ props.history.location.state.room_building_name + ' ' +props.history.location.state.room_building_dong + ' ' +props.history.location.state.room_name +'호'
                      +'\n이숙요청일 : '+moveAt+' \n실제 근무조 : '+party+'\n요청사항 : '+ claim 
                      +'\n신청진행 확인은 "마이페이지>>이숙관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 '+getWorkSitePhoneNum(WORK_SITE_CODE[props.history.location.state.room_work_site], props.history.location.state.room_type)
          }
          const { data: sms } = await lodge.sendSMS(smsPayload);
        }

        props.history.push('/mypage/move');
      } catch (error) {
        //console.log(error);

        const {status, data} = error;

        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
  

        info("이숙신청 중 문제가 발생했습니다.")
        return;
      }
    }
  }

  return (
    <DefaultTemplete
      leftInfo={
        <>
          <h2>이숙신청</h2>
          {props.history.location.state.guest_cellphone}
          <p className="desc">기숙사 이숙신청 및 현황을 확인하실 수 있습니다</p>
          <div className="bg_img change"></div>
        </>
      }
      id="wrap"
      className="wrap_mypage_manage"
      {...props}
    >
      <div className="title_contents">
          <h3 className="title">기본 정보</h3>
      </div>
      <ul className="lst_status">
        {props.history.location.state.moveId? (
          <>
            <li><strong>숙소유형</strong><span>{ROOM_TYPE[props.history.location.state.guest.room_type]}</span></li>
            <li><strong>이용사업장</strong><span>{props.history.location.state.guest.room_work_site}</span></li>
            <li><strong>입숙일</strong><span>{props.history.location.state.guest.checkin_at}</span></li>
            <li><strong>입숙만료일</strong><span>{props.history.location.state.guest.expired_at}</span></li>
            <li><strong>배방</strong><span>
              {props.history.location.state.guest.room_building_name}
              {props.history.location.state.guest.room_building_dong}
              {props.history.location.state.guest.room_name}
            </span></li>
          </>
        ) : (
          <>
            <li><strong>숙소유형</strong><span>{ROOM_TYPE[props.history.location.state.room_type]}</span></li>
            <li><strong>이용사업장</strong><span>{props.history.location.state.room_work_site}</span></li>
            <li><strong>입숙일</strong><span>{props.history.location.state.checkin_at}</span></li>
            <li><strong>입숙만료일</strong><span>{props.history.location.state.expired_at}</span></li>
            <li><strong>배방</strong><span>
              {props.history.location.state.room_building_name}
              {props.history.location.state.room_building_dong}
              {props.history.location.state.room_name}
            </span></li>
          </>
        )}
      </ul>

      <div className="title_contents">
          <h3 className="title">이숙신청 정보입력</h3>
      </div>

      <div className="table_design_write col_width2">
          <div id="table" className="table">
              <div className="tbody">
                  <div className="tr">
                      <div className="th"><span className="">기존근무조</span></div>
                      <div className="td">
                        {props.history.location.state.moveId? (props.history.location.state.guest.guest_party) : (props.history.location.state.guest_party)}
                      </div>
                  </div>
                  <div className="tr">
                      <div className="th"><span className="">실제근무조</span></div>
                      <div className="td">
                          <div className="check_area">
                              <label className="input_radio">
                                  <input type="radio" id="sample_case1" name="sample" checked={isPartyCheck} onChange={() => {
                                      if(props.history.location.state.moveId){
                                        setParty(props.history.location.state.guest.guest_party);
                                      }else{
                                        setParty(props.history.location.state.guest_party);
                                      }
                                      setIsPartyCheck(true);
                                    }} />
                                  <span className="label_text">동일</span>
                              </label>
                              <label className="input_radio">
                                  <input type="radio" id="sample_case2" name="sample" checked={!isPartyCheck} onChange={() => setIsPartyCheck(false)} />
                                  <span className="label_text">다름</span>
                              </label>

                              <select name="" id="" className="form_control form_inline" 
                                value={party} 
                                disabled={isPartyCheck? true: false} 
                                onChange={(e:any) => setParty(e.target.value)}
                              >
                                <option value="A">A조</option>
                                <option value="B">B조</option>
                                <option value="상시주간">상시주간</option>
                              </select>
                          </div>
                          
                      </div>
                  </div>

                  <div className="tr">
                      <div className="th"><span className="">이숙요청일</span></div>
                      <div className="td">
                        {/* <DatePicker
                          value={moveAt}
                          width={250}
                          onChange={(date: string) => setMoveAt(date)}
                          isPrevMonthView={true}
                          isNextMonthNotView={false}
                          min={getPrevDay(getTodayDate()[0])[0]}
                        /> */}
                        <CustomCalendar
                          min={getPrevDay(getTodayDate()[0])[0]}
                          value={moveAt}
                          onChange={(date:string) => {setMoveAt(date)}}
                        ></CustomCalendar> 
                      </div>
                  </div>

                  <div className="tr">
                      <div className="th"><span className="">이숙희망숙소</span></div>
                      <div className="td">
                          <input type="text" className="form_control" maxLength={100} value={desiredRoom} onChange={(e: any) => setDesiredRoom(e.target.value)}/>
                      </div>
                  </div>

                  <div className="tr mix_width2">
                      <div className="th textarea">
                        <span className="">요청사항 입력 <br></br>
                          <ul className="lst_warning gray">
                              <li>이숙신청은 1년 1회만 가능합니다.</li>
                          </ul>
                        </span>
                      </div>
                      <div className="td textarea">
                          <textarea name="" id="" className="form_control" maxLength={400} value={claim} onChange={(e: any)=> setClaim(e.target.value)}></textarea>
                          <span className="size">{claim.length} byte / 400 byte</span>
                      </div>
                  </div>

                  <div className="tr">
                      <div className="th">
                          <span className="">비품 이상유무 체크</span>
                      </div>
                      <div className="td row">
                        {props.history.location.state.moveId?(
                            <>
                            <div className="contents_area images">
                              {props.history.location.state.guest.checkin_files ? (
                                <div className="images_area">
                                  {props.history.location.state.guest.checkin_files.map((image: any, index: number) => (
                                      <div key={index} className="img" style={{cursor:'pointer'}} onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                                        <a download>
                                          <img
                                            src={`${process.env.REACT_APP_MEDIA}/${image.path}`}
                                            alt={image.name}
                                          />
                                        </a>
                                      </div>
                                    ))}
                                </div>
                              ) : ''}
                              <ul className="lst_warning gray">
                                  <li>입숙절차 시 관리자와 같이 확인했던 내용입니다. 이미지를 보시고 현재 이용중인 숙소의 이상 유무를 체크해 주세요.</li>
                              </ul>
                            </div>
                            </>
                        ) :(
                            <>
                            <div className="contents_area images">
                              {props.history.location.state.checkin_files ? (
                                <div className="images_area">
                                  {props.history.location.state.checkin_files.map((image: any, index: number) => (
                                      <div key={index} className="img" style={{cursor:'pointer'}} onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                                        <a download>
                                          <img
                                            src={`${process.env.REACT_APP_MEDIA}/${image.path}`}
                                            alt={image.name}
                                          />
                                        </a>
                                      </div>
                                    ))}
                                </div>
                              ) : ''}
                              <ul className="lst_warning gray">
                                  <li>입숙절차 시 관리자와 같이 확인했던 내용입니다. 이미지를 보시고 현재 이용중인 숙소의 이상 유무를 체크해 주세요.</li>
                              </ul>
                            </div>
                            </>
                        )}

                          <div className="radio_area">
                              <label className="input_radio" htmlFor="radio_warning1">
                                  <input type="radio" id="radio_warning1" name="radio_warning" checked={isEquimentOk} onChange={() => {setIsEquimentOk(true); setEquipmentDesc('');}} />
                                  <span className="label_text">숙소 상태 및 비품 등에 이상이 있습니다.</span>
                              </label>
                              <label className="input_radio" htmlFor="radio_warning2">
                                  <input type="radio" id="radio_warning2" name="radio_warning" checked={!isEquimentOk} onChange={() => {setIsEquimentOk(false); setEquipmentDesc('');}}/>
                                  <span className="label_text">숙소 상태 및 비품 등에 이상이 없습니다.</span>
                              </label>
                          </div>
                      </div>
                  </div>
                  {isEquimentOk? (
                    <div className="tr mix_width2">
                        <div className="th textarea">
                          <span className="">이상 품목 입력</span>
                        </div>
                        <div className="td textarea">
                            <textarea name="" id="" className="form_control" maxLength={400} value={equipmentDesc} onChange={(e: any)=> setEquipmentDesc(e.target.value)}></textarea>
                            <span className="size">{equipmentDesc.length} byte / 400 byte</span>
                        </div>
                    </div>
                  ) :''}
              </div>
          </div>
      </div> {/* table_design_write */}

      <ul className="lst_help mt15">
          <li>이숙 시 숙소 상태 및 비품 등 이상유무를 점검합니다. 관리자가 입력한 이숙확정일이 이숙일로 기산됩니다</li>
          <li>이숙 시 숙소 상태 및 비품 등에 이상이 있거나 짐이 남아 있으면 원상복구 요청 등을 할 수 있으며 이숙완료 처리가 되지 않습니다</li>
      </ul>

      {/* <div className="mb_view">
          <button type="button" className="btn btn_lg btn_gray">목록</button>
      </div> */}

      <div className="submit">
          {/* <div className="fl">
              <button type="button" className="btn btn_lg btn_gray">목록</button>
          </div> */}
          <div className="fr">
            <ConfirmBtn
                action={() => {
                  if(props.history.location.state.moveId){
                    props.history.push('/mypage/move');
                  }else {
                    props.history.push('/mypage/guest/detail', props.history.location.state);
                  }
                }}
                label={`취소`}
                title=""
                msg={`이숙신청을 취소하시겠습니까?`}
                className="btn btn_lg btn_outline"
              />
              <ConfirmBtn
                action={onSubmitHandler}
                label={`완료`}
                title=""
                msg={`이숙신청을 진행하시겠습니까?`}
                className="btn btn_lg btn_darkblue"
              />
          </div>
      </div>

      {/* 이미지 팝업 */}
      <div id="pop_image" className="pop_dim_wrap pop_modal" style={{display: isImgPop? 'block' : 'none'}}>
          <div className="pop_wrap">
              <div className="title_wrap">
                  <a href="javascript:void(0);" className="pop_btn_close" onClick={()=> setIsImgPop(false)}>닫기</a>
              </div>
              <div className="contents_wrap">
                  <div className="slide">
                      <p className="img_area">
                          <img src={imgPath} alt=""/>
                      </p>
                  </div>
              </div>
          </div>
      </div>
    </DefaultTemplete>
  );
});

export default LodgeMoveApply;
