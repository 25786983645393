import React, { useState, useEffect } from 'react';
import xss from 'xss';
import { pledge } from '../../../apis';
import { SHOW_MODAL } from '../../../store/modules/modal';
import { parserEscape } from '../../../utils/commons';

interface Props {
  workSite: number
  roomType: number

  onChangePledgeFile?: Function
}

//입숙서약서 acordian
const Pledge = (props: Props) => {

  const [contents, setContents] = useState<string>("");

  useEffect(() => {
    (async () => {
      let payload = {
        work_site: props.workSite,
        room_type: props.roomType
      }
      try {
        let { data } = await pledge.getPledges(payload);
        
        if (data.length) {
          setContents(data[0].contents);
          props.onChangePledgeFile && props.onChangePledgeFile(data[0].pledge_file);
        }
      }catch ({ status, data }) {
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }
    })()
  }, [props.workSite, props.roomType]);

  return (
    <div className="form_write">
      <div className="form_terms" id="editor" dangerouslySetInnerHTML={{__html: parserEscape(contents)}} />
    </div>
  );
};

export default Pledge;
