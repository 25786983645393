import React from 'react';
import Rooms from './room';
import intl from 'react-intl-universal';
import './index.scss';

import { Room, Floor } from './index.d'

interface Props {
  floors: Floor[]

  onRoomClickHandlerToSelect: Function
  onRoomClickHandlerToUnSelect: Function
  isDisable: Function
  isSelect: Function
  selectFloor: number
  floorHead?: string
  detail?: Function
  isGuide?: boolean
}

const LodgeFloors = ({
      floors,
      selectFloor,
      detail,
      floorHead,
      onRoomClickHandlerToSelect,
      onRoomClickHandlerToUnSelect,
      isGuide,
      isDisable, 
      isSelect, 
    }: Props): any  => 
{  
  const isSelectFloor = (selectFloor === -1) || (selectFloor <= (floors.length - 1));
  floors = (selectFloor === -1) ? floors : 
          (selectFloor !== -1) || (selectFloor <= (floors.length - 1)) ? [floors[selectFloor]] : [];
  
  //console.log(selectFloor);
  return floors.map((floor: any, idx: number) =>
    floor.rooms.length ? (
      <React.Fragment key={idx}>
        <ul className="lst_floortype type2">
            {floor.rooms.map((room: any, idx: number) => (
              <Rooms
                room={room}
                onRoomClickHandlerToSelect={onRoomClickHandlerToSelect}
                onClickonRoomClickHandlerToUnSelect={onRoomClickHandlerToUnSelect}
                key={idx}
                isDisable={isDisable}
                isSelect={isSelect}
                detail={detail}
              />
            ))}
        </ul>
      </React.Fragment>
    ) : (
        <></>
      )
  );
};

export default LodgeFloors;
