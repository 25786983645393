import React from 'react';

const UlsanMainNoti = (props: any) => {
    return (
      <div
      id="pop_guide"
      className="pop_dim_wrap pop_modal pop_lg"
      style={{display:'block'}}
    >
      <div className="pop_wrap">
      <div className="title_wrap">
        <h3>울산숙소 예약 안내사항</h3>
      </div>
      <div className="contents_wrap">
        <div className="service_space"></div>
        <div className="terms_service terms_wrap">
          <dl>
            <dt>1. 출장자 숙소</dt>
            <dd>
            - 울산공장 소속 시 신청이 불가능하오니, 참고 해주시기 바랍니다.
            </dd>

            <dt>2. 기숙사</dt>
            <dd>
              - 울산에 본가 및 자가가 없는 미혼자만 신청이 가능하오니, 참고 해주시기 바랍니다.
            </dd>
          </dl>
        </div>
      </div>

      <div className="button_wrap btn_length_1">
        <button
          type="button"
          className="btn btn_xlg btn_darkblue pop_close"
          onClick={() => props.onClosePopup()}
        >
          확인
        </button>
      </div>
    </div>
  </div>
  );
}

export default UlsanMainNoti;