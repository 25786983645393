import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

import { MessageContext } from '../../routes';

import intl from 'react-intl-universal';

import Floors from '../../components/molecules/floor';
import SelectBuilding from '../../components/atoms/select/buildings';
import SelectFloor from '../../components/atoms/select/floor';
import PhoneInput from '../../components/atoms/input/phone';
import Calendar from '../../components/molecules/calendar';

import useWorkSite from '../../hooks/useWorkSite';

import { GENDER, ROOM_TYPE_CODE } from '../../constant';
import { build } from '../../apis';

import {
  isAfterAndSame,
  getTodayDate,
  getNextDay,
  getPrevDay,
  gettwoDay,
  getTwoWeeks
} from '../../utils/date';

import _ from 'lodash';

const Step2 = (props: any) => {
  const { info } = useContext(MessageContext);

  const { workplaceMap = {} } = useSelector((state: any) => ({
    workplaceMap: state.base.workplaceMap
  }));

  const [beforeDisablePoint, setBeforeDisablePoint] = useState<any>([]);
  const [afterDisablePoint, setAfterDisablePoint] = useState<any>([]);

  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user
  }));

  const [ worksites, setUserWorkSiteByWorksite, setRoomTypeByWorksite ] = useWorkSite({
    roomType: ROOM_TYPE_CODE.business_room,
    userWorkSite: userInfo.worksite
  });

  console.log(props.dateAfter)
  console.log(props.dateBefore)

  //var date = new Date();
  //var m = date.getMonth();
  //var y = date.getFullYear();
  //var startMonth = new Date(y, m+1, 1);

  //const s_year = startMonth.getFullYear();
  //const s_month = ((startMonth.getMonth() + 1) >= 10) ? startMonth.getMonth() + 1 : `0${startMonth.getMonth() + 1}`;
  //const s_day = startMonth.getDate();

  //props.dateAfter = [s_year, s_month, s_day].join('-');
  //console.log(props.dateAfter);
  //console.log(props.dateBefore);
  //console.log([s_year, s_month, s_day + 1].join('-'));
  useEffect(() => {
    setUserWorkSiteByWorksite(userInfo.worksite)
    
  }, [userInfo.worksite])

  const todayDate = getTodayDate();

  const onClickDateAfterByCalendar = (day: string) => {
    // dateAfter <= dateBefore
    //const _isAfter = isAfterAndSame(day, props.nextTwoWeeksDate);
    const _isAfter = isAfterAndSame(day, props.dateBefore);
    //props.setTwoWeeksDate(day);
    props.setDateAfter(day);
    if (!_isAfter || day === props.dateBefore) {
      //props.setNextTwoWeeksDate(getNextDay(day)[0]);
      props.setDateBefore(getNextDay(day)[0]);
      setAfterDisablePoint(gettwoDay(day)[0]);
      // alert(`${day}보다 ${props.dateBefore}가 이전이 될 수 없습니다.`);
    }
  };

  const onClickDateBeforeByCalendar = (day: string) => {
    // dateAfter <= dateBefore
    //const _isAfter = isAfterAndSame(props.twoWeeksDate, day);
    const _isAfter = isAfterAndSame(props.dateAfter, day);
    //props.setNextTwoWeeksDate(day);
    props.setDateBefore(day);
    if (!_isAfter) {
      //props.setTwoWeeksDate(day);
      props.setDateAfter(day);
      // alert(`${props.dateAfter}보다 ${day}가 이전이 될 수 없습니다.`);
    }
  };

  //당일예약
  const onClickTodayReservationDate = (e: any) => {
    var date = new Date();
    var m = date.getMonth();
    var y = date.getFullYear();
    var startMonth = new Date(y, m+1, 1);
    
    const s_year = startMonth.getFullYear();
    const s_month = ((startMonth.getMonth() + 1) >= 10) ? startMonth.getMonth() + 1 : `0${startMonth.getMonth() + 1}`;
    const s_day = startMonth.getDate();
    props.setDateAfter([s_year, s_month, s_day].join('-'));
    props.setDateBefore([s_year, s_month, s_day + 1].join('-'));  
    //props.setDateAfter(todayDate[0]);
    //props.setDateBefore(getNextDay(todayDate[0])[0]);
  };

  return (
    <>
      <ul className="lst_thumb_request">
        <li>
          <strong>{intl.get("숙소유형")}</strong>
          <span>{intl.get("R&D STAY")}</span>
        </li>
        <li>
          <strong>{intl.get("이용사업장")}</strong>
          <span>{workplaceMap[props.workSite]?.name}</span>
        </li>
        <li>
          <strong>{intl.get("입숙예정일")}</strong>
          <span>{props.dateAfter}</span>
        </li>
        <li>
          <strong>{intl.get("퇴숙예정일")}</strong>
          <span>{props.dateBefore}</span>
        </li>
      </ul>

      <div className="title_contents">
        <h3 className="title">{intl.get("신청자 정보")}</h3>
      </div>

      <div className="table_design_list col_width6">
        <div id="table" className="table">
          <div className="tbody">
            <div className="tr">
              <div className="th">{intl.get("사번 (아이디)")}</div>
              <div className="td">{userInfo.employeenumber}</div>
              <div className="th">{intl.get("성명")}</div>
              <div className="td">{userInfo.username}</div>
              <div className="th">{intl.get("성별")}</div>
              <div className="td">{GENDER[userInfo.gender]}</div>
            </div>
            <div className="tr">
              <div className="th">{intl.get("소속 부서")}</div>
              <div className="td">{userInfo.department}</div>
              <div className="th">{intl.get("휴대폰 번호")}</div>
              <div className="td">{userInfo.phone}</div>
              <div className="th"></div>
              <div className="td"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="title_contents">
        <h3 className="title">{intl.get("이용자 정보")}</h3>
      </div>

      <div className="table_list" style={{ marginTop: 10 }}>
        <table>
          <caption className="sr_only">
            문의안내 - 숙소관리자, HMC 관리자, HEC 관리자, 플랫폼 관리자
            연락처 리스트
                </caption>
          <colgroup>
            <col width="20%" />
            <col width="20%" />
            <col width="20%" />
            <col width="20%" />
            <col width="20%" />
          </colgroup>
          <thead>
            <tr>
              <th>{intl.get("이용자 성명")}</th>
              <th>{intl.get("성별")}</th>
              <th>{intl.get("휴대폰 번호")}</th>
              <th>{intl.get("신청자와의 관계")}</th>
              <th>{intl.get("차량번호")}</th>
            </tr>
          </thead>
          <tbody>
            {props.guests.map((guest: any, idx: number) => (
              <tr key={idx}>
                <td>{guest.guest_name}</td>
                <td>{guest.guest_gender === 1 ? '남자' : '여자'}</td>
                <td>{guest.guest_cellphone}</td>
                <td>{guest.guest_relation}</td>
                <td>{guest.guest_car_number1}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="title_contents title_selectdate">
        <h3 className="title">이용기간 선택</h3>
        <div className="right_area">
          <ul className="lst_help">
            <li>이용 기간을 선택하여 주세요.</li>
          </ul>
        </div>
      </div>

      <div className="select_date">
        <div className="date_start">
          <div className="datepicker_area">
            <Calendar
              onClickSelectDay={onClickDateAfterByCalendar}
              selectDate={props.dateAfter}
              name={"left"}
              isNextMonthButtonView={false}
              beforeDisablePoint={getPrevDay(props.dateAfter)[0]}
            />
            <div className="btn_area" />
          </div>
        </div>
        <div className="date_end">
          <div className="datepicker_area">
            <Calendar
              onClickSelectDay={onClickDateBeforeByCalendar}
              name={"right"}
              selectDate={props.dateBefore}
              beforeDisablePoint={props.dateAfter}
              afterDisablePoint={props.afterDisablePoint}
              isNextMonthButtonView={true}
            />
            <div className="btn_area">
              <button
                className="btn btn_sm btn_darkblue btn_outline"
                onClick={onClickTodayReservationDate}
              >
                {intl.get("새로고침")}
                  </button>
            </div>
          </div>
        </div>
      </div>

      <div className="submit">
        <button className="btn btn_lg btn_outline" onClick={props.prev}>{intl.get("이전")}</button>
        <button className="btn btn_lg btn_darkblue" onClick={props.next}>{intl.get("다음")}</button>
      </div>
    </>

  );
};

export default Step2;
