import React from 'react';
import intl from 'react-intl-universal';

//건물명 및 동 select
const SelectBuilding = (props: any) => {
  
  return (
    <div className="col col_building">
      <label htmlFor="select_building" className="title_form">
        {intl.get("건물명 및 동")}
      </label>
      <select
        name=""
        id="select_building"
        className="form_control"
        onChange={e => props.changeBuildHandler(e)}
      >
        {props.buildings.map((build: any, idx: number) => (
          <option
            value={build.id}
            key={idx}
            selected={!idx}
            label={`${build.name} ${build.dong}`}
          />
        ))}
      </select>
    </div>
  );
};

export default SelectBuilding;
