import React, { useState, useEffect } from 'react';

import { onOff } from '../../../apis';

import { GUEST_TYPE, GUEST_TYPE_CODE } from '../../../constant';
import { SHOW_MODAL } from '../../../store/modules/modal';

interface option {
  label: string,
  guestType: number
}

interface Props {
  onChange: Function
  value: number
  options?: option[]
  selectWorkSite: number
  roomType: number
}

//이용자 타입 탭버튼
const GuestType = (props: Props) => {
  let defaultOptions: option[] = [
    { label: GUEST_TYPE[GUEST_TYPE_CODE.inner_employer], guestType: GUEST_TYPE_CODE.inner_employer },
    { label: GUEST_TYPE[GUEST_TYPE_CODE.similar_employer], guestType: GUEST_TYPE_CODE.similar_employer },
    { label: GUEST_TYPE[GUEST_TYPE_CODE.outer_employer], guestType: GUEST_TYPE_CODE.outer_employer }
  ]

  const [options, setOptions] = useState<option[]>(props.options || defaultOptions);

  useEffect(() => {
    if (props.selectWorkSite < 0 || props.roomType < 0) return;
    (async () => {
      try {

        if(props.selectWorkSite === 0) return;

        let { data } = await onOff.getUserType({
          work_site: props.selectWorkSite,
          room_type: props.roomType
        })

        defaultOptions = data
          .filter((item: { is_visible: boolean }, idx: number) => item.is_visible)
          .map((item: { guest_type: number }): option => ({
            label: GUEST_TYPE[item.guest_type], 
            guestType: item.guest_type,
          }))

        setOptions(defaultOptions)
        
        // if (defaultOptions.length) {
        //   props.onChange(defaultOptions[0].guestType);
        // } else {
        //   props.onChange(-1);
        // }
        //
      }catch ({ status, data }) {
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }
    })()

  }, [props.selectWorkSite, props.roomType])

  return (
    <>  
      <ul className="tabs pc">
        {options.map((option: option, optionIdx: number) => (
          <li
            onClick={() => props.onChange(option.guestType)}
            key={optionIdx}
          >
            <a
              href="javascript:void(0)"
              className={props.value === option.guestType ? 'on' : ''}
            >
              {option.label}
            </a>
          </li>
        ))}
      </ul>

      <select
        className="form_control form_lg mobile"
        value={props.value}
        onChange={e => props.onChange(parseInt(e.target.value))}
      >
        {options.map((option: option, optionIdx: number) => (
          <option value={option.guestType} key={optionIdx}>{option.label}</option>
        ))}
        
      </select>
    </>
  );
};

export default React.memo(GuestType);
