import React, { useState, useEffect, useContext } from 'react';

import { onOff, core } from '../../../apis';
import _ from 'lodash';

import intl from 'react-intl-universal';
import { MessageContext, MessageImpl } from '../../../routes';
import { SHOW_MODAL } from '../../../store/modules/modal';

export interface Document {
  id: number
  name: string
  is_required: true
  visible_type: number
}

export interface File {
  id: number
  path: string  // 실제 서버경로
  size: string
  name: string  // 업로드 했을 때 파일명
}

export interface FlatFile {
  document: number
  id: number
  is_required: true
  name: string
  title: string
  visible_type: number
}

export interface Props {
  category: number
  
  workSiteName?: string
  workSite?: number
  guestType: number
  roomType: number
  visibleType: number

  onChange: Function
  value: FlatFile[]
  alreadyFiles: {
    file: File,
    document?: Document
  }[]
  setisFile: Function

}

//파일업로드
const CheckoutUploadFileList = (props: Props) => {
  const [files, setFiles] = useState<FlatFile[]>([]);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  useEffect(() => {
    if ((props.workSite && props.workSite < 0) || props.guestType < 0 || props.roomType < 0) return

    let payload: any = {
      guest_type: props.guestType,
      room_type: props.roomType,
      visible_type: props.visibleType
    };
      
    if (props.workSite) payload.work_site = props.workSite;
    if (props.workSiteName) payload.work_site_name = props.workSiteName;

    (async () => {
      try {
        let { data } = await onOff.getDocument(payload)
        
        let files = []
        files = data.map((document: Document) => {
          return props.alreadyFiles.reduce((acc: any, curVal: any, idx: number) => { 
            // 업로드 했던 파일 추가하기
            if (
              curVal.document?.id === document.id 
            ) {
              acc.document = document.id
              acc.title = document.name
              acc.name = curVal.file.name
              acc.id = curVal.file.id
              acc.is_required = document.is_required
              acc.visible_type = document.visible_type
            }
            
            return acc
          }, {
            document: document.id,
            title: document.name,
            id: -1,
            is_required: document.is_required,
            name: '',
            visible_type: document.visible_type
          })
        }).filter(
          (file: { visible_type: number }) => file.visible_type === props.category
        )

        // if(files.length <= 0 ){
        //   props.setisFile(false);
        //   setFiles(files);
        // }else{
        //   props.setisFile(true);
        //   setFiles(files);
        // }
        setFiles(files);
      }catch ({ status, data }) {
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }
    })()
  }, [props.workSiteName, props.workSite, props.guestType, props.roomType])

  useEffect(() => {
    // console.log(files.filter((file: FlatFile) => file?.document))
    props.onChange(
      files.filter((file: FlatFile) => file?.document)
    );
  }, [files])

  const onUploadHandler = async (e: any, fileIdx: number) => {
    let tempFiles = _.cloneDeep(files);
    let frm = new FormData();

    frm.append("path", e.target.files[0]);

    let extList = ['gif', 'jpg', 'jpeg', 'png', 'hwp', 'pdf', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'docx'];

    let fileData: any = e.target.files[0];
    let fileExt = (fileData.name).split('.').pop().toLowerCase();

    if(!extList.includes(fileExt)){
      let extText = extList.join(', ');
      error(`파일은 `+extText+` 만 가능합니다.`);
      return false;
    }else if (e.target.files[0].size >= 10485760) {      
      error(`파일 용량은 10MB 이상 첨부할 수 없습니다.`);
      return false;
    }

    try {
      let { data } = await core.fileUpload<FlatFile>(frm);
      tempFiles[fileIdx].id = data.id
      tempFiles[fileIdx].name = data.name
      setFiles(tempFiles);
    } catch (err) {
      //console.log(err)
      const { status, data } = err;
      // console.log(status, data)

      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }      

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  }

  return (
    <>
      {files.map((file: any, fileIdx: number) => (
        <div className="tr" key={fileIdx}>
          <div className="th">
            {file.is_required ? <span className="emp_red">{file.title}</span> : <span className="">{file.title}</span>}
          </div>
          <div className="td">
            <div className="wrap_file">
              <input id="input_copy" className="upload_name form_control" placeholder={intl.get("파일찾기를 클릭하여 파일을 업로드 하세요")} value={file.name} disabled />
              <input
                type="file"
                id={`input_upload_copy_${fileIdx}`}
                className="upload_hidden"
                onClick={(e: any) => e.target.value = null}
                onChange={(e: any) => onUploadHandler(e, fileIdx)}
              />
              <label htmlFor={`input_upload_copy_${fileIdx}`} className="btn btn_gray btn_srch_file">{intl.get("파일찾기")}</label>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default CheckoutUploadFileList;
