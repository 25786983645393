import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import intl from 'react-intl-universal';
import ImageUpload from '../../../molecules/imageUpload';
import FileUpload from '../../../molecules/fileUpload';
import PlaceOccurrence from '../../../organismas/placeOccurrence';
import '../index.scss';
import Select from '../../../atoms/select';
import { MessageImpl, MessageContext } from '../../../../routes';
import { lodge, voc } from '../../../../apis';
import _ from 'lodash';
import DatePicker from '../../../organismas/datePicker';
import { SHOW_CONFIRM_MODAL, SHOW_MODAL } from '../../../../store/modules/modal';
import { LODGE_STATUS_CODE, ROOM_TYPE_CODE, VOC_CATEGORY, WORK_SITE_CODE } from '../../../../constant';
import { getNextDay, getTodayDate } from '../../../../utils/date';
import { getWorkSitePhoneNum, getWorkSitePhoneNum2 } from '../../../../utils/commons';

const VocUpdate = (props: any) => {
  const dispatch = useDispatch();
  const _state: any | null = props.item;
  const id = _state?.id || '';
  const author_name = _state?.author_name || '';
  const author_employee_number = _state?.author_employee_number || '';
  const isCreate = props.isEdit && !props.match.params.id;
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const [created_at, setCreated_at] = useState<string>(_state?.created_at || new Date());
  const [workSite, setWorkSite] = useState<string>(props.user.worksite);
  const [category1, setCategory1] = useState<string>(_state?.category1 || '');
  const [category2, setCategory2] = useState<string>(_state?.category2 || '');
  const [category3, setCategory3] = useState<string>(_state?.category3 || '');
  const [category4, setCategory4] = useState<string>(_state?.category4 || '');
  const [title, setTitle] = useState<string>(_state?.title || '');
  const [contents, setContents] = useState<string>(_state?.contents || '');
  const [found_at, setFound_at] = useState<string>(_state?.found_at || '');
  const [places, setPlaces] = useState<any[]>(_state?.places || []);
  const [images, setImages] = useState<any[]>(_state?.images || []);
  const [files, setFiles] = useState<any[]>(_state?.files || []);
  const [found_date, setFound_date] = useState<string>(_state?.found_at ? _state?.found_at.substr(0, 10) : '');
  const [found_hour, setFound_hour] = useState<string>(_state?.found_at ? _state?.found_at.substr(11, 2) : '');
  const [orgItem, setOrgItem] = useState<string>();
  const [hours, setHours] = useState<any[]>([{ value: '', label: '시간', optionIdx: 0 }]);
  const [categories1, setCategories1] = useState<any[]>([]);
  const [categories2, setCategories2] = useState<any[]>([]);
  const [categories3, setCategories3] = useState<any[]>([]);
  const [useRoom, setUseRoom] = useState<any[]>([]);
  const [placeRoomType, setPlaceRoomType] = useState<number>();
  const [placesEtc, setPlacesEtc] = useState<string>(_state?.places_etc || '');   // 발생장소 기타인 경우
  const [placesEtc2, setPlacesEtc2] = useState<string>(_state?.places_etc2 || '');   // 발생장소 기타인 경우

  useEffect(() => {
    let _categories2: any[] = [];
    let _categories3: any[] = [];
    if (category1) {
      const c1: any = VOC_CATEGORY.find((c: any) => c.name === category1);
      const _category1 = c1 ? c1.name : '';
      _categories2 = [
        { value: '', name: '2차 분류항목을 선택하세요', child: [] },
        ...c1.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child }))
      ];
      setCategories2(_categories2);
    }
    if (category2) {
      const c2: any = _categories2.find((c: any) => c.name === category2);
      const _category2 = c2 ? c2.name : '';
      _categories3 = [
        { value: '', name: '2차 분류항목을 선택하세요', child: [] },
        ...c2.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child }))
      ];
      setCategories3(_categories3);
    }
  }, []);

  // 이용약관, 개인정보 자세히보기 추가
  const [isPlaceOpen, setIsPlaceOpen] = useState<boolean>(false);
  const onShowPlacePopupHandler = (isOpen: boolean) => {
    setIsPlaceOpen(isOpen);
  }; // 열기

  useEffect(() => {
    const _hours = [...hours];
    _.range(24)
      .map(n => _.padStart(n.toString(), 2, '0'))
      .forEach((num, index) => _hours.push({ value: num, label: num, optionIdx: index + 1 }));
    setHours(_hours);

    if (_state?.found_at) {
      setFound_date(_state?.found_at.substr(0, 10));
      setFound_hour(_state?.found_at.substr(11, 2).trim());
    }

    const _orgItem = [
      category1 || '',
      category2 || '',
      category3 || '',
      category4 || '',
      title || '',
      contents || '',
      (places || []).map((place: any) => place.id).join('') || '',
      (images || []).map((image: any) => image.name).join('') || '',
      (files || []).map((file: any) => file.name).join('') || '',
      found_date || '',
      found_hour || '',
      placesEtc || '',
      placesEtc2 || ''
    ].join('');
    setOrgItem(_orgItem);
  }, []);

  // 이용중인 숙소가 있는 경우 숙소의 사업장 가져오기
  useEffect(() => {
    (async () => {
    // 이용중인 숙소 list
      const guestPayload: any = {
        status: LODGE_STATUS_CODE.checkin,
        // room_type: ROOM_TYPE_CODE.dormitory_room
      };

      try{
        const { data:guestList }: any = await lodge.getGuest(guestPayload);

        const domi = guestList.filter((dom:any, index:number)=> dom.room_type === ROOM_TYPE_CODE.dormitory_room);
        const busy = guestList.filter((busi:any, index:number)=> busi.room_type === ROOM_TYPE_CODE.business_room);
        const com = guestList.filter((comp:any, index:number)=> comp.room_type === ROOM_TYPE_CODE.company_room);
        const vip = guestList.filter((comp:any, index:number)=> comp.room_type === ROOM_TYPE_CODE.vip_room);

        if(busy.length > 0){
          setWorkSite(WORK_SITE_CODE[busy[0].room_work_site]);
          setUseRoom(busy);
        }else if(vip.length > 0){
          setWorkSite(WORK_SITE_CODE[vip[0].room_work_site]);
          setUseRoom(vip);
        }else if(domi.length > 0){
          setWorkSite(WORK_SITE_CODE[domi[0].room_work_site]);
          setUseRoom(domi);
        }else if(com.length > 0){
          setWorkSite(WORK_SITE_CODE[com[0].room_work_site]);
          setUseRoom(com);
        }else{
          setWorkSite(props.user.worksite);
        }
      }catch ({ status, data }) {
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }

    })();
  }, []);

  const currentValues = () => [
    category1,
    category2,
    category3,
    category4,
    title,
    contents,
    places.map(place => place.id).join(''),
    images.map(image => image.name).join(''),
    files.map(file => file.name).join(''),
    found_date,
    found_hour,
    placesEtc,
    placesEtc2
  ];

  const onSaveHandler = () => {
    if (orgItem === currentValues().join('')) {
      info('변경된 항목이 없습니다.');
      return;
    }

    if (!title || !contents) {
      info('필수 입력 사항이 모두 입력되지 않았습니다');
      return;
    }
    
    if (!category1) {
      info('유형을 선택해주세요.');
      return;
    }

    if (!found_hour || !found_date) {
      info('발생 시간을 입력해주세요.');
      return;
    }

    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        title: '',
        msg: intl.get('작성하신 내용을 등록하시겠습니까?'),
        redirectPath: false,
        statusCode: false,
        errorCode: '',
        type: 'info',
        isModalShow: true,
        action: async () => {

          const data: any = {
            work_site: workSite,
            category1,
            category2,
            category3,
            category4,
            title,
            contents,
            is_maintenance: true,
            is_complete: false,
            completed_at: '',
            found_at: `${found_date} ${found_hour}:00:00`,
          };

          if(useRoom.length > 0) {
            // roomtype 기타인 경우 텍스트 던지기
            if(placeRoomType === 7){
              data.places_etc = placesEtc;
              data.places_etc2 = placesEtc2;
              data.places = [];
            }else {
              data.places = places.map(place => place.id);
              data.places_etc = '';
              data.places_etc2 = '';
            }
          
            //data.building_id = places.map(place => place.building_id);
            data.images = images.map(image => image.id);
            data.files = files.map(file => file.id);
            
            try {
              let msg = intl.get('등록 완료했습니다.');
              if (id < 0) {
                await voc.createVoc(data);

                let placeName = '';
                if(places.length > 0) {
                  placeName = places[0].building_name + '>' + places[0].building_dong + '>' + places[0].room_name+'호';
                  if(places.length > 1) placeName += ' 외 ' + (places.length-1) + '곳'
                }
                // 문자발송
                let smsPayload: any = {
                  cellphone : props.user.phone,
                  message : 'VoC가 접수되었습니다. \n접수일 : '+getTodayDate()[0]+' \n접수 유형 : '+ category1 +' \n제목 : '+ title +' \n발생시간 : '+ found_date +' ' + found_hour +'시경'
                }
                if(placeName !== '') {
                  smsPayload.message += '\n발생장소 : '+ placeName
                }
                smsPayload.message += '\n빠른 처리후 답변드리겠습니다.'
                                    +'\n신청진행 확인은 "고객센터>>My VoC" 확인부탁드리며 기타 문의는 관리사무소 전화번호 '+getWorkSitePhoneNum2(props.user.worksite)
                const { data: sms } = await lodge.sendSMS(smsPayload);
              } else {
                await voc.updateVoc(id, data);
                msg = intl.get('수정 완료했습니다.');
              }
              info(msg);
              props.history.replace(`/customer/voc`);
            } catch ({ status, data }) {
              if (status === 401) {
                window.location.href = '/force/signout';
                return ;
              } else if (status === 403) {
                (window as any).store.dispatch({
                  type: SHOW_MODAL,
                  payload: {
                  title: '권한오류',
                  msg: '접근권한이 없습니다.',
                  redirectPath: false,
                  statusCode: status,
                  errorCode: '',
                  type: 'error',
                  isModalShow: true,
                  },
                });
                return ;
              }else if ( status === 500 ) {
                window.location.href = '/500';
                return;
              }

              (window as any).store.dispatch({
                type: SHOW_MODAL,
                payload: {
                title: '문제발생',
                msg: '문제가 발생했습니다',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
                },
              });
              return ;
            }
          }
          else {
            info('숙소이용 중에만 VoC를 등록 하실 수 있습니다.'); 
          }
        }
      },
    });
  };

  const onCancelhandler = () => {
    onBackHandler();
  };

  const onBackHandler = () => {
    if (orgItem === currentValues().join('')) {
      props.history.push('/customer/voc');
      //props.history.goBack();
    } else {
      let msg = intl.get('입력하신 내용이 삭제됩니다. 목록으로 이동하시겠습니까?');
      if (!isCreate) {
        msg = intl.get('변경하신 내용이 삭제됩니다. 취소하시겠습니까?');
      }
      dispatch({
        type: SHOW_CONFIRM_MODAL,
        payload: {
          title: '',
          msg,
          redirectPath: false,
          statusCode: false,
          errorCode: '',
          type: 'info',
          isModalShow: true,
          action: () => props.history.push('/customer/voc'),
          //action: () => props.history.goBack(),
        },
      });
    }
  };

  // 발생장소 선택 후 적용 버튼 클릭시
  const onSetPlacesHandler = (_places: any[], roomType: number, etc: string, etc2: string) => {
    if(roomType === 7) {
      setPlaces([]);
      setPlaceRoomType(roomType);
      setPlacesEtc(etc);
      setPlacesEtc2(etc2);
    }else {
      setPlaces([..._places]);
      setPlaceRoomType(roomType);
      setPlacesEtc('');
      setPlacesEtc2('');
    }
  };

  return (
    <>
      <div className="table_design_write detail col_width4 mt0">
        <div id="table" className="table">
          <div className="tbody">
            {/* {useRoom? (
              <>
                <div className="tr mix_width2 mb_w100">
                  <div className="th">{intl.get('이용중인 숙소')}</div>
                  <div className="td">{}</div>
                </div>
              </>
            ) :''} */}
            <div className="tr mix_width2 mb_w100">
              <div className="th"><span className="emp_red">{intl.get('유형')}</span></div>
              <div className="td">
                <div className="form_type">
                  <select name="" id="" className="form_control fl"
                    value={category1}
                    onChange={(e) => {
                      const category: any = VOC_CATEGORY.find(c => c.name === e.target.value);
                      const _category1 = category ? category.name : '';
                      setCategory1(_category1);
                      setCategory2(''); 
                      setCategory3('');
                      if (_category1 && category.child.length > 0) {
                        setCategories2([
                          { value: '', name: '2차 분류항목을 선택하세요', child: [] },
                          ...category.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child }))
                        ]);
                      } else {
                        setCategories2([]);
                      }
                      setCategories3([]);
                    }}
                  >
                    <option value="">1차 분류항목을 선택하세요</option>
                    {VOC_CATEGORY.map((category: any, index: number) => (
                      <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
                    ))}
                  </select>
                  {categories2.length > 0 ? (
                    <select name="" id="" className="form_control fl"
                      value={category2}
                      onChange={(e) => {
                        const category: any = categories2.find(c => c.name === e.target.value);
                        const _category2 = category ? category.name : '';
                        setCategory2(_category2);
                        setCategory3('');
                        if (_category2 && category.child.length > 0) {
                          setCategories3([
                            { value: '', name: '3차 분류항목을 선택하세요', child: [] },
                            ...category.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child  }))
                          ]);
                        } else {
                          setCategories3([]);
                        }
                      }}
                    >
                      {categories2.map((category: any, index: number) => (
                        <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
                      ))}
                    </select>
                  ) : <></>}
                  {categories3.length > 0 ? (
                    <select name="" id="" className="form_control fl"
                      value={category3}
                      onChange={(e) => setCategory3(e.target.value)}
                    >
                    {categories3.map((category: any, index: number) => (
                      <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
                    ))}
                    </select>
                  ) : <></>}
                </div>
              </div>
            </div>

            <div className="tr mix_width2 mb_w100">
              <div className="th"><span className="">{intl.get('작성자')}</span></div>
              <div className="td">
                {author_name}({author_employee_number})
              </div>
            </div>

            <div className="tr mix_width2 mb_w100">
              <div className="th"><span className="emp_red">{intl.get('제목')}</span></div>
              <div className="td">
                <input 
                  type="text"
                  className="form_control"
                  value={title}
                  onChange={(e: any) => setTitle(e.target.value)}
                  tabIndex={1}
                />
              </div>
            </div>

            <div className="tr mix_width2 mb_w100">
              <div className="th">{intl.get('발생장소')}</div>
              <div className="td">
                <button
                  type="button"
                  className="btn btn_sm btn_outline mr10 btn_srch_file"
                  onClick={() => onShowPlacePopupHandler(true)}
                  tabIndex={2}
                >{intl.get('검색')}</button>
                <div className="items_area">
                  {placesEtc? (
                    <>{'기타 > ' + placesEtc + ' > ' + placesEtc2}</>
                  ) :
                    places.map(place => (
                      <a key={place.id} href="javascript:void(0);" className="item">
                        {
                          place.building_name === '기타'
                          ? intl.get('_번 지역', { place: `${place.building_name} > ${place.room_name}-${place.floor_name}`})
                          // : place.building_id? `${place.building_name} > ${place.building_dong ? place.building_dong : ''}`
                          : intl.get('_호', { room: `${place.building_name} > ${place.building_dong ? place.building_dong + ' > ' : ''} ${place.room_name}` })
                        }
                      </a>
                    ))
                  }
                </div>
              </div>
            </div>

            <div className="tr mix_width2 mb_w100">
              <div className="th">
              <span className="emp_red">{intl.get('발생 시간')}</span>
              </div>
              <div className="td">
                <div className="form_datetime">
                  <DatePicker
                    value={found_date}
                    width={250}
                    onChange={(date: string) => setFound_date(date)}
                    isPrevMonthView={true}
                    max={getNextDay(getTodayDate()[0])[0]}
                  />
                  <Select
                    className="form_control fl"
                    options={hours}
                    value={found_hour}
                    onChange={(e: any) => setFound_hour(e.target.value)}
                  />
                  <span className="unit">{intl.get('시경')}</span>
                </div>
              </div>
            </div>

            <div className="tr mix_width2 mb_w100">
              <div className="th"><span className="emp_red">{intl.get('내용')}</span></div>
              <div className="td textarea">
                <textarea 
                  name=""
                  id=""
                  className="form_control"
                  value= {contents}
                  onChange={(e: any) => setContents(e.target.value)}
                  tabIndex={3}
                ></textarea>
              </div>
            </div>

            <div className="tr mix_width2 mb_w100">
              <div className="th"><span className="">{intl.get('사진첨부')}</span></div>
              <div className="td">
                <ImageUpload fileId="imageFile" items={images} onSetData={(data: any[]) => setImages([...data])} />
              </div>
            </div>

            <div className="tr mix_width2 mb_w100">
              <div className="th"><span className="">{intl.get('파일첨부')}</span></div>
              <div className="td">
                <FileUpload fileId="attacheFile" items={files} onSetData={(data: any[]) => setFiles([...data])} />
              </div>
            </div>
          </div>
        </div>
      </div> {/* table_design_list */}

      <div className="mb_view">
        <button type="button" className="btn btn_lg btn_gray" onClick={onBackHandler}>{intl.get('목록')}</button>
      </div>

      <div className="submit pt20">
        <div className="fl">
          <button type="button" className="btn btn_lg btn_gray" onClick={onBackHandler}>{intl.get('목록')}</button>
        </div>
        <div className="fr">
          <button type="button" className="btn btn_lg btn_outline" onClick={onCancelhandler}>{intl.get('취소')}</button>
          <button type="button" className="btn btn_lg btn_darkblue" onClick={() => onSaveHandler()}>{intl.get(isCreate ? '등록' : '저장')}</button>
        </div>
      </div>
      {isPlaceOpen ? (
        <PlaceOccurrence
          isOpen={isPlaceOpen}
          setIsOpen={setIsPlaceOpen}
          user={props.user}
          places={places}
          onApply={onSetPlacesHandler}
          setWorkSite={setWorkSite}
        />
      ) : (<></>)}
    </>
  )
};

export default VocUpdate;
