import React, { useState, useEffect, createContext, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import intl from 'react-intl-universal';

import locales from '../locales';
import { getSelectedLang } from '../utils/locales';

import SigninPage from '../pages/signin';
import SignupPage from '../pages/signup';
import SignupCompletePage from '../pages/signupComplete';

import HomePage from '../pages/home';
import ExcelPage from '../pages/excel';

import UseGuidePage from '../pages/useGuide';

import BusinessRoomSinglePage from '../pages/businessRoomSingle';
import BusinessRoomGroupReservationPage from '../pages/businessRoomGroup';
import VipRoomSinglePage from '../pages/vipRoomSingle';
import DormitoryRoomSingleReservationPage from '../pages/dormitoryRoomSingle';
import CompanyHouseRoomSingle from '../pages/companyHouseRoomSingle';
import FamilyHouseRoom from '../pages/familyHouseRoom';

import LodgeApplicantPage from '../pages/lodgeApplicant';
import LodgeApplicantDetailPage from '../pages/lodgeApplicantDetail';
import UserInfoManagementPage from '../pages/userInfoManagement';
import UserInfoUpdatePage from '../pages/userInfoUpdate';

import LodgeGuestPage from '../pages/lodgeGuest';
import LodgeGuestDetailPage from '../pages/lodgeGuestDetail';
import LodgeCheckoutPage from '../pages/lodgeCheckout';
import LodgeCheckoutApplyPage from '../pages/lodgeCheckoutApply';
import LodgeCheckoutDetailPage from '../pages/lodgeCheckoutDetail';
import LodgeCheckoutApplyDetail from '../pages/lodgeCheckoutApplyDetail';
import AuditPage from '../pages/audit';
import AuditDetailPage from '../pages/audit/detail';


import NoticePage from '../pages/notice';
import NoticeDetailPage from '../pages/notice/detail';

import FaqPage from '../pages/faq';
import FaqDetailPage from '../pages/faq/detail';

import VocPage from '../pages/voc';
import VocDetailPage from '../pages/voc/vocDetail';
import VocUpdatePage from '../pages/voc/vocUpdate';

import BoardSmsPage from '../pages/boardSms';

import FacilityInformationPage from '../pages/facilityInformation';

import CalendarPage from '../components/molecules/calendar';

import ForceSignout from '../components/templetes/forceSignout';

import NotFound from '../pages/notFound';
import ServerError from '../pages/serverError';

import Modal from '../components/modal';
import Confirm from '../components/organismas/popup/confirm';

import { SHOW_MODAL } from '../store/modules/modal';
import LodgeMoveApply from '../pages/lodgeMoveApply';
import LodgeMove from '../pages/lodgeMove';
import LodgeMoveDetail from '../pages/lodgeMoveDetail';
import LodgeMoveReqDetail from '../pages/lodgeMoveDetail/reqIndex';
import LodgeMoveReqApply from '../pages/lodgeMoveApply/reqIndex';

export interface MessageImpl {
  info(msg: string, redirectPath?: string): void
  warning(msg: string, redirectPath?: string): void
  error(msg: string, redirectPath?: string): void
}

export const MessageContext = createContext<MessageImpl>({
  info: (msg: string, redirectPath?: string) => { },
  warning: (msg: string, redirectPath?: string) => { },
  error: (msg: string, redirectPath?: string) => { },
});

export interface ApiErrorHandleImpl {
  errorHandler(err: {status: number, data: any}, defaultMsg?: string): void
}

export const ApiErrorHandleContext = createContext<ApiErrorHandleImpl>({
  errorHandler: (err: {status: number, data: any}, defaultMsg: string) => { }
})

const Routes: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const [initDone, setInitDome] = useState<boolean>(false);
  const { lang = 'kr' } = useSelector((state: any) => ({
    lang: state
  }))

  useEffect(() => {
    intl
      .init({
        currentLocale: getSelectedLang(),
        locales,
      })
      .then(() => {
        setInitDome(true);
      });
  }, [lang]);

  const info = (msg: string, redirectPath='') => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        title: '',
        msg,
        redirectPath: redirectPath? redirectPath: false,
        statusCode: false,
        errorCode: '',
        type: 'info',
        isModalShow: true,
      },
    });
  };
  const warning = (msg: string, redirectPath='') => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        title: '',
        msg,
        redirectPath: redirectPath? redirectPath: false,
        statusCode: false,
        errorCode: '',
        type: 'warning',
        isModalShow: true,
      },
    });
  };
  const error = (msg: string, redirectPath='') => {
    dispatch({
      type: SHOW_MODAL,
      payload: {
        title: '',
        msg,
        redirectPath: redirectPath? redirectPath: false ,
        statusCode: false,
        errorCode: '',
        type: 'error',
        isModalShow: true,
      },
    });
  };

  const errorHandler = (err: {status: number, data: any}, defaultMsg="서버에 문제가 있습니다.") => {
    if (err.status === 400) {
      if (intl.get(err.data.code)) {
        error(intl.get(err.data.code))
        return
      } else {
        error(err.data.code)
        return
      }
    }

    error(defaultMsg)
  }

  const messaageContextValue = useMemo(() => ({
    info,
    warning,
    error
  }), [SHOW_MODAL]);

  const apiErrorHandlerValue = useMemo(() => ({
    errorHandler
  }), [SHOW_MODAL])

  if (!initDone) return <></>;

  return (
    <React.Fragment>
      <MessageContext.Provider value={messaageContextValue} >
        <ApiErrorHandleContext.Provider value={apiErrorHandlerValue}>
          <Switch>
            <Route exact path="/" component={SigninPage} />
            <Route exact path="/home" component={HomePage} />
            <Route exact path="/auth/signup" component={SignupPage} />
            <Route
              exact
              path="/auth/signup/complete"
              component={SignupCompletePage}
            />
            <Route exact path="/excel" component={ExcelPage} />
            <Route exact path="/calendar" component={CalendarPage} />

            {/* 이용안내 */}
            <Route exact path="/guide" component={UseGuidePage} />

            {/* 이용신청 - 출장자개인 */}
            <Route
              exact
              path="/reservation/business/single"
              component={BusinessRoomSinglePage}
            />

            {/* 이용신청 - 출장자단체 */}
            <Route
              exact
              path="/reservation/business/group"
              component={BusinessRoomGroupReservationPage}
            />

            {/* 이용신청 - 영빈관 */}
            <Route
              exact
              path="/reservation/vip/single"
              component={VipRoomSinglePage}
            />
            {/* 이용신청 - 기숙사 */}
            <Route
              exact
              path="/reservation/dormitory/single"
              component={DormitoryRoomSingleReservationPage}
            />
            {/* 이용신청 - 사택 */}
            <Route
              exact
              path="/reservation/companyhouse/single"
              component={CompanyHouseRoomSingle}
            />
            {/* 이용신청 - 가족숙소 */}
            <Route
              exact
              path="/reservation/familyhouse/group"
              component={FamilyHouseRoom}
            />

            {/* 신청관리 리스트 */}
            <Route exact path="/mypage/applicant" component={LodgeApplicantPage} />

            {/* 신청관리 상세 */}
            <Route exact path="/mypage/applicant/detail" component={LodgeApplicantDetailPage} />


            {/* 이용관리 리스트 */}
            <Route exact path="/mypage/guest" component={LodgeGuestPage} />
            {/* 이용관리 상세 */}
            <Route exact path="/mypage/guest/detail" component={LodgeGuestDetailPage} />

            {/* 퇴숙관리 리스트 */}
            <Route exact path="/mypage/checkout" component={LodgeCheckoutPage} />
            {/* 퇴숙관리 신청 */}
            <Route exact path="/mypage/checkout/apply" component={LodgeCheckoutApplyPage} />
            {/* 퇴숙관리 신청 */}
            <Route exact path="/mypage/checkout/detail" component={LodgeCheckoutDetailPage} />
            {/* 퇴숙관리 수정 */}
            <Route exact path="/mypage/checkout/checkOutDetail" component={LodgeCheckoutApplyDetail} />
            
            {/* 이숙관리 리스트 */}
            <Route exact path="/mypage/move" component={LodgeMove} />
            {/* 이숙관리 상세 */}
            <Route exact path="/mypage/move/detail" component={LodgeMoveDetail} />
            <Route exact path="/mypage/move/reqdetail" component={LodgeMoveReqDetail} />
            {/* 이숙관리 신청 */}
            <Route exact path="/mypage/move/apply" component={LodgeMoveApply} />
            <Route exact path="/mypage/move/reqapply" component={LodgeMoveReqApply} />

            <Route exact path="/mypage/user/info" component={UserInfoManagementPage} />
            <Route exact path="/mypage/user/update" component={UserInfoUpdatePage} />

            {/* 환경심사관리 */}
            {/* <Route exact path="/customer/audit" component={AuditPage} />
            <Route exact path="/customer/audit/detail" component={AuditDetailPage} /> */}

            {/* 정기서류관리 */}
            <Route exact path="/customer/audit" component={AuditPage} />
            <Route exact path="/customer/audit/detail" component={AuditDetailPage} />

            {/* 공지사항 */}
            <Route exact path="/customer/notice" component={NoticePage} />
            <Route exact path="/customer/notice/detail" component={NoticeDetailPage} />
            {/* Faq */}
            <Route exact path="/customer/faq" component={FaqPage} />
            <Route exact path="/customer/faq/detail" component={FaqDetailPage} />
            {/* My Voc */}
            <Route exact path="/customer/voc" component={VocPage} />
            <Route exact path="/customer/voc/create" component={VocUpdatePage} />
            <Route exact path="/customer/voc/:id" component={VocDetailPage} />
            <Route exact path="/customer/voc/:id/update" component={VocUpdatePage} />

            {/* 알림 */}
            <Route exact path="/mypage/sms" component={BoardSmsPage} />

            {/* 시설정보 */}
            <Route
              exact
              path="/facility/information"
              component={FacilityInformationPage}
            />

            <Route exact path="/force/signout" component={ForceSignout} />
            <Route exact path="/404" component={NotFound} />
            <Route exact path="/500" component={ServerError} />
            <Route exact path="*" component={NotFound} />
          </Switch>
          <Modal />
          <Confirm />
        </ApiErrorHandleContext.Provider>
      </MessageContext.Provider>
    </React.Fragment>
  );
};

export default Routes;
