import React from 'react';
import intl from 'react-intl-universal';

interface Props {
  floors: [Floor]
  onChangeFloor: Function
  isShowAllOption?: boolean
  value?: number
}

interface Floor {
  id: number
  name: string | number
  layout_image: string
}

//건물 층 select
const Groupfloor = (props: Props) => {
  return (
    <select
        name=""
        id="select_floor"
        className="form_control form_sm"
        value={props.value}
        onChange={e => {props.onChangeFloor(e)}}
      >
        {props.isShowAllOption
        ? (
          <option value="-1">전체</option>
        ) : (
          <></>
        )}
      
      {props.floors.map((floor: Floor, floorIdx: number) => (
        <option value={floorIdx} label={`${floor.name} 층`} />
      ))}
    </select>
  );
};

export default Groupfloor;
