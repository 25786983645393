import React, { useState, useContext, useEffect } from 'react';
import queryString from 'query-string';
import { MessageContext } from '../../routes';
import ApplicantForm from '../../components/organismas/form/applicant';
import GuestForm from '../../components/organismas/form/guest';
import ConfirmBtn from '../../components/atoms/button/confirm';
import DefaultTemplete from '../../components/templetes/default';
import { BOOKING_STATUS_CODE, DOCUMENT_FILE_VISIBLE_TYPE_CODE, FILE_UPLOAD_CODE, LODGE_STATUS, LODGE_STATUS_CODE, ROOM_TYPE, ROOM_TYPE_CODE, STATUS_CODE, WORK_SITE } from '../../constant';
import { lodge } from '../../apis';
import intl from 'react-intl-universal';
import _ from 'lodash';
import './index.scss';
import FileFrm from '../../components/organismas/form/fileFrm';
import { getWorkSitePhoneNum } from '../../utils/commons';
import { SHOW_MODAL } from '../../store/modules/modal';

const LodgeApplicantDetailPage = (props: any) => {
  const qs: any = queryString.parse(window.location.search);
  const originGuests = _.cloneDeep(props.history.location.state?.guests || []);
  const [guests, setGuests] = useState(
    _.cloneDeep(props.history.location.state?.guests || []),
  );
  const [files, setFiles] = useState(
    _.cloneDeep(props.history.location.state.files || [])
  )
  const [originFiles, setOriginFiles] = useState(_.cloneDeep(props.history.location.state.files || []));
  const [isUpdateMode, setIsUpdateMode] = useState<boolean>(!!qs.isUpdateMode);
  const [isReapply, setIsReapply] = useState<boolean>(!!qs.isReapply);
  const [lodgeId, setLodgeId] = useState<number>(0);
  const [isGroup, setIsGroup] = useState<boolean>(props.history.location.state.room_type === ROOM_TYPE_CODE.business_room_group? true : false);
  const [mealTicket, setMealTicket] = useState<number>(props.history.location.state.meal_ticket || 0); //식권
  const [isClean, setIsClean] = useState<boolean>(false);
  const { info } = useContext(MessageContext);
  const [room_status, setRoomStatus] = useState<number>(0);

  useEffect(() => {
    (async () => {
      try {
        const data = await lodge.getBookingsByLodgeDetail(props.history.location.state.id);
        setTimeout(function(){
          setLodgeId(data.data.id);
          setRoomStatus(data.data.status);
        }, 10)
      }catch ({ status, data }) {
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }
    })();
  }, []);

  // 취소
  const onCancelHandler = async (e: any) => {
    const status = getCheckedStatus();
    const filtered = status.filter(
      (s: any, idx: number) =>
        // s !== STATUS_CODE.booking_requested &&
        // s !== STATUS_CODE.booking_completed &&
        // s !== STATUS_CODE.checkin_pending,
        s == LODGE_STATUS_CODE.opened &&
        s == LODGE_STATUS_CODE.waiting
    );

    console.log('room status : ' + room_status);
    console.log(props.history.location.state.room_type);
    if((room_status == BOOKING_STATUS_CODE.roomOpen) || 
    (props.history.location.state.room_type == ROOM_TYPE_CODE.family_room && room_status == BOOKING_STATUS_CODE.approved)) {
      info('배방이 완료되어 취소가 불가능합니다.');
      return; 
    }

    // if (filtered.length) {
    //   info('입숙취소 할 수 없는 상태입니다.');
    //   return; 
    // }

    //const ids = guests.map((guest: any, guestIdx: number) => guest.id);
    //const lodgeId: number = props.history.location.state.id;
    
    try {
        const { data } = await lodge.cancel(lodgeId);
        info('신청이 취소 되었습니다.');
        props.history.replace('/mypage/applicant')
      } catch (err) {
        //console.log(err);   

        const {status, data } = err;

        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
  
        info('신청취소 중 문제가 발생했습니다.');
        return;
      } 
    };          

    const onChangeSave = async (e: any) => {
      let payloadFiles = {};
      if(props.history.location.state.room_type === ROOM_TYPE_CODE.business_room_group){
        if(!files || files.length <=0) {
          payloadFiles = [{ file: props.history.location.state.files[0].file.id, document: props.history.location.state.files[0].document.id }];
        }else {
          payloadFiles = [{ file: files.file, document: files.document }];
        }
      }else {
        payloadFiles = files.filter((file: any) => file.id > 0).map((file: any) => ({ file: file.id, document: file.document }));
      }
      let bookingId = props.history.location.state.id;
      let meal_ticket = mealTicket;

    try {
      const { data } = await lodge.setBookingsByLodge(
        bookingId,
        { meal_ticket: meal_ticket, guests, update_files: payloadFiles },
      );
      //if (isReapply ) {
      if(props.history.location.state.status === BOOKING_STATUS_CODE.rejected) {
        const { data: reapply } = await lodge.lodgeReapply(bookingId, {});

        // 재등록 문자 발송
        let smsPayload: any = {
          cellphone : props.history.location.state.applicant_cellphone,
          message: ROOM_TYPE[props.history.location.state.room_type] + ' 입숙서류 재등록이 완료되었습니다.'
                  +'\n신청진행 확인은 "마이페이지>>신청관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 '+getWorkSitePhoneNum(props.history.location.state.room_work_site, ROOM_TYPE_CODE.company_room)
        }
        const { data: sms } = await lodge.sendSMS(smsPayload);
      }
      info('수정이 완료되었습니다.');
      setIsUpdateMode(false);

      props.history.push('/mypage/applicant')
    } catch (err) {
      const {status, data} = err;

      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info('이용자 정보 변경중 문제가 발생했습니다.');
      setIsUpdateMode(false);
      return;
    }
  };

  // 수정모드로 변경
  const onChangeUpdateModeHandler = (e: any) => {
    if (
      //guests[0].status !== STATUS_CODE.booking_requested
      //&& guests[0].status !== STATUS_CODE.booking_completed
      //&& guests[0].status !== STATUS_CODE.checkin_pending
      props.history.location.state.status !== BOOKING_STATUS_CODE.applied
        && props.history.location.state.status !== BOOKING_STATUS_CODE.rejected
    ) {

      info(`수정이 불가능합니다.`);
      return
    }
    setIsUpdateMode(true)
  }

  const getCheckedStatus = () => 
    guests.map(
      (guest: any, checkedsIdx: number) =>
        guest.status,
    );
  
  // 신청 취소 가능 상태값
  const showCancle = (bookinStatus: number, guestStatus: number) => {
    let chk = false;

    if(bookinStatus !== BOOKING_STATUS_CODE.approved && bookinStatus !== BOOKING_STATUS_CODE.canceled 
        && bookinStatus !== BOOKING_STATUS_CODE.terminated && bookinStatus !== BOOKING_STATUS_CODE.approved) {
          chk = true;
    }else if(bookinStatus === BOOKING_STATUS_CODE.approved && 
        (guestStatus === LODGE_STATUS_CODE.waiting || guestStatus === LODGE_STATUS_CODE.opened )) {
          chk = true;
    }

    return chk;
  }

  if (!props.history.location.state?.guests) {
    props.history.push('/mypage/applicant');
    return <></>
  }

  return (
    <DefaultTemplete
      id="wrap"
      className="wrap_mypage_manage"
      leftInfo={
        <>
          <h2>신청관리</h2>
          <p className="desc">숙소이용신청 내역확인 및 수정을 진행하실 수 있습니다</p>
          <div className="bg_img request"></div>
        </>
      }
      {...props}
    >
      {/* 기본정보 및 신청자 정보 */}
      <ApplicantForm applicant={props.history.location.state} />
      
      {/* 이용자 정보 */}
      <GuestForm
        isUpdateMode={isUpdateMode}
        guests={guests}
        setGuests={setGuests}
        roomType={props.history.location.state?.room_type || 1}
      />
      <br></br>
      {!isUpdateMode ? 
        props.history.location.state.room_type === ROOM_TYPE_CODE.business_room_group || props.history.location.state.room_type === ROOM_TYPE_CODE.business_room? 
        <div className="upload_area mt0">
            <label htmlFor="input_copy">{intl.get("식권")}</label>
            <div className="wrap_file p0">
                <input id="input_copy" 
                  className="upload_name form_control" 
                  placeholder={intl.get("필요한 전체 식권 수량을 입력 하세요")}
                  value={props.history.location.state.meal_ticket}
                  onChange={e =>
                    setMealTicket(parseInt(e.target.value) || 0)
                  }
                  disabled={true}
                />
            </div>
        </div>
       : (
        <></>
      )
       : (
        props.history.location.state.room_type === ROOM_TYPE_CODE.business_room_group || props.history.location.state.room_type === ROOM_TYPE_CODE.business_room? 
        <div className="upload_area mt0">
          <label htmlFor="input_copy">{intl.get("식권")}</label>
          <div className="wrap_file p0">
              <input id="text" 
                className="upload_name form_control" 
                placeholder={intl.get("필요한 전체 식권 수량을 입력 하세요")}
                value={mealTicket}
                onChange={e =>
                  setMealTicket(parseInt(e.target.value) || 0)
                }
              />
          </div>
        </div>
        : <></>
      )}

      {/* 파일정보 */}
      {guests && guests.length > 0 ? (
        <FileFrm
          files={files}
          originFiles={_.cloneDeep(props.history.location.state?.files)}
          isUpdateMode={isUpdateMode}
          onChange={(changedFiles: any) => setFiles(changedFiles)}
          isGroup={isGroup}
          isClean={isClean}
          setIsClean={() => setIsClean(false)}
          workSite={props.history.location.state.room_work_site}
          guestType={guests[0].guest_type}
          roomType={props.history.location.state.room_type}
          category={FILE_UPLOAD_CODE.apply}
          visibleType={DOCUMENT_FILE_VISIBLE_TYPE_CODE.apply}
          employeenumber = {guests[0].guest_employee_number}
        />
      ) :''}

      <div className="mb_view">
        {!isUpdateMode ? (
          <button className="btn btn_lg btn_gray" onClick={() => props.history.goBack()}>목록</button>
        ) : (
            <ConfirmBtn
              className="btn btn_lg btn_gray"
              action={() => {setGuests(originGuests); setFiles(originFiles); setIsClean(true)}}
              label="초기화"
              title=""
              msg="정말 초기화를 진행하시겠습니까?"
            />
          )}
      </div>

      <div className=
        {props.history.location.state.status && props.history.location.state.status === BOOKING_STATUS_CODE.applied ? "submit pt40" :
          props.history.location.state.status && props.history.location.state.status === BOOKING_STATUS_CODE.rejected ? "submit pt40" :
            "submit btn_length_1 pt40" }>
        <div className="fl">
          {!isUpdateMode ? (
            <button className="btn btn_lg btn_gray" onClick={() => props.history.push('/mypage/applicant')}>목록</button>
          ) : (
              <ConfirmBtn
                className="btn btn_lg btn_gray"
                action={() => {setGuests(originGuests); setFiles(originFiles); setIsClean(true)}}
                label="초기화"
                title=""
                msg="정말 초기화를 진행하시겠습니까?"
              />
            )}
        </div>
        <div className="fr">
          {!isUpdateMode ? (
            <>
              {props.history.location.state.status && props.history.location.state.status === BOOKING_STATUS_CODE.applied ? 
                (
                  <ConfirmBtn
                    className="btn btn_lg btn_outline"
                    action={onChangeUpdateModeHandler}
                    label="수정"
                    title=""
                    msg="정말 수정을 진행하시겠습니까?"
                  />
                ) : ''}
              {props.history.location.state.status && props.history.location.state.status === BOOKING_STATUS_CODE.rejected ? (
                <ConfirmBtn
                    className="btn btn_lg btn_outline"
                    action={onChangeUpdateModeHandler}
                    label="재등록"
                    title=""
                    msg="재등록 하시겠습니까?"
                  />
              ) : ''}
              { props.history.location.state.guests && props.history.location.state.guests.length > 0 && showCancle(props.history.location.state.status, props.history.location.state.guests[0].status) ? (
                  <ConfirmBtn
                    className="btn btn_lg btn_darkblue"
                    action={onCancelHandler}
                    label="신청취소"
                    title=""
                    msg="신청하신 숙소 예약을 취소하시겠습니까?"
                  />
              ): ''}
            </>
          ) : (
              <>
                <ConfirmBtn
                  className="btn btn_lg btn_outline"
                  action={() => {
                    setIsUpdateMode(false);
                    setGuests(_.cloneDeep(props.history.location.state?.guests) || [])
                  }}
                  label="수정취소"
                  title=""
                  msg="변경된 내용이 있습니다. 수정을 취소하시겠습니까?"
                />
                {props.history.location.state.status === BOOKING_STATUS_CODE.rejected ? (
                  <ConfirmBtn
                      className="btn btn_lg btn_darkblue"
                      action={onChangeSave}
                      label="재등록"
                      title=""
                      msg="재등록 하시겠습니까?"
                    />
                ) : (
                  <ConfirmBtn
                    className="btn btn_lg btn_darkblue"
                    action={onChangeSave}
                    label="저장"
                    title=""
                    msg="수정을 반영하시겠습니까?"
                  />
                )}
              </>
            )}
        </div>
      </div>
    </DefaultTemplete>
  );
};

export default LodgeApplicantDetailPage;
