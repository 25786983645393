import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { MessageContext } from '../../routes';

import intl from 'react-intl-universal';
import DefaultTemplate from '../../components/templetes/default';
import StepNav from '../../components/molecules/reservationStepNav';

import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';

import { getTodayDate, getNextDay } from '../../utils/date';
import { ROOM_TYPE_CODE, WORK_SITE } from '../../constant';

import { lodge } from '../../apis';
import { observer } from 'mobx-react';
import { useStores } from '../../store/mobx';
import { getWorkSitePhoneNum } from '../../utils/commons';
import { SHOW_MODAL } from '../../store/modules/modal';


const BusinessRoomSingleReservationPage = observer((props: any) => {
  const {reservationStore: store} = useStores();
  const { info } = useContext(MessageContext);
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user,
  }));

  const [today, setToday] = useState(getTodayDate());
  const [step, setStep] = useState<number>(1);

  const [building, setBuilding] = useState({});
  const [room, setRoom] = useState<any>({});

  const [roomType, setRoomType] = useState(ROOM_TYPE_CODE.business_room); // 출장자 숙소(1), 영빈관(2)
  const [workSite, setWorkSite] = useState(-1); // 울산(1), 남양(2),  아산(3), 전주(4)

  const [dateAfter, setDateAfter] = useState<string>(today[0]); // dateAfter ~ dateBefore
  const [dateBefore, setDateBefore] = useState<string>(getNextDay(today[0])[0]);

  const [users, setUsers] = useState<any>([]); // 이용자가 단체인 것까지 고려하여 리스트로 관리한다

  //console.log(ROOM_TYPE_CODE.business_room)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    const qs: any = queryString.parse(window.location.search);
    // setRoomType(parseInt(qs.roomType || 1));

    if (userInfo.worksite >= 0) {
      setWorkSite(userInfo.worksite);
    } else {
      setWorkSite(parseInt(qs.workSite || 2));
    }
  }, [window.location.search, userInfo.worksite]);

  const onSubmitHandler = async (e: any) => {
    const payload = {
      work_site: workSite,
      room: room.id,
      stay_after: dateAfter.replace(/\./gi, '-'),
      stay_before: dateBefore.replace(/\./gi, '-'),
      // mealTicket,

      // 실제 이용자
      // guest: { ...users[0] },
      // 신청자 정보는 서버에서 accessToken을 가지고 얻을 수 있기 때문에 전달할 필요없음.

      guest_type: users[0].type,
      guest_cellphone: users[0].cellphone,
      guest_department: users[0].department? users[0].department: '',
      guest_employee_number: users[0].employee_number? users[0].employee_number: '',
      guest_gender: users[0].gender,
      guest_name: users[0].name,
      guest_company: users[0].company? users[0].company : '현대자동차',
      guest_car_number1 : users[0].car_number1 ? users[0].car_number1 : '',
      meal_ticket: users[0].meal_ticket_request_count,
      privacyAgree: users[0].privacyAgree,
      personalAgree: users[0].personalAgree
      
    };

    try {
      await lodge.reservationByBusinessRoom(payload);
      store.roomInit();
      // 예약 완료 문자 발송
      let smsPayload: any = {
        cellphone : users[0].cellphone,
        message: '입숙신청되었습니다. \n신청정보 - 숙소유형 : 출장자숙소(개인) \n- 이용 사업장 : '+ WORK_SITE[workSite] +'  \n- 이용 기간 : ' + dateAfter.replace(/\./gi, '-') + ' - ' + dateBefore.replace(/\./gi, '-')
                +'\n신청진행 확인은 "마이페이지>>신청관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 '+getWorkSitePhoneNum(workSite, ROOM_TYPE_CODE.business_room)
      }
      const { data: sms } = await lodge.sendSMS(smsPayload);
      setStep(5);
    } catch ({ status, data }) {
      // console.log(status)
      // console.log(data)
      // console.log(users)
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else if (data?.code === "duplicate_guest") {
        info(intl.get('이미 이용중인 숙소가 있습니다.\n 퇴숙완료 후 신청 가능합니다.\n 신청관리 화면에서 확인해주세요.'));
        return;
      }else if (data?.code === "gender_not_match") {
        info(intl.get('숙소 성별 유형이 맞지 않습니다.'));
        return;
      }else if(data?.code){
        info(data?.code);
        return;
      }else if (data.guest) {
        if (data.guest.employee_number && data.guest.employee_number.length) {
          info(intl.get('사번이 올바르지 않습니다.'));
          return;
        } else if (data.guest.cellphone && data.guest.cellphone.length) {
          info(intl.get('올바른 핸드폰 번호를 입력해 주세요.'));
          return;
        }
        info(intl.get('게스트정보가 올바르지 않습니다.'));
        return;
      }

      info(intl.get('예약 중 문제발생'));
      return;
    }
  };

  window.addEventListener('popstate', function(event) { 
    props.history.push('/home');
  });

  
  return (
    <DefaultTemplate
      {...props}
      id="wrap"
      className={`wrap_request request_step${step}`}
      leftInfo={
        <>
          <h2>{intl.get("출장자숙소(개인)")}</h2>
          <StepNav.BusinessSingleNav step={step} />
        </>
      }
    >
      {step === 1 ? (
        <Step1
          roomType={roomType}
          setWorkSite={setWorkSite}
          workSite={workSite}
          dateAfter={dateAfter}
          dateBefore={dateBefore}
          building={building}
          nextStep={() => setStep(2)}
          room={room}
          setUsers={setUsers}
          history={props.history}
        />
      ) : step === 2 ? (
        <Step2
          today={today[0]}
          user={users[0]}
          roomType={roomType}
          workSite={workSite}
          dateAfter={dateAfter}
          dateBefore={dateBefore}
          setRoomType={setRoomType}
          setWorkSite={setWorkSite}
          setDateAfter={setDateAfter}
          setDateBefore={setDateBefore}
          nextStep={() => setStep(3)}
          prevStep={() => setStep(1)}
          building={building}
          room={room}
          history={props.history}
        />
      ) : step === 3 ? (
        <Step3
          user={users[0]}
          building={building}
          room={room}
          roomType={roomType}
          workSite={workSite}
          dateAfter={dateAfter}
          dateBefore={dateBefore}
          nextStep={() => setStep(4)}
          prevStep={() => setStep(2)}
          setBuilding={setBuilding}
          setRoom={setRoom}
          history={props.history}
          gender={userInfo.gender}
        />
      ) : step === 4 ? (
        <Step4
          today={today}
          user={users[0]}
          roomType={roomType}
          workSite={workSite}
          dateAfter={dateAfter}
          dateBefore={dateBefore}
          building={building}
          room={room}
          guest={users[0]}
          prevStep={() => setStep(3)}
          onSubmitHandler={onSubmitHandler}
          history={props.history}
        />
      ) : step === 5 ? (
        <Step5
          roomType={roomType}
          workSite={workSite}
          dateAfter={dateAfter}
          dateBefore={dateBefore}
          building={building}
          room={room}
          users={users}
          history={props.history}
        />
      ) : (
                  <></>
                )}
    </DefaultTemplate>
  );
});

export default BusinessRoomSingleReservationPage;
