import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ConfirmBtn from '../../components/atoms/button/confirm';

import { DownloadButton } from '../../components/molecules/download';
import CollapseView from '../../components/molecules/collapse';

import { GENDER } from '../../constant';

import './step4.scss';

// 영빈관은 입숙 서약서가 없음
const Step3 = (props: any) => {
  const { userInfo = {}, workplaceMap = {} } = useSelector((state: any) => ({
    userInfo: state.user,
    workplaceMap: state.base.workplaceMap
  }));

  const [guideFile, setGuideFile] = useState<string>("");

  const next = () => {
    props.onSubmitHandler()
  };

  return (
    <>
      {/* <ul className="lst_thumb_request">
        <li>
          <strong>숙소유형</strong>
          <span>영빈관</span>
        </li>
        <li>
          <strong>이용사업장</strong>
          <span>{workplaceMap[props.workSite]?.name}</span>
        </li>
        <li>
          <strong>입숙예정일</strong>
          <span>{props.dateAfter}</span>
        </li>
        <li>
          <strong>퇴숙예정일</strong>
          <span>{props.dateBefore}</span>
        </li>
      </ul> */}

      <div className="title_contents">
        <h3 className="title">신청자 정보</h3>
      </div>

      <div className="table_design_list col_width6">
        <div id="table" className="table">
          <div className="tbody">
            <div className="tr">
              <div className="th">성명</div>
              <div className="td">{userInfo.username}</div>
              <div className="th">사번 (아이디)</div>
              <div className="td">{userInfo.employeenumber}</div>
              <div className="th">성별</div>
              <div className="td">{GENDER[userInfo.gender]}</div>
            </div>
            <div className="tr">
              <div className="th">휴대폰번호</div>
              <div className="td">{userInfo.phone}</div>
              <div className="th">소속부서</div>
              <div className="td">{userInfo.department}</div>
              <div className="th"></div>
              <div className="td"></div>
            </div>
          </div>
        </div>
      </div>


      <div className="title_contents">
        <h3 className="title">숙소 이용안내</h3>
        <div className="right_area">
          {guideFile && (
            <DownloadButton
              src={guideFile}
              value="파일 다운로드"
              pc
            />
          )}
        </div>
      </div>

      <CollapseView
        workSite={props.workSite}
        roomType={props.roomType}
        onChangeGuideFile={setGuideFile}
      />

      <div className="table_design_list col_width6">
        <div id="table" className="table">
          <div className="tbody">
            <div className="tr">
              <div className="th">성명</div>
              <div className="td">{props.user.name}</div>
              <div className="th">사번 (아이디)</div>
              <div className="td">{props.guest.type !== 3 ? props.user.employee_number : ""}</div>
              <div className="th">성별</div>
              <div className="td">{GENDER[props.user.gender]}</div>
            </div>
            <div className="tr">
              <div className="th">휴대폰번호</div>
              <div className="td">{props.user.cellphone}</div>
              <div className="th">소속부서</div>
              <div className="td">{props.user.department}</div>
              <div className="th"></div>
              <div className="td"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="submit">
        <button
          className="btn btn_lg btn_outline"
          onClick={() => props.prevStep()}
        >
          이전
        </button>
        <ConfirmBtn
          className="btn btn_lg btn_darkblue"
          action={() => next()}
          label="완료"
          title=""
          msg="예약을 진행하시겠습니까?"
        />
      </div>
    </>
  );
};

export default Step3;
