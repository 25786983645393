import React, {useContext} from 'react';
import { isMobile } from 'react-device-detect';
import { MessageContext, MessageImpl } from '../../../routes';
import { core } from '../../../apis';
import { SHOW_MODAL } from '../../../store/modules/modal';

interface Porps {
  value: string
  src: string
  
  onlySrc?: boolean
  outer?: boolean // 선만 표시할지 말지
  small?: boolean
  large?: boolean
  pc?: boolean
}

//다운로드 버튼
const Button = (props: Porps) => {
  let classNames = ['btn', 'btn_outline', 'btn_download']
  let src = [process.env.REACT_APP_MEDIA]
  const { info, warning, error }: MessageImpl = useContext(MessageContext);

  props.outer && classNames.push('btn_outline');

  if (props.onlySrc) {
    src[0] = props.src
  } else {
    src.push(props.src)
  }

  if (props.small) {
    classNames.push('btn_sm');
  }

  if (props.pc) {
    classNames.push('pc');
  }

  const isIncludesAPIS = (url: string) => {
    const apis = ['apis.'];
    return apis.includes(url);
  }

  // 파일의 확장자를 가져오는 함수
  const getFileExtension = (filename: string) => {
    const parts = filename.split('.');
    if (parts.length > 1) {
      return parts.pop()!.toLowerCase(); // 파일 이름이 마침표를 포함하는 경우에만 확장자 추출
    } else {
      return ''; // 마침표가 없는 경우 빈 문자열 반환
    }
  };

  const downloadFile = async (url: string, filename: string) => {
    const token = window.localStorage.getItem('accessToken');
    console.log('url : ' + url);
    console.log('filename : ' + filename);

    if (!url) {
      console.error('url 경로가 없습니다.');
      return;
    }

    if(!filename || filename.length == 0) {
      const name = url.split("/");
      filename = name[name.length - 1];
    }
  
    const unifiedUrl = url.replace(/\\/g, '/');
    const parts = unifiedUrl.split('/');
    const desiredParts = parts.slice(2).join('\\');

    // 파일의 확장자가 이미지나 PDF인지 확인하는 함수
    const isImageOrPDF = (extension: string) => {
      const supportedExtensions = ['gif', 'jpg', 'jpeg', 'png', 'pdf'];
      return supportedExtensions.includes(extension);
    };

    const fileExtension = getFileExtension(filename);
    const isImageOrPDFFile = isImageOrPDF(fileExtension);
    console.log(desiredParts);
    //fileDownload
    try {
      let payload: any = {
        filename: filename,
        url : desiredParts,
      };

      let { data } = await core.fileDownload(payload);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      //info("협조전 등록이 완료되었습니다.");
    } catch ({ data, status }) {
      console.log(data, status);
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }
      info("파일 다운로드 중 문제가 발생했습니다");
      return;
    }
  };

  return (
    <button className="btn_sm btn_darkblue btn_download"
    onClick={() => downloadFile(`${props.src}`, '')}
    >
      {props.value}
    </button>
  //  {<a 
  //    href={src.join('/')} download
  //    target={`${props.src}`.split('.').pop() 
  //        && ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(`${props.src}`.split('.').pop() || '' )
  //        && !isMobile ? '_blank' : ''}
  //  >
  //    <button className={classNames.join(' ')}>
  //      {props.value}
  //      {/* <i className="ico_download ml10"></i> */}
  //    </button>
  //</a>
  );
};

export default Button;
