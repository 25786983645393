/*
  사용자에서 사용하는 모든 코드값 정리
*/

export const ROOM_TYPE: any = {
  0: '결정되지 않음',
  1: 'R&D STAY',
  2: '기숙사',
  3: '사택',
  4: '영빈관',
  5: '출장자숙소(개인)',
  6: '출장자숙소(단체)',
  7: '기타',
};

export const ROOM_TYPE_CODE: any = {
  family_room: 1,
  dormitory_room: 2,
  company_room: 3,
  vip_room: 4,
  business_room: 5,
  business_room_group: 6,
};

export const WORK_SITE: any = {
  // 1: '울산',
  // 2: '남양연구소',
  // 3: '아산',
  // 4: '전주',
  1: '울산공장',
  2: '남양연구소',
  3: '전주공장',
  4: '아산공장',
  5: '마북',
  6: '의왕',
  7: '기타'
}

export const WORK_SITE_CODE: any = {
  울산: 1,
  울산공장: 1,
  남양: 2,
  남양연구소: 2,
  전주공장: 3,
  아산공장: 4,
  마북: 5,
  의왕: 6,
  기타: 7
};

export const GENDER: { [key: number]: string } = {
  1: '남자',
  2: '여자',
};
export const GENDER_CODE = {
  male: 1,
  female: 2,
};

export const BOOKING_TYPE: { [key: number]: string } = {
  1: '개인',
  2: '단체',
};

export const VERIFY_CODE: { [key: string]: number } = {
  phone: 1,
  email: 2,
};

export const LANG: { [key: number]: string } = {
  1: 'kr',
  2: 'en',
  3: 'jp',
};
export const LANG_CODE: { [key: string]: number } = {
  kr: 1,
  en: 2,
  jp: 3,
};

export const STATUS: { [key: number]: string } = {
  1: '예약요청',
  2: '예약승인',
  3: '예약취소',
  4: '입숙대기',
  5: '입숙완료',
  6: '퇴숙완료',
  7: '반려(재요청)',
  8: '반려(거절)',
  9: '퇴숙신청'
};
export const STATUS_CODE: { [key: string]: number } = {
  booking_requested: 1, // 예약 요청
  booking_completed: 2, // 예약 승인
  booking_canceled: 3, // 예약 취소
  checkin_pending: 4, // 입숙 대기
  checkin_completed: 5, // 입숙 완료
  checkout_completed: 6, // 퇴숙 완료
  booking_rejected: 7, // 반려 - 재요청
  booking_terminated: 8, // 반려 - 거절
  checkout_apply: 9 // 퇴숙신청
};

// 신청자 status
export const BOOKING_STATUS: { [key: number]: string } = {
  1: '승인 대기',
  2: '예약 완료',
  3: '신청 취소',
  4: '반려(재요청)',
  5: '승인 거절',
  6: '승인 완료',    // 배방오픈 / 입숙일 입력 요청 
  7: '예약대기',    // 입숙일 입력 후 관리자 승인 기다리는 상태
  8: '입숙일변경요청',
  9: '입숙보류',
  10: '서류승인완료',
  12: '최종승인대기',     // 증빙서류 제출 후 최종승인대기 상태
  13: '증빙서류재요청'
};
export const BOOKING_STATUS_CODE: { [key: string]: number } = {
  applied: 1, // 신청완료
  approved: 2, // 승인
  canceled: 3, // 취소
  rejected: 4, // 반려
  terminated: 5, // 거절
  roomOpen: 6,    // 배방오픈
  stayAfter: 7,   // 입숙일입력
  stayAfterTerminated: 8,    // 입숙일 변경 요청
  stayHold: 9,          // 입숙보류
  documentApproved: 10,  // 서류승인
  documentSubmit: 12,   // 서류제출
  documentReject: 13    // 서류재요청
};

// 이용자 status
export const LODGE_STATUS: { [key: number]: string } = {
  1: '신청중',
  2: '대기',
  3: '입숙',
  4: '퇴숙',
  5: '이용안함'
};
export const LODGE_STATUS_CODE: { [key: string]: number } = {
  opened: 1, // 신청중
  waiting: 2, // 대기
  checkin: 3, // 입숙
  checkout: 4, // 퇴숙
  terminated: 5, // 이용안함
};

export const FILE: { [key: number]: string } = {
  1: '협조전',
};
export const CHECKOUT_STATUS_TYPE: { [key: number]: string } = {
  1: "-" ,
  2: "퇴숙요청",
  3: "퇴숙승인",
  4: "퇴숙반려",
  5: "퇴숙거절",
  6: "퇴숙완료",
}
export const CHECKOUT_STATUS_TYPE_CODE: { [key: string]: number }  = {
  default : 1,
  applied : 2,
  accepted : 3,
  canceled : 4,
  rejected : 5,
  completed : 6,
}

export const FILE_CODE: { [key: string]: number } = {
  RFC: 1,
  EXCEL: 2,
};

export const VIP_USABLE_BY_RANKS = [
  '명예회장',
  '회장',
  '수석부회장',
  '부회장',
  '사장',
  '수석부사장',
  '부사장',
  '전무',
  '상무',
  '수석연구위원',
  '연구위원',
];

export const VIP_USABLE_BY_POSITIONS_JEONJU = [
  '회장',
  '수석부회장',
  '부회장',
  '사장',
  '부사장',
  '수석부사장',
  '총괄본부장',
  '본부장',
  '부본부장',
  '사업부장',
  '부사업부장',
  '담당중역',
  '센터장',
  '부서장',
  '실장',
]

export const VIP_USABLE_BY_POSITIONS_ASAN = [
  '회장',
  '수석부회장',
  '부회장',
  '사장',
  '부사장',
  '수석부사장',
  '총괄본부장',
  '본부장',
  '부본부장',
  '사업부장',
  '부사업부장',
  '담당중역',
  '실장',
]

export const GUEST_TYPE: { [key: number]: string } = {
  1: '사내 임직원',
  2: '계열사 임직원',
  3: '외부인',
};

export const GUEST_TYPE_CODE: { [key: string]: number } = {
  inner_employer: 1,
  similar_employer: 2,
  outer_employer: 3,
};

export const FAQ_CATEGORY: { [key: number]: string } = {
  1: '입숙신청',
  2: '이숙',
  3: '퇴숙',
  4: '이용방법',
  5: 'VoC',
  6: '가입탈퇴',
  7: '기타',
};

export const EXHIBIT_STATUS: { [key: string]: string } = {
  1: '미등록',
  2: '확인대기',
  3: '승인완료',
  4: '반려',
  5: '재등록요청',
};

export const NOTICE_TYPE: { [key: number]: string } = {
  1: '정기공지',
  2: '비정기공지',
  3: '개별공지',
};

export const NOTICE_TO_CASE: { [key: number]: string } = {
  1: 'case6',
  2: 'case3',
  3: 'case5',
};

export const FILE_UPLOAD_CODE: { [key: string]: number } = {
  apply: 1, // 신청시
  assign: 2, // 배방시
  checkout: 3, // 퇴숙시
};

// 사업장 - 방타입 - 체크리스트 항목
export const CHECKOUT_CHECKLIST: any = {
  남양연구소: {
    [ROOM_TYPE_CODE.dormitory_room]: [
      { name: '남양-기숙사-책상', isChecked: false },
      { name: '남양-기숙사-의자', isChecked: false },
      { name: '남양-기숙사-식탁', isChecked: false },
      { name: '남양-기숙사-컴퓨터', isChecked: false },
      { name: '남양-기숙사-TV', isChecked: false },
      { name: '남양-기숙사-거울', isChecked: false },
    ],
    [ROOM_TYPE_CODE.company_room]: [
      { name: '남양-사택-책상', isChecked: false },
      { name: '남양-사택-의자', isChecked: false },
      { name: '남양-사택-식탁', isChecked: false },
      { name: '남양-사택-컴퓨터', isChecked: false },
      { name: '남양-사택-TV', isChecked: false },
      { name: '남양-사택-거울', isChecked: false },
    ],
  },
  울산공장: {
    [ROOM_TYPE_CODE.dormitory_room]: [
      { name: '울산-기숙사-책상', isChecked: false },
      { name: '울산-기숙사-의자', isChecked: false },
      { name: '울산-기숙사-식탁', isChecked: false },
      { name: '울산-기숙사-컴퓨터', isChecked: false },
      { name: '울산-기숙사-TV', isChecked: false },
      { name: '울산-기숙사-거울', isChecked: false },
    ],
    [ROOM_TYPE_CODE.company_room]: [
      { name: '울산-사택-책상', isChecked: false },
      { name: '울산-사택-의자', isChecked: false },
      { name: '울산-사택-식탁', isChecked: false },
      { name: '울산-사택-컴퓨터', isChecked: false },
      { name: '울산-사택-TV', isChecked: false },
      { name: '울산-사택-거울', isChecked: false },
    ],
  },
};

export const SEARCH_TYPE: { [key: number]: string } = {
  1: '제목',
  2: '내용',
  3: '작성자',
};

export const VOC_STATUS_TYPES: { [key: number]: string } = {
  1: 'VoC접수',
  2: '처리중',
  3: '처리완료',
};

export const MEMBER_DEL_CATEGORY: {[key: number]: string} = {
  1: '서비스 이용 안함',
  2: '이용 불편',
  3: '숙소 이용기간 만료',
  4: '퇴사',
  5: '기타'
}

export const DOCUMENT_FILE_VISIBLE_TYPE_CODE: { [key: string]: number } = {
  apply: 1,   // 신청
  assign: 2,    // 배방
  checkout: 3,    // 퇴숙
};

// 이숙 category 코드
export const LODGE_MOVE_CODE: { [key: string]: number} = {
  alway: 1, // 상시
  fixed: 2, // 정기
  one: 3    // 1:1
}

// 이숙 상태 코드
export const MOVE_STATUS: { [key: number]: string} = {
  1 : '신청',   // 신청
  2 : '승인',   // 승인
  3 : '완료',   // 완료
  4 : '거절',    // 거절
  5 : '취소',     // 취소
  6 : '상대방승인',
  7 : '상대방거절'
}

// 이숙 상태 코드 값
export const MOVE_STATUS_CODE: { [key: string]: number} = {
  apply: 1,     // 신청
  approve: 2,   // 승인
  complete: 3,  // 완료
  reject: 4,    // 거절
  cancel: 5,     // 취소
  resApprove : 6, // 상대방승인
  resReject: 7    // 상대방거절
}

// 퇴숙 상태 코드
export const CHECKOUT_STATUS: { [key: number]: string} = {
  1 : '신청',     // 신청
  2 : '승인',     // 승인
  3 : '취소',     // 취소
  4 : '반려',     // 반려 (재요청)
  5 : '거절',     // 거절
}

// 퇴숙 상태 코드 값
export const CHECKOUT_STATUS_CODE: { [key: string]: number} = {
  apply: 1,       // 신청
  approve: 2,     // 승인
  cancel: 3,      // 취소
  terminate: 4,   // 반려 (재요청)
  reject: 5      // 거절
}

//실제근무 코드 값
export const JOB_CODE: {[key: string]: string} ={
  A: 'A조', //A조
  B: 'B조'  //B조
}

// 입숙보류, 이숙 종류 코드 (1년에 한번인지 체크)
export const DELAY_COUNT_CODE: { [key: string]: number} = {
  delay: 1,   // 입숙보류
  normal: 2,  // 상시이숙
  regular: 3, // 정기이숙
  oneOne: 4   // 1:1이숙
}

export const VOC_CATEGORY = [
  {
    name: '숙소내',
    child: [
      {
        name: '생활',
        child: [
          { name: '쓰레기', child: [] },
          { name: '흡연', child: [] },
          { name: '총간소음', child: [] },
          { name: '취사', child: [] },
          { name: '정리정돈', child: [] },
          { name: '기타', child: [] },
        ],
      },
      {
        name: '비품(개인)',
        child: [
          { name: '침대,옷장등', child: [] },
        ],
      },
      {
        name: '비품(공용)',
        child: [
          { name: '세탁기', child: [] },
          { name: '에어컨', child: [] },
          { name: '정수기', child: [] },
          { name: '기타', child: [] },
        ],
      },
      {
        name: '시설',
        child: [
          { name: '난방', child: [] },
          { name: '수도', child: [] },
          { name: '전기', child: [] },
          { name: '도배,장판,타일', child: [] },
          { name: '기타', child: [] },
        ],
      },
    ],
  },
  {
    name: '숙소외',
    child: [
      {
        name: '생활',
        child: [
          { name: '흡연', child: [] },
          { name: '쓰레기', child: [] },
          { name: '소음', child: [] },
          { name: '헬스장', child: [] },
          { name: 'PC방', child: [] },
          { name: '기타', child: [] },
        ],
      },
      {
        name: '시설',
        child: [
          { name: '엘리베이터', child: [] },
          { name: '쓰레기장', child: [] },
          { name: '운동시설', child: [] },
          { name: '조경', child: [] },
          { name: '주차장', child: [] },
          { name: '기타', child: [] },
        ],
      },
    ],
  },
  {
    name: '사건사고',
    child: [],
  },
  {
    name: '서비스불만',
    child: [],
  },
  {
    name: '기타',
    child: [],
  },
]

// voc 발생장소 : 기타인 경우 select box
export const VOC_ETC_CATEGORY = [
  {
    name: '내부',
    child: [
      { name: '승강기', child: [] },
      { name: '복도', child: [] },
      { name: '로비', child: [] },
      { name: '세탁실', child: [] },
      { name: '조리실', child: [] },
      { name: '휴게실', child: [] },
      { name: '창고', child: [] },
      { name: '주차장(실내)', child: [] },
      { name: '주차장(실외)', child: [] },
      { name: 'PC룸', child: [] },
      { name: '독서실', child: [] },
      { name: '기타', child: [] }
    ]
  },
  {
    name: '외부',
    child: [
      { name: '분리수거장', child: [] },
      { name: '식당', child: [] },
      { name: '놀이터', child: [] },
      { name: '공동화장실', child: [] },
      { name: '어린이집', child: [] },
      { name: '주말농장', child: [] },
      { name: '클린하우스', child: [] },
      { name: '마을회관', child: [] },
      { name: '자전거 보관대', child: [] },
      { name: '정자', child: [] },
      { name: '공원', child: [] },
      { name: '기타', child: [] }
    ]
  },
  {
    name: '운동시설',
    child: [
      { name: '헬스장', child: [] },
      { name: '운동장', child: [] },
      { name: '테니스장', child: [] },
      { name: '풋살장', child: [] },
      { name: '실내체육관', child: [] },
      { name: '기타', child: [] }
    ]
  },
  {
    name: '관리시설',
    child: [
      { name: '관리동', child: [] },
      { name: '전기실', child: [] },
      { name: '기계실', child: [] },
      { name: '저수조', child: [] },
      { name: '승강기실', child: [] },
      { name: '기타', child: [] }
    ]
  },
]