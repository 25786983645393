import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useContext } from 'react';
import intl from 'react-intl-universal';
import { core, voc } from '../../../../apis';
import { VOC_CATEGORY, VOC_STATUS_TYPES } from '../../../../constant';
import { MessageContext, MessageImpl } from '../../../../routes';
import { formatByString } from '../../../../utils/date';
import _ from 'lodash';

import '../index.scss';
import xss from 'xss';
import { SHOW_MODAL } from '../../../../store/modules/modal';
import FileSaver from 'file-saver';

const VocDetail = (props: any) => {
  const [item, setItem] = useState<any>(props.history.location.state || {});
  // const [vocStatusTypes, setVocStatusTypes] = useState<any[]>([]);
  const [newComment, setNewComment] = useState<string>('');
  const [isImgPop, setIsImgPop] = useState<boolean>(false);
  const [imgPath, setImgPath] = useState<string>('');
  const { info, warning, error }: MessageImpl = useContext(MessageContext)

  // useEffect(() => {
  //   const tmpVocStatusTypes: any[] = [];
  //   Object.keys(VOC_STATUS_TYPES).forEach((key: string, index: number) => {
  //     tmpVocStatusTypes.push({
  //       value: key,
  //       label: VOC_STATUS_TYPES[Number(key)],
  //       optionIdx: index + 1,
  //     });
  //   });
  //   setVocStatusTypes(tmpVocStatusTypes);
  // }, []);

  useEffect(() => {
    if (item) {
      const c1: any = VOC_CATEGORY.find((c: any) => c.name === item.category1);
      const c2: any = c1.child.find((c: any) => c.name === item.category2);
      const c3: any = (c2 && c2.child.find((c: any) => c.name === item.category3)) || null;
    }
  }, []);


  const onDeletehandler = async () => {
    try {
      await voc.deleteVoc(item.id);
      info(intl.get('삭제 완료했습니다.'));
    } catch ({ status, data }) {
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  };

  const addCommentHandler = async () => {
    if (newComment.length === 0) {
      info(intl.get('내용을 입력해주세요.'));
      return;
    }

    try {
      const data: any = {
        voc: item.id,
        contents: newComment,
      };
  
      await voc.addComment(data);
  
      const result = await voc.getVocDetail(item.id);
      // console.log(result);
      setItem(result.data);
      setNewComment('');
      info(intl.get('작성을 완료했습니다.'));
    }catch ({ status, data }) {
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      (window as any).store.dispatch({
        type: SHOW_MODAL,
        payload: {
          title: '문제발생',
          msg: '문제가 발생했습니다',
          redirectPath: false,
          statusCode: status,
          errorCode: '',
          type: 'error',
          isModalShow: true,
        },
      });
      return ;
    }
  };

  const CommentMessageConverter = ({comment}: {comment: any}) => {
    let statusName = '';
    switch(comment.contents) {
      case '1': statusName= 'case6'; break;
      case '2': statusName= 'case5'; break;
      case '3': statusName= 'case4'; break;
    }
    switch(comment.title) {
      case 'manager':
        return (<>{intl.get('_님 _님을 담당자로 설정하였습니다.', { name: comment.author_name, name2: comment.contents })}</>);
      case 'status':
        return (
          <>
            {`${comment.author_name}님`}
            <span className={[
              'state_room',
              statusName
              ].join(' ')}>{VOC_STATUS_TYPES[Number(comment.contents)]}</span>
            {intl.get('상태로 변경되었습니다.')}
          </>
        );
    }
    return (<></>);
  };

  const goBack = () => {
    props.history.goBack();
  }

  return (
    <>
      <div className="table_design_write detail col_width4 mt0">
        <div id="table" className="table">
          <div className="tbody">
            <div className="tr">
              <div className="th"><span className="">{intl.get('유형')}</span></div>
              <div className="td">
                  {item.category1 ? `${item.category1}` : ''}
                  {item.category2 ? ` > ${item.category2}` : ''}
                  {item.category3 ? ` > ${item.category3}` : ''}
              </div>

              <div className="th"><span className="">{intl.get('진행상황')}</span></div>
              <div className="td">
                {/* {VOC_STATUS_TYPES[item.status]} */}
                {item.is_complete ? '해결완료' : '처리중'}
              </div>
            </div>

            <div className="tr">
              <div className="th"><span className="">{intl.get('작성일시')}</span></div>
              <div className="td">
                {formatByString(item.created_at, 'yyyy.MM.dd HH:mm')}
              </div>

              <div className="th"><span className="">{intl.get('작성자')}</span></div>
              <div className="td">
                {item.author_name}
              </div>
            </div>

            <div className="tr mix_width2 mb_w100">
              <div className="th"><span className="">{intl.get('제목')}</span></div>
              <div className="td">
                {item.title}
              </div>
            </div>

            <div className="tr mix_width2 mb_w100">
              <div className="th"><span className="">{intl.get('발생장소')}</span></div>
              <div className="td">
                {item.places_etc? 
                  '기타 > ' + item.places_etc + ' > ' + item.places_etc2
                : 
                  item.places.map((place: any) => (
                    <span key={place.id} className="item">
                    {
                      place.building_name === '기타'
                      ? intl.get('_번 지역', { place: `${place.building_name} > ${place.room_name}-${place.floor_name}`})
                      : intl.get('_호', { room: `${place.building_name} > ${place.building_dong ? place.building_dong + ' > ' : ''} ${place.room_name}` })
                    }
                    </span>
                  ))
                }
              </div>
            </div>

            <div className="tr mix_width2 mb_w100">
              <div className="th"><span className="">{intl.get('발생 시간')}</span></div>
              <div className="td">
                {formatByString(item.found_at, 'yyyy.MM.dd HH:mm')}
              </div>
            </div>


            <div className="tr mix_width2 mb_w100">
              <div className="th"><span className="">{intl.get('내용')}</span></div>
              <div className="td">
                <p id="editor" className="contents_area" dangerouslySetInnerHTML={{ __html: xss(item.contents) }}>
                </p>
              </div>
            </div>
            <div className="tr mix_width2 mb_w100">
              <div className="th"><span className="">{intl.get('사진첨부')}</span></div>
              <div className="td">
                <p className="contents_area images">
                  <div className="images_area">
                    {item.images.map((image: any, index: number) => (
                      <div key={index} className="img" style={{cursor:'pointer'}} onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                        <a key={index}>
                          <img src={`${process.env.REACT_APP_MEDIA}/${image.path}`} alt={image.name}/>
                        </a>
                      </div>
                    ))}
                  </div>
                </p>
              </div>
            </div>
            <div className="tr mix_width2 mb_w100">
              <div className="th"><span className="">{intl.get('파일첨부')}</span></div>
              <div className="td">
                {item.files.map((file: any, index: number) => (
                  <a key={index} href={`${process.env.REACT_APP_MEDIA}/${file.path}`} className="item file"
                  target={`${file.path}`.split('.').pop() 
                      && ['gif', 'jpg', 'jpeg', 'png', 'pdf', 'GIF', 'JPG', 'JPEG', 'PNG', 'PDF'].includes(`${file.path}`.split('.').pop() || '' )
                      && !isMobile ? '_blank' : ''}
                >{file.name}</a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb_view">
        <button type="button" className="btn btn_lg btn_gray" onClick={() => props.history.push('/customer/voc')}>{intl.get('목록')}</button>
      </div>

      <div className="submit btn_length_1 pt20">
        <div className="fl">
          <button type="button" className="btn btn_lg btn_gray" onClick={() => props.history.push('/customer/voc')}>{intl.get('목록')}</button>
        </div>

        {/* <div className="fr">
          <button type="button" className="btn btn_lg btn_outline">추가 문의</button>
        </div> */}

        <div className="fr">
          <button type="button" className="btn btn_lg btn_outline" onClick={() => props.setIsEdit(true)}>{intl.get('수정')}</button>
        </div>
      </div>

      {item.comments.length > 0 ? (
        <>
          <div className="title_contents border">
            <h3 className="title">{intl.get('추가문의')}</h3>
          </div>

          <div className="group_reply">
            <div className="form_reply">
              <p className="user">{props.user.username}</p>
              <textarea
                name=""
                id=""
                className="form_control"
                placeholder={intl.get('문의글을 남겨보세요')}
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}>
              </textarea>
              <div className="submit_area">
                  <button className="btn btn_sm btn_link" onClick={addCommentHandler}>{intl.get('문의하기')}</button>
              </div>
            </div>
            <ul className="lst_reply">

              {item.comments.map((comment: any, index: number) => (
                <li key={index}>
                  <p className="user">
                    <CommentMessageConverter comment={comment} />
                  </p>
                  <p className="contents_area images">
                    <div className="images_area">
                      {comment.images.map((image: any, index: number) => (
                        <div key={index} className="img" style={{cursor:'pointer'}} onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                          <a key={index}>
                            <img src={`${process.env.REACT_APP_MEDIA}/${image.path}`} alt={image.name}/>
                          </a>
                        </div>
                      ))}
                    </div>
                  </p>
                  {!_.includes(['manager', 'status'], comment.title) ? (
                    <p className="desc">
                      {comment.contents}
                    </p>
                  ) : (
                    <p className="desc"></p>
                  )}
                    <p className="date">
                    {formatByString(comment.created_at, 'yyyy.MM.dd HH:mm')}
                    </p>
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : (<></>)}

      {/* 이미지 팝업 */}
      <div id="pop_image" className="pop_dim_wrap pop_modal" style={{display: isImgPop? 'block' : 'none'}}>
          <div className="pop_wrap">
              <div className="title_wrap">
                <a href="javascript:void(0);" className="pop_btn_close" onClick={()=> setIsImgPop(false)}>닫기</a>
              </div>
              <div className="contents_wrap">
                  <div className="slide">
                      <p className="img_area">
                          <img src={imgPath} alt=""/>
                      </p>
                  </div>
              </div>
          </div>
      </div>
    </>
  )
};

export default VocDetail;
