import { addMonths, format } from 'date-fns';
import compareAsc from 'date-fns/compareAsc';

import getDay from 'date-fns/getDay';
import startOfMonth from 'date-fns/startOfMonth';
import getDaysInMonth from 'date-fns/getDaysInMonth';

import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';

import add from 'date-fns/addDays';
import sub from 'date-fns/subDays';
import addMonth from 'date-fns/addMonths';
import subMonth from 'date-fns/subMonths';

import getOverlappingDaysInIntervals from 'date-fns/getOverlappingDaysInIntervals';

import { ko } from 'date-fns/locale';

const FORMAT_DATE = 'yyyy-MM-dd';

// 일요일(0), 월요일(1), 화요일(2), 수요일(3), 목요일(4), 금요일(5), 토요일(6)
export const dayByNumber: string[] = ['일', '월', '화', '수', '목', '금', '토'];

// 오늘 날짜 가져오기
export function getTodayDate(today = new Date()): [string, number] {
  const todayDate: string = format(new Date(today), FORMAT_DATE, { locale: ko });

  const dayOfWeekNum: number = new Date(todayDate).getDay();

  const dayOfWeek: string = dayByNumber[dayOfWeekNum];

  return [todayDate, dayOfWeekNum];
}


// 내일 날짜 가져오기
export function getNextDay(d: string, n = 1): [string, number] {
  const nextDate = format(new Date(add(new Date(d), n)), FORMAT_DATE, {
    locale: ko,
  });
  const dayOfWeekNum: number = new Date(nextDate).getDay();

  const dayOfWeek: string = dayByNumber[dayOfWeekNum];
  return [nextDate, dayOfWeekNum];
}

// 어제 날짜 가져오기
export function getPrevDay(d: string, n = 1): [string, number] {
  const prevDate = format(new Date(sub(new Date(d), n)), FORMAT_DATE, {
    locale: ko,
  });
  const dayOfWeekNum: number = new Date(prevDate).getDay();

  const dayOfWeek: string = dayByNumber[dayOfWeekNum];
  return [prevDate, dayOfWeekNum];
}

// 2일 후 날짜(가족숙소 최대 신청 기간) 
export function gettwoDay(d: string, n = 3): [string, number] {
  const twoDay = format(new Date(add(new Date(d), n)), FORMAT_DATE, {
    locale: ko,
  });
  const dayOfWeekNum: number = new Date(twoDay).getDay();

  //const dayOfWeek: string = dayByNumber[dayOfWeekNum];
  return [twoDay, dayOfWeekNum];
}

// 2주후 날짜
export function getTwoWeeks(d: string, n = 14): [string, number] {
  const twoWeekDate = format(new Date(add(new Date(d), n)), FORMAT_DATE, {
    locale: ko,
  });
  const dayOfWeekNum: number = new Date(twoWeekDate).getDay();

  const dayOfWeek: string = dayByNumber[dayOfWeekNum];
  return [twoWeekDate, dayOfWeekNum];
}

// 다음달 가져오기
export function getNextMonth(d: string, n = 1): [string, number] {
  const nextDate = format(new Date(addMonth(new Date(d), n)), FORMAT_DATE, {
    locale: ko,
  });
  const dayOfWeekNum: number = getDay(new Date(nextDate));

  const dayOfWeek: string = dayByNumber[dayOfWeekNum];
  return [nextDate, dayOfWeekNum];
}

// 오늘로부터 n개월 후 날짜 가져오기
export function getTwoLatersMonthDate(n: number): [string, string] {
  const todayDate: string = format(new Date(), FORMAT_DATE, { locale: ko });

  const tempDate: Date =  new Date();
  tempDate.setMonth(tempDate.getMonth() + n);

  const BeforeDate: string = format(tempDate, FORMAT_DATE, { locale: ko });

  return[todayDate, BeforeDate];
}

// 오늘로부터 n개월 전 날짜 가져오기
export function getTwoLatersOldMonthDate(n: number): [string, string] {
  const todayDate: string = format(new Date(), FORMAT_DATE, { locale: ko });

  const tempDate: Date =  new Date();
  tempDate.setMonth(tempDate.getMonth() - n);

  const BeforeDate: string = format(tempDate, FORMAT_DATE, { locale: ko });

  return[todayDate, BeforeDate];
}

// 어제 날짜 가져오기
export function getPrevMonth(d: string, n = 1): [string, number] {
  const prevDate = format(new Date(subMonth(new Date(d), n)), FORMAT_DATE, {
    locale: ko,
  });
  const dayOfWeekNum: number = getDay(new Date(prevDate));

  const dayOfWeek: string = dayByNumber[dayOfWeekNum];
  return [prevDate, dayOfWeekNum];
}

// 해당 년-월의 첫번째 요일
export function getFirstDayByMonth(d: string): number {
  const startDay = startOfMonth(new Date(d));

  return getDay(startDay);
}

// 날짜를 기준으로 n개만큼 가져오기
export function getRangeDate(d: string, range = 1): [[string, number]] {
  const temp: any = [];

  for (let i = 0; i < range; ++i) {
    const formated = format(new Date(add(new Date(d), i)), FORMAT_DATE, {
      locale: ko,
    });
    const dayOfWeekNum: number = getDay(new Date(formated));

    const dayOfWeek: string = dayByNumber[dayOfWeekNum];
    temp.push([formated, dayOfWeekNum]);
  }
  return temp;
}

// 해당 달에 요일갯수
export function getCountDaysByMonth(focusDate: string): number {
  return getDaysInMonth(new Date(focusDate));
}

// 시작 ~ 마지막 날짜를 전달 받으면 그 사이의 날짜를 반환한다.
export function getRangeDates(startDate: string, endDate: string) {
  return eachDayOfInterval({
    start: new Date(startDate),
    end: new Date(endDate),
  }).map(date => format(new Date(date), FORMAT_DATE));
}

export function isAfterAndSame(startDate: string, endDate: string) {
  if ((!startDate && endDate) || (startDate && !endDate)) {
    return true;
  }

  const _isAfter = isAfter(new Date(endDate), new Date(startDate));

  const _isBefore = isBefore(new Date(endDate), new Date(startDate));

  if (!_isAfter && !_isBefore) {
    return true;
  } else if (_isAfter) {
    return true;
  } else {
    return false;
  }
}

// 포맷 변환
export function formatByString(d: string, f: string) {
  if (!d) return '';
  return format(new Date(d), f);
}
// 포맷 변환
export function format_YYYYMMDD(y: any, m: any, d: any) {
  return format(new Date(y, m - 1, d), FORMAT_DATE);
}

// 포맷 변환
export function formatByString_YYYYMMDD(d: string) {
  return format(new Date(d), FORMAT_DATE);
}

export function isMonthSame(d1: string, d2: string) {
  return d1.split('-')[0] === d2.split('-')[0] && d1.split('-')[1] === d2.split('-')[1]
}

// 크기비교 source < target true
export function rightBig(source: any, target: any) {
  return compareAsc(new Date(source), new Date(target)) === -1;
}

// 크기비교 source > target false
export function leftBig(source: any, target: any) {
  return compareAsc(new Date(source), new Date(target)) !== -1;
}


interface T {
  stay_after: string
  stay_before: string
}

// 충돌비교
export function isRangeOverlapping(t1: T, t2: T) : number{
  return getOverlappingDaysInIntervals(
    {
      start: new Date(t1.stay_after),
      end: new Date(t1.stay_before)
    }, {
      start: new Date(t2.stay_after),
      end: new Date(t2.stay_before)
    }
  )
}

// 단체 배방용 - 입숙, 퇴숙 예정일 겹치는 부분 확인하기
export function getStayConfirm(roomStayAfter: string, roomStayBefore: string, stayAfter: string, stayBefore: string): boolean {
  let chk: boolean = false;

  let temp: any = getRangeDates(roomStayAfter, roomStayBefore);

  if(stayAfter === roomStayAfter || stayAfter === roomStayBefore || stayBefore === roomStayAfter || stayBefore === roomStayBefore ){
    chk = true;
  }
  
  for(let i=0; i<temp.length; i++){
    if(temp[i] === stayAfter || temp[i] === stayBefore){
      chk = true;
    }
  }
  
  return chk;
}

// 정기이숙 가능 날짜인지 check 
export function getStayHourConfirm(roomStayAfter: string, roomStayBefore: string, today: string): boolean {
  let chk: boolean = false;

  let temp: any = getRangeDates(formatByString_YYYYMMDD(roomStayAfter), formatByString_YYYYMMDD(roomStayBefore));
  
  for(let i=0; i<temp.length; i++){
    if(temp[i] === today){
      chk = true;
    }
  }

  if(today === formatByString_YYYYMMDD(roomStayAfter)){
    if(new Date().getHours() < new Date(roomStayAfter).getHours()){
      chk = false;
    }
  }

  if(today === formatByString_YYYYMMDD(roomStayBefore)){
    if(new Date().getHours() > new Date(roomStayBefore).getHours()){
      chk = false;
    }
  }
  
  return chk;
}

// 5년 주기 년도 가져오기 (true: 과거 5년, false: 미래 5년)
export function getYear5(isPased: boolean): [any] {
  const todayDate: string = format(new Date(), FORMAT_DATE, { locale: ko });
  let getYear: number = new Date(todayDate).getFullYear();

  if(isPased){
    getYear = new Date(todayDate).getFullYear() -4;
  }

  const yearDate: any = [];

  for(let i=0; i<5; i++){
    yearDate.push(getYear + i);
  }

  return yearDate;
}

// 월 가져오기 
// year - 선택된 년도
// isPased - 과거데이터인지 여부 -> true: 1월부터 오늘 달까지, false: 오늘 달부터 12월 까지
export function getMonths(year:string, isPased: boolean): [any] {
  const todayDate: Date = new Date();
  let getMonth: number = todayDate.getMonth() + 1;
  const monthData: any = [];

  if(parseInt(year) !== (todayDate.getFullYear())){
    getMonth = 1;
    isPased = false;
  }

  if(isPased){
    for(let i=0; i<getMonth; i++){
      monthData.push(1 + i);
    }
  }else{
    for(let i=0; i< (12-getMonth+1); i++){
      monthData.push(getMonth + i);
    }
  }

  return monthData;
}

// 오늘 날짜 년 가져오기
export function getYear(): string {
  const todayDate: string = format(new Date(), FORMAT_DATE, { locale: ko });
  const getMonth: string = new Date(todayDate).getFullYear() +'';

  return getMonth;
}

// 오늘 날짜 달 가져오기
export function getMonth(): string {
  const date = format(addMonths(new Date(getTodayDate()[0]), -1), 'yyyy-MM-dd').split('-');
  const month = date[1];

  return month;
}
