// base은 prefix로 _BASE_을 붙인다.
// 특수상황은 _BASE_를 뺸다

const en = {
  'day-0': 'Sun.',
  'day-1': 'Mon.',
  'day-2': 'Tue.',
  'day-3': 'Wed.',
  'day-4': 'Thu.',
  'day-5': 'Fri.',
  'day-6': 'Sat.',

  'kr': 'Korean',
  'en': "English",

  '_BASE_signin': "Signin",
  '알림': 'Notice',
  '알림관리': 'Notice managiment',
  '검색': 'Search',
  '저장': 'Save',
  '더보기': 'More',
  '파일첨부': 'Upload files',
  '파일찾기': 'Search',
  '사진첨부': 'Upload Pictures',
  '내용': 'Text',
  '발생장소': 'Location',
  '제목': 'Title',
  '작성자': 'Writer',
  '작성일시': 'Day and Time',
  '진행상황': 'Status',
  '유형': 'Type',
  '답변': 'Respond',
  '답변 일시': 'Time',
  '답변 담당자': 'Staff',
  '목록': 'List',
  '추가 문의': 'Add',
  '취소': 'Cancel',
  '다음': 'Next',
  '이전': 'Previous',
  '완료': 'Complete',
  '수정': 'Correction',
  '삭제': 'Delete',
  '닫기': 'Close',
  '확인': 'Confirm',
  '추가': 'Add / New',
  '초기화': 'Reset',
  '공지사항': 'Notice',
  '남자': 'Man',
  '여자': 'Woman',
  '질문': 'Question',
  '선택': 'Select',
  'FAQ': 'FAQ',
  '생활민원': 'Complain',
  '영선': 'Maintenance',
  '시설': 'Facilities',
  '서비스': 'Service',
  '사건사고': 'Acciedent',
  '기타': 'Etc.',
  '전체': 'General',
  '데이터가 없습니다': 'There is no data.',
  '파일찾기를 클릭하여 파일을 업로드 하세요': 'Click the search butten, and upload the file.',
  '빠른 숙소 신청 서비스로 간편하게 숙소를 이용해보세요': 'Try to apply acommodation management system.',
  '숙소이용 불편사항 등을 게시하고 답변 받을 수 있습니다': 'You can post the inconvenience of using the accommodation and get a reply.',
  '최근 한달간 알림을 확인하실 수 있습니다': "You've been notified for the last month.",
  '회원가입 정보를 확인하고 수정하실 수 있습니다': 'You can check and modify your membership information.',
  '숙소이용신청 내역확인 및 수정을 진행하실 수 있습니다': 'You can check and modify the details of the accommodation application.',
  '기숙사, 사택  퇴숙신청 및 현황을 확인하실 수 있습니다': 'You can check the status and application for return of the dormitory and the family flat.',
  '기숙사, 이숙신청및 현황을 확인하실 수 있습니다': 'You can check the application of changing room and the status your accomodation. ',
  '숙소이용 이력 및 상세내용을 확인하실 수 있습니다': 'You can check the history and details of the accommodation use.',
  '필수항목을 업로드하지 않았습니다.': 'You have not uploaded the required requirements.',
  '기숙사' : 'Dormitory',
  '이미 이용중인 숙소가 있습니다.\n 퇴숙완료 후 신청 가능합니다.': 'There is an accommodation already in use.\n You can apply after using the accommodation.',
  '이미 이용중인 숙소가 있습니다.\n 퇴숙완료 후 신청 가능합니다.\n 신청관리 화면에서 확인해주세요.': "There's already an accommodation in use. \n You can apply after you leave the room. \n Please check the application management screen.",
  '이미 이용중이거나 신청중인 숙소가 있습니다.\n 퇴숙완료 또는 신청취소 후 신청 가능합니다.\n 신청관리 화면에서 확인해주세요.': 
  'There are accommodations already in use or being applied for. \n You can apply after you leave the room or cancel your application. \n Please check the application management screen.',
  '사번이 올바르지 않습니다.': 'The ID number is not right.',
  '올바른 핸드폰 번호를 입력해 주세요.': 'Enter the right Cell phone number. ',
  '게스트정보가 올바르지 않습니다.': 'The guest information is not right.',
  '예약 중 문제발생': 'There was a problem with the reservation.',
  '예약 중 문제가 발생했습니다.': 'There was a problem with the reservation.',
  '신청단계': 'The Phase of Application',
  '서비스이용약관 동의 또는 개인정보이용동의를 동의하지 않았습니다.': "I didn't agree to the terms of service or to agree to the use of personal information.",
  '성명을 입력해주세요.': 'Please, write the guest name.',
  '휴대폰번호 항목이 비었거나 잘못되었습니다.\n(휴대폰번호는 11자리입니다.)': 'The mobile number is empty or invalid.',
  '성별 항목이 비었습니다.': 'Please, choose the sex.',
  '사번을 검색하여 이용자 정보를 등록해주세요.' : 'Please, search guest ID, and apply guest information.',
  '소속부서를 입력해주세요.': 'Please, write your team.',
  '근무조를 선택해주세요.': 'Please, select your work shift.',
  '사번을 입력해주세요.': 'Please, write your ID.',
  '회사명을 선택해주세요.': 'Please, select the Company.',
  '회사명 또는 단체명을 입력해주세요.': 'Please, enter your a team or organization.',
  '는 가입된 사번이 아닙니다.': 'It is not right the employee number. ',
  '신청자 정보': 'Profile',
  '사번 (아이디)': 'ID ',
  '사번': 'Employee number',
  '성명': 'Name',
  '성별': 'Sex',
  '소속 부서': 'Team)',
  '휴대폰 번호': 'Mobile Phone (M.P.)',
  '신청자와 이용자가 동일합니다 (신청자 본인이 이용할 경우 체크하시면 이용자 정보가 자동 입력 됩니다)': 'The applicant and the guest are the same. (if the applicant uses it, the guest information will be automatically entered.)',
  '이용자 정보' : 'Profile',
  '사업장 선택': 'Site',
  '이용 사업장을 선택 후 이용자 정보를 입력하여 주세요': 'Please, check your work site.',
  '적용': 'Apply',
  '사번을 입력하세요' : 'Please, enter the employee number.',
  '성명을 입력하세요': 'Please, enter the name.',
  '소속부서를 입력하세요': 'Please, enter the team.',
  '휴대폰 번호를 입력하세요': 'Please, enter the mobile phonenumber.',
  '근무조': 'Work shift',
  '차량번호': 'Car number',
  '차량번호를 입력하세요': 'Please, enter your car number.',
  '서비스 이용약관 동의(필수)': 'Agreement the terms of Service (Mandatory)',
  '개인정보 이용 동의(필수)': 'Agreement to use personal informations (Mandatory)',
  '자세히보기': 'Check in Detail',
  '대리 신청의 경우 사번을 입력하시면 인사 정보 연동하여 기본 정보가 자동 입력 됩니다. 차량번호를 제외한 모든 입력 사항은 필수로 입력하셔야 합니다.': 'In the case of proxy application, the basic information will be automatically entered by linking personnel information when you enter the employee number. All input except the vehicle number is required.',
  '회사명(단체명)': 'The company name (organization name)',
  '회사명' : 'The company name',
  '차량번호를 제외한 모든 입력 사항은 필수로 입력하셔야 합니다.': 'All input except the vehicle number is required.',
  '회사명 또는 단체명을 입력하세요': 'Pleasae, enter the company or organization name.',
  '증빙서류': 'Documents',
  '이용신청을 취소 하시겠습니까?': 'Will you cancle the application?',
  '근무조를 선택하세요': 'Please, selecet the work shift',
  '회사명을 선택하세요': 'Please, select company name',
  '숙소 이용안내' : 'Instructions',
  '파일 다운로드' : 'File download',
  '다운로드': 'Download',
  '입숙 서약서': 'Acommodation pledge',
  '기숙사 입숙 서약서 및 입숙 준비사항에 대한 모든 항목을 확인하였으며 해당 내용에 동의하며 이를 충실히 이행 할 것을 서약합니다.':
  'I have confirmed all the requirements for the dormitory accommodation pledge and arrangements, and I agree and pledge to faithfully implement them.',
  '서약 일자' : 'The day of written',
  '동의합니다': 'Agree',
  '동의하지 않습니다': 'Disagree',
  '예약을 진행하시겠습니까?': 'Would you like to proceed with the reservation?',
  '입숙 서약서 내용에 동의를': 'I agree to the contents of the accommodation pledge',
  '하셔야만 이용 신청이 가능합니다': 'You can apply for use only when you do',
  '동의 여부를 확인하여 주세요': 'Confirm your agreement.',
  '신청이 완료되었습니다': 'Your application has been completed.',
  '이용 신청이 접수되었습니다. 관리자가 확인 후 승인이 완료되면 ':'Your application has been received',
  '마이페이지 신청관리에서 직접 배방을 진행하셔야 합니다.': 'After the administrator checks and approves, you have to arrange the distribution directly in My Page application management.',
  '신청된 이용자 정보는 하기와 같습니다.':'The guest information requested is as follows.',
  '예약정보': 'Reservation information',
  '신청관리 바로가기': 'Shortcuts to Manage Applications',
  '숙소유형':'Acommodatoin Type',
  '이용사업장': 'Work site',
  '이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 신청된 입숙일에 해당 사업장의 관리사무소로 방문하시어 간단한 인적사항 확인 후 키 분출을 하실 수 있습니다':
  'Those who are registered with the guest information can use the accommodation. Visit the office on the day of the application. You can get a key after checking your personal information.',
  '정보 등록': 'Register Information',
  '입숙서약동의': 'Accommodation Pledge Agreement',
  '신청완료': 'Application completed',
  '신청 완료 이후': 'You can get a key after checking your personal information',
  '관리자 승인 및 배방오픈': 'Administrator approval and room selection open',
  '신청관리에서 배방신청 및 입숙 예정일 입력': 'Apply for a room in the application management and enter the scheduled date of stay.',
  '예약 완료': 'Reservation complete',
  '사업장선택 및 정보등록': 'Selecting a site and registering information',
  '신청관리에서 배방신청': 'Application for room selection',
  '기간선택': 'choose the t a period',
  '배방선택': 'Select a room',
  '보증금 입금증 업로드 및 입숙 예정일 입력': 'Upload the deposit transfer document and enter the due date of check-in',
  '투숙인원': 'Number of Customers',
  '배방선택 및 정보등록': 'Room Selection and Information Registration',
  '출장자숙소(개인)': 'Business Trip Accommodation',
  '입숙예정일' : 'Check-in expected date',
  '퇴숙예정일' : 'Check-out expected date',
  '배방정보' : 'Room Selection Information',
  '건물/동' : 'Building/Dong',
  '건물명/동' : 'Building name/Dong',
  '방호수' : 'room number',
  '방호실/명': 'room / Guests',
  '사내 임직원의 경우 사번을 입력하시면 인사 정보 연동하여 기본 정보가 자동 입력 됩니다. 차량번호를 제외한 모든 입력 사항은 필수로 입력하셔야 합니다.':
  'In the case of Hyundai Motor employees, the basic information is automatically entered by linking HR personnel information when you enter the company number. All information except the vehicle number is required, if you do not register your vehicle, parking is prohibited.',
  '이용 예약을 취소하시겠습니까?': 'Will you cancle your book?',
  '이용신청을 취소하시겠습니까?': 'Do you want to cancel the application for use',
  '이용기간 선택': 'Choosing Check-in & Check-out',
  '이용 기간을 선택하여 주세요.': 'Please, choose Check-in & Check-out',
  '당일 예약선택': 'Today reservation',
  '새로고침': 'Refresh',
  '선택한 방은 총원 ' : 'Total number of rooms selected',
  '명 중 ': 'Among',
  '명이 사용중입니다. \n정말 예약을 진행하시겠습니까?': 'oo people are in use. \n  Are you sure you want to proceed with the reservation?',
  '동을 선택하고 층수를 선택하여 빈방을 검색하여 선택하여 주세요': 'Please select a building and a floor, check and select a vacant room.',
  '위치보기' : 'Location view',
  '배방신청': 'Apply for room selection',
  '건물명 및 동': 'Building name',
  '층 수': 'Floor',
  '이용 가능': 'Available',
  '이용 불가능': 'Disabled',
  '출장자 숙소 입숙 서약서 및 입숙 준비사항에 대한 모든 항목을 확인하였으며 해당 내용에 동의하며 이를 충실히 이행 할 것을 서약합니다.':
  'I have confirmed all the requirements for the bussiness trip accommodation pledge and arrangements, and I agree and pledge to faithfully implement them.',
  '예약이 완료되었습니다': 'Your book is complete.',
  '이용 신청이 접수 및 승인되어 예약이 완료되었습니다.': 'Your reservation has been completed as your application has been received and approved.',
  '예약정보 및 이용자 정보는 하기와 같습니다.': 'The reservation information and customer information are as follows.',
  '사택': 'Family flat',
  '사택 입숙 서약서 및 입숙 준비사항에 대한 모든 항목을 확인하였으며 해당 내용에 동의하며 이를 충실히 이행 할 것을 서약합니다.':
  'I have confirmed all the requirements for the family flat pledge and arrangements, and I agree and pledge to faithfully implement them.',
  '이용 신청이 접수 되었습니다. 관리자가 확인 후 매월 25일 승인여부가 결정됩니다.': 'The application for use has been approved, Approval is determined on the 25th of each month.',
  '이용 신청이 접수 되었습니다. 이용신청 후 3일 이내 대기순번(신청순)에 따라 승인여부가 결정됩니다.': 'Your application for use has been received. Approval will be determined according to the waiting order (order of application) within three days after application for use.',
  '입숙 선정자로 승인이 되면 문자가 발송 되오니 문자 확인이 되시면': "When you've been approved as candidate, You'll receive a text message",
  '마이페이지 신청관리에서 배방 확인 및 입숙 예정일을 입력해 주세요.': 'Please select a room directly from My Page application management.',
  '번째 사번은 등록되지 않았습니다.': 'th, the company number is not registered',
  '입숙일 항목이 비었습니다.': 'Your Check-in day is empty.',
  '퇴숙일 항목이 비었습니다.': 'Your Check-out day is empty.',
  '성별을 선택하지 않았습니다.': 'You did not choose a sex.',
  '날짜를 다시한번 확인해주세요.': 'Please, Check your date again.',
  '출장자숙소(단체)' : 'Bussiness trip Accomodation',
  '이용자 중 인솔자는 반드시\n 1명 이상 선택되어야 합니다.\n 인솔자 여부를 확인해주세요.':
  'At least one group leader must be selected among guests. \n Please, check there are a group leader or not.',
  '부서 항목이 비었습니다.' : 'The department name is empty.',
  '회사 항목을 선택해주세요.': 'Please, choose a company.',
  '사번 항목이 비었습니다.': 'The employee number is empty.',
  '회사 항목이 비었습니다.' : 'The company is empty.',
  '협조전이 등록되어있지 않습니다.\n 협조전 파일을 등록해주세요.': 'Team request document is not registered. \n Please register the Team request document file.',
  '추가된 이용자가 없습니다.': 'There is no added guest.',
  '업로드하신 엑셀파일의 형태가 맞지 않거나 입력정보가 올바르지 않아서 인원추가에 실패하였습니다. \n양식다운로드 하신 파일을 이용해 주시기 바랍니다.':
  'The Excel file you uploaded did not match the format or the input information was not correct, so we failed to add more people. \n Please use the file you downloaded.',
  '입숙일/퇴숙일 날짜는 올바른 날짜여야 합니다.': 'The check-in / check-out date must be an exact date.',
  '조회중 문제가 발생했습니다.' : 'There was a problem during the search.',
  '협조전 등록이 완료되었습니다.' : 'Team request document registration is complete.',
  '협조전 업로드 중 문제가 발생했습니다' : 'There was a problem uploading Team request document.',
  '파일의 용량이 큽니다.\n10M 미만의 파일을 등록해 주세요' : 'The file has a large capacity. \n Please register a file less than 10mb.',
  '양식다운로드': 'Download Document Form',
  '업로드 된 파일 ' : 'Uploaded file',
  '을 정말 삭제하시겠습니까?' : 'Are you sure you want to delete?',
  '식권 수 (장)' : 'the number of cafeteria tickets',
  '식권' : 'cafeteria tickets',
  '신청하실 식권 수를 입력해주세요' : 'Please enter the number of cafeteria tickets you need.',
  '입숙일' : 'Check-in day',
  '날짜를 선택해주세요' : 'Please choose a day',
  '퇴숙일': 'Check-out day',
  '인솔자 여부' : 'Group leader ',
  '여': 'Yes',
  '부': 'No',
  '입력 창에서 직접 입력이 가능합니다. 정보 입력 후 추가 버튼을 클릭하시면 추가 됩니다': 'You can enter directly from the input window. After entering the information, click the Add button to add it.',
  '사내 임직원의 경우 사번을 입력하시면 인사 정보 연동하여 기본 정보가 자동 입력 됩니다' : 'For Hyundai employees, enter your employee number, and the basic information will be automatically entered by linking the HR information',
  '먼저 이용자 유형을 선택하시고 파일 업로드를 하시거나 직접 정보 입력을 해주세요' : 'First, Choose the guest type and upload the file or enter the information yourself.',
  '출장자 숙소 단체 신청의 경우 양식 다운로드 받아서 파일업로드로 업무 처리하시기를 권장합니다' : 'We recommend that you download the form and upload the file for business trip group applications.',
  '만약 이용자 정보의 개별 등록을 원하실 경우 직접입력을 하여 추가하셔도 됩니다' : 'If you want to register customer information individually, you can add it by typing it in yourself.',
  '이용자 유형이 다양하거나 파일업로드와 직접입력 혼용도 가능합니다' : 'Various guest types allow both file upload and direct input.',
  '이용자' : 'Guest',
  '타입' : 'Type',
  '(아이디)' : 'Guest ID',
  '소속' : 'Affiliation',
  '부서' : 'Team',
  '휴대폰' : 'M.P.',
  '번호' : 'Number',
  '입숙' : 'Check-in',
  '예정일' : 'Due date',
  '퇴숙' : 'Check-out',
  '인솔자' : 'Group leader ',
  '여부' : 'Yes/No',
  '협조전 파일' : 'Team request document',
  '협조전은 한 개만 등록할 수 있습니다. 어떤 파일유형이든 업로드 가능하며 10M이상의 파일은 올릴 수 없습니다.':
  'Only one Team Request Document can be registered. Any type of file can be uploaded, and files over 10mb cannot be uploaded.',
  '정말 취소하시겠습니까?' : 'Are you sure you want to cancel?',
  '정말로 삭제하시겠습니까?' : 'Are you sure you want to delete it?',
  '아니오': 'No',
  '예' : 'Yes',
  '회사명을 입력하세요' : 'Please, Enter company name.',
  '이용 신청이 접수 되었습니다.' : 'Application for use has been received.',
  '관리자가 신청 내용 및 서류 확인 후 승인 및 배방이 이루어지며' : 'After the administrator checks the application and documents, approval and selection are made',
  '관리자가 확인 후 승인이 완료되면 마이페이지 신청관리에서 직접 배방을 진행하셔야 합니다' : 'When the accommodation manager has approved, you will need to proceed with choosing your room from My Page application management.',
  '신청된 자세한 정보는 마이페이지 신청관리에서 확인이 가능합니다.' : 'You can check the detailed information you requested in My Page application management.',
  '신청 총 이용 인원' : 'Total number of applicants',
  '이용자 정보에 등록되신 분들이 해당 숙소를 이용하실 수 있으며 신청된 입숙일에 인솔자 분들은 해당 사업장의 관리사무소로 방문하시어 간단한 확인 등의 절차를 거쳐 키 분출 하실 수 있습니다':
  'Those who are registered with guest information can use the accommodation. \n On the day of your requested visit, you can visit the accommodation management office and receive the key after simple verification and other procedures.',
  'R&D STAY' : 'Family room',
  '이용자 정보 입력이 충분하지 않습니다.' : 'Your guest information input is insufficient',
  '신청중 문제가 발생했습니다.' : 'There was a problem with the application',
  '투숙인원을 선택하시고 기간을 선택해 주세요' : 'Please select the number of guests and choose the period.',
  '투숙인원을 선택 후 투숙자의 정보를 입력해 주세요' : "Please select the number of guests and enter the guest's information.",
  '방을 선택해야 합니다.' : 'You should choose the room.',
  '가족 숙소는 3인실 입니다. 호 단위로 신청이 가능합니다' : 'Family room can available in three people. You can apply a flat unit.',
  '성명, 성별, 신청자와의 관계는 필수로 입력하셔야 합니다.' : 'Please, you must write the name, sex and relationship with the applicant.',
  '성별을 선택하세요' : 'Select your sex',
  '신청자와의 관계' : 'Relationship with applicant',
  '이용자의 관계를 입력하세요' : 'Relationship with guest',
  '입숙 서약서 내용에 동의를 \n하셔야만 이용 신청이 가능합니다\n동의 여부를 확인하여 주세요':
  'You can only apply if you agree to the terms of the accommodation agreement.',
  '예약정보는 하기와 같습니다' : 'Reservation information is as follows.',
  '호' : 'Flat',
  '환경관리심사': 'Screen room condition',
  '투숙 인원 및 정보 등록': 'Register customers and information',
  '펼치기/접기': "Expand/Collapse",
  '파일 업로드': 'File Upload',
  '이용 신청이 접수되었습니다.' : 'Your application has been received.',
  '관리자가 확인 후 배방을 진행하며 해당 정보는 신청관리에서 확인하실 수 있습니다.' : 'The administrator will check and select a room, and you can check the information in the application management.',
  'R&D STAY는 신청자의 가족이신 분들만 사용가능합니다. 입숙시, 가족관계증명서를 지참하시기 바랍니다.': 'R&D STAY is only available for family members of the applicant. \n Please bring a family relationship certificate when you enter.',
  '작성일자': 'a date by written',
  '등록일자': 'Book date',
  '정기서류관리': 'Regular Document Management',
  '정기서류검사등록': 'Regular Document Test',
  '반려사유': 'Rejection reason',
  '파일 업로드와 직접입력 혼용도 가능합니다' : 'You can upload files and enter them directly',
  'My VoC': 'My VoC',
  '회사명(단체명)을 입력하세요' : 'Enter company name',
  '등록': 'Book',
  '변경하신 내용이 삭제됩니다. 취소하시겠습니까?': 'Your changes will be deleted. \nAre you sure you want to cancel?',
  '작성하신 내용을 등록하시겠습니까?': 'Would you like to register what you wrote?',
  '입력하신 내용이 삭제됩니다. 목록으로 이동하시겠습니까?': 'What you wrote will be deleted. \nDo you want to go to the list?',
  '등록 완료했습니다.': 'Your registration is complete.',
  '수정 완료했습니다.': 'Corrected successfully',
  '만약 이용자 정보의 개별 등록을 원하실 경우 직접입력이 가능합니다' :'Your correction has been completed.  "If you want to register guest information individually, you can enter it directly."',
  '정보 입력 후 추가버튼을 클릭하시면 하단 단체이용자 정보에 추가됩니다' : 'If you click the Add button after entering information, it will be added to the bottom group guest information',
  '동일한 이용자 명으로 중복된 날짜 정보가 있습니다.' : 'There are duplicate date information with the same guest name.',
  '업로드한 파일에 오류가 있습니다. \n양식을 다운로드 받아 사용해 주세요.' : 'There is an error in the uploaded file. \nPlease download the form and use it.',
  '이용 신청이 정상적으로 접수 되었습니다.' : 'Your application has been accepted normally.',
  '관리자가 신청 내용 확인 후 사업장 별로 승인 및 배방 오픈이 이루어지며' : 'The room selection function is opened after the administrator checks the application details and approves it.',
  '관련 내용은 마이페이지 > 신청관리 메뉴에서 확인이 가능합니다.' : 'You can check it on My Page > Application Management menu.',
  '신청 접수된 이용자 정보의 본인만이 숙소이용이 가능합니다.' : 'Only the guest who applied can use the accommodation.',
  '각 현장별로 관리자가 승인완료 후 신청자가 방선택을 완료하면 관리자 최종승인 후 예약이 완료됩니다.' :
  "After the manager completes approval for each site, the applicant completes the room selection, and the reservation is completed after the manager's final approval.",
  '이용자 성명' : 'Guest name',
  '이용 신청이 정상적으로 접수되었습니다' : 'Your accommodation has been registered normally.',
  '관리자가 신청 내용 확인 후 사업장 별로 승인 및 배방이 완료됩니다' : 'After the manager checks the application details, approval and room selection are completed for each workplace.',
  '행에서 휴대폰번호 항목이 잘못되었습니다.' : 'The mobile phone number is wrong on the line.',
  '행에서 사번이 잘못되었습니다.' : 'The company ID is wrong on the line.',
  '행에서 날짜가 잘못되었습니다.' : 'The date is wrong on the line.',
  '행에서 식권 수가 잘못되었습니다.' : 'The cafeteria ticket number is wrong on the line.',
  '건물명 선택': 'Select Building',
  '숙소유형 선택': 'Accommodation type select',
  '선택된 장소가 없습니다.': 'There is no place is selected.',
  '_번 지역': '{place} Region',
  '_호': '{room} Room',
  '삭제 완료했습니다.': 'Delete completed successfully.',
  '내용을 입력해주세요.': 'Please enter the contents.',
  '작성을 완료했습니다.': 'You have completed your writing.',
  '님을 담당자로 설정하였습니다.': 'You have set {name2} as the person in charge.',
  '상태로 변경되었습니다.': 'It changed to ooo state.',
  '추가문의': 'Additional questions',
  '문의하기': 'Contact us',
  '문의글을 남겨보세요': 'Please leave an your question.',
  '발생 시간': 'Happened Time',
  '시경': 'around time',
  '행에서 빈항목이 있습니다.\n(성명, 휴대폰번호, 입숙일, 퇴숙일)' : 'There are empty items in the row. \n(Name, Mobile number,Check-in, Check-out)',
  '행에서 빈항목이 있습니다.\n(성명, 휴대폰번호, 입숙일, 퇴숙일, 성별, 인솔자 여부)' : 'There are empty items in the row. \n(Name, Mobile number,Check-in, Check-out, Sex, Group leader)',
  '업로드한 파일에 오류가 있습니다.\n' : 'There is an error in the uploaded file.',
  '행에서 인솔자 여부 항목이 잘못되었습니다.' :'There is an error in the uploaded file. Group leader item is wrong in the row.',
  '사내부부 여부' : 'Married couples in the company',
  '주거지원금 수혜여부' : 'Housing Benefit status',
  '잔여기간' : 'Remaining Period',
  '숙소 성별 유형이 맞지 않습니다.': 'The gender type of accommodation does not match.',
  '신청 접수된 이용자 정보' : 'Guest Information is received for Application.',
  '이용 사업장이 울산일 경우 사내임직원, 계열사임직원으로만 신청 가능합니다' : 
  'If the place of business is Ulsan, only employees of Hyundai Motor Company or affiliates can apply.',
  '이용 사업장이 남양연구센터일 경우 현대차직원으로만 신청 가능합니다.' : 
  'If the place of business is Namyang Research Center, only Hyundai Motor employees can apply.',
  '이용 사업장이 전주공장일 경우 현대차직원, 계열사직원으로만 신청 가능합니다' :
  'If the place of business is located in Jeonju, only employees of Hyundai Motor and affiliates can apply.',
  '해당 사업장은 미혼만 신청 가능합니다.': 'Only not married people can apply for this place of business.',
  '해당 사업장은 기혼만 신청 가능합니다.':'Only married people can apply for this place of business.',
  '방을 선택하지 않았습니다.': 'You didn’t choose the room.',
  '필요한 전체 식권 수량을 입력 하세요' : 'Enter the total number of cafeteria tickets you need.',
  '권한이 없습니다.' : "You don't have authority.",
  '숙소이용안내' : 'Instructions',
  '입숙예정일에 해당 사업장의 관리사무소로 방문하시면' : 'If you visit the management office of your place on the scheduled date of your stay,',
  '관리사무소 직원의 안내에 따라 입숙절차가 마무리되고 ' : 'The accommodation process is completed with the guidance of the management office staff,',
  '키를 수령 하실 수 있습니다.' : 'You can receive the key',
  '예약된 숙소 이용 정보' : 'Reserved accommodation information',
  '관리자가 신청 내용 및 서류 확인 후 사업장 별로 승인 또는 승인 및 임시배방이 이루어지며' : 'After the administrator checks the application details and documents, approval and temporary room selection are made,',
  '관련 내용은 마이페이지 > 신청 관리 메뉴에서 확인이 가능합니다.' : 'You can check the information in the My Page > Application Management menu.',
  '관리자가 신청 내용 서류 확인 후 사업장 별로 승인 및 배방이 완료됩니다.' : 'After the administrator checks the application documents, approval and distribution are completed by business establishment.',
  '이미 신청중이거나 승인상태인 R&D STAY 있습니다.' : 'There is a R&D STAY that is already approved or has been approved',
  '삭제 할 내용 선택해 주세요.':'Please select the contents to delete',
  '사번을 적용해주세요.' : 'Please enter your ID',
  '발송 장소 선택' : 'Please select the person to be sent.',
  '입숙희망일' : 'Accommodation request date',
  '입숙 희망일을 입력해주세요.' : 'Please enter the day you enter the accommodation.',
  '주의사항 : 1인1대 현대.기아 차량에 한하여 차량등록 가능합니다.' : 'Note : One person One car Only Hyundai and Kia cars can be registered.',
  '이용 신청이 접수되었습니다. 관리자가 신청 내용 및 서류 확인 후 승인 및 배방이 이루어지며 관련 내용은 마이페이지 > 신청관리 메뉴에서 확인이 가능합니다. 신청된 이용자 정보는 하기와 같습니다.':
  'Your application has been received. After the administrator checks the application details and documents, approval and release are made. You can check the information in the My Page > Application Management menu. User information is as follows.',
  '이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 기숙사 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.':
  'Those registered in the user information can use the accommodation. Visit the accommodation office on the boarding house assignment room and the accommodation date. You can check your personal information, receive the supplies, and enter the room.',
  '코로나 관련하여 개인 위생을 철저히 지켜주시기 바라며, 정부의 행동지침에 따라 주시기 바랍니다. 코로나 의심증상 있을시 연구소 코로나 상황실(031-368-6510)연락주시기 바랍니다.':
  "Please, keep your personal hygiene strictly. Please follow the government's guidelines for action. Corona Situation control office (031-368-6510) in case of suspected corona symptoms. Please contact us.",
  '이용 신청이 정상적으로 접수 되었습니다. 관리자가 신청 내용 확인 후 사업장 별로 승인 및 배방 오픈이 이루어지며 관련 내용은 마이페이지 > 신청관리 메뉴에서 확인이 가능합니다.':
  'Your application for use has been received normally. After the manager checks the application, approval and distribution are opened for each workplace, and related information can be found in the My Page > Application Management menu.',
  '이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 출장자(단체) 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.':
  'The person registered in the user information can use the accommodation. You can visit the management office on the day you are assigned to a business trip (organization) and check your personal information before entering the room.',
  '이용 신청이 접수 및 승인되어 예약이 완료되었습니다. 신청 접수된 이용자 정보의 본인만이 숙소이용이 가능합니다. 입숙예정일에 해당 사업장의 관리사무소로 방문하시면 관리사무소 직원의 안내에 따라 입숙절차가 마무리되고 ':
  'Your application for use has been received and approved, and your reservation has been completed. Only you who have received the application can use the accommodation. If you visit the management office of the relevant workplace on the scheduled date of occupancy, the procedure will be completed according to the guidance of the management office staff,',
  '이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 출장자(개인) 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.':
  'A person registered with the user information can use the accommodation, and visit the management office on the day of the assignment of the traveler (personal) and the boarding date to check the personal information and enter the accommodation after receiving the payment equipment.',
  '이용 신청이 접수 되었습니다. 관리자가 확인 후 매월 25일 승인여부가 결정됩니다. 입숙 선정자로 승인이 되면 문자가 발송 되오니 문자 확인이 되시면 마이페이지 신청관리에서 배방 확인 및 입숙 예정일을 입력해 주세요. 신청된 이용자 정보는 하기와 같습니다.':
  "Your application for use has been received. Approval will be determined on the 25th of each month after confirmation by the administrator. If you are approved as a room-in-place picker, you will be sent a text message, so if you can check the text, please check the delivery and enter the expected date of your room-in-place in My Page's application management. The user information requested is as follows.",
  '이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 사택 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.':
  'A person registered in the user information can use the accommodation, and visit the management office on the day of the house assignment and the boarding date to check the personal information and enter the accommodation after receiving the payment equipment.',
  '이용 신청이 정상적으로 접수되었습니다. 관리자가 신청 내용 확인 후 사업장 별로 승인 및 배방이 완료됩니다. 관련 내용은 마이페이지 > 신청관리 메뉴에서 확인이 가능합니다.':
  'Your application for use has been received normally. After the administrator confirms the application, approval and distribution are completed for each workplace. You can check the information in the My Page > Application Management menu.',
  '이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 R&D STAY 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.':
  'A person registered in the user information can use the accommodation, visit the management office on the day of the R&D STAY assignment, check the personal information, and receive the payment equipment before entering the accommodation.',
  '이용 신청이 접수되었습니다. 관리자가 신청 내용 서류 확인 후 사업장 별로 승인 및 배방이 완료됩니다. 관련 내용은 마이페이지 > 신청 관리 메뉴에서 확인이 가능합니다.':
  'Your application for use has been received. After the administrator checks the application documents, approval and distribution are completed by business establishment. Related information can be found in the My Page > Application Management menu.',
  '이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 영빈관 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.':
  'A person registered in the user information can use the accommodation, and visit the management office on the day of guest house assignment and reception to check the personal information and enter the accommodation after receiving the payment equipment.',
  '근무조 : 상시주간' : 'Working group : Daytime worker',
  '입숙희망일 : 기숙사 입숙 검토 3일이상 소요됩니다.' : 'Accommodation Date: It will take more than 3 days to review dormitory occupancy.',
  '차량번호 : 현대/기아차 한해 1인 1주대 주차가능 (문의 : 기숙사 관리 사무소 031-356-4285)' : '차량번호 : 현대/기아차 한해 1인 1주대 주차가능 (문의 : 기숙사 관리 사무소 031-356-4285) (en)',
  '기타인 경우 내용을 입력해주세요': 'In other cases, please enter the contents.',
  '입숙 서약서 다운로드 -> 확인후 사인 -> 증빙서류 파일업로드 시 서약서 파일업로드 하여 서류 제출 요청드립니다.' : "Please check the 'Download the Accommodation Pledge -> Sign -> Upload the document file and submit the document.",
  '기숙사 신청전 이용안내 탭에서 기숙사 입숙조건 및 기타 내용 확인 후 신청해주세요.' : '기숙사 신청전 이용안내 탭에서 기숙사 입숙조건 및 기타 내용 확인 후 신청해주세요. (en)',
  '사택 신청전 이용안내 탭에서 사택 입숙조건 및 기타 내용 확인 후 신청해주세요.' : '사택 신청전 이용안내 탭에서 사택 입숙조건 및 기타 내용 확인 후 신청해주세요. (en)',
  '기타인 경우 분류를 선택해주세요': '기타인 경우 분류를 선택해주세요 (en)',
  '증빙서류인 지방세세목별과세증명서는 아래의 [과세증명서 매뉴얼]을 다운받아 확인 후 발급하여 업로드하여 하시기 바랍니다.':'For the proof of taxation by local tax category, please download and check the [Taxation Certificate Manual] below, then issue and upload it.',
  '# 발급처는 구)동사무소만 가능합니다. (인터넷,발급기 발급 불가)':'# The issuer is only available at the old)-dong office. (Internet and issuing machines are not available)'
};

export default en;
