import React, { useState } from 'react';

import { excelToJson } from '../utils/file';

const Excel = (props: any) => {
  const [excelData, setExcelData] = useState([]);

  const onChangeHandler = async (e: any) => {
    const d: any = await excelToJson(e.target.files[0]);
    setExcelData(d);
  };

  return (
    <>
      <input type="file" onChange={onChangeHandler} />
      {excelData.map((row: any, idx: number) => (
        <React.Fragment key={idx}>
          <div>
            <span>{row.a}</span>
            <span>{row.b}</span>
            <span>{row.c}</span>
            <span>{row.d}</span>
            <span>{row.e}</span>
          </div>
        </React.Fragment>
      ))}
    </>
  );
};

export default Excel;
