import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { MessageContext } from '../../routes';

import intl from 'react-intl-universal';
import queryString from 'query-string';
import _ from 'lodash';

import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

import StepNav from '../../components/molecules/reservationStepNav';
import DefaultTemplate from '../../components/templetes/default';

import { isRangeOverlapping } from '../../utils/date';

import { User, Valid, Rfc } from './type.d';


import { ROOM_TYPE_CODE, WORK_SITE } from '../../constant';
import { lodge } from '../../apis';
import { getWorkSitePhoneNum } from '../../utils/commons';
import { SHOW_MODAL } from '../../store/modules/modal';

const BusinessRoomGroupPage = (props: any) => {
  const { info } = useContext(MessageContext);

  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user,
  }));

  const [step, setStep] = useState<number>(1);

  const [workSite, setWorkSite] = useState<number>(-1);
  const [roomType, setRoomType] = useState<number>(ROOM_TYPE_CODE.business_room_group); // 출장자 숙소(1), 영빈관(2)
  const [guests, setGuests] = useState([]);
  const [mealTicket, setMealTicket] = useState<number>(0);
  const [rfc, setRfc] = useState<Rfc>({})
  const [excelFiles, setExcelFiles] = useState<any>([]);

  const addGuest = (guest: any, groupType: number) => {
    let added = guests.concat(guest);
    setGuests(added)
  };

  const overwriteGuest = (guests: any) => setGuests(guests)

  const deleteGuest = (checkUsersIdx: any) => setGuests(guests.filter((item: any, idx: number) => !checkUsersIdx[idx]));

  useEffect(() => {
    // console.log(guests);
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    const qs: any = queryString.parse(window.location.search);
    //setRoomType(parseInt(qs.roomType || 1));

    if (userInfo.worksite >= 0) {
      setWorkSite(userInfo.worksite);
    } else {
      setWorkSite(parseInt(qs.workSite || 2));
    }
  }, [window.location.search, userInfo.worksite]);

  const onSubmitHandler = async () => {
    const payload: any = {
      work_site: workSite,
      guests,
      meal_ticket: mealTicket
    };

    if (rfc?.file) {
      payload.files = [{
        file: rfc.file,
        document: rfc.document
      }]
    }

    try {
      const { data } = await lodge.reservationByBusinessRoomGroup(payload);

      // let guestsCellPhone = guests.map((item: any) => {
      //   return item.guest_cellphone;
      // });

      guests.map(async(item: any) => {
        // 예약 완료 문자 발송
        let smsPayload: any = {
          cellphone : item.guest_cellphone,
          message: '입숙신청되었습니다. \n신청정보 - 숙소유형 : 출장자숙소(단체) \n- 이용 사업장 : '+ WORK_SITE[workSite] +'  \n- 이용 기간 : ' + item.stay_after + ' - ' + item.stay_before
                    +'\n신청진행 확인은 "마이페이지>>신청관리" 확인부탁드리며 기타 문의는 관리사무소 전화번호 '+getWorkSitePhoneNum(workSite, ROOM_TYPE_CODE.business_room_group)
        }
        const { data: sms } = await lodge.sendSMS(smsPayload);
      });
  
      setStep(3);
    } catch ({ status, data }) {
      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }else if (status === 400) {
        for (let i = 0; i < data?.guests?.length; ++i) {
          if (data?.guests?.is_leader) {
            info(intl.get('예약 중 문제가 발생했습니다.') + ' - ' + `${data.guests.is_leader}`);
            return;
          }
          if (
            data?.guests &&
            data?.guests[i]['employee_number'] &&
            data?.guests[i]['employee_number']?.length
          ) {
            info(
              `${i + 1}` + intl.get('번째 사번은 등록되지 않았습니다.') + `${data.guests[i]['employee_number']}`,
            );
            return;
          }
        }
      }

      //let msg = intl.get(data?.code);
      let msg = data?.code;
      if (msg) {
        if(msg === "duplicate_guest"){
          info("이미 이용중인 사용자가 있습니다");
          return;
        }
        info(msg);
        return;
      }

      info(intl.get("예약 중 문제가 발생했습니다."));
    }
  };

  const isLeaderExist = () => {
    const leaders = guests.filter(
      (guest: any, guestIdx: number) =>
        guest.is_leader,
    );
    return !!leaders.length;
  };

  const isAddedUserCheck = ({
    privacyPolicyAgreed, personalInformationCollectionAgreed, name, phone, dateAfter, dateBefore, gender
  }: User): Valid => {

    let isValid = false;
    let msg = "";

    if (!privacyPolicyAgreed || !personalInformationCollectionAgreed) {
      isValid = true
      msg = intl.get('서비스이용약관 동의 또는 개인정보이용동의를 동의하지 않았습니다.');
    }

    if (!name) {
      isValid = true
      msg = intl.get('성명을 입력해주세요.');

    } else if (!phone) {
      isValid = true
      msg = intl.get('휴대폰번호 항목이 비었거나 잘못되었습니다.\n(휴대폰번호는 11자리입니다.)');

    } else if (!dateAfter) {
      isValid = true
      msg = intl.get('입숙일 항목이 비었습니다.');

    } else if (!dateBefore) {
      isValid = true
      msg = intl.get('퇴숙일 항목이 비었습니다.');

    } else if (!gender) {
      isValid = true
      msg = intl.get('성별을 선택하지 않았습니다.');
    }

    if (msg) {
      info(msg);
    }

    return { isValid, msg }
  }

  const isOverlapping = (guests: any) => {
    let ovrelappings: any = {}

    guests.forEach((targetGuest: any, targetGuestIdx: number) => {
      guests.forEach((compareGuest: any, compareGuestIdx: number) => {
        if (targetGuestIdx !== compareGuestIdx) {
          let isSameGuest =
            targetGuest.guest_name === compareGuest.guest_name
            && targetGuest.guest_cellphone === compareGuest.guest_cellphone
            && targetGuest.stay_after === compareGuest.stay_after
            && targetGuest.stay_before === compareGuest.stay_before
          if (isSameGuest) {
            let overlappingNum: number = isRangeOverlapping(targetGuest, compareGuest)
            if (overlappingNum) {
              ovrelappings[targetGuestIdx] = compareGuestIdx
            }
          }
        }
      })
    })

    if (Object.keys(ovrelappings).length) {
      info(intl.get("동일한 이용자 명으로 중복된 날짜 정보가 있습니다."));
    }
    // console.log('[PAGE]: reservation/business/group, Validation: ')
    // console.log(ovrelappings);

    return { isValid: !!Object.keys(ovrelappings).length };
  }


  return (
    <DefaultTemplate
      id="wrap"
      className="wrap_request"
      {...props}
      leftInfo={
        <>
          <h2>{intl.get("출장자숙소(단체)")}</h2>
          <h4 className="title_step">{intl.get("신청단계")}</h4>
          <StepNav.BusinessGroupNav step={step} />
        </>
      }
    >
      {step === 1 ? (
        <Step1
          workSite={workSite}
          setWorkSite={setWorkSite}
          roomType={roomType}
          setRoomType={setRoomType}
          setMealTicket={setMealTicket}
          guests={guests}
          setGuests={setGuests}
          nextStep={() =>
            isLeaderExist()
              ? setStep(2)
              : info(intl.get('이용자 중 인솔자는 반드시\n 1명 이상 선택되어야 합니다.\n 인솔자 여부를 확인해주세요.'))
          }
          addGuest={addGuest}
          deleteGuest={deleteGuest}
          history={props.history}
          isAddedUserCheck={isAddedUserCheck}
          isOverlapping={isOverlapping}
          setRfc={setRfc}
          overwriteGuest={overwriteGuest}
          rfc={rfc}
          excelFiles={excelFiles}
          setExcelFiles={setExcelFiles}
        />
      ) : step === 2 ? (
        <Step2
          onSubmitHandler={onSubmitHandler}
          nextStep={() => setStep(3)}
          prevStep={() => setStep(1)}
          history={props.history}
          guests={guests}
          workSite={workSite}
          roomType={roomType}
        />
      ) : step === 3 ? (
        <Step3
          workplace={workSite}
          roomType={roomType}
          guests={guests}
          nextStep={() => setStep(4)}
          prevStep={() => setStep(2)}
          history={props.history}
        />
      ) : (
              <></>
            )}
    </DefaultTemplate>
  );
};

export default BusinessRoomGroupPage;
