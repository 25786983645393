import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import store, { root } from './store';
import { RootStore } from './store/mobx/rootStore';
import Routes from './routes';

(window as any).store = store;
const rootStore = new RootStore();
(window as any).store = store

function App() {

  return (
    <root.StoreProvider value={ rootStore }>
      <Provider store={store}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </Provider>
    </root.StoreProvider>
  );
}

export default App;
