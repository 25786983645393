import React from 'react';
import { useSelector } from 'react-redux';

import { ROOM_TYPE, ROOM_TYPE_CODE, VIP_USABLE_BY_POSITIONS_ASAN, VIP_USABLE_BY_POSITIONS_JEONJU, VIP_USABLE_BY_RANKS} from '../../../constant';
import _, { includes } from 'lodash';
import { useStores } from '../../../store/mobx';

interface option {
  label: string,
  roomType: number
  isShow: Function
}

interface Props {
  onChange: Function
  value: number
  options?: option[]
  selectWorkSite?: number
  userWorkSite?: number
  roomType?: number
}

const hideDomitoryHouses= [1, 2, 5, 6, 7];

//숙소타입 버튼
const RoomType = (props: Props) => {
  const { userInfo= { } } = useSelector((state: any) => ({
    userInfo: state.user
  }))
  const {headerStore: headerStore } = useStores();
  const showFamilies = [2, 4];
  const hideCompanyHouses = [1];
  const isShowFamily = () => _.includes(showFamilies, userInfo.worksite);//&& (userInfo.lodging && (userInfo.lodging.room_type === 2 && userInfo.lodging.stay_after))

  const defaultOptions: option[] = [
    { label: "전체", roomType: 0, isShow: () => true},
    { label: ROOM_TYPE[ROOM_TYPE_CODE.business_room], roomType: ROOM_TYPE_CODE.business_room, isShow: () => true, },
    { label: ROOM_TYPE[ROOM_TYPE_CODE.business_room_group], roomType: ROOM_TYPE_CODE.business_room_group, isShow: () => true },
    { label: ROOM_TYPE[ROOM_TYPE_CODE.vip_room], roomType: ROOM_TYPE_CODE.vip_room, isShow: () => userInfo.worksite === 2 && VIP_USABLE_BY_RANKS.includes(userInfo.rank) || userInfo.worksite === 3 && VIP_USABLE_BY_POSITIONS_JEONJU.includes(userInfo.position) || userInfo.worksite === 4 && VIP_USABLE_BY_POSITIONS_ASAN.includes(userInfo.position) || VIP_USABLE_BY_RANKS.includes(userInfo.position) && true},
    { label: ROOM_TYPE[ROOM_TYPE_CODE.dormitory_room], roomType: ROOM_TYPE_CODE.dormitory_room , isShow: () => _.includes(hideDomitoryHouses, userInfo.worksite) && userInfo.isMarried === false || userInfo.worksite === 3 || userInfo.worksite === 4},
    { label: ROOM_TYPE[ROOM_TYPE_CODE.company_room], roomType: ROOM_TYPE_CODE.company_room, isShow: () => userInfo.worksite === 2 && userInfo.isMarried === true || userInfo.worksite === 3 || userInfo.worksite === 4},
    { label: ROOM_TYPE[ROOM_TYPE_CODE.family_room], roomType: ROOM_TYPE_CODE.family_room, isShow: () => headerStore._showFamily && isShowFamily() },
  ]

  const options: option[] = props.options || defaultOptions;
  
  return (
    <>  
      <ul className="tabs">
        {options.map((option: option, optionIdx: number) => (
          <li
            onClick={() => props.onChange(option.roomType)}
            style={{display: option.isShow() ? "" : "none"}}
          >
            <a
              href="javascript:void(0)"
              className={`${props.value === option.roomType ? 'on' : ''}`}
            >
              {option.label}
            </a>
          </li>
        ))}
      </ul>
    </>
  );
};

export default RoomType;
