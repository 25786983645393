export const ERROR_CODE: {[key: string]: string} = {
  "not_requested": "인증을 진행하지 않았습니다.",
  "cannot_find_user": "사번과 핸드폰 번호로 사용자를 찾을 수 없습니다.",
  "invalid_number": "인증번호가 일치하지 않습니다.",
  "invalid_token": "잘못된 요청입니다.",
  "not_verified": "인증이 필요합니다.",
  "wrong_personnel": "인사디비 조회중 문제가 발생했습니다.",
  "wrong_user": "입력한 사용자 정보에 문제가 발생했습니다.",
  "permission_denied": "권한이 없습니다.",
  "married_only": "결혼을 한 사람만 신청할 수 있습니다.",
}