// 이용약관, 개인정보처리방침 자세히보기
import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';
import intl from 'react-intl-universal';
import { build, lodge } from '../../../apis';
import { LODGE_STATUS_CODE, ROOM_TYPE, ROOM_TYPE_CODE, VOC_ETC_CATEGORY, WORK_SITE_CODE } from '../../../constant';
import { MessageContext, MessageImpl } from '../../../routes';
import { getTodayDate } from '../../../utils/date';
import './index.scss';
import _, { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { SHOW_CONFIRM_MODAL, SHOW_MODAL } from '../../../store/modules/modal';

//장소선택 팝업
const PlaceOccurrence = (props: any) => {
  const dispatch = useDispatch();
  const etcIndex = props.places.findIndex((place: any) => place.building_name === '기타');
  const [room_types, setRoom_types] = useState<any[]>([]);
  const [buildings, setBuildings] = useState<any[]>([]);
  const [floors, setFloors] = useState<any[]>([]);
  const [room_type, setRoom_type] = useState<number>(-1);
  const [building, setBuilding] = useState<number>(-1);
  const [places, setPlaces] = useState<any[]>(props.places);
  const [etcImageUrl, setEtcImageUrl] = useState<string>('');
  const [etcText, setEtcText] = useState<string>('내부');
  const [etcCategory, setEtcCategory] = useState<any>([]);
  const [etcText2, setEtcText2] = useState<string>('');
  // const [isOpen, setIsOpen] = useState<boolean>(props.isOpen);
  const { info, warning, error }: MessageImpl = useContext(MessageContext);
  const [workSite, setWorkSite] = useState<number>(0);

  useEffect(() => {
    const items = [{ value: -1, label: intl.get('숙소유형 선택')}];
    Object.keys(ROOM_TYPE).filter(key => key !== '0')
      .forEach(key => items.push({ value: Number(key), label: ROOM_TYPE[key] }));
    setRoom_types(items);
  }, []);

  useEffect(() => {
    (async () => {
    // 이용중인 기숙사 list
      const guestPayload: any = {
        status: LODGE_STATUS_CODE.checkin,
        // room_type: ROOM_TYPE_CODE.dormitory_room
      };

      try{
        const { data:guestList }: any = await lodge.getGuest(guestPayload);

        const domi = guestList.filter((dom:any, index:number)=> dom.room_type === ROOM_TYPE_CODE.dormitory_room);
        const busy = guestList.filter((busi:any, index:number)=> busi.room_type === ROOM_TYPE_CODE.business_room);
        const com = guestList.filter((comp:any, index:number)=> comp.room_type === ROOM_TYPE_CODE.company_room);
        const vip = guestList.filter((comp:any, index:number)=> comp.room_type === ROOM_TYPE_CODE.vip_room);
        
        if(busy.length > 0){
          setWorkSite(WORK_SITE_CODE[busy[0].room_work_site]);
          props.setWorkSite(WORK_SITE_CODE[busy[0].room_work_site]);
        }else if(vip.length > 0){
          setWorkSite(WORK_SITE_CODE[vip[0].room_work_site]);
          props.setWorkSite(WORK_SITE_CODE[vip[0].room_work_site]);
        }else if(domi.length > 0){
          setWorkSite(WORK_SITE_CODE[domi[0].room_work_site]);
          props.setWorkSite(WORK_SITE_CODE[domi[0].room_work_site]);
        }else if(com.length > 0){
          setWorkSite(WORK_SITE_CODE[com[0].room_work_site]);
          props.setWorkSite(WORK_SITE_CODE[com[0].room_work_site]);
        }else{
          setWorkSite(props.user.worksite);
        }
      }catch ({ status, data }) {
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }
        
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const init = () => setBuildings([{id: -1, name: intl.get('건물명 선택')}]);
      if (room_type < 0) {
        init();
      }else if(room_type === 7){
        // 발생장소 기타 선택한 경우 selectbox
        let tmpList:any = VOC_ETC_CATEGORY.find(c => c.name === etcText);
        setEtcCategory([
          ...tmpList.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child }))
        ]);
        setEtcText2(tmpList.child[0].name);
      }else {
        try {
          const { data } = await build.getBuilds({ work_site: workSite, room_type });
          if (data.length > 0) {
            setBuildings([{id: -1, name: intl.get('건물명 선택')}, ...data]);
          } else {
            init();
          }
        }catch ({ status, data }) {
          if (status === 401) {
            window.location.href = '/force/signout';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }

          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '문제발생',
              msg: '문제가 발생했습니다',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }
      }
    })();
  }, [room_type]);

  useEffect(() => {
    if (buildings.length > 1) {
      setBuilding(buildings[1].id);
    } else {
      setBuilding(-1);
    }
    setFloors([]);
  }, [buildings]);

  useEffect(() => {
      const init = () => setFloors([]);
      (async () => {
      if (building < 0) {
        init();
      }  else {
        try {
          const today = getTodayDate()[0];
          const { data } = await build.getFloors({
            building,
            stay_after: today,
            stay_before: today,
          });
          if (data.length > 0) {
            if (room_type !== 7) {
              let d = _.cloneDeep(_.orderBy(data, 'step', 'desc'))
              setFloors(data);
            } else {
              setFloors(data);
            }
          } else {
            init();
          }
        }catch ({ status, data }) {
          if (status === 401) {
            window.location.href = '/force/signout';
            return ;
          } else if (status === 403) {
            (window as any).store.dispatch({
              type: SHOW_MODAL,
              payload: {
                title: '권한오류',
                msg: '접근권한이 없습니다.',
                redirectPath: false,
                statusCode: status,
                errorCode: '',
                type: 'error',
                isModalShow: true,
              },
            });
            return ;
          }else if ( status === 500 ) {
            window.location.href = '/500';
            return;
          }

          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '문제발생',
              msg: '문제가 발생했습니다',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }
      }
      selectedEtcObject(building);
    })();
  }, [building]);

  useEffect(() => {
    if (props.isOpen && etcIndex > -1) {
      setRoom_type(6);
    }
    // console.log('props.isOpen:', props.isOpen);
  }, [props.isOpen]);

  const close = async () => {
    setBuildings([]);
    setFloors([]);
    setRoom_type(-1);
    setBuilding(-1);
    setPlaces([]);
    props.setIsOpen(false);
  };

  const onApply = () => {
    // console.log('building -------------> ', building, places);
    // 숙소유형을 기타로 선택한 경우
    if(room_type === 7){
      if(!etcText || etcText === '' || !etcText2 || etcText2 === '') {
        info(intl.get('기타인 경우 분류를 선택해주세요'));
        return;
      }

      props.onApply([], room_type, etcText, etcText2);
      close();
    }else {
      if (places.length === 0) {
        // if(Number(building) > 0){
        //   setBuildingFn();
        //   return;
        // }else{
          info(intl.get('선택된 장소가 없습니다.'));
          return;
        // }
      }
      
      props.onApply(places, room_type, '', '');
      close();
    }
  };

  // 숙소 방 선택 X -> 건물/동 선택으로 해주기
  const setBuildingFn = async() => {
    let tmpBuild = buildings.find(bd => bd.id === building);

    dispatch({
      type: SHOW_CONFIRM_MODAL,
      payload: {
        title: '',
        msg: tmpBuild.name + ' ' + tmpBuild.dong + '으로 하시겠습니까?',
        redirectPath: false,
        statusCode: false,
        errorCode: '',
        type: 'info',
        isModalShow: true,
        action: () => {
          let tempPlace =[{
            building_id: building,
            building_name: tmpBuild.name,
            building_dong: tmpBuild.dong
          }];
      
          // console.log('tmpBuild => ', tmpBuild, tempPlace);
          
          //setPlaces(tempPlace);
          props.onApply(tempPlace);
          close();
        }
      },
    });
  }

  const onRoomTypeChnage = async (e: any) => {
    const id = Number(e.target.value);
    setRoom_type(id);
  };

  const onBuildingChnage = async (e: any) => {
    const id = Number(e.target.value);
    setBuilding(id);
  };

  const onSelectedRoom = (room: any, floor: any = null) => {
    const _places =  [ ...places ];
    const index = _places.findIndex(place => place.id === room.id);
    if (index > -1) {
      _places.splice(index, 1);
    } else {
      const item = buildings.find(bd => bd.id === building);
      _places.push({
        id: room.id,
        building_name: item.name,
        building_dong: item.dong,
        floor_name: floor.step,
        room_name: room.name,
      });
    }
    setPlaces(_places);
  };

  const selectedEtcObject = (buildingId: number): any | null => {
    if (room_type === 7) {
      const buildingItem = buildings.find(item => item.id === buildingId);
      if (buildingItem) {
        setEtcImageUrl(`${process.env.REACT_APP_MEDIA}/${buildingItem.map_image}`);
      }
    }
  };

  const GroupSelectLocation = () => {
    const buildingName = () => {
      const buildingItem = buildings.find(bd => bd.id === building);
      return buildingItem ? buildingItem.name + '' + buildingItem.dong : '';
    }
    return floors.length > 0 ? (
      <>
        <div className="type_area">
          {floors.map((floor: any, index: number) => (
            <ul key={index} className={['lst_floortype'].join(' ')}>
              {floor.rooms.map((room: any) => (
                <li
                  key={room.id}
                  onClick={() => onSelectedRoom(room, floor)}>
                  <a
                    href="javascript:void(0);"
                    className={[ places.findIndex(place => place.id === room.id) > -1 ? 'on' : ''].join(' ')}>
                    {room.name}호
                  </a>
                </li>
              ))}
            </ul>
          ))}
        </div>
        <div className="txt_current">{buildingName()}</div>
      </>
    ) : (<div className="txt_current">숙소가 존재하지 않습니다.</div>);
  };

  const GroupCoordinate = () => {
    return floors.length > 0 ? (
      <div className="group_coordinate">
        <ul className="lst_top">
          {floors[0].rooms.map((room: any, index: number) => (
            <li key={index}><span>{room.name}</span></li>
          ))}
        </ul>
        <ul className="lst_left">
          {floors.map((bd, index) => (
            <li key={index}><span>{index + 1}</span></li>
          ))}
        </ul>
        <div className="img_area">
          { etcImageUrl ? <img src={`${etcImageUrl}`} alt=""/> : <></> }
          <ul className="lst_area">
            {floors.map((floor: any, fIndex: number) =>
              floor.rooms.map((room: any, rIndex: number) => (
                <li key={room.id} onClick={() => onSelectedRoom(room, floor)}>
                  <a href="javascript:void(0);"
                    className={[places.findIndex(place => place.id === room.id) > -1 ? 'active' : ''].join(' ')}>
                  </a>
                </li>
              ))
            )}
          </ul>
        </div>
      </div>
    ) : (<></>);
  };

  const onDeletePlace = (_place: any) => {
    const _places = places.filter(p => p.id !== _place.id);
    setPlaces(_places);
  };

  return (
    <div
      id="pop_select_spot"
      className="pop_dim_wrap pop_modal"
      style={{ display: props.isOpen ? 'block' : 'none' }}>
      <div className="pop_wrap">
        <div className="title_wrap">
          <h3>{intl.get('발송 장소 선택')}</h3>
          <button
            className="pop_btn_close pop_close"
            onClick={() => props.setIsOpen(false)}>
            {intl.get('닫기')}
          </button>
        </div>
        <div className="contents_wrap">

          <div className="select_keyword">
            <select
              name=""
              id=""
              className="form_control"
              value={room_type}
              onChange={onRoomTypeChnage}>
              {room_types.map((item, index) => (
                <option key={index} value={item.value}>{item.label}</option>
              ))}
            </select>
            {room_type !== 7 ? (
              <select
                name=""
                id=""
                className="form_control"
                value={building}
                onChange={onBuildingChnage}
                //disabled={room_type === 7}
              >
                {/* <option value="-1">건물명 선택</option> */}
                {buildings.map((item: any, index: number) => (
                  <option key={`${room_type}_${index}`} value={item.id}>{item.name}{isEmpty(item.dong) ? '' : ("\("+item.dong+"\)")}
                  </option>
                ))}
              </select>
            ) :(
              <>
                <select
                  name=""
                  id=""
                  className="form_control"
                  value={etcText}
                  onChange={(e:any)=>{
                    setEtcText(e.target.value);
                    let tmpList:any = VOC_ETC_CATEGORY.find(c => c.name === e.target.value);
                    setEtcCategory([
                      ...tmpList.child.map((i: any) => ({ value: i.name, name: i.name, child: i.child }))
                    ]);
                    setEtcText2(tmpList.child[0].name);
                  }}
                >
                  {VOC_ETC_CATEGORY.map((category: any, index: number) => (
                    <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
                  ))}
                </select>
                <select
                  name=""
                  id=""
                  className="form_control"
                  value={etcText2}
                  onChange={(e:any)=>{setEtcText2(e.target.value)}}
                >
                  {etcCategory.map((category: any, index: number) => (
                    <option key={`${category.name}_${index}`} value={category.name}>{category.name}</option>
                  ))}
                </select>
              </>
            )}
          </div>
          {room_type !== 7 ? 
            (<>
              <GroupSelectLocation />
              <div className="function_area">
                <button className="btn btn_sm btn_reset" onClick={() => setPlaces([])}>{intl.get('초기화')}</button>
              </div>
              <div className="items_selected">
                {places.map((_place, index) => (
                  <a
                    key={index }
                    href="javascript:void(0);"
                    className="item del"
                    onClick={() => onDeletePlace(_place)}
                    >
                    {
                      _place.building_name === '기타'
                      ? intl.get('_번 지역', { place: `${_place.building_name} > ${_place.room_name}-${_place.floor_name}`})
                      : intl.get('_호', { room:`${_place.building_name} > ${_place.building_dong ? `${_place.building_dong} > ` : '' } ${_place.room_name}`})
                    }
                  </a>
                ))}
              </div>
          </>) 
          : (<>
              {/* <div className="">
                <input
                    name=""
                    id=""
                    className="form_control w100"
                    placeholder={intl.get('기타인 경우 내용을 입력해주세요')}
                    value={etcText}
                    maxLength={100}
                    onChange={(e:any) => {setEtcText(e.target.value)}}
                />
                </div> */}
            </>
          )}
        </div>
        <div className="button_wrap btn_length_1">
          <button type="button" className="btn btn_xlg btn_darkblue pop_close" onClick={onApply}>{intl.get('적용')}</button>
        </div>
      </div>
    </div>
  );
};

export default PlaceOccurrence;
