import React, { useState, useEffect, useContext} from 'react';
import _, { range } from 'lodash';
import { useStores } from '../../../store/mobx';
import { MessageContext, MessageImpl } from '../../../routes';
import { getTodayDate, getStayHourConfirm, getYear5, getYear, getMonth} from '../../../utils/date';
import { SHOW_MODAL } from '../../../store/modules/modal';
import { LODGE_STATUS_CODE, ROOM_TYPE_CODE, WORK_SITE_CODE } from '../../../constant';
import { useSelector } from 'react-redux';
import { addMonths } from 'date-fns';
import format from 'date-fns/format';
import { lodge } from '../../../apis';
import { onlyNumberFormatter } from '../../../utils/commons';

//공제내역 팝업
const DeductDetail = (props: any) => {
  const { userInfo = {} } = useSelector((state: any) => ({
      userInfo: state.user
  }));
  //공제내역 조회 전용 year , month
  const[year, setYear] = useState<any>(getYear());
  const[month, setMonth] = useState<any>(getMonth());
  const [click, setClick] = useState<any>(false);
  const [dedcutData , setDeductData] = useState<any>([]);

  useEffect(() => {
    if(!props.isShowDeductPop) return;
    (async () => {
      
      initDatas();
      setDeductData([]);
      await getDeductList();
    })();

  }, [props.isShowDeductPop]);

  useEffect(() => {
    if(!props.isShowDeductPop) return;
    //console.log(year + '' +month);
    (async () => {
      setDeductData([]);
      await getDeductList();
    })();
  }, [year,month]);


  const initDatas = () => {
    const date = format(addMonths(new Date(getTodayDate()[0]), -1), 'yyyy-MM-dd').split('-');
    setYear(date[0]);
    setMonth(date[1]);
  }
  //공제내역 년월 바꿀때 재조회 async function
  const changeYear = async (thisYear : any) => {
    setYear(thisYear);
    //await getDeductList();
  }

  const changeMonth = async (thisMonth : any) => {
    setMonth(thisMonth);
    //await getDeductList();
  }

  const getDeductList = async() => {
    
    //룸타임 : 기숙사,사택 , 사번, 동호수, 년월   
    //worksite, roomtype, employee_number, dong, ho, dedut_date
      //console.log(userInfo);
      //console.log('props>>>>>>>>>>>>>>>>>>>>>>>>', props);
      //console.log('props>>>>>>>>>>>>>>>>>>>>>>>>', props.deductData.history.location.state);
    const payload: any = {
      employee_number : props.deductData.history.location.state.guest_employee_number,
    }
    //사업장 
    if(WORK_SITE_CODE[props.deductData.history.location.state.room_work_site] === WORK_SITE_CODE.울산) {
      payload.worksite = '울산'
    } else if(WORK_SITE_CODE[props.deductData.history.location.state.room_work_site] === WORK_SITE_CODE.남양 ) {
      payload.worksite = '남양'
    } else if(WORK_SITE_CODE[props.deductData.history.location.state.room_work_site] === WORK_SITE_CODE.전주공장 ) {
      payload.worksite = '전주'
    } else if(WORK_SITE_CODE[props.deductData.history.location.state.room_work_site] === WORK_SITE_CODE.아산공장 ) {
      payload.worksite = '아산'
    } else {
      return;
    }
    
    //룸타입
    if(props.deductData.history.location.state.room_type == ROOM_TYPE_CODE.dormitory_room) { 
      //기숙사
      payload.roomtype = '기숙사'
    } else if(props.deductData.history.location.state.room_type == ROOM_TYPE_CODE.company_room) {
      //사택
      payload.roomtype = '사택'
    } else if(props.deductData.history.location.state.room_type == ROOM_TYPE_CODE.business_room && WORK_SITE_CODE[props.deductData.history.location.state.room_work_site] === WORK_SITE_CODE.울산) {
      //울산 의 출장자 숙소(개인)
      payload.roomtype = '출장자숙소'
    } else {
      props.onClose();
      return;
    }
  
    //조회날짜
    //console.log(year);
    //console.log(month);
    
    if(props.deductData.history.location.state.status === LODGE_STATUS_CODE.checkin) {
      //이용중일때
      payload.deduct_date = year+month;
    } else if(props.deductData.history.location.state.status === LODGE_STATUS_CODE.checkout) {
      //퇴숙시 
      /*let checkoutDate = props.deductData.history.location.state.checkout_at.split('-');
      let chkecoutDateYear = checkoutDate[0];
      let chkecoutDateMonth = checkoutDate[1];
      let paramYear = '';
      let paramMonth = '';
      // 체크아웃 한 년도 
      if(chkecoutDateYear === year) {
        paramYear = year;
      } else {
        paramYear = chkecoutDateYear;
        setYear(chkecoutDateYear);
      }
      //선택된 월과 퇴숙 월 비교후 같으면 선택된 월 다르면 퇴숙월로 세팅 
      if(chkecoutDateMonth === month) {
        paramMonth = month;
      } else {
        paramMonth = chkecoutDateMonth;
        setMonth(chkecoutDateMonth);
      }*/
      payload.deduct_date = payload.deduct_date = year+month;
      payload.checkout_id = props.deductData.history.location.state.checkout_id;
    } else {
      props.onClose();
      return;
    }
    //남양일때 어떻게? 뉴타운 본동??
    if(props.deductData.history.location.state.room_building_name && WORK_SITE_CODE[props.deductData.history.location.state.room_work_site] !== WORK_SITE_CODE.울산 && WORK_SITE_CODE[props.deductData.history.location.state.room_work_site] !== WORK_SITE_CODE.남양연구소) {
      payload.building_name = props.deductData.history.location.state.room_building_name;
    }
    if(props.deductData.history.location.state.room_building_dong) {
      let splitDong = props.deductData.history.location.state.room_building_dong.split('동');
      payload.dong = splitDong[0];
    }
    if(props.deductData.history.location.state.room_building_dong && (WORK_SITE_CODE[props.deductData.history.location.state.room_work_site] === WORK_SITE_CODE.울산 || WORK_SITE_CODE[props.deductData.history.location.state.room_work_site] === WORK_SITE_CODE.남양연구소)) {
      payload.dong = props.deductData.history.location.state.room_building_dong;
    }

    if(props.deductData.history.location.state.room_name) {
      payload.ho = props.deductData.history.location.state.room_name;
    } 
    
    //worksite, roomtype, deduct_date, employee_number, dong, ho
    setDeductData([]);
    
    const {data} = await lodge.getDeductDetail(payload);
    setTimeout (function (){
      setDeductData(data[0]);
    }, 150);
   
  }

  if (!props.isShowDeductPop) return<></>;

  return (
    <div id="pop_deduction" className="pop_dim_wrap pop_modal pop_lg" style={{display: props.isShowDeductPop? "block": "none" }}>
      <div className="pop_wrap">
        <div className="title_wrap">
            <h3>공제내역</h3>
            <button className="pop_btn_close pop_close" onClick={()=>{props.onClose(); setDeductData([]);}}>닫기</button> 
        </div>
        <div className="contents_wrap">
          <div className="title_contents select_area mt20">
            
            {props.deductData.history.location.state.room_type == ROOM_TYPE_CODE.business_room && WORK_SITE_CODE[props.deductData.history.location.state.room_work_site] === WORK_SITE_CODE.울산 ?
              <h3>※ 총 공제내역 : <span className="color_green">{dedcutData ? (dedcutData.total_deduct === '' ? 0 : onlyNumberFormatter(Number(dedcutData.total_deduct))) : 0}</span>원</h3> 
              :<h3>※ 총 공제내역 : <span className="color_green">{dedcutData ? (dedcutData.total_amount === '' ? 0 : onlyNumberFormatter(Number(dedcutData.total_amount))) : 0}</span>원</h3>
            }
            
            <div className="right_area mt0">
              <select name="" id="" className="form_control" value={year} 
                onChange={(e:any) => {
                  setYear(e.target.value);
                  //setClick(true);
                  changeYear(e.target.value); 
                }}>
                {getYear5(true).map((year:any, index: number) => (
                    <option key={index} value={year}>{year}</option>
                ))}
              </select>
              <select name="" id="" className="form_control" value={month} 
                onChange={(e: any) => {
                  setMonth(e.target.value);
                  //setClick(true);
                  changeMonth(e.target.value);
                }}>
                {range(0, 12, 1).map((item:any, index:number) => (
                  <option key={index} value={`${item + 1}`.padStart(2, '0')}>{`${item + 1}`.padStart(2, '0')}</option>
                ))}
              </select>
            </div>
          </div>
            {props.deductData.history.location.state.room_type == ROOM_TYPE_CODE.company_room ? //사택
              <div className="table_normal_write">
                  <table>
                      <caption></caption>
                      <colgroup>
                          <col width="25%"/>
                          <col width="*"/>
                          <col width="25%"/>
                          <col width="*"/>
                      </colgroup>
                      <tbody>
                      <tr>
                          <th>기본료</th>
                          <td className="ar"><a href="javascript:void(0);" className="detail_desc">{dedcutData ? (dedcutData.basic_fee === '' ? 0 : onlyNumberFormatter(Number(dedcutData.basic_fee))) : 0}원</a></td>
                          <th>개별전기료</th>
                          <td className="ar"><a href="javascript:void(0);" className="detail_desc">{dedcutData ? (dedcutData.indvd_elbll === '' ? 0 : onlyNumberFormatter(Number(dedcutData.indvd_elbll))) : 0}원</a></td>
                      </tr>
                      <tr>
                          <th>공용전기료</th>
                          <td className="ar"><a href="javascript:void(0);" className="detail_desc">{dedcutData ? (dedcutData.comon_elctr === '' ? 0 : onlyNumberFormatter(Number(dedcutData.comon_elctr))) : 0}원</a></td>
                          <th>하/수도료</th>
                          <td className="ar"><a href="javascript:void(0);">{dedcutData ? (dedcutData.swrg_fee === '' ? 0 : onlyNumberFormatter(Number(dedcutData.swrg_fee))) : 0}원</a></td>
                      </tr>
                      <tr>
                          <th>기타</th>
                          <td className="ar"><a href="javascript:void(0);" className="detail_desc">{dedcutData ? (dedcutData.other_chrgs === '' ? 0 : onlyNumberFormatter(Number(dedcutData.other_chrgs))) : 0}원</a></td>
                          <th></th>
                          <td className="ar"><a href="javascript:void(0);" className="detail_desc"></a></td>
                      </tr>
                      </tbody>
                  </table>
              </div>
            : <></>}
            {props.deductData.history.location.state.room_type == ROOM_TYPE_CODE.dormitory_room ? //기숙사
              <div className="table_normal_write">
                  <table>
                      <caption></caption>
                      <colgroup>
                          <col width="25%"/>
                          <col width="*"/>
                          <col width="25%"/>
                          <col width="*"/>
                      </colgroup>
                      <tbody>
                      <tr>
                          <th>기본료(유지비)</th>
                          <td className="ar"><a href="javascript:void(0);" className="detail_desc">{dedcutData ? (dedcutData.basic_fee === '' ? 0 : onlyNumberFormatter(Number(dedcutData.basic_fee))) : 0}원</a></td>   
                          <th>유틸리티비용</th>
                          <td className="ar"><a href="javascript:void(0);" className="detail_desc">{dedcutData ? (dedcutData.util_fee === '' ? 0 : onlyNumberFormatter(Number(dedcutData.util_fee))) : 0}원</a></td>
                      </tr>
                      <tr>
                          <th>침구대</th>
                          <td className="ar"><a href="javascript:void(0);" className="detail_desc">{dedcutData ? (dedcutData.bdng_fee === '' ? 0 : onlyNumberFormatter(Number(dedcutData.bdng_fee))) : 0}원</a></td>
                          <th>초과금액</th>
                          <td className="ar"><a href="javascript:void(0);">{dedcutData ? (dedcutData.exces_amnt === '' ? 0 : onlyNumberFormatter(Number(dedcutData.exces_amnt))) : 0}원</a></td>
                      </tr>
                      <tr>
                          <th>기타</th>
                          <td className="ar"><a href="javascript:void(0);" className="detail_desc">{dedcutData ? (dedcutData.other_chrgs === '' ? 0 : onlyNumberFormatter(Number(dedcutData.other_chrgs))) : 0}원</a></td>
                          <th></th>
                          <td className="ar"><a href="javascript:void(0);" className="detail_desc"></a></td>
                      </tr>
                      </tbody>
                  </table>
              </div>
            : <></>}
            {props.deductData.history.location.state.room_type == ROOM_TYPE_CODE.business_room && WORK_SITE_CODE[props.deductData.history.location.state.room_work_site] === WORK_SITE_CODE.울산 ? 
              <div className="table_normal_write">
                  <table>
                      <caption></caption>
                      <colgroup>
                          <col width="25%"/>
                          <col width="*"/>
                          <col width="25%"/>
                          <col width="*"/>
                      </colgroup>
                      <tbody>
                      <tr>
                          <th>키반납</th>
                          <td className="ar"><a href="javascript:void(0);" className="detail_desc">{dedcutData ? (dedcutData.is_key_return === '' ? 0 : (dedcutData.is_key_return === "Y" ? 0 : "15,000")) : 0}원</a></td>
                          <th>숙박비</th>
                          <td className="ar"><a href="javascript:void(0);" className="detail_desc">{dedcutData ? (dedcutData.room_charge === '' ? 0 : onlyNumberFormatter(Number(dedcutData.room_charge))) : 0}원</a></td>
                      </tr>
                      <tr>
                          <th>식권금액</th>
                          <td className="ar"><a href="javascript:void(0);" className="detail_desc">{dedcutData ? (dedcutData.meal_charge === '' ? 0 : onlyNumberFormatter(Number(dedcutData.meal_charge))) : 0}원</a></td>
                          <th>미반납액</th>
                          <td className="ar"><a href="javascript:void(0);">{dedcutData ? (dedcutData.unreturn_charge === '' ? 0 : onlyNumberFormatter(Number(dedcutData.unreturn_charge))) : 0}원</a></td>
                      </tr>
                      </tbody>
                  </table>
              </div>
            : <></>}
          </div>
          <div className="button_wrap btn_length_1">
              <button type="button" className="btn btn_darkblue pop_close" onClick={()=>{props.onClose(); setDeductData([]);}}>확인</button>
          </div>
        </div>
    </div>
  );
};

export default DeductDetail;
