import React, { useState, useEffect, useContext } from 'react';

import Calendar from '../../molecules/calendar';
import Floors from '../../molecules/floor';
import { getTodayDate, getRangeDate, formatByString_YYYYMMDD } from '../../../utils/date';
import { ROOM_TYPE_CODE } from '../../../constant';

import { MessageContext } from '../../../routes';


import { build} from '../../../apis';

import _ from 'lodash';
import LodgeFloors from '../../molecules/lodgeFloor';
import { SHOW_MODAL } from '../../../store/modules/modal';

// 배방 확인 팝업
const LodgeBackroomPopup = (props: any) => {
  // 관리자가 배방 오픈한 날짜
  // 해당 날짜를 기준으로 30일 까지 사용자가 선택가능
  const [beforeDisablePoint, setBeforeDisablePoint] = useState<string>(getTodayDate()[0]);
  const [afterDisablePoint, setAfterDisablePoint] = useState<string>('');

  const [dateStart, setDateStart] = useState<string>('');
  const [floors, setFloors] = useState<any>([]);
  
  useEffect(() => {
    if (!props.isShowPop) return;
    if (!isNotBusinessGroupByGuest()) return
    let afterDate = formatByString_YYYYMMDD(props.selectBackroom.modified_at);
    //let afterDate = props.selectBackroom.stay_after;
    setBeforeDisablePoint(afterDate);
    setDateStart(props.selectBackroom.guests[0].stay_after || afterDate);
  }, [props.selectBackroom])

  // 배방확인 팝업 room 그리는 부분
  useEffect(() => {
    if (!props.isShowPop) return;
    if (!isNotBusinessGroupByGuest()) return

    let range = 30
    let dates = getRangeDate(beforeDisablePoint, range);
    setAfterDisablePoint(dates[range - 1][0]);

    (async () => {
      try {
        let { data } = await build.getBuilds({
          room: props.selectBackroom.guests[0].room
        })

        let stayAfter = dates[0][0];
        let stayBefore = dates[range - 1][0];
        if(props.selectBackroom.stay_after) {
          stayAfter = props.selectBackroom.stay_after;
        }
        if(props.selectBackroom.stay_before){
          stayBefore = props.selectBackroom.stay_before;
        }
        let { data: floors } = await build.getFloors({
          building: data[0].id,
          stay_after: stayAfter,
          stay_before: stayBefore
        })
        setFloors(floors)
      }catch ({ status, data }) {
        if (status === 401) {
          window.location.href = '/force/signout';
          return ;
        } else if (status === 403) {
          (window as any).store.dispatch({
            type: SHOW_MODAL,
            payload: {
              title: '권한오류',
              msg: '접근권한이 없습니다.',
              redirectPath: false,
              statusCode: status,
              errorCode: '',
              type: 'error',
              isModalShow: true,
            },
          });
          return ;
        }else if ( status === 500 ) {
          window.location.href = '/500';
          return;
        }

        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '문제발생',
            msg: '문제가 발생했습니다',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }
    })()
  }, [beforeDisablePoint, props.selectBackroom])

  const isExist = () => Object.keys(props.selectBackroom).length
  const isNotBusinessGroupByGuest = () => props.selectBackroom.room_type !== ROOM_TYPE_CODE.business_room_group

  const onRoomClickHandlerToSelect = () => {}
  const onRoomClickHandlerToUnSelect = () => { }
  const detail = (room: any) => { }
  const isDisable = (room: any) => { }
  const isSelect = (room: any) => props.selectBackroom.guests[0].room == room.id

  if (!props.isShowPop) return <></>;
  if (!isExist() || !isNotBusinessGroupByGuest()) return <></>

  return (
    <div id="pop_confirm_room" className="pop_dim_wrap pop_modal pop_sm" style={{display: props.isShowPop? 'block' : 'none'}}>
      <div className="pop_wrap">
          <div className="title_wrap">
            <h3>배방확인</h3>
            <button
              className="pop_btn_close pop_close"
              onClick={props.onClose}
            >
              닫기
            </button>
        </div>

        <div className="contents_wrap">
          <div className="process_list">
              <dl>
                <dt>배방</dt>
                <dd>
                    <p className="form_control bd0 pl0">
                      {props.selectBackroom.guests[0].room_building_name} {props.selectBackroom.guests[0].room_building_dong} {props.selectBackroom.guests[0].room_name} 호
                    </p>
                </dd>
                <dd>
                  <div className="type_area">
                    <LodgeFloors
                      floors={floors}
                      selectFloor={-1}
                      onRoomClickHandlerToSelect={onRoomClickHandlerToSelect}
                      onRoomClickHandlerToUnSelect={onRoomClickHandlerToUnSelect}
                      detail={detail}
                      isDisable={isDisable}
                      isSelect={isSelect}
                      floorHead=""
                    />
                  </div>

                  <div className="txt_current">
                    {props.selectBackroom.guests[0].room_building_name} {props.selectBackroom.guests[0].room_building_dong}
                  </div>
                </dd>
              </dl>
            </div>

            <div className="button_wrap btn_length_1">
              <button
                type="button"
                className="btn btn_darkblue pop_close"
                onClick={props.onClose}
              >
                확인
              </button>
              </div>
              
            </div>
          </div>
    </div>
  );
};

export default LodgeBackroomPopup;
