import axios from 'axios';
import config from './config';

import apiHandler from './decorate';

const MODE = process.env.REACT_APP_MODE || 'dev';
const IP = (config as any)[MODE].IP;

class Build {
  static URL = `${IP}`;

  @apiHandler()
  static async getBuilds(payload: {work_site ?: number, room_type ?: number, room?: number}) {
    const PATH = '/member/building/';
    return await axios.get(`${Build.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }

  @apiHandler()
  static async getFloors(payload: any) {
    // console.log('getFloors: ', payload);
    const PATH = '/member/building/floor/guest/';
    return await axios.get(`${Build.URL}${PATH}`, {
      params: payload,
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
      },
    });
  }
}

export default Build;
