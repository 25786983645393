import React from 'react';

import Header from '../../organismas/header';
import Footer from '../../organismas/footer';

interface Props {
  id: string
  className: string
  disableFooter?:boolean
  leftInfo?: React.FC
  children: React.FC
  addClassName? : string
}

const DefaultTemplete = (props: Props) => {
  let innerClassName = "col col_left group_title ";
  let innerClassName2 = "col col_right ";
  if(props?.addClassName) {
    innerClassName += props?.addClassName;
    innerClassName2 += props?.addClassName;
  }

  return (
    <div id={props.id} className={props.className}>
      <Header {...props} />
     
      <div id="container">
        <div className="row clearfix">
          <div className={innerClassName}>
              {props?.leftInfo}
          </div>
          <div id="contents" className={innerClassName2}>
            {props.children}
          </div>
        </div>
      </div>

      {!props.disableFooter && <Footer />}
    </div>
  );
};

export default DefaultTemplete;
