import React, { useState, useEffect, useContext } from 'react';
import { MessageContext } from '../../routes';
import DefaultTemplete from '../../components/templetes/default';
import ConfirmBtn from '../../components/atoms/button/confirm';
import DatePicker from '../../components/organismas/datePicker';
import { ROOM_TYPE, FILE_UPLOAD_CODE, DOCUMENT_FILE_VISIBLE_TYPE_CODE, CHECKOUT_STATUS_CODE } from '../../constant';
import { getTodayDate, formatByString_YYYYMMDD } from '../../utils/date';
import { lodge } from '../../apis/';
import { Valid, Check, CheckOut } from './type';
import CheckoutUploadFileList from '../../components/organismas/checkoutUploadFileList';
import CustomCalendar from '../../components/organismas/customCalendar';
import { SHOW_MODAL } from '../../store/modules/modal';


const LodgeCheckoutApplyDetail = (props: any) => {
  const { info } = useContext(MessageContext);
  const [checkoutDate, setCheckoutDate] = useState<string>(props.history.location.state.guest.stay_before || getTodayDate()[0]);
  const [reason_sel, setReason] = useState<string>(props.history.location.state.reason_select || '만기');
  const [reasonMemo, setReasonMemo] = useState<string>(props.history.location.state.reason);
  const [claim, setClaim] = useState<string>(props.history.location.state.claim || '');
  const [checklist, setCheckList] = useState<Check[]>();
  const [isEquimentOk, setIsEquimentOk] = useState<boolean>(props.history.location.state.is_equipment_ok || false);
  const [equipmentDesc, setEquipmentDesc] = useState<string>(props.history.location.state.equipment_description || '');
  const [etcMsg, setEtcMsg] = useState<string>(props.history.location.state.checkout_memo || '');
  const [files, setFiles] = useState<any>([]);
  const [isFiles, setisFile] = useState<boolean>(false);
  const [isImgPop, setIsImgPop] = useState<boolean>(false);
  const [imgPath, setImgPath] = useState<string>('');

  useEffect(() => {
    c()
    /* let cl: Check[] = []

    if (props.history.location.state.room_work_site && (props.history.location.state.room_type > 0)) {
      cl = CHECKOUT_CHECKLIST[props.history.location.state.room_work_site][props.history.location.state.room_type]
    } else {
      cl = CHECKOUT_CHECKLIST["남양연구소"][ROOM_TYPE_CODE.dormitory_room]
    }
    setCheckList(cl) */
  }, [props.history.location.state])


  const onSubmitHandler = async () => {

    let { isValid, msg } = isValidConfirm({
      checkoutDate,
      reason_sel,
      claim,
      etcMsg,
    });

    if (isValid) {
      info(msg);
      return
    }

    if ((!reasonMemo || reasonMemo === '') && reason_sel === '기타') {
      info("사유를 입력해주세요");
      return;
    }

    if(isEquimentOk && !equipmentDesc) {
      info("이상 품목을 입력해주세요");
      return false;
    }

    let isFileUploaded = files.filter((file: any) => file.id < 1 && file.is_required)

    if (isFileUploaded.length) {
      info("필수항목을 업로드하지 않았습니다.");
      return;
    }

    if (formatByString_YYYYMMDD(props.history.location.state.guest.checkin_at) > formatByString_YYYYMMDD(checkoutDate)) {
      info('퇴숙요청일이 입숙일 보다 빠를수 없습니다.');
      return
    }

    let payload = {
      update_stay_before: checkoutDate,
      reason_select: reason_sel,
      reason: reasonMemo,
      claim: claim,
      is_equipment_ok: isEquimentOk,
      //checkout_checklist: JSON.stringify(checklist),
      //checkout_memo: etcMsg,
      equipment_description: equipmentDesc,
      status: CHECKOUT_STATUS_CODE.apply,
      update_files: files.map((file: any) => ({ file: file.id, document: file.document })).filter((file: any) => file.file !== -1)
    }

    try {
      const guestId = props.history.location.state.id;
      let { data } = await lodge.getCheckOutModify(guestId, payload);      
      info('퇴숙 수정이 완료되었습니다 \n 퇴숙관리에서 확인바랍니다.');
      props.history.push('/mypage/checkout');

    } catch (err) {
      //console.log(err)
      const { status, data } = err;

      if (status === 401) {
        window.location.href = '/force/signout';
        return ;
      } else if (status === 403) {
        (window as any).store.dispatch({
          type: SHOW_MODAL,
          payload: {
            title: '권한오류',
            msg: '접근권한이 없습니다.',
            redirectPath: false,
            statusCode: status,
            errorCode: '',
            type: 'error',
            isModalShow: true,
          },
        });
        return ;
      }else if ( status === 500 ) {
        window.location.href = '/500';
        return;
      }

      info('퇴숙신청 중 문제가 발생했습니다.')
      return;
    }
  }
  const isValidConfirm = ({
    checkoutDate, reason_sel, claim, etcMsg
  }: CheckOut): Valid => {

    let isValid = false;
    let msg = "";

    if (!checkoutDate) {
      isValid = true
      msg = '퇴숙 희망일은 필수항목 입니다.';
    } else if (!reason_sel) {
      isValid = true
      msg = '퇴숙사유는 필수항목 입니다.';
    }

    return { isValid, msg }
  }
  const c = () => {
    if (!props.history.location.state) {
      if (props.history.action !== 'PUSH') {
        props.history.push('/mypage/checkout');
      } else {
        props.history.goBack();
      }
      return <></>
    }
  }

  c()
  return (
    <DefaultTemplete
      id="wrap"
      className="wrap_mypage_manage"
      leftInfo={
        <>
          <h2>퇴숙신청</h2>
          <p className="desc">기숙사, 사택  퇴숙신청 및 현황을 확인하실 수 있습니다</p>
          <div className="bg_img checkout"></div>
        </>
      }
      {...props}
    >
      <div id="contents" className="col col_right">
        <div className="title_contents">
          <h3 className="title">기본 정보</h3>
        </div>
        <ul className="lst_status">
          <li><strong>숙소유형</strong><span>{ROOM_TYPE[props.history.location.state.guest.room_type]}</span></li>
          <li><strong>이용사업장</strong><span>{props.history.location.state.guest.room_work_site}</span></li>
          <li><strong>입숙일</strong><span>{props.history.location.state.guest.checkin_at}</span></li>
          <li><strong>입숙만료일</strong><span>{props.history.location.state.guest.expired_at}</span></li>
          <li><strong>배방</strong><span>
            {props.history.location.state.guest.room_building_name}
            {props.history.location.state.guest.room_building_dong}
            {props.history.location.state.guest.room_name}
          </span></li>
        </ul>

        <div className="title_contents">
          <h3 className="title">퇴숙 신청 정보 입력</h3>
        </div>

        <div className="table_design_write col_width2">
          <div id="table" className="table">
            <div className="tbody">
              <div className="tr">
                <div className="th"><span className="emp_red">퇴숙요청일</span></div>
                <div className="td">
                  {/* <input type="text" className="form_control datepicker form_inline" value={checkoutDate} /> */}
                  {/* <DatePicker
                    value={checkoutDate}
                    width={250}
                    onChange={(date: string) => setCheckoutDate(date)}
                    isPrevMonthView={true}
                    min={getTodayDate()[0]}
                  /> */}
                  <CustomCalendar
                    min={getTodayDate()[0]}
                    value={checkoutDate}
                    onChange={(date:string) => {setCheckoutDate(date)}}
                  ></CustomCalendar>
                </div>
              </div>
              <div className="tr">
                <div className="th"><span className="emp_red">퇴숙사유</span></div>
                <div className="td">
                  <div className="form_cause">
                    <select name="" id="" className="form_control" value={reason_sel} onChange={(e: any) => setReason(e.target.value)}>
                      <option value="만기">만기</option>
                      <option value="결혼">결혼</option>
                      <option value="퇴사">퇴사</option>
                      <option value="발령">발령</option>
                      <option value="본가이사">본가이사</option>
                      <option value="자가구입">자가구입</option>
                      <option value="기타">기타</option>
                    </select>
                    <input type="text"
                      className="form_control"
                      disabled={reason_sel !== '기타'}
                      value={reasonMemo}
                      maxLength={100}
                      onChange={(e: any) => setReasonMemo(e.target.value)}
                      placeholder="사유가 기타일 경우 입력해주세요"
                    />
                  </div>
                </div>
              </div>
              <div className="tr">
                <div className="th textarea"><span className="">요청사항 입력</span></div>
                <div className="td textarea">
                  <textarea name="" id="" className="form_control" value={claim} maxLength={400} onChange={(e: any) => setClaim(e.target.value)}></textarea>
                  <span className="size">{claim.length} byte / 400 byte</span>
                </div>
              </div>
              <div className="tr">
                <div className="th">
                  <span className="emp_red">비품 이상유무 체크</span>
                </div>
                <div className="td row">
                  <div className="contents_area images">
                    <div className="images_area">
                      {props.history.location.state.checkoutId ?

                        props.history.location.state.guest.checkin_files.map((image: any, index: number) => (
                          <div key={index} className="img" style={{cursor:'pointer'}} onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                            <a download>
                              <img
                                src={`${process.env.REACT_APP_MEDIA}/${image.path}`}
                                alt={image.name}
                              />
                            </a>
                          </div>

                        )) :

                        props.history.location.state.checkin_files.map((image: any, index: number) => (
                          <div key={index} className="img" style={{cursor:'pointer'}} onClick={() => {setImgPath(`${process.env.REACT_APP_MEDIA}/${image.path}`); setIsImgPop(true);}}>
                            <a download>
                              <img
                                src={`${process.env.REACT_APP_MEDIA}/${image.path}`}
                                alt={image.name}
                              />
                            </a>
                          </div>
                        ))}
                    </div>
                    <ul className="lst_warning gray">
                      <li>입숙절차 시 관리자와 같이 확인했던 내용입니다. 이미지를 보시고 현재 이용중인 숙소의 이상 유무를 체크해 주세요.
                      </li>
                    </ul>
                  </div>

                  <div className="radio_area">
                    <label className="input_radio" htmlFor="radio_warning1">
                      <input type="radio" id="radio_warning1" name="sample" checked={!isEquimentOk} onChange={() => {setIsEquimentOk(false); setEquipmentDesc('');}} />
                      <span className="label_text">숙소 상태 및 비품 등에 이상이 없습니다.</span>
                    </label>
                    <label className="input_radio" htmlFor="radio_warning2">
                      <input type="radio" id="radio_warning2" name="sample" checked={isEquimentOk} onChange={() => {setIsEquimentOk(true); setEquipmentDesc('');}} />
                      <span className="label_text">숙소 상태 및 비품 등에 이상이 있습니다.</span>
                    </label>
                  </div>
                </div>
              </div>
              {isEquimentOk? (
                  <div className="tr mix_width2">
                      <div className="th textarea">
                        <span className="">이상 품목 입력</span>
                      </div>
                      <div className="td textarea">
                          <textarea name="" id="" className="form_control" maxLength={400} value={equipmentDesc} onChange={(e: any)=> setEquipmentDesc(e.target.value)}></textarea>
                          <span className="size">{equipmentDesc.length} byte / 400 byte</span>
                      </div>
                  </div>
              ) :''}
              <CheckoutUploadFileList
                workSite={props.history.location.state.guest.booking.room_work_site}
                guestType={props.history.location.state.guest.guest_type}
                roomType={props.history.location.state.guest.room_type}
                category={FILE_UPLOAD_CODE.checkout}
                onChange={(files: any) => setFiles(files)}
                value={files}
                alreadyFiles={props.history.location.state.files}
                visibleType={DOCUMENT_FILE_VISIBLE_TYPE_CODE.checkout}
                setisFile={(chkFile: boolean) => setisFile(chkFile)}
              />
            </div>
          </div>
          <ul className="lst_help mt15">
            <li>퇴숙요청일에 숙소 상태 및 비품 등 이상유무를 점검합니다. 관리자가 입력한 퇴숙확정일이 퇴숙완료일로 기산됩니다
            </li>
            <li>퇴숙 시, 숙소 상태 및 비품 등에 이상이 있거나 짐이 남아 있으면 원상복구 요청 등을 할 수 있으며 퇴숙 완료 처리가 되지 않습니다</li>
          </ul>
        </div>

        <div className="submit pt20">
          <div className="fr">
            <ConfirmBtn
              action={() => props.history.goBack()}
              label={`취소`}
              title=""
              msg={`퇴숙신청을 취소하시겠습니까?`}
              className="btn btn_lg btn_outline"
            />
            <ConfirmBtn
              action={onSubmitHandler}
              label={`완료`}
              title=""
              msg={`퇴숙신청을 진행하시겠습니까?`}
              className="btn btn_lg btn_darkblue"
            />
          </div>
        </div>
      </div>

      {/* 이미지 팝업 */}
      <div id="pop_image" className="pop_dim_wrap pop_modal" style={{display: isImgPop? 'block' : 'none'}}>
          <div className="pop_wrap">
              <div className="title_wrap">
                <a href="javascript:void(0);" className="pop_btn_close" onClick={()=> setIsImgPop(false)}>닫기</a>
              </div>
              <div className="contents_wrap">
                  <div className="slide">
                      <p className="img_area">
                          <img src={imgPath} alt=""/>
                      </p>
                  </div>
              </div>
          </div>
      </div>
    </DefaultTemplete>
  );
};

export default LodgeCheckoutApplyDetail;
