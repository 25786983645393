const en = {
  "not_requested": "You have not been authenticated..",
  "cannot_find_user": "can't find the user by employee number and mobile phone number.",
  "invalid_number": "The authentication number does not match.",
  "invalid_token": "Bad request.",
  "not_verified": "Authentication is required..",
  "wrong_personnel": "There was a problem while searching InsaDB.",
  "wrong_user": "There was a problem with the user information you entered.",
  "leader_required": "leader_required",
  "duplicate_guest": "duplicate_guest",
  "password_common": "Your password is too common. Or it does not contain English letters, special characters or numbers.",
  "permission_denied": "permission_denied",
  "married_only": "married_only",
  "single_only": "single_only",
  "invalid_applicant": "You can apply only when you are using the dormitory.",
};

export default en;
