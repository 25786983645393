import React from 'react';

import intl from 'react-intl-universal';

import Cell from './cell';
import Row from './row';

const Header = (props: any) => {
  const days = ['day-0', 'day-1', 'day-2', 'day-3', 'day-4', 'day-5', 'day-6'];

  return (
    <Row>
      {days.map((day: any, idx: number) => (
        <Cell header={true} key={idx} sequence={idx}>
          {intl.get(day)}
        </Cell>
      ))}
    </Row>
  );
};

export default Header;
