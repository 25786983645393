import {action, computed, observable} from 'mobx';
import { LODGE_MOVE_CODE } from '../../constant';
export class LodgedStore {

  @observable
  _category: number = LODGE_MOVE_CODE.alway;

  @computed
  get category() : number {
    return this._category;
  }

 /* constructor(root: any) {

    (this, {
      category: observable
    });
  }
  */
}
