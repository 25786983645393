import React from 'react';

import SelectBad from '../../atoms/square/select';
import DisableBad from '../../atoms/square/disable';


//배방 팝업 네모칸
const Bad = (props: any) => {
  if (props.isDisable) {
    return <DisableBad />;
  }
  if (props.selected) {
    return (
      <SelectBad
        selected={props.selected}
        onClick={props.onClickonRoomClickHandlerToUnSelect}
      >
        {props.detail}
      </SelectBad>
    )
  }
  
  return (
    <SelectBad
      onClick={props.onRoomClickHandlerToSelect}
      selected={false}
    />
  )
};

export default Bad;
