import React, { useState } from 'react';

import SignupHelper from '../signupHelper';

const Footer = (props: any) => {
  const [tabNumber, setTabNumber] = useState<number>(-1);
  const onShowSignupHelperPopupHandler = (tab: number) => {
    setTabNumber(tab);
  }; // 가입관련 문의 열기
  const onCloseSignupHelperPopupHandler = () => {
    setTabNumber(-1);
  }; // 가입관련 문의 닫기

  return (
    <>
      <footer>
        <ul className="lst_fmenu">

          <li onClick={() => onShowSignupHelperPopupHandler(2)}><a href="javascript:void(0)">서비스이용약관</a></li>
          <li onClick={() => onShowSignupHelperPopupHandler(3)}><a href="javascript:void(0)" className="fwb">개인정보처리방침</a></li>
        </ul>
        <ul className="lst_cinfo">
        </ul>

        <p className="copyright">COPYRIGHT&copy; HYUNDAI ENGINEERING CO.,LTD. ALL RIGHTS RESERVED.</p>

      </footer>
      <SignupHelper
        classNameBySelect="form_control"
        tabNumber={tabNumber}
        setTabNumber={setTabNumber}
        onCloseSignupHelperPopupHandler={onCloseSignupHelperPopupHandler}
      />
    </>
  );
};

export default Footer;
