import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import queryString from 'query-string';

import Header from '../../organismas/header';
import Footer from '../../organismas/footer';

import { ROOM_TYPE, VIP_USABLE_BY_RANKS } from '../../../constant';

import Drawing from './drawing';
import Layout from './layout';

import './index.scss';

const FacilityInformation = (props: any) => {
  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user,
  }));
  const [workPlace, setWorkplace] = useState<number>(2);
  const [roomType, setRoomType] = useState<number>(1);

  //층별 도면 보기
  const [floorType, setFloorType] = useState<number | null>(null);

  const onClickChangeRoomTypeHandler = (idx: number) => {
    // console.log(idx);
    setRoomType(idx);
    setFloorType(null);
  };

  const [isPop1, setIsPop1] = useState<boolean>(false);
  const [isPop2, setIsPop2] = useState<boolean>(false);

  const [isFacilityLayout, setIsFacilityLayout] = useState<boolean>(false); // 시설배치도(모바일)
  const [isDrawing, setIsDrawing] = useState<boolean>(false); // 위치보기(모바일)

  useEffect(() => {
    const qs: any = queryString.parse(window.location.search);
    setWorkplace(parseInt(qs.workSite || 2));
    setRoomType(parseInt(qs.roomType || 1));
  }, [window.location.search]);

  const onClickChangeFloorHandler = (idx: number) => {
    setFloorType(idx);
  };

  return (
    <div id="wrap" className="wrap_guide">
      <Header history={props.history} />
      <div id="container" className="section section_summary">
        <div className="row clearfix">
          <div className="col col_left group_title">
            <h2>시설안내</h2>
            <p className="desc">남양</p>
            <div className="bg_img">
              <ul className="lst_bg">
                <li>
                  <img
                    src="/static/img/pc/install_rimg_01.jpg"
                    className="pc"
                    alt=""
                  />
                  <img
                    src="/static/img/mobile/install_rimg_01.jpg"
                    className="mobile"
                    alt=""
                  />
                </li>
                <li>
                  <img
                    src="/static/img/pc/install_rimg_02.jpg"
                    className="pc"
                    alt=""
                  />
                  <img
                    src="/static/img/mobile/install_rimg_02.jpg"
                    className="mobile"
                    alt=""
                  />
                </li>
                <li>
                  <img
                    src="/static/img/pc/install_rimg_03.jpg"
                    className="pc"
                    alt=""
                  />
                  <img
                    src="/static/img/mobile/install_rimg_03.jpg"
                    className="mobile"
                    alt=""
                  />
                </li>
                <li>
                  <img
                    src="/static/img/pc/install_rimg_04.jpg"
                    className="pc"
                    alt=""
                  />
                  <img
                    src="/static/img/mobile/install_rimg_04.jpg"
                    className="mobile"
                    alt=""
                  />
                </li>
              </ul>
              <div className="btn_area">
                <a href="javascript:void(0)" className="btn_prev">
                  이전
                </a>
                <a href="javascript:void(0)" className="btn_next">
                  다음
                </a>
              </div>
            </div>
          </div>
          <div id="contents" className="col col_right">
            <ul className="lst_thumb_install">
              <li>
                <a href="javascript:void(0)" className="room_type1 disabled">
                  <p className="title_type">기숙사</p>
                  <p className="desc">
                    <strong>본동</strong>
                    <span>1개동 122호실</span>
                  </p>
                  <p className="desc">
                    <strong>뉴타운</strong>
                    <span>6개동 450호실</span>
                  </p>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" className="room_type2 disabled">
                  <p className="title_type">사택</p>
                  <p className="desc">
                    <strong>숙소</strong>
                    <span>3개동</span>
                  </p>
                  <p className="desc">
                    <strong>호실</strong>
                    <span>248호실</span>
                  </p>
                </a>
              </li>
              <li onClick={() => onClickChangeRoomTypeHandler(1)}>
                <a
                  href="javascript:void(0)"
                  className={`room_type3 ${roomType === 1 ? 'on' : ''}`}
                >
                  <p className="title_type">출장자 숙소</p>
                  <p className="desc">
                    <strong>본동</strong>
                    <span>2층</span>
                  </p>
                  <p className="desc">
                    <strong>3층</strong>
                    <span>41호실</span>
                  </p>
                </a>
              </li>
              <li onClick={() => onClickChangeRoomTypeHandler(2)} style={{display: VIP_USABLE_BY_RANKS.includes(userInfo.rank)? "block" : "none"}}>
                <a
                  href="javascript:void(0)"
                  className={`room_type4 ${roomType === 2 ? 'on' : ''}`}
                >
                  <p className="title_type">영빈관</p>
                  <p className="desc">
                    <strong>숙소</strong>
                    <span>1개동</span>
                  </p>
                  <p className="desc">
                    <strong>호실</strong>
                    <span>30호실</span>
                  </p>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" className="room_type5 disabled">
                  <p className="title_type">R&D STAY</p>
                  <p className="desc">
                    <strong>뉴타운</strong>
                    <span>205동 3세대</span>
                  </p>
                </a>
              </li>
            </ul>

            
            <div className="title_contents">
              <h3 className="title">시설개요</h3>
              <p className="desc">
                경기도 화성시 남양읍 남양리에 위치해 있으며 기숙사, 사택, 출장자
                숙소, 영빈관, 가족 숙소를 운영 중에 있으며 시설규모는 총 11개동
                969호실로 약 2,578명을 수용할 수 있습니다.
              </p>
            </div>

            <div className="btn_area mobile">
              <button
                className="btn btn_lg btn_darkblue"
                onClick={() => setIsFacilityLayout(true)}
              >
                시설배치도
              </button>
            </div>

            <div className="view_installation pc">
              {/*type3 출장자숙소*/}
              {roomType === 1 && (
                <img src="/static/img/pc/location_type3.jpg" alt="" />
              )}

              {/*type4 영빈관*/}
              {roomType === 2 && (
                <img src="/static/img/pc/location_type4.jpg" alt="" />
              )}
            </div>
          </div>{' '}
          {/*#contents*/}
        </div>
      </div>{' '}
      {/*container*/}
      <div className="section section_floor">
        <div className="row clearfix">
          {/*type1 기숙사 - green
                   type2 사택 - blue
                   type3 출장자숙소 - pink
                   type4 영빈관 - purple
                   type5 가족숙소 - yellow */}
          <div className="col col_left">
            {/*기본타입 출장자숙소*/}
            <ul className={`lst_floor ${roomType === 1 ? 'on' : ''}`}>
              {' '}
              {/*on 추가 시 show*/}
              <li className="floor_top">
                <a href="javascript:void(0)" className="disabled">
                  {' '}
                  {/*클릭 시 view_floor(도면) on 추가*/}
                  <p className="txt_floor">9F</p>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" className="disabled">
                  <p className="txt_floor">8F</p>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" className="disabled">
                  <p className="txt_floor">7F</p>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" className="disabled">
                  <p className="txt_floor">6F</p>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" className="disabled">
                  <p className="txt_floor">5F</p>
                </a>
              </li>
              <li>
                <a href="javascript:void(0)" className="disabled">
                  <p className="txt_floor">4F</p>
                </a>
              </li>
              <li onClick={() => onClickChangeFloorHandler(3)}>
                <a href="javascript:void(0)" className="type3 on">
                  <p className="txt_floor">3F</p>
                </a>
              </li>
              <li onClick={() => onClickChangeFloorHandler(2)}>
                <a href="javascript:void(0)" className="type3 on">
                  <p className="txt_floor">2F</p>
                </a>
              </li>
              <li className="floor_1f">
                <a href="javascript:void(0)" className="disabled">
                  <p className="txt_floor">1F</p>
                </a>
              </li>
            </ul>

            {/*기본타입 영빈관*/}
            <ul className={`lst_floor ${roomType === 2 ? 'on' : ''}`}>
              {' '}
              {/*on 추가 시 show*/}
              <li
                className="floor_top"
                onClick={() => onClickChangeFloorHandler(3)}
              >
                <a href="javascript:void(0)" className="type4 on">
                  {' '}
                  {/*클릭 시 view_floor(도면) on 추가*/}
                  <p className="txt_floor">3F</p>
                </a>
              </li>
              <li onClick={() => onClickChangeFloorHandler(2)}>
                <a href="javascript:void(0)" className="type4 on">
                  <p className="txt_floor">2F</p>
                </a>
              </li>
              <li className="floor_1f">
                <a href="javascript:void(0)" className="disabled">
                  <p className="txt_floor">1F</p>
                </a>
              </li>
            </ul>

            {/*a / b 타입 시*/}
            {/*<ul className="tabs tabs_type">
                        <li><a href="javascript:void(0)" className="on">A</a></li>
                        <li><a href="javascript:void(0)">B</a></li>
                    </ul>*/}

            {/*a타입*/}
            <div className="tab_contents">
              {' '}
              {/*on 추가 시 show*/}
              <ul className="lst_floor lst_floor_a on">
                <li className="floor_top">
                  <a href="javascript:void(0)" className="type2">
                    <p className="txt_floor">11F</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="type2">
                    <p className="txt_floor">10F</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="type1">
                    <p className="txt_floor">9F</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="type1">
                    <p className="txt_floor">8F</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="type1">
                    <p className="txt_floor">7F</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="type1">
                    <p className="txt_floor">6F</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="type5">
                    <p className="txt_floor">5F</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="type5">
                    <p className="txt_floor">4F</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="type5">
                    <p className="txt_floor">3F</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="type3">
                    <p className="txt_floor">2F</p>
                  </a>
                </li>
                <li className="floor_1f">
                  <a href="javascript:void(0)" className="type4">
                    <p className="txt_floor">1F</p>
                  </a>
                </li>
              </ul>
            </div>

            {/*b타입*/}
            <div className="tab_contents">
              {' '}
              {/*on 추가 시 show*/}
              <ul className="lst_floor lst_floor_b on">
                <li className="floor_top">
                  <a href="javascript:void(0)" className="type2">
                    <p className="txt_floor">11F</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="type2">
                    <p className="txt_floor">10F</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="type1 on">
                    <p className="txt_floor">9F</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="type1">
                    <p className="txt_floor">8F</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="type1">
                    <p className="txt_floor">7F</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="type1">
                    <p className="txt_floor">6F</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="type5">
                    <p className="txt_floor">5F</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="type5">
                    <p className="txt_floor">4F</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="type5">
                    <p className="txt_floor">3F</p>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="type3">
                    <p className="txt_floor">2F</p>
                  </a>
                </li>
                <li className="floor_1f">
                  <a href="javascript:void(0)" className="type4">
                    <p className="txt_floor">1F</p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col col_right">
            <div className="title_contents">
              <h3 className="title">동호수표 및 층별 도면</h3>
              <div className="right_area">
                <select name="" id="" className="form_control form_lg">
                  <option value="">본동 102동</option>
                </select>
              </div>

              <div className="btn_area mobile">
                <button
                  className="btn btn_lg btn_gray"
                  onClick={() => setIsDrawing(true)}
                >
                  위치보기
                </button>
              </div>
            </div>

            {/*출장자숙소*/}
            <div
              className={`view_dong ${
                roomType === 1 && !floorType ? 'on' : ''
              }`}
            >
              {' '}
              {/*on 추가 시 show*/}
              {/*type1 기숙사 - green
                           type2 사택 - blue
                           type3 출장자숙소 - pink
                           type4 영빈관 - purple
                           type5 가족숙소 - yellow */}
              {/*<ul className="lst_dong">
                            <li>PC방</li>
                            <li className="colspan_{">}게실</li>
                            <li>남자화장실</li>
                            <li>여자화장실</li>
                            <li>계단</li>
                            <li>로비</li>
                            <li>108</li>
                            <li>109</li>
                            <li>110</li>
                            <li>111</li>
                            <li>112</li>
                        </ul>*/}
              <div className="table_floor">
                <table>
                  <tbody>
                    <tr className="">
                      <td>909</td>
                      <td>908</td>
                      <td>907</td>
                      <td>906</td>
                      <td>905</td>
                      <td>921</td>
                      <td>920</td>
                      <td>904</td>
                      <td>903</td>
                      <td>902</td>
                      <td>901</td>
                      <td>계단</td>
                      <td className="floor" rowSpan={2}>
                        9F
                      </td>
                    </tr>
                    <tr className="">
                      <td>918</td>
                      <td>917</td>
                      <td>916</td>
                      <td>915</td>
                      <td>914</td>
                      <td>로비</td>
                      <td>계단</td>
                      <td>913</td>
                      <td>912</td>
                      <td>911</td>
                      <td>910</td>
                      <td>919</td>
                    </tr>
                    <tr className="">
                      <td>809</td>
                      <td>808</td>
                      <td>807</td>
                      <td>806</td>
                      <td>805</td>
                      <td>820</td>
                      <td>819</td>
                      <td>804</td>
                      <td>803</td>
                      <td>802</td>
                      <td>801</td>
                      <td>계단</td>
                      <td className="floor" rowSpan={2}>
                        8F
                      </td>
                    </tr>
                    <tr className="">
                      <td>818</td>
                      <td>817</td>
                      <td>816</td>
                      <td>815</td>
                      <td>814</td>
                      <td>로비</td>
                      <td>계단</td>
                      <td>813</td>
                      <td>812</td>
                      <td>811</td>
                      <td>810</td>
                      <td>세탁실</td>
                    </tr>
                    <tr className="">
                      <td>709</td>
                      <td>708</td>
                      <td>707</td>
                      <td>706</td>
                      <td>705</td>
                      <td>721</td>
                      <td>720</td>
                      <td>704</td>
                      <td>703</td>
                      <td>702</td>
                      <td>701</td>
                      <td>계단</td>
                      <td className="floor" rowSpan={2}>
                        7F
                      </td>
                    </tr>
                    <tr className="">
                      <td>718</td>
                      <td>717</td>
                      <td>716</td>
                      <td>715</td>
                      <td>714</td>
                      <td>로비</td>
                      <td>계단</td>
                      <td>713</td>
                      <td>712</td>
                      <td>711</td>
                      <td>710</td>
                      <td>719</td>
                    </tr>
                    <tr className="">
                      <td>609</td>
                      <td>608</td>
                      <td>607</td>
                      <td>606</td>
                      <td>605</td>
                      <td>620</td>
                      <td>619</td>
                      <td>604</td>
                      <td>603</td>
                      <td>602</td>
                      <td>601</td>
                      <td>계단</td>
                      <td className="floor" rowSpan={2}>
                        6F
                      </td>
                    </tr>
                    <tr className="">
                      <td>618</td>
                      <td>617</td>
                      <td>616</td>
                      <td>615</td>
                      <td>614</td>
                      <td>로비</td>
                      <td>계단</td>
                      <td>613</td>
                      <td>612</td>
                      <td>611</td>
                      <td>610</td>
                      <td>세탁실</td>
                    </tr>
                    <tr className="">
                      <td>509</td>
                      <td>508</td>
                      <td>507</td>
                      <td>506</td>
                      <td>505</td>
                      <td>521</td>
                      <td>520</td>
                      <td>504</td>
                      <td>503</td>
                      <td>502</td>
                      <td>501</td>
                      <td>계단</td>
                      <td className="floor" rowSpan={2}>
                        5F
                      </td>
                    </tr>
                    <tr className="">
                      <td>518</td>
                      <td>517</td>
                      <td>516</td>
                      <td>515</td>
                      <td>514</td>
                      <td>로비</td>
                      <td>계단</td>
                      <td>513</td>
                      <td>512</td>
                      <td>511</td>
                      <td>510</td>
                      <td>519</td>
                    </tr>
                    <tr className="">
                      <td>409</td>
                      <td>408</td>
                      <td>407</td>
                      <td>406</td>
                      <td>405</td>
                      <td>420</td>
                      <td>419</td>
                      <td>404</td>
                      <td>403</td>
                      <td>402</td>
                      <td>401</td>
                      <td>계단</td>
                      <td className="floor" rowSpan={2}>
                        4F
                      </td>
                    </tr>
                    <tr className="">
                      <td>418</td>
                      <td>417</td>
                      <td>416</td>
                      <td>415</td>
                      <td>414</td>
                      <td>로비</td>
                      <td>계단</td>
                      <td>413</td>
                      <td>412</td>
                      <td>411</td>
                      <td>410</td>
                      <td>세탁실</td>
                    </tr>
                    <tr className="type3">
                      <td>309</td>
                      <td>308</td>
                      <td>307</td>
                      <td>306</td>
                      <td>305</td>
                      <td>321</td>
                      <td>320</td>
                      <td>304</td>
                      <td>303</td>
                      <td>302</td>
                      <td>301</td>
                      <td>계단</td>
                      <td className="floor" rowSpan={2}>
                        3F
                      </td>
                    </tr>
                    <tr className="type3">
                      <td>318</td>
                      <td>317</td>
                      <td>316</td>
                      <td>315</td>
                      <td>314</td>
                      <td>로비</td>
                      <td>계단</td>
                      <td>313</td>
                      <td>312</td>
                      <td>311</td>
                      <td>310</td>
                      <td>319</td>
                    </tr>
                    <tr className="type3">
                      <td>209</td>
                      <td>208</td>
                      <td>207</td>
                      <td>206</td>
                      <td>205</td>
                      <td>220</td>
                      <td>219</td>
                      <td>204</td>
                      <td>203</td>
                      <td>202</td>
                      <td>201</td>
                      <td>계단</td>
                      <td className="floor" rowSpan={2}>
                        2F
                      </td>
                    </tr>
                    <tr className="type3">
                      <td>218</td>
                      <td>217</td>
                      <td>216</td>
                      <td>215</td>
                      <td>214</td>
                      <td>로비</td>
                      <td>계단</td>
                      <td>213</td>
                      <td>212</td>
                      <td>211</td>
                      <td>210</td>
                      <td>세탁실</td>
                    </tr>
                    <tr>
                      <td>PC방</td>
                      <td colSpan={2}>휴게실</td>
                      <td>남자화장실</td>
                      <td>여자화장실</td>
                      <td>계단</td>
                      <td>로비</td>
                      <td>108</td>
                      <td>109</td>
                      <td>110</td>
                      <td>111</td>
                      <td>112</td>
                      <td className="floor" rowSpan={2}>
                        1F
                      </td>
                    </tr>
                    <tr>
                      <td>계단</td>
                      <td>101</td>
                      <td>102</td>
                      <td>미화실</td>
                      <td>경비실</td>
                      <td colSpan={2}>현관</td>
                      <td>103</td>
                      <td>104</td>
                      <td>105</td>
                      <td>106</td>
                      <td>107</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/*영빈관*/}
            <div
              className={`view_dong ${
                roomType === 2 && !floorType ? 'on' : ''
              }`}
            >
              {' '}
              {/*on 추가 시 show*/}
              <div className="table_floor">
                <table>
                  <tr className="type4">
                    <td>315</td>
                    <td>313</td>
                    <td>311</td>
                    <td>승강기홀</td>
                    <td>308</td>
                    <td>307</td>
                    <td>305</td>
                    <td>303</td>
                    <td>302</td>
                    <td className="floor" rowSpan={2}>
                      3F
                    </td>
                  </tr>
                  <tr className="type4">
                    <td>314</td>
                    <td>312</td>
                    <td>310</td>
                    <td>309</td>
                    <td colSpan={2}>306</td>
                    <td colSpan={3}>301</td>
                  </tr>
                  <tr className="type4">
                    <td>216</td>
                    <td>214</td>
                    <td>212</td>
                    <td>승강기홀</td>
                    <td>209</td>
                    <td>207</td>
                    <td>205</td>
                    <td>203</td>
                    <td>202</td>
                    <td className="floor" rowSpan={2}>
                      2F
                    </td>
                  </tr>
                  <tr className="type4">
                    <td>215</td>
                    <td>213</td>
                    <td>211</td>
                    <td>210</td>
                    <td>208</td>
                    <td>206</td>
                    <td>204</td>
                    <td colSpan={2}>201</td>
                  </tr>
                  <tr className="">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="floor" rowSpan={2}>
                      1F
                    </td>
                  </tr>
                  <tr className="">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
              </div>
            </div>

            <div className={`view_floor ${floorType ? 'on' : ''}`}>
              {' '}
              {/*on 추가 시 show*/}
              {/*출장자 숙소 2층*/}
              {roomType === 1 && floorType === 2 && (
                <img src="/static/img/pc/plan_type3_2f.jpg" alt="" />
              )}
              {/*출장자 숙소 3층*/}
              {roomType === 1 && floorType === 3 && (
                <img src="/static/img/pc/plan_type3_3f.jpg" alt="" />
              )}
              {/*영빈관 2층*/}
              {roomType === 2 && floorType === 2 && (
                <img src="/static/img/pc/plan_type4_2f.jpg" alt="" />
              )}
              {/*영빈관 3층*/}
              {roomType === 2 && floorType === 3 && (
                <img src="/static/img/pc/plan_type4_3f.jpg" alt="" />
              )}
            </div>
          </div>{' '}
          {/*#contents*/}
        </div>
        <div className="row row_bottom">
          <div className="col col_right">
            {roomType === 1 && <p className="txt_selected">출장자 숙소</p>}
            {roomType === 2 && <p className="txt_selected">영빈관</p>}
          </div>
        </div>
      </div>
      <div className="section section_type">
        <div className="row">
          <div className="col col_right">
            <div className="title_contents">
              <h3 className="title">숙소유형별 이미지</h3>
            </div>

            <div className="tabs_area">
              <ul className="tabs">
                <li>
                  <a href="javascript:void(0)" className="disabled">
                    기숙사
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="disabled">
                    사택
                  </a>
                </li>
                <li onClick={() => onClickChangeRoomTypeHandler(1)}>
                  <a
                    href="javascript:void(0)"
                    className={`${roomType === 1 ? 'on' : ''}`}
                  >
                    출장자 숙소
                  </a>
                </li>
                <li onClick={() => onClickChangeRoomTypeHandler(2)} style={{display: VIP_USABLE_BY_RANKS.includes(userInfo.rank) ? "block" : "none"}}>
                  <a
                    href="javascript:void(0)"
                    className={`${roomType === 2 ? 'on' : ''}`}
                  >
                    영빈관
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0)" className="disabled">
                  R&D STAY
                  </a>
                </li>
              </ul>
            </div>
          </div>{' '}
          {/*#contents*/}
        </div>

        <div className="view_type">
          {/*출장자 숙소*/}
          {roomType === 1 && (
            <img src="/static/img/pc/install_type3.jpg" alt="" />
          )}

          {/*영빈관*/}
          {roomType === 2 && (
            <img src="/static/img/pc/install_type4.jpg" alt="" />
          )}
        </div>
      </div>
      {/* 위치보기(모바일) */}
      <Drawing
        isShow={isDrawing}
        onClose={() => setIsDrawing(false)}
        roomType={roomType}
        floorType={floorType}
      />
      {/* 시설배치도(모바일) */}
      <Layout
        isShow={isFacilityLayout}
        onClose={() => setIsFacilityLayout(false)}
        roomType={roomType}
        floorType={floorType}
      />
      <Footer />
    </div>
  );
};

export default FacilityInformation;
