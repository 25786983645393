import React from 'react';
import { useSelector } from 'react-redux';

import Calendar from '../../components/molecules/calendar';
import StepNav from '../../components/molecules/reservationStepNav';

import {
  isAfterAndSame,
  getTodayDate,
  getNextDay,
  getPrevDay,
} from '../../utils/date';
import { GENDER } from '../../constant';

import './step2.scss';

const Step2 = (props: any) => {
  const { userInfo = {}, workplaceMap = {} } = useSelector((state: any) => ({
    userInfo: state.user,
    workplaceMap: state.base.workplaceMap
  }));
  const todayDate = getTodayDate();

  const onClickDateAfterByCalendar = (day: string) => {
    // dateAfter <= dateBefore
    const _isAfter = isAfterAndSame(day, props.dateBefore);
    props.setDateAfter(day);

    if (!_isAfter || day === props.dateBefore) {
      props.setDateBefore(getNextDay(day)[0]);
      // alert(`${day}보다 ${props.dateBefore}가 이전이 될 수 없습니다.`);
    }
  };

  const onClickDateBeforeByCalendar = (day: string) => {
    // dateAfter <= dateBefore
    const _isAfter = isAfterAndSame(props.dateAfter, day);
    props.setDateBefore(day);
    if (!_isAfter) {
      props.setDateAfter(day);
      // alert(`${props.dateAfter}보다 ${day}가 이전이 될 수 없습니다.`);
    }
  };

  const onClickTodayReservationDate = (e: any) => {
    props.setDateAfter(todayDate[0]);
    props.setDateBefore(getNextDay(todayDate[0])[0]);
  };

  return (
    <>
      <ul className="lst_thumb_request">
        <li>
          <strong>숙소유형</strong>
          <span>영빈관</span>
        </li>
        <li>
          <strong>이용사업장</strong>
          <span>{workplaceMap[props.workSite]?.name}</span>
        </li>
        <li>
          <strong>입숙예정일</strong>
          <span>{props.dateAfter}</span>
        </li>
        <li>
          <strong>퇴숙예정일</strong>
          <span>{props.dateBefore}</span>
        </li>
      </ul>

      <div className="title_contents">
        <h3 className="title">신청자 정보</h3>
      </div>

      <div className="table_design_list col_width6">
        <div id="table" className="table">
          <div className="tbody">
            <div className="tr">
              <div className="th">성명</div>
              <div className="td">{userInfo.username}</div>
              <div className="th">사번 (아이디)</div>
              <div className="td">{userInfo.employeenumber}</div>
              <div className="th">성별</div>
              <div className="td">{GENDER[userInfo.gender]}</div>
            </div>
            <div className="tr">
              <div className="th">휴대폰번호</div>
              <div className="td">{userInfo.phone}</div>
              <div className="th">소속부서</div>
              <div className="td">{userInfo.department}</div>
              <div className="th"></div>
              <div className="td"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="title_contents">
        <h3 className="title">이용자 정보</h3>
      </div>
      <div className="table_design_list col_width6">
        <div id="table" className="table">
          <div className="tbody">
            <div className="tr">
              <div className="th">성명</div>
              <div className="td">{props.user.name}</div>
              <div className="th">사번 (아이디)</div>
              <div className="td">{props.user.employee_number}</div>
              <div className="th">성별</div>
              <div className="td">{GENDER[props.user.gender]}</div>
            </div>
            <div className="tr">
              <div className="th">휴대폰번호</div>
              <div className="td">{props.user.cellphone}</div>
              <div className="th">소속부서</div>
              <div className="td">{props.user.department}</div>
              <div className="th"></div>
              <div className="td"></div>
            </div>
          </div>
        </div>
      </div>

      { props.workSite === 2 ? (
        <></>
      ) : (
          <>
            <div className="title_contents title_selectdate">
              <h3 className="title">이용기간 선택</h3>
              <div className="right_area">
                <ul className="lst_help">
                  <li>이용기간을 선택하여 주세요.</li>
                </ul>
              </div>
            </div>
            <div className="select_date">
              <div className="date_start">
                <div className="datepicker_area">
                  <Calendar
                    onClickSelectDay={onClickDateAfterByCalendar}
                    onRangeSelectDay={(e: string) => { }}
                    tasks={false}
                    isNextMonthButtonView={true}
                    selectDate={props.dateAfter}
                    name={"left"}
                    beforeDisablePoint={getPrevDay(props.today)[0]}
                  />
                  <div className="btn_area">
                  </div>
                </div>
              </div>
              <div className="date_end">
                <div className="datepicker_area">
                  <Calendar
                    onClickSelectDay={onClickDateBeforeByCalendar}
                    onRangeSelectDay={(e: string) => { }}
                    tasks={false}
                    name={"right"}
                    isNextMonthButtonView={true}
                    selectDate={props.dateBefore}
                    beforeDisablePoint={props.dateAfter}
                  />
                  <div className="btn_area">
                    <button
                      className="btn btn_sm btn_darkblue btn_outline"
                      onClick={onClickTodayReservationDate}
                    >
                      당일 예약선택
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

      <div className="submit">
        <button
          className="btn btn_lg btn_outline"
          onClick={props.prevStep}
        >
          이전
            </button>
        <button className="btn btn_lg btn_darkblue" onClick={props.nextStep}>
          다음
            </button>
      </div>
    </>
  );
};

export default Step2;

