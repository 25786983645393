import React, { useState, useContext } from 'react';
import { useSelector } from 'react-redux';

import { MessageContext } from '../../routes';

import ConfirmBtn from '../../components/atoms/button/confirm';

import {DownloadButton} from '../../components/molecules/download';
import CollapseView from '../../components/molecules/collapse';
import PledgeView from '../../components/molecules/pledge';

import intl from 'react-intl-universal';

import { formatByString_YYYYMMDD } from '../../utils/date';
import { } from '../../constant';

import { GENDER, ROOM_TYPE_CODE } from '../../constant';

const Step3 = (props: any) => {
  const { info } = useContext(MessageContext);

  const { userInfo = {} } = useSelector((state: any) => ({
    userInfo: state.user
  }));

  const [isGreed, setIsGreed] = useState<boolean>(true);

  const [guideFile, setGuide] = useState<string>("");
  const [pledgeFile, setPledgeFile] = useState<string>("");

  const onNextHandler = () => {
    if (isGreed) {
      props.next()
    } else {
      info(intl.get("입숙 서약서 내용에 동의를 \n하셔야만 이용 신청이 가능합니다\n동의 여부를 확인하여 주세요"));
    }
  }
  
  return (
    <>
      <div className="title_contents">
        <h3 className="title">{intl.get("신청자 정보")}</h3>
      </div>

      <div className="table_design_list col_width6">
        <div id="table" className="table">
          <div className="tbody">
            <div className="tr">
              <div className="th">{intl.get("사번 (아이디)")}</div>
              <div className="td">{userInfo.employeenumber}</div>
              <div className="th">{intl.get("성명")}</div>
              <div className="td">{userInfo.username}</div>
              <div className="th">{intl.get("성별")}</div>
              <div className="td">{GENDER[userInfo.gender]}</div>
            </div>
            <div className="tr">
              <div className="th">{intl.get("소속 부서")}</div>
              <div className="td">{userInfo.department}</div>
              <div className="th">{intl.get("휴대폰 번호")}</div>
              <div className="td">{userInfo.phone}</div>
              <div className="th"></div>
              <div className="td"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="title_contents">
        <h3 className="title">{intl.get("숙소 이용안내")}</h3>
        <div className="right_area">
          {guideFile && (
            <DownloadButton 
              src={guideFile}
              value={intl.get("파일 다운로드")}
              pc
            />
          )}
        </div>
      </div>

      <CollapseView
        workSite={props.workSite}
        roomType={props.roomType}
        onChangeGuideFile={setGuide}
      />

      <div className="title_contents">
        <h3 className="title">{intl.get("입숙 서약서")}</h3>
        <div className="right_area">
          {pledgeFile && (
            <DownloadButton 
              src={pledgeFile}
              value={intl.get("파일 다운로드")}
              pc
            />
          )}
        </div>
      </div>

      <div className="table_list" style={{ marginTop: 10 }}>
        <table>
          <caption className="sr_only">
            문의안내 - 숙소관리자, HMC 관리자, HEC 관리자, 플랫폼 관리자
            연락처 리스트
                </caption>
          <colgroup>
            <col width="20%" />
            <col width="20%" />
            <col width="20%" />
            <col width="20%" />
            <col width="20%" />
          </colgroup>
          <thead>
            <tr>
              <th>{intl.get("이용자 성명")}</th>
              <th>{intl.get("성별")}</th>
              <th>{intl.get("휴대폰 번호")}</th>
              <th>{intl.get("신청자와의 관계")}</th>
              <th>{intl.get("차량번호")}</th>
            </tr>
          </thead>
          <tbody>
            {props.guests.map((guest: any, idx: number) => (
              <tr key={idx}>
                <td>{guest.guest_name}</td>
                <td>{guest.guest_gender === 1 ? '남자' : '여자'}</td>
                <td>{guest.guest_cellphone}</td>
                <td>{guest.guest_relation}</td>
                <td>{guest.guest_car_number1}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PledgeView
        workSite={props.workSite}
        roomType={props.roomType}
        onChangePledgeFile={setPledgeFile}
      />

      <div className="form_agree">
        <p className="desc_area">
          {intl.get("출장자 숙소 입숙 서약서 및 입숙 준비사항에 대한 모든 항목을 확인하였으며 해당 내용에 동의하며 이를 충실히 이행 할 것을 서약합니다.")}
                    <span className="right_area">{intl.get("서약 일자")} : {formatByString_YYYYMMDD(new Date().toString())}</span>
        </p>
        <p className="check_area">
          <label className="input_radio">
            <input type="radio" id="radio_sex_omale" name="radio_osex" checked={isGreed ? true : false} onClick={() => setIsGreed(true)} /><span className="label_text">{intl.get("동의합니다")}</span>
          </label>
          <label className="input_radio">
            <input type="radio" id="radio_sex_ofemale" name="radio_osex" 
              checked={isGreed ? false : true} 
              onClick={() => {
                  setIsGreed(false); 
                  info(intl.get("입숙 서약서 내용에 동의를 \n하셔야만 이용 신청이 가능합니다\n동의 여부를 확인하여 주세요"));
                }} 
            />
            <span className="label_text">{intl.get("동의하지 않습니다")}</span>
          </label>
        </p>
      </div>


      <div className="submit">
        <button className="btn btn_lg btn_outline" onClick={props.prev}>{intl.get("이전")}</button>
        {/*<button className="btn btn_lg btn_darkblue" onClick={onNextHandler}>완료</button>*/}
        {isGreed ? ( 
          <ConfirmBtn
            className="btn btn_lg btn_darkblue"
            action={() => onNextHandler()}
            label={intl.get("완료")}
            title=""
            msg={intl.get("예약을 진행하시겠습니까?")}
          />
        ) : (
          <button
            className="btn btn_lg btn_darkblue"
            onClick={() => {
              setIsGreed(false); 
              info(intl.get("입숙 서약서 내용에 동의를 \n하셔야만 이용 신청이 가능합니다\n동의 여부를 확인하여 주세요"));
            }}
          >
            {intl.get("완료")}
          </button>
        )}
      </div>
    </>

  );
};

export default Step3;
