import React from 'react';
import { useSelector } from 'react-redux';

import { GENDER } from '../../constant';

import intl from 'react-intl-universal';
import './step5.scss';

const Step5 = (props: any) => {
  const { workplaceMap = {} } = useSelector((state: any) => ({
    workplaceMap: state.base.workplaceMap
  }));

  return (
    <>
      <dl className="lst_complete">
        <dt>{intl.get("예약이 완료되었습니다")}</dt>
        {Number(props.workSite) === 2? (
          <>
            <dd>
              {intl.get("이용 신청이 접수 및 승인되어 예약이 완료되었습니다. 신청 접수된 이용자 정보의 본인만이 숙소이용이 가능합니다. 입숙예정일에 해당 사업장의 관리사무소로 방문하시면 관리사무소 직원의 안내에 따라 입숙절차가 마무리되고 ")}
              <span style={{color:"blue"}}><b>{intl.get("키를 수령 하실 수 있습니다.")}</b></span>{intl.get("예약정보 및 이용자 정보는 하기와 같습니다.")}
              <br />
              {intl.get("코로나 관련하여 개인 위생을 철저히 지켜주시기 바라며, 정부의 행동지침에 따라 주시기 바랍니다. 코로나 의심증상 있을시 연구소 코로나 상황실(031-368-6510)연락주시기 바랍니다.")}
            </dd>
          </>
        ) : (
          <>
            <dd>
              {intl.get("이용 신청이 접수 및 승인되어 예약이 완료되었습니다.")}
                <br />
                {intl.get("신청 접수된 이용자 정보의 본인만이 숙소이용이 가능합니다.")}
                <br/>
                {intl.get("입숙예정일에 해당 사업장의 관리사무소로 방문하시면")}
                <br/>
                {intl.get("관리사무소 직원의 안내에 따라 입숙절차가 마무리되고 ")} 
                <span style={{color:"blue"}}><b>{intl.get("키를 수령 하실 수 있습니다.")}</b></span>
                <br/>
              {intl.get("예약정보 및 이용자 정보는 하기와 같습니다.")}
            </dd>
          </>
        )}
      </dl>

      <div className="title_contents">
        <h3 className="title">{intl.get("예약된 숙소 이용 정보")}</h3>
      </div>

      
      <dl className="lst_reservation">
        <dt>{intl.get("숙소유형")}</dt>
        <dd>{intl.get("출장자숙소(개인)")}</dd>
        <dt>{intl.get("이용사업장")}</dt>
        <dd>{workplaceMap[props.workSite]?.name}</dd>
        <dt>{intl.get("입숙예정일")}</dt>
        <dd>{props.dateAfter}</dd>
        <dt>{intl.get("퇴숙예정일")}</dt>
        <dd>{props.dateBefore}</dd>
        <dt>{intl.get("배방정보")}</dt>
        <dd>
          {props.building.name} {props.building.dong} {props.room.name ? props.room.name+'호' : ''}
        </dd>
        <dt></dt>
        <dd></dd>
      </dl>

      <ul className="lst_userinfo">
        {props.users.map((uesr: any, idx: number) => (
          <React.Fragment key={idx}>
            <li className="user_area">
              <span className="img_user">
                <img src="/static/img/pc/userimg_default.png" alt="" />
              </span>
              <span className="username">{intl.get("이용자 정보")}</span>
            </li>
            <li>
              <strong>{intl.get("성명")}</strong>
              <span>{uesr.name}</span>
            </li>
            <li>
              <strong>{intl.get("성별")}</strong>
              <span>{GENDER[uesr.gender]}</span>
            </li>
            <li>
              <strong>{intl.get("휴대폰 번호")}</strong>
              <span>{uesr.cellphone}</span>
            </li>
          </React.Fragment>
        ))}
      </ul>

      <ul className="lst_help">
        {Number(props.workSite) === 2? (
          <li>
            {intl.get("이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 출장자(개인) 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.")}
          </li>
        ) :(
          <li>
            {intl.get("이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 신청된 입숙일에 해당 사업장의 관리사무소로 방문하시어 간단한 인적사항 확인 후 키 분출을 하실 수 있습니다")}
          </li>
        )}
      </ul>

      <div className="submit submit btn_length_1">
        <button
          className="btn btn_lg btn_darkblue"
          onClick={() => props.history.push('/mypage/applicant')}
        >
          {intl.get("신청관리 바로가기")}
        </button>
      </div>
    </>
  );
};

export default Step5;
