import React, { useState } from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy_policy terms_wrap">
      <p className="terms_explain">
        '현대엔지니어링'은 (이하 '회사'는) 「개인정보보호법」 및 「정보통신망
        이용촉진 및 정보보호 등에 관한 법률」에 따라 정보주체의 개인정보 보호 및
        권익을 보호하고 개인정보와 관련한 정보주체의 고충을 원활하게 처리할 수
        있도록 다음과 같은 처리방침을 두고 있습니다. 회사는 개인정보처리방침을
        개정하는 경우 홈페이지(https://www.hyundaiams.com) 를 통하여 공지할
        것입니다. 본 방침은 2021년 07월 21일부터 시행됩니다.
      </p>
      <dl>
        <dt>1. 개인정보의 처리 목적</dt>
        <dd>
          회사는 다음의 목적으로 필요에 의한 최소한의 개인정보를 처리하고
          있습니다.
          <ul>
            <li>
              가. 홈페이지 회원가입 및 관리
              <p>
                회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별•인증,
                회원자격 유지•관리, 제한적 본인확인제 시행에 따른 본인확인,
                서비스 부정이용 방지, 각종 고지•통지, SMS서비스, 민원처리, 분쟁
                조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.
              </p>
            </li>
            <li>
              나. 숙소이용관리 목적
              <p>
                민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락•통지,
                처리결과 통보 등을 목적으로 개인정보를 처리합니다.
              </p>
            </li>
            <li>
              다. 민원사무 처리
              <p>
                민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락•통지,
                처리결과 통보 등을 목적으로 개인정보를 처리합니다.
              </p>
            </li>
            <li>
              라. 재화 또는 서비스 제공
              <p>
                서비스 제공, 본인인증, 정산 등을 목적으로 개인정보를 처리합니다.
              </p>
            </li>
          </ul>
        </dd>

        <dt>2. 개인정보 필수 및 선택 항목</dt>
        <dd>
          회사는 제1항의 각 목적을 위해 다음의 개인정보를 처리합니다.
          <ul>
            <li>
              가. 필수항목 : 가입시 필요한 항목
              <p>
                -회원가입 : 사번, 성명, 생년월일, 성별, 근무지명, 소속부서,
                직책, 휴대폰번호, 이메일, 결혼여부
              </p>
              <p>-시스템 운영 : 접속IP, 접속로그, 이용신청 및 사용로그</p>
            </li>
            <li>
              나. 선택항목 : 각 서비스를 이용시 필요한 항목
              <p>
                - 출장자숙소, 영빈관 이용 : 사번, 성명, 성별, 근무지명,
                소속부서, 직책, 휴대폰번호, 이메일
              </p>
              <p>- 민원신청처리, 공지관리 : 사번, 성명, 휴대폰번호</p>
              <p>
                - 기숙사, 사택, 정기서류심사등 이용 : 사번, 성명, 주소,
                결혼여부, 직종, 근무조, 주민등록등본(주민등록번호 뒷자리
                미표시), 재산과세증명 (주민등록번호 뒷자리 미표시)
              </p>
              <p>- 영빈관 이용 : 직책, 성명, 휴대폰 번호</p>
              <p>- R&D STAY 이용 : 성명, 성별, 신청자와의 관계 </p>
              <p>- 기숙사, 사택, 영빈관, R&D STAY 등 이용 : 차량번호</p>
            </li>
            <li>다. 수집방법 : 통합숙소관리시스템 홈페이지</li>
          </ul>
        </dd>

        <dt>3. 개인정보의 처리 및 보유 기간</dt>
        <dd>
          <ul>
            <li>
              가. 법령에 따른 개인정보 보유•이용기간 또는 정보주체로부터
              개인정보를 수집 시에 동의 받은 개인정보 보유, 이용기간 내에서
              개인정보를 처리, 보유합니다. 단, 나호에 해당하는 경우, 나호의
              기준에 따른다.
            </li>
            <li>
              나. 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
              <p>
                ①회원가입 정보
                <p>
                  - 이용정보 : 사번, 성명, 생년월일, 성별, 근무지명, 소속부서,
                  직책, 휴대폰번호, 이메일, 결혼여부
                  <br />
                  - 보유근거 : 이용자 식별, 본인여부 확인 및 회사가 제공하는
                  서비스 정보제공등
                  <br />
                  - 보유기간 : 회원 탈퇴시 삭제
                  <br />
                  ※ 1) 숙소 미이용 회원 中 시스템 미접속 기간이 1년 경과 시
                  자동으로 휴먼회원전환 처리됨
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;이때, 1년 경과 한 달 전에
                  문자메세지(SMS)를 통한 휴면회원전환 및 1년 추가 경과시 회원
                  자동탈퇴처리 예정 안내 실시
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2) 휴먼회원전환 후 1년 경과 시
                  회원 자동 탈퇴 처리
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;삭제항목 : 사번, 성명, 생년월일,
                  성별, 근무지명, 소속부서, 직책, 휴대폰번호, 이메일, 결혼여부
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;단, 기숙사 및 사택을 이용시 :
                  사번, 성명은 퇴사시까지 유지
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(내부기준에 입사기간중 언제나
                  사택,기숙사 이용가능하나 5~6년이상 이용불가)
                  <br />
                </p>
                ②기숙사, 사택, 영빈관 사용 정보
                <br />
                <p>
                  1) 단기 보관
                  <br />
                  - 이용정보 : 생년월일, 성별, 근무지명, 소속부서, 직책,
                  휴대폰번호, 이메일, 차량번호
                  <br />
                  - 보유근거 : 숙소 이용신청, 입숙, 이숙, 퇴숙, VOC 또는 분쟁에
                  관한 기록
                  <br />
                  - 보유기간 : 회원 탈퇴 후 퇴숙완료처리 시점 1년 경과 시 삭제
                  <br />
                  ※삭제항목 : 주소, 결혼여부, 직종, 근무조, 주민등록
                  등본(주민등록 뒷자리 미표기), <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;재산과세증명 (주민등록번호
                  뒷자리 미표시)
                  <br />
                  2) 장기 보관
                  <br />
                  - 이용정보 : 사번, 성명
                  <br />
                  - 보유근거 : 계약사 숙소 운영기준에 따라 숙소 입숙조건에 관련
                  해당사 입사 후 퇴사
                  <br />
                  &nbsp;&nbsp;&nbsp;전까지 5 or 6년간만 숙소 이용가능 (기간초과
                  재입숙불가)
                  <br />
                  - 보유기간 : 이용자 해당사 퇴사 전<br />
                  ※삭제항목 : 사번, 성명
                  <br />
                </p>
                ③출장자, R&D STAY 이용숙소 사용정보
                <br />
                <p>
                  - 이용정보 : 사번, 성명, 생년월일, 성별, 근무지명, 소속부서,
                  직책, 휴대폰번호, 이메일
                  <br />
                  - 보유근거 : 숙소 이용신청, 입숙, 이숙, 퇴숙, VOC 또는 분쟁에
                  관한 기록
                  <br />
                  - 보유기간 : 퇴숙완료처리 시점으로부터 1년후 삭제
                  <br />
                  ※삭제항목 : 생년월일, 성별, 근무지명, 소속부서, 직책,
                  휴대폰번호, 이메일
                  <br />
                </p>
                ④민원처리 및 시스템 운영 정보
                <br />
                <p>
                  - 이용정보 : 휴대폰번호, 접속IP, 접속로그, 이용신청 및
                  사용로그
                  <br />
                  - 보유근거 : 민원신청, 민원처리, VOC 또는 분쟁에 관한 기록,
                  사건발생시 사용내역 확인
                  <br />
                  - 보유기간 : 탈퇴 시점으로부터 1년 후 삭제
                  <br />
                  - 삭제항목 : 휴대폰번호, 접속IP, 접속로그, 이용신청 및
                  사용로그
                  <br />
                </p>
              </p>
            </li>
          </ul>
        </dd>
        <dt>4. 개인정보처리 위탁</dt>
        <dd>
          <ul>
            <li>
              가. 회사가 개인정보의 처리를 위탁하는 경우에는 개인정보가 안전하게
              관리될 수 있도록 필요한 사항을 이용약관 또는 위탁계약에 구성하고
              있습니다. 현재 원활한 서비스 제공과 관련하여 처리위탁 업체는
              아래와 같습니다.
              <div className="group_desc">
                <div className="table_design_list col_width3">
                  <div id="table" className="table">
                    <div className="thead">
                      <div className="tr">
                        <div className="th">위탁업무</div>
                        <div className="th">수탁업체</div>
                        <div className="th">위탁의 범위</div>
                      </div>
                    </div>
                    <div className="tbody">
                      <div className="tr">
                        <div className="td">통합숙소관리시스템 관리</div>
                        <div className="td">현대오토에버㈜</div>
                        <div className="td">
                          통합숙소관리시스템 서버 및 운영관리
                        </div>
                      </div>
                      <div className="tr">
                        <div className="td">숙소업무 및 VOC관리</div>
                        <div className="td">
                          ㈜윈피앤에스
                          <br />
                          에프엠파트너㈜
                          <br />
                          ㈜아이비에스인더스트리
                          <br />
                          미성엠프로주식회사
                          <br />
                          ㈜지앤비시스템
                        </div>
                        <div className="td">
                          숙소이용관련 신청내용 확인 및 처리 업무
                          <br />
                          민원신청에 대한 조치 및 처리 업무
                          <br />
                          숙소이용현황 확인 업무
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              나. 회사는 정보주체정보 처리를 위탁받은 업체가 개인정보 보호와
              관련하여 제반의 조치를 취하도록 하며 만약 정보주체정보를 위탁받은
              업체가 고의 또는 과실로 인하여 회사 회원에게 손해를 입한 경우 그에
              대한 모든 책임을 부담합니다.
            </li>
          </ul>
        </dd>
        <dt>5. 정보주체와 법정대리인의 권리•의무 및 그 행사방법</dt>
        <dd>
          정보주체는 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다.
          <ul>
            <li>
              가. 정보주체는 언제든지 개인정보 열람, 정정, 삭제, 처리정지 등의
              요구 권리를 행사할 수 있습니다.
            </li>
            <li>
              나. '가'항에 따른 권리 행사는 개인정보 보호법 시행령에 따라 서면,
              전자우편, 모사전송(FAX) 등을 통하여
              <br />
              하실 수 있으며 이에 대해 지체 없이 조치하겠습니다.
            </li>
            <li>
              다. '가'항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을
              받은 자 등 대리인을 통하여 하실 수 있습니다. <br />이 경우
              위임장을 제출하셔야 합니다.
            </li>
            <li>
              라. 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제3항
              및 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수
              있습니다.
            </li>
            <li>
              마. 개인정보보호법 제36조 제1항 및 제2항에 따라, 개인정보의 정정
              및 삭제 요구는 다른 법령에서 그 개인 정보가 수집 대상으로 명시되어
              있는 경우에는 그 삭제를 요구할 수 없고, 개인정보의 정정 또는
              삭제에 관하여 다른 법령에 특별한 절차가 규정되어 있는 경우에는 그
              절차에 따라 정정 또는 삭제되어야 합니다.
            </li>
            <li>
              바. 정보주체 권리에 따른 열람의 요구, 정정•삭제의 요구, 처리정지의
              요구 시 열람 등 요구를 한 자가 본인이거나
              <br />
              정당한 대리인인지를 확인합니다.
            </li>
          </ul>
        </dd>
        <dt>6. 개인정보의 파기</dt>
        <dd>
          원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당
          개인정보를 파기합니다.
          <br />
          파기의 절차, 시점 및 방법은 다음과 같습니다.
          <ul>
            <li>
              가. 파기절차
              <p>
                정보주체가 입력한 정보는 목적 달성 후 지체없이 그 개인정보를
                파기하도록 하고 있고, 다만 다른 법령에 따라 보존하여야 하는
                경우에는 해당 개인정보를 다른 개인정보와 분리하여 저장 및
                관리하고 있습니다.
              </p>
            </li>
            <li>
              나. 파기시점
              <p>
                이용목적 달성 및 그 개인정보가 불필요하게 된 경우에 내부방침 및
                기타관련 법령에 의한
                <br />
                정보보호 방침에 의거 즉시 삭제하거나 파기합니다.
              </p>
            </li>
            <li>
              다. 파기방법
              <p>
                1) 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을
                사용합니다.
                <br />
                2) 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여
                파기합니다.
              </p>
            </li>
          </ul>
        </dd>

        <dt>7. 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항</dt>
        <dd>
          <ul>
            <li>
              가. 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로
              불러오는 ‘쿠키(cookie)’를 사용합니다.
            </li>
            <li>
              나. 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 정보주체의
              컴퓨터 브라우저에게 보내는 소량의 정보이며 정보주체들의 PC
              컴퓨터내의 하드디스크에 저장되기도 합니다.
              <p>
                -쿠키의 사용 목적 : 정보주체가 방문한 각 서비스와 웹 사이트들에
                대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여
                정보주체에게 최적화된 정보 제공을 위해 사용됩니다.
              </p>
              <p>
                -쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구&gt;인터넷
                옵션&gt;개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수
                있습니다
              </p>
              <p>
                -쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
                있습니다.
              </p>
            </li>
          </ul>
        </dd>

        <dt>8. 개인정보 보호책임자</dt>
        <dd>
          개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한
          정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보
          보호책임자를 지정하고 있습니다.
          <div className="group_desc">
            <div className="table_design_list col_width2">
              <div id="table" className="table">
                <div className="thead">
                  <div className="tr">
                    <div className="th">개인정보보호관리자</div>
                    <div className="th">개인정보보호담당자</div>
                  </div>
                </div>
                <div className="tbody">
                  <div className="tr">
                    <div className="td">성명 : 경규호</div>
                    <div className="td">성명 : 최성민</div>
                  </div>
                  <div className="tr">
                    <div className="td">직책 : 팀장대행</div>
                    <div className="td">직책 : 매니저</div>
                  </div>
                  <div className="tr">
                    <div className="td">연락처 : 02-2134-7851</div>
                    <div className="td">연락처 : 02-2134-7834</div>
                  </div>
                  <div className="tr">
                    <div className="td">이메일 : wow@hec.co.kr</div>
                    <div className="td">이메일 : whitelion@hec.co.kr</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          정보주체께서는 서비스(또는 사업)를 이용하시면서 발생한 모든 개인정보
          보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보
          보호관리자 및 담당자에게 문의하실 수 있습니다.
        </dd>

        <dt>9. 개인정보의 안전성 확보 조치</dt>
        <dd>
          개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한
          기술적/관리적 및 물리적 조치를 하고 있습니다.
          <ul>
            <li>
              가. 내부관리계획의 수립 및 시행
              <p>
                개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고
                있습니다.
              </p>
            </li>
            <li>
              나. 개인정보 취급 직원의 최소화 및 교육
              <p>
                개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화
                하여 개인정보를 관리하는 대책을 시행하고 있습니다.
              </p>
            </li>
            <li>
              다. 개인정보에 대한 접근 제한
              <p>
                개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의
                부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한
                조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단
                접근을 통제하고 있습니다.
              </p>
            </li>
            <li>
              라. 접속기록의 보관 및 위변조 방지
              <p>
                개인정보처리시스템에 접속한 기록을 보관, 관리하고 있으며, 접속
                기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고
                있습니다.
              </p>
            </li>
            <li>
              마. 개인정보의 암호화
              <p>
                정보주체의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고
                있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송
                데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도
                보안기능을 사용하고 있습니다.
              </p>
            </li>
            <li>
              바. 해킹 등에 대비한 기술적 대책
              <p>
                해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기
                위하여 보안프로그램을 설치하고 주기적인 갱신•점검을 하며
                외부로부터 접근이 통제된 구역에 시스템을 설치하고
                기술적/물리적으로 감시 및 차단하고 있습니다.
              </p>
            </li>
            <li>
              사. 문서보안을 위한 잠금장치 사용
              <p>
                개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한
                장소에 보관하고 있습니다.
              </p>
            </li>
            <li>
              아. 비인가자에 대한 출입 통제
              <p>
                개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에
                대해 출입통제 절차를 수립, 운영하고 있습니다.
              </p>
            </li>
          </ul>
        </dd>
        <dt>10. 정보주체의 권익침해에 대한 구제방법</dt>
        <dd>
          아래의 기관은 회사와는 별개의 기관으로서, 자체적인 개인정보 불만처리,
          피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면
          문의하여 주시기 바랍니다.
          <ul>
            <li>
              ▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)
              <p>
                - 소관업무 : 개인정보 침해사실 신고, 상담 신청
                <br />
                - 홈페이지 : privacy.kisa.or.kr
                <br />
                - 전화 : (국번없이) 118
                <br />- 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층
                개인정보침해신고센터
              </p>
            </li>
            <li>
              ▶ 개인정보 분쟁조정위원회
              <p>
                - 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
                <br />
                - 홈페이지 : www.kopico.go.kr
                <br />
                - 전화 : (국번없이) 1833-6972
                <br />
                - 주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층
                <br />
              </p>
            </li>
            <li>▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)</li>
            <li>
              ▶ 경찰청 사이버안전국 : 182 (http://cyberbureau.police.go.kr)
            </li>
          </ul>
        </dd>
      </dl>
    </div>
  );
};

export default PrivacyPolicy;
