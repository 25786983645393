// base은 prefix로 _BASE_을 붙인다.
// 특수상황은 _BASE_를 뺸다

const kr = {
  'day-0': '일',
  'day-1': '월',
  'day-2': '화',
  'day-3': '수',
  'day-4': '목',
  'day-5': '금',
  'day-6': '토',

  'kr': '한국어',
  'en': "영어",

  '_BASE_signin': "로그인",
  '알림': '알림',
  '알림관리': '알림관리',
  '검색': '검색',
  '저장': '저장',
  '더보기': '더보기',
  '파일첨부': '파일첨부',
  '사진첨부': '사진첨부',
  '파일찾기': '파일찾기',
  '내용': '내용',
  '발생장소': '발생장소',
  '제목': '제목',
  '작성자': '작성자',
  '작성일시': '작성일시',
  '진행상황': '진행상황',
  '유형': '유형',
  '답변': '답변',
  '답변 일시': '답변 일시',
  '답변 담당자': '답변 담당자',
  '목록': '목록',
  '추가 문의': '추가 문의',
  '취소': '취소',
  '다음': '다음',
  '이전': '이전',
  '완료': '완료',
  '수정': '수정',
  '삭제': '삭제',
  '닫기': '닫기',
  '확인': '확인',
  '추가': '추가',
  '초기화': '초기화',
  '공지사항': '공지사항',
  '남자': '남자',
  '여자': '여자',
  '질문': '질문',
  '선택': '선택',
  'FAQ': 'FAQ',
  '생활민원': '생활민원',
  '영선': '영선',
  '시설': '시설',
  '서비스': '서비스',
  '사건사고': '사건사고',
  '기타': '기타',
  '전체': '전체',
  '데이터가 없습니다': '데이터가 없습니다.',
  '파일찾기를 클릭하여 파일을 업로드 하세요': '파일찾기를 클릭하여 파일을 업로드 하세요.',
  '빠른 숙소 신청 서비스로 간편하게 숙소를 이용해보세요': '빠른 숙소 신청 서비스로 간편하게 숙소를 이용해보세요.',
  '숙소이용을 위한 공지사항을 확인하실 수 있습니다': '숙소이용을 위한 공지사항을 확인하실 수 있습니다',
  '환경심사를 위한 일정 등 안내를 확인 하실 수 있습니다': '환경심사를 위한 일정 등 안내를 확인 하실 수 있습니다',
  '정기서류검사를 위한 안내 및 서류 등록을 하실 수 있습니다': '정기서류검사를 위한 안내 및 서류 등록을 하실 수 있습니다',
  '빈도수가 높은 질문에 대한 답변을 확인하실 수 있습니다': '빈도수가 높은 질문에 대한 답변을 확인하실 수 있습니다',
  '숙소이용 불편사항 등을 게시하고 답변 받을 수 있습니다': '숙소이용 불편사항 등을 게시하고 답변 받을 수 있습니다',
  '최근 한달간 알림을 확인하실 수 있습니다': '최근 한달간 알림을 확인하실 수 있습니다',
  '회원가입 정보를 확인하고 수정하실 수 있습니다': '회원가입 정보를 확인하고 수정하실 수 있습니다',
  '숙소이용신청 내역확인 및 수정을 진행하실 수 있습니다': '숙소이용신청 내역확인 및 수정을 진행하실 수 있습니다',
  '기숙사, 사택  퇴숙신청 및 현황을 확인하실 수 있습니다': '기숙사, 사택  퇴숙신청 및 현황을 확인하실 수 있습니다',
  '기숙사, 이숙신청및 현황을 확인하실 수 있습니다': '기숙사, 이숙신청및 현황을 확인하실 수 있습니다',
  '숙소이용 이력 및 상세내용을 확인하실 수 있습니다': '숙소이용 이력 및 상세내용을 확인하실 수 있습니다',
  '필수항목을 업로드하지 않았습니다.': '필수항목을 업로드하지 않았습니다.',
  '기숙사' : '기숙사',
  '이미 이용중인 숙소가 있습니다.\n 퇴숙완료 후 신청 가능합니다.': '이미 이용중인 숙소가 있습니다.\n 퇴숙완료 후 신청 가능합니다.',
  '이미 이용중인 숙소가 있습니다.\n 퇴숙완료 후 신청 가능합니다.\n 신청관리 화면에서 확인해주세요.': '이미 이용중인 숙소가 있습니다.\n 퇴숙완료 후 신청 가능합니다.\n 신청관리 화면에서 확인해주세요.',
  '이미 이용중이거나 신청중인 숙소가 있습니다.\n 퇴숙완료 또는 신청취소 후 신청 가능합니다.\n 신청관리 화면에서 확인해주세요.': 
  '이미 이용중이거나 신청중인 숙소가 있습니다.\n 퇴숙완료 또는 신청취소 후 신청 가능합니다.\n 신청관리 화면에서 확인해주세요.',
  '사번이 올바르지 않습니다.': '사번이 올바르지 않습니다.',
  '올바른 핸드폰 번호를 입력해 주세요.': '올바른 핸드폰 번호를 입력해 주세요.',
  '게스트정보가 올바르지 않습니다.': '게스트정보가 올바르지 않습니다.',
  '예약 중 문제발생': '예약 중 문제발생',
  '예약 중 문제가 발생했습니다.': '예약 중 문제가 발생했습니다.',
  '신청단계': '신청단계',
  '서비스이용약관 동의 또는 개인정보이용동의를 동의하지 않았습니다.': '서비스이용약관 동의 또는 개인정보이용동의를 동의하지 않았습니다.',
  '성명을 입력해주세요.': '성명을 입력해주세요.',
  '휴대폰번호 항목이 비었거나 잘못되었습니다.\n(휴대폰번호는 11자리입니다.)': '휴대폰번호 항목이 비었거나 잘못되었습니다.\n(휴대폰번호는 11자리입니다.)',
  '성별 항목이 비었습니다.': '성별 항목이 비었습니다.',
  '사번을 검색하여 이용자 정보를 등록해주세요.' : '사번을 검색하여 이용자 정보를 등록해주세요.',
  '소속부서를 입력해주세요.': '소속부서를 입력해주세요.',
  '근무조를 선택해주세요.': '근무조를 선택해주세요.',
  '사번을 입력해주세요.': '사번을 입력해주세요.',
  '회사명을 선택해주세요.': '회사명을 선택해주세요.',
  '회사명 또는 단체명을 입력해주세요.': '회사명 또는 단체명을 입력해주세요.',
  '는 가입된 사번이 아닙니다.': '는 가입된 사번이 아닙니다.',
  '신청자 정보': '신청자 정보',
  '사번 (아이디)': '사번 (아이디)',
  '사번': '사번',
  '성명': '성명',
  '성별': '성별',
  '소속 부서': '소속 부서',
  '휴대폰 번호': '휴대폰 번호',
  '신청자와 이용자가 동일합니다 (신청자 본인이 이용할 경우 체크하시면 이용자 정보가 자동 입력 됩니다)':'신청자와 이용자가 동일합니다 (신청자 본인이 이용할 경우 체크하시면 이용자 정보가 자동 입력 됩니다)',
  '이용자 정보' : '이용자 정보',
  '사업장 선택': '사업장 선택',
  '이용 사업장을 선택 후 이용자 정보를 입력하여 주세요': '이용 사업장을 선택 후 이용자 정보를 입력하여 주세요',
  '적용': '적용',
  '사번을 입력하세요' : '사번을 입력하세요',
  '성명을 입력하세요': '성명을 입력하세요',
  '소속부서를 입력하세요': '소속부서를 입력하세요',
  '휴대폰 번호를 입력하세요': '휴대폰 번호를 입력하세요',
  '근무조': '근무조',
  '차량번호': '차량번호',
  '차량번호를 입력하세요': '차량번호를 입력하세요',
  '서비스 이용약관 동의(필수)': '서비스 이용약관 동의(필수)',
  '개인정보 이용 동의(필수)': '개인정보 이용 동의(필수)',
  '자세히보기': '자세히보기',
  '대리 신청의 경우 사번을 입력하시면 인사 정보 연동하여 기본 정보가 자동 입력 됩니다. 차량번호를 제외한 모든 입력 사항은 필수로 입력하셔야 합니다.': '대리 신청의 경우 사번을 입력하시면 인사 정보 연동하여 기본 정보가 자동 입력 됩니다. 차량번호를 제외한 모든 입력 사항은 필수로 입력하셔야 합니다.',
  '회사명(단체명)': '회사명(단체명)',
  '회사명' : '회사명',
  '차량번호를 제외한 모든 입력 사항은 필수로 입력하셔야 합니다.': '차량번호를 제외한 모든 입력 사항은 필수로 입력하셔야 합니다.',
  '회사명 또는 단체명을 입력하세요': '회사명 또는 단체명을 입력하세요',
  '증빙서류': '증빙서류',
  '이용신청을 취소 하시겠습니까?': '이용신청을 취소 하시겠습니까?',
  '근무조를 선택하세요': '근무조를 선택하세요',
  '회사명을 선택하세요': '회사명을 선택하세요',
  '숙소 이용안내' : '숙소 이용안내',
  '파일 다운로드' : '파일 다운로드',
  '다운로드': '다운로드',
  '입숙 서약서': '입숙 서약서',
  '기숙사 입숙 서약서 및 입숙 준비사항에 대한 모든 항목을 확인하였으며 해당 내용에 동의하며 이를 충실히 이행 할 것을 서약합니다.':
  '기숙사 입숙 서약서 및 입숙 준비사항에 대한 모든 항목을 확인하였으며 해당 내용에 동의하며 이를 충실히 이행 할 것을 서약합니다.',
  '서약 일자' : '서약 일자',
  '동의합니다': '동의합니다',
  '동의하지 않습니다': '동의하지 않습니다',
  '예약을 진행하시겠습니까?': '예약을 진행하시겠습니까?',
  '입숙 서약서 내용에 동의를': '입숙 서약서 내용에 동의를',
  '하셔야만 이용 신청이 가능합니다': '하셔야만 이용 신청이 가능합니다',
  '동의 여부를 확인하여 주세요': '동의 여부를 확인하여 주세요',
  '신청이 완료되었습니다': '신청이 완료되었습니다',
  '이용 신청이 접수되었습니다. 관리자가 확인 후 승인이 완료되면 ':'이용 신청이 접수되었습니다. 관리자가 확인 후 승인이 완료되면 ',
  '마이페이지 신청관리에서 직접 배방을 진행하셔야 합니다.': '마이페이지 신청관리에서 직접 배방을 진행하셔야 합니다.',
  '신청된 이용자 정보는 하기와 같습니다.':'신청된 이용자 정보는 하기와 같습니다.',
  '예약정보': '예약정보',
  '신청관리 바로가기': '신청관리 바로가기',
  '숙소유형':'숙소유형',
  '이용사업장': '이용사업장',
  '이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 신청된 입숙일에 해당 사업장의 관리사무소로 방문하시어 간단한 인적사항 확인 후 키 분출을 하실 수 있습니다':
  '이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 신청된 입숙일에 해당 사업장의 관리사무소로 방문하시어 간단한 인적사항 확인 후 키 분출을 하실 수 있습니다',
  '정보 등록': '정보 등록',
  '입숙서약동의': '입숙서약동의',
  '신청완료': '신청완료',
  '신청 완료 이후': '신청 완료 이후',
  '관리자 승인 및 배방오픈': '관리자 승인 및 배방오픈',
  '신청관리에서 배방신청 및 입숙 예정일 입력': '신청관리에서 배방신청 및 입숙 예정일 입력',
  '예약 완료': '예약 완료',
  '사업장선택 및 정보등록': '사업장선택 및 정보등록',
  '신청관리에서 배방신청': '신청관리에서 배방신청',
  '기간선택': '기간선택',
  '배방선택': '배방선택',
  '보증금 입금증 업로드 및 입숙 예정일 입력': '보증금 입금증 업로드 및 입숙 예정일 입력',
  '투숙인원': '투숙인원',
  '배방선택 및 정보등록': '배방선택 및 정보등록',
  '출장자숙소(개인)': '출장자숙소(개인)',
  '입숙예정일' : '입숙예정일',
  '퇴숙예정일' : '퇴숙예정일',
  '배방정보' : '배방정보',
  '건물/동' : '건물/동',
  '건물명/동' : '건물명/동',
  '방호수' : '방호수',
  '방호실/명': '방호실/명',
  '사내 임직원의 경우 사번을 입력하시면 인사 정보 연동하여 기본 정보가 자동 입력 됩니다. 차량번호를 제외한 모든 입력 사항은 필수로 입력하셔야 합니다.':
  '사내 임직원의 경우 사번을 입력하시면 인사 정보 연동하여 기본 정보가 자동 입력 됩니다. 차량번호를 제외한 모든 입력 사항은 필수로 입력하셔야 합니다.',
  '이용 예약을 취소하시겠습니까?': '이용 예약을 취소하시겠습니까?',
  '이용신청을 취소하시겠습니까?': '이용신청을 취소하시겠습니까?',
  '이용기간 선택': '이용기간 선택',
  '이용 기간을 선택하여 주세요.': '이용 기간을 선택하여 주세요.',
  '당일 예약선택': '당일 예약선택',
  '새로고침': '새로고침',
  '선택한 방은 총원 ' : '선택한 방은 총원 ',
  '명 중 ': '명 중 ',
  '명이 사용중입니다. \n정말 예약을 진행하시겠습니까?': '명이 사용중입니다. \n정말 예약을 진행하시겠습니까?',
  '동을 선택하고 층수를 선택하여 빈방을 검색하여 선택하여 주세요': '동을 선택하고 층수를 선택하여 빈방을 검색하여 선택하여 주세요',
  '위치보기' : '위치보기',
  '배방신청': '배방신청',
  '건물명 및 동': '건물명 및 동',
  '층 수': '층 수',
  '이용 가능': '이용 가능',
  '이용 불가능': '이용 불가능',
  '출장자 숙소 입숙 서약서 및 입숙 준비사항에 대한 모든 항목을 확인하였으며 해당 내용에 동의하며 이를 충실히 이행 할 것을 서약합니다.':
  '출장자 숙소 입숙 서약서 및 입숙 준비사항에 대한 모든 항목을 확인하였으며 해당 내용에 동의하며 이를 충실히 이행 할 것을 서약합니다.',
  '예약이 완료되었습니다': '예약이 완료되었습니다',
  '이용 신청이 접수 및 승인되어 예약이 완료되었습니다.': '이용 신청이 접수 및 승인되어 예약이 완료되었습니다.',
  '예약정보 및 이용자 정보는 하기와 같습니다.': '예약정보 및 이용자 정보는 하기와 같습니다.',
  '사택': '사택',
  '사택 입숙 서약서 및 입숙 준비사항에 대한 모든 항목을 확인하였으며 해당 내용에 동의하며 이를 충실히 이행 할 것을 서약합니다.':
  '사택 입숙 서약서 및 입숙 준비사항에 대한 모든 항목을 확인하였으며 해당 내용에 동의하며 이를 충실히 이행 할 것을 서약합니다.',
  '이용 신청이 접수 되었습니다. 관리자가 확인 후 매월 25일 승인여부가 결정됩니다.': '이용 신청이 접수 되었습니다. 관리자가 확인 후 매월 25일 승인여부가 결정됩니다.',
  '이용 신청이 접수 되었습니다. 이용신청 후 3일 이내 대기순번(신청순)에 따라 승인여부가 결정됩니다.': '이용 신청이 접수 되었습니다. 이용신청 후 3일 이내 대기순번(신청순)에 따라 승인여부가 결정됩니다.',
  '입숙 선정자로 승인이 되면 문자가 발송 되오니 문자 확인이 되시면': '입숙 선정자로 승인이 되면 문자가 발송 되오니 문자 확인이 되시면',
  '마이페이지 신청관리에서 배방 확인 및 입숙 예정일을 입력해 주세요.': '마이페이지 신청관리에서 배방 확인 및 입숙 예정일을 입력해 주세요.',
  '번째 사번은 등록되지 않았습니다.': '번째 사번은 등록되지 않았습니다.',
  '입숙일 항목이 비었습니다.': '입숙일 항목이 비었습니다.',
  '퇴숙일 항목이 비었습니다.': '퇴숙일 항목이 비었습니다.',
  '성별을 선택하지 않았습니다.': '성별을 선택하지 않았습니다.',
  '날짜를 다시한번 확인해주세요.': '날짜를 다시한번 확인해주세요.',
  '출장자숙소(단체)' : '출장자숙소(단체)',
  '이용자 중 인솔자는 반드시\n 1명 이상 선택되어야 합니다.\n 인솔자 여부를 확인해주세요.':
  '이용자 중 인솔자는 반드시\n 1명 이상 선택되어야 합니다.\n 인솔자 여부를 확인해주세요.',
  '부서 항목이 비었습니다.' : '부서 항목이 비었습니다.',
  '회사 항목을 선택해주세요.': '회사 항목을 선택해주세요.',
  '사번 항목이 비었습니다.': '사번 항목이 비었습니다.',
  '회사 항목이 비었습니다.' : '회사 항목이 비었습니다.',
  '협조전이 등록되어있지 않습니다.\n 협조전 파일을 등록해주세요.': '협조전이 등록되어있지 않습니다.\n 협조전 파일을 등록해주세요.',
  '추가된 이용자가 없습니다.': '추가된 이용자가 없습니다.',
  '업로드하신 엑셀파일의 형태가 맞지 않거나 입력정보가 올바르지 않아서 인원추가에 실패하였습니다. \n양식다운로드 하신 파일을 이용해 주시기 바랍니다.':
  '업로드하신 엑셀파일의 형태가 맞지 않거나 입력정보가 올바르지 않아서 인원추가에 실패하였습니다. \n양식다운로드 하신 파일을 이용해 주시기 바랍니다.',
  '입숙일/퇴숙일 날짜는 올바른 날짜여야 합니다.': '입숙일/퇴숙일 날짜는 올바른 날짜여야 합니다.',
  '조회중 문제가 발생했습니다.' : '조회중 문제가 발생했습니다.',
  '협조전 등록이 완료되었습니다.' : '협조전 등록이 완료되었습니다.',
  '협조전 업로드 중 문제가 발생했습니다' : '협조전 업로드 중 문제가 발생했습니다',
  '파일의 용량이 큽니다.\n10M 미만의 파일을 등록해 주세요' : '파일의 용량이 큽니다.\n10M 미만의 파일을 등록해 주세요',
  '양식다운로드': '양식다운로드',
  '업로드 된 파일 ' : '업로드 된 파일 ',
  '을 정말 삭제하시겠습니까?' : '을 정말 삭제하시겠습니까?',
  '식권 수 (장)' : '식권 수 (장)',
  '식권' : '식권',
  '신청하실 식권 수를 입력해주세요' : '신청하실 식권 수를 입력해주세요',
  '입숙일' : '입숙일',
  '날짜를 선택해주세요' : '날짜를 선택해주세요',
  '퇴숙일': '퇴숙일',
  '인솔자 여부' : '인솔자 여부',
  '여': '여',
  '부': '부',
  '입력 창에서 직접 입력이 가능합니다. 정보 입력 후 추가 버튼을 클릭하시면 추가 됩니다': '입력 창에서 직접 입력이 가능합니다. 정보 입력 후 추가 버튼을 클릭하시면 추가 됩니다',
  '사내 임직원의 경우 사번을 입력하시면 인사 정보 연동하여 기본 정보가 자동 입력 됩니다' : '사내 임직원의 경우 사번을 입력하시면 인사 정보 연동하여 기본 정보가 자동 입력 됩니다',
  '먼저 이용자 유형을 선택하시고 파일 업로드를 하시거나 직접 정보 입력을 해주세요' : '먼저 이용자 유형을 선택하시고 파일 업로드를 하시거나 직접 정보 입력을 해주세요',
  '출장자 숙소 단체 신청의 경우 양식 다운로드 받아서 파일업로드로 업무 처리하시기를 권장합니다' : '출장자 숙소 단체 신청의 경우 양식 다운로드 받아서 파일업로드로 업무 처리하시기를 권장합니다',
  '만약 이용자 정보의 개별 등록을 원하실 경우 직접입력을 하여 추가하셔도 됩니다' : '만약 이용자 정보의 개별 등록을 원하실 경우 직접입력을 하여 추가하셔도 됩니다',
  '이용자 유형이 다양하거나 파일업로드와 직접입력 혼용도 가능합니다' : '이용자 유형이 다양하거나 파일업로드와 직접입력 혼용도 가능합니다',
  '이용자' : '이용자',
  '타입' : '타입',
  '(아이디)' : '(아이디)',
  '소속' : '소속',
  '부서' : '부서',
  '휴대폰' : '휴대폰',
  '번호' : '번호',
  '입숙' : '입숙',
  '예정일' : '예정일',
  '퇴숙' : '퇴숙',
  '인솔자' : '인솔자',
  '여부' : '여부',
  '협조전 파일' : '협조전 파일',
  '협조전은 한 개만 등록할 수 있습니다. 어떤 파일유형이든 업로드 가능하며 10M이상의 파일은 올릴 수 없습니다.':
  '협조전은 한 개만 등록할 수 있습니다. 어떤 파일유형이든 업로드 가능하며 10M이상의 파일은 올릴 수 없습니다.',
  '정말 취소하시겠습니까?' : '정말 취소하시겠습니까?',
  '정말로 삭제하시겠습니까?' : '정말로 삭제하시겠습니까?',
  '아니오': '아니오',
  '예' : '예',
  '회사명을 입력하세요' : '회사명을 입력하세요',
  '이용 신청이 접수 되었습니다.' : '이용 신청이 접수 되었습니다.',
  '관리자가 확인 후 승인이 완료되면 마이페이지 신청관리에서 직접 배방을 진행하셔야 합니다' : '관리자가 확인 후 승인이 완료되면 마이페이지 신청관리에서 직접 배방을 진행하셔야 합니다',
  '신청된 자세한 정보는 마이페이지 신청관리에서 확인이 가능합니다.' : '신청된 자세한 정보는 마이페이지 신청관리에서 확인이 가능합니다.',
  '신청 총 이용 인원' : '신청 총 이용 인원',
  '이용자 정보에 등록되신 분들이 해당 숙소를 이용하실 수 있으며 신청된 입숙일에 인솔자 분들은 해당 사업장의 관리사무소로 방문하시어 간단한 확인 등의 절차를 거쳐 키 분출 하실 수 있습니다':
  '이용자 정보에 등록되신 분들이 해당 숙소를 이용하실 수 있으며 신청된 입숙일에 인솔자 분들은 해당 사업장의 관리사무소로 방문하시어 간단한 확인 등의 절차를 거쳐 키 분출 하실 수 있습니다',
  'R&D STAY' : 'R&D STAY',
  '이용자 정보 입력이 충분하지 않습니다.' : '이용자 정보 입력이 충분하지 않습니다.',
  '신청중 문제가 발생했습니다.' : '신청중 문제가 발생했습니다.',
  '투숙인원을 선택하시고 기간을 선택해 주세요' : '투숙인원을 선택하시고 기간을 선택해 주세요',
  '투숙인원을 선택 후 투숙자의 정보를 입력해 주세요' : '투숙인원을 선택 후 투숙자의 정보를 입력해 주세요',
  '방을 선택해야 합니다.' : '방을 선택해야 합니다.',
  '가족 숙소는 3인실 입니다. 호 단위로 신청이 가능합니다' : '가족 숙소는 3인실 입니다. 호 단위로 신청이 가능합니다',
  '성명, 성별, 신청자와의 관계는 필수로 입력하셔야 합니다.' : '성명, 성별, 신청자와의 관계는 필수로 입력하셔야 합니다.',
  '성별을 선택하세요' : '성별을 선택하세요',
  '신청자와의 관계' : '신청자와의 관계',
  '이용자의 관계를 입력하세요' : '이용자의 관계를 입력하세요',
  '입숙 서약서 내용에 동의를 \n하셔야만 이용 신청이 가능합니다\n동의 여부를 확인하여 주세요':
  '입숙 서약서 내용에 동의를 \n하셔야만 이용 신청이 가능합니다\n동의 여부를 확인하여 주세요',
  '예약정보는 하기와 같습니다' : '예약정보는 하기와 같습니다',
  '호' : '호',
  '환경관리심사': '환경관리심사',
  '투숙 인원 및 정보 등록': '투숙 인원 및 정보 등록',
  '펼치기/접기': '펼치기/접기',
  '파일 업로드': '파일 업로드',
  '이용 신청이 접수되었습니다.' : '이용 신청이 접수되었습니다.',
  '관리자가 확인 후 배방을 진행하며 해당 정보는 신청관리에서 확인하실 수 있습니다.' : '관리자가 확인 후 배방을 진행하며 해당 정보는 신청관리에서 확인하실 수 있습니다.',
  'R&D STAY는 신청자의 가족이신 분들만 사용가능합니다. 입숙시, 가족관계증명서를 지참하시기 바랍니다.': 'R&D STAY는 신청자의 가족이신 분들만 사용가능합니다. 입숙시, 가족관계증명서를 지참하시기 바랍니다.',
  '작성일자': '작성일자',
  '등록일자': '등록일자',
  '정기서류관리': '정기서류관리',
  '정기서류검사등록': '정기서류검사등록',
  '반려사유': '반려사유',
  
  '파일 업로드와 직접입력 혼용도 가능합니다' : '파일 업로드와 직접입력 혼용도 가능합니다',
  'My VoC': 'My VoC',
  '회사명(단체명)을 입력하세요' : '회사명(단체명)을 입력하세요',
  '등록': '등록',
  '변경하신 내용이 삭제됩니다. 취소하시겠습니까?': '변경하신 내용이 삭제됩니다.\n취소하시겠습니까?',
  '작성하신 내용을 등록하시겠습니까?': '작성하신 내용을 등록하시겠습니까?',
  '입력하신 내용이 삭제됩니다. 목록으로 이동하시겠습니까?': '입력하신 내용이 삭제됩니다.\n목록으로 이동하시겠습니까?',
  '등록 완료했습니다.': '등록 완료했습니다.',
  '수정 완료했습니다.': '수정 완료했습니다.',
  '만약 이용자 정보의 개별 등록을 원하실 경우 직접입력이 가능합니다' :'만약 이용자 정보의 개별 등록을 원하실 경우 직접입력이 가능합니다',
  '정보 입력 후 추가버튼을 클릭하시면 하단 단체이용자 정보에 추가됩니다' : '정보 입력 후 추가버튼을 클릭하시면 하단 단체이용자 정보에 추가됩니다',
  '동일한 이용자 명으로 중복된 날짜 정보가 있습니다.' : '동일한 이용자 명으로 중복된 날짜 정보가 있습니다.',
  '업로드한 파일에 오류가 있습니다. \n양식을 다운로드 받아 사용해 주세요.' : '업로드한 파일에 오류가 있습니다. \n양식을 다운로드 받아 사용해 주세요.',
  '이용 신청이 정상적으로 접수 되었습니다.' : '이용 신청이 정상적으로 접수 되었습니다.',
  '관리자가 신청 내용 확인 후 사업장 별로 승인 및 배방 오픈이 이루어지며' : '관리자가 신청 내용 확인 후 사업장 별로 승인 및 배방 오픈이 이루어지며',
  '관련 내용은 마이페이지 > 신청관리 메뉴에서 확인이 가능합니다.' : '관련 내용은 마이페이지 > 신청관리 메뉴에서 확인이 가능합니다.',
  '신청 접수된 이용자 정보의 본인만이 숙소이용이 가능합니다.' : '신청 접수된 이용자 정보의 본인만이 숙소이용이 가능합니다.',
  '각 현장별로 관리자가 승인완료 후 신청자가 방선택을 완료하면 관리자 최종승인 후 예약이 완료됩니다.' :
  '각 현장별로 관리자가 승인완료 후 신청자가 방선택을 완료하면 관리자 최종승인 후 예약이 완료됩니다.',
  '이용자 성명' : '이용자 성명',
  '이용 신청이 정상적으로 접수되었습니다' : '이용 신청이 정상적으로 접수되었습니다',
  '관리자가 신청 내용 및 서류 확인 후 승인 및 배방이 이루어지며' : '관리자가 신청 내용 및 서류 확인 후 승인 및 배방이 이루어지며',
  '관리자가 신청 내용 확인 후 사업장 별로 승인 및 배방이 완료됩니다' : '관리자가 신청 내용 확인 후 사업장 별로 승인 및 배방이 완료됩니다',
  '행에서 휴대폰번호 항목이 잘못되었습니다.' : '행에서 휴대폰번호 항목이 잘못되었습니다.',
  '행에서 사번이 잘못되었습니다.' : '행에서 사번이 잘못되었습니다.',
  '행에서 날짜가 잘못되었습니다.' : '행에서 날짜가 잘못되었습니다.',
  '행에서 식권 수가 잘못되었습니다.' : '행에서 식권 수가 잘못되었습니다.',
  '건물명 선택': '건물명 선택',
  '숙소유형 선택': '숙소유형 선택',
  '선택된 장소가 없습니다.': '선택된 장소가 없습니다.',
  '_번 지역': '{place}번 지역',
  '_호': '{room} 호',
  '삭제 완료했습니다.': '삭제 완료했습니다.',
  '내용을 입력해주세요.': '내용을 입력해주세요.',
  '작성을 완료했습니다.': '작성을 완료했습니다.',
  '_님 _님을 담당자로 설정하였습니다.': '{name}님 {name2}님을 담당자로 설정하였습니다.',
  '상태로 변경되었습니다.': '상태로 변경되었습니다.',
  '추가문의': '추가문의',
  '문의하기': '문의하기',
  '문의글을 남겨보세요': '문의글을 남겨보세요',
  '발생 시간': '발생 시간',
  '시경': '시경',
  '행에서 빈항목이 있습니다.\n(성명, 휴대폰번호, 입숙일, 퇴숙일)' : '행에서 빈항목이 있습니다.\n(성명, 휴대폰번호, 입숙일, 퇴숙일)',
  '행에서 빈항목이 있습니다.\n(성명, 휴대폰번호, 입숙일, 퇴숙일, 성별, 인솔자 여부)' : '행에서 빈항목이 있습니다.\n(성명, 휴대폰번호, 입숙일, 퇴숙일, 성별, 인솔자 여부)',
  '업로드한 파일에 오류가 있습니다.\n' : '업로드한 파일에 오류가 있습니다.\n',
  '행에서 인솔자 여부 항목이 잘못되었습니다.' :'행에서 인솔자 여부 항목이 잘못되었습니다.',
  '사내부부 여부' : '사내부부 여부',
  '주거지원금 수혜여부' : '주거지원금 수혜여부',
  '잔여기간' : '잔여기간',
  '숙소 성별 유형이 맞지 않습니다.': '숙소 성별 유형이 맞지 않습니다.',
  '신청 접수된 이용자 정보' : '신청 접수된 이용자 정보',
  '이용 사업장이 울산일 경우 현대차직원, 계열사 직원으로만 신청 가능합니다' : 
  '이용 사업장이 울산일 경우 현대차직원, 계열사 직원으로만 신청 가능합니다',
  '이용 사업장이 남양연구센터일 경우 현대차직원으로만 신청 가능합니다.' : 
  '이용 사업장이 남양연구센터일 경우 현대차직원으로만 신청 가능합니다.',
  '이용 사업장이 전주공장일 경우 현대차직원, 계열사직원으로만 신청 가능합니다' :
  '이용 사업장이 전주공장일 경우 현대차직원, 계열사직원으로만 신청 가능합니다',
  '해당 사업장은 미혼만 신청 가능합니다.': '해당 사업장은 미혼만 신청 가능합니다.',
  '해당 사업장은 기혼만 신청 가능합니다.':'해당 사업장은 기혼만 신청 가능합니다.',
  '방을 선택하지 않았습니다.': '방을 선택하지 않았습니다.',
  '필요한 전체 식권 수량을 입력 하세요' : '필요한 전체 식권 수량을 입력 하세요',
  '권한이 없습니다.' : '권한이 없습니다.',
  '숙소이용안내' : '숙소이용안내',
  '입숙예정일에 해당 사업장의 관리사무소로 방문하시면' : '입숙예정일에 해당 사업장의 관리사무소로 방문하시면',
  '관리사무소 직원의 안내에 따라 입숙절차가 마무리되고 ' : '관리사무소 직원의 안내에 따라 입숙절차가 마무리되고 ',
  '키를 수령 하실 수 있습니다.' : '키를 수령 하실 수 있습니다.',
  '예약된 숙소 이용 정보' : '예약된 숙소 이용 정보',
  '관리자가 신청 내용 및 서류 확인 후 사업장 별로 승인 또는 승인 및 임시배방이 이루어지며' : '관리자가 신청 내용 및 서류 확인 후 사업장 별로 승인 또는 승인 및 임시배방이 이루어지며',
  '관련 내용은 마이페이지 > 신청 관리 메뉴에서 확인이 가능합니다.' : '관련 내용은 마이페이지 > 신청 관리 메뉴에서 확인이 가능합니다.',
  '관리자가 신청 내용 서류 확인 후 사업장 별로 승인 및 배방이 완료됩니다.' : '관리자가 신청 내용 서류 확인 후 사업장 별로 승인 및 배방이 완료됩니다.',
  '이미 신청중이거나 승인상태인 R&D STAY가 있습니다.' : '이미 신청중이거나 승인상태인 R&D STAY가 있습니다.',
  '삭제 할 내용 선택해 주세요.':'삭제 할 내용 선택해 주세요.',
  '사번을 적용해주세요.' : '사번을 적용해주세요.',
  '발송 장소 선택' : '발송 장소 선택',
  '입숙희망일' : '입숙희망일',
  '입숙 희망일을 입력해주세요.' : '입숙 희망일을 입력해주세요.',
  '주의사항 : 1인1대 현대.기아 차량에 한하여 차량등록 가능합니다.' : '주의사항 : 1인1대 현대.기아 차량에 한하여 차량등록 가능합니다.',
  '이용 신청이 접수되었습니다. 관리자가 신청 내용 및 서류 확인 후 승인 및 배방이 이루어지며 관련 내용은 마이페이지 > 신청관리 메뉴에서 확인이 가능합니다. 신청된 이용자 정보는 하기와 같습니다.':
  '이용 신청이 접수되었습니다. 관리자가 신청 내용 및 서류 확인 후 승인 및 배방이 이루어지며 관련 내용은 마이페이지 > 신청관리 메뉴에서 확인이 가능합니다. 신청된 이용자 정보는 하기와 같습니다.',
  '이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 기숙사 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.':
  '이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 기숙사 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.',
  '코로나 관련하여 개인 위생을 철저히 지켜주시기 바라며, 정부의 행동지침에 따라 주시기 바랍니다. 코로나 의심증상 있을시 연구소 코로나 상황실(031-368-6510)연락주시기 바랍니다.':
  '코로나 관련하여 개인 위생을 철저히 지켜주시기 바라며, 정부의 행동지침에 따라 주시기 바랍니다. 코로나 의심증상 있을시 연구소 코로나 상황실(031-368-6510)연락주시기 바랍니다.',
  '이용 신청이 정상적으로 접수 되었습니다. 관리자가 신청 내용 확인 후 사업장 별로 승인 및 배방 오픈이 이루어지며 관련 내용은 마이페이지 > 신청관리 메뉴에서 확인이 가능합니다.':
  '이용 신청이 정상적으로 접수 되었습니다. 관리자가 신청 내용 확인 후 사업장 별로 승인 및 배방 오픈이 이루어지며 관련 내용은 마이페이지 > 신청관리 메뉴에서 확인이 가능합니다.',
  '이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 출장자(단체) 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.':
  '이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 출장자(단체) 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.',
  '이용 신청이 접수 및 승인되어 예약이 완료되었습니다. 신청 접수된 이용자 정보의 본인만이 숙소이용이 가능합니다. 입숙예정일에 해당 사업장의 관리사무소로 방문하시면 관리사무소 직원의 안내에 따라 입숙절차가 마무리되고 ':
  '이용 신청이 접수 및 승인되어 예약이 완료되었습니다. 신청 접수된 이용자 정보의 본인만이 숙소이용이 가능합니다. 입숙예정일에 해당 사업장의 관리사무소로 방문하시면 관리사무소 직원의 안내에 따라 입숙절차가 마무리되고 ',
  '이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 출장자(개인) 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.':
  '이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 출장자(개인) 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.',
  '이용 신청이 접수 되었습니다. 관리자가 확인 후 매월 25일 승인여부가 결정됩니다. 입숙 선정자로 승인이 되면 문자가 발송 되오니 문자 확인이 되시면 마이페이지 신청관리에서 배방 확인 및 입숙 예정일을 입력해 주세요. 신청된 이용자 정보는 하기와 같습니다.':
  '이용 신청이 접수 되었습니다. 관리자가 확인 후 매월 25일 승인여부가 결정됩니다. 입숙 선정자로 승인이 되면 문자가 발송 되오니 문자 확인이 되시면 마이페이지 신청관리에서 배방 확인 및 입숙 예정일을 입력해 주세요. 신청된 이용자 정보는 하기와 같습니다.',
  '이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 사택 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.':
  '이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 사택 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.',
  '이용 신청이 정상적으로 접수되었습니다. 관리자가 신청 내용 확인 후 사업장 별로 승인 및 배방이 완료됩니다. 관련 내용은 마이페이지 > 신청관리 메뉴에서 확인이 가능합니다.':
  '이용 신청이 정상적으로 접수되었습니다. 관리자가 신청 내용 확인 후 사업장 별로 승인 및 배방이 완료됩니다. 관련 내용은 마이페이지 > 신청관리 메뉴에서 확인이 가능합니다.',
  '이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 R&D STAY 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.':
  '이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 R&D STAY 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.',
  '이용 신청이 접수되었습니다. 관리자가 신청 내용 서류 확인 후 사업장 별로 승인 및 배방이 완료됩니다. 관련 내용은 마이페이지 > 신청 관리 메뉴에서 확인이 가능합니다.':
  '이용 신청이 접수되었습니다. 관리자가 신청 내용 서류 확인 후 사업장 별로 승인 및 배방이 완료됩니다. 관련 내용은 마이페이지 > 신청 관리 메뉴에서 확인이 가능합니다.',
  '이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 영빈관 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.':
  '이용자 정보에 등록되신 분이 해당 숙소를 이용하실 수 있으며 영빈관 배정 호실 및 안내 받은 입숙일에 관리사무소 방문하시어 간단한 인적사항 확인 후 지급 비품 수령 후 입숙하실 수 있습니다.',
  '근무조 : 상시주간' : '근무조 : 상시주간',
  '입숙희망일 : 기숙사 입숙 검토 3일이상 소요됩니다.' : '입숙희망일 : 기숙사 입숙 검토 3일이상 소요됩니다.',
  '차량번호 : 현대/기아차 한해 1인 1주대 주차가능 (문의 : 기숙사 관리 사무소 031-356-4285)' : '차량번호 : 현대/기아차 한해 1인 1주대 주차가능 (문의 : 기숙사 관리 사무소 031-356-4285)',
  '기타인 경우 내용을 입력해주세요': '기타인 경우 내용을 입력해주세요',
  '입숙 서약서 다운로드 -> 확인후 사인 -> 증빙서류 파일업로드 시 서약서 파일업로드 하여 서류 제출 요청드립니다.' : '입숙 서약서 다운로드 -> 확인후 사인 -> 증빙서류 파일업로드 시 서약서 파일업로드 하여 서류 제출 요청드립니다.',
  '기숙사 신청전 이용안내 탭에서 기숙사 입숙조건 및 기타 내용 확인 후 신청해주세요.' : '기숙사 신청전 이용안내 탭에서 기숙사 입숙조건 및 기타 내용 확인 후 신청해주세요.',
  '사택 신청전 이용안내 탭에서 사택 입숙조건 및 기타 내용 확인 후 신청해주세요.' : '사택 신청전 이용안내 탭에서 사택 입숙조건 및 기타 내용 확인 후 신청해주세요.',
  '기타인 경우 분류를 선택해주세요': '기타인 경우 분류를 선택해주세요',
  '증빙서류인 지방세세목별과세증명서는 아래의 [과세증명서 매뉴얼]을 다운받아 확인 후 발급하여 업로드하여 하시기 바랍니다.':'증빙서류인 지방세세목별과세증명서는 아래의 [과세증명서 매뉴얼]을 다운받아 확인 후 발급하여 업로드하여 하시기 바랍니다.',
  '# 발급처는 구)동사무소만 가능합니다. (인터넷,발급기 발급 불가)':'# 발급처는 구)동사무소만 가능합니다. (인터넷,발급기 발급 불가)'
};

export default kr;
