import React from 'react';
import './index.scss';
import Header from '../../components/organismas/header';
import Footer from '../../components/organismas/footer';

const NotFoundPage = (props: any) => {
  return (
    <div id="wrap" className="wrap_error">
      <Header {...props} isApiDisabled={true} />
      <div id="container">
        <div className="row clearfix">
          <div className="col col_left group_title">
            <h2>오류안내</h2>
            <p className="desc">
              빠른 숙소 신청 서비스로 간편하게 숙소를 이용해보세요.
            </p>
            <div className="bg_img"></div>
          </div>
          <div id="contents" className="col col_right">
            <dl className="lst_complete">
              <dt>
                죄송합니다
                <br />
                요청하신 페이지를 찾을 수 없습니다
              </dt>
              <dd></dd>
            </dl>

            <div className="lst_msg">
              방문하시려는 페이지의 주소가 잘못 입력되었거나
              <br />
              페이지 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수
              없습니다.
              <br />
              {/* 관련 문의사항은 My Page &gt; VoC로 알려주시면 친절하게 안내하여 드리겠습니다.<br /> */}
              감사합니다.
            </div>

            <div className="submit">
              <button
                className="btn btn_lg btn_darkblue btn_outline"
                onClick={() => props.history.push('/home')}
              >
                Home
              </button>
              {/* <button className="btn btn_lg btn_darkblue">VoC 바로가기</button> */}
            </div>
          </div>{' '}
          {/*#contents*/}
        </div>
      </div>{' '}
      {/*container*/}
      <Footer />
    </div>
  );
};

export default NotFoundPage;
